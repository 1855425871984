import api from '../collums-components/api/config';

class StockApi {
    static getAllBrands(clinic, isFromOrg = false) {
        return api
            .request({
                method: 'GET',
                url: `stocks/brands?clinic=${clinic}&isFromOrg=${isFromOrg}`
            })
            .then(result => result)
            .catch(err => err);
    }

    static getBrands(skip, limit, queryParams) {
        return api.request({
            method: 'GET',
            url: `stocks/brands-paginated?skip=${skip}&limit=${limit}${queryParams ? `&${queryParams}` : ''}`
        });
    }

    static updateBrandActive(id, value, clinic, isFromOrg = false) {
        return api.request({
            method: 'PUT',
            url: `stocks/brands/${id}/change-active?value=${value}&clinic=${clinic}&isFromOrg=${isFromOrg}`
        });
    }

    static archiveBrand(id, value, clinic, isFromOrg) {
        return api.request({
            method: 'DELETE',
            url: `stocks/brands/${id}/archive?value=${value}&clinic=${clinic}&isFromOrg=${isFromOrg}`
        });
    }

    static createBrand(data) {
        return api.request({
            method: 'POST',
            url: '/stocks/brands',
            data
        });
    }

    static updateBrand(id, data) {
        return api.request({
            method: 'PUT',
            url: `/stocks/brands/${id}`,
            data
        });
    }
}

export default StockApi;
