import React, { useEffect, useState, useCallback } from 'react';

import { withStyles } from '@material-ui/core';

import styles from './styles';

import View from '../common/View';
import Header from './ViewHeader';
import Table from './ViewTable';
import Modal from './ViewModal';

import TemporaryDiscountsApi from '../../api/TemporaryDiscountsApi';
import { toastr } from 'react-redux-toastr';
import scrollTop from '../../services/scrollTop';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import { listClinics } from '../../collums-components/api/ClinicApi';
import { useDebounce } from '../../collums-components/helpers';

function TemporaryDiscountsView({ isFromOrg = false, clinic }) {
    const [open, setOpen] = useState(false);
    const [items, setItems] = useState([]);
    const [selectedDiscount, setSelectedDiscount] = useState(null);
    const [nameFilter, setNameFilter] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [count, setCount] = useState(0);
    const [clinics, setClinics] = useState([]);
    const [showArchived, setShowArchived] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const debounceFilter = useDebounce(nameFilter, 750);

    useEffect(() => {
        getClinics();
    }, [isFromOrg, clinic]); //eslint-disable-line

    const getClinics = useCallback(async () => {
        setClinics(isFromOrg ? await listClinics() : [clinic]);
    }, [isFromOrg, clinic]); //eslint-disable-line

    const refreshList = async () => {
        setIsLoading(true);
        try {
            const temporaryDiscounts = await TemporaryDiscountsApi.getAll(
                nameFilter,
                page,
                rowsPerPage,
                isFromOrg,
                clinic?.id,
                showArchived
            );
            setItems(temporaryDiscounts.items);
            setCount(temporaryDiscounts.count);
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        scrollTop();
    }, [items]);

    const toggleItem = async (id, property, value) => {
        try {
            const updatedDiscount = await TemporaryDiscountsApi.toggleProperty(
                id,
                property,
                value,
                isFromOrg,
                clinic?.id
            );
            await refreshList();
            const toastrMessage = (() => {
                if (property === 'Archived') {
                    return toastr.success(
                        'Success!',
                        `Discount successfully ${updatedDiscount.archived ? 'archived' : 'unarchived'}`
                    );
                }
                return toastr.success(
                    'Success!',
                    `Discount successfully ${updatedDiscount.active ? 'activated' : 'inactivated'}`
                );
            })();
            return toastrMessage;
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        }
    };

    const onChangePage = (e, newPage) => setPage(newPage);
    const onChangeRowsPerPage = e => {
        setRowsPerPage(parseInt(e.target.value), 25);
        setPage(0);
    };

    const panelProps = {
        open,
        openModal: () => setOpen(true),
        closeModal: () => setOpen(false),
        selectedDiscount,
        selectDiscount: discount => setSelectedDiscount(discount),
        resetDiscount: () => setSelectedDiscount(null),
        items,
        nameFilter,
        setNameFilter,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        onChangePage,
        onChangeRowsPerPage,
        count,
        toggleItem,
        clinics,
        clinic,
        isFromOrg,
        refreshList,
        showArchived,
        setShowArchived
    };

    useEffect(() => {
        if (isFromOrg || clinic?.id) {
            refreshList();
        }
    }, [page, rowsPerPage, debounceFilter, showArchived, clinic, isFromOrg]); //eslint-disable-line

    return (
        <>
            <LoadingScreen isVisible={isLoading} />
            <View>
                <Header {...panelProps} />
                <Table {...panelProps} items={items} />
                {open && <Modal {...panelProps} />}
            </View>
        </>
    );
}

export default withStyles(styles)(TemporaryDiscountsView);
