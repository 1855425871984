import React from 'react';
import { Paper, withStyles } from '@material-ui/core';
import View from '../common/View';

import { openedModalStyles } from './styles';

function OpenedModal({ classes, children }) {
    return (
        <View extraStyleClass={classes.centerFlexContent}>
            <Paper className={classes.paperContainer}>{children}</Paper>
        </View>
    );
}

export default withStyles(openedModalStyles)(OpenedModal);
