import React from 'react';
import { listsViewStyles } from '../styles';
import { FormControl, Grid, TextField, Typography, withStyles } from '@material-ui/core';

function ClientTab({ classes, register, formValues, setFormValues }) {
    return (
        <Grid container direction="row" spacing={4}>
            <Grid item xs={12}>
                <Typography style={{ fontSize: 16 }}>
                    Add each list item in the textbox with each item on Its own line. Put them in the order you wish
                    them to appear.
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography variant="h3">Referral Sources</Typography>
                <FormControl class={classes.textBox}>
                    <TextField
                        multiline
                        rows={8}
                        name="client_referral_sources"
                        id="client_referral_sources"
                        value={formValues.client_referral_sources}
                        inputRef={register()}
                        onChange={({ target }) => {
                            setFormValues({
                                ...formValues,
                                client_referral_sources: target.value
                            });
                        }}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
}

export default withStyles(listsViewStyles)(ClientTab);
