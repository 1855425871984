export const typeMenuStyles = () => ({
    logout: {
        '& svg': {
            fontSize: 18,
            color: '#ffffff',
            opacity: 0.7
        },
        '&:hover svg': {
            opacity: 1
        }
    },
    generalTabs: {
        '& .MuiTabs-flexContainer': {
            maxWidth: 'inherit',
            minWidth: 1204
        },
        '& .MuiTabs-scrollButtons': {
            transition: '200ms ease',
            '&.Mui-disabled': {
                width: 0
            }
        }
    },
    tabs: {
        // old header
        color: 'white',
        '& button': {
            padding: 0,
            '& span': {
                height: '48px',
                '& a': {
                    color: 'white',
                    textDecoration: 'none',
                    width: '100%',
                    height: '100%',
                    textAlign: 'center',
                    alignItems: 'center',
                    display: 'inline-flex',
                    justifyContent: 'center',
                    fontSize: '0.825rem',
                    letterSpacing: '0.058rem'
                }
            }
        }
    },
    noShadow: {
        boxShadow: 'none',
        minHeight: '28px',
        height: '38px',
        '& button': {
            padding: 0
        }
    },
    tabsContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '28px',
        height: '38px',
        backgroundColor: '#69767E',
        padding: 0
    },
    tabIndicator: {
        height: 0,
        backgroundColor: 'white'
    },
    mainTab: {
        opacity: 1,
        fontSize: 15,
        fontWeight: 400,
        backgroundColor: '#69767E',
        color: 'white',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: '#59666E'
        },
        '&.Mui-selected': {
            backgroundColor: '#565A65'
        },
        '& a': {
            color: 'white',
            height: '100%',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            textDecoration: 'none',
            textAlign: 'center',
            whiteSpace: 'nowrap'
        },
        maxWidth: 250,
        width: 250
    }
});
