import React, { useEffect, useState } from 'react';
import Modal from '../common/Modal';
import { Controller, useForm } from 'react-hook-form';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import { makeStyles, Button, TextField, FormControl, Chip, Select, MenuItem, InputLabel } from '@material-ui/core';
import { toastr } from 'react-redux-toastr';
import CategoryApi from '../../collums-components/api/CategoryApi';
import NotificationApi from '../../api/NotificationApi';
import CancelContinueModal from '../../collums-components/components/common/CancelContinueModal';

const PreAfterCareModal = ({ visible, setVisibility, classes = {}, closeModal }) => {
    const globalStyles = makeStyles(modalsButtonStyles)();
    const { control, register, handleSubmit, errors } = useForm({});
    const [categories, setCategories] = useState([]);
    const [lossDataModal, setLossDataModal] = useState(false);

    const loadCategories = async () => {
        try {
            const categ = await CategoryApi.filter('', false, null, true);
            setCategories(categ);
        } catch (error) {
            toastr.error(error.data?.message || 'Something went wrong');
        }
    };

    useEffect(() => {
        loadCategories();
    }, []);

    useEffect(() => {
        const requiredFields = Object.keys(errors);

        if (requiredFields.length !== 0) {
            const messages = requiredFields.map(field => errors[field].message);

            toastr.error('Missing fields', messages.join(','));
        }
    }, [errors]);

    const handleAddNotification = async formData => {
        try {
            const notificationCreated = await NotificationApi.createNotification({
                ...formData,
                description: formData.description || ''
            });
            closeModal(notificationCreated._id);
        } catch (error) {
            toastr.error(error.data?.message || 'Something went wrong');
        }
    };

    // eslint-disable-next-line
    const renderInputCategories = ({ value, onChange }) => {
        const handleDelete = index => {
            const newTags = [...value];
            newTags.splice(index, 1);
            onChange(newTags);
        };
        return (
            <div className={classes.tagsContainer}>
                <div style={{ width: '45%' }}>
                    <InputLabel shrink className={classes.categoriesLabel} style={{ marginTop: 115, marginLeft: 10 }}>
                        Send to these service categories
                    </InputLabel>
                    <Select
                        onChange={event => {
                            if (event.target.value) {
                                onChange([...value, event.target.value]);
                            }
                        }}
                        className={classes.autocomplete}
                        variant={'outlined'}
                        style={{ width: '100%' }}
                    >
                        {categories
                            .filter(opt => !value.some(el => el.id === opt.id))
                            .map(opt => {
                                return (
                                    <MenuItem key={opt.name} value={opt}>
                                        {opt.name}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </div>

                <div className={classes.tagChipsContainer}>
                    {/* eslint-disable-next-line */}
                    {value.map((tag, index) => (
                        <Chip key={index} size="small" label={tag.name} onDelete={() => handleDelete(index)} />
                    ))}
                </div>
            </div>
        );
    };

    return (
        <>
            <Modal
                isOpen={visible}
                onCancel={() => setLossDataModal(true)}
                onClose={() => setVisibility(false)}
                title={'New Pre/After Care'}
                id="preaftercare-form"
                size="xs"
                dialogClass={classes.modal}
            >
                <FormControl style={{ width: '100%' }}>
                    <Controller
                        render={props => (
                            <TextField
                                required
                                {...props}
                                className={classes.inputField}
                                variant="outlined"
                                label={'Name'}
                            />
                        )}
                        name={'name'}
                        control={control}
                        rules={register({ required: true })}
                    />
                    <Controller
                        render={props => (
                            <TextField
                                {...props}
                                className={classes.inputField}
                                variant="outlined"
                                label={'Description'}
                            />
                        )}
                        name={'description'}
                        control={control}
                    />
                    <Controller
                        name={'categories'}
                        control={control}
                        render={renderInputCategories}
                        defaultValue={[]}
                    />

                    <div className={globalStyles.buttonsContainer}>
                        <Button className={globalStyles.cancelButton} onClick={() => setVisibility(false)}>
                            Cancel
                        </Button>
                        <Button
                            className={globalStyles.confirmButton}
                            type="submit"
                            variant="outlined"
                            onClick={() => {
                                handleSubmit(handleAddNotification)();
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </FormControl>
            </Modal>
            <CancelContinueModal
                open={lossDataModal}
                onContinue={() => setVisibility(false)}
                onCancel={() => setLossDataModal(false)}
                closeOnBlur={false}
                title={'Your changes will not be saved'}
                contentText={'Are you sure you want to continue?'}
                cancelButtonText="Back"
            />
        </>
    );
};

export default PreAfterCareModal;
