import React, { useEffect, forwardRef, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Jodit } from 'jodit';
import 'jodit/build/jodit.min.css';

/**
 * We was using [jodit-react]{@link https://github.com/jodit/jodit-react} on email editor
 * but this library has this issue [issue]{@link https://github.com/jodit/jodit-react/issues/43}
 * So, because of that we need to write this wrapper
 */

const JoditEditorWrapper = forwardRef(
    (
        {
            value,
            onChange,
            onBlur,
            tabIndex,
            name,
            defaultValue,
            uploads = true,
            customizedButtons = false,
            placeholder
        },
        ref
    ) => {
        const isChangingMode = useRef(false);
        const isChangingModeBlur = useRef(false);

        const buttons = useMemo(() => {
            const choosedButtons = [
                'source',
                '|',
                'bold',
                'italic',
                '|',
                'ul',
                'ol',
                '|',
                'font',
                'fontsize',
                'brush',
                'paragraph',
                '|',
                ...(uploads ? ['image', 'video'] : []),
                'table',
                'link',
                '|',
                'left',
                'center',
                'right',
                'justify',
                '|',
                'undo',
                'redo',
                '|',
                'hr',
                'eraser',
                'fullsize'
            ];
            if (customizedButtons) {
                return {
                    buttons: choosedButtons,
                    buttonsMD: choosedButtons // for keeping buttons with/without upload ones on smaller editors
                };
            }
            return {};
        }, [customizedButtons, uploads]);

        useEffect(() => {
            const blurHandler = value => {
                onBlur && onBlur(value);
            };

            const changeHandler = value => {
                onChange && onChange(value);
            };

            ref.current = new Jodit(`#${name}-editor`, {
                width: '100%',
                height: 400,
                style: {
                    fontFamily: 'Roboto, Arial, Sans-serif'
                },
                readonly: false,
                beautyHtml: false,
                enableDragAndDropFileToEditor: uploads,
                ...buttons,
                ...(placeholder ? { placeholder } : {}),
                uploader: {
                    insertImageAsBase64URI: true,
                    imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                    headers: null,
                    data: null,
                    withCredentials: false,
                    pathVariableName: 'path',
                    format: 'json'
                },
                cleanWhitespace: false,
                removeEmptyBlocks: false,
                cleanHTML: {
                    cleanOnPaste: false
                }
            });

            ref.current.value = value || '';

            ref.current.events.on('blur', () => {
                if (isChangingModeBlur.current && ref.current.value === '') {
                    ref.current.value = value;
                    blurHandler(value);
                    isChangingModeBlur.current = false;
                    return;
                }
                blurHandler(ref.current.value);
            });

            ref.current.events.on('change', () => {
                if (isChangingMode.current && ref.current.value === '') {
                    ref.current.value = value;
                    changeHandler(value);
                    isChangingMode.current = false;
                    return;
                }
                changeHandler(ref.current.value);
            });

            ref.current.events.on('beforeSetMode', () => {
                isChangingMode.current = true;
                isChangingModeBlur.current = true;
            });

            ref.current.events.on('afterSetMode', () => {
                isChangingMode.current = true;
                isChangingModeBlur.current = true;
            });

            ref.current.workplace.tabIndex = tabIndex || -1;
            /* eslint-disable-next-line */
        }, []);

        return (
            <textarea
                id={`${name}-editor`}
                style={{ display: 'none' }}
                name={name}
                defaultValue={defaultValue || ''}
            ></textarea>
        );
    }
);

JoditEditorWrapper.propTypes = {
    value: PropTypes.string,
    tabIndex: PropTypes.number,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    defaultValue: PropTypes.string,
    uploads: PropTypes.bool,
    customizedButtons: PropTypes.bool,
    placeholder: PropTypes.string,
    name: PropTypes.string
};

export default JoditEditorWrapper;
