export const productCategoriesViewStyles = theme => ({
    header: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    addButton: {
        '& svg': {
            fontSize: 32
        }
    },
    table: {
        '& tr[level="1"]': {
            background: theme.palette.grey['100']
        },
        '& tr[level="1"] td:nth-of-type(2)': {
            paddingLeft: 32,
            paddingRight: 0
        },
        '& tr[level="2"] td:nth-of-type(2)': {
            paddingLeft: 48
        }
    },
    checkboxcontainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '& span': {
            color: 'rgba(0, 0, 0, 0.87)'
        },
        '& label': {
            fontFamily: 'Roboto, Arial, Sans-serif'
        }
    }
});

export const productCategoryModalStyles = () => ({
    sectionLabelFirst: {
        marginBottom: 8
    },
    sectionLabelNext: {
        marginTop: 16,
        marginBottom: 8
    },
    lastSection: {
        marginBottom: 32
    },
    accordionsForm: {
        '& .MuiExpansionPanelSummary-content': {
            '& p': {
                fontSize: 16,
                fontWeight: 400
            }
        }
    }
});

export const productCategoryDetailsModalStyles = () => ({
    sectionLabelFirst: {
        marginBottom: 8
    },
    sectionLabelNext: {
        marginTop: 16,
        marginBottom: 8
    },
    lastSection: {
        marginBottom: 32
    },
    dynamicAddField: {
        width: '100%'
    },
    dynamicAddFieldIconButton: {
        width: '100%',
        border: '1px dashed rgba(0, 0, 0, .6)',
        borderRadius: 0
    },
    formContent: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap'
    },
    formContentRow: {
        width: '100%'
    },
    formItem: {
        width: '48%',
        marginRight: 8,
        marginTop: 8,
        marginBottom: 8,
        '& input': {
            height: 'inherit',
            boxSizing: 'border-box'
        }
    },
    colourInput: {
        width: '21%'
    },
    rowInputItems: {
        width: '50%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    listRow: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    listContainer: {
        width: '50%'
    },
    title: {
        marginTop: '2%'
    },
    autocomplete: {
        marginRight: 20,
        width: 200,
        '& .MuiInputBase-root': {
            padding: '2px 8px',
            paddingRight: '45px !important'
        },
        '& .MuiAutocomplete-tag': {
            marginTop: 1
        }
    },
    autocompleteTextField: {
        width: '100%',
        marginRight: 20
    }
});
