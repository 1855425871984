import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import AdminMenu from './components/menu/AdminMenu';
import OrganisationView from './components/organisationView/OrganisationView';
import ResourcesView from './components/resourcesView/ResourcesView';
import LocationView from './components/locationView/LocationView';

const Routes = () => {
    return (
        <Switch>
            <Route path="/" exact component={AdminMenu} />

            <Redirect exact from="/organisation" to="/organisation/temporary-discounts" />

            <Route path="/organisation/:tab" exact component={OrganisationView} />
            <Route path="/organisation/:tab/:subTab" exact component={OrganisationView} />

            <Redirect exact from="/resources/location/:clinic" to="resources/location/:location/courses" />
            <Route path="/resources/location/:clinic/:tab" exact component={ResourcesView} />
            <Route path="/resources/location/:clinic/:tab/:subTab" exact component={ResourcesView} />

            <Redirect exact from="/location/" to="/" />
            <Route path="/location/:id/:tab" exact component={LocationView} />

            {/** old routes 
                 
                <Route path="/membership">
                    <Typography>Membership</Typography>
                </Route>
                <Route path="/commissions">
                    <Typography>Commissions</Typography>
                </Route>
                <Route path="/catalog">
                    <Typography>Catalog</Typography>
                </Route> 
                <Route path="/employee-roles">
                    <Typography>Employee roles</Typography>
                </Route>

                <Route path="/practitioners" component={PractitionersView} />  
                <Route path="/categories" component={CategoriesView} />
                <Route path="/services" component={ServicesView} />
                <Route path="/products" component={ProductsView} />
                <Route path="/notifications" component={NotificationsView} />
                <Route path="/courses" component={CoursesView} />
                <Route path="/suppliers" exact component={SuppliersView} />
                
                <Route path="/rooms" component={RoomView} />
                <Route path="/equipment" component={EquipmentView} />
                <Route path="/square" component={SquareView} />
                <Route path="/tags" component={TagsView} />
                <Route path="/temporary-discounts" component={TemporaryDiscountsView} />
                <Route path="/merge-clients" component={MergeClientsView} />
                */}
        </Switch>
    );
};

export default Routes;
