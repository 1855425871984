import React, { useEffect, useState } from 'react';
import NotificationApi from '../../api/NotificationApi';
import { AddAction } from '../common/ListPagination/Actions';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import NotificationTable from './NotificationTable';
import Switch from '../../collums-components/components/common/customSwitch';
import { Typography, withStyles, Checkbox } from '@material-ui/core';
import notificationsStyle from './style';
import PreAfterCareModal from './PreAfterCareModal';
import { toastr } from 'react-redux-toastr';
import { Link } from 'react-router-dom';
import Actions from '../common/TabContent/actions';
import PreAfterCarEditModal from './PreAfterCarEditModal';

const PreAfterCareView = ({ classes }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [notificationList, setNotificationList] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState({});
    const [showArchived, setShowArchived] = useState(false);

    const loadList = async () => {
        try {
            setIsLoading(true);
            const list = await NotificationApi.query(true, showArchived);
            setNotificationList(list);
        } catch (error) {
            toastr.error('Something went wrong');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadList();
        // eslint-disable-next-line
    }, [notificationList.length, showArchived]);

    const openModal = async id => {
        setIsLoading(true);
        const selectedNotification = await NotificationApi.get(id);
        setSelectedNotification(selectedNotification);
        setModalOpen(true);
        setIsLoading(false);
    };

    const closeModal = async () => {
        setModalOpen(false);
    };

    const changeActive = async (notification, target) => {
        const payload = {};
        if (target === 'email') {
            payload.isEmail = true;
            payload.value = !notification.email.active;
        } else {
            payload.isEmail = false;
            payload.value = !notification.sms.active;
        }
        try {
            await NotificationApi.changeActive(notification.id, payload);
            toastr.success(`Notification ${payload.isEmail ? 'email' : 'sms'} successfully updated`);
            await loadList();
        } catch (err) {
            toastr.error('Something went wrong');
        }
    };

    const tableActions = {
        handleArchive: async id => {
            try {
                setIsLoading(true);
                const res = await NotificationApi.changeArchived(true, id);
                toastr.success(res.name, 'Successfully ' + (res.archived ? 'Archived' : 'Unachived'));
                loadList();
            } catch (err) {
                toastr.error('Something went wrong', err);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const collumns = [
        {
            label: 'Name',
            key: 'name',
            specialField: notification => {
                return (
                    <Typography>
                        <Link
                            onClick={() => openModal(notification.id)}
                            style={{ textDecoration: 'none', color: '#3083EC' }}
                        >
                            {notification.name}
                        </Link>
                    </Typography>
                );
            }
        },
        {
            label: 'Description',
            key: 'description'
        },
        {
            label: 'Categories',
            key: 'categories',
            specialField: notification =>
                (notification?.categories || []).map(category => (
                    <Typography key={category.name}>{category.name} </Typography>
                ))
        },
        {
            label: 'Email',
            key: 'email',
            specialField: notification => (
                <Switch
                    checked={notification.email?.active}
                    onChange={() => {
                        changeActive(notification, 'email');
                    }}
                />
            )
        },
        {
            label: 'SMS',
            key: 'sms',
            specialField: notification =>
                notification.sms.trigger === 'pre-form' ? null : (
                    <Switch
                        checked={notification.sms?.active}
                        onChange={() => {
                            changeActive(notification, 'sms');
                        }}
                    />
                )
        },
        {
            label: 'Actions',
            key: 'actions',
            specialField: notif => <Actions id={notif.id} isArchived={notif.archived} actions={tableActions} />
        }
    ];

    const validateGeneralForm = data => {
        if (!data.name) {
            toastr.error('Please complete name');
            return false;
        }

        return true;
    };

    const generalTabSaveHandler = async (data, id) => {
        if (!validateGeneralForm(data)) return;

        try {
            setIsLoading(true);

            await NotificationApi.update(data, id);
            await closeModal();
            toastr.success('Pre/After Care updated');

            loadList();
        } catch (err) {
            if (err?.data?.message) {
                toastr.error(err.data.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {isCreateModalOpen && (
                <PreAfterCareModal
                    visible={isCreateModalOpen}
                    setVisibility={setCreateModalOpen}
                    classes={classes}
                    setIsLoading={setIsLoading}
                    closeModal={id => {
                        setCreateModalOpen(false);
                        loadList();
                        openModal(id);
                    }}
                />
            )}
            <div className={classes.options}>
                <AddAction
                    onAction={() => {
                        setCreateModalOpen(true);
                    }}
                />
                <div className={classes.showArchived}>
                    <Checkbox checked={showArchived} onClick={() => setShowArchived(!showArchived)} />
                    <Typography variant="h4">Show Archived</Typography>
                </div>
            </div>

            <NotificationTable notificationList={notificationList} collumns={collumns} />
            {isModalOpen && (
                <PreAfterCarEditModal
                    notification={selectedNotification}
                    closeModal={closeModal}
                    isPreAfterCare={true}
                    setIsLoading={setIsLoading}
                    generalTabSaveHandler={generalTabSaveHandler}
                />
            )}
            {isLoading && <LoadingScreen />}
        </>
    );
};

export default withStyles(notificationsStyle)(PreAfterCareView);
