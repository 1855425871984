import React from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    withStyles
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { customModalStyles as styles } from './styles';

function Modal({ classes, title, children, onConfirm, confirmLabel, actions, onClose, isOpen }) {
    const renderActions = () => {
        const buttons = [];
        if (onConfirm) {
            buttons.push(
                <Button
                    key="confirm"
                    onClick={e => {
                        e.preventDefault();
                        onConfirm();
                    }}
                    color="primary"
                >
                    {confirmLabel || 'Confirm'}
                </Button>
            );
        }

        buttons.push(...(actions || []));
        return buttons;
    };
    return (
        <div
            className="modal"
            style={{
                display: isOpen ? 'flex' : 'none',
                position: 'absolute',
                height: '100%'
            }}
            onClick={e => {
                if (e.target.classList?.value === 'modal') {
                    onClose();
                }
            }}
        >
            <div className={`modal-content ${classes.modalContainer}`}>
                <DialogTitle className={classes.title} disableTypography>
                    <Typography variant="h4">{title}</Typography>
                    <IconButton onClick={onClose} className={classes.closeButton}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>{children}</DialogContent>
                <DialogActions>{renderActions()}</DialogActions>
            </div>
        </div>
    );
}

export default withStyles(styles)(Modal);
