export const colorPickerStyles = theme => ({
    container: {
        marginBottom: 8
    },
    picker: {
        position: 'relative',
        top: 4,
        left: 10
    },
    pickerWrapper: {
        position: 'absolute',
        zIndex: 100
    },
    label: {
        color: theme.palette.text.secondary
    },
    button: {
        marginTop: 8
    }
});

export const textInputStyles = () => ({
    container: {
        marginBottom: 16
    },
    tooltip: {
        position: 'absolute',
        right: 0,
        top: 10,
        padding: 6,
        '& svg': {
            fontSize: 16
        }
    }
});

export const numberInputStyles = () => ({
    container: {
        display: 'flex',
        marginBottom: 16
    },
    prefix: {
        fontSize: 14,
        paddingTop: 19,
        paddingRight: 4
    }
});

export const selectDropdownStyles = () => ({
    container: {
        marginBottom: 16
    }
});

export const singleCheckboxStyles = () => ({
    container: {
        marginBottom: 16
    }
});

export const singleAutocompleteStyles = theme => ({
    container: {
        marginBottom: 16,
        '&.error label': {
            color: theme.palette.error.main
        }
    },
    description: {
        color: theme.palette.text.secondary,
        fontSize: 9,
        marginTop: 6
    },
    descriptionError: {
        color: theme.palette.error.main,
        fontSize: 9,
        marginTop: 6
    }
});

export const durationAutocompleteStyles = theme => ({
    container: {
        marginBottom: 16
    },
    invalid: {
        '& .MuiInputLabel-root': {
            color: theme.palette.error.main
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.error.main
        }
    },
    description: {
        color: theme.palette.text.secondary,
        fontSize: 9,
        marginTop: 6
    },
    descriptionError: {
        color: theme.palette.error.main,
        fontSize: 9,
        marginTop: 6
    }
});

export const multipleAutocompleteStyles = () => ({
    container: {
        marginBottom: 16
    }
});

export const rowEditorStyles = theme => ({
    container: {
        marginBottom: 8
    },
    headerRow: {
        marginBottom: 4,
        display: 'flex'
    },
    row: {
        marginBottom: 8,
        display: 'flex'
    },
    subRowWrapper: {
        flex: 1
    },
    subRow: {
        display: 'flex',
        flex: 1
    },
    cellHeader: {
        flex: 1,
        color: theme.palette.text.secondary,
        paddingRight: 8,
        paddingBottom: 8
    },
    cellInput: {
        flex: 1,
        paddingRight: 8
    },
    cellRemove: {
        width: 20
    },
    label: {
        color: theme.palette.text.secondary,
        marginBottom: 10
    },
    labelError: {
        color: theme.palette.error.main,
        marginBottom: 10
    },
    description: {
        color: theme.palette.text.secondary,
        fontSize: 9,
        marginBottom: 10
    },
    descriptionError: {
        color: theme.palette.error.main,
        fontSize: 9,
        marginBottom: 10
    },
    addButton: {
        width: '100%'
    }
});

export const unitAndValueStyles = theme => ({
    container: {
        marginBottom: 8
    },
    label: {
        color: theme.palette.text.secondary,
        marginBottom: 10
    },
    labelError: {
        color: theme.palette.error.main,
        marginBottom: 10
    },
    description: {
        color: theme.palette.text.secondary,
        fontSize: 9,
        marginBottom: 10
    },
    descriptionError: {
        color: theme.palette.error.main,
        fontSize: 9,
        marginBottom: 10
    },
    row: {
        display: 'flex'
    },
    itemUnit: {
        display: 'flex',
        flex: 2,
        overflow: 'hidden'
    },
    itemValue: {
        display: 'flex',
        flex: 1
    },
    itemSeparator: {
        width: 8
    },
    prefix: {
        fontSize: 14,
        paddingTop: 3,
        paddingRight: 4
    },
    suffix: {
        fontSize: 14,
        paddingTop: 3,
        paddingLeft: 4
    }
});
