export const defaultTC = `
    <div>
        <h3 style="font-size:1.17rem;margin-bottom:0.7rem;margin-top:0.6rem;">Input a title here</h3>
        <p style="font-size:16px;display:inline-block;margin-bottom:0.2rem;margin-top:0;line-height:1.55">Input the body text here</p>

        <h3 style="font-size:1.17rem;margin-bottom:0.7rem;margin-top:0.6rem;">Input a title here</h3>
        <p style="font-size:16px;display:inline-block;margin-bottom:0.2rem;margin-top:0;line-height:1.55">Input the body text here</p>

        <h3 style="font-size:1.17rem;margin-bottom:0.7rem;margin-top:0.6rem;">Input a title here</h3>
        <p style="font-size:16px;display:inline-block;margin-bottom:0.2rem;margin-top:0;line-height:1.55">Input the body text here</p>

        <h3 style="font-size:1.17rem;margin-bottom:0.7rem;margin-top:0.6rem;">Input a title here</h3>
        <p style="font-size:16px;display:inline-block;margin-bottom:0.2rem;margin-top:0;line-height:1.55">Input the body text here</p>

        <h3 style="font-size:1.17rem;margin-bottom:0.7rem;margin-top:0.6rem;">Input a title here</h3>
        <p style="font-size:16px;display:inline-block;margin-bottom:0.2rem;margin-top:0;line-height:1.55">Input the body text here</p>
    </div>
`;

export const defaultThankYouHtml = `
    <div style="text-align:center">
        <h4 style="font-size:16px;font-weight:400;margin-top: 0.8rem;margin-bottom: 1.5rem;display:inline-block;">Thank your for your booking</h4>
        <p style="font-size:16px;">Input the body text here</p>
        <p style="font-size:16px;">Input the body text here</p>
        <p style="font-size:16px;">Input the body text here</p>
        <h4 style="font-size:16px;font-weight:400;margin-top: 0.8rem;margin-bottom: 1.5rem;display:inline-block;">Input your final message here</h4>
    </div>`;
