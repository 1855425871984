import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { toastr } from 'react-redux-toastr';
import { withStyles, TextField, Button, makeStyles } from '@material-ui/core';

import { supplierModalForm as styles } from './styles';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';

import Accordion from '../common/Accordion';
import LocationTab from '../common/LocationTab';
import Modal from '../common/Modal';
import Autocomplete from '../common/SelectAutocomplete';
import ConfirmExitModal from '../../collums-components/components/common/ConfirmExitModal/ConfirmExitModal';

function DetailModal({
    classes,
    isOpen,
    isFromOrg,
    clinics,
    onClose,
    supplier,
    brands,
    refreshList,
    onAddSupplier,
    onEditSupplier,
    allClinics
}) {
    const globalStyles = makeStyles(modalsButtonStyles)();

    const defaultValues = {
        name: supplier?.name || '',
        code: supplier?.code || '',
        description: supplier?.description || '',
        address: supplier?.address || '',
        address2: supplier?.address2 || '',
        city: supplier?.city || '',
        county: supplier?.county || '',
        postCode: supplier?.postCode || '',
        phone: supplier?.telephone || '',
        email: supplier?.email || '',
        contactName: supplier?.contactName || '',
        brands: supplier?.brands || []
    };
    const { handleSubmit, register, control, getValues, errors } = useForm({ defaultValues });
    const [isEditing, setIsEditing] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const formRef = useRef();

    const onSubmit = async data => {
        if (!isEditing && !isAdding) return;
        const filteredLocations = data.locations.filter(loc => loc.isSelected).map(loc => ({ clinic: loc.value }));
        const body = {
            name: data.name,
            code: data.code,
            description: data.description,
            address: data.address,
            address2: data.address2,
            city: data.city,
            county: data.county,
            postCode: data.postCode,
            telephone: data.phone,
            email: data.email,
            contactName: data.contactName,
            brands: data.brands?.map(brand => brand.id),
            locations: filteredLocations
        };

        if (supplier.name) {
            const id = supplier._id ? supplier._id : supplier.id;
            await onEditSupplier(id, body);
        } else {
            await onAddSupplier(body);
        }
        await refreshList();
        onClose();
    };

    useEffect(() => {
        if (!supplier.name) {
            setIsAdding(true);
        }
        /* eslint-disable-next-line */
    }, []);

    useEffect(() => {
        if (errors && isDialogOpen) {
            setIsDialogOpen(false);
        }
        const requiredFields = Object.keys(errors);

        if (requiredFields.length !== 0) {
            const messages = requiredFields.map(field => errors[field].message);

            toastr.error('Missing fields', messages.join(', '));
        }
        // eslint-disable-next-line
    }, [errors]);

    const tabProps = {
        clinics,
        isFromOrg,
        register,
        control,
        selectedItem: supplier,
        isFrom: 'suppliers'
    };

    return (
        <>
            <Modal
                size="md"
                id="suppliers-modal"
                isOpen={isOpen}
                title={supplier.name ? `View/Edit ${supplier.name} supplier` : 'Add a new supplier'}
                onClose={onClose}
                onCancel={onClose}
            >
                <Accordion className={classes.accordion} title={'General'}>
                    <form className={classes.form} ref={formRef}>
                        <div className={classes.row}>
                            <TextField
                                label="Supplier Name"
                                className={classes.formItem}
                                name="name"
                                required
                                inputRef={register({ required: 'Name' })}
                                variant="outlined"
                                inputProps={{ readOnly: isAdding ? false : !isEditing }}
                                disabled={isAdding ? false : !isEditing}
                            />
                            <TextField
                                label="Supplier Code"
                                className={classes.formItem}
                                name="code"
                                inputRef={register}
                                variant="outlined"
                                inputProps={{ readOnly: isAdding ? false : !isEditing }}
                                disabled={isAdding ? false : !isEditing}
                            />
                        </div>

                        <div className={classes.row}>
                            <TextField
                                label="Description"
                                className={classes.formItem}
                                name="description"
                                inputRef={register}
                                variant="outlined"
                                inputProps={{ readOnly: isAdding ? false : !isEditing }}
                                disabled={isAdding ? false : !isEditing}
                            />

                            <TextField
                                label="Address"
                                className={classes.formItem}
                                name="address"
                                inputRef={register}
                                variant="outlined"
                                inputProps={{ readOnly: isAdding ? false : !isEditing }}
                                disabled={isAdding ? false : !isEditing}
                            />
                        </div>

                        <div className={classes.row}>
                            <TextField
                                label="Address 2"
                                className={classes.formItem}
                                name="address2"
                                inputRef={register}
                                variant="outlined"
                                inputProps={{ readOnly: isAdding ? false : !isEditing }}
                                disabled={isAdding ? false : !isEditing}
                            />

                            <TextField
                                label="City"
                                className={classes.formItem}
                                name="city"
                                inputRef={register}
                                variant="outlined"
                                inputProps={{ readOnly: isAdding ? false : !isEditing }}
                                disabled={isAdding ? false : !isEditing}
                            />
                        </div>

                        <div className={classes.row}>
                            <TextField
                                label="County"
                                className={classes.formItem}
                                name="county"
                                inputRef={register}
                                variant="outlined"
                                inputProps={{ readOnly: isAdding ? false : !isEditing }}
                                disabled={isAdding ? false : !isEditing}
                            />

                            <TextField
                                label="Post Code"
                                className={classes.formItem}
                                name="postCode"
                                inputRef={register}
                                variant="outlined"
                                inputProps={{ readOnly: isAdding ? false : !isEditing }}
                                disabled={isAdding ? false : !isEditing}
                            />
                        </div>

                        <div className={classes.row}>
                            <TextField
                                label="Phone"
                                className={classes.formItem}
                                name="phone"
                                inputRef={register}
                                variant="outlined"
                                inputProps={{ readOnly: isAdding ? false : !isEditing }}
                                disabled={isAdding ? false : !isEditing}
                            />

                            <TextField
                                label="Email"
                                className={classes.formItem}
                                name="email"
                                inputRef={register}
                                variant="outlined"
                                inputProps={{ readOnly: isAdding ? false : !isEditing }}
                                disabled={isAdding ? false : !isEditing}
                            />
                        </div>

                        <div className={classes.row}>
                            <TextField
                                label="Contact Name"
                                className={classes.formItem}
                                name="contactName"
                                inputRef={register}
                                variant="outlined"
                                inputProps={{ readOnly: isAdding ? false : !isEditing }}
                                disabled={isAdding ? false : !isEditing}
                            />

                            <Autocomplete
                                name="brands"
                                control={control}
                                opts={brands}
                                label="Brands"
                                className={classes.brandsStyle}
                                getValues={getValues}
                                props={{
                                    multiple: true,
                                    disabled: isAdding ? false : !isEditing,
                                    variant: 'outlined',
                                    filterSelectedOptions: true
                                }}
                                optionsLabel="name"
                                ChipProps={{ className: classes.autocompleteChipProps }}
                            />
                        </div>
                    </form>
                </Accordion>
                <Accordion className={classes.accordion} title={'Locations'} hideItem={!isFromOrg}>
                    <LocationTab {...tabProps} disabled={isAdding ? false : !isEditing} key="locations" />
                </Accordion>
                <div className={globalStyles.buttonsContainer}>
                    {(isFromOrg || allClinics.length === 1) && (
                        <>
                            <Button className={globalStyles.cancelButton} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button
                                variant="outlined"
                                className={globalStyles.confirmButton}
                                onClick={() => {
                                    if (!isEditing && !isAdding) {
                                        toastr.info('Info', 'Please, enable editing to submit form');
                                        return;
                                    }
                                    if (isEditing && !isAdding) {
                                        setIsDialogOpen(true);
                                        return;
                                    }
                                    if (isAdding) {
                                        handleSubmit(data => onSubmit(data))();
                                    }
                                }}
                            >
                                Confirm
                            </Button>
                        </>
                    )}
                    {!isAdding && (isFromOrg || allClinics.length === 1) && (
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setIsEditing(!isEditing);
                            }}
                            className={`${globalStyles.baseButton} ${classes.outlinedButton}`}
                        >
                            {isEditing ? 'Block Edition' : 'Edit Supplier'}
                        </Button>
                    )}
                </div>
                {isDialogOpen && (
                    <ConfirmExitModal
                        isOpen={isDialogOpen}
                        onConfirm={() => {
                            handleSubmit(data => onSubmit(data))();
                        }}
                        onCancel={() => setIsDialogOpen(false)}
                        title="Confirm changes"
                        text="Are you sure you want to apply those changes?"
                    />
                )}
            </Modal>
        </>
    );
}

export default withStyles(styles)(DetailModal);
