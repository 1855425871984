import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';
import { toastr } from 'react-redux-toastr';

import Modal from '../common/Modal';
import Accordion from '../common/Accordion';
import ProductFormGeneral from './tabs/ProductFormGeneral';
import ProductFormPricingStock from './tabs/ProductFormPricingStock';
import CommissionTab from './tabs/CommissionTab';

import { withStyles, Button, makeStyles } from '@material-ui/core';

import ProductApi from '../../api/ProductApi';

import { productsFormStyles } from './styles';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import { isMobile } from 'react-device-detect';
import LocationTab from '../common/LocationTab';
import ConfirmModal from '../common/ConfirmModal';
import _ from 'lodash';

function ProductFormModal({
    isVisible,
    isFromOrg,
    productTaxes,
    clinic,
    clinics,
    closeModal,
    selectedProduct,
    categories,
    classes,
    allClinics
}) {
    const globalStyles = makeStyles(modalsButtonStyles)();

    selectedProduct = Object.keys(selectedProduct).length
        ? { ...selectedProduct, prevName: selectedProduct.name }
        : undefined;
    const formRef = useRef();
    const [brands, setBrands] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [netPrice, setNetPrice] = useState(
        selectedProduct
            ? isFromOrg || allClinics.length === 1
                ? Math.round(selectedProduct.netPrice * 100) / 100
                : selectedProduct.locations.filter(loc => loc.clinic === clinic)?.[0]?.netPrice
            : undefined
    );
    const [grossPrice, setGrossPrice] = useState(
        selectedProduct
            ? isFromOrg || allClinics.length === 1
                ? Math.round(selectedProduct.grossPrice * 100) / 100
                : selectedProduct.locations.filter(loc => loc.clinic === clinic)?.[0]?.gossPrice
            : undefined
    );
    const volumeUnitRef = useRef(selectedProduct && selectedProduct.vol ? selectedProduct.vol.toString() : undefined);
    const selectedProdWithoutLocs = { ...selectedProduct };
    delete selectedProdWithoutLocs.locations;

    const { register, handleSubmit, control, watch, setValue, getValues, errors } = useForm({
        defaultValues: selectedProdWithoutLocs
    });

    const [confirmModal, setConfirmModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState('');
    const [modalCancel, setModalCancel] = useState(null);
    const [modalContinue, setModalContinue] = useState(null);
    const [modalOnContinue, setModalOnContinue] = useState(() => {});

    useEffect(() => {
        if (errors.general) {
            const requiredFields = Object.keys(errors.general);

            if (requiredFields.length !== 0) {
                const messages = requiredFields.map(field => errors.general[field].message);

                toastr.error('Missing fields on General tab', messages.join(', '));
            }
        }
        if (errors.pricingStock || errors.location?.pricingStock) {
            const requiredFields = Object.keys(errors.location ? errors.location?.pricingStock : errors.pricingStock);

            if (requiredFields.length !== 0) {
                const messages = requiredFields.map(field =>
                    errors.location ? errors.location?.pricingStock[field].message : errors.pricingStock[field].message
                );

                if (isNaN(Number(netPrice))) messages.push(' Net Price');
                if (isNaN(Number(grossPrice))) messages.push(' Gross Price');
                toastr.error('Missing fields on Pricing & Stock tab', messages.join(','));
            }
        }
        /*eslint-disable-next-line */
    }, [errors]);

    const save = value => {
        const fieldErrors = [];
        if (isNaN(volumeUnitRef.current || 0)) {
            toastr.error('Invalid volume');
            return;
        }

        if (!isFromOrg && allClinics.length === 1) {
            value.pricingStock = value.location.pricingStock;
        }

        if (!grossPrice && grossPrice !== 0) fieldErrors.push(' Gross Price');
        if (!netPrice && netPrice !== 0) fieldErrors.push(' Net Price');

        if (fieldErrors.length > 0) {
            toastr.error('Missing fields on Pricing & Stock tab', fieldErrors.join(','));
            return;
        }

        if (selectedProduct?.id) {
            if (value.general.name !== selectedProduct.prevName) {
                setModalTitle('Confirm Editing');
                setModalContent(
                    'Changing the name of this product will also affect historic appointments, invoices, reports and anywhere else it features.\n' +
                        'If you wish to keep historic records the same but have this change for the future, please inactivate this product and create a new one.'
                );
                setModalCancel('Cancel');
                setModalContinue('Confirm');
                setModalOnContinue(() => () => {
                    selectedProduct.prevName = value.general.name;
                    save(value);
                });
                setConfirmModal(true);
                return;
            }
        }

        let locations = _.cloneDeep(value.locations);
        locations = locations
            .filter(item => item.isSelected)
            .map(item => {
                item.clinic = item.value;
                item.isActive = true;
                item.archived = false;

                delete item.isSelected;
                delete item.subtitle;
                delete item.value;
                delete item.label;
                return item;
            });
        const data = {
            isActive: value.general.isActive,
            archived: value.general.isArchived,
            name: value.general.name,
            type: value.general.productType,
            vol: parseFloat(volumeUnitRef.current || 0),
            locations: isFromOrg || allClinics.length === 1 ? locations || [] : selectedProduct.locations,
            volUnit: value.general.volUnit,
            barcodes: value.general.barcodes?.map(current => current.value) || [],
            tags: value.general.tags?.map(current => current.id) || [],
            supplier: value.general.supplier,
            brand: value.general.brand,
            category: isFromOrg || allClinics.length === 1 ? value.general.category : selectedProduct?.category.id,
            subCategory:
                isFromOrg || allClinics.length === 1 ? value.general?.subCategory : selectedProduct?.subCategory?.id,
            tax: isFromOrg || allClinics.length === 1 ? value.pricingStock.tax.id : selectedProduct.tax,
            grossPrice: isFromOrg || allClinics.length === 1 ? String(grossPrice) : selectedProduct.netPrice,
            netPrice: isFromOrg || allClinics.length === 1 ? String(netPrice) : selectedProduct.grossPrice,
            initialStock: 0,
            allowCommission: value.allowCommission,
            autoStockControl: value?.pricingStock?.allowControl,
            allowNegative: value?.pricingStock?.allowNegative
        };
        const defaultLocData = data.locations.map(loc => {
            loc.tax = data.tax;
            loc.grossPrice = data.grossPrice;
            loc.netPrice = data.netPrice;
            loc.min = 1;
            loc.max = 5;
            loc.shouldLast = 5;
            loc.category = data.category;
            loc.brand = data.brand;
            loc.supplier = data.supplier;
            return loc;
        });

        if (isFromOrg || allClinics.length === 1) {
            delete data.min;
            delete data.max;
            delete data.shouldLast;
        }
        if (!selectedProduct) {
            data.locations = defaultLocData;
            ProductApi.createProduct({ ...data, isActive: true })
                .then(() => {
                    toastr.success('Product created!');
                    closeModal();
                })
                .catch(err => {
                    if (typeof err === 'object') {
                        if (err.data && err.data.message) {
                            toastr.error(err.data.message);
                            return;
                        }
                    }
                    toastr.error('Oops', 'Something went wrong');
                });
        } else {
            if (!isFromOrg || allClinics.length === 1) {
                // data.brand = selectedProduct.brand?.id ? selectedProduct.brand.id : selectedProduct.brand;
                // data.supplier = selectedProduct.supplier?.id ? selectedProduct.supplier.id : selectedProduct.supplier;
                data.allowCommission = selectedProduct.allowCommission;
                data.locations = data.locations.map(loc => {
                    if (loc.clinic === clinic) {
                        loc.tax = value.location.pricingStock.tax.id;
                        loc.shouldLast = value.location.pricingStock.shouldLast;
                        loc.min = parseInt(value.location.pricingStock.minQuantity);
                        loc.max = parseInt(value.location.pricingStock.maxQuantity);
                        loc.netPrice = netPrice;
                        loc.grossPrice = grossPrice;
                        loc.category = value.general.category;
                        loc.subCategory = value.general.subCategory;
                        loc.brand = value.general.brand;
                        loc.supplier = value.general.supplier;
                        loc.allowCommission = value.allowCommission;
                        return loc;
                    } else {
                        return loc;
                    }
                });
            } else {
                data.locations = defaultLocData;
            }
            ProductApi.updateProduct(selectedProduct.id, data, clinic, isFromOrg || allClinics.length === 1)
                .then(() => {
                    toastr.success('Product updated!');
                    closeModal();
                })
                .catch(err => {
                    if (typeof err === 'object') {
                        if (err.data && err.data.message) {
                            toastr.error(err.data.message);
                            return;
                        }
                    }
                    toastr.error('Oops', 'Something went wrong');
                });
        }
    };

    const panelProps = {
        register,
        categories,
        subcategories,
        brands,
        clinics,
        clinic,
        isFromOrg,
        suppliers,
        control,
        selectedItem: selectedProduct,
        selectedProduct: selectedProduct,
        setValue,
        getValues,
        watch,
        allClinics
    };

    useEffect(() => {
        async function queryApi() {
            try {
                const subcategories = await ProductApi.getSubcategories(clinic, isFromOrg || allClinics.length === 1);
                const suppliers = await ProductApi.getSuppliers(clinic, isFromOrg || allClinics.length === 1);
                const brands = await ProductApi.getBrands(clinic, isFromOrg || allClinics.length === 1);
                setSubcategories(subcategories);
                setSuppliers(suppliers);
                if (selectedProduct?.brand?.id && !brands.some(el => el.id === selectedProduct.brand.id)) {
                    brands.push({ ...selectedProduct.brand });
                }
                setBrands(brands);
            } catch (err) {
                return toastr.error(err?.data?.message || 'Something went wrong');
            }
        }

        queryApi();
        //eslint-disable-next-line
    }, []);

    const formSubmit = (() => {
        if (!isMobile) {
            return {
                form: {
                    onSubmit: e => {
                        e.preventDefault();
                        handleSubmit(save)();
                    }
                },
                saveButton: {}
            };
        }
        return {
            form: {},
            saveButton: {
                onClick: e => {
                    e.preventDefault();
                    handleSubmit(save)();
                }
            }
        };
    })();

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setModalTitle('');
        setModalContent();
        setModalContinue(null);
        setModalCancel(null);
    };

    return (
        <>
            <Modal
                id={'product-form-modal'}
                isOpen={isVisible}
                onCancel={() => closeModal()}
                title={!selectedProduct?.id ? 'New product' : `View/Edit ${selectedProduct.name} product`}
                onClose={() => closeModal()}
            >
                <form ref={formRef} {...formSubmit.form} className={classes.accordionsForm}>
                    <Accordion title={'General'}>
                        <ProductFormGeneral volumeUnitRef={volumeUnitRef} {...panelProps} key="general" />
                    </Accordion>
                    <Accordion title={'Locations'} hideItem={!isFromOrg}>
                        {' '}
                        <LocationTab {...panelProps} key="location" />{' '}
                    </Accordion>
                    <Accordion title={isFromOrg || allClinics.length === 1 ? 'Price' : 'Price & Stock'}>
                        <ProductFormPricingStock
                            taxes={productTaxes}
                            netPrice={netPrice}
                            setNetPrice={setNetPrice}
                            grossPrice={grossPrice}
                            setGrossPrice={setGrossPrice}
                            {...panelProps}
                            key="pricingStock"
                        />
                    </Accordion>

                    <Accordion title={'Commissions'}>
                        <CommissionTab {...panelProps} key="commissions" />
                    </Accordion>

                    <div className={globalStyles.buttonsContainer}>
                        <Button className={globalStyles.cancelButton} onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button className={globalStyles.confirmButton} type="submit" {...formSubmit.saveButton}>
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>
            {confirmModal && (
                <ConfirmModal
                    isOpen
                    setIsOpen={closeConfirmModal}
                    onConfirm={modalOnContinue}
                    title={modalTitle}
                    content={modalContent}
                    centerContent
                    continueText={modalContinue}
                    cancelText={modalCancel}
                />
            )}
        </>
    );
}

ProductFormModal.propTypes = {
    classes: PropTypes.object.isRequired,
    isVisible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    categories: PropTypes.array.isRequired,
    selectedProduct: PropTypes.any
};

export default withStyles(productsFormStyles)(ProductFormModal);
