import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    withStyles
} from '@material-ui/core';
import { cloneDeep } from 'lodash';
import CommissionCategoryInput from '../../collums-components/form/CommissionCategoryInput';
import { AMOUNT_FEE_TYPE, COMMISSION_RANGE_TYPES, CURRENCY_TYPES } from '../../collums-constants';
import v4 from 'uuid/v4';
import { toastr } from 'react-redux-toastr';
import CurrencyInput from '../../collums-components/form/CurrencyInput';
import RateTypeInput from '../../collums-components/form/RateTypeInput';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { commissionSchemeStyles } from './styles';
import { toLocaleString } from '../../collums-components/helpers';
import Accordion from './Accordion';
import OrganisationsApi from '../../api/OrganisationsApi';

const CommissionsScheme = ({ classes, commissionFormRef, disabled, selectedCommission, serviceCategories }) => {
    const createDefaultCommission = () => ({ allowFlatRate: true, id: v4(), flatRateType: '%' });
    const [currencySymbol, setCurrencySymbol] = useState('£');

    const [organisationData, setOrganisationData] = useState(null);

    useEffect(() => {
        (async () => {
            const organisation = await OrganisationsApi.getOrganisation();
            setOrganisationData(organisation);
        })();
    }, []);

    useEffect(() => {
        if (organisationData?.currency) {
            const currency = CURRENCY_TYPES.find(type => type.NAME === organisationData.currency);
            if (currency) {
                setCurrencySymbol(currency.SYMBOL);
            }
        }
    }, [organisationData]);

    const [commissionRules, setCommissionRules] = useState(commissionFormRef.current.commissions || {});

    useEffect(() => {
        if (selectedCommission?.commissions) {
            setCommissionRules(selectedCommission.commissions);
        }
    }, [selectedCommission]);

    const warningTexts = useCallback(
        type => {
            if (type === 'total') {
                return (
                    <p className={classes.textWarning}>
                        If commission has been set in the other sections, Total commission will be in addition to that
                        commission.
                    </p>
                );
            }

            return null;
        },
        [classes.textWarning]
    );

    const RenderTitle = title => {
        return (
            {
                product: 'Products sold',
                service: 'Services performed (includes redemptions)',
                course: 'Courses sold',
                total: 'Total (all sales + services performed)'
            }[title] ?? ''
        );
    };

    const addCommissionRule = type => {
        const lowerCaseType = type.toLowerCase();
        commissionFormRef.current.commissions[lowerCaseType].push(createDefaultCommission());
        setCommissionRules(cloneDeep(commissionFormRef.current.commissions));
    };

    const removeCommissionRule = (index, type) => {
        const lowerCaseType = type.toLowerCase();
        commissionFormRef.current.commissions[lowerCaseType].splice(index, 1);
        setCommissionRules(cloneDeep(commissionFormRef.current.commissions));
    };

    const RangeTable = ({ type, index, onChange }) => {
        const getValue = () => {
            return commissionFormRef.current.commissions[type]?.[index]?.commissionRanges || [];
        };

        const [editingIndex, setEditingIndex] = useState(getValue().length === 1 ? 0 : undefined);
        const [tableValue, setTableValue] = useState(getValue());

        const updateValue = (newValue, updateState) => {
            if (updateState) setTableValue(newValue);
            onChange(newValue);
        };

        const addRow = useCallback(() => {
            const newRow = {};
            const value = getValue();

            if (tableValue && tableValue.length > 0) {
                const missingTo = (value || [])
                    .map(item => {
                        return !Object.keys(item).includes('to') ? true : false;
                    })
                    .filter(el => el);
                if (missingTo.length) {
                    toastr.warning("You need to fill 'To' field to insert a new layer");
                    return;
                }
                const prevTo = (value || [])[tableValue.length - 1].to.replace(/,/g, '');
                newRow.from = toLocaleString(
                    (parseFloat(parseFloat(prevTo.slice(1)).toFixed(2)) + 0.01).toFixed(2).toString()
                );
            } else {
                newRow.from = toLocaleString(0.0);
            }
            newRow.commission = {
                type: '%'
            };
            if (tableValue) {
                updateValue([...(value || []), newRow], true);
                setEditingIndex(tableValue.length);
            } else {
                updateValue([newRow], true);
                setEditingIndex(0);
            }
        }, [tableValue]); // eslint-disable-line

        const update = useCallback(
            property => (e, updateState = false) => {
                const value = getValue();
                const newRow = { ...(value || [])[editingIndex] };
                if (e.target.value) {
                    newRow[property] = e.target.value;
                }
                const newValue = [...(value || [])];
                newValue.splice(editingIndex, 1, newRow);
                updateValue(newValue, updateState);
            },
            [editingIndex, tableValue] // eslint-disable-line
        );

        const deleteRow = useCallback(
            index => {
                const value = getValue();
                const newValue = [...(value || [])];
                newValue.splice(index, 1);
                updateValue(newValue, true);
            },
            [tableValue] // eslint-disable-line
        );

        const memoizedTable = useMemo(
            () => (
                <>
                    <TableContainer style={{ marginTop: 10 }} component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>From</TableCell>
                                    <TableCell>To</TableCell>
                                    <TableCell>Commission</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableValue &&
                                    tableValue.map((row, index) =>
                                        index !== editingIndex ? (
                                            <TableRow key={index}>
                                                <TableCell>{row.from}</TableCell>
                                                <TableCell>{row.to}</TableCell>
                                                <TableCell>
                                                    {row.commission.value}
                                                    {
                                                        {
                                                            [AMOUNT_FEE_TYPE.PERCENTAGE]: '%',
                                                            [AMOUNT_FEE_TYPE.AMOUNT]: currencySymbol
                                                        }[row.commission.type]
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <EditIcon
                                                        onClick={() => {
                                                            if (disabled) return;
                                                            setEditingIndex(index);
                                                        }}
                                                    />
                                                    <DeleteIcon
                                                        onClick={() => {
                                                            if (disabled) return;
                                                            deleteRow(index);
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            <TableRow>
                                                <TableCell>
                                                    <CurrencyInput
                                                        CustomInput={props => (
                                                            <TextField {...props} disabled={disabled} />
                                                        )}
                                                        defaultValue={row.from}
                                                        onChange={e => update('from')(e, false)}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <CurrencyInput
                                                        CustomInput={props => (
                                                            <TextField {...props} disabled={disabled} />
                                                        )}
                                                        defaultValue={row.to}
                                                        onChange={e => update('to')(e, false)}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <RateTypeInput
                                                        classes={classes}
                                                        value={row.commission || 0}
                                                        disabled={disabled}
                                                        onChange={newValue =>
                                                            update('commission')({ target: { value: newValue } }, true)
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <DeleteIcon
                                                        onClick={() => {
                                                            if (disabled) return;
                                                            deleteRow(index);
                                                            setEditingIndex(null);
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {!disabled && (
                        <Button
                            onClick={addRow}
                            className={classes.commissionButton}
                            variant="contained"
                            color="primary"
                        >
                            Add commission layer
                        </Button>
                    )}
                </>
            ),
            [addRow, deleteRow, editingIndex, update, tableValue]
        );
        if (selectedCommission !== 'new' || tableValue?.length) {
            return memoizedTable;
        }

        if (disabled) return <></>;

        return (
            <Button onClick={addRow} className={classes.commissionButton} variant="contained" color="primary">
                Add commission layer
            </Button>
        );
    };

    const allowFlatRate = commissionFormRef.current.commissions?.service?.[0]?.allowFlatRate;

    const canShowAddRuleButton = !disabled && allowFlatRate;

    const canShowRemoveRuleButton = type => {
        return Array(commissionRules[type]?.length || 0).fill('-').length > 1 && !disabled;
    };

    const [includeCommissionForRedemptions, setIncludeCommissionForRedemptions] = useState(
        commissionFormRef.current.includeCommissionForRedemptions
    );

    return (
        <>
            <FormControlLabel
                label="Deduct commission on refund"
                control={
                    <Checkbox
                        disabled={disabled}
                        onChange={e => {
                            commissionFormRef.current.deductCommissionOnRefund = e.target.checked;
                        }}
                        defaultChecked={selectedCommission.deductCommissionOnRefund}
                    />
                }
            />
            <div className={classes.accordionsForm}>
                <div className={`${classes.formContentRow} ${classes.accordionTitle}`}>
                    <p>Commission schemes</p>
                    {COMMISSION_RANGE_TYPES.map(type => (
                        <Accordion title={RenderTitle(type)} key={type}>
                            {type.toLowerCase() === 'service' && (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            disabled={disabled}
                                            checked={includeCommissionForRedemptions}
                                            onClick={event => {
                                                commissionFormRef.current.includeCommissionForRedemptions =
                                                    event.target.checked;
                                                setIncludeCommissionForRedemptions(event.target.checked);
                                            }}
                                        />
                                    }
                                    label="Ex/Inc Redemptions"
                                />
                            )}
                            <div className={classes.commissionRuleContainer}>
                                <RadioGroup
                                    className={`${classes.formItem} ${classes.commissionTypes}`}
                                    defaultValue={
                                        commissionFormRef.current.commissions?.[type.toLowerCase()][0]?.allowFlatRate
                                            ? 'allowFlatRate'
                                            : 'tieredCommission'
                                    }
                                    onChange={e => {
                                        const value = e.target.value === 'allowFlatRate';
                                        const newProps = (() => {
                                            if (value) {
                                                return {
                                                    commissionRanges: [],
                                                    flatRateType: '%',
                                                    allowFlatRate: true,
                                                    categories: [],
                                                    allCategories: false
                                                };
                                            } else {
                                                return {
                                                    commissionRanges: [],
                                                    flatRateType: undefined,
                                                    flatRateValue: undefined,
                                                    allowFlatRate: false,
                                                    categories: [],
                                                    allCategories: true
                                                };
                                            }
                                        })();

                                        commissionFormRef.current.commissions[type.toLowerCase()] = [newProps];

                                        setCommissionRules(cloneDeep(commissionFormRef.current.commissions));
                                    }}
                                >
                                    <FormControlLabel
                                        value={'allowFlatRate'}
                                        control={<Radio disabled={disabled} />}
                                        label={'Flat rate commission'}
                                    />
                                    <FormControlLabel
                                        value={'tieredCommission'}
                                        control={<Radio disabled={disabled} />}
                                        label={'Tiered commission'}
                                    />
                                </RadioGroup>
                                {Boolean(commissionRules[type]?.length) &&
                                    Array(commissionRules[type].length)
                                        .fill('-')
                                        .map((_, index) => {
                                            const canShowCategoryList =
                                                RenderTitle(type) === 'Services performed (includes redemptions)' &&
                                                Boolean(allowFlatRate);
                                            return (
                                                <div
                                                    className={classes.commissionRuleRow}
                                                    key={`${commissionRules?.[type]?.[index]?.id}-${commissionRules?.[type]?.[index]?.allowFlatRate}`}
                                                >
                                                    <div style={{ width: '100%' }}>
                                                        {warningTexts(type)}
                                                        <div className={classes.tableContent}>
                                                            {canShowCategoryList && (
                                                                <div className={classes.commissionCatSelectorContainer}>
                                                                    <p>Apply to categories</p>
                                                                    <CommissionCategoryInput
                                                                        inputSize={'100%'}
                                                                        showInsideLabel
                                                                        options={serviceCategories}
                                                                        formRef={commissionFormRef}
                                                                        type={type}
                                                                        index={index}
                                                                        disabled={disabled}
                                                                    />
                                                                </div>
                                                            )}
                                                            {commissionRules?.[type]?.[index]?.allowFlatRate ? (
                                                                <div className={classes.flatRateInputs}>
                                                                    <FormControl
                                                                        variant="outlined"
                                                                        className={classes.formItem}
                                                                    >
                                                                        <InputLabel>Commission</InputLabel>

                                                                        <Select
                                                                            disabled={disabled}
                                                                            defaultValue={
                                                                                selectedCommission?.commissions?.[
                                                                                    type.toLowerCase()
                                                                                ]?.[index]?.flatRateType || '%'
                                                                            }
                                                                            onChange={event => {
                                                                                commissionFormRef.current.commissions[
                                                                                    type.toLowerCase()
                                                                                ][index].flatRateType =
                                                                                    event.target.value;
                                                                            }}
                                                                        >
                                                                            <MenuItem value="Amount">Amount</MenuItem>
                                                                            <MenuItem value="%">%</MenuItem>
                                                                        </Select>
                                                                    </FormControl>

                                                                    <FormControl
                                                                        variant="outlined"
                                                                        className={classes.formItem}
                                                                    >
                                                                        <TextField
                                                                            disabled={disabled}
                                                                            InputProps={
                                                                                commissionFormRef.current.commissions?.[
                                                                                    type
                                                                                ]?.flatRateType === 'Amount' && {
                                                                                    startAdornment: (
                                                                                        <InputAdornment position="start">
                                                                                            {currencySymbol}
                                                                                        </InputAdornment>
                                                                                    )
                                                                                }
                                                                            }
                                                                            type="number"
                                                                            variant="outlined"
                                                                            defaultValue={
                                                                                commissionFormRef.current.commissions?.[
                                                                                    type.toLowerCase()
                                                                                ]?.[index]?.flatRateValue
                                                                            }
                                                                            onChange={event => {
                                                                                commissionFormRef.current.commissions[
                                                                                    type.toLowerCase()
                                                                                ][index].flatRateValue =
                                                                                    event.target.value;
                                                                            }}
                                                                        />
                                                                    </FormControl>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <p>Commission layers</p>
                                                                    <RangeTable
                                                                        type={type}
                                                                        index={index}
                                                                        onChange={value => {
                                                                            commissionFormRef.current.commissions[
                                                                                type.toLowerCase()
                                                                            ][index].commissionRanges = value;
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {canShowRemoveRuleButton(type) && (
                                                        <Button
                                                            disabled={disabled}
                                                            onClick={() => removeCommissionRule(index, type)}
                                                            className={`${classes.commissionButton} ${classes.removeRuleButton}`}
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Remove commission rule
                                                        </Button>
                                                    )}
                                                    <Divider />
                                                </div>
                                            );
                                        })}
                            </div>
                            {canShowAddRuleButton && type.toLowerCase() === 'service' && (
                                <Button
                                    onClick={() => addCommissionRule(type)}
                                    className={classes.commissionButton}
                                    variant="contained"
                                    color="primary"
                                >
                                    Add commission rule for another category(s)
                                </Button>
                            )}
                        </Accordion>
                    ))}
                </div>
            </div>
        </>
    );
};

export default withStyles(commissionSchemeStyles)(CommissionsScheme);
