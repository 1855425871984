import React from 'react';

import { Switch, withStyles } from '@material-ui/core';
import { customSwitchStyles } from './styles';
import PropTypes from 'prop-types';

const CustomSwitch = props => (
    <Switch
        {...props}
        classes={{
            track: props.classes.switchTrack,
            switchBase: props.classes.switchBase
        }}
    />
);

CustomSwitch.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(customSwitchStyles)(CustomSwitch);
