export const VolumeUnitStyles = () => ({
    textFieldInputLabelProps: {
        marginTop: 6
    },
    textFieldOptions: {
        fontSize: 14
    },
    autocomplete: {
        marginRight: 20,
        width: 300,
        '& .MuiInputBase-root': {
            padding: '9.5px 4px',
            paddingRight: '25px !important'
        },
        '& .MuiAutocomplete-tag': {
            marginTop: 1
        }
    }
});
