import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    Checkbox,
    ListItemText,
    Divider,
    FormControlLabel
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import CurrencyInput from '../../common/CurrencyInput';
import { getLocationItem } from '../../../collums-constants/utils';

function PriceAndLocationTab({
    classes,
    register,
    clinics,
    control,
    watch,
    validationMessages,
    setValue,
    selectedCourse,
    courseTaxes,
    isFromOrg,
    clinic,
    allClinics
}) {
    const taxWatch = watch('tax');
    const [netPrice, setNetPrice] = useState(
        isFromOrg || allClinics.length === 1
            ? selectedCourse?.netPrice
            : getLocationItem(selectedCourse, clinic).netPrice
    );
    const [salePrice, setSalePrice] = useState(
        isFromOrg || allClinics.length === 1
            ? selectedCourse?.salePrice
            : getLocationItem(selectedCourse, clinic).salePrice
    );

    const listOptions =
        clinics &&
        clinics.map(clinic => {
            let isSelected = false;
            if (!selectedCourse?.id) {
                if (clinics.length === 1) {
                    isSelected = true;
                }
            }
            if (selectedCourse?.id && selectedCourse.locations) {
                isSelected = selectedCourse.locations.some(loc => loc.clinic === clinic.id);
            }
            return {
                value: clinic.id,
                label: clinic.accountName,
                subtitle: undefined,
                isSelected
            };
        });

    const calculate = (event, isNetPrice) => {
        let value = event.target.value;
        const tax = taxWatch?.rate || 0;

        //TODO: it should not be hardcoded
        value = value.replace(/[£$€]/g, '');
        value = value.replace(',', '');
        if (isNetPrice) {
            const newGrossValue = (parseFloat(value) * tax) / 100 + parseFloat(value);
            newGrossValue ? setSalePrice(newGrossValue) : setSalePrice(0);
            setNetPrice(value);
        } else {
            const newNetValue = parseFloat(value) - (parseFloat(value) * tax) / (100 + tax);
            newNetValue ? setNetPrice(Math.round(newNetValue * 100) / 100) : setNetPrice(0);
            setSalePrice(value);
        }
    };

    useEffect(() => {
        register('salePrice', salePrice);
        register('netPrice', netPrice);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const tax = taxWatch?.rate || 0;
        if (netPrice) {
            setSalePrice(parseFloat(netPrice) + (parseFloat(netPrice) * parseFloat(tax)) / 100);
        }
        // eslint-disable-next-line
    }, [taxWatch]);

    useEffect(() => {
        if (!netPrice && salePrice) {
            const tax = taxWatch?.rate || 0;
            setNetPrice(parseFloat(salePrice) - (parseFloat(salePrice) * tax) / (100 + tax));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setValue('salePrice', salePrice);
        setValue('netPrice', netPrice);
        // eslint-disable-next-line
    }, [netPrice, salePrice]);

    const getSelectedValues = (options, selectedLocations) => {
        return options.map(option => {
            const foundValue = selectedLocations.find(location => option.value === location.value);
            if (foundValue) return { ...option, isSelected: true };
            else return option;
        });
    };

    return (
        <>
            <div className={classes.formContent}>
                <div className={classes.formContentRow}>
                    <Typography className={classes.spanLocationLabel}>
                        This course will be available to book in the locations that offer this service.
                    </Typography>
                </div>
                <div className={classes.formContentRow}>
                    <FormControl className={classes.formItem} variant="outlined">
                        <InputLabel>Tax *</InputLabel>
                        <Controller
                            name="tax"
                            rules={register({ required: validationMessages.required })}
                            defaultValue={
                                isFromOrg || allClinics.length === 1
                                    ? selectedCourse.itemTax
                                    : getLocationItem(selectedCourse, clinic)?.itemTax
                            }
                            control={control}
                            render={({ value, onChange, ...props }) => (
                                <Select
                                    {...props}
                                    value={courseTaxes.find(tax => tax.name === value?.name)}
                                    onChange={e => {
                                        onChange(e.target.value);
                                    }}
                                >
                                    {courseTaxes &&
                                        courseTaxes.map(option => (
                                            <MenuItem key={option} value={option}>
                                                {option.rate}%
                                            </MenuItem>
                                        ))}
                                </Select>
                            )}
                        />
                    </FormControl>
                </div>
                <div className={classes.formContentRow}>
                    <FormControl className={classes.formItem}>
                        <InputLabel shrink required>
                            Net Price
                        </InputLabel>
                        <div style={{ height: 8 }} />
                        <CurrencyInput
                            disabled={taxWatch === undefined}
                            value={netPrice * 100}
                            CustomInput={TextField}
                            onChange={e => {
                                calculate(e, true);
                            }}
                        />
                    </FormControl>

                    <FormControl className={classes.formItem}>
                        <InputLabel shrink required>
                            Gross Price
                        </InputLabel>
                        <div style={{ height: 8 }} />
                        <CurrencyInput
                            disabled={taxWatch === undefined}
                            value={salePrice * 100}
                            CustomInput={TextField}
                            onChange={e => {
                                calculate(e, false);
                            }}
                        />
                    </FormControl>
                </div>
                <div
                    className={classes.formContentRow}
                    style={{ display: isFromOrg && allClinics.length > 1 ? '' : 'none' }}
                >
                    <div className={classes.locationsTitle}>
                        <b>{'Locations'}</b>
                        {clinics.length === 1 && (
                            <Tooltip
                                title={
                                    <Typography style={{ fontSize: 14 }}>
                                        Select the locations that can book this service.
                                    </Typography>
                                }
                                aria-label="help"
                            >
                                <HelpIcon style={{ fontSize: 18 }} />
                            </Tooltip>
                        )}
                    </div>
                    <Controller
                        name={'locations'}
                        control={control}
                        defaultValue={
                            selectedCourse.locations
                                ? getSelectedValues(listOptions, selectedCourse.locations)
                                : listOptions
                        }
                        rules={register}
                        render={({ onChange, value }) => {
                            const checkAll = (e, checked) => {
                                const newItems = [
                                    ...value.map(item => {
                                        item.isSelected = checked;
                                        return item;
                                    })
                                ];
                                onChange(newItems);
                            };

                            const handleToggle = async option => {
                                const currentIndex = ((value.length && value) || listOptions || []).findIndex(
                                    v => v.value === option.value
                                );
                                const newItems = value.length ? [...value] : [...listOptions];
                                let newItem;
                                if (value.length) {
                                    newItem = {
                                        ...value[currentIndex],
                                        isSelected: !value[currentIndex].isSelected
                                    };
                                } else {
                                    newItem = {
                                        ...listOptions[currentIndex],
                                        isSelected: !listOptions[currentIndex].isSelected
                                    };
                                }
                                newItems.splice(currentIndex, 1, newItem);
                                onChange(newItems);
                            };
                            return (
                                <>
                                    {isFromOrg ||
                                        (allClinics.length === 1 && value.length > 1 && (
                                            <div className={classes.locationFilter}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onChange={checkAll}
                                                            edge="start"
                                                            tabIndex={-1}
                                                            disableRipple
                                                            // checked={locations?.length && locations.filter(item => item.isSelected).length === locations.length}
                                                        />
                                                    }
                                                    label="Select all"
                                                />
                                            </div>
                                        ))}
                                    <List>
                                        {((value.length && value) || listOptions).map((option, index) => (
                                            <>
                                                <ListItem key={index} role={undefined} dense button>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            onClick={() => {
                                                                handleToggle(option);
                                                            }}
                                                            disabled={
                                                                listOptions?.length === 1 && listOptions[0].isSelected
                                                            }
                                                            edge="start"
                                                            checked={option.isSelected}
                                                            tabIndex={-1}
                                                            disableRipple
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary={option.label} secondary={option.subtitle} />
                                                </ListItem>
                                                <Divider />
                                            </>
                                        ))}
                                    </List>
                                </>
                            );
                        }}
                    />
                </div>
            </div>
        </>
    );
}

export default PriceAndLocationTab;
