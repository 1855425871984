import React from 'react';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiPaper from '@material-ui/core/Paper';
import { IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { modalStyles } from '../../collums-constants/styles/stylesheets/modalsStyles';

const styles = {
    closeButton: {
        position: 'absolute',
        right: 6,
        top: 6,
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 20
    },
    violetButton: {
        backgroundColor: '#b41778',
        color: 'white',
        border: '0px solid #000',
        '&:hover': {
            backgroundColor: '#912d6b'
        }
    }
};

const DialogFullHeight = withStyles(() => ({
    paper: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        height: '100%'
    }
}))(MuiDialog);

function PaperDraggable(props) {
    return (
        <Draggable handle={`#${'service-details'}`} cancel={'[class*="MuiDialogContent-root"]'}>
            <MuiPaper {...props} />
        </Draggable>
    );
}

class Modal extends React.Component {
    renderActions() {
        const buttons = [];
        if (this.props.onConfirm) {
            buttons.push(
                <Button
                    key="confirm"
                    onClick={e => {
                        e.preventDefault();
                        this.props.onConfirm();
                    }}
                    color="primary"
                    className={this.props.confirmButtonColored ? this.props.classes.violetButton : ''}
                >
                    {this.props.confirmLabel || 'Confirm'}
                </Button>
            );
        }

        buttons.push(...(this.props.actions || []));
        return buttons;
    }

    render() {
        const { classes } = this.props;
        const Dialog = this.props.fullHeight ? DialogFullHeight : MuiDialog;
        const Paper = this.props.draggable ? PaperDraggable : MuiPaper;
        return (
            <Dialog
                classes={{ root: `${classes.modalContainer} ${this.props.dialogClass}` }}
                fullWidth
                maxWidth={this.props.size || 'md'}
                open={this.props.isOpen}
                onClose={this.props.onCancel}
                aria-labelledby={this.props.id}
                PaperComponent={Paper}
                disableBackdropClick={this.props.disableBackdropClick ? this.props.disableBackdropClick : false}
            >
                <DialogTitle className={classes.title} id={this.props.id} disableTypography>
                    <Typography variant="h4">{this.props.title}</Typography>
                    {this.props.hideCloseIcon !== true && (
                        <IconButton className={classes.closeButton} onClick={this.props.onClose}>
                            <Close />
                        </IconButton>
                    )}
                </DialogTitle>
                <DialogContent>{this.props.children}</DialogContent>
                <DialogActions>{this.renderActions()}</DialogActions>
            </Dialog>
        );
    }
}

Modal.propTypes = {
    id: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    cancelLabel: PropTypes.string,
    confirmLabel: PropTypes.string,
    fullHeight: PropTypes.bool,
    draggable: PropTypes.bool,
    dialogClass: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    children: PropTypes.node.isRequired,
    actions: PropTypes.array,
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    confirmButtonColored: PropTypes.bool
};

export default withStyles(theme => ({ ...styles, ...modalStyles(theme) }))(Modal);
