import api from '../collums-components/api/config';

class StripeApi {
    static getOnboardingLink() {
        return api.request({
            method: 'GET',
            url: '/stripe/onboarding'
        });
    }

    static getAccount() {
        return api.request({
            method: 'GET',
            url: '/stripe/me'
        });
    }

    static getDashboardLink() {
        return api.request({
            method: 'GET',
            url: '/stripe/dashboard-link'
        });
    }

    static revokeAccess() {
        return api.request({
            method: 'GET',
            url: '/stripe/revoke'
        });
    }

    static terminalList() {
        return api.request({
            method: 'GET',
            url: '/stripe/devices'
        });
    }

    static removeDevice(deviceId) {
        return api.request({
            method: 'DELETE',
            url: '/stripe/delete-terminal-device',
            data: { deviceId }
        });
    }

    static createDevice({ code, label }) {
        return api.request({
            method: 'POST',
            url: '/stripe/create-terminal-device',
            data: { code, label }
        });
    }
}

export default StripeApi;
