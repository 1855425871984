import React, { useEffect, useState } from 'react';
import { Grid, Typography, withStyles, TextField } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import { adminMenuStyles } from './styles';

import organisationTabs from '../header/tabsReferences/organisationTabs';
import resourcesTabs from '../header/tabsReferences/resourcesTabs';

import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import AuthApi from '../../collums-components/api/AuthApi';
import { toastr } from 'react-redux-toastr';
import { ROLES } from '../../collums-constants';
import locationTabs from '../header/tabsReferences/locationTabs';
import { useDispatch, useSelector } from 'react-redux';
import { loadOrganisation } from '../../actions/organisationActions';
import { listClinics } from '../../collums-components/api/ClinicApi';
import OrganisationsApi from '../../api/OrganisationsApi';
import api from '../../collums-components/api/config';

const MenuOption = ({ classes, option }) => {
    const links = option.links;

    return (
        <Grid item className={classes.menuOption}>
            <Typography variant="h3" style={{ textAlign: 'center' }}>
                {option.name}
            </Typography>
            <Grid container spacing={0} className={classes.menuOptionContainer}>
                {links.length >= 5 ? (
                    <>
                        <Grid item md={6} className={classes.menuOptionItem}>
                            {/* ---- Check if is a odd number of options to add the top of next column at the bottom*/}
                            {links
                                .slice(0, links.length % 2 !== 0 ? (links.length / 2).toFixed(0) : links.length / 2)
                                .map(link =>
                                    link?.locked ? (
                                        <Typography variant="span" className={classes.linkLocked}>
                                            {link.name}
                                        </Typography>
                                    ) : (
                                        <Link key={link.name} to={link.ref} className={classes.link}>
                                            {link.name}
                                        </Link>
                                    )
                                )}
                        </Grid>
                        <Grid item md={6} className={classes.menuOptionItem}>
                            {/* ---- Check if is a odd number of options to remove the first one of the column */}
                            {links.slice(links.length % 2 !== 0 ? links.length / 2 + 1 : links.length / 2).map(link =>
                                link?.locked ? (
                                    <Typography variant="span" className={classes.linkLocked}>
                                        {link.name}
                                    </Typography>
                                ) : (
                                    <Link key={link.name} to={link.ref} className={classes.link}>
                                        {link.name}
                                    </Link>
                                )
                            )}
                        </Grid>
                    </>
                ) : (
                    <Grid item md={12} className={classes.menuOptionItem}>
                        {links.map(link =>
                            link?.locked ? (
                                <Typography variant="span" className={classes.linkLocked}>
                                    {link.name}
                                </Typography>
                            ) : (
                                <Link
                                    key={link.name}
                                    to={link.ref}
                                    className={`${classes.link} ${link?.locked ? 'locked' : ''}`}
                                >
                                    {link.name}
                                </Link>
                            )
                        )}
                    </Grid>
                )}
            </Grid>
            {!links.length && option.name === 'Location Settings' && <p>No locations available.</p>}
        </Grid>
    );
};

const AdminMenu = ({ classes }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showSpecialPanel, setShowSpecialPanel] = useState(false);
    const [clinics, setClinics] = useState([]);
    const organisation = useSelector(state => state.organisation);
    const [selectedClinic, setSelectedClinic] = useState(organisation.clinic);
    const [organisationData, setOrganisationData] = useState(null);

    useEffect(() => {
        getCurrentUserClincs();
    }, []); //eslint-disable-line

    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            const organisation = await OrganisationsApi.getOrganisation();
            setOrganisationData(organisation);
        })();
    }, []);

    async function getCurrentUserClincs() {
        try {
            setIsLoading(true);
            const currentUser = await AuthApi.getMe();
            if (currentUser.email.address === 'developer@collums.co.uk') {
                setShowSpecialPanel(true);
            }
            const isPractitionerAdmin = currentUser.role === ROLES.ADMIN || currentUser.job.name === ROLES.ADMIN;
            const practitionerClinics = await listClinics();
            const clinics = (practitionerClinics || []).map(clinic => ({
                id: clinic.id,
                name: clinic.accountName
            }));
            const clinicOptions =
                isPractitionerAdmin || clinics.length === 1
                    ? [{ id: 'organisation', name: 'Organisation' }, ...clinics]
                    : clinics;
            setClinics(clinicOptions);
            setSelectedClinic(organisation.clinic || clinicOptions[0]);
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    }

    const clinicLocationTabs = (clinicId, isSingleClinic = false) => {
        const choosenClinicId = clinicId || selectedClinic?.id;
        return locationTabs.map(tab => {
            if (isSingleClinic && ['Staff', 'Alerts', 'Discounts'].includes(tab.name)) {
                return false;
            }
            return {
                ...tab,
                ref: choosenClinicId ? tab.ref.replace('/location', `/location/${choosenClinicId}`) : tab.ref
            };
        });
    };

    const organisationMenu = singleClinicId => {
        if (!organisationData) {
            return [];
        }

        const menu = [];
        organisationTabs.map(tab => {
            if (
                (tab.ref === '/organisation/stripe' && !organisationData?.stripeCheckoutActive) ||
                (tab.ref === '/organisation/square' && organisationData.stripeAccountId)
            ) {
                return false;
            }

            if (singleClinicId) {
                if (tab.ref === '/organisation/alerts') {
                    tab.ref = `/location/${singleClinicId}/alerts`;
                }
            }

            menu.push(tab);
            return true;
        });
        return menu;
    };

    const options = [];

    if (clinics.length > 2) {
        options.push(
            {
                name: `${selectedClinic?.name || ''} Resources`,
                links: resourcesTabs(selectedClinic?.id)
            },
            {
                name: `${selectedClinic?.name || ''} Settings`,
                links: clinicLocationTabs()
            }
        );
        if (!selectedClinic || selectedClinic?.name === 'Organisation') {
            options.pop();
            if (selectedClinic?.name !== 'Organisation') {
                options.pop();
            }
        }

        if (selectedClinic?.name === 'Organisation') {
            options.push({
                name: 'Organisation Settings',
                links: organisationMenu()
            });
        }
    } else {
        let singleClinicId = null;
        // eslint-disable-next-line
        clinics.map(clinic => {
            if (clinic?.name !== 'Organisation') {
                options.push(
                    {
                        name: `${clinic?.name || ''} Resources`,
                        links: resourcesTabs(clinic?.id, clinics.length === 2)
                    },
                    {
                        name: `${clinic?.name || ''} Settings`,
                        links: clinicLocationTabs(clinic?.id, true)
                    }
                );
                singleClinicId = clinic?.id;
            }
        });
        options.push({
            name: 'Organisation Settings',
            links: organisationMenu(singleClinicId)
        });
    }

    const _filterOptions = createFilterOptions();

    const filterOptions = (options, state) => {
        const results = _filterOptions(options, state);
        if (clinics?.[0]?.name === 'Organisation') {
            if (!results.includes(clinics[0])) {
                results.unshift(clinics[0]);
            }
        }

        return results;
    };

    return (
        <>
            <div className={classes.headerContainer}>
                <Typography variant="h2" className={classes.adminTitle}>
                    {selectedClinic?.name} Admin Menu
                </Typography>
                {clinics.length > 2 && (
                    <Autocomplete
                        className={`${classes.clinicSelector} ${classes.font}`}
                        fullWidth
                        autoComplete
                        autoHighlight
                        margin="dense"
                        // value={selectedClinic && selectedClinic.name} --- insert default selected
                        defaultValue={selectedClinic && selectedClinic}
                        options={clinics}
                        getOptionLabel={opt => opt.name}
                        onChange={(e, value) => {
                            setSelectedClinic(value);
                            dispatch(loadOrganisation({ ...organisation, clinic: value }));
                        }}
                        filterOptions={filterOptions}
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant={'outlined'}
                                fullWidth
                                className={`${classes.autocomplete} ${classes.clinicOptions} ${classes.font} ${classes.selectLocation} locationPlaceholder`}
                                placeholder={'Select a Location'}
                            />
                        )}
                    />
                )}
            </div>
            <Grid
                container
                direction="row"
                justify="space-evenly"
                alignItems="center"
                spacing={3}
                className={classes.adminMenuContainer}
            >
                {options.map((option, index) => (
                    <MenuOption key={index} option={option} classes={classes} />
                ))}
                {showSpecialPanel && <SpecialPanel classes={classes} />}
            </Grid>
            {isLoading && <LoadingScreen />}
        </>
    );
};

const SpecialPanel = ({ classes }) => {
    return (
        <Grid item className={classes.menuOption}>
            <Typography variant="h3" style={{ textAlign: 'center' }}>
                Special Panel
            </Typography>
            <Grid container spacing={0} className={classes.menuOptionContainer}>
                <Link
                    key="productstock"
                    className={classes.link}
                    onClick={() => {
                        return api.request({
                            method: 'GET',
                            url: '/onerunjobs/productstock'
                        });
                    }}
                >
                    Recount product stock
                </Link>
                <Link
                    key="paymentlink-notification"
                    className={classes.link}
                    onClick={() => {
                        return api.request({
                            method: 'GET',
                            url: '/onerunjobs/paymentlink-notification'
                        });
                    }}
                >
                    Payment link notification
                </Link>
            </Grid>
        </Grid>
    );
};

export default withStyles(adminMenuStyles)(AdminMenu);
