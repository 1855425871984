import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

import DetailsFormModal from '../common/DetailsFormModal';
import PractitionerApi from '../../api/PractitionerApi';
import { generalForm, userDetailsForm } from './practitionerDetailsForm';
import { dynamicOptions } from '../../services/formHelper';
import { practitionerDetailsModalStyles } from './styles';
import { toastr } from 'react-redux-toastr';

const positionOptionsMap = {
    doctor: [
        { label: 'Senior consultant', value: 'seniorConsultant' },
        { label: 'Surgeon', value: 'surgeon' },
        { label: 'Injector', value: 'injector' }
    ],
    nurse: [
        { label: 'Senior nurse', value: 'seniorNurse' },
        { label: 'Nurse prescriber', value: 'nursePrescriber' }
    ],
    therapist: [
        { label: 'Senior therapist', value: 'seniorTherapist' },
        { label: 'Junior therapist', value: 'juniorTherapist' },
        { label: 'facialist', value: 'facialist' }
    ]
};

const sameProperties = [
    'id',
    'password',
    'active',
    'employeeCode',
    'customTitle',
    'firstName',
    'middleName',
    'lastName',
    'previousName',
    'displayName',
    'gender',
    'phone',
    'email',
    'birthdate',
    'address1',
    'address2',
    'city',
    'county',
    'country',
    'postal'
];

const PractitionerDetailsModal = props => {
    const [initialValue, setInitialValue] = useState({});
    const [formValue, setFormValue] = useState({});

    useEffect(() => {
        const practitioner = props.practitioner || {};
        setInitialValue({
            ..._.pick(props.practitioner, sameProperties),
            active: true,
            employeeCode: 'W80XQRIO',
            title: generalForm
                .find(field => 'title' === field.id)
                .options.find(option => practitioner.title === option.value),
            emailPractitioner: practitioner.email
        });
    }, [props.practitioner]);

    useEffect(() => {
        const objIndex = generalForm.findIndex(obj => obj.id === 'country');
        generalForm[objIndex].options = props.countries;
    }, [props.countries]);

    const mapGeneralForm = () => {
        const value = _.assign({}, initialValue, formValue);
        return generalForm.map(field => {
            switch (field.id) {
                case 'position': {
                    const positionCategoryValue = _.get(value, 'positionCategory.value');
                    return dynamicOptions(field, positionOptionsMap[positionCategoryValue] || []);
                }
                default:
                    return field;
            }
        });
    };

    const mapUserDetailsForm = () => {
        let userDetailsFormCopy = userDetailsForm;
        if ('new' !== props.practitionerId) {
            const hiddenFields = ['email', 'password', 'confirmPassword'];
            userDetailsFormCopy = userDetailsFormCopy.map(field => ({
                ...field,
                visibleWhen: [
                    hiddenFields.find(fieldId => fieldId === field.id) ? { field: 'changeCredentials', is: [true] } : {}
                ]
            }));
            userDetailsFormCopy.unshift({
                id: 'changeCredentials',
                name: 'changeCredentials',
                type: 'checkbox',
                label: 'Change login data'
            });
        }
        return userDetailsFormCopy;
    };

    const confirm = async () => {
        const value = _.assign({}, initialValue, formValue);

        if (!props.practitionerId) {
            await PractitionerApi.create(value);
            toastr.success('Employee created successfully');
        } else {
            await PractitionerApi.update(props.practitionerId, value);
            toastr.success('Employee updated successfully');
        }
        // Clear the current state and reset form
        props.onCancel();
        props.onConfirm();
        await props.refreshList();
    };

    if (!props.practitionerId) {
        return null;
    }

    return (
        <DetailsFormModal
            id="practitioner-details"
            title="Employee Details"
            sections={[
                {
                    displayName: 'General',
                    form: mapGeneralForm(),
                    splitIndex: 27
                },
                {
                    displayName: 'User Details',
                    form: mapUserDetailsForm(),
                    splitIndex: 6
                }
            ]}
            initialValue={initialValue}
            isInitiallyValid={'new' !== props.practitionerId}
            onConfirm={confirm}
            onCancel={props.onCancel}
            onChange={value => setFormValue(value)}
        />
    );
};

PractitionerDetailsModal.propTypes = {
    practitionerId: PropTypes.string,
    practitioner: PropTypes.object,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(practitionerDetailsModalStyles)(PractitionerDetailsModal));
