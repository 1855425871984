import * as React from 'react';
import { Typography } from '@material-ui/core';

import TextInput from './TextInput';
import NumberInput from './NumberInput';
import SelectDropdown from './SelectDropdown';
import SingleCheckbox from './SingleCheckbox';
import SingleAutocomplete from './SingleAutocomplete';
import MultipleAutocomplete from './MultipleAutocomplete';
import DurationAutocomplete from './DurationAutocomplete';
import RowEditor from './RowEditor';
import UnitAndValue from './UnitAndValue';
import ColorPicker from '../common/ColorPicker';
import _ from 'lodash';

const renderer = (field, onChange) => {
    switch (field.type) {
        case 'text':
            return <TextInput key={field.id} field={field} onChange={onChange} />;
        case 'password':
            return <TextInput key={field.id} field={field} type="password" onChange={onChange} />;
        case 'number':
            return <NumberInput key={field.id} field={field} onChange={onChange} />;
        case 'select':
            return <SelectDropdown key={field.id} field={field} onChange={onChange} />;
        case 'checkbox':
            return <SingleCheckbox key={field.id} field={field} onChange={onChange} />;
        case 'autocomplete':
            if (field.multiple) {
                return <MultipleAutocomplete key={field.id} field={field} onChange={onChange} />;
            } else {
                return <SingleAutocomplete key={field.id} field={field} onChange={onChange} />;
            }
        case 'duration':
            return <DurationAutocomplete key={field.id} field={field} onChange={onChange} />;
        case 'rowEditor':
            return <RowEditor key={field.id} field={field} onChange={onChange} />;
        case 'unitAndValue':
            return <UnitAndValue key={field.id} field={field} onChange={onChange} />;
        case 'color':
            return (
                <ColorPicker
                    key={field.id}
                    // Getting field props except for the value
                    props={_.omit(field, 'value')}
                    onChange={value => {
                        onChange(field.id, value);
                    }}
                />
            );
        default:
            return (
                <div key={field.id}>
                    <Typography>
                        No renderer for field {field.id} of type {field.type}
                    </Typography>
                </div>
            );
    }
};

export default renderer;
