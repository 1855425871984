import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Header from '../header/Header';
import CoursesView from '../courses/CoursesView';
import CategoriesCoursesView from '../coursesCategories/CourseCategoriesView';
import ProductCategoryView from '../productCategory/ProductCategoryView';
import CategoriesView from '../categories/CategoriesView';
import ProductsView from '../products/ProductsView';
import ServicesView from '../services/ServicesView';
import SuppliersView from '../suppliers/SuppliersView';
import TagsView from '../tags/TagsView';
import MergeClients from '../mergeClients/';
import SubHeaderPlaceholder from '../common/SubHeaderPlaceholder';
import { CURRENT_CLINIC } from '../../collums-constants/storageKeys';
import { listClinics, getClinic } from '../../collums-components/api/ClinicApi';

import SubHeader from '../header/subHeader/SubHeader';
import { resourcesStyles } from './styles';
import resourcesTabs from '../header/tabsReferences/resourcesTabs';
import ProductBrandView from './../productBrand/index';

const PlaceholderView = tabName => (
    <div>
        <h3>resources {tabName}</h3>
    </div>
);

const ResourceView = ({ classes, ...props }) => {
    const [currentClinic, setCurrentClinic] = useState();
    const currentTab = props.match.params.tab;
    const currentLoc = props.match.params.clinic ? props.match.params.clinic : localStorage.getItem(CURRENT_CLINIC);
    const [selectedSubTab, setSelectedSubTab] = useState('');
    const [clinics, setClinics] = useState([]);
    const [allClinics, setAllClinics] = useState([]);
    const isFromOrg = currentLoc === 'organisation';

    useEffect(() => {
        getClinics();
    }, [isFromOrg, currentLoc]); //eslint-disable-line

    const getClinics = useCallback(async () => {
        setAllClinics(await listClinics());
        setClinics(isFromOrg ? await listClinics() : [await getClinic(currentLoc)]);
    }, [isFromOrg, currentLoc]); //eslint-disable-line
    /* when location or match changes, redifines the current subtab */
    useEffect(() => {
        if (props.match.params.subTab === undefined) {
            if (
                currentTab === 'services' ||
                currentTab === 'courses' ||
                currentTab === 'products' ||
                currentTab === 'staff'
            ) {
                setSelectedSubTab(currentTab);
            } else {
                setSelectedSubTab('');
            }
        } else {
            setSelectedSubTab(props.match.params.subTab);
        }
        const fetchClinic = async location => {
            if (location === 'organisation') {
                setCurrentClinic('organisation');
            } else {
                const clinic = await getClinic(location);
                setCurrentClinic(clinic);
            }
        };
        fetchClinic(currentLoc);
    }, [props.match.params.subTab, currentTab, currentLoc]);

    /* sub tabs defined on the end of file */
    const subTabs = useMemo(() => {
        const selectSubTabs = currentTab => {
            if (currentTab === 'courses') {
                return coursesSubTab;
            } else if (currentTab === 'products') {
                return productsSubTab;
            } else if (currentTab === 'services') {
                return servicesSubTab;
            } else {
                return [];
            }
        };
        return selectSubTabs(currentTab);
    }, [currentTab]);

    /* seeks tabName, as the reference(currentTab) sometimes isn't equal */
    function tabName() {
        const tab = resourcesTabs(currentLoc).filter(tab => {
            const refNames = tab.ref.split('/');
            return refNames[refNames.length - 1] === currentTab;
        });
        return tab[0]?.name;
    }

    function subTabName() {
        const subTab = subTabs.filter(tab => tab.value === selectedSubTab);
        return subTab[0]?.label;
    }

    function renderComponent() {
        switch (currentTab) {
            case 'merge-clients':
                return <MergeClients isFromOrg={isFromOrg} clinic={currentLoc} />;
            case 'courses':
                if (selectedSubTab === 'courses') {
                    return (
                        <CoursesView
                            isFromOrg={isFromOrg}
                            clinics={clinics}
                            clinic={currentLoc}
                            allClinics={allClinics}
                        />
                    );
                } else {
                    // categories
                    return (
                        <CategoriesCoursesView
                            isFromOrg={isFromOrg}
                            clinics={clinics}
                            clinic={currentLoc}
                            allClinics={allClinics}
                        />
                    );
                }
            case 'locations':
                return PlaceholderView(currentTab);
            case 'products':
                if (selectedSubTab === 'products') {
                    return (
                        <ProductsView
                            isFromOrg={isFromOrg}
                            clinics={clinics}
                            clinic={currentLoc}
                            allClinics={allClinics}
                        />
                    );
                } else if (selectedSubTab === 'categories') {
                    return (
                        <ProductCategoryView
                            isFromOrg={isFromOrg}
                            clinics={clinics}
                            clinic={currentLoc}
                            allClinics={allClinics}
                        />
                    );
                } else {
                    return (
                        <ProductBrandView
                            isFromOrg={isFromOrg}
                            clinics={clinics}
                            clinic={currentLoc}
                            allClinics={allClinics}
                        />
                    );
                }
            case 'reasons':
                return PlaceholderView(currentTab);
            case 'services':
                if (selectedSubTab === 'services') {
                    return (
                        <ServicesView
                            isFromOrg={isFromOrg}
                            clinics={clinics}
                            allClinics={allClinics}
                            clinic={currentLoc}
                        />
                    );
                } else {
                    return <CategoriesView isFromOrg={isFromOrg} clinic={currentLoc} allClinics={allClinics} />;
                }
            case 'suppliers':
                return (
                    <SuppliersView
                        isFromOrg={isFromOrg}
                        clinics={clinics}
                        clinic={currentLoc}
                        allClinics={allClinics}
                    />
                );
            case 'tags':
                return <TagsView isFromOrg={isFromOrg} clinic={currentLoc} />;
            default:
            // should not get here. TODO: wrong route component
            //  return PlaceholderView(currentTab);
        }
    }

    // returns a boolean to show or not SubHeader
    function showSubHeader() {
        return (
            currentTab === 'services' || currentTab === 'courses' || currentTab === 'products' || currentTab === 'staff'
        );
    }

    return (
        <>
            <Header currentClinic={currentLoc} />
            {showSubHeader() ? (
                <SubHeader
                    tabs={subTabs}
                    currentSubTab={selectedSubTab}
                    currentTab={currentTab}
                    path={`resources/location/${currentLoc}`}
                />
            ) : (
                <SubHeaderPlaceholder />
            )}

            <div style={{ marginTop: 10, marginLeft: 10 }}>
                <Link className={classes.link} to="/">
                    Admin home
                </Link>
            </div>
            <Typography variant="h4" className={classes.title}>
                {currentClinic?.accountName} Resources - {selectedSubTab !== '' ? subTabName() : tabName()}
            </Typography>
            {clinics.length && renderComponent()}
        </>
    );
};

export default withStyles(resourcesStyles)(ResourceView);

const coursesSubTab = [
    { label: 'Courses', value: 'courses' },
    { label: 'Course Categories', value: 'categories' }
];

const productsSubTab = [
    { label: 'Products', value: 'products' },
    { label: 'Categories', value: 'categories' },
    { label: 'Brands', value: 'brands' }
];

const servicesSubTab = [
    { label: 'Services', value: 'services' },
    { label: 'Service Categories', value: 'categories' }
];
