import PropTypes from 'prop-types';
import { Button, withStyles, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import PractitionerApi from '../../api/PractitionerApi';
import RoomApi from '../../api/RoomApi';
import Modal from '../common/Modal';
import Accordion from '../common/Accordion';
import Staff from './tabs/Staff';
import GeneralTab from './tabs/GeneralTab';
import TimingTab from './tabs/TimingTab';
import PricingTab from './tabs/PricingTab';
import LocationTab from './tabs/LocationTab';
import RoomEquipmentTab from './tabs/RoomEquipmentTab';
import OnlineBookingTab from './tabs/OnlineBookingTab';
import CommissionTab from './tabs/CommissionTab';
import { listClinics, getClinic } from '../../collums-components/api/ClinicApi';
import CategoryApi from '../../collums-components/api/CategoryApi';
import ServiceApi from '../../collums-components/api/ServiceApi';
import EquipmentApi from '../../api/EquipmentApi';
import { toastr } from 'react-redux-toastr';
import { pick } from 'lodash';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import ServicesFormTab from '../common/ServicesFormsTab';
import { getAvailableForServices } from '../../collums-components/api/FormApi';

import { serviceDetailsModalStyles } from './styles';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import setFormOptions from './../../services/setFormOptions';
import { formatServiceFormData, getLocationItem } from '../../collums-constants/utils';
import { DEFAULT_DURATION } from '../../constants/Services';
import SharedServicesOrgAndLocProps from '../../constants/SharedServicesOrgAndLocProps';
import updateLocLevelProperties from '../../helpers/updateLocLevelProperties';
import OrganisationsApi from '../../api/OrganisationsApi';
import LocationSettingModal from './LocationSettitngModal';
import { SERVICE_AVAILABILITIES } from '../../collums-constants';
import ConfirmModal from '../common/ConfirmModal';
import TaxesApi from '../../api/TaxesApi';

function ServiceFormModal({ isFromOrg, classes, onClose, id, onConfirm, clinic, serviceTaxes, onCategoryConfirm }) {
    const globalStyles = makeStyles(modalsButtonStyles)();

    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [services, setServices] = useState([]);
    const [clinics, setClinics] = useState([]);
    const [allClinics, setAllClinics] = useState([]);
    const [currentService, setCurrentService] = useState();
    const [practitioners, setPractitioners] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [equipments, setEquipments] = useState({ size: 5, items: [] });
    //const [locations, setLocations] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [ltccPrice, setLtccPrice] = useState(undefined);
    const [noShowPrice, setNoShowPrice] = useState(undefined);
    const [forms, setForms] = useState([]);
    const formProperties = useRef({});
    const isMakingRequest = useRef(false);
    const haveAllServices = useRef(false);
    const [allowCommission, setAllowCommission] = useState(false);
    const [commissionCondition, setCommissionCondition] = useState();
    const [collapseTrigger, setCollapseTrigger] = useState(false);
    const [inlineLocationSettings, setInlineLocationSettings] = useState({});
    const [isMultiClinic, setIsMultiClinic] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [currentSettingLocation, setCurrentSettingLocation] = useState({});

    const [confirmModal, setConfirmModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState('');
    const [modalCancel, setModalCancel] = useState(null);
    const [modalContinue, setModalContinue] = useState(null);
    const [modalOnContinue, setModalOnContinue] = useState(() => {});
    const [saveButtonInactive, setSaveButtonInactive] = useState(false);
    const [organisationData, setOrganisationData] = useState(null);

    const { register, handleSubmit, control, watch, setValue, getValues, errors } = useForm();
    useEffect(() => {
        (async () => {
            const organisation = await OrganisationsApi.getOrganisation();
            setIsMultiClinic(!!organisation?.isMultiClinic);
            setOrganisationData(organisation);
        })();
    }, []);

    useEffect(() => {
        register('allowCommission', allowCommission);
        register('commissionCondition', commissionCondition);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setValue('allowCommission', allowCommission);
        setValue('commissionCondition', commissionCondition);
        // eslint-disable-next-line
    }, [allowCommission, commissionCondition]);

    const convertMinutesToHours = duration => {
        if (!duration) return '00:05';
        duration = Number(duration || 0);
        let hours = duration / 60;
        let remainingHours = Math.floor(hours);
        let minutes = parseInt(duration - remainingHours * 60);
        if (String(minutes).length === 1) {
            minutes = `0${minutes}`;
        }
        if (String(remainingHours).length === 1) {
            remainingHours = `0${remainingHours}`;
        }
        return `${remainingHours}:${minutes}`;
    };

    const loadOptions = async svc => {
        // * VERIFY IF CURRENT SERVICE HAVE A CATEGORY.

        const categ = await CategoryApi.filter('', false, clinic, isFromOrg);
        if (!(currentService || {}).id) {
            categ.unshift({ id: null, name: 'New category' });
        }
        let selectedCateg;
        if (svc.populatedCategory) {
            const popCategModel = ['id', 'name', 'locations', 'showDurationOnline', 'showPriceOnline', 'showOnline'];
            const popCateg = pick(svc.populatedCategory, popCategModel);
            if (categ.find(el => el.id === popCateg.id)) {
                setCategories([popCateg, ...categ.filter(el => el.id !== popCateg.id)]);
            } else setCategories([popCateg, ...categ]);
            selectedCateg = popCateg;
            setSelectedCategory(popCateg);
        } else setCategories(categ);

        // * VERIFY IF CURRENT SERVICE HAVE A SUB CATEGORY.
        if (selectedCateg) {
            const subCateg = await CategoryApi.getSubCategories('', selectedCateg.id, clinic, isFromOrg);
            if (svc.populatedSubCategory) {
                const popSubCategModel = ['id', 'name'];
                const popSubCateg = pick(svc.populatedSubCategory, popSubCategModel);
                if (subCateg.find(el => el.id === popSubCateg.id)) {
                    setSubCategories([popSubCateg, ...subCateg.filter(el => el.id !== popSubCateg.id)]);
                } else setSubCategories([popSubCateg, ...subCateg]);
                setSelectedSubCategory(popSubCateg);
            } else setSubCategories(subCateg);
        }

        // * VERIFY IF CURRENT SERVICE HAVE A ADD ONS SERVICES.
        ServiceApi.query('', '', '', `skip=${services.length}&count=50&isFromOrg=${isFromOrg}`).then(res =>
            setServices(res.items.filter(el => el.id !== id))
        );
    };

    const loadService = async allClinics => {
        const fetchedForms = await getAvailableForServices();
        setForms(fetchedForms);
        if (id !== 'new' && id !== undefined) {
            const res = await ServiceApi.getFormDetails(id);
            res.defaultDuration = convertMinutesToHours(res.defaultDuration);
            const taxOptions = await TaxesApi.getTaxesForItem('services');

            if (isFromOrg) {
                // --- defaultValue not working
                res.taxType = taxOptions.find(tax => tax.id === res.taxType);
            } else {
                res.taxType = taxOptions.find(tax => tax.id === getLocationItem(res, clinic)?.taxType);
            }
            res.locations =
                res.locations?.map(el => {
                    return {
                        ...el,
                        rooms: el.rooms || [],
                        equipments: el.equipments || []
                    };
                }) || [];

            setFormOptions(res, fetchedForms, formProperties);
            setCurrentService({ ...res, prevName: res.name });
            let currentInlineLocationSettings = {};
            if (isFromOrg) {
                // eslint-disable-next-line array-callback-return
                res.locations.map(loc => {
                    currentInlineLocationSettings[loc.clinic] = {
                        equipments: [],
                        rooms: [],
                        practitioners: []
                    };
                    // eslint-disable-next-line array-callback-return
                    loc.equipments.map(eq => {
                        currentInlineLocationSettings[loc.clinic].equipments.push(eq.equipment);
                    });
                    // eslint-disable-next-line array-callback-return
                    loc.rooms.map(eq => {
                        currentInlineLocationSettings[loc.clinic].rooms.push(eq.room);
                    });
                    // eslint-disable-next-line array-callback-return
                    loc.staffs.map(eq => {
                        currentInlineLocationSettings[loc.clinic].practitioners.push(eq.staff);
                    });
                });
                setInlineLocationSettings(currentInlineLocationSettings);
            }
            setAllowCommission(isFromOrg ? res.allowCommission : getLocationItem(res, clinic)?.allowCommission);
            setCommissionCondition(
                isFromOrg ? res.commissionCondition : getLocationItem(res, clinic)?.commissionCondition
            );
            if (res.lateCancellationCharge) setLtccPrice(res.lateCancellationCharge.value);
            if (res.noShowCharge) setNoShowPrice(res.noShowCharge.value);
            await loadOptions(res);

            return res;
        } else {
            if (allClinics.length === 1) {
                const staffs = await PractitionerApi.queryByClinic(allClinics[0].id);
                const clinicPractitioners = staffs[0].clinic;
                clinicPractitioners.id = clinicPractitioners._id;
                clinicPractitioners.practitioners = staffs[0].practitioners
                    .filter(staff => staff.active && !staff.archived)
                    .map(staff => ({
                        id: staff._id,
                        displayName: staff.displayName
                    }));
                setPractitioners([clinicPractitioners]);
            }
            setFormOptions({}, fetchedForms, formProperties);
            await loadOptions({});
            setCurrentService({ availability: SERVICE_AVAILABILITIES.BOOK_AND_SELL });
        }
        if (!isFromOrg) {
            // --- Insert the current clinic in order to show rooms and equipments
            setValue('locations', clinics);
        }
        return false;
    };

    const loadEquipRoomClinic = async (service, allClinics) => {
        const fetchedRooms = await RoomApi.listAll(clinic !== 'organisation' ? clinic : '');
        setRooms(fetchedRooms);
        const fetchedEquipment = await EquipmentApi.listAll(clinic !== 'organisation' ? clinic : '');
        setEquipments(fetchedEquipment);
        if (!service) return;
        let practitionerList = [];
        if (allClinics.length === 1) {
            const staffs = await PractitionerApi.queryByClinic(allClinics[0].id);
            const clinicPractitioners = staffs[0].clinic;
            clinicPractitioners.id = clinicPractitioners._id;
            clinicPractitioners.practitioners = staffs[0].practitioners
                .filter(staff => staff.active && !staff.archived)
                .map(staff => ({
                    id: staff._id,
                    displayName: staff.displayName,
                    locations: staff.locations
                }));
            practitionerList = [clinicPractitioners];
        } else {
            practitionerList = await PractitionerApi.availablePractitioners(id, clinic);
        }
        setPractitioners(practitionerList);
    };

    useEffect(() => {
        const loadItems = async () => {
            setIsLoading(true);
            const fetchedClinics = await listClinics();
            const service = await loadService(fetchedClinics);
            setAllClinics(fetchedClinics);
            if (isFromOrg) {
                if (service.category) {
                    await loadEquipRoomClinic(service, fetchedClinics);
                    setClinics(fetchedClinics);
                } else {
                    setClinics(fetchedClinics);
                    if (fetchedClinics.length === 1) {
                        await loadEquipRoomClinic(null, fetchedClinics);
                    }
                }
            } else {
                const fetchedClinics = [await getClinic(clinic)];
                setClinics(fetchedClinics);
                await loadEquipRoomClinic(service, fetchedClinics);
            }
            setIsLoading(false);
        };
        loadItems();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const requiredFields = Object.keys(errors);

        if (requiredFields.length !== 0) {
            const messages = requiredFields.map(field => errors[field].message);

            toastr.error('Missing fields', messages.join(','));
        }
    }, [errors]);

    const converHoursToMinutes = hms => {
        const splitted = hms.split(':');
        return String(+splitted[0] * 60 + +splitted[1]);
    };

    const formRef = useRef();

    const save = async (value, skipConfigureCheck = false) => {
        setSaveButtonInactive(true);
        value.locations = value.locations.filter(loc => loc.isSelected);

        if (currentService.id) {
            if (value.name !== currentService.prevName) {
                setModalTitle('Confirm Editing');
                setModalContent(
                    'Changing the name of this service will also affect historic appointments, invoices, reports and anywhere else it features.\n' +
                        'If you wish to keep historic records the same but have this change for the future, please inactivate this service and create a new one.'
                );
                setModalCancel('Cancel');
                setModalContinue('Confirm');
                setModalOnContinue(() => () => {
                    currentService.prevName = value.name;
                    save(value);
                });
                setConfirmModal(true);
                setSaveButtonInactive(false);
                return;
            }
            value.locations = value.locations.map(loc => {
                const previousLocationData = (currentService.locations ?? []).find(el => el.clinic === loc.value);
                if (previousLocationData) {
                    return {
                        ...previousLocationData,
                        ...loc
                    };
                }
                return loc;
            });
        } else {
            // if (
            //     clinics.length === 1 &&
            //     !inlineLocationSettings[value.locations[0].value] &&
            //     value.skipLocationChecking !== true
            // ) {
            //     setCurrentSettingLocation(value.locations[0]);
            //     setIsModalVisible(true);
            //     return;
            // }
        }

        value.deposit = { type: 'none', value: 0 };
        if (
            (value.lateCancellationCharge || {}).type !== undefined &&
            (value.lateCancellationCharge || {}).type !== 'none'
        ) {
            value.lateCancellationCharge.value = ltccPrice;
        } else {
            value.lateCancellationCharge = { type: 'none', value: 0 };
        }
        if ((value.noShowCharge || {}).type !== undefined && (value.noShowCharge || {}).type !== 'none') {
            value.noShowCharge.value = noShowPrice;
        } else {
            value.noShowCharge = { type: 'none', value: 0 };
        }

        if (!value.category) value.category = selectedCategory !== null ? (selectedCategory || {}).id : undefined;
        if (!value.subCategory)
            value.subCategory = selectedSubCategory !== null ? (selectedSubCategory || {}).id : undefined;
        if (!value.id && !value.category) {
            toastr.error('Missing field category');
            setSaveButtonInactive(false);
            return;
        }

        Object.assign(value, formProperties.current);

        const errors = formatServiceFormData(value);

        if (errors.length) {
            toastr.error(errors[0]);
            setSaveButtonInactive(false);
            return;
        }

        if (!value.defaultDuration?.value) {
            toastr.error('Missing default duration');
            setSaveButtonInactive(false);
            return;
        }

        if (value.taxType === undefined) {
            toastr.error('Missing field tax');
            setSaveButtonInactive(false);
            return;
        }

        if (Object.keys(value.taxType).includes('id')) {
            value.taxType = value.taxType.id;
        }
        const locations = value.locations;

        if (locations.length === 0) {
            const newValue = {
                ...value,
                tags: value.tags.map(tag => tag.id),
                defaultDuration: value.defaultDuration?.value
                    ? converHoursToMinutes(value.defaultDuration.value) || DEFAULT_DURATION
                    : DEFAULT_DURATION,
                taxType: value.taxType
            };
            delete newValue.list;
            delete newValue.room_equipment;
            delete newValue.locations;

            // if (!newValue.onlineName) {
            //     newValue.onlineName = value.name;
            // }
        }

        const newLocations = locations.map((location, index) => {
            const currentLocationSettings = inlineLocationSettings[location.value];
            let defaultEquipment = [];
            let defaultStaff = [];

            if (currentService.id) {
                const currLocation = currentService.locations.find(defaultLoc => defaultLoc.clinic === location.value);

                if (currLocation) {
                    if (currLocation.equipments) defaultEquipment = currLocation.equipments;
                    if (currLocation.staffs) defaultStaff = currLocation.staffs;
                }
            }

            location.rooms = location.rooms?.filter(room => !!room);
            location.equipments = location.equipments?.filter(equipment => !!equipment);

            let rooms = [];
            if (currentLocationSettings) {
                rooms = currentLocationSettings.rooms.map(roomId => {
                    return { id: roomId };
                });
            } else {
                rooms = location.rooms !== undefined ? isSelected(location.rooms[0]) : [[]];
            }

            let equips = [];
            if (currentLocationSettings) {
                equips = currentLocationSettings.equipments.map(equipId => {
                    return { id: equipId };
                });
            } else {
                equips = location.equipments !== undefined ? isSelected(location.equipments[0]) : [[]];
            }

            const oldLocationValues = (currentService.locations || []).find(loc => loc.clinic === location.clinic);

            let staffsValues = (() => {
                if (isFromOrg && allClinics.length !== 1) {
                    return defaultStaff;
                }
                return isSelected(value.list && value.list[index] ? value.list[index].practitioners : []).map(pract => {
                    return {
                        staff: pract.value,
                        netPrice: pract.netPrice === null ? undefined : pract.netPrice,
                        grossPrice: pract.grossPrice === null ? undefined : pract.grossPrice,
                        duration: pract.duration === null ? undefined : pract.duration
                    };
                });
            })();

            if (currentLocationSettings) {
                staffsValues = currentLocationSettings.practitioners.map(staffId => {
                    return { staff: staffId, netPrice: undefined, grossPrice: undefined, duration: undefined };
                });
            }

            const newLocationRooms = rooms.map((room, index) => {
                return { room: room.id, priority: index };
            });

            const newLocationEquipments =
                equips?.map((equipment, index) => {
                    return { equipment: equipment.id, priority: index };
                }) || defaultEquipment;

            return {
                active: oldLocationValues?.active ?? currentService.active,
                archived: oldLocationValues?.archived ?? currentService.archived,
                showOnline: oldLocationValues?.showOnline ?? currentService.showOnline,
                taxType:
                    typeof currentService.taxType === 'object' ? currentService.taxType.id : currentService.taxType,
                onlineName: currentService.onlineName,
                order: currentService.order,
                onlineDescription: currentService.onlineDescription,
                onlineSubtitle: currentService.onlineSubtitle,
                allowCommission: currentService.allowCommission,
                clinic: location.value,
                staffs: staffsValues,
                rooms: newLocationRooms.filter(room => room),
                equipments: newLocationEquipments.filter(eq => eq)
            };
        });

        if (newLocations.every(location => location === undefined)) {
            toastr.error('Missing field rooms');
            setSaveButtonInactive(false);
            return;
        }

        const selectedStaffs = newLocations.filter(el => el?.staffs?.length);
        const selectedRooms = newLocations.filter(el => el?.rooms?.length);

        if (
            (isFromOrg || allClinics.length === 1) &&
            !skipConfigureCheck &&
            (!selectedStaffs?.length || !selectedRooms?.length)
        ) {
            setModalTitle('Configure your service');
            setModalContent(
                'Your service cannot be booked until it has been configured (connected to a room & staff).'
            );
            setModalCancel('Back');
            setModalContinue('Configure later');
            setModalOnContinue(() => () => {
                setConfirmModal(false);
                save(value, true);
            });
            setConfirmModal(true);
            setSaveButtonInactive(false);
            return;
        }

        const newValue = {
            ...value,
            tags: value.tags.map(tag => tag.id),
            defaultDuration: value.defaultDuration?.value
                ? converHoursToMinutes(value.defaultDuration.value) || DEFAULT_DURATION
                : DEFAULT_DURATION,
            locations: newLocations,
            taxType: typeof value.taxType === 'object' ? value.taxType.id : value.taxType
        };

        delete newValue.list;
        delete newValue.room_equipment;

        // if (!newValue?.onlineName) {
        //     newValue.onlineName = '';
        // }

        if (!isFromOrg && currentService.id) {
            newValue.defaultNetPrice = currentService.defaultNetPrice;
            newValue.defaultGrossPrice = currentService.defaultGrossPrice;
            newValue.taxType =
                typeof currentService.taxType === 'object' ? currentService.taxType.id : currentService.taxType;
            newValue.defaultDuration = converHoursToMinutes(currentService.defaultDuration);
            newValue.onlineName = currentService.onlineName;
            newValue.order = currentService.order;
            newValue.onlineDescription = currentService.onlineDescription;
            newValue.onlineSubtitle = currentService.onlineSubtitle;
            newValue.allowCommission = currentService.allowCommission;
            newValue.category = currentService.category;
            newValue.commissionCondition = currentService.commissionCondition;
            newValue.locations = newValue.locations.map(loc => {
                if (loc.clinic.toString() === clinic.toString()) {
                    loc.netPrice = value.defaultNetPrice;
                    loc.grossPrice = value.defaultGrossPrice;
                    loc.taxType = typeof value.taxType === 'object' ? value.taxType.id : value.taxType;
                    loc.defaultDuration = value.defaultDuration?.value
                        ? converHoursToMinutes(value.defaultDuration.value) || DEFAULT_DURATION
                        : DEFAULT_DURATION;
                    loc.onlineName = value.onlineName;
                    loc.order = value.order;
                    loc.onlineDescription = value.onlineDescription;
                    loc.onlineSubtitle = value.onlineSubtitle;
                    loc.allowCommission = value.allowCommission;
                    loc.commissionCondition = value.commissionCondition;
                    loc.category = value?.category || currentService.category;
                    loc.showOnline = value.showOnline;
                }
                return loc;
            });
        } else if (!value.allowCommission) {
            newValue.locations = newValue.locations.map(loc => ({
                ...loc,
                allowCommission: false,
                commissionCondition: ''
            }));
        }

        if (newValue.taxType?.id) {
            newValue.taxType = newValue.taxType.id;
        }

        if (currentService.id) {
            try {
                if (isFromOrg) {
                    newValue.locations = newValue.locations.map(location => {
                        const sharedServiceProperties = SharedServicesOrgAndLocProps;
                        const updatedLocLevel = updateLocLevelProperties({
                            newItem: newValue,
                            oldItem: currentService,
                            location,
                            properties: sharedServiceProperties
                        });

                        if (updatedLocLevel?.subCategory === '' && !value?.subCategory) {
                            // Fix incorrectly assigned subcategory as empty string.
                            // It should to be ObjectId or Null or Property should not exists.
                            delete updatedLocLevel.subCategory;
                        }

                        if (!_.has(updatedLocLevel, 'active') || updatedLocLevel?.active === undefined) {
                            updatedLocLevel.active = true;
                        }

                        if (!_.has(updatedLocLevel, 'archived') || updatedLocLevel?.archived === undefined) {
                            updatedLocLevel.archived = false;
                        }

                        if (!updatedLocLevel?.active) {
                            if (inlineLocationSettings?.[location.clinic]?.finishedWizard) {
                                updatedLocLevel.active = true;
                                updatedLocLevel.archived = false;
                            } else {
                                const locationItem = currentService?.locations?.find(
                                    el => el?.clinic === location?.clinic
                                );

                                const staffsChanged =
                                    (locationItem?.staffs || [])?.length !== location?.staffs?.length ||
                                    !_.isEqual(
                                        locationItem?.staffs?.map(el => el?.staff),
                                        (location?.staffs || [])?.map(el => el?.staff)
                                    );

                                const roomsChanged =
                                    (locationItem?.rooms || [])?.length !== location?.rooms?.length ||
                                    !_.isEqual(
                                        locationItem?.rooms?.map(el => el?.room),
                                        (location?.rooms || [])?.map(el => el?.room)
                                    );

                                const equipmentChanged =
                                    (locationItem?.equipments || [])?.length !== location?.equipments?.length ||
                                    !_.isEqual(
                                        locationItem?.equipments?.map(el => el?.equipment),
                                        (location?.equipments || [])?.map(el => el?.equipment)
                                    );

                                if (staffsChanged || roomsChanged || equipmentChanged) {
                                    updatedLocLevel.active = true;
                                    updatedLocLevel.archived = false;
                                }
                            }
                        }

                        return updatedLocLevel;
                    });
                } else {
                    newValue.showOnline = currentService.showOnline;
                }

                await ServiceApi.update(
                    currentService.id,
                    newValue,
                    isFromOrg,
                    clinic !== 'organisation' ? clinic : ''
                );
                const serviceCategory = newValue.category;
                const serviceLocations = newValue.locations.map(loc => loc.clinic);
                const category = await CategoryApi.get(serviceCategory);
                const categoryLocations = category.locations.map(loc => loc.clinic);
                const newCategoryLocations = [];

                serviceLocations.forEach(serviceLoc => {
                    if (!categoryLocations.includes(serviceLoc)) {
                        newCategoryLocations.push({
                            clinic: serviceLoc
                        });
                    }
                });
                toastr.success(`Service ${value.name} successfully updated!`);
                setSaveButtonInactive(false);
                setCollapseTrigger(!collapseTrigger);
                onConfirm();
            } catch (err) {
                setSaveButtonInactive(false);
                if (typeof err === 'object') {
                    if (err.data && err.data.message) {
                        toastr.error(err.data.message);
                        return;
                    }
                }
                toastr.error('Something went wrong');
            }
        } else {
            try {
                newValue.locations = locations.map(loc => {
                    loc.grossPrice = value.defaultGrossPrice;
                    loc.netPrice = value.defaultNetPrice;
                    loc.clinic = loc.value;
                    loc.defaultDuration = value.defaultDuration?.value
                        ? converHoursToMinutes(value.defaultDuration.value) || DEFAULT_DURATION
                        : DEFAULT_DURATION;
                    loc.taxType = value.taxType;
                    loc.staffs = newValue.locations.find(nLoc => nLoc.clinic === loc.value)?.staffs || [];
                    loc.rooms = newValue.locations.find(nLoc => nLoc.clinic === loc.value)?.rooms || [];
                    loc.equipments = newValue.locations.find(nLoc => nLoc.clinic === loc.value)?.equipments || [];
                    loc.active = true;
                    loc.archived = false;
                    loc.onlineName = value.onlineName;
                    loc.order = value.order;
                    loc.onlineDescription = value.onlineDescription;
                    loc.onlineSubtitle = value.onlineSubtitle;
                    loc.showOnline = value.showOnline;
                    loc.allowCommission = value.allowCommission;
                    loc.category = value.category;
                    loc.subCategory = value.subCategory;
                    return loc;
                });
                await ServiceApi.create(newValue);
                setSaveButtonInactive(false);
                toastr.success(`Service ${value.name} successfully created!`);
                onConfirm();
            } catch (err) {
                setSaveButtonInactive(false);
                if (typeof err === 'object') {
                    if (err.data && err.data.message) {
                        toastr.error(err.data.message);
                        return;
                    }
                }
                toastr.error('Something went wrong');
            }
        }
    };

    const isSelected = arr => {
        if (!arr) return [];
        const list = arr.map(item => {
            if (item.isSelected) return item;
            else return undefined;
        });
        const filteredList = list.filter(item => item !== undefined);
        return filteredList;
    };

    const searchCategories = async value => {
        try {
            const newValues = await CategoryApi.filter(value, false, clinic, isFromOrg);
            setCategories(newValues);
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        }
    };

    const searchSubCategories = async (value, id) => {
        try {
            const newValues = await CategoryApi.getSubCategories(value, id, clinic, isFromOrg);
            setSubCategories(newValues);
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        }
    };

    const loadServices = async () => {
        try {
            if (isMakingRequest.current || haveAllServices.current) return;
            isMakingRequest.current = true;
            const data = await ServiceApi.query('', '', '', `skip=${services.length}&count=50&isFromOrg=${isFromOrg}`);
            isMakingRequest.current = false;
            setServices([...services, ...data.items.filter(el => el.id !== id)]);
            if (data.items.length !== 50) haveAllServices.current = true;
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        }
    };

    const tabProps = {
        selectedCategory,
        selectedSubCategory,
        setSelectedCategory,
        setSelectedSubCategory,
        categories: categories,
        searchCategories,
        searchSubCategories,
        loadServices,
        services: services,
        subCategories,
        clinics,
        setClinics,
        allClinics,
        clinic,
        isFromOrg,
        practitioners,
        rooms,
        equipments,
        currentService,
        control,
        convertMinutesToHours,
        register,
        watch,
        setValue,
        getValues,
        setAllowCommission,
        setCommissionCondition,
        allowCommission,
        commissionCondition,
        setInlineLocationSettings,
        isMultiClinic,
        organisationData
    };

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setModalTitle('');
        setModalContent();
        setModalContinue(null);
        setModalCancel(null);
    };

    if (isLoading) return <LoadingScreen />;

    return (
        <>
            <Modal
                isOpen={true}
                title={(currentService || {}).id ? `View/Edit ${currentService.name} Service` : 'New Service'}
                id="service"
                size="md"
                onClose={onClose}
                onCancel={onClose}
            >
                <form
                    ref={formRef}
                    onSubmit={e => {
                        e.preventDefault();
                        handleSubmit(save)();
                    }}
                >
                    {currentService && organisationData && (
                        <div className={classes.accordions}>
                            <Accordion title={'General'} collapse={collapseTrigger}>
                                {' '}
                                <GeneralTab {...tabProps} onCategoryConfirm={onCategoryConfirm} key="general" />{' '}
                            </Accordion>
                            <Accordion
                                title={'Duration'}
                                hideItem={!isFromOrg && allClinics.length !== 1}
                                collapse={collapseTrigger}
                            >
                                {' '}
                                <TimingTab {...tabProps} key="timing" />{' '}
                            </Accordion>
                            <Accordion title={'Pricing'} collapse={collapseTrigger}>
                                {' '}
                                <PricingTab
                                    {...tabProps}
                                    taxes={serviceTaxes}
                                    ltccPrice={ltccPrice}
                                    setLtccPrice={setLtccPrice}
                                    noShowPrice={noShowPrice}
                                    setNoShowPrice={setNoShowPrice}
                                    key="pricing"
                                />{' '}
                            </Accordion>
                            <Accordion
                                title={'Locations'}
                                hideItem={!isFromOrg || allClinics.length === 1}
                                collapse={collapseTrigger}
                            >
                                {' '}
                                <LocationTab
                                    {...tabProps}
                                    taxes={serviceTaxes}
                                    ltccPrice={ltccPrice}
                                    setLtccPrice={setLtccPrice}
                                    noShowPrice={noShowPrice}
                                    setNoShowPrice={setNoShowPrice}
                                    key="location"
                                    inlineLocationSettings={inlineLocationSettings}
                                    getMainFormValues={getValues}
                                    allClinics={allClinics}
                                />{' '}
                            </Accordion>
                            <Accordion
                                title={'Staff'}
                                hideItem={isFromOrg && allClinics.length !== 1}
                                collapse={collapseTrigger}
                            >
                                {' '}
                                <Staff {...tabProps} key="staff" />{' '}
                            </Accordion>

                            <Accordion
                                title={'Room & Equipment'}
                                hideItem={isFromOrg && allClinics.length !== 1}
                                collapse={collapseTrigger}
                            >
                                {' '}
                                <RoomEquipmentTab {...tabProps} key="room_equipment" />{' '}
                            </Accordion>
                            <Accordion title={'Online Booking & Deposit'} collapse={collapseTrigger}>
                                {' '}
                                <OnlineBookingTab {...tabProps} key="online_booking" />{' '}
                            </Accordion>

                            <Accordion title={'Commission'} collapse={collapseTrigger}>
                                {' '}
                                <CommissionTab {...tabProps} key="commissions" />{' '}
                            </Accordion>
                            <Accordion
                                title={'Forms'}
                                disableFlex
                                // hideItem={!isFromOrg && allClinics.length > 1}
                                collapse={collapseTrigger}
                            >
                                <ServicesFormTab
                                    classes={classes}
                                    isFromOrg={isFromOrg}
                                    formPropertiesRef={formProperties}
                                    formOptions={forms}
                                    allClinics
                                />
                            </Accordion>
                        </div>
                    )}
                    <div className={globalStyles.buttonsContainer}>
                        <Button className={globalStyles.cancelButton} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button className={globalStyles.confirmButton} type="submit" disabled={saveButtonInactive}>
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>

            {isModalVisible && (
                <LocationSettingModal
                    isVisible={isModalVisible}
                    currentSettingLocation={currentSettingLocation}
                    closeModal={() => {
                        setIsModalVisible(false);
                    }}
                    inlineLocationSettings={inlineLocationSettings}
                    setInlineLocationSettings={setInlineLocationSettings}
                    getMainFormValues={getValues}
                    additionalWorkOnContinue={async () => {
                        await save({ ...getValues(), skipLocationChecking: true });
                    }}
                />
            )}
            {confirmModal && (
                <ConfirmModal
                    isOpen
                    setIsOpen={closeConfirmModal}
                    onConfirm={modalOnContinue}
                    title={modalTitle}
                    content={modalContent}
                    centerContent
                    continueText={modalContinue}
                    cancelText={modalCancel}
                />
            )}
        </>
    );
}

ServiceFormModal.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func.isRequired
};

export default withStyles(serviceDetailsModalStyles)(ServiceFormModal);
