import { makeStyles, FormControl, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { serviceDetailsModalStyles } from '../styles';
import SelectAutocomplete from '../../common/SelectAutocomplete';

// TODO ??
import MomentDurationFormat from 'moment-duration-format';
import Moment from 'moment';

MomentDurationFormat(Moment);

function TimingTab({
    register,
    control,
    setValue,
    getValues,
    convertMinutesToHours,
    isFromOrg,
    clinic,
    currentService,
    clinics
}) {
    const classes = makeStyles(serviceDetailsModalStyles)();

    const opts = new Array(2 * 12).fill(5).map((e, i) => {
        return { value: Moment.duration(e * (i + 1), 'minutes').format('HH:mm', { trim: false }) };
    });

    return (
        <div className={classes.formContent}>
            <FormControl className={classes.formItem} variant="outlined">
                <InputLabel shrink>Default Duration *</InputLabel>
                <SelectAutocomplete
                    name={'defaultDuration'}
                    getValues={getValues}
                    register={register}
                    setValue={setValue}
                    control={control}
                    opts={opts}
                    defaultValue={(() => {
                        if (isFromOrg || clinics.length === 1) {
                            return currentService ? currentService.defaultDuration : undefined;
                        }
                        return convertMinutesToHours(
                            currentService.locations.find(loc => loc.clinic === clinic)?.defaultDuration
                        );
                    })()}
                    rules={register({ required: 'Default duration' })}
                />
            </FormControl>
        </div>
    );
}

TimingTab.propTypes = {
    register: PropTypes.any.isRequired,
    setValue: PropTypes.func,
    getValues: PropTypes.func,
    control: PropTypes.any.isRequired
};

export default TimingTab;
