import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Button } from '@material-ui/core';

import { modalsButtonStyles } from '../../../../collums-constants/styles/stylesheets/buttonsStyles';

function CustomModalActions({ onCancel, onConfirm }) {
    const globalStyles = makeStyles(modalsButtonStyles)();

    return (
        <div className={globalStyles.buttonsContainer}>
            <Button disableElevation className={globalStyles.cancelButton} variant="contained" onClick={onCancel}>
                Cancel
            </Button>
            <Button disableElevation className={globalStyles.confirmButton} variant="contained" onClick={onConfirm}>
                Merge
            </Button>
        </div>
    );
}

CustomModalActions.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired
};

export default CustomModalActions;
