import React, { useCallback, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import View from '../common/View';
import AdminTable from '../../collums-components/components/common/adminTable';
import Switch from '../../collums-components/components/common/customSwitch';
import { Link } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import AlertsApi from '../../collums-components/api/AlertsApi';
import { alertViewStyle } from './style';
import { withStyles } from '@material-ui/styles';
import AlertsModal from './AlertsModal';

const defaultSMSProps = {
    nSegments: 0,
    nMessages: 0
};

const AlertsView = ({ clinic, classes }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [alertList, setAlertList] = useState([]);
    const [selectedAlert, setSelectedAlert] = useState({});
    const [smsProperties, setSMSProperties] = useState(defaultSMSProps);

    const changeActive = async (alert, target) => {
        try {
            setIsLoading(true);
            await AlertsApi.changeActive(alert.id, target, !alert[target]);
            toastr.success(`Alert ${target} successfully updated`);
            await loadList();
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong');
            setIsLoading(false);
        }
    };

    const renderTable = useCallback(() => {
        return (
            <View>
                <AdminTable
                    list={alertList}
                    columns={[
                        {
                            label: 'Name',
                            key: 'name',
                            specialField: alert => {
                                return (
                                    <Typography>
                                        <Link
                                            onClick={() => openModal(alert.id)}
                                            style={{ textDecoration: 'none', color: '#3083EC' }}
                                        >
                                            {alert.name}
                                        </Link>
                                    </Typography>
                                );
                            }
                        },
                        {
                            label: 'Description',
                            key: 'description'
                        },
                        {
                            label: 'Email',
                            key: 'email',
                            specialField: alert => (
                                <Switch
                                    checked={alert.email}
                                    onChange={() => {
                                        changeActive(alert, 'email');
                                    }}
                                />
                            )
                        },
                        {
                            label: 'SMS',
                            key: 'sms',
                            specialField: alert => (
                                <Switch
                                    checked={alert.sms}
                                    onChange={() => {
                                        changeActive(alert, 'sms');
                                    }}
                                />
                            )
                        }
                    ]}
                />
            </View>
        );
        /*eslint-disable-next-line */
    }, [alertList]);

    const getSMSCount = async () => {
        try {
            const newSmsProperties = await AlertsApi.getMessageCounter();
            setSMSProperties(newSmsProperties);
        } catch (err) {
            setSMSProperties(defaultSMSProps);
        }
    };

    const loadList = async () => {
        setIsLoading(true);
        try {
            getSMSCount();
            const list = await AlertsApi.getAlerts(clinic?.id);
            setAlertList(list);
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong');
        }
        setIsLoading(false);
    };

    useEffect(() => {
        loadList();
        //eslint-disable-next-line
    }, []);

    const openModal = async id => {
        setIsLoading(true);
        try {
            const selectedAlert = await AlertsApi.getAlert(id);
            setSelectedAlert(selectedAlert);
            setModalOpen(true);
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong');
        }
        setIsLoading(false);
    };

    const closeModal = async () => {
        setModalOpen(false);
    };

    return (
        <>
            <div className={classes.countersDiv}>
                <Typography variant="span">SMS messages sent this month: {smsProperties?.nMessages || 0}</Typography>
                <Typography variant="span">SMS segments sent this month: {smsProperties?.nSegments || 0}</Typography>
            </div>
            {renderTable()}
            {isModalOpen && <AlertsModal alert={selectedAlert} closeModal={closeModal} />}
            {isLoading && <LoadingScreen />}
        </>
    );
};

export default withStyles(alertViewStyle)(AlertsView);
