import {
    Box,
    Button,
    Checkbox,
    IconButton,
    Menu,
    MenuItem,
    Select,
    TablePagination,
    TextField,
    Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Promise from 'bluebird';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import ListTable from '../common/ListTable';
import View from '../common/View';
import Switch from '../../collums-components/components/common/customSwitch';

import CategoryApi from '../../collums-components/api/CategoryApi';
import ServiceApi from '../../collums-components/api/ServiceApi';
import TaxesApi from '../../api/TaxesApi';

import ServiceFormModal from './ServiceFormModal';
import { servicesViewStyles } from './styles';
import { toLocaleString } from '../../collums-components/helpers';
import StopIcon from '@material-ui/icons/Stop';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Autocomplete } from '@material-ui/lab';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import ConfirmModal from '../common/ConfirmModal';
import { toastr } from 'react-redux-toastr';
import { getLocationItem } from '../../collums-constants/utils';
import { MTableBodyRow } from 'material-table';

import { buttonsStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import { inputSearchStyles } from '../../collums-constants/styles/stylesheets/inputSearchStyles';
import scrollTop from './../../services/scrollTop';
import CategoriesModal from '../categories/CategoriesModal';
import CancelContinueModal from '../../collums-components/components/common/CancelContinueModal';

const ActionButton = ({
    classes,
    item,
    isFromOrg,
    clinic,
    from,
    editService,
    deleteService,
    isArchived,
    cloneService
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = event => setAnchorEl(event.currentTarget);
    const closeMenu = () => setAnchorEl(null);
    const renderMenuItemText = (() => {
        if (isFromOrg ? item.archived : getLocationItem(item, clinic)?.archived) {
            return 'Unarchive';
        }
        return 'Archive';
    })();
    if (from === 'category')
        return (
            <>
                <Button onClick={openMenu} className={classes.grayButton}>
                    Options
                </Button>

                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
                    <MenuItem onClick={() => deleteService(item)}>{renderMenuItemText}</MenuItem>
                </Menu>
            </>
        );
    return (
        <>
            <Button onClick={openMenu} className={classes.grayButton}>
                Options
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
                <MenuItem
                    onClick={() => {
                        editService(item);
                    }}
                >
                    Edit
                </MenuItem>
                <MenuItem onClick={() => cloneService(item.id)}>Copy</MenuItem>
                <MenuItem onClick={() => deleteService(item)}>
                    {(isFromOrg ? isArchived : getLocationItem(item, clinic)?.archived) ? 'Unarchive' : 'Archive'}
                </MenuItem>
            </Menu>
        </>
    );
};
const EditableCell = ({ onBlur, initValue, type = '', min = -999 }) => {
    const [value, setValue] = useState(initValue);
    return (
        <TextField
            type={type === 'NumericalText' ? 'text' : type}
            value={value}
            onBlur={() => {
                onBlur(value);
            }}
            onChange={e => {
                const inputValue = e.target.value;
                if (['NumericalText', 'number'].includes(type)) {
                    if (isNaN(inputValue)) {
                        e.target.value = value;
                        return;
                    }
                    if (type === 'NumericalText') {
                        setValue(inputValue);
                        return;
                    }
                    if (parseFloat(inputValue) < min) {
                        toastr.error('This value cannot be negative');
                        setValue(parseFloat('0'));
                    } else {
                        setValue(parseFloat(inputValue));
                    }
                } else {
                    setValue(inputValue);
                }
            }}
        />
    );
};

class ServicesView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            services: [],
            size: 10,
            rows: [],
            index: 0,
            maxPage: 1,
            rowsFetched: 0,
            rowsPerPage: 25,
            isModalOpen: false,
            editing: {
                active: false,
                name: '',
                defaultNetPrice: 0,
                taxType: '',
                serviceTime: 0,
                price: 0,
                online: false
            },
            categoryIdUpdated: '',
            editingRow: '',
            isEditing: false,
            categoriesFilter: [],
            subCategoriesFilter: [],
            taxFilter: [],
            categoriesOptions: [],
            subCategoriesOptions: [],
            taxOptions: [],
            search: '',
            showArchived: false,
            isLoading: false,
            selectedService: {},
            modalTitle: '',
            confirmModal: false,
            modalContent: '',
            typing: false,
            typingTimeout: 0,
            showInactive: true,
            updatedServicesByActive: [],
            updatedServicesByArchive: [],
            updatedServicesByShowOnline: [],
            updatedCategoriesByActive: [],
            updatedCategoriesByShowOnline: [],
            isServiceUpdateModalOpen: false,
            serviceUpdateModalMessage: ''
        };

        this.deleteService = this.deleteService.bind(this);
        this.openCategoryDetails = this.openCategoryDetails.bind(this);
        this.openServiceDetails = this.openServiceDetails.bind(this);
        this.onCategoryDetailsConfirm = this.onCategoryDetailsConfirm.bind(this);
        this.onServiceDetailsConfirm = this.onServiceDetailsConfirm.bind(this);
        this.onDetailsCancel = this.onDetailsCancel.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.changePage = this.changePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.editService = this.editService.bind(this);
        this.updateServiceInline = this.updateServiceInline.bind(this);
        this.cancelEditInline = this.cancelEditInline.bind(this);
        this.toDo = this.toDo.bind(this);
        this.openArchive = this.openArchive.bind(this);
        this.closeConfirmModal = this.closeConfirmModal.bind(this);
        this.refreshList = this.refreshList.bind(this);
        this.changeName = this.changeName.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.changeServiceActive = this.changeServiceActive.bind(this);
        this.getFilters = this.getFilters.bind(this);
        this.checkService = this.checkService.bind(this);
        this.cloneService = this.cloneService.bind(this);
        this.getPageItemsParams = this.getPageItemsParams.bind(this);
    }

    async componentDidMount() {
        const categoriesOptions = await CategoryApi.filter('', false, this.props.clinic, this.props.isFromOrg, true);
        this.setState({ categoriesOptions });
        const subCategoriesOptions = await CategoryApi.filter('', true, this.props.clinic, this.props.isFromOrg);
        this.setState({ subCategoriesOptions });
        const taxOptions = await TaxesApi.getTaxesForItem('services');
        this.setState({ taxOptions });
        await this.refreshList();

        const currentCategory = new URL(window.location.href).searchParams.get('categoryId');
        if (currentCategory) {
            this.openCategoryDetails(currentCategory);
        }
    }

    async changeServiceActive(object) {
        if (!(object || {}).id) return;
        try {
            const value = this.props.isFromOrg ? !object.active : !getLocationItem(object, this.props.clinic).active;
            if (object.category) {
                let servCat;
                if (this.props.isFromOrg) {
                    servCat = this.state.categories.find(cat => cat.id.toString() === object.category.toString());
                    if (!servCat?.active) {
                        return toastr.error(`Category ${servCat.name} should be active to activate this service`);
                    }
                } else {
                    servCat = this.state.categories.find(
                        cat => cat.id === getLocationItem(object, this.props.clinic).category
                    );

                    if (!getLocationItem(servCat, this.props.clinic).active) {
                        return toastr.error(`Category ${servCat.name} should be active to activate this service`);
                    }
                }

                const serviceUpdated = await ServiceApi.changeServiceActive(
                    object.id,
                    this.props.isFromOrg,
                    this.props.clinic,
                    value
                );

                this.setState({
                    services: this.state.services.map(service => {
                        return service.map(s => {
                            if (serviceUpdated.id === s.id) {
                                if (!this.props.isFromOrg) {
                                    return { ...s, locations: serviceUpdated.locations };
                                }
                                return { ...s, active: serviceUpdated.active };
                            }
                            return { ...s };
                        });
                    }),
                    categories: this.state.categories.map(category => {
                        if (serviceUpdated.category === category.id && serviceUpdated.active) {
                            return { ...category, active: serviceUpdated.active };
                        }
                        return { ...category };
                    })
                });

                toastr.success(`Service ${object.name} successfully updated`);
                return true;
            } else {
                const categoryUpdated = await CategoryApi.changeCategoryActive(
                    object.id,
                    this.props.isFromOrg,
                    this.props.clinic,
                    value
                );

                this.setState({
                    services: this.state.services.map(service => {
                        return service.map(s => {
                            if (categoryUpdated.id === s.category) {
                                if (!this.props.isFromOrg) {
                                    return {
                                        ...s,
                                        locations: s.locations.map(l => {
                                            if (l.clinic === this.props.clinic && s?.active) {
                                                return {
                                                    ...l,
                                                    active: getLocationItem(categoryUpdated, this.props.clinic).active
                                                };
                                            }
                                            return l;
                                        })
                                    };
                                }
                                return { ...s, active: categoryUpdated.active };
                            }
                            return { ...s };
                        });
                    }),
                    categories: this.state.categories.map(category => {
                        if (categoryUpdated.id === category.id) {
                            if (!this.props.isFromOrg) {
                                return { ...category, locations: categoryUpdated.locations };
                            }
                            return { ...category, active: categoryUpdated.active };
                        }
                        return { ...category };
                    })
                });
                toastr.success(`Category ${object.name} successfully updated`);
            }
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        }
    }

    async changeShowOnline(object) {
        if (!(object || {}).id) return;
        try {
            if (object.category) {
                const value = this.props.isFromOrg
                    ? !object.showOnline
                    : !getLocationItem(object, this.props.clinic).showOnline;
                const serviceUpdated = await ServiceApi.changeServiceShowOnline(
                    object.id,
                    this.props.clinic,
                    this.props.isFromOrg,
                    value
                );
                toastr.success(`Service ${object.name} successfully updated`);

                await this.refreshList();
                return serviceUpdated;
            } else {
                const value = this.props.isFromOrg
                    ? !object.showOnline
                    : !getLocationItem(object, this.props.clinic).showOnline;
                const categoryUpdated = await CategoryApi.changeCategoryShowOnline(
                    object.id,
                    value,
                    this.props.clinic,
                    this.props.isFromOrg
                );
                this.setState({
                    services: this.state.services.map(service => {
                        return service.map(s => {
                            if (categoryUpdated.id === s.category) {
                                if (!this.props.isFromOrg) {
                                    return {
                                        ...s,
                                        locations: s.locations.map(l => {
                                            if (l.clinic === this.props.clinic) {
                                                return {
                                                    ...l,
                                                    showOnline: getLocationItem(categoryUpdated, this.props.clinic)
                                                        .showOnline
                                                };
                                            }
                                            return l;
                                        })
                                    };
                                }
                                return { ...s, showOnline: categoryUpdated.showOnline };
                            }
                            return { ...s };
                        });
                    }),
                    categories: this.state.categories.map(category => {
                        if (categoryUpdated.id === category.id) {
                            if (!this.props.isFromOrg) {
                                return { ...category, locations: categoryUpdated.locations };
                            }
                            return { ...category, showOnline: categoryUpdated.showOnline };
                        }
                        return { ...category };
                    })
                });

                toastr.success(`Category ${object.name} successfully updated`);
                await this.refreshList();
            }
        } catch (err) {
            if (typeof err === 'object') {
                if (!this.props.isFromOrg && err?.data?.errorCode === 1) {
                    this.setState({
                        isServiceUpdateModalOpen: true,
                        serviceUpdateModalMessage:
                            'You cannot change this item, it must be online at organisation level' ||
                            'Something went wrong'
                    });
                } else if (err.data && err.data.message) {
                    this.setState({
                        isServiceUpdateModalOpen: false
                    });
                    toastr.error(err.data.message);
                } else {
                    toastr.error('Something went wrong');
                }
            } else {
                toastr.error('Something went wrong');
            }
        } finally {
            this.setState({
                isLoading: false
            });
        }
    }

    async refreshList() {
        try {
            this.setState({ isLoading: true });
            const queryString = this.getFilters();
            const response = await CategoryApi.adminList(queryString);
            const { categories, services, size } = response;
            let categoriesList = categories;

            const categoryState = this.state.categories.filter(category => category?.tableData?.isTreeExpanded);
            if (categoryState.length > 0) {
                categoryState.forEach(categoryState => {
                    categoriesList = categoriesList?.map(category => {
                        if (categoryState.id === category.id) {
                            return {
                                ...category,
                                tableData: {
                                    ...category.tableData,
                                    isTreeExpanded: categoryState?.tableData?.isTreeExpanded
                                }
                            };
                        }
                        return category;
                    });
                });
            }

            if (response) {
                this.setState({
                    categories: categoriesList,
                    services,
                    size,
                    index: 0,
                    maxPage: parseInt(size / this.state.rowsPerPage) + (size % this.state.rowsPerPage !== 0 ? 1 : 0),
                    rowsFetched: categories.length,
                    updatedServicesByActive: [],
                    updatedServicesByArchive: [],
                    updatedServicesByShowOnline: [],
                    updatedCategoriesByActive: [],
                    updatedCategoriesByShowOnline: []
                });
            }
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            this.setState({
                isLoading: false
            });
        }
    }

    getFilters() {
        let taxFilter = '';
        if (this.state.taxFilter.length)
            taxFilter = `&tax=${this.state.taxFilter.map(element => {
                return element.id;
            })}&`;
        const queryString = `?fromServices=true${
            this.state.search ? `&name=${encodeURIComponent(this.state.search)}` : ''
        }&archived=${this.state.showArchived}${
            this.state.categoriesFilter.length ? `&categories=${this.state.categoriesFilter.join(',')}&` : ''
        }${
            this.state.subCategoriesFilter.length ? `&subCategories=${this.state.subCategoriesFilter.join(',')}` : ''
        }&active=${!this.state.showInactive}&count=${this.state.rowsPerPage}${taxFilter}&clinic=${
            this.props.clinic ? this.props.clinic : ''
        }&isFromOrg=${this.props.isFromOrg}`;
        return queryString;
    }

    async changePage(direction) {
        if (direction < 0) {
            this.cancelEditInline();
            if (!this.state.index) return;
            this.setState({ index: this.state.index - 1 });
        } else {
            if (this.state.maxPage === this.state.index + 1) return;
            if (this.state.rowsFetched < this.state.size) {
                this.cancelEditInline();
                this.setState({ isLoading: true });
                const filters = this.getFilters();
                const response = await CategoryApi.adminList(
                    `${filters}&skip=${(this.state.index + 1) * this.state.rowsPerPage}`
                );
                const { categories, services } = response;
                if (response) {
                    this.setState({
                        index: this.state.index + 1,
                        categories: [...this.state.categories, ...categories].slice(
                            this.state.startRow,
                            this.state.endRow
                        ),
                        services: [...this.state.services, ...services].slice(this.state.startRow, this.state.endRow),
                        rowsFetched: this.state.rowsFetched + categories.length
                    });
                }
                this.setState({ isLoading: false });
            } else {
                this.cancelEditInline();
                this.setState({
                    index: this.state.index + 1
                });
            }
        }
        scrollTop();
    }

    getCategoryId() {
        const query = queryString.parse(this.props.location.search);
        return query.categoryId;
    }

    getServiceId() {
        const query = queryString.parse(this.props.location.search);
        return query.serviceId;
    }

    getCategoryDetails() {
        const categoryId = this.getCategoryId();
        return this.state.categories.find(category => categoryId === category.id);
    }

    openCategoryDetails(categoryId) {
        this.setState({ categoryIdUpdated: categoryId });
        this.props.history.push(
            `/resources/location/${this.props.clinic}/services?${queryString.stringify({ categoryId })}`
        );
    }

    openServiceDetails(serviceId, serviceCopyId) {
        this.props.history.push(
            `/resources/location/${this.props.clinic}/services?${queryString.stringify({ serviceId, serviceCopyId })}`
        );
    }

    getPageItemsParams() {
        return `skip=${this.state.index * this.state.rowsPerPage}`;
    }

    async cloneService(serviceId) {
        try {
            this.setState({
                isLoading: true
            });
            const services = this.state.services.flat();
            const serviceToClone = await ServiceApi.get(serviceId);
            serviceToClone.copyOf = serviceToClone.id;
            delete serviceToClone.id;
            const previousName = serviceToClone.name;
            const amount = services.filter(
                s =>
                    s.category === serviceToClone.category &&
                    (s.name.startsWith(serviceToClone.name + ' - COPY') || s.name === serviceToClone.name)
            ).length;
            serviceToClone.name += ' - COPY'.repeat(amount === 1 ? 1 : amount + 1);
            serviceToClone.showDurationOnline = !!serviceToClone.showDurationOnline;
            serviceToClone.showPriceOnline = !!serviceToClone.showPriceOnline;
            serviceToClone.showOnline = !!serviceToClone.showOnline;
            serviceToClone.isAddOn = !!serviceToClone.isAddOn;
            serviceToClone.courseOnly = !!serviceToClone.courseOnly;
            serviceToClone.archived = !!serviceToClone.archived;
            serviceToClone.allowOnlineBooking = !!serviceToClone.allowOnlineBooking;
            serviceToClone.allowCommission = !!serviceToClone.allowCommission;
            serviceToClone.active = !!serviceToClone.active;

            serviceToClone.defaultTreatmentRecord = serviceToClone.defaultTreatmentRecord || undefined;
            if (!serviceToClone.subCategory) delete serviceToClone.subCategory;
            if (serviceToClone.formsToEmail) {
                serviceToClone.formsToEmail = serviceToClone.formsToEmail.map(el => ({ ...el, id: undefined }));
            }
            if (serviceToClone.tags?.length) {
                serviceToClone.tags = serviceToClone.tags.map(el => el.id);
            }
            const queryString = this.getFilters();
            await ServiceApi.create(serviceToClone);
            toastr.success(`Service ${previousName} successfully copied!`);
            const response = await CategoryApi.adminList(`${queryString}&${this.getPageItemsParams()}`);
            if (response?.services) {
                const updatedServices = this.state.services;
                const indexStart = this.state.index * this.state.rowsPerPage;
                const indexEnd = indexStart + response.services.length;
                let servicesIndex = 0;
                for (let i = indexStart; i < indexEnd; i++) {
                    updatedServices[i] = response.services[servicesIndex++];
                }
                this.setState({
                    services: updatedServices
                });
            }
        } catch (e) {
            toastr.error(e.data?.message || 'Something went wrong');
        } finally {
            this.setState({
                isLoading: false
            });
        }
    }

    async onCategoryDetailsConfirm() {
        this.setState({
            isLoading: true
        });
        const queryString = this.getFilters();
        const response = await CategoryApi.adminList(`${queryString}&${this.getPageItemsParams()}`);
        const { services, categories } = response;
        if (response) {
            const includesUpdateditem = categories.some(c => c.id === this.state.categoryIdUpdated);
            const itemProps =
                !includesUpdateditem && this.state.categoryIdUpdated
                    ? await CategoryApi.get(this.state.categoryIdUpdated)
                    : {};
            const serviceList = [...(this.state.services || [])];

            const newCategoriesList = this.state.categories.map((category, index) => {
                const categIndex = categories.findIndex(c => c.id === category.id);
                const isCategoryOnPayload = categIndex > -1;
                const newCategoryData = isCategoryOnPayload ? categories[categIndex] : {};
                if (isCategoryOnPayload) {
                    serviceList[index] = services[categIndex];
                }

                if (this.state.categoryIdUpdated === category.id && !includesUpdateditem) {
                    return {
                        ...(category || {}),
                        ...(itemProps || {})
                    };
                }
                return {
                    ...(category || {}),
                    ...(newCategoryData || {})
                };
            });
            if (categories.length > this.state.categories.length) {
                const newCat = categories.find(cat => this.state.categories.every(curCat => curCat.id !== cat.id));
                newCategoriesList.push(newCat);
                newCategoriesList.sort((a, b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
            }
            this.setState({
                services: serviceList,
                categories: newCategoriesList
            });
        }
        if (this.state.categoryIdUpdated) {
            this.props.history.push(`/resources/location/${this.props.clinic}/services`);
        }
        this.setState({
            isLoading: false
        });
    }

    async onServiceDetailsConfirm() {
        const serviceId = this.getServiceId();
        if (!serviceId || serviceId === 'new' || serviceId === 'Create') {
            this.props.history.push(`/resources/location/${this.props.clinic}/services`);
        }
        const queryString = this.getFilters();
        const response = await CategoryApi.adminList(`${queryString}&${this.getPageItemsParams()}`);
        const { services, categories } = response;
        if (response) {
            const serviceList = [...(this.state.services || [])];

            const newCategoriesList = this.state.categories.map((category, index) => {
                const categIndex = categories.findIndex(c => c.id === category.id);
                const isCategoryOnPayload = categIndex > -1;
                const newCategoryData = isCategoryOnPayload ? categories[categIndex] : {};

                if (isCategoryOnPayload) {
                    serviceList[index] = services[categIndex];
                }

                return {
                    ...(category || {}),
                    ...(newCategoryData || {})
                };
            });
            this.setState({
                services: serviceList,
                categories: newCategoriesList
            });
        }
    }

    onDetailsCancel() {
        this.props.history.push(`/resources/location/${this.props.clinic}/services`);
    }

    async deleteService() {
        if (!this.state.selectedService.id) return;
        try {
            const selectedService = this.state.selectedService;
            const value = this.props.isFromOrg
                ? !selectedService.archived
                : !getLocationItem(selectedService, this.props.clinic).archived;
            if (selectedService.category) {
                await ServiceApi.removeService(selectedService.id, this.props.isFromOrg, this.props.clinic, value);
                toastr.success(
                    `Service ${selectedService.name || ''} successfully ${
                        selectedService.archived ? 'unarchived' : 'archived'
                    }!`
                );
                return true;
            } else {
                await CategoryApi.archive(selectedService.id, value, this.props.clinic, this.props.isFromOrg);
                toastr.success(
                    `Category ${selectedService.name || ''} successfully ${
                        selectedService.archived ? 'unarchived' : 'archived'
                    }!`
                );
                await this.refreshList();
            }
        } catch (err) {
            if (typeof err === 'object') {
                if (err.data && err.data.message) {
                    toastr.error(err.data.message);
                    return;
                }
            }
            toastr.error('Something went wrong');
        }
    }

    handleChangePage(e, newPage) {
        this.changePage(newPage > this.state.index ? 1 : -1);
    }

    async handleChangeRowsPerPage(event) {
        const rowsPerPage = Number(event.target.value, 25);
        await this.setState({
            index: 0,
            rowsPerPage
        });
        this.refreshList();
    }

    async searchCategories(value) {
        const newValues = await CategoryApi.filter(value);
        this.setState({ categoriesOptions: newValues });
    }

    async searchSubCategories(value) {
        const newValues = await CategoryApi.filter(value, true);
        this.setState({ subCategoriesOptions: newValues });
    }

    async editService(service) {
        if (this.state.updatedServicesByActive.includes(service.id)) {
            service.active = !service.active;
        } // ---- Solution to don't rerender after change switch props?
        if (this.state.updatedServicesByShowOnline.includes(service.id)) {
            service.showOnline = !service.showOnline;
        }
        if (this.props.isFromOrg) {
            this.setState({
                editingRow: service.id,
                editing: {
                    name: service.name,
                    active: service.active,
                    defaultNetPrice: service.defaultNetPrice,
                    taxType: this.state.taxOptions.find(tax => tax.id === service.taxType),
                    serviceTime: service.defaultDuration,
                    price: service.defaultGrossPrice,
                    showOnline: service.showOnline,
                    defaultDuration: service.defaultDuration,
                    defaultGrossPrice: service.defaultGrossPrice
                },
                isEditing: true,
                services: this.state.services.map(services => {
                    return services.map(element => ({
                        ...element,
                        isEditing: element.id.toString() === service.id.toString()
                    }));
                })
            });
        } else {
            this.setState({
                editingRow: service.id,
                editing: {
                    active: getLocationItem(service, this.props.clinic).active,
                    defaultNetPrice: getLocationItem(service, this.props.clinic).netPrice,
                    taxType: this.state.taxOptions.find(
                        tax => tax.id === getLocationItem(service, this.props.clinic).taxType
                    ),
                    serviceTime: getLocationItem(service, this.props.clinic).defaultDuration,
                    price: getLocationItem(service, this.props.clinic).grossPrice,
                    showOnline: getLocationItem(service, this.props.clinic).showOnline,
                    defaultDuration: getLocationItem(service, this.props.clinic).defaultDuration,
                    defaultGrossPrice: getLocationItem(service, this.props.clinic).grossPrice
                },
                isEditing: true,
                services: this.state.services.map(services => {
                    return services.map(element => ({
                        ...element,
                        isEditing: element.id.toString() === service.id.toString()
                    }));
                })
            });
        }
    }

    async updateServiceInline(item) {
        const serviceId = this.state.editingRow;
        let data = this.state.editing;
        const oldService = item;
        try {
            if (!this.props.isFromOrg) {
                data = oldService;
                const newContent = this.state.editing;

                newContent.taxType = newContent.taxType.id;
                newContent.grossPrice = newContent.defaultGrossPrice;
                newContent.netPrice = newContent.defaultNetPrice;

                delete newContent.price;
                delete newContent.defaultGrossPrice;
                delete newContent.defaultNetPrice;
                delete newContent.serviceTime;

                data.locations = data.locations.map(loc => {
                    if (loc.clinic === this.props.clinic) {
                        loc = {
                            ...loc,
                            ...newContent
                        };
                        return loc;
                    } else {
                        return loc;
                    }
                });
            } else {
                data.taxType = this.state.editing.taxType.id;
                data.locations = oldService.locations;
            }
            const updatedRow = await ServiceApi.update(
                serviceId,
                data,
                this.props.isFromOrg,
                this.props.clinic !== 'organisation' ? this.props.clinic : ''
            );
            const newItemTax = this.state.taxOptions.find(tax => tax.id === updatedRow.taxType);
            const categories = this.state.categories.map(category => {
                const index = category.tableData.childRows
                    ? category.tableData.childRows.indexOf(
                          category.tableData.childRows.find(row => row.id === updatedRow.id)
                      )
                    : -1;
                if (index >= 0) {
                    const outdatedRow = category.tableData.childRows[index];

                    outdatedRow.defaultDuration = updatedRow.defaultDuration;
                    outdatedRow.defaultGrossPrice = updatedRow.defaultGrossPrice;
                    outdatedRow.defaultNetPrice = updatedRow.defaultNetPrice;
                    outdatedRow.name = updatedRow.name;
                    outdatedRow.onlineName = updatedRow.onlineName;
                    outdatedRow.order = updatedRow.order;
                    outdatedRow.taxType = updatedRow.taxType;
                    outdatedRow.showOnline = updatedRow.showOnline;
                    outdatedRow.itemTax = newItemTax;

                    category.tableData.childRows[index] = outdatedRow;
                }
                return category;
            });

            this.setState({
                categories
            });

            toastr.success('Service successfully updated!');
            this.cancelEditInline();
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong');
        }
    }

    cancelEditInline() {
        this.setState({
            editingRow: '',
            editing: {
                active: false,
                name: '',
                defaultNetPrice: 0,
                taxType: '',
                price: 0,
                showOnline: false,
                defaultDuration: 0,
                defaultGrossPrice: 0
            },
            isEditing: false
        });
    }

    async toDo() {
        if (this.state.modalTitle === 'Archive service' || this.state.modalTitle === 'Archive category') {
            const isServiceDeleted = await this.deleteService();
            if (isServiceDeleted) {
                const toUpdate = {};
                if (this.state.updatedServicesByArchive.includes(this.state.selectedService.id)) {
                    toUpdate.updatedServicesByArchive = this.state.updatedServicesByArchive.filter(
                        el => el !== this.state.selectedService.id
                    );
                } else {
                    toUpdate.updatedServicesByArchive = [
                        ...this.state.updatedServicesByArchive,
                        this.state.selectedService.id
                    ];
                }
                if (
                    !this.state.selectedService.active &&
                    this.state.updatedServicesByActive.includes(this.state.selectedService.id)
                ) {
                    toUpdate.updatedServicesByActive = this.state.updatedServicesByActive.filter(
                        el => el !== this.state.selectedService.id
                    );
                } else if (
                    this.state.selectedService.active &&
                    !this.state.updatedServicesByActive.includes(this.state.selectedService.id)
                ) {
                    toUpdate.updatedServicesByActive = [
                        ...this.state.updatedServicesByActive,
                        this.state.selectedService.id
                    ];
                }
                if (
                    !this.state.selectedService.showOnline &&
                    this.state.updatedServicesByShowOnline.includes(this.state.selectedService.id)
                ) {
                    toUpdate.updatedServicesByShowOnline = this.state.updatedServicesByShowOnline.filter(
                        el => el !== this.state.selectedService.id
                    );
                } else if (
                    this.state.selectedService.showOnline &&
                    !this.state.updatedServicesByShowOnline.includes(this.state.selectedService.id)
                ) {
                    toUpdate.updatedServicesByShowOnline = [
                        ...this.state.updatedServicesByShowOnline,
                        this.state.selectedService.id
                    ];
                }
                let categIndex;
                this.state.categories.forEach((categ, index) => {
                    if (categ.id === this.state.selectedService.category) categIndex = index;
                });
                if (typeof categIndex !== 'number') return;
                const childServices = (this.state.services ? this.state.services[categIndex] : []).filter(
                    el => el.category === this.state.selectedService.category
                );
                const availableServices = childServices.filter(data =>
                    this.checkService(data, toUpdate.updatedServicesByArchive, 'archived')
                );
                if (!availableServices.length) return this.refreshList();
                this.setState(toUpdate);
            }
        }
    }

    async openArchive(data) {
        this.setState({ selectedService: data });
        this.openConfirmModal(
            `Archive ${data.category ? 'service' : 'category'}`,
            `Are you sure you want to ${data.archived ? 'unarchive' : 'archive'} this ${
                data.category ? 'service' : 'category'
            }?`
        );
    }

    closeConfirmModal() {
        this.setState({
            modalTitle: '',
            confirmModal: false,
            modalContent: ''
        });
    }

    openConfirmModal(title, component) {
        this.setState({
            modalTitle: title,
            confirmModal: true,
            modalContent: component
        });
    }

    changeName(event) {
        if (this.state.typingTimeout) clearTimeout(this.state.typingTimeout);
        const self = this;
        this.setState({
            search: event.target.value,
            typing: false,
            typingTimeout: setTimeout(function() {
                self.refreshList();
            }, 800)
        });
    }

    async clearSearch() {
        const self = this;
        this.setState({
            search: '',
            typing: false,
            typingTimeout: setTimeout(function() {
                self.refreshList();
            }, 800)
        });
    }

    checkService(service, updatedSvcList, from) {
        if (from === 'inactive' && !this.state.showInactive) {
            if (
                service.active &&
                updatedSvcList.includes(service.id) &&
                !this.state.updatedServicesByArchive.includes(service.id)
            )
                return;
            if (this.state.updatedServicesByArchive.includes(service.id) && !this.state.showArchived) return;
        } else if (from === 'inactive' && this.state.showInactive) {
            if (this.state.updatedServicesByArchive.includes(service.id)) return;
        }
        if (from === 'archived' && !this.state.showArchived) {
            if (!service.archived && updatedSvcList.includes(service.id)) return;
            if (service.archived && updatedSvcList.includes(service.id) && !this.state.showInactive) return;
        } else if (this.state.showArchived && from === 'archived') {
            return;
        }
        return true;
    }

    render() {
        const startRow = this.state.index * this.state.rowsPerPage;
        const endRow = startRow + this.state.rowsPerPage;
        const categoriesSlice = this.state.categories.slice(startRow, endRow);
        const servicesSlice = this.state.services.slice(startRow, endRow);
        const servicesList = [];
        servicesSlice.map(element => {
            element.map(subElement => {
                servicesList.push(subElement);
                return subElement;
            });
            return element;
        });
        const rows = [...categoriesSlice, ...servicesList];
        const currentTaxValue = this.state.editing.taxType?.rate ?? 0;

        return (
            <View>
                <LoadingScreen isVisible={this.state.isLoading} />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        id="search-input"
                        onChange={this.changeName}
                        value={this.state.search}
                        variant="outlined"
                        className={this.props.classes.inputSearch}
                        InputProps={{
                            endAdornment: (
                                <>
                                    <IconButton
                                        className={this.props.classes.icons}
                                        onClick={this.clearSearch}
                                        disabled={!this.state.search}
                                    >
                                        <ClearIcon
                                            style={
                                                !this.state.search
                                                    ? { color: 'transparent', fontSize: 24 }
                                                    : { fontSize: 24 }
                                            }
                                        />
                                    </IconButton>
                                    <IconButton position="end" className={this.props.classes.icons}>
                                        <SearchIcon style={{ fontSize: 24 }} />
                                    </IconButton>
                                </>
                            )
                        }}
                    />
                    {(this.props.clinic === 'organisation' || this.props.allClinics.length === 1) && (
                        <IconButton onClick={() => this.openServiceDetails('new')}>
                            <AddCircleIcon className={this.props.classes.addCircleStyle} />
                        </IconButton>
                    )}
                </div>
                <div className={this.props.classes.header}>
                    <div style={{ display: 'flex' }}>
                        <Autocomplete
                            multiple
                            options={this.state.categoriesOptions}
                            getOptionLabel={option => option.name}
                            className={this.props.classes.autocomplete}
                            onChange={async (event, values) => {
                                await this.setState({
                                    categoriesFilter: values.map(element => element.id)
                                });
                                this.refreshList();
                            }}
                            onInputChange={(event, value) => {
                                this.searchCategories(value);
                            }}
                            freeSolo
                            renderInput={params => (
                                <TextField
                                    className={this.props.classes.autocompleteTextField}
                                    {...params}
                                    label="Category"
                                    variant="outlined"
                                />
                            )}
                            renderOption={option => {
                                return <p className={this.props.classes.autocompleteOptions}>{`${option.name}`}</p>;
                            }}
                            ChipProps={{ className: this.props.classes.autocompleteChipProps }}
                        />
                        <Autocomplete
                            multiple
                            options={this.state.subCategoriesOptions}
                            className={this.props.classes.autocomplete}
                            getOptionLabel={option => option.name}
                            onChange={async (event, values) => {
                                await this.setState({
                                    subCategoriesFilter: values.map(element => element.id)
                                });
                                this.refreshList();
                            }}
                            freeSolo
                            onInputChange={(event, value) => {
                                this.searchSubCategories(value);
                            }}
                            renderInput={params => (
                                <TextField
                                    className={this.props.classes.autocompleteTextField}
                                    {...params}
                                    label="Sub Category"
                                    variant="outlined"
                                />
                            )}
                            renderOption={option => {
                                return <p className={this.props.classes.autocompleteOptions}>{`${option.name}`}</p>;
                            }}
                            ChipProps={{ className: this.props.classes.autocompleteChipProps }}
                        />
                        <Autocomplete
                            multiple
                            freeSolo
                            className={this.props.classes.autocomplete}
                            options={this.state.taxOptions || []}
                            getOptionLabel={option => option.rate}
                            onChange={async (event, values) => {
                                await this.setState({
                                    taxFilter: values
                                });
                                this.refreshList();
                            }}
                            renderInput={params => (
                                <TextField
                                    className={this.props.classes.autocompleteTextField}
                                    {...params}
                                    label="Tax Type"
                                    variant="outlined"
                                />
                            )}
                            renderOption={option => {
                                return <p className={this.props.classes.autocompleteOptions}>{`${option.rate}%`}</p>;
                            }}
                            ChipProps={{ className: this.props.classes.autocompleteChipProps }}
                        />
                    </div>
                    <div className={this.props.classes.checkboxesContainer}>
                        <Checkbox
                            onChange={async () => {
                                await this.setState({
                                    showArchived: !this.state.showArchived,
                                    isLoading: true,
                                    categories: [],
                                    services: [],
                                    size: 0,
                                    index: 0,
                                    maxPage: 0,
                                    rowsFetched: 0
                                });
                                this.refreshList();
                            }}
                        />
                        <label>Show Archived</label>

                        {/*<Checkbox*/}
                        {/*    checked={this.state.showInactive}*/}
                        {/*    onChange={async () => {*/}
                        {/*        await this.setState({*/}
                        {/*            showInactive: !this.state.showInactive,*/}
                        {/*            isLoading: true,*/}
                        {/*            categories: [],*/}
                        {/*            services: [],*/}
                        {/*            size: 0,*/}
                        {/*            index: 0,*/}
                        {/*            maxPage: 0,*/}
                        {/*            rowsFetched: 0*/}
                        {/*        });*/}
                        {/*        this.refreshList();*/}
                        {/*    }}*/}
                        {/*/>*/}
                        {/*<label>Show Inactive</label>*/}
                    </div>
                </div>

                {this.state.categories.length > 0 && (
                    <ListTable
                        tableClasses={this.props.classes.table}
                        data={rows}
                        columns={[
                            {
                                title: 'Active',
                                field: 'active',
                                render: item => {
                                    let isActive = this.props.isFromOrg
                                        ? item?.active
                                        : getLocationItem(item, this.props.clinic)?.active;
                                    let isArchived = this.props.isFromOrg
                                        ? item?.archived
                                        : getLocationItem(item, this.props.clinic)?.archived;
                                    // if (item) {
                                    //     if (this.state.updatedServicesByActive.includes(item.id)) {
                                    //         isActive = this.props.isFromOrg
                                    //             ? !item?.active
                                    //             : !getLocationItem(item, this.props.clinic)?.active;
                                    //     } else if (this.state.updatedCategoriesByActive.includes(item.id)) {
                                    //         isActive = this.props.isFromOrg
                                    //             ? !item?.active
                                    //             : !getLocationItem(item, this.props.clinic)?.active;
                                    //     }
                                    //     if (
                                    //         (this.props.isFromOrg
                                    //             ? item?.archived
                                    //             : getLocationItem(item, this.props.clinic)?.archived) &&
                                    //         !this.state.updatedServicesByArchive.includes(item.id)
                                    //     ) {
                                    //         isActive = false;
                                    //     } else if (
                                    //         (this.props.isFromOrg
                                    //             ? !item?.archived
                                    //             : !getLocationItem(item, this.props.clinic)?.archived) &&
                                    //         this.state.updatedServicesByArchive.includes(item.id)
                                    //     ) {
                                    //         isActive = false;
                                    //     }
                                    // }
                                    if (this.state.isEditing) {
                                        if (this.state.editingRow.toString() === item.id.toString()) {
                                            return (
                                                <Switch
                                                    checked={this.state.editing.active}
                                                    onChange={() =>
                                                        this.setState({
                                                            editing: {
                                                                ...this.state.editing,
                                                                active: !this.state.editing.active
                                                            }
                                                        })
                                                    }
                                                />
                                            );
                                        }
                                    }
                                    return (
                                        <Switch
                                            checked={isActive}
                                            disabled={isArchived}
                                            onChange={async () => {
                                                const response = await this.changeServiceActive(item);
                                                if (response) {
                                                    const toUpdate = {};
                                                    if (!item.active) {
                                                        if (item.category) {
                                                            const wasChanged = this.state.categories.some(
                                                                categ => categ.id === item.category && !categ.active
                                                            );
                                                            if (
                                                                wasChanged &&
                                                                !this.state.updatedCategoriesByActive.includes(
                                                                    item.category
                                                                )
                                                            ) {
                                                                toUpdate.updatedCategoriesByActive = toUpdate.updatedCategoriesByActive = [
                                                                    ...this.state.updatedCategoriesByActive,
                                                                    item.category
                                                                ];
                                                            }
                                                        }
                                                    }

                                                    if (this.state.updatedServicesByActive.includes(item.id)) {
                                                        toUpdate.updatedServicesByActive = this.state.updatedServicesByActive.filter(
                                                            el => el !== item.id
                                                        );
                                                    } else {
                                                        toUpdate.updatedServicesByActive = [
                                                            ...this.state.updatedServicesByActive,
                                                            item.id
                                                        ];
                                                    }

                                                    if (item.active && item.category && item.showOnline) {
                                                        if (!this.state.updatedServicesByShowOnline.includes(item.id)) {
                                                            toUpdate.updatedServicesByShowOnline = [
                                                                ...this.state.updatedServicesByShowOnline,
                                                                item.id
                                                            ];
                                                        }
                                                    } else {
                                                        if (
                                                            !this.state.updatedCategoriesByShowOnline.includes(
                                                                item.id
                                                            ) &&
                                                            item.showOnline
                                                        ) {
                                                            toUpdate.updatedCategoriesByShowOnline = [
                                                                ...this.state.updatedCategoriesByShowOnline,
                                                                item.id
                                                            ];
                                                        }
                                                    }

                                                    if (
                                                        item.active &&
                                                        item.category &&
                                                        toUpdate.updatedServicesByActive &&
                                                        toUpdate.updatedServicesByActive.length
                                                    ) {
                                                        let categIndex;
                                                        this.state.categories.forEach((categ, index) => {
                                                            if (categ.id === item.category) categIndex = index;
                                                        });
                                                        if (typeof categIndex !== 'number') return;
                                                        const childServices = (this.state.services
                                                            ? this.state.services[categIndex]
                                                            : []
                                                        ).filter(el => el.category === item.category);
                                                        const availableServices = childServices.filter(data =>
                                                            this.checkService(
                                                                data,
                                                                toUpdate.updatedServicesByActive,
                                                                'inactive'
                                                            )
                                                        );
                                                        if (!availableServices.length) return this.refreshList();
                                                    }

                                                    this.setState(toUpdate);
                                                }
                                            }}
                                        />
                                    );
                                }
                            },
                            {
                                title: 'Name',
                                field: 'name',
                                cellStyle: { width: '40%' },
                                render: item => {
                                    if (1 === item.tableData.path.length) {
                                        return (
                                            <Box
                                                onClick={() => this.openCategoryDetails(item.id)}
                                                style={{ display: 'flex' }}
                                            >
                                                <StopIcon style={{ color: item.color }} fontSize="small" />
                                                <Typography>
                                                    <Link style={{ textDecoration: 'none', color: '#2b78e4' }}>
                                                        {item.name}
                                                    </Link>
                                                </Typography>
                                            </Box>
                                        );
                                    }
                                    if (this.state.isEditing && this.props.isFromOrg) {
                                        if (this.state.editingRow.toString() === item.id.toString()) {
                                            return (
                                                <EditableCell
                                                    onBlur={v => {
                                                        this.setState({ editing: { ...this.state.editing, name: v } });
                                                    }}
                                                    initValue={this.state.editing.name}
                                                />
                                            );
                                        }
                                    }
                                    return (
                                        <Box
                                            onClick={() => this.openServiceDetails(item.id)}
                                            style={{ display: 'flex' }}
                                        >
                                            <StopIcon style={{ color: item.colour }} fontSize="small" />
                                            <Typography>
                                                <Link style={{ textDecoration: 'none', color: '#3083EC' }}>
                                                    {item.name}
                                                </Link>
                                            </Typography>
                                        </Box>
                                    );
                                }
                            },
                            {
                                title: 'Parent Category',
                                render: item => {
                                    if (item.parentCategoryObj && item.parentCategoryObj[0]) {
                                        return <Typography>{item.parentCategoryObj[0].name}</Typography>;
                                    }
                                    return <></>;
                                }
                            },
                            {
                                title: 'Net Price',
                                field: 'defaultNetPrice',
                                render: item => {
                                    if (this.state.isEditing) {
                                        if (this.state.editingRow.toString() === item.id.toString()) {
                                            return (
                                                <EditableCell
                                                    type="NumericalText"
                                                    min={0}
                                                    onBlur={v => {
                                                        v = Number(v);
                                                        const defaultGrossPrice = (
                                                            v *
                                                            (1 + currentTaxValue / 100)
                                                        ).toFixed(2);
                                                        return this.setState({
                                                            editing: {
                                                                ...this.state.editing,
                                                                defaultNetPrice: v,
                                                                defaultGrossPrice
                                                            }
                                                        });
                                                    }}
                                                    initValue={this.state.editing.defaultNetPrice}
                                                />
                                            );
                                        }
                                    }
                                    return (
                                        <Typography style={{ textAlign: 'right' }}>
                                            {toLocaleString(
                                                this.props.isFromOrg
                                                    ? item.defaultNetPrice
                                                    : getLocationItem(item, this.props.clinic)?.netPrice
                                            )}
                                        </Typography>
                                    );
                                }
                            },
                            {
                                title: 'Tax Type',
                                field: 'taxType',
                                render: item => {
                                    if (this.state.isEditing) {
                                        if (this.state.editingRow.toString() === item.id.toString()) {
                                            return (
                                                <Select
                                                    style={{ width: '100%' }}
                                                    onChange={e => {
                                                        const defaultGrossPrice = (
                                                            this.state.editing.defaultNetPrice *
                                                            (1 + (e.target.value?.rate / 100 || 0))
                                                        ).toFixed(2);
                                                        return this.setState({
                                                            editing: {
                                                                ...this.state.editing,
                                                                taxType: e.target.value,
                                                                defaultGrossPrice
                                                            }
                                                        });
                                                    }}
                                                    defaultValue={this.state.editing.taxType}
                                                >
                                                    {Boolean(this.state.taxOptions.length) &&
                                                        this.state.taxOptions.map(item => (
                                                            <MenuItem value={item} key={item.id}>
                                                                {item?.rate}%
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            );
                                        }
                                    }
                                    const renderTypographyText = (() => {
                                        if (this.props.isFromOrg) {
                                            return item.itemTax?.name;
                                        }
                                        return this.state.taxOptions.find(
                                            tax => tax.id === getLocationItem(item, this.props.clinic)?.taxType
                                        )?.name;
                                    })();
                                    return <Typography>{renderTypographyText}</Typography>;
                                }
                            },
                            {
                                title: 'Service Time',
                                field: 'defaultDuration',
                                render: item => {
                                    if (2 === item.tableData.path.length) {
                                        if (this.state.isEditing && this.props.isFromOrg) {
                                            if (this.state.editingRow.toString() === item.id.toString()) {
                                                return (
                                                    <EditableCell
                                                        type="number"
                                                        min={0}
                                                        onBlur={v => {
                                                            this.setState({
                                                                editing: { ...this.state.editing, defaultDuration: v }
                                                            });
                                                        }}
                                                        initValue={this.state.editing.defaultDuration}
                                                    />
                                                );
                                            }
                                        }
                                        return (
                                            <Typography>{`${
                                                this.props.isFromOrg
                                                    ? item.defaultDuration
                                                    : getLocationItem(item, this.props.clinic)?.defaultDuration ||
                                                      '00:00'
                                            } minutes`}</Typography>
                                        );
                                    }
                                    return <></>;
                                }
                            },
                            {
                                title: 'Price',
                                field: 'defaultGrossPrice',
                                render: item => {
                                    if (this.state.isEditing) {
                                        if (this.state.editingRow.toString() === item.id.toString()) {
                                            return (
                                                <EditableCell
                                                    type="NumericalText"
                                                    min={0}
                                                    onBlur={v => {
                                                        v = Number(v);
                                                        const defaultNetPrice = (
                                                            v /
                                                            (currentTaxValue / 100 + 1)
                                                        ).toFixed(2);
                                                        return this.setState({
                                                            editing: {
                                                                ...this.state.editing,
                                                                defaultGrossPrice: v,
                                                                defaultNetPrice
                                                            }
                                                        });
                                                    }}
                                                    initValue={this.state.editing.defaultGrossPrice}
                                                />
                                            );
                                        }
                                    }
                                    return (
                                        <Typography style={{ textAlign: 'right' }}>
                                            {toLocaleString(
                                                this.props.isFromOrg
                                                    ? item.defaultGrossPrice
                                                    : getLocationItem(item, this.props.clinic)?.grossPrice
                                            )}
                                        </Typography>
                                    );
                                }
                            },
                            {
                                title: 'Online',
                                field: 'showOnline',
                                render: item => {
                                    let showOnline = item?.showOnline || false;
                                    let isArchived = item?.archived || false;
                                    let active = item?.active || false;
                                    if (this.state.isEditing) {
                                        if (this.state.editingRow.toString() === item.id.toString()) {
                                            return (
                                                <Switch
                                                    disabled={isArchived}
                                                    checked={this.state.editing.showOnline}
                                                    onChange={() => {
                                                        this.setState({
                                                            editing: {
                                                                ...this.state.editing,
                                                                showOnline: !this.state.editing.showOnline
                                                            }
                                                        });
                                                    }}
                                                />
                                            );
                                        }
                                    }
                                    return (
                                        <Switch
                                            disabled={
                                                isArchived ||
                                                !(this.props.isFromOrg
                                                    ? active
                                                    : getLocationItem(item, this.props.clinic)?.active)
                                            }
                                            checked={
                                                this.props.isFromOrg
                                                    ? showOnline
                                                    : getLocationItem(item, this.props.clinic)?.showOnline
                                            }
                                            onChange={async () => {
                                                const response = await this.changeShowOnline(item);
                                                if (response) {
                                                    this.setState({
                                                        services: this.state.services.map(service => {
                                                            return service.map(s => {
                                                                if (response.id === s.id) {
                                                                    if (!this.props.isFromOrg) {
                                                                        return {
                                                                            ...s,
                                                                            locations: response.locations
                                                                        };
                                                                    }
                                                                    return { ...s, showOnline: response.showOnline };
                                                                }
                                                                return { ...s };
                                                            });
                                                        })
                                                    });
                                                }
                                            }}
                                        />
                                    );
                                }
                            },
                            {
                                title: '',
                                render: item => {
                                    let isArchived = this.props.isFromOrg
                                        ? item.archived
                                        : getLocationItem(item, this.props.clinic)?.archived;
                                    if (isArchived && this.state.updatedServicesByArchive.includes(item.id)) {
                                        isArchived = false;
                                    } else if (!isArchived && this.state.updatedServicesByArchive.includes(item.id)) {
                                        isArchived = true;
                                    }
                                    if (this.state.isEditing) {
                                        if (this.state.editingRow.toString() === item.id.toString()) {
                                            return (
                                                <div style={{ display: 'flex' }}>
                                                    <IconButton onMouseUp={() => this.updateServiceInline(item)}>
                                                        <CheckIcon style={{ fontSize: 20 }} />
                                                    </IconButton>
                                                    <IconButton onClick={() => this.cancelEditInline()}>
                                                        <ClearIcon style={{ fontSize: 20 }} />
                                                    </IconButton>
                                                </div>
                                            );
                                        }
                                    }
                                    return (
                                        <ActionButton
                                            isFromOrg={this.props.isFromOrg}
                                            clinic={this.props.clinic}
                                            editService={this.editService}
                                            from={item.tableData.path.length === 1 ? 'category' : 'service'}
                                            deleteService={this.openArchive}
                                            item={item}
                                            isArchived={isArchived}
                                            classes={this.props.classes}
                                            cloneService={this.cloneService}
                                        />
                                    );
                                }
                            }
                        ]}
                        rowStyle={rowData => {
                            if ((rowData || {}).category) {
                                let isArchived = this.props.isFromOrg
                                    ? rowData.archived
                                    : getLocationItem(rowData, this.props.clinic)?.archived;
                                if (isArchived && this.state.updatedServicesByArchive.includes(rowData.id)) {
                                    isArchived = false;
                                } else if (!isArchived && this.state.updatedServicesByArchive.includes(rowData.id)) {
                                    isArchived = true;
                                }
                                return {
                                    backgroundColor: isArchived ? '#ff9e9e' : '#f0f0f0'
                                };
                            }
                            return {
                                backgroundColor: (() => {
                                    if (
                                        this.props.isFromOrg
                                            ? !rowData.archived
                                            : !getLocationItem(rowData, this.props.clinic)?.archived
                                    ) {
                                        return 'white';
                                    }
                                    return '#ff9e9e';
                                })()
                            };
                        }}
                        parentChildData={(row, rows) => {
                            const childRows = rows.find(a => {
                                const rowCat = this.props.isFromOrg
                                    ? row.category
                                    : getLocationItem(row, this.props.clinic)?.category;

                                if (rowCat) {
                                    if (
                                        !this.state.showArchived &&
                                        this.state.updatedServicesByArchive.includes(row.id)
                                    )
                                        return false;
                                    if (!this.state.showInactive && this.state.showArchived) {
                                        if (
                                            row.active &&
                                            this.state.updatedServicesByActive.includes(row.id) &&
                                            !this.state.updatedServicesByArchive.includes(row.id)
                                        )
                                            return false;
                                        if (row.archived && this.state.updatedServicesByArchive.includes(row.id))
                                            return false;
                                    } else if (!this.state.showInactive) {
                                        if (!row.active) return false;
                                        if (row.active && this.state.updatedServicesByActive.includes(row.id))
                                            return false;
                                    }
                                    return a.id === rowCat;
                                }
                                return false;
                            });

                            if (childRows && childRows?.tableData?.childRows) {
                                childRows.tableData.childRows.push(row);
                                // eslint-disable-next-line no-unused-expressions
                                // childRows.tableData.childRows.sort((a, b) => {
                                //     return a.onlineName?.localeCompare(b.onlineName);
                                // });
                            }

                            return childRows;
                        }}
                        onEdit={(newData, oldData) => {
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    this.updateService(newData, oldData);
                                    resolve();
                                }, 1000);
                            });
                        }}
                        customComponents={{
                            Row: rowProps => {
                                if (rowProps) {
                                    if (rowProps.data?.category && rowProps.level === 0) return <></>;
                                }
                                return <MTableBodyRow {...rowProps} />;
                            }
                        }}
                    />
                )}
                <div className={this.props.classes.paginationContainer}>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        width={'100%'}
                        className={this.props.classes.tablePagination}
                        count={this.state.size}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.index}
                        SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </div>
                {Boolean(this.getCategoryId()) && (
                    <CategoriesModal
                        isFromOrg={this.props.isFromOrg}
                        key={this.getCategoryId()}
                        categoryId={this.getCategoryId()}
                        category={this.getCategoryDetails()}
                        onConfirm={this.onCategoryDetailsConfirm}
                        onCancel={this.onDetailsCancel}
                        methodType={'Update'}
                        clinic={this.props.clinic}
                        allClinics={this.props.allClinics}
                    />
                )}
                {/* <ServiceDetailsModal
                    key={this.getServiceId()}
                    serviceId={this.getServiceId()}
                    services={this.state.services}
                    categories={this.state.categories}
                    onConfirm={this.onServiceDetailsConfirm}
                    onCancel={this.onDetailsCancel}
                /> */}
                {this.state.confirmModal && (
                    <ConfirmModal
                        isOpen
                        setIsOpen={this.closeConfirmModal}
                        onConfirm={this.toDo}
                        title={this.state.modalTitle}
                        content={this.state.modalContent}
                    />
                )}
                {!!this.getServiceId() && (
                    <ServiceFormModal
                        serviceTaxes={this.state.taxOptions}
                        onClose={this.onDetailsCancel}
                        id={this.getServiceId()}
                        onConfirm={() => this.onServiceDetailsConfirm()}
                        clinic={this.props.clinic}
                        isFromOrg={this.props.isFromOrg}
                        onCategoryConfirm={() => this.onCategoryDetailsConfirm()}
                    />
                )}
                <CancelContinueModal
                    title="Unable to show online"
                    open={this.state.isServiceUpdateModalOpen}
                    setOpen={() => {
                        this.setState({
                            isServiceUpdateModalOpen: false
                        });
                    }}
                    contentText={'This setting must be enabled at Organisation level before you can enable it here'}
                    cancelButtonText="Continue"
                    continueButtonText="Take me to org level"
                    onCancel={() => {
                        this.setState({
                            isServiceUpdateModalOpen: false
                        });
                    }}
                    onContinue={() => {
                        this.props.history.push('/resources/location/organisation/services');
                    }}
                />
            </View>
        );
    }
}

ServicesView.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
};

export default withRouter(
    withStyles(theme => ({ ...inputSearchStyles(theme), ...buttonsStyles(theme), ...servicesViewStyles(theme) }))(
        ServicesView
    )
);
