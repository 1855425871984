import { Checkbox, List, ListItem, Divider, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import { listSelectorStyles } from './styles';

function SelectionList({ options, control, disabled, name, label, rules, defaultValue, isPaginated = false, action }) {
    const classes = makeStyles(listSelectorStyles)();

    return (
        <div>
            <b>{label}</b>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={rules}
                render={({ onChange, value }) => {
                    const handleToggle = option => {
                        const currentIndex = (value || []).findIndex(v => v === option.value);
                        const newChecked = [...(value || [])];

                        if (currentIndex === -1) {
                            newChecked.push(option.value);
                        } else {
                            newChecked.splice(currentIndex, 1);
                        }
                        onChange(newChecked);
                    };
                    return (
                        <List
                            className={classes.listContainer}
                            onScroll={e => {
                                const element = e.target;
                                const scrollPosition = element.scrollTop;
                                if (
                                    isPaginated &&
                                    action &&
                                    element.scrollTop / (element.scrollHeight - element.clientHeight) >= 1
                                )
                                    action().then(() => {
                                        element.scrollTop = scrollPosition;
                                    });
                            }}
                        >
                            {!options.length && 'Loading...'}
                            {options.map((option, index) => (
                                <>
                                    <ListItem
                                        disabled={disabled}
                                        key={index}
                                        role={undefined}
                                        dense
                                        button
                                        onClick={() => handleToggle(option)}
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={value && value.some(v => v === option.value)}
                                                tabIndex={-1}
                                                disableRipple
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={option.label} secondary={option.subtitle} />
                                    </ListItem>
                                    <Divider />
                                </>
                            ))}
                        </List>
                    );
                }}
            />
        </div>
    );
}

SelectionList.propTypes = {
    options: PropTypes.array,
    control: PropTypes.any,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    rules: PropTypes.array,
    defaultValue: PropTypes.array,
    isPaginated: PropTypes.bool,
    action: PropTypes.func
};

export default SelectionList;
