import PropTypes from 'prop-types';
import { Button, withStyles, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toastr } from 'react-redux-toastr';
import CoursesCategoryApi from '../../api/CourseCategoriesApi';
import Modal from '../common/Modal';
import Accordion from '../common/Accordion';
import GeneralTab from './tabs/GeneralTab';
import LocationTab from '../common/LocationTab';
import CommissionTab from './tabs/CommissionTab';

import { courseCategoryModalStyles } from './styles';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import ConfirmModal from '../common/ConfirmModal';

const _ = require('lodash');

function CourseCategoryFormModal({
    classes,
    visible,
    clinic,
    onClose,
    Category,
    isFromOrg,
    onConfirm,
    clinics = [],
    allClinics
}) {
    const globalStyles = makeStyles(modalsButtonStyles)();
    const category = Category || {};
    const [categories, setCategories] = useState([]);
    const [parent, setParent] = useState(null);

    const [openConfirm, setOpenConfirm] = useState(false);

    useState(() => {
        const loadCategoryFilter = async () => {
            try {
                const fetchedCategories = await CoursesCategoryApi.filter('', false, isFromOrg);
                if (category.parentCategory) {
                    const categoryFetched = await CoursesCategoryApi.getCategory(category.parentCategory);
                    if (!categoryFetched) return;
                    const parentCategModel = ['id', 'name'];
                    const parentCateg = _.pick(categoryFetched, parentCategModel);
                    if (fetchedCategories.find(el => el.id === parentCateg.id)) {
                        setCategories([parentCateg, ...fetchedCategories.filter(el => el.id !== parentCateg.id)]);
                    } else setCategories([parentCateg, ...fetchedCategories]);
                    setParent(parentCateg);
                } else setCategories(fetchedCategories);
            } catch (err) {
                return toastr.error(err?.data?.message || 'Something went wrong');
            }
        };

        loadCategoryFilter();
    }, []);

    const searchCategories = async value => {
        try {
            const newValues = await CoursesCategoryApi.filter(value, false, isFromOrg);
            setCategories(newValues);
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        }
    };

    const { register, handleSubmit, control, watch, errors, setValue, getValues } = useForm({
        defaultValues: {
            category: Category
        }
    });

    useEffect(() => {
        const requiredFields = Object.keys(errors);

        if (requiredFields.length !== 0) {
            const messages = requiredFields.map(field => errors[field].message);

            toastr.error('Missing fields', messages.join(','));
        }
    }, [errors]);

    const formRef = useRef();
    const fields = watch();

    const tabProps = {
        categories,
        clinics,
        clinic,
        isFromOrg,
        control,
        register,
        watch,
        fields,
        setValue,
        getValues,
        parent,
        setParent,
        category,
        allClinics
    };

    const checkDifferences = (current, payload) => {
        let isTheSame = true;
        let straightSave = true;

        Object.keys(payload).forEach(key => {
            if (!Array.isArray(payload[key]) && current[key] !== payload[key]) {
                isTheSame = false;
                if (key === 'color' || key === 'name') {
                    straightSave = false;
                }
            }
        });

        if (straightSave && !isTheSame) {
            return onConfirm(payload);
        }

        return setOpenConfirm(true);
    };

    return (
        <>
            <Modal
                isOpen={visible}
                title={category.id !== undefined ? `View/Edit ${category.name} Course Category` : 'New Course Category'}
                id="category"
                onClose={onClose}
                onCancel={onClose}
            >
                <form ref={formRef} className={classes.accordionsForm}>
                    <Accordion title={'General'}>
                        {' '}
                        <GeneralTab {...tabProps} searchCategories={searchCategories} key="general" />{' '}
                    </Accordion>
                    <Accordion title={'Locations'} hideItem={!isFromOrg}>
                        {' '}
                        <LocationTab {...tabProps} key="pricing_location" />{' '}
                    </Accordion>
                    <Accordion title={'Commissions'}>
                        {' '}
                        <CommissionTab {...tabProps} key="commission" />{' '}
                    </Accordion>

                    <div className={globalStyles.buttonsContainer}>
                        <Button className={globalStyles.cancelButton} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            className={globalStyles.confirmButton}
                            onClick={handleSubmit(data => {
                                if (category.id) {
                                    checkDifferences(category, data);
                                } else {
                                    onConfirm(data);
                                }
                            })}
                        >
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>
            {openConfirm && category.id !== undefined && (
                <ConfirmModal
                    isOpen
                    setIsOpen={setOpenConfirm}
                    onConfirm={handleSubmit(data => onConfirm(data))}
                    onClose={() => setOpenConfirm(false)}
                    title={'Confirm Editing'}
                    centerContent={true}
                    contentHtml={`${
                        getValues().name !== Category.prevName
                            ? 'Changing the name of this category will also affect historic appointments, invoices, reports and anywhere else it features.<br>' +
                              'If you wish to keep historic records the same but have this change for the future, please inactivate this category and create a new one.<br><br>'
                            : ''
                    } These changes will be replicated to all courses in that category. Are you sure?`}
                />
            )}
        </>
    );
}

CourseCategoryFormModal.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func.isRequired
};

export default withStyles(courseCategoryModalStyles)(CourseCategoryFormModal);
