import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import GoogleMapReact from 'google-map-react';
import Marker from './Marker/Marker';
import { locationMap } from './styles';

const LocationMap = ({ location, classes, onChange, newLocation }) => {
    const getCoordinates = useCallback(() => {
        return {
            lat: parseFloat(location.latitude),
            lng: parseFloat(location.longitude)
        };
    }, [location]);

    const hasCoordinates = useCallback(() => {
        return !!(location?.latitude && location?.longitude);
    }, [location]);

    const handleMapClick = ({ lat, lng }) => {
        onChange({ lat, lng });
    };

    const handleNewMarkerClick = () => {
        onChange({});
    };

    return (
        <div className={`${classes.mapWrapper}`}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
                defaultCenter={{
                    lat: 51.512545,
                    lng: -1.18317
                }}
                center={hasCoordinates() ? getCoordinates() : null}
                zoom={hasCoordinates() ? 13 : 7}
                yesIWantToUseGoogleMapApiInternals
                onClick={handleMapClick}
            >
                {hasCoordinates() && (
                    <Marker
                        lat={getCoordinates().lat}
                        lng={getCoordinates().lng}
                        isOld={!!Object.keys(newLocation).length}
                    />
                )}
                {newLocation ? (
                    <Marker lat={newLocation.lat} lng={newLocation.lng} onClick={handleNewMarkerClick} />
                ) : (
                    ''
                )}
            </GoogleMapReact>
        </div>
    );
};

LocationMap.propTypes = {
    location: PropTypes.object.isRequired
};

export default withStyles(locationMap)(LocationMap);
