import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Paper, withStyles, Typography, makeStyles } from '@material-ui/core';
import apps from '../../config';

import { TaxInUseModalStyles } from './styles';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
function TaxInUseModal({ setModalVisible, classes, itemsUsingTax, tax, method }) {
    const globalStyles = makeStyles(modalsButtonStyles)();
    return (
        <Modal
            open={true}
            disableBackdropClick={false}
            onClose={() => setModalVisible(false)}
            className={classes.modal}
            onCancel={() => setModalVisible(false)}
        >
            {!itemsUsingTax.products && !itemsUsingTax.services ? (
                <p>waiting data</p>
            ) : (
                <Paper className={classes.root}>
                    <div className={classes.header}>
                        <Typography>You cannot {method} a tax that has items using it.</Typography>
                    </div>
                    <div className={classes.usingTaxContainer}>
                        {itemsUsingTax?.services?.length !== 0 && (
                            <div className={classes.resourceUsingTax}>
                                <Typography className={classes.subtitle}>Services:</Typography>
                                <ul>
                                    {itemsUsingTax.services.map((service, idx) => {
                                        const orgIsUsingTax = service.taxType?.toString() === tax.id.toString();
                                        return (
                                            <li key={idx}>
                                                <Typography>
                                                    <a
                                                        className={classes.linkItem}
                                                        target={'_blank'}
                                                        rel="noopener noreferrer"
                                                        href={
                                                            orgIsUsingTax &&
                                                            `${apps.adminUrl}/resources/location/organisation/services?serviceId=${service.id}`
                                                        }
                                                    >
                                                        {service.name}
                                                    </a>
                                                </Typography>
                                                {service?.locationsUsingTax?.length > 0 && (
                                                    <ul>
                                                        {service.locationsUsingTax.map((loc, idx) => (
                                                            <li key={idx}>
                                                                <Typography>
                                                                    <a
                                                                        className={classes.linkItem}
                                                                        target={'_blank'}
                                                                        rel="noopener noreferrer"
                                                                        href={`${apps.adminUrl}/resources/location/${loc.clinicId}/services?serviceId=${service.id}`}
                                                                    >
                                                                        {loc.clinicName}
                                                                    </a>
                                                                </Typography>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}
                        {itemsUsingTax?.products?.length !== 0 && (
                            <div className={classes.resourceUsingTax}>
                                <Typography className={classes.subtitle}>Products:</Typography>
                                <ul>
                                    {itemsUsingTax.products.map((product, idx) => {
                                        const orgIsUsingTax = product.tax?.toString() === tax.id.toString();
                                        return (
                                            <li key={idx}>
                                                <Typography>
                                                    <a
                                                        className={classes.linkItem}
                                                        target={'_blank'}
                                                        rel="noopener noreferrer"
                                                        href={
                                                            orgIsUsingTax &&
                                                            `${apps.adminUrl}/resources/location/organisation/products`
                                                            // ---- Modal needs to open based on resource id on query as it is on services
                                                            // `${apps.adminUrl}/resources/location/organisation/products?productId=${product.id}`
                                                        }
                                                    >
                                                        {product.name}
                                                    </a>
                                                </Typography>
                                                {product?.locationsUsingTax?.length > 0 && (
                                                    <ul>
                                                        {product.locationsUsingTax.map((loc, idx) => (
                                                            <li key={idx}>
                                                                <Typography>
                                                                    <a
                                                                        className={classes.linkItem}
                                                                        target={'_blank'}
                                                                        rel="noopener noreferrer"
                                                                        href={`${apps.adminUrl}/resources/location/${loc.clinicId}/products`}
                                                                        // ---- Modal needs to open based on resource id on query as it is on services
                                                                        // href={`${apps.adminUrl}/resources/location/${loc.clinicId}/products?productId=${product.id}`}
                                                                    >
                                                                        {loc.clinicName}
                                                                    </a>
                                                                </Typography>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}
                        {itemsUsingTax?.courses?.length !== 0 && (
                            <div className={classes.resourceUsingTax}>
                                <Typography className={classes.subtitle}>Courses:</Typography>
                                <ul>
                                    {itemsUsingTax.courses.map((course, idx) => {
                                        const orgIsUsingTax = course.tax?.toString() === tax.id.toString();
                                        return (
                                            <li key={idx}>
                                                <Typography>
                                                    <a
                                                        className={classes.linkItem}
                                                        target={'_blank'}
                                                        rel="noopener noreferrer"
                                                        href={
                                                            orgIsUsingTax &&
                                                            `${apps.adminUrl}/resources/location/organisation/courses`
                                                            // ---- Modal needs to open based on resource id on query as it is on services
                                                            // `${apps.adminUrl}/resources/location/organisation/courses?courseId=${course.id}`
                                                        }
                                                    >
                                                        {course.name}
                                                    </a>
                                                </Typography>
                                                {course?.locationsUsingTax?.length > 0 && (
                                                    <ul>
                                                        {course.locationsUsingTax.map((loc, idx) => (
                                                            <li key={idx}>
                                                                <Typography>
                                                                    <a
                                                                        className={classes.linkItem}
                                                                        target={'_blank'}
                                                                        rel="noopener noreferrer"
                                                                        href={`${apps.adminUrl}/resources/location/${loc.clinicId}/courses`}
                                                                        // ---- Modal needs to open based on resource id on query as it is on services
                                                                        // href={`${apps.adminUrl}/resources/location/${loc.clinicId}/courses?courseId=${course.id}`}
                                                                    >
                                                                        {loc.clinicName}
                                                                    </a>
                                                                </Typography>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className={globalStyles.buttonsContainer}>
                        <Button className={globalStyles.confirmButton} onClick={() => setModalVisible(false)}>
                            Ok
                        </Button>
                    </div>
                </Paper>
            )}
        </Modal>
    );
}

TaxInUseModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    setModalVisible: PropTypes.func.isRequired,
    classes: PropTypes.object,
    selectedTag: PropTypes.object
};

export default withStyles(TaxInUseModalStyles)(TaxInUseModal);
