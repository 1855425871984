import React from 'react';
import { useForms } from '../../../../collums-components/hooks/forms';
import HtmlEditor from './components/HtmlEditor';
import PropTypes from 'prop-types';

const ConsentDesignTab = ({ editorRef, formRef }) => {
    const { setData } = useForms();

    const updateConsent = value => {
        setData({ ...formRef.current, consent: value });
    };

    return <HtmlEditor editorRef={editorRef} onChange={updateConsent} />;
};

ConsentDesignTab.propTypes = {
    formRef: PropTypes.object,
    editorRef: PropTypes.object
};

export default ConsentDesignTab;
