import React, { useCallback, useRef, useState } from 'react';
import { Button, makeStyles, withStyles } from '@material-ui/core';
import Modal from './Modal';
import formModalStyles from './styles';
import GeneralTab from './tabs/GeneralTab';
import FormDesignTab from './tabs/FormDesignTab';
import { FormProvider } from '../../../collums-components/hooks/forms';
import { ORGANISATION_FORMS_TARGET, ORGANISATION_FORM_TYPES } from '../../../collums-constants';
import ConsentDesignTab from './tabs/ConsentDesignTab';
import { modalsButtonStyles } from '../../../collums-constants/styles/stylesheets/buttonsStyles';
import { toastr } from 'react-redux-toastr';
import { createForm, updateForm } from '../../../collums-components/api/FormApi';
import { filterFieldsData, validateFormFields } from '../../../collums-constants/utils';
import LoadingScreen from '../../../collums-components/components/common/loadingScreen';
import TagsModal from '../../../collums-components/components/tags/TagsModal';
import FormPreviewModal from './FormPreviewModal';
import Accordion from './../../common/Accordion';
import CancelContinueModal from './../../../collums-components/components/common/CancelContinueModal';

const FormModal = ({ classes, closeModal, tags, selectedForm, refreshList, getTags }) => {
    const globalStyles = makeStyles(modalsButtonStyles)();

    const [isShowingPreviewModal, setIsShowingPreviewModal] = useState(false);
    const [formType, setFormType] = useState(selectedForm?.type);
    const [isShowingExitConfirmation, setIsShowingExitConfirmation] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowingTagModal, setIsShowingTagModal] = useState(false);
    const isFirstChange = useRef(true);

    const formRef = useRef();
    const consentFormEditorRef = useRef();
    const haveUnsavedChanges = useRef(false);

    const validateForm = () => {
        const errors = [];
        if (!formRef.current) {
            errors.push('invalid form');
            return errors;
        }
        if (!formRef.current.name) errors.push('Please complete name');
        if (!Object.values(ORGANISATION_FORM_TYPES).includes(formRef.current.type)) {
            errors.push('Please complete type');
        }
        if (!(formRef.current.isFor || []).length) {
            errors.push('Please select in which journey the form will appear');
        }
        if (errors.length) return errors;
        if (ORGANISATION_FORM_TYPES.CONSENT === formRef.current.type) {
            if (!formRef.current.consent) errors.push('Please complete consent');
        } else {
            if (!formRef.current.fields.length) errors.push('Please add fields to the form');
        }
        return errors;
    };

    const saveForm = async () => {
        setIsLoading(true);
        const errors = validateForm();
        if (errors.length) {
            toastr.error(errors[0]);
            setIsLoading(false);
            return;
        }
        const formData = {
            ...formRef.current,
            tag: formRef.current.tag?.id,
            fields: filterFieldsData(formRef.current.fields),
            isFor: [ORGANISATION_FORMS_TARGET.CLIENT, ORGANISATION_FORMS_TARGET.PRACTITIONER]
        };
        if (formData.isMasterForm) {
            formData.isFor = [ORGANISATION_FORMS_TARGET.CLIENT];
        }
        if (formRef.current.type !== ORGANISATION_FORM_TYPES.CONSENT) {
            formData.consent = undefined;
            const errorsInFields = validateFormFields(formData.fields);
            if (errorsInFields.length) {
                errorsInFields.forEach(err => toastr.error(err));
                setIsLoading(false);
                return;
            }
        } else {
            formData.fields = [];
            if (!formData.consent) {
                setIsLoading(false);
                toastr.error('Consent form should not be empty');
                return;
            }
        }
        if (formData.id) {
            const data = {
                ...formData,
                id: undefined,
                createdAt: undefined,
                archived: undefined,
                active: undefined,
                isMasterForm: undefined
            };
            try {
                await updateForm(formData.id, data);
                toastr.success('Form updated successfuly');
                closeModal();
                refreshList();
            } catch (err) {
                if (err?.data?.message) {
                    toastr.error(err.data.message);
                }
            } finally {
                setIsLoading(false);
            }
            return;
        }
        try {
            await createForm(formData);
            toastr.success('Form created successfuly');
            setIsLoading(false);
            closeModal();
            refreshList();
        } catch (err) {
            if (err?.data?.message) {
                toastr.error(err.data.message);
            }
            setIsLoading(false);
        }
    };

    const showConfirmationDialog = () => {
        if (haveUnsavedChanges.current) setIsShowingExitConfirmation(true);
        else closeModal();
    };

    const openPreviewModal = () => {
        setIsShowingPreviewModal(true);
    };

    const closePreviewModal = () => {
        setIsShowingPreviewModal(false);
    };

    const openTagModal = () => {
        setIsShowingTagModal(true);
    };

    const closeTagModal = () => {
        setIsShowingTagModal(false);
        getTags();
    };

    const designTab = useCallback(() => {
        if (!formType) return;
        if (
            [
                ORGANISATION_FORM_TYPES.GENERAL,
                ORGANISATION_FORM_TYPES.MEDICAL,
                ORGANISATION_FORM_TYPES.AFTERCARE
            ].includes(formType)
        )
            return 'FormBuilder';
        if (ORGANISATION_FORM_TYPES.CONSENT === formType) return 'ConsentForm';
    }, [formType])();

    return (
        <>
            {isLoading && <LoadingScreen />}
            <FormProvider
                value={{
                    isJourney: false,
                    defaultData: selectedForm,
                    onDataChange: data => {
                        formRef.current = data;
                        if (isFirstChange.current) {
                            isFirstChange.current = false;
                            return;
                        }
                        haveUnsavedChanges.current = true;
                    }
                }}
            >
                <Modal
                    isOpen={true}
                    title={`View/Edit${selectedForm?.name ? ` ${selectedForm.name}` : ''} Form`}
                    onClose={showConfirmationDialog}
                    onCancel={showConfirmationDialog}
                >
                    <form>
                        <div className={classes.accordions}>
                            <Accordion title={'General'}>
                                <GeneralTab setFormType={setFormType} tags={tags} openTagModal={openTagModal} />
                            </Accordion>

                            {designTab === 'FormBuilder' && (
                                <Accordion title={'Form Design'} disableFlex>
                                    <FormDesignTab openPreviewModal={openPreviewModal} />
                                </Accordion>
                            )}

                            {designTab === 'ConsentForm' && (
                                <Accordion title={'Consent Design'} disableFlex>
                                    <ConsentDesignTab editorRef={consentFormEditorRef} formRef={formRef} />
                                </Accordion>
                            )}
                        </div>
                    </form>
                    <div className={globalStyles.buttonsContainer}>
                        <Button className={globalStyles.cancelButton} onClick={showConfirmationDialog}>
                            Cancel
                        </Button>
                        <Button className={globalStyles.confirmButton} onClick={saveForm}>
                            Save
                        </Button>
                    </div>
                </Modal>
            </FormProvider>
            {isShowingPreviewModal && <FormPreviewModal formRef={formRef} closeModal={closePreviewModal} />}
            {isShowingExitConfirmation && (
                <CancelContinueModal
                    onCancel={() => setIsShowingExitConfirmation(false)}
                    onContinue={closeModal}
                    title={'Your changes will not be saved'}
                    contentText={'Are you sure you want to continue?'}
                />
            )}
            {isShowingTagModal && <TagsModal isVisible={isShowingTagModal} closeModal={closeTagModal} />}
        </>
    );
};

export default withStyles(formModalStyles)(FormModal);
