export const customSwitchStyles = {
    switchTrack: {
        backgroundColor: '#C62828'
    },
    switchBase: {
        color: '#C62828',
        '&.Mui-checked': {
            color: '#43A047'
        },
        '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#43A047'
        },
        '&.Mui-disabled': {
            color: '#C6282866'
        },
        '&.Mui-disabled.Mui-checked': {
            color: '#43A04766'
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            backgroundColor: '#555555'
        }
    }
};
