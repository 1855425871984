import React from 'react';
import { TextField, withStyles } from '@material-ui/core';
import { TabStyles } from './styles';

const General = ({ register, brand, classes }) => {
    return (
        <div className={classes.formContent}>
            <div className={classes.formContentRow}>
                <TextField
                    className={classes.formItem}
                    inputRef={register({ required: 'Name' })}
                    defaultValue={brand?.name || ''}
                    name="name"
                    label="Name*"
                    variant="outlined"
                />
                <TextField
                    className={classes.formItem}
                    inputRef={register}
                    defaultValue={brand?.description || ''}
                    name="description"
                    label="Description"
                    variant="outlined"
                />
            </div>
        </div>
    );
};

export default withStyles(TabStyles)(General);
