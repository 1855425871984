import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, DialogTitle, DialogContent, withStyles, Paper, Typography, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { customModal } from '../../styles';

function Modal({ classes, children, isOpen, title, onClose, size }) {
    return (
        <Dialog fullWidth maxWidth={size || 'xl'} PaperComponent={Paper} open={isOpen} onClose={onClose}>
            <DialogTitle className={classes.dialogTitle}>
                <Typography variant="h4">{title}</Typography>
                <IconButton className={classes.closeButton} onClick={onClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
        </Dialog>
    );
}

Modal.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.any.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    actions: PropTypes.any,
    onClose: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired
};

export default withStyles(customModal)(Modal);
