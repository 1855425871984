import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField, FormControlLabel, Checkbox, InputLabel, FormControl, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import TagsInput from '../../../collums-components/form/TagsInput';

const quantityOptions = new Array(20).fill(0).map((el, index) => index + 1);
const expiresAfterTypes = ['days', 'weeks', 'months'];
const minTimeBetweenServicesType = ['days', 'months', 'years'];

function CourseFormGeneral({
    classes,
    validationMessages,
    register,
    control,
    services,
    getServices,
    categories,
    getCoursesCategories,
    setFilterServices,
    isFromOrg,
    allClinics
}) {
    return (
        <div className={classes.formContent}>
            <div className={classes.formItem}>
                <InputLabel shrink>Name *</InputLabel>
                <TextField
                    style={{ width: '100%', marginTop: 10 }}
                    inputRef={register({ required: validationMessages.required })}
                    name="name"
                    variant="outlined"
                    inputProps={{ readOnly: !isFromOrg && allClinics.length > 1 }}
                />
            </div>
            <div className={classes.formItem}>
                <InputLabel shrink>Description</InputLabel>
                <TextField
                    style={{ width: '100%', marginTop: 10 }}
                    inputRef={register}
                    name="description"
                    variant="outlined"
                    multiline
                    rowsMax={3}
                    inputProps={{ readOnly: !isFromOrg && allClinics.length > 1 }}
                />
            </div>

            <Controller
                render={({ value, onChange }) => (
                    <Autocomplete
                        disabled={!isFromOrg && allClinics.length > 1}
                        className={`${classes.formItem} ${classes.autocomplete}`}
                        onClose={async () => {
                            setFilterServices({ value: '' });
                            await getServices();
                        }}
                        options={services}
                        getOptionLabel={option => option.name || ''}
                        getOptionSelected={(option, service) => option.id === service.id}
                        onChange={(e, service) => {
                            onChange(service);
                        }}
                        clearOnEscape
                        value={value}
                        onInputChange={async (e, value) => {
                            setFilterServices({ value });
                            // await getServices();
                        }}
                        renderInput={params => (
                            <FormControl style={{ width: '100%' }}>
                                <InputLabel shrink>Service *</InputLabel>
                                <TextField style={{ marginTop: 10 }} {...params} variant="outlined" />
                            </FormControl>
                        )}
                        renderOption={option => {
                            return <p className={classes.autocompleteOptions}>{`${option.name}`}</p>;
                        }}
                    />
                )}
                name={'services'}
                control={control}
                rules={{ required: validationMessages.required }}
            />
            <Controller
                render={({ value, onChange }) => (
                    <Autocomplete
                        // disabled={!isFromOrg}
                        className={`${classes.formItem} ${classes.autocomplete}`}
                        onClose={async () => {
                            await getCoursesCategories({ name: '' });
                        }}
                        options={categories}
                        getOptionLabel={option => option.name || ''}
                        getOptionSelected={(option, category) => option === category}
                        onChange={(e, category) => {
                            onChange(category);
                        }}
                        clearOnEscape
                        value={value}
                        onInputChange={async (e, value) => {
                            await getCoursesCategories({ name: value });
                        }}
                        renderInput={params => (
                            <FormControl style={{ width: '100%' }}>
                                <InputLabel shrink>Category *</InputLabel>
                                <TextField style={{ marginTop: 10 }} {...params} variant="outlined" />
                            </FormControl>
                        )}
                        renderOption={option => {
                            return <p className={classes.autocompleteOptions}>{`${option.name}`}</p>;
                        }}
                    />
                )}
                name={'category'}
                control={control}
                rules={{ required: validationMessages.required }}
            />

            {/* quantity */}
            <Controller
                render={({ value, onChange }) => (
                    <Autocomplete
                        disabled={!isFromOrg && allClinics.length > 1}
                        className={`${classes.formItem} ${classes.autocomplete}`}
                        options={quantityOptions}
                        getOptionLabel={option => option.toString()}
                        getOptionSelected={(option, value) => option === value}
                        onChange={(e, value) => {
                            onChange(value);
                        }}
                        value={value}
                        renderInput={params => (
                            <FormControl style={{ width: '100%' }}>
                                <InputLabel shrink>Quantity *</InputLabel>
                                <TextField style={{ marginTop: 10 }} {...params} variant="outlined" fullWidth />
                            </FormControl>
                        )}
                        renderOption={option => {
                            return <p className={classes.autocompleteOptions}>{`${option}`}</p>;
                        }}
                    />
                )}
                rules={{ min: 1, message: validationMessages.minValueOne, required: validationMessages.required }}
                name="quantity"
                control={control}
            />
            <div className={classes.halfRow}>
                <Controller
                    render={({ value, onChange }) => (
                        <Autocomplete
                            disabled={!isFromOrg && allClinics.length > 1}
                            className={`${classes.halfItem} ${classes.autocomplete}`}
                            options={quantityOptions}
                            getOptionLabel={option => option.toString()}
                            getOptionSelected={(option, value) => option === value}
                            onChange={(e, value) => {
                                onChange(value);
                            }}
                            value={value}
                            renderInput={params => (
                                <FormControl style={{ width: '100%' }}>
                                    <InputLabel shrink>Course expires after *</InputLabel>
                                    <TextField style={{ marginTop: 10 }} {...params} variant="outlined" fullWidth />
                                </FormControl>
                            )}
                            renderOption={option => {
                                return <p className={classes.autocompleteOptions}>{`${option}`}</p>;
                            }}
                        />
                    )}
                    rules={{ min: 1, message: validationMessages.minValueOne }}
                    name="expiresAfterQuantity"
                    control={control}
                />
                <Controller
                    render={({ value, onChange }) => (
                        <Autocomplete
                            disabled={!isFromOrg && allClinics.length > 1}
                            className={`${classes.halfItem}  ${classes.autocomplete}`}
                            options={expiresAfterTypes}
                            getOptionLabel={option => option}
                            getOptionSelected={(option, value) => option === value}
                            onChange={(e, value) => {
                                onChange(value);
                            }}
                            value={value}
                            renderInput={params => (
                                <TextField
                                    style={{ marginTop: 10 }}
                                    {...params}
                                    label={''}
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                            renderOption={option => {
                                return <p className={classes.autocompleteOptions}>{`${option}`}</p>;
                            }}
                        />
                    )}
                    name="expiresAfterType"
                    control={control}
                />
            </div>

            <FormControlLabel
                className={classes.formItem}
                label="Expiry countdown starts on date of 1st redemption rather than date of purchase"
                control={
                    <Controller
                        disabled={!isFromOrg && allClinics.length > 1}
                        control={control}
                        name="startValidityOnRedemption"
                        render={({ onChange, onBlur, value, ref }) => (
                            <Checkbox
                                disabled={!isFromOrg && allClinics.length > 1}
                                onBlur={onBlur}
                                onChange={e => onChange(e.target.checked)}
                                checked={value}
                                inputRef={ref}
                                defaultValue={value || false}
                            />
                        )}
                    />
                }
            />
            <div className={classes.halfRow} style={{ marginLeft: 10 }}>
                <Controller
                    render={({ value, onChange }) => (
                        <Autocomplete
                            className={`${classes.halfItem} ${classes.autocomplete}`}
                            style={{ marginTop: 20 }}
                            options={quantityOptions}
                            getOptionLabel={option => option.toString()}
                            getOptionSelected={(option, value) => option === value}
                            onChange={(e, value) => {
                                onChange(value);
                            }}
                            disabled={!isFromOrg || true}
                            value={value}
                            renderInput={params => (
                                <FormControl style={{ width: '100%' }}>
                                    <InputLabel shrink>Min time between services</InputLabel>
                                    <TextField
                                        style={{ marginTop: 10 }}
                                        {...params}
                                        label={''}
                                        variant="outlined"
                                        fullWidth
                                    />
                                    <Typography>Coming soon</Typography>
                                </FormControl>
                            )}
                            renderOption={option => {
                                return <p className={classes.autocompleteOptions}>{`${option}`}</p>;
                            }}
                        />
                    )}
                    rules={{ min: 1, message: validationMessages.minValueOne }}
                    name="minTimeBetweenServices"
                    control={control}
                />
                <Controller
                    render={({ value, onChange }) => (
                        <Autocomplete
                            disabled={!isFromOrg || true}
                            className={`${classes.halfItem}  ${classes.autocomplete}`}
                            options={minTimeBetweenServicesType}
                            getOptionLabel={option => option}
                            getOptionSelected={(option, value) => option === value}
                            onChange={(e, value) => {
                                onChange(value);
                            }}
                            value={value}
                            renderInput={params => <TextField {...params} label={''} variant="outlined" fullWidth />}
                            renderOption={option => {
                                return <p className={classes.autocompleteOptions}>{`${option}`}</p>;
                            }}
                        />
                    )}
                    name="minTimeBetweenServicesType"
                    control={control}
                />
            </div>
            <div className={classes.formContentRow}>
                <TagsInput disabled={!isFromOrg && allClinics.length > 1} name="tags" control={control} />
            </div>
        </div>
    );
}

export default CourseFormGeneral;
