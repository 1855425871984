import { format, differenceInYears, isSameDay } from 'date-fns';

export const dateFormatter = (date, message) => {
    if (!date) return message || '-';
    return format(new Date(date), 'dd/MM/yyyy - HH:mm');
};

export const dobFormatter = (date, message = '') => {
    if (!date) return message;
    return format(new Date(date), 'dd/MM/yyyy');
};

export const getCustomerInfo = (customer, field) => {
    if (!customer.id || !customer[field.id]) return;
    if (field.type === 'date') {
        if (field.id === 'dateOfBirth') return dobFormatter(customer[field.id]);
        return dateFormatter(customer[field.id]);
    }

    if (field.id === 'referredBy') {
        return customer[field.id]?.firstName + ' ' + customer[field.id]?.surname;
    }

    if (field.type === 'object') return customer[field.id][field.objectLabel];
    if (field.type === 'array') {
        if (field.shouldCount) return formatArrayCount(customer, field);
        return customer[field.id].join();
    }
    return customer[field.id];
};

export const isCustomerSelected = (preferentialCustomerTemplates, customer, field) => {
    if (
        !preferentialCustomerTemplates[field.id] ||
        (field.type === 'object' && !customer[field.id]) ||
        (field.type === 'array' && !customer[field.id]?.length) ||
        !customer[field.id]
    )
        return;
    if (preferentialCustomerTemplates[field.id].toString() === customer.id.toString()) return true;
};

export const formatArrayCount = (customer, field) => {
    return customer[field.id].length;
};

export const calculateAge = customer => {
    const age = differenceInYears(new Date(), new Date(customer.dateOfBirth));
    if (isNaN(age)) return '';
    return age;
};

export const getId = customer => {
    const displayId = customer.id.substr(14);
    return displayId;
};

export const isSameDOB = (customer1, customer2) => {
    return isSameDay(new Date(customer1), new Date(customer2));
};
