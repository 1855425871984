import React from 'react';

import './styles.css';

function Marker({ isOld, onClick }) {
    return (
        <div onClick={onClick} className={`${onClick ? 'pin-clickable' : ''}`}>
            <div className={`pin ${isOld ? 'old-pin' : ''}`} />
            {!isOld && <div className="pulse" />}
        </div>
    );
}

export default Marker;
