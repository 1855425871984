import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles, Tab, Tabs, Paper } from '@material-ui/core';

import { subHeaderStyles } from './styles';

const subHeader = ({ classes, history, tabs, path, currentTab, currentSubTab }) => {
    function handleSubTabChange(event, value) {
        if (value === 'services' || value === 'staff' || value === 'temporary-discounts' || value === 'notifications') {
            history.push(`/${path}/${currentTab}`);
        } else {
            history.push(`/${path}/${currentTab}/${value}`);
        }
    }

    /* used to disabled tabs that are not implemented */
    function isDisabled(tabValue) {
        return (
            (currentTab === 'temporary-discounts' && tabValue === 'bundles') ||
            (currentTab === 'notifications' && tabValue === 'alerts')
        );
    }

    return (
        <Paper>
            <Tabs
                initialSelectedIndex={tabs[0]?.value}
                value={currentSubTab}
                onChange={handleSubTabChange}
                className={classes.subTabsContainer}
                TabIndicatorProps={{ className: classes.subTabIndicator }}
            >
                {tabs.map(tab => (
                    <Tab
                        key={tab.label}
                        disabled={isDisabled(tab.value)}
                        className={classes.subTab}
                        label={tab.label}
                        value={tab.value}
                    />
                ))}
            </Tabs>
        </Paper>
    );
};

export default withRouter(withStyles(subHeaderStyles)(subHeader));
