export default [
    { field: 'firstName', title: 'First Name', reordable: true, style: {} },
    { field: 'surname', title: 'Surname', reordable: true, style: {} },
    { field: 'dateOfBirth', title: 'DOB', reordable: true, type: 'date', style: {} },
    { field: 'age', title: 'Age', reordable: true, type: 'age', style: {} },
    { field: 'gender', title: 'Gender', reordable: true, style: {} },
    { field: 'mobilePhone', title: 'Mobile', reordable: true, style: {} },
    { field: 'email', title: 'Email', reordable: true, style: {} },
    { field: 'address1', title: 'Address 1', reordable: true, style: {} },
    { field: 'createdAt', title: 'Created', reordable: true, type: 'visit', style: {} },
    { field: 'firstVisit', title: 'First Visit', reordable: true, type: 'visit', style: {} },
    { field: 'lastVisit', title: 'Last Visit', reordable: true, type: 'visit', style: {} },
    { field: '_id', title: 'ID', reordable: true, style: {} },
    { field: 'tagsString', title: 'Tags', reordable: true, style: {} },
    { field: '', title: '', reordable: false, style: {} }
];
