import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles, TextField } from '@material-ui/core';
import AutoCompleteStyles from './styles';
import { PropTypes } from 'prop-types';
import customDropdownSearch from './../../../../../collums-components/helpers/customDropdownSearch';

const CustomAutoComplete = ({ id, disabled, options, value, onChange, classes }) => {
    return (
        <Autocomplete
            id={id}
            disabled={disabled}
            className={classes.autocomplete}
            options={options || []}
            getOptionLabel={option => {
                if (typeof option === 'string') {
                    return option || '';
                }
                return option?.name || '';
            }}
            filterOptions={customDropdownSearch}
            value={value}
            onChange={onChange}
            renderInput={params => <TextField {...params} label="" variant="outlined" fullWidth />}
        />
    );
};

CustomAutoComplete.propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    classes: PropTypes.object.isRequired,
    getOptionLabel: PropTypes.func.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool
};

export default withStyles(AutoCompleteStyles)(CustomAutoComplete);
