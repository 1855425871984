import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Typography, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CategoryFormModal from './CourseCategoriesFormModal';
import ConfirmModal from '../common/ConfirmModal';
import PropTypes from 'prop-types';
import Modal from '../common/Modal';
import CoursesCategoryApi from '../../api/CourseCategoriesApi';
import { courseCategoryModalStyles } from './styles';
import { toastr } from 'react-redux-toastr';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import { getLocationItem } from '../../collums-constants/utils';

const CourseCategoryModal = props => {
    const [isLoading, setIsLoading] = useState(false);

    const category = { ...props.category, prevName: props.category.name } || {};
    const methodType = props.methodType;

    const loadItems = async () => {
        if (methodType === 'Update' || methodType === 'Create') {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadItems();
        /*eslint-disable-next-line */
    }, []);

    const isLocationSelected = locations => locations.some(location => location.isSelected);

    const confirm = async (method, data = {}) => {
        let response, errData;
        let action = method;

        if (category.id === undefined) action = 'create';

        if (action !== 'remove' && action !== 'archive') {
            if (!isLocationSelected(data.locations || [])) {
                return toastr.error('Missing field location');
            }
        }

        switch (action) {
            case 'create':
                try {
                    data = {
                        ...data,
                        locations: data.locations.map(location => ({
                            clinic: location.value,
                            allowCommission: data.allowCommission
                        }))
                    };

                    response = await CoursesCategoryApi.createCategory(data);
                    toastr.success(`Category ${response.name} successfully created!`);
                } catch (err) {
                    err.data !== undefined ? (errData = err.data.message) : (errData = 'Something went wrong');
                    toastr.error(errData);
                    return;
                }
                break;
            case 'update':
                try {
                    if (props.isFromOrg) {
                        data.locations = data.locations
                            .map(
                                location =>
                                    location.isSelected && {
                                        clinic: location.value,
                                        allowCommission: data.allowCommission
                                    }
                            )
                            .filter(el => el);
                    } else if (!props.isFromOrg && props.clinic) {
                        data.locations = category.locations.map(loc => {
                            if (loc.id) {
                                delete loc.id;
                            }
                            if (loc.clinic === props.clinic) {
                                loc.allowCommission = data.allowCommission;
                                return loc;
                            }
                            return loc;
                        });
                        data.allowCommission = category.allowCommission || false;
                    }

                    if (!data.description) {
                        data.description = '';
                    }
                    response = await CoursesCategoryApi.updateCategory(
                        category.id,
                        data,
                        props.clinic,
                        props.isFromOrg
                    );
                    props.onCancel();
                    toastr.success('Category successfully updated!');
                } catch (err) {
                    err.data !== undefined ? (errData = err.data.message) : (errData = 'Something went wrong');
                    toastr.error(errData);
                    return;
                }
                break;
            case 'remove':
                try {
                    response = await CoursesCategoryApi.deleteCategory(category.id);
                    toastr.success('Category successfully removed!');
                } catch (err) {
                    err.data !== undefined ? (errData = err.data.message) : (errData = 'Something went wrong');
                    toastr.error(errData);
                    return;
                }
                break;
            default:
                try {
                    const value = props.isFromOrg
                        ? !category.archived
                        : !getLocationItem(category, props.clinic).archived;
                    response = await CoursesCategoryApi.archiveCategory(
                        category.id,
                        value,
                        props.clinic,
                        props.isFromOrg
                    );
                    toastr.success(`Category ${response.name} successfully archived!`);
                } catch (err) {
                    err.data !== undefined ? (errData = err.data.message) : (errData = 'Something went wrong');
                    toastr.error(errData);
                    return;
                }
                break;
        }
        props.onConfirm();
    };

    if (!props.categoryId) {
        return null;
    }

    const Update = () => {
        if (isLoading) return <LoadingScreen />;
        return (
            <CategoryFormModal
                clinic={props.clinic}
                Category={category}
                visible={true}
                onClose={props.onCancel}
                onConfirm={data => confirm('update', data)}
                clinics={props.clinics}
                isFromOrg={props.isFromOrg}
                allClinics={props.allClinics}
            />
        );
    };

    const Create = () => {
        if (isLoading) return <LoadingScreen />;
        return (
            <CategoryFormModal
                clinic={props.clinic}
                visible={true}
                onClose={props.onCancel}
                onConfirm={data => confirm('create', data)}
                clinics={props.clinics}
                isFromOrg={props.isFromOrg}
                allClinics={props.allClinics}
            />
        );
    };
    const Archive = () => {
        const modalTitle = `${category.archived ? 'Unarchive' : 'Archive'} category`;
        const modalContent = `Are you sure you want to ${category.archived ? 'unarchive' : 'archive'} this category?`;
        return (
            <ConfirmModal
                isOpen
                setIsOpen={props.onCancel}
                onConfirm={() => confirm('archive')}
                title={modalTitle}
                content={modalContent}
            />
        );
    };

    const Remove = () => {
        return (
            <Modal
                id={`${methodType}-category`}
                isOpen={true}
                title={methodType}
                size="xs"
                draggable
                onConfirm={() => confirm('remove')}
                onClose={props.onCancel}
            >
                <Box container spacing={4}>
                    <Typography item>Are you sure you want to delete this category?</Typography>
                </Box>
            </Modal>
        );
    };

    const MethodModal = () => {
        switch (methodType) {
            case 'Archive':
                return <Archive />;
            case 'Remove':
                return <Remove />;
            case 'Create':
                return <Create />;
            default:
                return <Update />;
        }
    };

    return <MethodModal />;
};

CourseCategoryModal.propTypes = {
    categoryId: PropTypes.string,
    category: PropTypes.object,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(courseCategoryModalStyles)(CourseCategoryModal));
