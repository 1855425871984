import React from 'react';
import { FormControlLabel, Checkbox, withStyles } from '@material-ui/core';
import { settingsStyles } from '../styles';

function ProductStockTab({ classes, register, organisation, setOrganisation }) {
    const changeProductStockCheckbox = async ({ target }) => {
        if (target.id === 'product-stock-control') {
            setOrganisation({
                ...organisation,
                product: {
                    autoStockControl: target.checked,
                    allowNegative:
                        organisation.product && organisation.product.allowNegative
                            ? organisation.product.allowNegative
                            : false
                }
            });
        } else {
            setOrganisation({
                ...organisation,
                product: {
                    autoStockControl:
                        organisation.product && organisation.product.autoStockControl
                            ? organisation.product.autoStockControl
                            : false,
                    allowNegative: target.checked
                }
            });
        }
    };

    return (
        <div className={classes.ProductStockContainer}>
            <div className={classes.checkboxesContainer}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={
                                organisation && organisation.product ? organisation.product.autoStockControl : false
                            }
                            onChange={changeProductStockCheckbox}
                            id="product-stock-control"
                            inputRef={register}
                            name="product.autoStockControl"
                            color="primary"
                        />
                    }
                    className={`${classes.label}`}
                    label="Allow stock control"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={organisation && organisation.product ? organisation.product.allowNegative : false}
                            onChange={changeProductStockCheckbox}
                            id="product-negative-stock"
                            inputRef={register}
                            name="product.allowNegative"
                            color="primary"
                        />
                    }
                    className={`${classes.label}`}
                    label="Allow negative stock"
                />
            </div>
        </div>
    );
}

export default withStyles(settingsStyles)(ProductStockTab);
