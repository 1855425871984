import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { toastr } from 'react-redux-toastr';

import View from '../common/View';
import TagsModal from '../../collums-components/components/tags/TagsModal';

import {
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
    withStyles,
    makeStyles
} from '@material-ui/core';
import { Search, Clear } from '@material-ui/icons';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import TagsApi from '../../collums-components/api/TagsApi';

import { tagsStyles } from './styles';
import { inputSearchStyles } from '../../collums-constants/styles/stylesheets/inputSearchStyles';
import { buttonsStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import Actions from '../common/TabContent/actions';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import Switch from '../../collums-components/components/common/customSwitch';

import ConfirmModal from '../common/ConfirmModal';
import { useDebounce } from '../../collums-components/helpers';

function TagsView({ classes, isFromOrg }) {
    const [type, setType] = useState('');
    const [name, setName] = useState('');
    const [tagsList, setTagsList] = useState([]);
    const [typeOpts, setTypeOpts] = useState([]);
    const [selectedTag, setSelectedTag] = useState(null);
    const [showArchived, setShowArchived] = useState(false);
    const [showInactive, setShowInactive] = useState(false);
    const [isModalVisible, setisModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState('');
    const [tagId, setTagId] = useState('');
    const filterDebounce = useDebounce(name, 600);

    const globalSearchStyles = makeStyles(inputSearchStyles)();
    const globalButtonsStyles = makeStyles(buttonsStyles)();

    const toDo = () => {
        if (modalTitle === 'Archive tag') handleArchive();
    };

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setModalTitle('');
        setModalContent();
    };

    const openConfirmModal = (title, component) => {
        setConfirmModal(true);
        setModalTitle(title);
        setModalContent(component);
    };

    const getTags = useCallback(async () => {
        const query = {
            archived: showArchived,
            inactive: showInactive,
            name: name.trim(),
            type: type.trim()
        };
        setIsLoading(true);
        const res = await TagsApi.listTags(query);
        setTagsList(res);
        setIsLoading(false);
        /*eslint-disable-next-line */
    }, [filterDebounce, type, showArchived, showInactive]);

    const getTypes = async () => {
        const res = await TagsApi.listTypes();
        setTypeOpts(res);
    };
    /* const handleDelete = async id => {
        TagsApi.deleteTag(id).then(() => {
            toastr.success('Tag deleted!');
            refreshList();
        });
    }; */

    const handleArchive = async () => {
        if (!tagId) return;
        TagsApi.archiveTag(tagId).then(() => {
            toastr.success('Tag archived!');
            refreshList();
        });
    };

    const openArchive = async id => {
        setTagId(id);
        const tag = tagsList.find(element => element.id.toString() === id.toString()) || {};
        openConfirmModal(
            'Archive tag',
            `Are you sure you want to ${(tag || {}).archived ? 'unarchive' : 'archive'} this tag?`
        );
    };

    const handleDuplicate = async id => {
        const tag = tagsList.find(current => current.id === id);
        const amount = tagsList.filter(t => t.name === tag.name || t.name.startsWith(tag.name + ' - Copy')).length;
        tag.name += ' - Copy'.repeat(amount);
        TagsApi.createTag({
            name: tag.name,
            type: tag.type,
            description: tag.description
        }).then(() => {
            toastr.success('Tag duplicated!');
            refreshList();
        });
    };

    const actions = {
        /* handleDelete: id => handleDelete(id), */
        handleArchive: id => openArchive(id),
        handleDuplicate: id => handleDuplicate(id)
    };

    const refreshList = () => {
        getTags();
        getTypes();
    };

    useEffect(() => {
        getTags();
    }, [getTags]);

    useEffect(() => {
        getTypes();
    }, []);

    const changeTagActive = async tag => {
        if (!(tag || {}).id) return;
        if (tag.archived) {
            toastr.error(`Cannot change tag ${tag.name} active, because this tag is archived`);
            return;
        }
        try {
            await TagsApi.changeTagActive(tag.id);
            getTags();
            toastr.success(`Tag ${tag.name} successfully updated`);
        } catch (err) {
            if (typeof err === 'object') {
                if (err.data && err.data.message) {
                    toastr.error(err.data.message);
                    return;
                }
            }
            toastr.error('Something went wrong');
        }
    };

    return (
        <>
            <View>
                <div className={classes.header}>
                    <TextField
                        className={globalSearchStyles.inputSearch}
                        id="seachSupplier"
                        variant="outlined"
                        value={name}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                getTags();
                            }
                        }}
                        onChange={e => setName(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <>
                                    <IconButton
                                        className={globalSearchStyles.icons}
                                        onClick={() => {
                                            setName('');
                                        }}
                                        disabled={!name}
                                    >
                                        <Clear style={!name ? { color: 'transparent' } : {}} />
                                    </IconButton>
                                    <IconButton position="end" onClick={getTags} className={globalSearchStyles.icons}>
                                        <Search />
                                    </IconButton>
                                </>
                            )
                        }}
                    />
                    {isFromOrg && (
                        <IconButton onClick={() => setisModalVisible(true)}>
                            <AddCircleIcon className={globalButtonsStyles.addCircleStyle} />
                        </IconButton>
                    )}
                </div>
                <div className={classes.actionsHeader}>
                    <FormControl variant={'outlined'} className={classes.typeSelect}>
                        <InputLabel>Type</InputLabel>
                        <Select className={classes.select} value={type} onChange={e => setType(e.target.value)}>
                            <MenuItem value={''}>
                                <i>None</i>
                            </MenuItem>
                            {typeOpts.map(tag => (
                                <MenuItem value={tag} key={tag?.id || tag}>
                                    {tag}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <div className={classes.checkboxContainer}>
                        <FormControlLabel
                            label="Show Archived"
                            control={
                                <Checkbox checked={showArchived} onChange={() => setShowArchived(!showArchived)} />
                            }
                        />
                        <FormControlLabel
                            label="Show Inactive"
                            control={
                                <Checkbox checked={showInactive} onChange={() => setShowInactive(!showInactive)} />
                            }
                        />
                    </div>
                </div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography>Active</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>Name</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>Type</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>Description</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography></Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tagsList.length > 0 &&
                            tagsList
                                .filter(current => {
                                    if (type === '' || current.type === type) {
                                        return true;
                                    }
                                    return false;
                                })
                                .map(current => (
                                    <TableRow
                                        key={current.id}
                                        style={{ backgroundColor: !current.archived ? 'transparent' : '#ff9e9e' }}
                                    >
                                        <TableCell>
                                            <Switch
                                                checked={current.active}
                                                onChange={() => {
                                                    changeTagActive(current);
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                <Link
                                                    onClick={() => {
                                                        setSelectedTag(current);
                                                        setisModalVisible(true);
                                                    }}
                                                >
                                                    {current.name}
                                                </Link>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{current.type}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{current.description}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Actions
                                                    actions={actions}
                                                    id={current.id}
                                                    isArchived={current.archived}
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                    </TableBody>
                </Table>
                {isModalVisible && (
                    <TagsModal
                        isFromOrg={isFromOrg}
                        selectedTag={selectedTag}
                        isVisible={isModalVisible}
                        closeModal={() => {
                            refreshList();
                            setSelectedTag(null);
                            setisModalVisible(false);
                        }}
                        fromAdmin={true}
                    />
                )}
                {confirmModal && (
                    <ConfirmModal
                        isOpen
                        setIsOpen={closeConfirmModal}
                        onConfirm={toDo}
                        title={modalTitle}
                        content={modalContent}
                    />
                )}
                {isLoading && <LoadingScreen />}
            </View>
        </>
    );
}

TagsView.propTypes = {
    classes: PropTypes.object
};

export default withStyles(tagsStyles)(TagsView);
