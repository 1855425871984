import Axios from 'axios';
import api from '../collums-components/api/config';

class NotificationApi {
    static update(data, id) {
        return api.request({
            method: 'PUT',
            url: `/notifications/${id}`,
            data
        });
    }
    static query(isPreAfterCare = false, archived = false) {
        return api.request({
            method: 'GET',
            url: `/notifications/table?isPreAfterCare=${isPreAfterCare}&archived=${archived}`
        });
    }

    static changeArchived(isPreAfterCare, id) {
        return api.request({
            method: 'GET',
            url: `/notifications/${id}/change-archived?isPreAfterCare=${isPreAfterCare}`
        });
    }

    static get(id) {
        return api.request({
            method: 'GET',
            url: `/notifications/${id}`
        });
    }

    static getTemplates(templateType) {
        return api.request({
            method: 'GET',
            url: `/notifications/templates?type=${templateType}`
        });
    }

    static createNotification(data) {
        return api.request({
            method: 'POST',
            url: '/notifications',
            data
        });
    }

    static createTemplate(data) {
        return api.request({
            method: 'POST',
            url: '/notifications/templates',
            data
        });
    }

    static updateEmail(id, data) {
        return api.request({
            method: 'PUT',
            url: `/notifications/${id}/email`,
            data
        });
    }

    static updateSMS(id, data) {
        return api.request({
            method: 'PUT',
            url: `/notifications/${id}/sms`,
            data
        });
    }

    static changeActive(id, data) {
        return api.request({
            method: 'PUT',
            url: `/notifications/${id}/change-active`,
            data
        });
    }

    static sendTests(isEmail, data) {
        return api.request({
            method: 'POST',
            url: `/notifications/test?email=${isEmail}`,
            data
        });
    }

    static async uploadFile(file) {
        const uploadUrl = await api.request({
            method: 'GET',
            url: `/notifications/upload-url?filename=${file.name}`
        });

        const signedRequest = uploadUrl.signedRequest;
        const url = uploadUrl.url;

        const options = {
            headers: {
                'Content-Type': file.name.split('.')[1],
                'x-amz-acl': 'public-read'
            }
        };

        await Axios.put(signedRequest, file, options);
        return url;
    }

    static getPreview(data) {
        return api.request({
            method: 'POST',
            url: '/notifications/preview',
            data
        });
    }

    static updateTemplate(id, data) {
        return api.request({
            method: 'PUT',
            url: `/notifications/templates/${id}`,
            data
        });
    }
}

export default NotificationApi;
