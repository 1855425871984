const organisationTabs = [
    // { name: 'Account', ref: '/organisation/account' },
    { name: 'Alerts', ref: '/organisation/alerts' },
    { name: 'Discounts', ref: '/organisation/temporary-discounts' },
    { name: 'Forms', ref: '/organisation/forms' },
    // { name: 'General Info', ref: '/organisation/general-info' },
    // { name: 'Loyalty', ref: '/organisation/loyalty' },
    { name: 'Jobs', ref: '/organisation/job' },
    { name: 'Lists', ref: '/organisation/lists' },
    { name: 'Locations', ref: '/organisation/clinics' },
    { name: 'Notifications', ref: '/organisation/notifications' },
    { name: 'Pre/After Care', ref: '/organisation/preaftercare' },
    // { name: 'Online Booking', ref: '/organisation/online-booking' },
    { name: 'Settings', ref: '/organisation/settings' },
    // { name: 'Square', ref: '/organisation/square' },
    { name: 'Staff', ref: '/organisation/staffs' },
    // { name: 'Stripe', ref: '/organisation/stripe' },
    // { name: 'Xero', ref: '/organisation/xero'},
    { name: 'User Permissions', ref: '/organisation/user-permissions' },
    { name: 'Xero', ref: '/organisation/xero' }
];

export default organisationTabs;
