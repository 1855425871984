import React, { useState, useEffect, useRef } from 'react';
import { toastr } from 'react-redux-toastr';

import { useDebounce } from '../../collums-components/helpers/';
import customerModelMap from './services/customerModelMap';
import { mapper } from './services/customerEditHelper';

import View from '../common/View';
import MergeClientsTable from './views/MergeClientsTable';
import MergeClientsHeader from './views/MergeClientsHeader';
import MergeClientsModal from './views/MergeClientsModal';
import MergeClientsEditCustomer from './views/MergeClientsEditCustomer';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';

import CustomerAPI from '../../api/CustomerApi';
import CreateCustomerModal from '../../collums-components/components/common/CreateCustomerModal/CreateCustomerModal';
import { uploadCustomerAvatar } from '../../collums-components/api/CustomerApi';
import scrollTop from '../../services/scrollTop';

import { dobFormatter, calculateAge, dateFormatter } from './services/helpers';

import exportCsv from '../../collums-components/helpers/exportCSV';
import customersTableColumns from './views/customersTableColumns';
import { listClinics } from '../../collums-components/api/ClinicApi';

function MergeClientsView({ clinic, isFromOrg }) {
    const [isLoading, setIsLoading] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [numOfItems, setNumOfItems] = useState(0);
    const [sortState, setSortState] = useState({ columnId: 'firstName', sortDirection: 'asc' });
    const [filter, setFilter] = useState('');
    const debounceFilter = useDebounce(filter, 1000);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditCustomerModalOpen, setIsEditCustomerModalOpen] = useState(false);
    const [editCustomer, setEditCustomer] = useState({});
    const [customer1, setCustomer1] = useState({});
    const [customer2, setCustomer2] = useState({});
    const [mergedCustomer, setMergedCustomer] = useState({});
    const [preferentialCustomersTemplates, setPreferentialCustomersTemplates] = useState({});
    const [showDuplicates, setShowDuplicates] = useState(false);
    const [showArchived, setShowArchived] = useState(false);
    const [deletedPhoto, setDeletedPhoto] = useState(false);
    const mergeInProgressRef = useRef(false);
    const [allClinics, setAllClinics] = useState([]);

    useEffect(() => {
        scrollTop();
    }, [customers]);

    useEffect(() => {
        const loadItems = async () => {
            const fetchedClinics = await listClinics();
            setAllClinics(fetchedClinics);
        };
        loadItems();
        // eslint-disable-next-line
    }, []);

    const getCustomers = async ({
        page,
        rowsPerPage,
        sort,
        sortDirection,
        value,
        onlyDuplicates,
        showArchived,
        clinic
    }) => {
        setIsLoading(true);
        try {
            const queryObject = {
                ...(page >= 0 && { page }),
                ...(rowsPerPage && { rowsPerPage }),
                ...(sort && { sort }),
                ...(sort && { sortDirection: sortDirection === 'asc' ? 1 : -1 }),
                ...(value && { value }),
                ...(onlyDuplicates && { onlyDuplicates }),
                ...(showArchived && { showArchived }),
                ...(clinic && clinic !== 'organisation' && { clinic }),
                tableData: true
            };
            const { customers, numOfItems } = await CustomerAPI.getMergeClientCustomers(queryObject);
            setCustomers(customers);
            setNumOfItems(numOfItems);
        } catch (e) {
            console.error(e);
            toastr.error('Something went wrong', 'Could not get customers');
        }
        scrollTop();
        setIsLoading(false);
    };

    const refreshList = async () => {
        await getCustomers({
            page,
            rowsPerPage,
            sort: sortState.columnId,
            sortDirection: sortState.sortDirection,
            value: filter,
            onlyDuplicates: showDuplicates,
            showArchived: showArchived,
            clinic: clinic
        });
    };

    const archiveCustomer = async id => {
        try {
            await CustomerAPI.remove(id);
            await refreshList();
            toastr.success('Customer successfully archived');
        } catch (e) {
            console.error(e);
            toastr.error('Something went wrong', 'Could not archive customer');
        }
    };

    const unArchiveCustomer = async id => {
        try {
            await CustomerAPI.unremove(id);
            await refreshList();
            toastr.success('Customer successfully unarchived');
        } catch (e) {
            console.error(e);
            toastr.error('Something went wrong', 'Could not unarchive customer');
        }
    };

    const confirmCustomerMerge = async () => {
        try {
            setIsLoading(true);
            const oldCustomer = [customer1, customer2].find(customer => customer.id !== mergedCustomer.id);

            // merge hidden fields
            const recentestUser = customer1.createdAt > customer2.createdAt ? customer1 : customer2;

            mergedCustomer.createdAt = recentestUser.createdAt;
            mergedCustomer.customTitle = recentestUser.customTitle;
            mergedCustomer.customGender = recentestUser.customGender;
            mergedCustomer.accountBalance = customer1.accountBalance + customer2.accountBalance;

            if (customer1.notes) {
                mergedCustomer.notes = customer1.notes;
                if (customer2.notes) {
                    mergedCustomer.notes = mergedCustomer.notes + ' ' + customer2.notes;
                }
            } else if (customer2.notes) {
                mergedCustomer.notes = mergedCustomer.notes + ' ' + customer2.notes;
            }

            mergedCustomer.notes =
                mergedCustomer.notes === 'undefined undefined undefined' ? undefined : mergedCustomer.notes;

            mergedCustomer.tags = [...(customer1.tags || []), ...(customer2.tags || [])];

            if (recentestUser.squareCustomerId) {
                mergedCustomer.squareCustomerId = recentestUser.squareCustomerId;
            } else if (customer1.squareCustomerId) {
                mergedCustomer.squareCustomerId = customer1.squareCustomerId;
            } else {
                mergedCustomer.squareCustomerId = customer2.squareCustomerId;
            }

            if (recentestUser.defaultCard) {
                mergedCustomer.defaultCard = recentestUser.defaultCard;
            } else if (customer1.defaultCard) {
                mergedCustomer.defaultCard = customer1.defaultCard;
            } else {
                mergedCustomer.defaultCard = customer2.defaultCard;
            }

            mergedCustomer.hasReferredBy = [...(customer1.hasReferredBy || []), ...(customer2.hasReferredBy || [])];

            mergedCustomer.referralSourceCustom = recentestUser.referralSourceCustom;
            mergedCustomer.clinics = recentestUser.clinics;

            mergedCustomer.preferredClinic = [
                ...(customer1.preferredClinic || []),
                ...(customer2.preferredClinic || [])
            ];
            mergedCustomer.medicalHistory = [...(customer1.medicalHistory || []), ...(customer2.medicalHistory || [])];
            mergedCustomer.drugHistory = [...(customer1.drugHistory || []), ...(customer2.drugHistory || [])];
            mergedCustomer.allergies = [...(customer1.allergies || []), ...(customer2.allergies || [])];
            mergedCustomer.treatmentNotes = [...(customer1.treatmentNotes || []), ...(customer2.treatmentNotes || [])];

            const data = {
                customerA: customer1.id,
                customerB: customer2.id,
                oldCustomer: oldCustomer.id,
                newCustomer: mergedCustomer
            };
            await CustomerAPI.mergeCustomers(data);
            await refreshList();
            toastr.success('Customers successfully merged');
            closeModal();
            setIsLoading(false);
        } catch (e) {
            console.error(e);
            setIsLoading(false);
            toastr.error('Something went wrong', 'Could not merge clients');
        }
    };

    const updateMergedCustomer = (customer, field) => {
        if (preferentialCustomersTemplates[field.id] === customer.id) {
            setPreferentialCustomersTemplates({ ...preferentialCustomersTemplates, [field.id]: undefined });
            setMergedCustomer({ ...mergedCustomer, [field.id]: undefined });
            return;
        }
        setPreferentialCustomersTemplates({ ...preferentialCustomersTemplates, [field.id]: customer.id });
        setMergedCustomer({ ...mergedCustomer, [field.id]: customer[field.id] });
    };

    const closeModal = () => {
        mergeInProgressRef.current = false;
        setIsModalOpen(false);
        setCustomer1({});
        setCustomer2({});
        setMergedCustomer({});
    };

    const openEditModal = customer => {
        setEditCustomer(customer);
        setIsEditCustomerModalOpen(true);
    };

    const closeEditCustomerModal = () => {
        setIsEditCustomerModalOpen(false);
    };

    const onConfirmEditModal = async (data, file) => {
        try {
            await CustomerAPI.update(editCustomer.id, editCustomerToCustomer(data));
            if (file) {
                await uploadCustomerAvatar(editCustomer.id, file);
            } else if (deletedPhoto) {
                await CustomerAPI.deleteCustomerAvatar(editCustomer.id);
            }
            toastr.success('Customer successfully updated');
        } catch (e) {
            console.error(e);
            toastr.error(e?.data?.message || 'Something went wrong');
        }
    };

    useEffect(() => {
        refreshList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, sortState, debounceFilter, showDuplicates, showArchived]);

    useEffect(() => {
        async function getMergedCustomerTemplate() {
            try {
                if (!customer1.id || !customer2.id) return;
                if (mergeInProgressRef.current) return;

                mergeInProgressRef.current = true;

                const { customers } = await CustomerAPI.getMergeClientCustomers({
                    clientsIds: [customer1.id, customer2.id].join(','),
                    page: 0,
                    rowsPerPage: 25,
                    sort: 'firstName',
                    sortDirection: 1
                });

                if (customers) {
                    // eslint-disable-next-line array-callback-return
                    customers.map(customer => {
                        if (customer.id === customer1.id) {
                            setCustomer1({ ...customer1, ...customer });
                        } else if (customer.id === customer2.id) {
                            setCustomer2({ ...customer2, ...customer });
                        }
                    });
                }

                const { mergedCustomerTemplate, fieldsByCustomer } = await CustomerAPI.createMergeCustomerTemplate({
                    customer1Id: customer1.id,
                    customer2Id: customer2.id,
                    customerModelMap
                });
                setMergedCustomer(mergedCustomerTemplate);
                setPreferentialCustomersTemplates(fieldsByCustomer);
                setIsModalOpen(true);
            } catch (e) {
                setCustomer1({});
                setCustomer2({});
                mergeInProgressRef.current = false;
                console.error(e);
                toastr.error('Something went wrong', 'Could not resolve a merge between these clients');
            }
        }

        getMergedCustomerTemplate();
    }, [customer1, customer2]);

    const panelProps = {
        customers,
        page,
        setPage,
        isFromOrg,
        rowsPerPage,
        setRowsPerPage,
        numOfItems,
        setNumOfItems,
        sortState,
        setSortState,
        filter,
        setFilter,
        customer1,
        customer2,
        setCustomer1,
        setCustomer2,
        archiveCustomer,
        unArchiveCustomer,
        confirmCustomerMerge,
        updateMergedCustomer,
        openEditModal,
        allClinics
    };

    const [createCustomerModalOpen, setCreateCustomerModalOpen] = useState(false);

    const handlePersistCustomer = async data => {
        try {
            data.clinics = [clinic];
            await CustomerAPI.create(mapper(data));
            toastr.success('Customer successfully updated');
            return false;
        } catch (err) {
            console.error(err);
            toastr.error(err?.data?.message || 'Something went wrong');
            return true;
        }
    };

    const customerToEditCustomer = customer => {
        customer = Object.assign({}, customer);
        if (customer) {
            if (customer.notificationTypes) {
                customer.receiveNotificationSms = customer.notificationTypes.includes('SMS');
                customer.receiveNotificationEmails = customer.notificationTypes.includes('Email');
            }
            if (customer.marketingTypes) {
                customer.receiveMarketingSms = customer.marketingTypes.includes('SMS');
                customer.receiveMarketingEmails = customer.marketingTypes.includes('Email');
            }
        }
        customer.postal = customer.postal ? customer.postal : customer.postCode;
        return customer;
    };

    const editCustomerToCustomer = customer => {
        customer = Object.assign({}, customer);
        customer.notificationTypes = [];
        customer.marketingTypes = [];
        if (customer.receiveNotificationSms) {
            customer.notificationTypes.push('SMS');
        }
        if (customer.receiveNotificationEmails) {
            customer.notificationTypes.push('Email');
        }
        if (customer.receiveMarketingSms) {
            customer.marketingTypes.push('SMS');
        }
        if (customer.receiveMarketingEmails) {
            customer.marketingTypes.push('Email');
        }
        customer.referredBy = customer.referredBy ? customer.referredBy : undefined;
        customer.postCode = customer.postal ? customer.postal : customer.postCode;
        return customer;
    };

    const getFormattedData = async columns => {
        const allPages = await (async () => {
            const queryObject = {
                ...(sortState.columnId && { sort: sortState.columnId }),
                ...(sortState.sortDirection && { sortDirection: sortState.sortDirection === 'asc' ? 1 : -1 }),
                ...(filter && { value: filter }),
                ...(showDuplicates && { onlyDuplicates: showDuplicates }),
                isDownloadingList: true
            };
            const { customers } = await CustomerAPI.getMergeClientCustomers(queryObject);
            return customers;
        })();

        const data = allPages.map(row => {
            const newRow = {};
            columns.forEach(column => {
                if (column.type) {
                    switch (column.type) {
                        case 'date':
                            newRow[column.field] = dobFormatter(row[column.field], 'Missing DOB');
                            return;
                        case 'age':
                            newRow['age'] = calculateAge(row);
                            return;
                        default: {
                            const splitedId = column.field.split('.');
                            if (splitedId.length > 1) {
                                newRow[splitedId[1]] = dateFormatter(row[column.field]).toString();
                            } else {
                                newRow[column.field] = dateFormatter(row[column.field]).toString();
                            }
                            return;
                        }
                    }
                }

                if (column.field === '_id') {
                    newRow[column.field] = row['id'];
                } else {
                    newRow[column.field] = row[column.field];
                }
                if (column.field === 'marketingEmail') {
                    newRow[column.field] = row['marketingTypes'].includes('Email');
                }

                if (column.field === 'marketingSms') {
                    newRow[column.field] = row['marketingTypes'].includes('SMS');
                }
            });

            return newRow;
        });
        return data;
    };

    const formatAndExportCsv = async () => {
        try {
            setIsLoading(true);
            let columns = customersTableColumns;
            columns = columns.filter(el => el.field);
            columns.push(
                { field: 'marketingEmail', title: 'Email Marketing', reordable: true, style: {} },
                { field: 'marketingSms', title: 'SMS Marketing', reordable: true, style: {} }
            );
            await exportCsv({
                data: await getFormattedData(columns),
                tableInfo: {
                    title: 'Customers'
                },
                columns: columns
            });
        } catch (e) {
            console.error(e);
            toastr.error('Something went wrong');
        }
        setIsLoading(false);
    };

    return (
        <View>
            <div>
                <LoadingScreen isVisible={isLoading} />
                <MergeClientsHeader
                    {...panelProps}
                    formatAndExportCsv={formatAndExportCsv}
                    setCreateCustomerModalOpen={setCreateCustomerModalOpen}
                    setShowDuplicates={setShowDuplicates}
                    showDuplicates={showDuplicates}
                    showArchived={showArchived}
                    setShowArchived={setShowArchived}
                />
                {allClinics.length > 0 && <MergeClientsTable {...panelProps} showDuplicates={showDuplicates} />}
            </div>
            {createCustomerModalOpen && (
                <CreateCustomerModal
                    isCreateCustomerModalOpen={createCustomerModalOpen}
                    persistCustomer={handlePersistCustomer}
                    reloadCustomerList={refreshList}
                    hideCreateCustomerModal={() => setCreateCustomerModalOpen(false)}
                />
            )}
            {isModalOpen && (
                <MergeClientsModal
                    open={isModalOpen}
                    closeModal={closeModal}
                    customer1={customer1}
                    customer2={customer2}
                    mergedCustomer={mergedCustomer}
                    preferentialCustomerTemplates={preferentialCustomersTemplates}
                    confirmCustomerMerge={confirmCustomerMerge}
                    updateMergedCustomer={updateMergedCustomer}
                />
            )}
            <MergeClientsEditCustomer
                isEditCustomerModalOpen={isEditCustomerModalOpen}
                hideEditCustomerModal={closeEditCustomerModal}
                persistCustomer={onConfirmEditModal}
                reloadCustomerList={refreshList}
                customer={customerToEditCustomer(editCustomer)}
                deletedPhoto={deletedPhoto}
                setDeletedPhoto={setDeletedPhoto}
            />
        </View>
    );
}

export default MergeClientsView;
