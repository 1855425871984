import cloneDeep from 'lodash/cloneDeep';

const getCommissionsData = (commission, categoryItems) => {
    const commissionsData = cloneDeep(commission);
    Object.keys(commissionsData.commissions || {}).forEach(commissionSection => {
        const categoryList =
            {
                service: categoryItems.services
            }[commissionSection] ?? [];
        commissionsData.commissions[commissionSection].forEach(commissionItem => {
            if ((commissionItem.categories || []).length) {
                commissionItem.categories = commissionItem.categories
                    .map(categId => {
                        return categoryList.find(categ => categ.id === categId);
                    })
                    .filter(el => el);
            } else if (commissionItem.allCategories) {
                commissionItem.categories = categoryList;
            }
            if (commissionItem !== undefined) {
                if (isNaN(commissionItem.flatRateValue)) {
                    commissionItem.flatRateValue = undefined;
                } else {
                    commissionItem.flatRateValue = commissionItem.flatRateValue.toString();
                }
            }
            if (commissionItem.commissionRanges?.length) {
                commissionItem.commissionRanges = commissionItem.commissionRanges.map(item => ({
                    ...item,
                    id: undefined
                }));
            }
        });
    });
    return commissionsData;
};

export default getCommissionsData;
