import { Box, Button, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import XeroApi from '../../api/XeroApi';
import { squareView } from './styles';

export default function SquareView() {
    const classes = makeStyles(squareView)();
    const location = useLocation();
    const history = useHistory();

    const [state, setState] = useState();
    const [xeroError, setXeroError] = useState();
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const code = params.get('code');
        const error = params.get('error');
        const responseState = params.get('state');

        if (error) {
            XeroApi.generateCsrf().then(setState);
            return setXeroError(error);
        }

        if (code) {
            XeroApi.createSeller(code, responseState, process.env.REACT_APP_ADMIN_URL + '/organisation/xero')
                .then(() => {
                    setIsConnected(true);
                    history.replace('/organisation/xero');
                })
                .catch(err => {
                    setXeroError(err?.data?.message);
                    history.replace('/organisation/xero');
                });
        } else {
            XeroApi.checkSellerConnected().then(data => {
                setIsConnected(data.isConnected);
                if (!data.isConnected) {
                    XeroApi.generateCsrf().then(setState);
                }
            });
        }

        // eslint-disable-next-line
    }, [location.search]);

    const redirect = () => {
        const scopes =
            'offline_access openid profile email accounting.contacts accounting.transactions accounting.settings';
        window.location.href = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${process.env.REACT_APP_XERO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_ADMIN_URL}/organisation/xero&scope=${scopes}&state=${state}`;
    };

    const revoke = () => {
        XeroApi.revokeToken().then(() => setIsConnected(false));
        XeroApi.generateCsrf().then(setState);
    };

    return (
        <div className={classes.container}>
            <Box display="flex" flexDirection="column">
                {!isConnected && (
                    <>
                        {xeroError && (
                            <div className={classes.errorBox}>
                                Error! The required permissions were not granted. Please try again!
                            </div>
                        )}
                        <div className={classes.fullWidthRoboto}>
                            <b className={classes.font16px}>Instructions to connect your Xero Account to Collums:</b>
                            <ol>
                                <li>
                                    Click &quot;Connect&quot; and Enter your Xero account log in details into the Xero
                                    log in page.
                                </li>
                                <li>Select the organization that you would like to use, and allow access.</li>
                            </ol>
                        </div>
                        <div className={classes.connectButton}>
                            <Button color="primary" variant="contained" onClick={redirect}>
                                Connect
                            </Button>
                        </div>
                    </>
                )}
                {isConnected && (
                    <>
                        <b>You are connected with your Xero account, to revoke the token click Revoke</b>
                        <div className={classes.connectButton}>
                            <Button color="warn" variant="outlined" className={classes.revokeBtn} onClick={revoke}>
                                Revoke
                            </Button>
                        </div>
                    </>
                )}
            </Box>
        </div>
    );
}
