import api from '../collums-components/api/config';

class ServiceApi {
    static getServices(qs, isFromOrg = false, clinic, count, category) {
        return api.request({
            method: 'GET',
            url: `/services?value=${encodeURIComponent(qs.value)}&isFromOrg=${isFromOrg}&clinic=${clinic}${
                count ? `&count=${count}` : ''
            }${category ? `&category=${category}` : ''}`
        });
    }
}

export default ServiceApi;
