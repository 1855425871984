import Api from './config';
class CategoryApi {
    static query(params = '', isFromOrg = false) {
        return Api.request({
            method: 'GET',
            url: `/categories${params}&isFromOrg=${isFromOrg}`
        });
    }
    static clinics(params = '') {
        return Api.request({
            method: 'GET',
            url: `/categories/clinics${params}`
        });
    }

    static countCategories(clinic, isFromOrg = false) {
        return Api.request({
            method: 'GET',
            url: `/categories/count?clinic=${clinic}&isFromOrg=${isFromOrg}`
        });
    }

    static create(data) {
        return Api.request({
            method: 'POST',
            url: '/categories',
            data
        });
    }

    static archive(categoryId, value, clinic, isFromOrg = false) {
        return Api.request({
            method: 'POST',
            url: `/categories/${categoryId}/archive?value=${value}&clinic=${clinic}&isFromOrg=${isFromOrg}`
        });
    }

    static update(categoryId, data, clinic, isFromOrg = false) {
        return Api.request({
            method: 'PUT',
            url: `/categories/${categoryId}?clinic=${clinic}&isFromOrg=${isFromOrg}`,
            data
        });
    }

    static remove(categoryId) {
        return Api.request({
            method: 'DELETE',
            url: `/categories/${categoryId}`
        });
    }

    static filter(value = '', isSub = false, clinic, isFromOrg = false, isFromListOpts = false) {
        return Api.request({
            method: 'GET',
            url: `/categories/filterBy?name=${value}&isSub=${isSub}&clinic=${clinic}&isFromOrg=${isFromOrg}&isFromListOpts=${isFromListOpts}`
        });
    }

    static getSubCategories(value = '', id, clinic, isFromOrg = false, notAllowNull = false) {
        if (!id) return [];
        return Api.request({
            method: 'GET',
            url: `/categories/${id}/get-sub-categories?name=${value}&clinic=${clinic}&isFromOrg=${isFromOrg}&notAllowNull=${notAllowNull}`
        });
    }

    static adminList(params = '') {
        return Api.request({
            method: 'GET',
            url: `/admin-categories${params}`
        });
    }

    static changeCategoryActive(id, isFromOrg = false, clinic, value) {
        return Api.request({
            method: 'PUT',
            url: `/category/${id}/change-active?value=${value}&clinic=${clinic}&isFromOrg=${isFromOrg}`
        });
    }

    static changeCategoryShowOnline(id, value, clinic, isFromOrg = false) {
        return Api.request({
            method: 'PUT',
            url: `/category/${id}/change-show-online?value=${value}&clinic=${clinic}&isFromOrg=${isFromOrg}`
        });
    }

    static get(id) {
        return Api.request({
            method: 'GET',
            url: `/categories/${id}`
        });
    }

    static getAll() {
        return Api.request({
            method: 'GET',
            url: '/categories/get-all'
        });
    }
}

export default CategoryApi;
