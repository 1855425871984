export const tagsStyles = () => ({
    header: {
        display: 'flex',
        alignItems: 'center'
    },
    actionsHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 20,
        marginTop: 1,
        alignItems: 'center',
        height: 48
    },
    typeSelect: {
        minWidth: 120,
        width: 200
    },
    select: { height: 39 },
    rightActionsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    checkboxContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '& span': {
            color: 'rgba(0, 0, 0, 0.87)'
        },
        '& label': {
            fontFamily: 'Roboto, Arial, Sans-serif',
            marginRight: 10
        },
        '& :last-child': {
            marginRight: 0
        }
    }
});
