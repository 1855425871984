import React from 'react';
import PropTypes from 'prop-types';
import { Typography, TextField, MenuItem, Select } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { unitAndValueStyles } from './styles';
import { getCurrencySymbol } from '../../collums-components/helpers';

const UnitAndValue = ({ field, onChange, classes }) => {
    const optionsMap = {
        pound: 'Amount',
        amountUnits: 'Amount (part units)',
        amountWhole: 'Amount whole / part',
        percentage: '%'
    };
    const value = field.value || {};

    const onItemChange = (unitValue, valueValue) => {
        let calculatedUnit = null == unitValue ? value.unit : unitValue;
        let calculatedValue = null == valueValue ? value.value : valueValue;
        if (0 > calculatedValue) {
            calculatedValue = 0;
        }
        if (100 < calculatedValue && ('percentage' === calculatedUnit || 'amountUnits' === calculatedUnit)) {
            calculatedValue = 100;
        }
        onChange(field.id, { unit: calculatedUnit, value: calculatedValue });
    };

    const renderLabel = () => {
        if (!field.label) {
            return null;
        }
        return <Typography className={field.isValid ? classes.label : classes.labelError}>{field.label}</Typography>;
    };

    const renderDescription = () => {
        if (!field.errorMessages && !field.description) {
            return null;
        }
        return (
            <Typography className={field.isValid ? classes.description : classes.descriptionError}>
                {field.errorMessages || field.description}
            </Typography>
        );
    };

    const renderPrefix = () => {
        if ('pound' !== value.unit) {
            return null;
        }
        return <Typography className={classes.prefix}>{getCurrencySymbol()}</Typography>;
    };

    const renderSuffix = () => {
        if ('percentage' !== value.unit && 'amountUnits' !== value.unit) {
            return null;
        }
        return <Typography className={classes.suffix}>%</Typography>;
    };

    return (
        <div className={classes.container}>
            {renderLabel()}
            {renderDescription()}
            <div className={classes.row}>
                <div className={classes.itemUnit}>
                    <Select
                        id={`${field.id}-unit`}
                        value={value.unit || ''}
                        onChange={event => onItemChange(event.target.value, null)}
                        fullWidth
                    >
                        {field.units.map(item => (
                            <MenuItem key={item} value={item}>
                                {optionsMap[item]}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                <div className={classes.itemSeparator} />
                <div className={classes.itemValue}>
                    {renderPrefix()}
                    <TextField
                        id={`${field.id}-value`}
                        value={value.value || ''}
                        type="number"
                        onChange={event => onItemChange(null, event.target.value)}
                        fullWidth
                    />
                    {renderSuffix()}
                </div>
            </div>
        </div>
    );
};

UnitAndValue.propTypes = {
    field: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(unitAndValueStyles)(UnitAndValue);
