import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    makeStyles,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { serviceDetailsModalStyles } from '../../services/styles';
import { useSelector } from 'react-redux';
import PinSaveModal from './helper/PinSaveModal';
import HelpIcon from '@material-ui/icons/Help';

export default function SecurityTab({ register, setValue, practitioner, setPinHasChanged }) {
    const classes = makeStyles(serviceDetailsModalStyles)();
    const [hideOtherColumns, setHideOtherColumns] = useState(practitioner.hideOtherColumns);

    const role = useSelector(state => state.organisation.role);
    const job = useSelector(state => state.organisation.job);

    useEffect(() => {
        if (job?.code === 'ADM - 06' || role === 'admin') {
            setPin(practitioner?.loginPin);
        }
        // eslint-disable-next-line
    }, [role, job]);

    const [pin, setPin] = useState();
    const [showPinModal, setShowPinModal] = useState();

    /* useEffect(() => {
        register({ name: 'loginPin', required: (practitioner || {}).id ? false : ' PIN' });
        /* eslint-disable-next-line /
    }, []);*/

    useEffect(() => {
        setValue('loginPin', pin);
        /*eslint-disable-next-line */
    }, [pin]);

    return (
        <div className={classes.formContent}>
            {showPinModal && (
                <PinSaveModal
                    classes={classes}
                    onConfirm={() => setShowPinModal(false)}
                    onCancel={() => setShowPinModal(false)}
                />
            )}
            <div className={classes.formContentRow}>
                <FormControlLabel
                    label="Only show own column in calendar"
                    control={
                        <Checkbox
                            checked={hideOtherColumns}
                            onChange={event => setHideOtherColumns(event.target.checked)}
                            inputProps={{ name: 'hideOtherColumns' }}
                            inputRef={register}
                        />
                    }
                />
            </div>
            <div className={classes.formContentRow}>
                <Typography style={{ fontSize: 14 }}>Username is the same as employee email.</Typography>
            </div>
            <div className={classes.formContentRow} style={{ marginTop: 10 }}>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Password</InputLabel>
                    <TextField
                        inputRef={register}
                        placeholder={practitioner.hasPassword ? '*****' : ''}
                        name="password"
                        variant="outlined"
                    />
                </FormControl>
            </div>
            <FormControl className={classes.formItem} variant="outlined" style={{ marginTop: '20px' }}>
                <InputLabel shrink>PIN</InputLabel>
                <Tooltip
                    style={{
                        position: 'absolute',
                        top: '-22px',
                        marginLeft: '35px',
                        width: '0.8em'
                    }}
                    title={
                        <Typography style={{ fontSize: 14 }}>
                            PIN must be 5 digits and be unique to each member of staff. PINs identify the member of
                            staff when starting a client journey and are used to log back in when locking the screen via
                            the lock icon in the right hand menu.
                        </Typography>
                    }
                    aria-label="help"
                >
                    <HelpIcon />
                </Tooltip>
                <TextField
                    onChange={e => {
                        const pin = e.target.value;
                        if (pin.length > 5) {
                            toastr.warning('Invalid PIN', 'PIN must have 5 digits');
                        } else if (pin.length === 5 && pin.split('').every(x => x === pin[0])) {
                            toastr.warning('Invalid PIN', 'PIN must not be the same 5 digits');
                        } else if (
                            pin.length === 5 &&
                            pin
                                .split('')
                                .every((x, i) => i === pin.length - 1 || parseInt(x) + 1 === parseInt(pin[i + 1]))
                        ) {
                            toastr.warning('Invalid PIN', 'PIN must not be a sequence');
                        } else if (
                            pin.length === 5 &&
                            pin
                                .split('')
                                .every((x, i) => i === pin.length - 1 || parseInt(x) - 1 === parseInt(pin[i + 1]))
                        ) {
                            toastr.warning('Invalid PIN', 'PIN must not be a sequence');
                        } else if (/^\d+$/.test(e.target.value || '0')) {
                            setPin(e.target.value);
                            setPinHasChanged(true);
                        }
                    }}
                    type="text"
                    autoComplete="off"
                    value={pin}
                    inputRef={register}
                    name="loginPin"
                    variant="outlined"
                />
            </FormControl>
        </div>
    );
}
