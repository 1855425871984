import React, { useEffect, useRef, useState } from 'react';
import {
    Button,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
    withStyles,
    makeStyles,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import notificationsStyle from '../style';
import SMSEditor from './components/SMSEditor';
import NotificationApi from '../../../api/NotificationApi';
import { toastr } from 'react-redux-toastr';
import { KeyboardTimePicker } from '@material-ui/pickers';
import SmsTemplate from './components/SmsTemplate';

import { modalsButtonStyles } from '../../../collums-constants/styles/stylesheets/buttonsStyles';
import { getCurrentTimezonedDate } from '../../../collums-components/helpers/timezone';
import OrganisationsApi from '../../../api/OrganisationsApi';
import { getOrganisationPhoneCode } from '../../../collums-components/helpers';

const SmsTab = ({ classes, notification, closeAccordion, setHaveSMSUnsavedChanges }) => {
    const globalStyles = makeStyles(modalsButtonStyles)();

    const mergeTags = notification.sms.mergeTags.map(element => `{${element}}`);
    const editorRef = useRef();
    let targetOptions;
    if (notification.from === 'Appointment') {
        targetOptions = ['Clinic', 'Customer', 'Practitioner'];
    } else {
        if (notification.from === 'User') targetOptions = ['Customer'];
        else targetOptions = ['Clinic', 'Customer'];
    }
    let triggerOptions;
    if (notification.from === 'Appointment') {
        triggerOptions = [
            { label: 'appointment cancellation', value: 'cancel' },
            { label: 'appointment creation', value: 'creation' },
            { label: 'reschedule appointment', value: 'reschedule' }
        ];
    } else {
        if (notification.from === 'User') triggerOptions = [{ label: 'Sign up', value: 'signUp' }];
        else
            triggerOptions = [
                { label: 'invoice payment', value: 'invoicePayment' },
                { label: 'invoice refund', value: 'invoiceRefund' }
            ];
    }

    const [loadingUpload, setLoadingUpload] = useState({ visible: false, status: 'loading' });
    const [message, setMessage] = useState(notification ? notification.sms.content : '');
    const [smsTemplates, setSMSTemplates] = useState([]);
    const [isSavingTemplate, setIsSavingTemplate] = useState(false);
    const [smsList, setSmsList] = useState('');
    //const [preview, setPreview] = useState('');
    const [target, setTarget] = useState(notification ? notification.sms.target : targetOptions[0]);
    const trigger = notification ? notification.sms.trigger : triggerOptions[0].value;
    const [selectedTime, setSelectedTime] = useState(notification ? notification.sms.sendReminder?.value || 1 : 1);
    const [selectedType, setSelectedType] = useState(
        notification ? notification.sms.sendReminder?.type || 'days' : 'days'
    );
    const [hourOfDay, setHourOfDay] = useState(9);
    const [minuteOfDay, setMinuteOfDay] = useState(0);
    const isReminder = notification ? notification.sms.isReminder : false;
    const [selectionPosition, setSelectionPosition] = useState(0);
    const [templateIndex, setTemplateIndex] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState();
    const [sendOnAppointmentCreation, setSendOnAppointmentCreation] = useState(
        notification.sms.sendOnAppointmentCreation
    );
    const [organisation, setOrganisation] = useState(null);

    useEffect(() => {
        const getOrganisation = async () => {
            return OrganisationsApi.getOrganisation();
        };

        if (!organisation) {
            getOrganisation().then(org => {
                setOrganisation(org);
            });
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (notification) {
            const [hour, minute] = notification.sms.timeOfDayToSend.split(':');
            setHourOfDay(Number(hour));
            setMinuteOfDay(Number(minute));
        }
        /*eslint-disable-next-line */
    }, []);

    const timeArray = new Array(100).fill(100).map((el, index) => index + 1);

    const keyboardTimeValue = getCurrentTimezonedDate();
    keyboardTimeValue.setHours(hourOfDay);
    keyboardTimeValue.setMinutes(minuteOfDay);

    const sendTestSMS = async () => {
        if (!smsList) {
            return toastr.warning('Please enter a mobile number');
        }
        if (smsList && message) {
            await NotificationApi.sendTests(false, {
                list: smsList,
                notificationTemplate: message,
                type: notification.from,
                id: notification.id
            });
            toastr.success('Test SMS sent');
        }
    };

    const changeTime = e => {
        if (!e) return;
        const hours = e.getHours();
        const min = e.getMinutes();
        setHourOfDay(hours);
        setMinuteOfDay(min);
        setHaveSMSUnsavedChanges(true);
    };

    const saveData = async () => {
        if (!message) {
            toastr.error('Missing content');
            return;
        }
        if (!isReminder && !trigger) {
            toastr.error('Missing occasion');
            return;
        }
        const payload = {
            content: message,
            isReminder,
            trigger,
            sendReminder: {
                value: selectedTime,
                type: selectedType
            },
            timeOfDayToSend: `${hourOfDay < 10 ? `0${hourOfDay}` : hourOfDay}:${
                minuteOfDay < 10 ? `0${minuteOfDay}` : minuteOfDay
            }`,
            ...(notification.from === 'Form' ? { sendOnAppointmentCreation } : {})
        };
        try {
            await NotificationApi.updateSMS(notification.id, payload);
            toastr.success('Notification sms successfully updated!');
            setHaveSMSUnsavedChanges(false);
            closeAccordion();
        } catch (err) {
            if (typeof err === 'object') {
                if (err.data && err.data.message) {
                    toastr.error(err.data.message);
                    return;
                }
            }
            toastr.error('Something went wrong');
        }
    };

    const loadTemplates = () => {
        NotificationApi.getTemplates('sms').then(res => setSMSTemplates(res));
    };

    useEffect(loadTemplates, []);

    useEffect(() => {
        if (!selectedTemplate) return;
        smsTemplates.forEach((template, index) => {
            if (template.name === selectedTemplate.name) {
                setTemplateIndex(index);
            }
        });
        //eslint-disable-next-line
    }, [smsTemplates]);

    const closeTemplateModal = newTemplate => {
        setIsSavingTemplate(false);
        if (newTemplate) {
            setSelectedTemplate(newTemplate);
            loadTemplates();
        }
    };

    const saveTemplateAction = () => {
        if (message) setIsSavingTemplate(true);
        else toastr.warning('Content cannot be blank');
    };

    const updateTemplateAction = async () => {
        if (!selectedTemplate) return;
        if (!selectedTemplate.id) return;
        if (!message) {
            toastr.error('Missing content');
            return;
        }
        const payload = {
            content: message
        };
        try {
            const updatedTemplate = await NotificationApi.updateTemplate(selectedTemplate.id, payload);
            toastr.success('Notification template successfully updated!');
            setSelectedTemplate(updatedTemplate);
            await loadTemplates();
        } catch (err) {
            if (typeof err === 'object') {
                if (err.data && err.data.message) {
                    toastr.error(err.data.message);
                    return;
                }
            }
            toastr.error('Something went wrong');
        }
    };

    const ReminderOptions = () => {
        return (
            <>
                <div className={classes.reminderContainer}>
                    <Typography>Send reminder:</Typography>
                    <Select
                        id="select-remind-time"
                        variant="outlined"
                        defaultValue={selectedTime}
                        inputProps={{
                            className: classes.selectInputProps
                        }}
                        style={{ width: 70, marginLeft: 5 }}
                        onChange={evt => {
                            setSelectedTime(evt.target.value);
                            setHaveSMSUnsavedChanges(true);
                        }}
                    >
                        {timeArray.map((time, index) => {
                            return (
                                <MenuItem key={index} value={time}>
                                    {time}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <Select
                        id="select-remind-time-type"
                        variant="outlined"
                        defaultValue={selectedType}
                        inputProps={{
                            className: classes.selectInputProps
                        }}
                        style={{ width: 100, marginRight: 5 }}
                        onChange={evt => {
                            setSelectedType(evt.target.value);
                            setHaveSMSUnsavedChanges(true);
                        }}
                    >
                        <MenuItem value="days">day(s)</MenuItem>
                        <MenuItem value="hours">hour(s)</MenuItem>
                    </Select>
                    <Typography>before appointment</Typography>
                </div>
                {selectedType === 'days' && (
                    <div style={{ display: 'flex', marginTop: 10 }}>
                        <KeyboardTimePicker
                            margin="normal"
                            id="hour-and-minute"
                            value={keyboardTimeValue}
                            onChange={changeTime}
                            KeyboardButtonProps={{
                                'aria-label': 'change time'
                            }}
                            TextFieldComponent={props => (
                                <TextField
                                    {...props}
                                    style={{ height: 30, width: 150 }}
                                    label="Time of day to send"
                                    variant="outlined"
                                />
                            )}
                            ampm={false}
                        />
                    </div>
                )}
            </>
        );
    };

    return (
        <div style={{ display: 'block', width: '100%' }}>
            <Grid container xs={12} spacing={2} style={{ marginBottom: 20 }}>
                <Grid container item xs={12} alignItems="center">
                    <Grid item className={classes.defaultLabelWidth}>
                        <Typography>Format:</Typography>
                    </Grid>
                    <Grid item className={classes.defaultLabelWidth}>
                        <Typography>SMS</Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={12} alignItems="center">
                    <Grid item className={classes.defaultLabelWidth}>
                        <Typography>Template:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Select
                            id="select"
                            defaultValue=""
                            variant="outlined"
                            style={{ width: 300, height: 30 }}
                            onChange={evt => {
                                const value = evt.target.value;
                                if (typeof value !== 'number') {
                                    setTemplateIndex('');
                                    return;
                                }
                                setMessage(smsTemplates[value].content);
                                setSelectedTemplate(smsTemplates[value]);
                                setTemplateIndex(value);
                                setHaveSMSUnsavedChanges(true);
                            }}
                            value={templateIndex}
                        >
                            <MenuItem value="">None</MenuItem>
                            {smsTemplates.map((template, index) => {
                                return (
                                    <MenuItem key={index} value={index}>
                                        <Typography>{template.name}</Typography>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Grid>
                </Grid>
                <Grid container item xs={12} alignItems="center">
                    <Grid item className={classes.defaultLabelWidth}>
                        <Typography>SMS to:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Select
                            id="select"
                            defaultValue={target}
                            variant="outlined"
                            style={{ width: 300, height: 30 }}
                            onChange={evt => {
                                const value = evt.target.value;
                                if (!value) return;
                                setTarget(value);
                                setHaveSMSUnsavedChanges(true);
                            }}
                            disabled={true}
                        >
                            {targetOptions.map((option, index) => {
                                return (
                                    <MenuItem key={index} value={option}>
                                        <Typography>{option}</Typography>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Grid>
                </Grid>
            </Grid>
            <SMSEditor
                setLoadingUpload={setLoadingUpload}
                loadingUpload={loadingUpload}
                editorRef={editorRef}
                smsMessage={message}
                setSMSMessage={setMessage}
                haveUnsavedChanges={() => setHaveSMSUnsavedChanges(true)}
                setSelectionPosition={setSelectionPosition}
                selectionPosition={selectionPosition}
                mergeTags={mergeTags}
            />
            <div style={{ marginTop: 20 }}>
                {selectedTemplate ? (
                    <Button className={classes.blueBtn} onClick={updateTemplateAction}>
                        Update template
                    </Button>
                ) : (
                    <Button className={classes.blueBtn} onClick={saveTemplateAction}>
                        Save template
                    </Button>
                )}
            </div>
            {isSavingTemplate && (
                <SmsTemplate message={message} getTemplates={loadTemplates} closeModal={closeTemplateModal} />
            )}
            <div className={classes.testContainer}>
                <Typography>Send test sms:</Typography>
                <TextField
                    style={{ marginLeft: 20, marginRight: 10, width: '50%' }}
                    placeholder={`Number must start with ${getOrganisationPhoneCode(
                        organisation
                    )} (omit next 0). Separate numbers with commas`}
                    variant="outlined"
                    value={smsList}
                    className={`${classes.input} usePlaceholderTextField`}
                    onChange={e => {
                        setSmsList(e.target.value);
                    }}
                />
                <Button className={classes.blueBtn} onClick={sendTestSMS}>
                    Send
                </Button>
                {isReminder && notification.from === 'Form' && (
                    <FormControlLabel
                        control={
                            <Checkbox
                                value={sendOnAppointmentCreation}
                                onChange={e => setSendOnAppointmentCreation(e.target.checked)}
                            />
                        }
                        label="Send on appointment creation"
                    />
                )}
            </div>
            {isReminder && (notification.from === 'Appointment' || !sendOnAppointmentCreation) && (
                <ReminderOptions sendEmail={notification.from === 'Form'} />
            )}
            <div className={globalStyles.buttonsContainer}>
                <Button
                    className={globalStyles.cancelButton}
                    onClick={() => {
                        closeAccordion();
                        setHaveSMSUnsavedChanges(false);
                    }}
                >
                    Cancel
                </Button>
                <Button className={globalStyles.confirmButton} onClick={saveData}>
                    Save
                </Button>
            </div>
        </div>
    );
};

export default withStyles(notificationsStyle)(SmsTab);
