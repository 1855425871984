import React, { useState, useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';

import { toLocaleString, useDebounce } from '../../collums-components/helpers/index';

import View from '../common/View';
import Actions from '../common/TabContent/actions';
import CourseFormModal from './CourseFormModal';

import CourseApi from '../../api/CoursesApi';
import TaxesApi from '../../api/TaxesApi';
import CourseCategoriesApi from '../../api/CourseCategoriesApi';

import addCopySufix from '../../services/addCopySufix';

import {
    IconButton,
    Link,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    Typography,
    withStyles,
    makeStyles,
    Checkbox,
    FormControlLabel
} from '@material-ui/core';
import { Clear, Search, AddCircle } from '@material-ui/icons';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import { coursesStyles } from './styles';
import { inputSearchStyles } from '../../collums-constants/styles/stylesheets/inputSearchStyles';
import { buttonsStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import ConfirmModal from '../common/ConfirmModal';
import Switch from '../../collums-components/components/common/customSwitch';
import { getLocationItem } from '../../collums-constants/utils';

const columns = [
    { field: 'Active' },
    { field: 'Name' },
    { field: '#', style: { width: '15' } },
    { field: 'Category' },
    { field: 'Sub Category' },
    { field: 'Net Price' },
    { field: 'Tax Type' },
    { field: 'Gross Price' },
    { field: 'Actions' }
];

const errorMessage = {
    title: 'Something went wrong',
    message: ''
};

function CoursesView({ classes, clinic, clinics, isFromOrg, allClinics }) {
    const [isOpen, setIsOpen] = useState(false);
    const [courses, setCourses] = useState([]);
    const [value, setValue] = useState('');
    const [selectedCourse, setSelectedCourse] = useState({});
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [isLoading, setIsLoading] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState('');
    const [courseId, setCourseId] = useState('');
    const [showArchived, setShowArchived] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [showInactive, setShowInactive] = useState(true);
    const [courseTaxes, setCourseTaxes] = useState([]);
    const [categories, setCategories] = useState([]);

    const filterDebounce = useDebounce(value, 600);

    const globalSearchStyles = makeStyles(inputSearchStyles)();
    const globalButtonsStyles = makeStyles(buttonsStyles)();

    const onArchiveConfirm = async () => {
        await handleArchive();
        await refreshList();
    };

    const openArchive = async id => {
        setCourseId(id);
        const course = courses.find(element => element.id.toString() === id.toString()) || {};
        if (course.archived) {
            onArchiveConfirm();
            return true;
        }
        openConfirmModal(
            `${course.archived ? 'Unarchived course' : 'Archive course'}`,
            `Are you sure you want to ${course.archived ? 'unarchive' : 'archive'} this course?`
        );
    };
    const getTaxes = async item => {
        try {
            const taxes = await TaxesApi.getTaxesForItem(item);
            setCourseTaxes(taxes);
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        }
    };
    useEffect(() => {
        const run = async () => {
            await getTaxes('services');
        };
        run();
    }, [courses]);

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setModalTitle('');
        setModalContent();
    };

    const openConfirmModal = (title, component) => {
        setConfirmModal(true);
        setModalTitle(title);
        setModalContent(component);
    };

    const refreshList = async () => {
        setIsLoading(true);
        try {
            const qs = queryParamsBuilder({
                value,
                pageSize: rowsPerPage,
                skip: page * rowsPerPage,
                archived: showArchived,
                inactive: showInactive,
                clinic: clinic,
                isFromOrg: isFromOrg || 'false'
            });
            const result = await CourseApi.listCourses(qs);
            setCourses(result.courses);
            setCount(result.size);
        } catch {
            toastr.error(errorMessage.title, errorMessage.message);
        }
        setIsLoading(false);
    };

    const queryParamsBuilder = qs => {
        if (!qs) return '';
        const keys = Object.keys(qs);
        let string = '?';
        keys.filter(key => qs[key] || qs[key] === 0).forEach(key => {
            string = string.concat(`${key}=${qs[key]}&`);
            return string;
        });
        const queryString = encodeURI(string);
        return queryString;
    };

    const onChangePage = (e, newPage) => {
        setPage(newPage);
    };

    const onChangeRowsPerPage = e => {
        setPage(0);
        setRowsPerPage(parseInt(e.target.value));
    };

    const handleArchive = async () => {
        try {
            const currentCourse = courses.find(course => course.id.toString() === courseId.toString());
            const value = isFromOrg ? !currentCourse.archived : !getLocationItem(currentCourse, clinic).archived;
            if (!courseId) return;
            if (courses?.find(course => course.id === courseId)?.name.includes('- COPY'))
                return toastr.error("Can't Archive Copies", 'Please change the name field to can archive this!');
            await CourseApi.archiveCourse(courseId, value, isFromOrg, clinic);
            await refreshList();
        } catch (err) {
            toastr.error(err?.data?.message || errorMessage.title, errorMessage.message);
        }
    };

    const handleCopy = async id => {
        try {
            const course = await CourseApi.getCoursesWithouAppt(id);
            course.name = addCopySufix(course.name, courses);
            course.service = course.service[0].id;
            course.commissionForSellingCourse = course.commissionForSallingCourse;

            delete course.id;
            delete course.status;
            delete course.commissionForSallingCourse;
            delete course.allowCommissionSellingCourse;

            await CourseApi.createCourse(course);
            await refreshList();

            toastr.success('Course successfully duplicated.');
        } catch (e) {
            toastr.error(e.data?.message || errorMessage.message);
        }
    };
    const getCoursesCategories = async nameInput => {
        try {
            const queryParams = `?archived=${false}&name=${
                nameInput?.length ? nameInput : ''
            }&inactive=${false}&count=${1000}&withSubcategories=${false}&clinic=${
                clinic ? clinic : ''
            }&isFromOrg=${isFromOrg}`;
            const result = await CourseCategoriesApi.getCategories(queryParams);
            setCategories(result.items);
        } catch {
            toastr.error(errorMessage.title, errorMessage.message);
        }
    };
    const getSubCategories = category => {
        const names = category.subCategories.map(subCategory => subCategory.name);
        return names;
    };

    const actions = {
        /* handleDelete: id => handleDelete(id), */
        handleArchive: id => openArchive(id),
        handleDuplicate: id => handleCopy(id)
    };

    const getCourseNetPrice = (course, isTotal, salePrice) => {
        if (course.netPrice) {
            return parseFloat(Number(course.netPrice).toFixed(2));
        }
        let value;
        const courseTax =
            typeof course.tax === 'object' ? course.tax.rate : courseTaxes.find(tax => tax.id === course.tax)?.rate;
        const tax = courseTax;
        value = (salePrice || course.salePrice) / (tax / 100 + 1);

        if (!isTotal) value = value / course.quantity;
        return parseFloat(Number(value).toFixed(2));
    };

    const getCourseGrossPrice = (course, isTotal, salePrice) => {
        let value = salePrice || course.salePrice;
        if (!isTotal) value = value / course.quantity;
        return parseFloat(Number(value).toFixed(2));
    };

    useEffect(() => {
        refreshList();
        getCoursesCategories();
        /*eslint-disable-next-line */
    }, [page, rowsPerPage, filterDebounce, showArchived, showInactive]);

    const changeCourseActive = async (course, value) => {
        if (!(course || {}).id) return;
        if (course.archived) {
            toastr.error(`Cannot change course ${course.name} active, because this course is archived`);
            return;
        }
        try {
            await CourseApi.changeCourseActive(course.id, value, isFromOrg, clinic);

            if (isFromOrg) {
                setCourses([
                    ...courses.map(c => {
                        if (c.id === course.id) {
                            c.active = value;
                        }
                        return c;
                    })
                ]);
            } else {
                setCourses([
                    ...courses.map(c => {
                        if (c.id === course.id) {
                            c.locations = c.locations.map(l => {
                                if (l.clinic === clinic) {
                                    l.active = value;
                                }
                                return l;
                            });
                        }
                        return c;
                    })
                ]);
            }

            toastr.success(`Course ${course.name} successfully updated`);
        } catch (err) {
            if (typeof err === 'object') {
                if (err.data && err.data.message) {
                    toastr.error(err.data.message);
                    return;
                }
            }
            toastr.error('Something went wrong');
        }
    };

    const renderRow = useMemo(() => {
        return courses.map(course => (
            <TableRow
                key={course.id}
                style={{
                    backgroundColor: (() => {
                        if (isFromOrg ? !course.archived : !getLocationItem(course, clinic).archived) {
                            return 'transparent';
                        }
                        return '#ff9e9e';
                    })()
                }}
            >
                <TableCell>
                    <Switch
                        disabled={isFromOrg ? course.archived : getLocationItem(course, clinic).archived}
                        checked={isFromOrg ? course.active : getLocationItem(course, clinic).active}
                        onChange={(e, value) => changeCourseActive(course, value)}
                    />
                </TableCell>
                <TableCell>
                    <Typography>
                        <Link
                            onClick={() => {
                                setSelectedCourse({ ...course, prevName: course.name });
                                setIsOpen(true);
                            }}
                        >
                            {course.name}
                        </Link>
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography>{course.quantity}</Typography>
                </TableCell>
                <TableCell>
                    <Typography>{course.category?.name}</Typography>
                </TableCell>
                <TableCell>
                    <Typography>{getSubCategories(course.category)}</Typography>
                </TableCell>
                <TableCell className={classes.cellRightAlign}>
                    <Typography>
                        {toLocaleString(
                            getCourseNetPrice(isFromOrg ? course : getLocationItem(course, clinic, 'netPrice'), true)
                        )}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography>
                        {isFromOrg
                            ? course?.itemTax?.name
                            : courseTaxes.find(tax => tax.id === getLocationItem(course, clinic, 'tax').tax)?.name}
                    </Typography>
                </TableCell>
                <TableCell className={classes.cellRightAlign}>
                    <Typography>
                        {toLocaleString(
                            getCourseGrossPrice(isFromOrg ? course : getLocationItem(course, clinic), true)
                        )}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Actions
                        isFromOrg={isFromOrg}
                        actions={actions}
                        id={course.id}
                        isArchived={isFromOrg ? course.archived : getLocationItem(course, clinic).archived}
                    />
                </TableCell>
            </TableRow>
        ));
        // eslint-disable-next-line
    }, [courses]);

    return (
        <>
            <LoadingScreen isVisible={isLoading} />
            <View>
                <div className={classes.header}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            className={globalSearchStyles.inputSearch}
                            id="seachSupplier"
                            variant="outlined"
                            value={value}
                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    refreshList();
                                }
                            }}
                            onChange={e => {
                                setValue(e.target.value);
                                setPage(0);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <>
                                        <IconButton
                                            className={globalSearchStyles.icons}
                                            onClick={() => {
                                                setValue('');
                                                setPage(0);
                                            }}
                                            disabled={!value}
                                        >
                                            <Clear style={!value ? { color: 'transparent' } : {}} />
                                        </IconButton>
                                        <IconButton
                                            position="end"
                                            onClick={() => refreshList()}
                                            className={globalSearchStyles.icons}
                                        >
                                            <Search />
                                        </IconButton>
                                    </>
                                )
                            }}
                        />
                        {(clinic === 'organisation' || allClinics.length === 1) && (
                            <div className={classes.rightActionsContainer}>
                                <IconButton
                                    onClick={() => {
                                        setSelectedCourse({});
                                        setIsOpen(true);
                                    }}
                                >
                                    <AddCircle className={globalButtonsStyles.addCircleStyle} />
                                </IconButton>
                            </div>
                        )}
                    </div>
                    <div className={classes.checkboxesContainer}>
                        <FormControlLabel
                            label="Show Archived"
                            control={
                                <Checkbox
                                    checked={showArchived}
                                    onChange={() => {
                                        setShowArchived(!showArchived);
                                    }}
                                />
                            }
                        />
                        {/*<FormControlLabel*/}
                        {/*    label="Show Inactive"*/}
                        {/*    control={*/}
                        {/*        <Checkbox checked={showInactive} onChange={() => setShowInactive(!showInactive)} />*/}
                        {/*    }*/}
                        {/*/>*/}
                    </div>
                </div>
                <TableContainer className={classes.tableContainer}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map(item => (
                                    <TableCell
                                        key={item.field}
                                        style={item.style}
                                        className={
                                            item.field === 'Net Price' || item.field === 'Gross Price'
                                                ? `${classes.cellRightAlign}`
                                                : ''
                                        }
                                    >
                                        {item.field}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>{courses.length > 0 && renderRow}</TableBody>
                    </Table>
                </TableContainer>
                <div className={classes.fixedTableFooter}>
                    <TablePagination
                        width={'100%'}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        rowsPerPageOptions={[10, 25, 50]}
                        onChangePage={onChangePage}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                    />
                </div>
            </View>
            {isOpen && (
                <CourseFormModal
                    getCoursesCategories={getCoursesCategories}
                    categories={categories}
                    setCategories={categories => setCategories(categories)}
                    open={isOpen}
                    title={selectedCourse.id ? `View/Edit ${selectedCourse.name} course` : 'New course'}
                    selectedCourse={selectedCourse}
                    onClose={() => setIsOpen(false)}
                    queryParamsBuilder={queryParamsBuilder}
                    refreshList={refreshList}
                    errorMessage={errorMessage}
                    getCourseGrossPrice={getCourseGrossPrice}
                    getCourseNetPrice={getCourseNetPrice}
                    clinic={clinic}
                    clinics={clinics}
                    isFromOrg={isFromOrg}
                    courseTaxes={courseTaxes}
                    allClinics={allClinics}
                />
            )}
            <ConfirmModal
                isOpen={confirmModal}
                setIsOpen={closeConfirmModal}
                onConfirm={onArchiveConfirm}
                title={modalTitle}
                content={modalContent}
            />
        </>
    );
}

CoursesView.propTypes = {
    classes: PropTypes.object
};

export default withStyles(coursesStyles)(CoursesView);
