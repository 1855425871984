const formModalStyles = () => ({
    formDesign: {
        display: 'block',
        padding: 10,
        width: '100%',
        '& > div > div > div': {
            // formContent
            minWidth: 'unset !important'
        }
    },
    accordions: {
        '& .MuiExpansionPanelSummary-content': {
            '& p': {
                fontSize: 16,
                fontWeight: 400
            }
        }
    }
});

export default formModalStyles;
