import React, { useEffect, useRef, useState, useCallback } from 'react';

import { useForm } from 'react-hook-form';
import { toastr } from 'react-redux-toastr';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Modal from '../common/Modal';
import Constants from '../../collums-constants';
import Accordion from '../common/Accordion';
import SecurityTab from './tabs/SecurityTab';
import PersonalTab from './tabs/PersonalTab';
import CommissionTab from './tabs/CommissionTab';
import ProfessionalTab from './tabs/ProfessionalTab';
import ServiceRulesTab from './tabs/ServiceRulesTab';
import OnlineBookingTab from './tabs/OnlineBookingTab';
// import { isEmpty } from 'lodash';

import { Button } from '@material-ui/core';
import { practitionerFormModalStyles } from './styles';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import PractitionerApi from '../../api/PractitionerApi';
import CategoryApi from '../../collums-components/api/CategoryApi';
import ServiceApi from '../../collums-components/api/ServiceApi';
import { isValidMongoIdString } from '../../collums-constants/utils';
import LocationTab from '../common/LocationTab';
import bluebird from 'bluebird';
import uniqBy from 'lodash/uniqBy';
import { listClinics } from '../../collums-components/api/ClinicApi';

export default withStyles(practitionerFormModalStyles)(function PractitionerFormModal({
    visible,
    onClose,
    practitioner = {},
    refreshList,
    jobs,
    countries,
    classes,
    setIsLoading,
    soloPractitioner,
    currentClinic,
    serviceCategories,
    isFromOrg,
    commissions,
    countryCode,
    allClinics
}) {
    const globalStyles = makeStyles(modalsButtonStyles)();

    const [clinics, setClinics] = useState([]);
    const [file, setFile] = useState(null);
    const [signatureFile, setSignatureFile] = useState(null);
    const [servicesFields, setServicesFields] = useState([]);
    const [canShowMore, setCanShowMore] = useState(true);
    const [categories, setCategories] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState();
    const [otherNumber, setOtherNumber] = useState();
    const [currentService, setCurrentService] = useState();
    const [pinHasChanged, setPinHasChanged] = useState(false);
    const [performTreatments, setPerformTreatments] = useState(false);
    const [isSoloPractitioner, setIsSoloPractitioner] = useState(false);
    const [clinicsSettings, setClinicsSettings] = useState({});
    const [deletedPhoto, setDeletedPhoto] = useState(false);
    const [deletedSignature, setDeletedSignature] = useState(false);

    const loadCategories = async (preloadedServicesFields, showAll = false) => {
        try {
            const limit = showAll
                ? 9999
                : categories.reduce((acc, clinic) => {
                      return acc > clinic.categories.length ? acc : clinic.categories.length;
                  }, 0);

            const query = `?skip=0&count=${showAll ? limit : limit + 15}&isFromOrg=${isFromOrg}&clinic=${
                currentClinic?.id
            }&active=true`;
            const response = await CategoryApi.clinics(query);

            const responseCategoryLength = response
                .map(clinic => {
                    return clinic.total;
                })
                .reduce((_, currentValue) => currentValue, 0);

            if (responseCategoryLength < limit) {
                setCanShowMore(false);
            }

            if (preloadedServicesFields) {
                const allIDs = response.map(clinic => {
                    return {
                        clinicId: clinic.id,
                        categoriesIds: clinic.categories.map(category => {
                            return category.id;
                        })
                    };
                });

                const allClinics = await listClinics();

                const responseCategories = await bluebird.map(allIDs, async (oneId, idsIndex) => {
                    const services = await ServiceApi.getServiceWithCategoriesID(oneId);

                    const UpdatedCategories = response[idsIndex].categories
                        .map((cat, index) => {
                            if (cat?.subCategories?.length) {
                                if (categories?.[idsIndex]) {
                                    cat.subCategories = categories?.[idsIndex]?.categories?.[index]?.subCategories;
                                }
                            }

                            const categorySaved = categories?.[idsIndex]?.categories.find(
                                el => el.id === cat.id && el.allSelected
                            );
                            if (categorySaved) {
                                return { ...cat, allSelected: true };
                            }
                            let preloadedCorresponding = [];
                            if (isFromOrg || allClinics.length === 1) {
                                preloadedCorresponding = [
                                    ...new Map(
                                        services
                                            .filter(el => {
                                                if (!el.archived) {
                                                    return (
                                                        el.category.id === cat.id &&
                                                        response[idsIndex].id === el?.locations?.clinic
                                                    );
                                                }
                                                return false;
                                            })
                                            .map(item => [item.id, item])
                                    ).values()
                                ];
                            } else {
                                preloadedCorresponding = [
                                    ...new Map(
                                        preloadedServicesFields
                                            .filter(el => {
                                                if (
                                                    !el.archived &&
                                                    !el.locations.find(loc => loc.clinic === currentClinic?.id)
                                                        ?.archived
                                                ) {
                                                    return (
                                                        el.category === cat.id &&
                                                        (isFromOrg || el.clinic === response[idsIndex].id)
                                                    );
                                                }
                                                return false;
                                            })
                                            .map(item => [item.id, item])
                                    ).values()
                                ];
                            }
                            if (preloadedCorresponding.length) {
                                const servicesCorresponding = [
                                    ...new Map(
                                        services
                                            .filter(el => {
                                                let isChecked = false;
                                                if (isFromOrg) {
                                                    isChecked = services
                                                        .find(service => service.id === el.id)
                                                        .locations.staffs.find(
                                                            staff => staff.staff === practitioner.id
                                                        );
                                                } else {
                                                    isChecked = practitioner.locations
                                                        .find(loc => loc.clinic.id === response[idsIndex].id)
                                                        .services.find(service => service.service.id === el.id);
                                                }
                                                return el.category.id === cat.id && isChecked;
                                            })
                                            .map(item => [item.id, item])
                                    ).values()
                                ];
                                if (
                                    servicesCorresponding.length === preloadedCorresponding.length &&
                                    !!servicesCorresponding.length
                                ) {
                                    return { ...cat, allSelected: true, indeterminate: false };
                                } else if (servicesCorresponding.length) {
                                    return { ...cat, indeterminate: true };
                                }
                            }

                            return cat;
                        })
                        .filter(el => el);
                    return { ...response[idsIndex], categories: UpdatedCategories };
                });
                setCategories(responseCategories);
            } else if (response) {
                if (response.size === categories.length + response.length || 0) setCanShowMore(false);
                else setCanShowMore(true);
                setCategories(response);
            }
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        }
    };

    useEffect(() => {
        const loadClinicsAndSoloPractitioner = async () => {
            try {
                const clinics = await listClinics();
                setClinics(clinics);

                if (!isFromOrg && currentClinic && practitioner && practitioner.locations) {
                    setIsSoloPractitioner(
                        !!practitioner?.locations.find(loc => loc.clinic.id === currentClinic.id).isSoloPractitioner
                    );
                } else if (isFromOrg && clinics.length === 1 && practitioner && practitioner.locations) {
                    setIsSoloPractitioner(
                        !!practitioner?.locations.find(loc => loc.clinic.id === clinics[0].id).isSoloPractitioner
                    );
                }
                if (!isFromOrg && currentClinic && practitioner && practitioner.locations) {
                    setPerformTreatments(
                        !!practitioner?.locations.find(loc => loc.clinic.id === currentClinic.id).performTreatments
                    );
                } else if (isFromOrg && clinics.length === 1 && practitioner && practitioner.locations) {
                    setPerformTreatments(
                        !!practitioner?.locations.find(loc => loc.clinic.id === clinics[0].id).performTreatments
                    );
                } else if (isFromOrg && clinics.length > 1 && practitioner && practitioner.locations) {
                    setPerformTreatments(!!practitioner?.locations.find(loc => loc.performTreatments));
                }

                if (practitioner && practitioner.locations) {
                    const newClinicsSettings = {};
                    for (let i = 0; i < practitioner.locations.length; i++) {
                        const cLoc = practitioner.locations[i];
                        newClinicsSettings[cLoc.clinic.id] = {
                            isSoloPractitioner: !!cLoc.isSoloPractitioner,
                            isPerformTreatments: !!cLoc.performTreatments
                        };
                    }
                    setClinicsSettings(newClinicsSettings);
                }
            } catch (err) {
                return toastr.error(err?.data?.message || 'Something went wrong');
            }
        };

        loadCategories();
        loadClinicsAndSoloPractitioner();
        /*eslint-disable-next-line */
    }, []);

    useEffect(() => {
        const loadDefaultServices = async () => {
            try {
                if (practitioner.locations) {
                    setIsLoading(true);
                    const allServiceFields = practitioner.locations.map(location => {
                        const services = location.services
                            .map(item => {
                                if (!isFromOrg && currentClinic.id !== location.clinic.id) {
                                    return undefined;
                                }
                                if (item.service)
                                    return {
                                        ...item.service,
                                        duration: convertDurationToString(item.duration),
                                        offered: true,
                                        commission: item.commission,
                                        //  commissionDeduction: item.commissionDeduction,
                                        clinic: location.clinic.id
                                    };
                                return undefined;
                            })
                            .filter(service => service);
                        return uniqBy([...servicesFields, ...services], 'id');
                    });
                    let allPractitionerServices = [];
                    // if (isFromOrg) {
                    allPractitionerServices = (
                        await ServiceApi.query(undefined, practitionerId, '', 'isFromOrg=true&count=99999&active=false')
                    ).items;
                    // }
                    const allServReduced = (allServiceFields || []).reduce(
                        (currentEl, nextEl) => [...nextEl, ...currentEl],
                        []
                    );
                    const allServReducedForCategory = [...allServReduced];
                    // eslint-disable-next-line array-callback-return
                    allPractitionerServices.map(item => {
                        // eslint-disable-next-line array-callback-return
                        item.locations.map(loc => {
                            const isStaffAssigned = loc?.staffs?.some(staff => staff.staff === practitionerId);
                            if (
                                isStaffAssigned &&
                                !allServReducedForCategory.find(srv => srv.id === item.id && srv.clinic === item.clinic)
                            ) {
                                allServReducedForCategory.push({
                                    ...item,
                                    offered: true,
                                    clinic: loc.clinic,
                                    category: item.category.id
                                });
                            }
                            if (
                                isStaffAssigned &&
                                isFromOrg &&
                                !allServReduced.find(srv => srv.id === item.id && srv.clinic === loc.clinic)
                            ) {
                                allServReduced.push({
                                    ...item,
                                    offered: true,
                                    clinic: loc.clinic,
                                    category: item.category.id
                                });
                            }
                        });
                    });
                    setServicesFields(allServReduced);
                    await loadCategories(allServReducedForCategory);
                }
            } finally {
                setIsLoading(false);
            }
        };

        loadDefaultServices();
        //eslint-disable-next-line
    }, [practitioner.locations]);

    const practitionerWithoutLocations = { ...practitioner };
    delete practitionerWithoutLocations.locations;

    const { register, handleSubmit, control, watch, setValue, getValues, errors } = useForm({
        defaultValues: {
            category: {},
            timing: {},
            pricingLocation: {},
            staff: {},
            clinics: [],
            services: servicesFields,
            ...practitionerWithoutLocations
        }
    });

    const selectedLocations = watch('locations');
    const selectedClinics = watch('clinics');
    const allOnlineBookings = watch('onlineBooking');

    useEffect(() => {
        setValue('clinics', selectedLocations?.filter(loc => loc.isSelected) || []);
        setValue('services', servicesFields);
        /*eslint-disable-next-line */
    }, [clinics, selectedLocations, servicesFields]);

    const UpdateCategoriesAllSelected = allSelected => [
        ...categories.map(clinic => {
            const cat = clinic.categories.map(category => {
                return { ...category, allSelected };
            });
            return { ...clinic, categories: cat };
        })
    ];

    const selectAllCategories = useCallback(
        async checked => {
            if (checked) {
                try {
                    setCategories(UpdateCategoriesAllSelected(true));
                } catch (err) {
                    return toastr.error(err?.data?.message || 'Something went wrong');
                }
            } else {
                setCategories(UpdateCategoriesAllSelected(false));
                setCanShowMore(true);
            }
        },
        [categories, setServicesFields] // eslint-disable-line
    );

    useEffect(() => {
        if (!performTreatments) {
            setServicesFields([
                ...servicesFields.map(service => {
                    return { ...service, offered: false };
                })
            ]);
            setCategories(UpdateCategoriesAllSelected(false));
        }
        // eslint-disable-next-line
    }, [performTreatments]);

    const changeService = value =>
        setServicesFields(
            servicesFields.map(element => {
                const valueClinic = value?.clinic?.id || value?.clinic;
                const elementClinic = element?.clinic?.id || element?.clinic;
                return value.id.toString() === element.id.toString() && valueClinic === elementClinic ? value : element;
            })
        );

    const appendService = async value => {
        setServicesFields([
            ...servicesFields,
            {
                ...value,
                offered: true,
                allowOnlineBooking: true,
                comission: { type: 'None', value: 0 },
                comissionDeduction: { type: 'None', value: 0 }
            }
        ]);

        // update categories checkboxes
        setCategories(
            await bluebird.map(categories, async clinic => {
                const valueClinicId = value?.clinic?.id || value?.clinic;

                if (valueClinicId === clinic?.id) {
                    const UpdatedClinicBody = await bluebird.map(clinic.categories || [], async category => {
                        if (category.id === value.category.id && category?.subCategories?.length && value.subCategory) {
                            const updatedSubCategories = await bluebird.map(category.subCategories, async subCat => {
                                if (subCat.id === value.subCategory) {
                                    return recountSubCategory(subCat.id, value.id, subCat);
                                }
                                return subCat;
                            });
                            return {
                                ...category,
                                ...recountCategory(
                                    value.category.id,
                                    category.id,
                                    true,
                                    clinic?.id,
                                    value.id,
                                    false,
                                    category,
                                    true
                                ),
                                subCategories: updatedSubCategories
                            };
                        } else if (category.id === value.category.id) {
                            return {
                                ...recountCategory(
                                    value.category.id,
                                    null,
                                    true,
                                    clinic?.id,
                                    value.id,
                                    false,
                                    undefined,
                                    false
                                )
                            };
                        }
                        return category;
                    });
                    clinic.categories = UpdatedClinicBody;
                }
                return clinic;
            })
        );
    };

    const removeService = async value => {
        setServicesFields(
            servicesFields.filter(svc => {
                const svcClinicId = svc?.clinic?.id || svc?.clinic;
                const valueClinicId = value?.clinic?.id || value?.clinic;
                if (svcClinicId === valueClinicId) {
                    if (svc.id.toString() === value.id.toString()) {
                        return false;
                    }
                }
                return true;
            })
        );

        setCategories(
            await bluebird.map(categories, async clinic => {
                const valueClinicId = value?.clinic?.id || value?.clinic;

                if (valueClinicId === clinic?.id) {
                    const UpdatedClinicBody = await bluebird.map(clinic.categories || [], async category => {
                        if (category.id === value.category.id && category?.subCategories?.length && value.subCategory) {
                            const updatedSubCategories = await bluebird.map(category.subCategories, async subCat => {
                                if (subCat.id === value.subCategory) {
                                    return recountSubCategory(subCat.id, value.id, subCat, true);
                                }
                                return subCat;
                            });
                            return {
                                ...category,
                                ...recountCategory(value.category.id, null, true, clinic?.id, value.id, true, category),
                                subCategories: updatedSubCategories
                            };
                        } else if (category.id === value.category.id) {
                            return {
                                ...recountCategory(value.category.id, null, true, clinic?.id, value.id, true)
                            };
                        }
                        return category;
                    });
                    clinic.categories = UpdatedClinicBody;
                }
                return clinic;
            })
        );
    };

    // used to select all  category services
    const appendMultipleServices = useCallback(
        (services, clinic) => {
            setServicesFields([
                ...servicesFields,
                ...services
                    .map(currentService => {
                        // don't add if it's already there
                        if (
                            servicesFields.find(current => {
                                return (
                                    current.id.toString() === currentService.id.toString() &&
                                    current?.clinic === clinic?.id
                                );
                            })
                        ) {
                            return undefined;
                        }
                        return {
                            clinic: clinic?.id || clinic,
                            ...currentService,
                            offered: true,
                            allowOnlineBooking: true,
                            comission: { type: 'None', value: 0 },
                            comissionDeduction: { type: 'None', value: 0 }
                        };
                    })
                    .filter(currentService => currentService)
            ]);
        },
        [servicesFields]
    );

    // used to deselect all  category services
    const removeMultipleServices = useCallback(
        (ids, clinic) => {
            const newServices = servicesFields.filter(service => {
                return !ids.some(id => {
                    const clinicId = service.clinic?.id || service.clinic;

                    return service.id === id && clinicId === clinic.id;
                });
            });
            setServicesFields(newServices);
        },
        [servicesFields]
    );

    /**
     * recount category when changing a subcategory checkbox or adding/removing service
     * @param {string} categoryId - Id of category  being recounted
     * @param {boolean} subCategoryId - Id of subcategory being changed (used when clicked on subcategory  checkbox).
     * @param {boolean} checked - Boolean that indicates if the checkbox was checked or not.
     * @param {string} clinicId - Id of clinic that the category belongs
     * @param {string} serviceId - Optional, indicates the service being removed/added (append/remove functions)
     * @param {boolean} removingService - Optional, indicates if the service is being removed or added
     * @param {object} alternativeCategory - Optional, a alternative to the category, for cases that the category value was already loaded
     * @param {boolean} isSub - Optional, in case that category being recounted is a subCategory
     * @returns {object} Updated category
     */
    const recountCategory = useCallback(
        (
            categoryId,
            subCategoryId,
            checked,
            clinicId,
            serviceId = null,
            removingService = false,
            alternativeCategory = undefined,
            isSub
        ) => {
            const category =
                categories
                    .map(cat => {
                        if (cat?.id === clinicId) {
                            const currentCat = cat.categories.find(category => category.id === categoryId);
                            if (currentCat?.subCategories?.length) {
                                const filtredCat = currentCat?.subCategories
                                    .map(subCat => {
                                        if (subCat?.id) {
                                            return subCat;
                                        }
                                        return false;
                                    })
                                    .filter(el => el);

                                return { ...currentCat, subCategories: filtredCat };
                            }

                            return currentCat;
                        }
                        return false;
                    })
                    .filter(el => el)[0] || alternativeCategory;
            category.indeterminate = false;
            if (category?.allSelected && !checked) {
                return { ...category, allSelected: false };
            } else if (checked) {
                const onList = compareServ => {
                    if (compareServ.id === serviceId) {
                        return removingService ? false : true;
                    }
                    return getValues()
                        .services.filter(serv => {
                            return serv.category.id !== compareServ.category.id;
                        })
                        .find(serv => {
                            const servClinicId = serv?.clinic?.id || serv?.clinic;
                            const compareServClinicId = compareServ?.clinic?.id || compareServ?.clinic;

                            return serv.id === compareServ.id && servClinicId === compareServClinicId;
                        });
                };

                const currentCategoryServices = currentService.items.filter(service => {
                    if (!(isFromOrg && clinics.length === 1)) {
                        if (service?.category?.id === categoryId) {
                            return service;
                        } else {
                            return false;
                        }
                    }
                    if (
                        !isFromOrg &&
                        !service?.locations
                            .find(loc => loc.clinic.toString() === clinicId)
                            ?.staffs.find(staff => staff.staff.toString() === practitionerId)
                    ) {
                        return false;
                    }
                    if (isSub) {
                        if (
                            service?.category?.id === subCategoryId ||
                            service?.category?.parentCategory === subCategoryId
                        ) {
                            return service;
                        }
                    } else {
                        if (service.category.id === categoryId) return service;
                    }
                    return false;
                });
                const numberOfDisplayedServices = currentCategoryServices.length;
                const numberOfCheckedServices = getValues().services.filter(
                    serv =>
                        (serv.category.id === categoryId || serv.category === categoryId) &&
                        (serv.clinic.id === clinicId || serv.clinic === clinicId)
                ).length;

                if (numberOfDisplayedServices > numberOfCheckedServices) {
                    return {
                        ...category,
                        allSelected: false,
                        indeterminate: numberOfCheckedServices > 0
                    };
                }

                if (!category?.subCategories || !category?.subCategories.length) {
                    return {
                        ...category,
                        allSelected: numberOfDisplayedServices <= numberOfCheckedServices,
                        indeterminate:
                            numberOfDisplayedServices > numberOfCheckedServices && numberOfCheckedServices > 0
                    };
                }

                const subServices =
                    category?.subCategories &&
                    category?.subCategories.length &&
                    category?.subCategories
                        ?.map(subCat => {
                            if (subCat.id === subCategoryId) return removingService ? false : true;
                            return currentService.items
                                .filter(service => {
                                    if (service.category.id === subCat.id) {
                                        return service;
                                    }
                                    return false;
                                })
                                .every(service => onList(service.id));
                        })
                        .every(el => el);

                return {
                    ...category,
                    allSelected: subServices
                };
            } else {
                return category;
            }
        },
        //eslint-disable-next-line
        [categories, servicesFields]
    );

    // recount subcategory when adding or removing service, works as the "recaountCategory"
    const recountSubCategory = useCallback(
        async (subCategoryId, serviceId = null, subCategory, removingService = false) => {
            // get subCategories array to update the category and the subCategory to update it
            const onList = svcId => {
                if (svcId === serviceId) {
                    return removingService ? false : true;
                }
                return servicesFields.find(serv => serv.id === svcId);
            };
            const allServicesOnList = currentService.items
                .filter(service => {
                    if (service?.subCategory === subCategoryId) return service;
                    return false;
                })
                .every(service => onList(service.id));

            return { ...subCategory, allSelected: allServicesOnList };
        },
        /*eslint-disable-next-line */
        [categories, servicesFields]
    );

    // updates the "select all" checkbox of one category or subcategory
    const updateCategory = useCallback(
        (categoryId, clinicId, checked, parentCategory) => {
            try {
                setCategories(
                    categories.map(clinic => {
                        if (clinicId === clinic.id) {
                            const newCat = clinic?.categories.map(category => {
                                if (category.id === categoryId) {
                                    if (category.subCategories && category.subCategories.length > 0) {
                                        return {
                                            ...category,
                                            allSelected: checked,
                                            subCategories: category.subCategories.map(sub => {
                                                if (sub.id) {
                                                    return {
                                                        ...sub,
                                                        allSelected: checked
                                                    };
                                                }
                                                return {
                                                    id: sub,
                                                    allSelected: checked
                                                };
                                            })
                                        };
                                    }
                                    // if it doesn't have sub categories
                                    return { ...category, allSelected: checked, indeterminate: false };
                                } else if (
                                    category.subCategories &&
                                    category.subCategories.length > 0 &&
                                    category.id === parentCategory
                                ) {
                                    const subCategories = category.subCategories.map(sub => {
                                        if (sub.id === categoryId) {
                                            return {
                                                ...sub,
                                                allSelected: checked
                                            };
                                        }
                                        return {
                                            ...sub
                                        };
                                    });
                                    if (checked === false) {
                                        return {
                                            ...category,
                                            allSelected: checked,
                                            subCategories
                                        };
                                    }
                                    const allServicesOnList = (currentService?.items || [])
                                        .filter(service => {
                                            if (
                                                service?.clinic === clinicId &&
                                                service?.category?.id === category.id &&
                                                service?.subCategory !== categoryId
                                            ) {
                                                return service;
                                            }
                                            return false;
                                        })
                                        .every(service => {
                                            return servicesFields.find(
                                                serv => serv.id === service.id && serv.clinic === service.clinic
                                            );
                                        });

                                    return {
                                        ...category,
                                        allSelected: allServicesOnList,
                                        subCategories
                                    };
                                }
                                return category;
                            });
                            return { ...clinic, categories: newCat };
                        }
                        return clinic;
                    })
                );
            } catch (err) {
                return toastr.error(err?.data?.message || 'Something went wrong');
            }
        },
        // eslint-disable-next-line
        [categories]
    );

    useEffect(() => {
        const requiredFields = Object.keys(errors);

        if (requiredFields.length !== 0) {
            const messages = requiredFields.map(field => errors[field].message);

            toastr.error('Missing fields', messages.join(','));
        }
    }, [errors]);

    const convertDurationToString = duration => {
        if (duration === undefined) return;
        let hours = duration / 60;
        let remainingHours = Math.floor(hours);
        let minutes = parseInt(duration - remainingHours * 60);
        if (String(minutes).length === 1) {
            minutes = `0${minutes}`;
        }
        if (String(remainingHours).length === 1) {
            remainingHours = `0${remainingHours}`;
        }
        return `${remainingHours}:${minutes}`;
    };

    const convertTimeToMinutes = value => {
        if (typeof value === 'number') {
            value = convertDurationToString(value);
            if (!value) return undefined;
        }
        const split = value.split(':');
        return parseInt(split[0]) * 60 + parseInt(split[1]);
    };

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const practitionerId = urlParams.get('practitionerId');
    if (practitionerId !== 'new' && !practitioner.id) {
        onClose();
    }

    // const getClinic = clinic => {
    //     const clinicObj = clinics.find(cl => cl.accountName === clinic);
    //     return clinicObj?.id;
    // };

    const formRef = useRef();

    const save = async value => {
        setIsLoading(true);
        value.performTreatments = true;
        if (phoneNumber) {
            value.mobilePhone = phoneNumber;
        }
        if (otherNumber) {
            value.otherPhone = { phone: otherNumber };
        }
        if ((practitioner || {}).id) {
            if (!value.password) {
                delete value.password;
            }
        }
        if (value.loginPin && value.loginPin.length !== 5) {
            toastr.warning('Invalid PIN', 'PIN must have 5 digits');
            setIsLoading(false);
            return;
        }
        if (!value.loginPin || !pinHasChanged) {
            delete value.loginPin;
        }

        if (!isFromOrg && currentClinic) {
            value.locations = [
                {
                    value: currentClinic.id,
                    label: currentClinic.accountName
                }
            ];
        } else {
            value.locations = selectedClinics;

            value.onlineBooking = allOnlineBookings?.filter(loc => loc.isSelected) || [];
        }

        value.locations = value.clinics.map((clinic, index) => {
            return {
                allowOnlineBooking: value.onlineBooking.find(item => item.value === clinic.value)?.isSelected || false,
                clinic: clinic.value,
                ...(value.locations && {
                    ...(value.locations[index].services && {
                        services: value.locations[index].services
                            .filter(service => service.offered)
                            .map(service => {
                                delete service.offered;
                                return {
                                    ...service,
                                    ...(service.duration && {
                                        duration: convertTimeToMinutes(service.duration)
                                    }),
                                    ...(service.commission?.value && {
                                        commission: {
                                            ...service.commission,
                                            value: parseInt(service.commission.value)
                                        }
                                    }) /*,
                                    ...(service.commissionDeduction && {
                                        commissionDeduction: parseInt(service.commissionDeduction)
                                    })*/
                                };
                            })
                    })
                })
            };
        });

        value.locations = value.locations.map(location => {
            let locationId;
            if (typeof location.clinic === 'string') locationId = location.clinic;
            else locationId = location.clinic.id;
            const locationClinicId = location?.clinic?.id || location?.clinic;

            const servicesFromClinic = servicesFields.filter(service => {
                if (!service) return false;
                const clinicId = service?.clinic?.id || service?.clinic || locationId;
                return clinicId.toString() === locationClinicId.toString();
            });

            const currentCatIndex = categories.findIndex(el => el.id === locationClinicId);
            const allSelectedCategories = categories?.[currentCatIndex]?.categories.filter(cat => cat?.allSelected);
            let servMap = undefined;
            if (servicesFromClinic)
                servMap = servicesFromClinic.map(service => {
                    const toReturn = {
                        service: service.id,
                        duration: service.duration,
                        commission: service.commission,
                        category: service.category.id || service.category
                        //   commissionDeduction: service.commissionDeduction
                    };
                    toReturn.allSelected = !!allSelectedCategories?.find(el => service?.category === el.id);
                    if (value.allowOnlineBooking) {
                        toReturn.allowOnlineBooking = value.allowOnlineBooking.find(
                            item => item.value === locationId
                        )?.isSelected;
                    } else {
                        toReturn.allowOnlineBooking = false;
                    }
                    return toReturn;
                });
            return {
                ...location,
                clinic: locationId,
                services: servMap
            };
        });

        delete value.onlineBooking;
        if (currentClinic && !isFromOrg) {
            value.clinic = currentClinic.id;
        }

        if (!value.hideOtherColumns) value.hideOtherColumns = false;

        if (value.nextOfKin === '') {
            delete value.nextOfKin;
            delete value.relationship;
        }

        if (value.lateCancellationCommission && value.lateCancellationCommission.value) {
            value.lateCancellationCommission.value = parseFloat(value.lateCancellationCommission.value);
        }

        if (value.missedAppointmentCommission && value.missedAppointmentCommission.value) {
            value.missedAppointmentCommission.value = parseFloat(value.missedAppointmentCommission.value);
        }

        if (value.hoursPerWeek === '') value.hoursPerWeek = 0;
        if (value.overtimePerHour === '') value.overtimePerHour = 0;

        if (value.hourlyRate) {
            value.hourlyRate = parseFloat(value.hourlyRate);
        } else {
            value.hourlyRate = 0;
        }

        if (value.salary === null) {
            value.salary = 0;
        }
        if (value.salary) {
            value.salary = parseFloat(value.salary);
        } else {
            value.salary = 0;
        }
        if (value.paidAnnualLeave === '') value.paidAnnualLeave = 0;
        if (value.paidSpecialLeave === '') value.paidSpecialLeave = 0;

        /*eslint-disable-next-line */
        value.country = countries.find(country => {
            if (country.value === value.country) return country;
            return undefined;
        });
        value.jobTags = value.jobTags.map(element => element.id);
        if (typeof value.job !== 'string' && value.job) value.job = value.job.id;
        delete value.clinics;
        delete value.services;

        if (!value.job) value.job = undefined;
        if (!value.title) value.title = Constants.TITLES.CUSTOM;
        if (!value.gender) value.gender = Constants.GENDERS.CUSTOM;
        if (!value.professionalBody) value.professionalBody = Constants.PROFESSIONAL_BODIES.NONE;
        if (!value.locations.length && isFromOrg) {
            setIsLoading(false);
            toastr.error('Invalid Location', 'Please select a location');
            return;
        }

        const invalidCommission = !isValidMongoIdString(value.commission) && value.commissions;

        if (invalidCommission) {
            toastr.error('Invalid Commission', 'Please select a commission');
            setIsLoading(false);
            return;
        }

        if (practitionerId === 'new') {
            value.locations = value.locations.map(loc => {
                loc.allowOnlineBooking = value.allowOnlineBooking !== undefined ? value.allowOnlineBooking : false;
                loc.job = value.job;
                loc.commission = value.commission;
                loc.active = value.active !== undefined ? value.active : true;
                loc.performTreatments = value.performTreatments;
                return loc;
            });

            PractitionerApi.create({ ...value, hourlyRate: value.hourlyRate !== null ? value.hourlyRate : 0 })
                .then(async result => {
                    if (file) {
                        await PractitionerApi.uploadPractitionerAvatar(result.id, file);
                    }
                    if (signatureFile) {
                        await PractitionerApi.uploadPractitionerSignature(result.id, signatureFile);
                    }
                    setIsLoading(false);
                    onClose();
                    toastr.success('Practitioner successfully created!');
                    refreshList();
                })
                .catch(err => {
                    setIsLoading(false);
                    if (typeof err === 'object') {
                        if (err.data && err.data.message) {
                            toastr.error(err.data.message);
                            return;
                        }
                    }
                    toastr.error(err?.data?.message || 'Something went wrong');
                });
        } else {
            if (!isFromOrg) {
                value.allowOnlineBooking = practitioner.allowOnlineBooking;
                value.job = practitioner.job;
                value.active = practitioner.active;
                value.locations = value.locations.map(loc => {
                    if (loc.clinic === currentClinic.id) {
                        Object.assign(loc, {
                            job: value.job,
                            // commission: value.commission,
                            active: value.active,
                            allowCommission: value.allowCommission
                        });
                    }
                    return loc;
                });
            }

            const newLocationsClinic = value.locations.map(loc => loc.clinic);

            value.locations = value.locations
                .map(loc => {
                    if (newLocationsClinic.includes(typeof loc.clinic === 'object' ? loc.clinic.id : loc.clinic)) {
                        const oldLoc = value.locations.find(_loc => _loc.clinic === loc.clinic) || {};

                        oldLoc.clinic = typeof oldLoc.clinic === 'object' ? oldLoc?.clinic?.id : oldLoc?.clinic;

                        return {
                            ...loc,
                            ...oldLoc
                        };
                    } else {
                        return false;
                    }
                })
                .filter(el => el);

            value.locations = value.locations.map(loc => {
                loc.clinic = typeof loc.clinic === 'object' ? loc.clinic.id : loc.clinic;
                return loc;
            });
            delete value.commissions;
            delete value.allowOnlineBooking;
            if (isFromOrg) {
                PractitionerApi.update(
                    practitionerId,
                    {
                        ...value,
                        hourlyRate: value.hourlyRate !== null ? value.hourlyRate : 0
                    },
                    true
                )
                    .then(async () => {
                        if (file) {
                            await PractitionerApi.uploadPractitionerAvatar(practitionerId, file);
                        } else if (deletedPhoto) {
                            await PractitionerApi.deletePractitionerAvatar(practitionerId);
                        }
                        if (signatureFile) {
                            await PractitionerApi.uploadPractitionerSignature(practitionerId, signatureFile);
                        } else if (deletedSignature) {
                            await PractitionerApi.deletePractitionerSignature(practitionerId);
                        }
                        setIsLoading(false);
                        onClose();
                        toastr.success('Practitioner successfully updated!');
                        refreshList();
                    })
                    .catch(err => {
                        setIsLoading(false);
                        if (typeof err === 'object') {
                            if (err.data && err.data.message) {
                                toastr.error(err.data.message);
                                return;
                            }
                        }
                        toastr.error(err?.data?.message || 'Something went wrong');
                    });
            } else {
                PractitionerApi.update(
                    practitionerId,
                    {
                        ...value,
                        hourlyRate: value.hourlyRate !== null ? value.hourlyRate : 0
                    },
                    false,
                    currentClinic.id
                )
                    .then(async () => {
                        if (file) {
                            await PractitionerApi.uploadPractitionerAvatar(practitionerId, file);
                        } else if (deletedPhoto) {
                            await PractitionerApi.deletePractitionerAvatar(practitionerId);
                        }
                        if (signatureFile) {
                            await PractitionerApi.uploadPractitionerSignature(practitionerId, signatureFile);
                        } else if (deletedSignature) {
                            await PractitionerApi.deletePractitionerSignature(practitionerId);
                        }
                        setIsLoading(false);
                        onClose();
                        toastr.success('Practitioner successfully updated!');
                        refreshList();
                    })
                    .catch(err => {
                        setIsLoading(false);
                        if (typeof err === 'object') {
                            if (err.data && err.data.message) {
                                toastr.error(err.data.message);
                                return;
                            }
                        }
                        toastr.error(err?.data?.message || 'Something went wrong');
                    });
            }
        }
    };
    const tabProps = {
        categories,
        setCat: setCategories,
        control,
        register,
        watch,
        setValue,
        getValues,
        phoneNumber,
        setPhoneNumber,
        otherNumber,
        setOtherNumber,
        performTreatments,
        setPerformTreatments,
        soloPractitioner,
        isSoloPractitioner,
        selectAllCategories,
        setIsSoloPractitioner,
        practitioner,
        countries,
        selectedItem: practitioner,
        isFromOrg,
        clinic: currentClinic,
        clinics,
        servicesFields,
        setServicesFields,
        changeService,
        appendService,
        removeService,
        commissions,
        setFile,
        setSignatureFile,
        deletedPhoto,
        currentService,
        setCurrentService,
        setDeletedPhoto,
        setDeletedSignature,
        pinHasChanged,
        setPinHasChanged,
        clinicsSettings,
        setClinicsSettings,
        countryCode,
        allClinics
    };
    return (
        <>
            <Modal
                isOpen={visible}
                title={
                    practitionerId !== 'new' ? `View/Edit ${practitioner.displayName} Practitioner` : 'New Practitioner'
                }
                id="practitioner"
                onClose={() => onClose()}
                onCancel={() => onClose()}
            >
                <form
                    ref={formRef}
                    onSubmit={e => {
                        e.preventDefault();
                        handleSubmit(save)();
                    }}
                    className={classes.accordionsForm}
                >
                    <Accordion title="Personal">
                        <PersonalTab {...tabProps} />
                    </Accordion>
                    <Accordion title="Professional">
                        <ProfessionalTab
                            {...tabProps}
                            clinicsOpt={currentClinic ? [currentClinic] : clinics}
                            jobOpts={jobs}
                        />
                    </Accordion>

                    <Accordion title={'Locations'} hideItem={!isFromOrg}>
                        <LocationTab
                            {...tabProps}
                            clinics={isFromOrg ? clinics : [currentClinic]}
                            clinic={currentClinic}
                            isFrom={'staff'}
                        />
                    </Accordion>

                    <Accordion title="Service Rules">
                        <ServiceRulesTab
                            clinics={selectedClinics}
                            {...tabProps}
                            updateCategory={updateCategory}
                            appendMultipleServices={appendMultipleServices}
                            removeMultipleServices={removeMultipleServices}
                            practitionerId={practitioner.id}
                            showMoreCategories={loadCategories}
                            canShowMore={canShowMore}
                            clinicOptions={currentClinic ? [currentClinic] : clinics}
                        />
                    </Accordion>
                    <Accordion title="Commission">
                        <CommissionTab {...tabProps} serviceCategories={serviceCategories} isFromOrg={isFromOrg} />
                    </Accordion>
                    <Accordion title="Security" hideItem={!isFromOrg}>
                        <SecurityTab {...tabProps} />
                    </Accordion>
                    <Accordion title="Online Booking">
                        <OnlineBookingTab clinics={selectedClinics} {...tabProps} />
                    </Accordion>
                    <div className={globalStyles.buttonsContainer}>
                        <Button className={globalStyles.cancelButton} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button className={globalStyles.confirmButton} type="submit" variant="outlined">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>
        </>
    );
});
