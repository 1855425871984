import React, { useEffect, useState } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Header from '../header/Header';

import { getClinic } from '../../collums-components/api/ClinicApi';
import { locationStyles } from './styles';
import locationTabs from '../header/tabsReferences/locationTabs';
import EquipmentView from '../equipment/equipmentView';
import RoomsView from '../rooms/roomsView';
import HoursView from '../hoursView/HoursView';
import SubHeaderPlaceholder from '../common/SubHeaderPlaceholder';
import PractitionersView from '../practitioners/PractitionersView';
import TemporaryDiscountsView from '../temporaryDiscounts/TemporaryDiscountsView';
import AlertsView from '../alerts/AlertsView';

const PlaceholderView = currentTab => (
    <div>
        <h3>Location {currentTab}</h3>
    </div>
);

const LocationView = ({ classes, ...props }) => {
    const id = props.match.params.id;
    const currentTab = props.match.params.tab || id;
    const [clinic, setClinic] = useState({});
    const isFromOrg = clinic === 'organisation';
    const loadClinic = async () => {
        getClinic(id).then(setClinic);
    };

    useEffect(() => {
        if (!locationTabs.some(tab => tab.group.includes(id))) {
            loadClinic();
        }
        /* eslint-disable-next-line */
    }, [id]);

    /* seeks tabName, as the reference(currentTab) sometimes isn't equal */
    const tabName = () => {
        const tab = locationTabs.filter(tab => {
            return tab.ref.split('/')[2] === currentTab;
        });
        if (tab[0]?.customName) {
            return tab[0].customName;
        }
        return tab[0]?.name;
    };

    function renderComponent() {
        if (!Object.keys(clinic).length) {
            return null;
        }
        switch (currentTab) {
            case 'equipment':
                return <EquipmentView isFromOrg={isFromOrg} clinic={clinic} />;
            case 'hours':
                return <HoursView clinic={clinic} reloadClinic={loadClinic} />;
            case 'rooms':
                return <RoomsView isFromOrg={isFromOrg} clinic={clinic} />;
            case 'staff':
                return <PractitionersView clinic={clinic} />;
            case 'card-terminals':
                return PlaceholderView(currentTab);
            case 'discounts':
                return <TemporaryDiscountsView clinic={clinic} />;
            case 'alerts':
                return <AlertsView clinic={clinic} />;
            default:
                // should not get here. TODO: wrong route component
                return PlaceholderView(currentTab);
        }
    }
    return (
        <>
            <Header />
            <SubHeaderPlaceholder />
            <div style={{ marginTop: 10, marginLeft: 10 }}>
                <Link className={classes.link} to="/">
                    Admin home
                </Link>
            </div>
            <Typography variant="h4" className={classes.title}>
                {currentTab === id ? tabName() : `${clinic.accountName || ''} - ${tabName()}`}
            </Typography>
            {renderComponent()}
        </>
    );
};

export default withStyles(locationStyles)(LocationView);
