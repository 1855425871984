export const listPaginationStyles = () => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 20,
        alignItems: 'center',
        minHeight: 48,
        flexWrap: 'wrap',
        '& .MuiCheckbox-root:nth-of-type(1)': {
            paddingLeft: 0
        }
    },
    searchContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 4,
        '& input': {
            height: 'inherit',
            boxSizing: 'border-box'
        }
    },
    tableContainer: {
        '& tr, th': {
            minWidth: 101,
            paddingRight: 0
        }
    },
    inputFilters: {
        display: 'flex',
        flexWrap: 'wrap',
        rowGap: '12px'
    },
    filtersContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '12px',
        '& > div': {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            '& span': {
                color: 'rgba(0, 0, 0, 0.87)'
            },
            '& label': {
                fontFamily: 'Roboto, Arial, Sans-serif',
                marginRight: 10
            },
            '& :last-child': {
                marginRight: 0
            }
        }
    },
    paginationContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    cellRightAlign: {
        textAlign: 'right'
    }
});

export const actionsStyles = () => ({
    autocomplete: {
        width: 200,
        marginRight: 20,
        minHeight: 38,
        '& .MuiInputBase-root': {
            height: 'auto !important',
            padding: '2px 8px',
            paddingRight: '45px !important'
        }
    },
    autocompleteChipProps: {
        maxWidth: '72%',
        margin: 1,
        padding: 0,
        fontSize: 11,
        '& .MuiOutlinedInput-root': {
            height: 'auto !important'
        }
    }
});
