import React, { useRef, useState } from 'react';
import { Button, Grid, TextField, Typography, withStyles, makeStyles } from '@material-ui/core';
import { alertFormStyle } from '../style';
import EmailEditor from './components/EmailEditor';
import { toastr } from 'react-redux-toastr';

import { modalsButtonStyles } from '../../../collums-constants/styles/stylesheets/buttonsStyles';
import AlertsApi from '../../../collums-components/api/AlertsApi';

const EmailTab = ({ classes, alert, closeAccordion, setHaveEmailUnsavedChanges }) => {
    const globalStyles = makeStyles(modalsButtonStyles)();

    const editor = useRef();
    const subjectRef = useRef();

    const [loadingUpload, setLoadingUpload] = useState({ visible: false, status: 'loading' });
    const [message, setMessage] = useState(alert?.email?.content || '');
    const [emailList, setEmailList] = useState(alert?.email?.recipients || '');
    const [subject, setSubject] = useState(alert?.email?.subject || '');
    const [smsUsage, setSMSUsage] = useState(alert?.email?.smsUsage || '');

    const saveData = async () => {
        if (!subject) {
            toastr.error('Missing subject');
            return;
        }
        if (!smsUsage || smsUsage === '0') {
            toastr.error('Invalid SMS usage');
            return;
        }
        if (!emailList && emailList !== '') {
            toastr.error('Missing recipient(s)');
            return;
        }
        try {
            const data = {
                name: alert.name,
                email: {
                    content: message,
                    recipients: emailList,
                    subject: subject,
                    smsUsage: smsUsage.toString()
                },
                sentAlert: alert.sentAlert,
                description: alert.description,
                sms: alert.sms,
                clinic: alert.clinic
            };
            await AlertsApi.updateAlert(alert.id, 'email', data);
            toastr.success('Alert email successfully updated!');
            setHaveEmailUnsavedChanges(false);
            closeAccordion();
        } catch (err) {
            if (typeof err === 'object') {
                if (err.data && err.data.message) {
                    toastr.error(err.data.message);
                    return;
                }
            }
            toastr.error('Something went wrong');
        }
    };

    return (
        <div style={{ display: 'block', width: '100%' }}>
            <Grid container xs={12} alignItems={'space-between'}>
                <Grid container xs={6} spacing={2} style={{ marginBottom: 20 }}>
                    <Grid container item xs={12} alignItems="center">
                        <Grid item className={classes.defaultLabelWidth}>
                            <Typography>Format:</Typography>
                        </Grid>
                        <Grid item className={classes.defaultLabelWidth}>
                            <Typography>Email</Typography>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} alignItems="center">
                        <Grid item className={classes.defaultLabelWidth}>
                            <Typography>Subject:</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                label="subject"
                                ref={subjectRef}
                                value={subject}
                                onChange={evt => {
                                    setSubject(evt.target.value);
                                    setHaveEmailUnsavedChanges(true);
                                }}
                                variant="outlined"
                                style={{ width: 300, height: 30 }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <EmailEditor
                setLoadingUpload={setLoadingUpload}
                loadingUpload={loadingUpload}
                editor={editor}
                message={message}
                defaultValue={alert ? alert.email.content : ''}
                setMessage={setMessage}
                setUnsavedChanges={() => setHaveEmailUnsavedChanges(true)}
            />
            <div className={classes.flexContainer}>
                <Typography>Send when SMS usage =</Typography>
                <TextField
                    style={{ marginLeft: 5, marginRight: 5, width: 75 }}
                    label=""
                    placeholder=""
                    variant="outlined"
                    value={smsUsage}
                    onChange={e => {
                        if (isNaN(e.target.value || 0)) {
                            return;
                        }
                        if (!e.target.value) {
                            setSMSUsage('');
                            return;
                        }

                        setSMSUsage(Number.parseInt(e.target.value).toString());
                    }}
                />
                <Typography>credits.</Typography>
            </div>
            <div className={classes.flexContainer}>
                <Typography>Email recipient(s):</Typography>
                <TextField
                    style={{ marginLeft: 5, width: 300 }}
                    label="Email address"
                    placeholder="Email address, separate with comma"
                    variant="outlined"
                    value={emailList}
                    className="usePlaceholderTextField"
                    onChange={e => {
                        setEmailList(e.target.value);
                    }}
                />
            </div>
            <div className={globalStyles.buttonsContainer}>
                <Button
                    className={globalStyles.cancelButton}
                    onClick={() => {
                        closeAccordion();
                        setHaveEmailUnsavedChanges(false);
                    }}
                >
                    Cancel
                </Button>
                <Button className={globalStyles.confirmButton} onClick={saveData}>
                    Save
                </Button>
            </div>
        </div>
    );
};

export default withStyles(alertFormStyle)(EmailTab);
