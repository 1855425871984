import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
    withStyles,
    makeStyles,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Typography,
    FormControlLabel,
    Checkbox,
    Grid
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useForm, useFieldArray } from 'react-hook-form';
import { toastr } from 'react-redux-toastr';

import OrganisationsApi from '../../api/OrganisationsApi';

import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import { settingsStyles } from './styles';

import { COLLUMS_DOMAIN, COOKIE_KEYS, DEFAULT_PAYMENT_TYPES, DEFAULT_UNIT_VALUES } from '../../collums-constants/index';
import { defaultTC, defaultThankYouHtml } from '../../constants/CustomerAppBaseHtml';

import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import ConfirmModal from '../common/ConfirmModal';
import OpenedModal from '../common/OpenedModal';

import GeneralTab from './tabs/GeneralTab';
import PaymentTypesTab from './tabs/PaymentTypesTab';
import CalendarTab from './tabs/CalendarTab';
import CustomerAppTab, { DEPOSIT_TYPES } from './tabs/CustomerAppTab';
import TreatmentRecordsTab from './tabs/TreatmentRecordsTab';
import ProductStockTab from './tabs/ProductStockTab';

import PaymentTypeModal from './PaymentTypeModal';
import ProductApi from './../../collums-components/api/ProductApi';
import { v4 } from 'uuid';
import TaxRatesTab from './tabs/TaxRatesTab';
import CommissionsTab from './tabs/CommissionsTab';
import { useDispatch } from 'react-redux';
import { loadOrganisation } from '../../actions/organisationActions';
import ReportsSettings from './tabs/ReportsSettings';
import SecurityTab from './tabs/SecurityTab';
import { useCookies } from 'react-cookie';
import { TOKEN } from '../../collums-constants/storageKeys';
import AuthApi from '../../api/AuthApi';
import { getHasAdvancedPermissions } from '../../collums-components/helpers/user';

function Settings({ classes }) {
    const [expanded, setExpanded] = useState('');
    const [organisation, setOrganisation] = useState({});
    const globalStyles = makeStyles(modalsButtonStyles)();
    const [isLoading, setIsLoading] = useState(true);
    const [file, setFile] = useState(null);
    // used for comunication with the modal
    const [update, setUpdate] = useState(false);
    const [isPaymentTypeModalVisible, setIsPaymentTypeModalVisible] = useState(false);
    const [selectedType, setSelectedType] = useState();

    // const [rateFields, setRateFields] = useState({});

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [toxinProducts, setToxinProducts] = useState([]);
    const [treatmentRecords, setTreatmentRecords] = useState();
    const [bookIntervals, setBookIntervals] = useState(5);
    const [, setCookies] = useCookies([TOKEN]);

    const dispatch = useDispatch();

    // refs for the customer app texts
    const homeBannerRef = useRef();
    const termsAndConditionsRef = useRef();
    const lateCancellationRef = useRef();
    const thankYouRef = useRef();
    const treatmentRecordsRef = useRef();

    const [hasAdvancedPermissions, setHasAdvancedPermissions] = useState(false);

    const { register, handleSubmit, setValue, errors, control, reset, watch } = useForm();

    const { fields, append } = useFieldArray({
        control,
        name: 'paymentTypes'
    });

    const getNewTreatmentRecord = useCallback(() => {
        return {
            id: v4(),
            doseUnits: [...DEFAULT_UNIT_VALUES]
        };
    }, []);

    useEffect(() => {
        AuthApi.getMe().then(res => {
            setHasAdvancedPermissions(getHasAdvancedPermissions(res));
        });
    }, []);

    const changeAllowSkippingFormsInJourney = useCallback(
        e => {
            setOrganisation({
                ...organisation,
                allowSkippingFormsInJourney: e.target.checked
            });
        },
        [organisation, setOrganisation]
    );

    const resetTreatmentRecordsData = useCallback(
        res => {
            ProductApi.getByTagType('toxin').then(toxins => {
                treatmentRecordsRef.current = (res.injectionDoses || [])
                    .map(injectionDose => {
                        const toxin = toxins.find(toxin => toxin.id === injectionDose.toxin);
                        return {
                            ...injectionDose,
                            toxin
                        };
                    })
                    .filter(injectionDose => injectionDose.toxin);

                if (!treatmentRecordsRef.current.length) {
                    treatmentRecordsRef.current = [getNewTreatmentRecord()];
                }

                setTreatmentRecords(treatmentRecordsRef.current);

                setToxinProducts(toxins);
            });
        },
        [setToxinProducts, setTreatmentRecords, treatmentRecordsRef, getNewTreatmentRecord]
    );

    const addNewTreatmentRecord = () => setTreatmentRecords([...treatmentRecordsRef.current, getNewTreatmentRecord()]);

    const loadData = useCallback(
        fromSave => {
            setIsLoading(true);
            OrganisationsApi.getOrganisation()
                .then(res => {
                    if (fromSave) {
                        return;
                    }
                    resetTreatmentRecordsData(res);
                    setOrganisation({
                        ...res,
                        ...(res.termsAndConditionsText ? {} : { termsAndConditionsText: defaultTC }),
                        ...(res.thankYouText ? {} : { thankYouText: defaultThankYouHtml }),
                        ...(!res.paymentTypes ||
                        res.paymentTypes.length === 0 ||
                        typeof res.paymentTypes[0] === 'string' ||
                        !res.paymentTypes[0].label
                            ? { paymentTypes: DEFAULT_PAYMENT_TYPES }
                            : { paymentTypes: res.paymentTypes }),
                        ...(!res?.bookingDepositType
                            ? { bookingDepositType: DEPOSIT_TYPES.PERCENTAGE }
                            : { bookingDepositType: res.bookingDepositType }),
                        bookingDeposit: !!(res?.bookingDepositType && res?.bookingDepositAmount)
                    });
                    setBookIntervals(res.bookIntervals);

                    reset({
                        ...res,
                        ...(res.termsAndConditionsText ? {} : { termsAndConditionsText: defaultTC }),
                        ...(res.thankYouText ? {} : { thankYouText: defaultThankYouHtml }),
                        ...(!res.paymentTypes ||
                        res.paymentTypes.length === 0 ||
                        typeof res.paymentTypes[0] === 'string' ||
                        !res.paymentTypes[0].label
                            ? { paymentTypes: DEFAULT_PAYMENT_TYPES }
                            : { paymentTypes: res.paymentTypes })
                    });
                })
                .finally(() => setIsLoading(false));
        },
        [reset, resetTreatmentRecordsData]
    );

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const requiredFields = Object.keys(errors);

        if (requiredFields.length !== 0) {
            const messages = requiredFields.map(field => errors[field].message);

            toastr.error('Missing fields', messages.join(','));
        }
    }, [errors]);

    const handleCancel = () => {
        // clear fields (load from api)
        setIsLoading(true);
        OrganisationsApi.getOrganisation().then(res => {
            resetTreatmentRecordsData(res);
            setOrganisation(res);
            reset(res);
            setIsLoading(false);
        });
    };

    const mapValuesToObject = useCallback(
        async paymentTypes => {
            return await paymentTypes
                .map(paymentType => {
                    const key = Object.keys(paymentType)[0];
                    const temp = fields.find(field => field.name === key);
                    if (temp?.id) delete temp.id;
                    return temp;
                })
                .filter(paymentType => paymentType);
        },
        [fields]
    );

    const convertDoseUnits = doseUnits => {
        return doseUnits.map(doseUnit => {
            return Math.round(parseFloat(doseUnit) * 100) / 100;
        });
    };

    const onSubmit = useCallback(
        async values => {
            setTreatmentRecords(treatmentRecordsRef.current);

            const injectionDoses = (treatmentRecordsRef.current || [])
                .filter(el => el.toxin)
                .map(el => ({ toxin: el.toxin.id, doseUnits: convertDoseUnits(el.doseUnits) }));

            const isTreatmentRecordsWithError = (injectionDoses || []).some(el => {
                return el.doseUnits.some(unit => {
                    return !unit || unit < 0.1 || Number.isNaN(unit);
                });
            });

            if (isTreatmentRecordsWithError) {
                toastr.error('Invalid unit values on injection dose values');
                return;
            }

            if (!(values?.name || '').trim()) {
                toastr.error('Missing name');
                return;
            }

            if (!(values?.email || '').trim()) {
                toastr.error('Missing email address');
                return;
            }

            if (!(values?.emailName || '').trim()) {
                toastr.error('Missing email send from name');
                return;
            }

            if (!(values?.address?.line1 || '').trim()) {
                toastr.error('Missing address1');
                return;
            }

            if (!(values?.address?.postcode || '').trim()) {
                toastr.error('Missing postcode');
                return;
            }

            if (!(values?.address?.city || '').trim()) {
                toastr.error('Missing city');
                return;
            }

            if (!(values?.address?.country || '').trim()) {
                toastr.error('Missing country');
                return;
            }

            if (values?.bookingDeposit) {
                if (Number(values?.bookingDepositAmount) === 0) {
                    toastr.error('Please provide correct value for Deposit percentage/amount');
                    return;
                }
                if (
                    Number(values?.bookingDepositAmount) > 100 &&
                    values?.bookingDepositType === DEPOSIT_TYPES.PERCENTAGE
                ) {
                    values.bookingDepositAmount = 100;
                }
                values.bookingDepositAmount = Number(values.bookingDepositAmount);
            } else {
                values.bookingDepositAmount = null;
                values.bookingDepositType = '';
            }

            delete values.bookingDeposit;

            setIsLoading(true);
            if (file) {
                //save logo
                await OrganisationsApi.uploadOrganisationLogo(organisation.id, file);
            }

            // saves only if user has changed the text
            if (values.termsAndConditionsText === defaultTC) {
                delete values.termsAndConditionsText;
            }
            if (values.thankYouText === defaultThankYouHtml) {
                delete values.thankYouText;
            }

            const data = {
                ...values,
                paymentTypes: await mapValuesToObject(values.paymentTypes),
                homeBannerText: homeBannerRef.current?.value || undefined,
                termsAndConditionsText: termsAndConditionsRef.current?.value || undefined,
                lateCancellationText: lateCancellationRef.current?.value || '',
                thankYouText: thankYouRef.current?.value || undefined,
                injectionDoses,
                reporting: organisation.reporting || {}
            };

            OrganisationsApi.updateOrganisation(data)
                .then(async () => {
                    if (data.logoutTime && data.logoutActive !== undefined) {
                        const environment = {};
                        if (window.location.hostname !== 'localhost') {
                            environment.path = '/';
                            environment.domain = COLLUMS_DOMAIN;
                        }
                        setCookies(COOKIE_KEYS.EXP_TIME, data.logoutTime, environment);
                        setCookies(COOKIE_KEYS.LOGOUT_ACTIVE, data.logoutActive, environment);
                    }

                    dispatch(loadOrganisation(data));
                    localStorage.setItem('currency', data.currency);
                    await loadData(true);
                    setIsLoading(false);
                    toastr.success('Organisation updated!');
                })
                .catch(err => {
                    setIsLoading(false);
                    reset();
                    if (typeof err === 'object') {
                        if (err.data && err.data.message) {
                            toastr.error(err.data.message);
                            return;
                        }
                        toastr.error('Oops', 'Something went wrong');
                    }
                });
        },
        // eslint-disable-next-line
        [file, loadData, mapValuesToObject, organisation.id, organisation.reporting]
    );

    const submit = useCallback(
        e => {
            if (e) {
                e.preventDefault();
            }

            handleSubmit(onSubmit)();
        },
        [handleSubmit, onSubmit]
    );

    // saves the organisation when updated on paymentTypeModal as we cannot pass the submit func
    useEffect(() => {
        if (update) {
            submit();
            setUpdate(false);
        }
    }, [submit, update]);

    const updatePaymentType = useCallback(
        async (id, newOption) => {
            let newPayments = await (fields || []).map(payment => {
                if (payment.id === id) {
                    return { ...newOption };
                }
                return payment;
            });

            setValue('paymentTypes', newPayments);
            setOrganisation({
                ...organisation,
                paymentTypes: newPayments
            });
        },
        [fields, organisation, setValue]
    );

    const openPaymentsModal = useCallback(paymentType => {
        if (paymentType && paymentType.id) {
            setSelectedType(paymentType);
            setIsPaymentTypeModalVisible(true);
        } else {
            setSelectedType('new');
            setIsPaymentTypeModalVisible(true);
        }
    }, []);

    const openDeleteModal = useCallback(paymentType => {
        setSelectedType(paymentType);
        setDeleteModalOpen(true);
    }, []);

    const ClosePaymentTypeModal = () => {
        setSelectedType();
        setDeleteModalOpen(false);
        setIsPaymentTypeModalVisible(false);
    };

    const handleDeletePaymentType = useCallback(async () => {
        if (selectedType.id) {
            await OrganisationsApi.removePaymentType(organisation.id, selectedType.id);
            await loadData();
        }
    }, [loadData, organisation, selectedType]);

    if (isLoading && !Object.keys(organisation).length) {
        return <LoadingScreen />;
    }

    return (
        <OpenedModal>
            <form onSubmit={submit}>
                {/* General */}
                <Accordion
                    expanded={expanded === 'general'}
                    onChange={() => setExpanded(expanded === 'general' ? '' : 'general')}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontSize: '0.9rem' }} />}>
                        <Typography>Organisation Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <GeneralTab
                            register={register}
                            organisation={organisation}
                            setValue={setValue}
                            setOrganisation={setOrganisation}
                            setFile={setFile}
                        />
                    </AccordionDetails>
                </Accordion>

                {/* Label options  // hardcoded for now
                <Accordion
                    expanded={expanded === 'labelOptions'}
                    onChange={() => (expanded === 'labelOptions' ? setExpanded('') : setExpanded('labelOptions'))}
                >
                    <AccordionSummary>
                        <Typography>Label options</Typography>
                    </AccordionSummary>
                    <AccordionDetails></AccordionDetails>
                </Accordion>*/}

                {/* Calendar */}
                <Accordion
                    expanded={expanded === 'calendar'}
                    onChange={() => setExpanded(expanded === 'calendar' ? '' : 'calendar')}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontSize: '0.9rem' }} />}>
                        <Typography>Calendar</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <CalendarTab
                            register={register}
                            organisation={organisation}
                            control={control}
                            setOrganisation={setOrganisation}
                        />
                    </AccordionDetails>
                </Accordion>

                {/* Payment Types  */}
                <Accordion
                    expanded={expanded === 'paymentTypes'}
                    onChange={() => setExpanded(expanded === 'paymentTypes' ? '' : 'paymentTypes')}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontSize: '0.9rem' }} />}>
                        <Typography>Payments</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PaymentTypesTab
                            fields={fields}
                            setValue={setValue}
                            register={register}
                            organisation={organisation}
                            setOrganisation={setOrganisation}
                            openModal={openPaymentsModal}
                            openDeleteModal={openDeleteModal}
                            setUpdate={setUpdate}
                            hasAdvancedPermissions={hasAdvancedPermissions}
                        />
                    </AccordionDetails>
                </Accordion>

                {/* Tax Rates  */}
                <Accordion
                    expanded={expanded === 'taxRates'}
                    onChange={() => setExpanded(expanded === 'taxRates' ? '' : 'taxRates')}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontSize: '0.9rem' }} />}>
                        <Typography>Tax Rates</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TaxRatesTab
                            expanded={expanded === 'taxRates'}
                            organisationId={organisation.id}
                            setIsLoading={setIsLoading}
                            // openModal={openTaxRatesModal}
                        />
                    </AccordionDetails>
                </Accordion>

                {/* Commissions  */}
                <Accordion
                    expanded={expanded === 'commissions'}
                    onChange={() => setExpanded(expanded === 'commissions' ? '' : 'commissions')}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontSize: '0.9rem' }} />}>
                        <Typography>Commissions</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <CommissionsTab expanded={expanded === 'commissions'} setIsLoading={setIsLoading} />
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    expanded={expanded === 'security'}
                    onChange={() => setExpanded(expanded === 'security' ? '' : 'security')}
                    hidden={true}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontSize: '0.9rem' }} />}>
                        <Typography>Security</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {expanded === 'security' && (
                            <SecurityTab
                                control={control}
                                register={register}
                                organisation={organisation}
                                setIsLoading={setIsLoading}
                                watch={watch}
                            />
                        )}
                    </AccordionDetails>
                </Accordion>

                {/* Online Customer App */}
                <Accordion
                    expanded={expanded === 'customerApp'}
                    onChange={() => setExpanded(expanded === 'customerApp' ? '' : 'customerApp')}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontSize: '0.9rem' }} />}>
                        <Typography>Online Booking & Deposits</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <CustomerAppTab
                            register={register}
                            bookIntervals={bookIntervals}
                            setBookIntervals={setBookIntervals}
                            organisation={organisation}
                            control={control}
                            setValue={setValue}
                            setOrganisation={setOrganisation}
                            homeBannerRef={homeBannerRef}
                            termsAndConditionsRef={termsAndConditionsRef}
                            lateCancellationRef={lateCancellationRef}
                            thankYouRef={thankYouRef}
                        />
                    </AccordionDetails>
                </Accordion>

                {/* E-notes settings */}
                <Accordion
                    expanded={expanded === 'treatmentRecords'}
                    onChange={() => setExpanded(expanded === 'treatmentRecords' ? '' : 'treatmentRecords')}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontSize: '0.9rem' }} />}>
                        <Typography>Client Journey and Treatment Records</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ flexDirection: 'column' }}>
                        <Typography variant="h4">Client journey</Typography>

                        <Grid container direction="column" spacing={3}>
                            <Grid item>
                                <FormControlLabel
                                    label="Allow to skip forms in journey"
                                    control={
                                        <Checkbox
                                            onChange={changeAllowSkippingFormsInJourney}
                                            color="primary"
                                            checked={Boolean(organisation?.allowSkippingFormsInJourney)}
                                            inputRef={register}
                                            name="allowSkippingFormsInJourney"
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                    <AccordionDetails>
                        {treatmentRecords && (
                            <TreatmentRecordsTab
                                treatmentRecordsRef={treatmentRecordsRef}
                                toxinProducts={toxinProducts}
                                treatmentRecords={treatmentRecords}
                                setTreatmentRecords={setTreatmentRecords}
                                addNewTreatmentRecord={addNewTreatmentRecord}
                            />
                        )}
                    </AccordionDetails>
                </Accordion>

                {/* Product Stock settings */}
                <Accordion
                    expanded={expanded === 'productStock'}
                    onChange={() => setExpanded(expanded === 'productStock' ? '' : 'productStock')}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontSize: '0.9rem' }} />}>
                        <Typography>Product Stock</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ProductStockTab
                            register={register}
                            organisation={organisation}
                            control={control}
                            setValue={setValue}
                            setOrganisation={setOrganisation}
                        />
                    </AccordionDetails>
                </Accordion>

                {/* Reports settings */}
                <Accordion
                    expanded={expanded === 'reports'}
                    onChange={() => {
                        if (expanded === 'reports') {
                            setExpanded('');
                        } else {
                            setExpanded('reports');
                        }
                    }}
                    hidden={true}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontSize: '0.9rem' }} />}>
                        <Typography>Reports</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ReportsSettings
                            organisation={organisation}
                            setValue={setValue}
                            setOrganisation={setOrganisation}
                        />
                    </AccordionDetails>
                </Accordion>

                <div className={globalStyles.buttonsContainer}>
                    <Button className={globalStyles.cancelButton} onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button className={globalStyles.confirmButton} type="submit" variant="outlined">
                        Save
                    </Button>
                </div>
            </form>
            {isLoading && <LoadingScreen />}
            {isPaymentTypeModalVisible && selectedType && (
                <PaymentTypeModal
                    append={append}
                    closeModal={ClosePaymentTypeModal}
                    setUpdate={setUpdate}
                    updatePaymentType={updatePaymentType}
                    paymentType={selectedType}
                />
            )}
            {deleteModalOpen && selectedType && (
                <ConfirmModal
                    isOpen
                    setIsOpen={ClosePaymentTypeModal}
                    centerContent
                    onConfirm={handleDeletePaymentType}
                    title="Delete payment type"
                    content="Are you sure you want to delete this payment type?"
                />
            )}
        </OpenedModal>
    );
}

export default withStyles(settingsStyles)(Settings);
