import { getCurrencySymbol } from '../../collums-components/helpers';

export const generalForm = [
    {
        id: 'active',
        name: 'active',
        type: 'checkbox',
        label: 'Active'
    },
    {
        id: 'employeeCode',
        name: 'employeeCode',
        type: 'text',
        label: 'Employee code',
        defaultDisabled: true
    },
    {
        id: 'title',
        name: 'title',
        type: 'select',
        label: 'Title',
        options: [
            {
                label: 'Mr',
                value: 'Mr'
            },
            {
                label: 'Mrs',
                value: 'Mrs'
            },
            {
                label: 'Miss',
                value: 'Miss'
            },
            {
                label: 'Ms',
                value: 'Ms'
            },
            {
                label: 'Dr',
                value: 'Dr'
            },
            {
                label: 'Prof',
                value: 'Prof'
            },
            {
                label: 'Not entered',
                value: 'Not entered'
            }
        ],
        required: true
    },
    {
        id: 'customTitle',
        name: 'customTitle',
        type: 'text',
        label: 'Title',
        controlledVisibility: { path: 'title.value', value: 'Custom' }
    },
    {
        id: 'firstName',
        name: 'firstName',
        type: 'text',
        label: 'First name',
        required: true
    },
    {
        id: 'middleName',
        name: 'middleName',
        type: 'text',
        label: 'Middle name'
    },
    {
        id: 'lastName',
        name: 'lastName',
        type: 'text',
        label: 'Surname',
        required: true
    },
    {
        id: 'previousName',
        name: 'previousName',
        type: 'text',
        label: 'Prev surname'
    },
    {
        id: 'displayName',
        name: 'displayName',
        type: 'text',
        label: 'Display name',
        required: true
    },
    {
        id: 'gender',
        name: 'gender',
        type: 'text',
        label: 'Gender'
    },
    {
        id: 'professionalRegCode',
        name: 'professionalRegCode',
        type: 'text',
        label: 'Professional reg no'
    },
    {
        id: 'phone',
        name: 'phone',
        type: 'text',
        label: 'Mobile phone'
    },
    {
        id: 'secondPhone',
        name: 'secondPhone',
        type: 'text',
        label: 'Other phone'
    },
    {
        id: 'emailPractitioner',
        name: 'emailPractitioner',
        type: 'text',
        label: 'Email'
    },
    {
        id: 'birthdate',
        name: 'birthdate',
        type: 'text',
        label: 'DoB'
    },
    {
        id: 'workAnniversary',
        name: 'workAnniversary',
        type: 'text',
        label: 'Work anniversary'
    },
    {
        id: 'location',
        name: 'location',
        type: 'text',
        label: 'Location'
    },
    {
        id: 'address1',
        name: 'address1',
        type: 'text',
        label: 'Address 1'
    },
    {
        id: 'address2',
        name: 'address2',
        type: 'text',
        label: 'Address 2'
    },
    {
        id: 'city',
        name: 'city',
        type: 'text',
        label: 'City'
    },
    {
        id: 'country',
        name: 'country',
        multiple: false,
        type: 'autocomplete',
        label: 'Country',
        placeholder: '',
        allowUndefinedOption: false,
        options: []
    },
    {
        id: 'county',
        name: 'county',
        type: 'text',
        label: 'County'
    },
    {
        id: 'postal',
        name: 'postal',
        type: 'text',
        label: 'Post Code'
    },
    {
        id: 'postal',
        name: 'postal',
        type: 'text',
        label: 'Post Code'
    },
    {
        id: 'positionCategory',
        name: 'positionCategory',
        type: 'select',
        label: 'Position category',
        options: [
            {
                label: 'Doctor',
                value: 'doctor'
            },
            {
                label: 'Nurse',
                value: 'nurse'
            },
            {
                label: 'Therapist',
                value: 'therapist'
            },
            {
                label: 'Management',
                value: 'management'
            },
            {
                label: 'Assistant Manager',
                value: 'assistantManager'
            },
            {
                label: 'Admin',
                value: 'admin'
            },
            {
                label: 'Front of House',
                value: 'frontOfHouse'
            },
            {
                label: 'create new',
                value: 'new'
            }
        ]
    },
    {
        id: 'newPositionCategory',
        name: 'newPositionCategory',
        type: 'text',
        label: 'Position category',
        controlledVisibility: { path: 'positionCategory.value', value: 'new' }
    },
    {
        id: 'position',
        name: 'position',
        type: 'select',
        label: 'Position',
        controlledOptions: true
    },
    {
        id: 'selfEmployed',
        name: 'selfEmployed',
        type: 'checkbox',
        label: 'Self employed/consultant'
    },
    {
        id: 'companyName',
        name: 'companyName',
        type: 'text',
        label: 'Company name',
        visibleWhen: [
            {
                field: 'selfEmployed',
                is: [true]
            }
        ]
    },
    {
        id: 'companyNumber',
        name: 'companyNumber',
        type: 'text',
        label: 'Company no',
        visibleWhen: [
            {
                field: 'selfEmployed',
                is: [true]
            }
        ]
    },
    {
        id: 'companyAddress1',
        name: 'companyAddress1',
        type: 'text',
        label: 'Company address 1',
        visibleWhen: [
            {
                field: 'selfEmployed',
                is: [true]
            }
        ]
    },
    {
        id: 'companyAddress2',
        name: 'companyAddress2',
        type: 'text',
        label: 'Company address 2',
        visibleWhen: [
            {
                field: 'selfEmployed',
                is: [true]
            }
        ]
    },
    {
        id: 'companyCity',
        name: 'companyCity',
        type: 'text',
        label: 'Company city',
        visibleWhen: [
            {
                field: 'selfEmployed',
                is: [true]
            }
        ]
    },
    {
        id: 'companyCountry',
        name: 'companyCountry',
        type: 'text',
        label: 'Company country ',
        visibleWhen: [
            {
                field: 'selfEmployed',
                is: [true]
            }
        ]
    },
    {
        id: 'companyCounty',
        name: 'companyCounty',
        type: 'text',
        label: 'Company county ',
        visibleWhen: [
            {
                field: 'selfEmployed',
                is: [true]
            }
        ]
    },
    {
        id: 'companyPostal',
        name: 'companyPostal',
        type: 'text',
        label: 'Company post code ',
        visibleWhen: [
            {
                field: 'selfEmployed',
                is: [true]
            }
        ]
    },
    {
        id: 'salary',
        name: 'salary',
        type: 'number',
        label: 'Salary ',
        prefix: getCurrencySymbol()
    },
    {
        id: 'hourlyRate',
        name: 'hourlyRate',
        type: 'number',
        label: 'Hourly rate ',
        prefix: getCurrencySymbol()
    },
    {
        id: 'overtime',
        name: 'overtime',
        type: 'number',
        label: 'Overtime ',
        prefix: getCurrencySymbol()
    },
    {
        id: 'mandatoryBreak',
        name: 'mandatoryBreak',
        type: 'duration',
        label: 'Mandatory break '
    },
    {
        id: 'useBonus',
        name: 'useBonus',
        type: 'text',
        label: 'Eligible for additional commission bonus',
        defaultValue: "I don't know how this field should looks like"
    },
    {
        id: 'maxWorkHours',
        name: 'maxWorkHours',
        type: 'number',
        label: 'Max work hours'
    },
    {
        id: 'targetRevenue',
        name: 'targetRevenue',
        type: 'number',
        label: 'Target revenue'
    },
    {
        id: 'annualLeaveDays',
        name: 'annualLeaveDays',
        type: 'number',
        label: 'Annual leave days'
    },
    {
        id: 'specialLeaveDays',
        name: 'specialLeaveDays',
        type: 'number',
        label: 'Special leave days'
    },
    {
        id: 'sickDays',
        name: 'sickDays',
        type: 'number',
        label: 'Sick days'
    },
    {
        id: 'startDate',
        name: 'startDate',
        type: 'text',
        label: 'Start date'
    },
    {
        id: 'endDate',
        name: 'endDate',
        type: 'text',
        label: 'End date'
    },
    {
        id: 'probationCompleteDate',
        name: 'probationCompleteDate',
        type: 'text',
        label: 'Probation completed date'
    },
    {
        id: 'marketingEmails',
        name: 'marketingEmails',
        type: 'checkbox',
        label: 'Receive marketing emails'
    },
    {
        id: 'marketingSms',
        name: 'marketingSms',
        type: 'checkbox',
        label: 'Receive marketing SMS'
    },
    {
        id: 'notificationEmails',
        name: 'notificationEmails',
        type: 'checkbox',
        label: 'Receive notification emails'
    },
    {
        id: 'notificationSms',
        name: 'notificationSms',
        type: 'checkbox',
        label: 'Receive notification SMS'
    },
    {
        id: 'appointmentEmails',
        name: 'appointmentEmails',
        type: 'checkbox',
        label: 'Send confirmation email'
    },
    {
        id: 'appointmentSms',
        name: 'appointmentSms',
        type: 'checkbox',
        label: 'Send confirmation SMS'
    }
];

export const userDetailsForm = [
    {
        id: 'email',
        name: 'email',
        type: 'text',
        label: 'Email',
        required: true
    },
    {
        id: 'password',
        name: 'password',
        type: 'password',
        label: 'Password',
        required: true
    },
    {
        id: 'confirmPassword',
        name: 'confirmPassword',
        type: 'password',
        label: 'Confirm password',
        required: true
    },
    {
        id: 'usePin',
        name: 'usePin',
        type: 'checkbox',
        label: 'PIN log in'
    },
    {
        id: 'pin',
        name: 'pin',
        type: 'password',
        label: 'PIN',
        visibleWhen: [
            {
                field: 'usePin',
                is: [true]
            }
        ]
    },
    {
        id: 'autoLogoutDuration',
        name: 'autoLogoutDuration',
        type: 'duration',
        label: 'Auto log out'
    }
];
