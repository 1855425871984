export const modalFormStyles = {
    closeButton: {
        position: 'absolute',
        right: 6,
        top: 6,
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 20
    },
    modalContainer: {
        backgroundColor: '#eeeeee',
        '& .MuiDialogTitle-root': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& h4': {
                fontSize: 18,
                fontWeight: 500
            }
        },
        borderRadius: 4,
        '& .MuiDialogContent-root': {
            maxHeight: '80vh',
            overflow: 'auto'
        },
        boxShadow: `
            0px 11px 15px -7px rgb(0, 0, 0, 20%), 
            0px 24px 38px 3px rgb(0, 0, 0, 14%), 
            0px 9px 46px 8px rgb(0, 0, 0, 12%)
        `,
        top: '50%',
        transform: 'translate(-50%, -50%)',
        margin: '0',
        width: 'calc(85% - 60px)',

        '@media (min-width: 1300px)': {
            width: '90%'
        }
    }
};
