import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';

import { dateFormatter, calculateAge, isSameDOB, dobFormatter } from '../services/helpers';

import { mergeClientsTable } from '../styles';
import tableColumns from './customersTableColumns';

import config from '../../../config';

import {
    withStyles,
    Typography,
    Button,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableSortLabel,
    TablePagination
} from '@material-ui/core';

import MergeClientsActions from './MergeClientsActions';
import { Link } from 'react-router-dom';

function MergeClientsTable({
    classes,
    customers,
    sortState,
    setSortState,
    numOfItems,
    setPage,
    page,
    setRowsPerPage,
    rowsPerPage,
    archiveCustomer,
    unArchiveCustomer,
    setCustomer1,
    setCustomer2,
    customer1,
    openEditModal,
    isFromOrg,
    showDuplicates
    // allClinics = []
}) {
    const actions = [
        {
            label: 'Client Card',
            handler: customer => {
                window.open(CCUrl(customer), '_blank');
            }
        },
        {
            label: 'Merge',
            handler: customer => setCustomer1(customer)
        },
        {
            label: 'Archive',
            handler: async customer => await archiveCustomer(customer.id)
        }
    ];

    const archivedActions = [
        {
            label: 'Client Card',
            handler: customer => {
                window.open(CCUrl(customer), '_blank');
            }
        },
        {
            label: 'Merge',
            handler: customer => setCustomer1(customer)
        },
        {
            label: 'Unarchive',
            handler: async customer => await unArchiveCustomer(customer.id)
        }
    ];

    // if (!isFromOrg && allClinics.length > 1) {
    //     const mergeOpt = actions.find(item => item.label === 'Merge');
    //     const index = actions.indexOf(mergeOpt);
    //     if (index >= 0) {
    //         actions.splice(index, 1);
    //     }
    // }

    if (!isFromOrg) {
        const mergeOpt = archivedActions.find(item => item.label === 'Merge');
        const index = archivedActions.indexOf(mergeOpt);
        if (index >= 0) {
            archivedActions.splice(index, 1);
        }
    }
    const CCUrl = customer => `${config.clientCardUrl}/${customer.id}/general`;
    const cancelMerge = () => {
        setCustomer1({});
    };

    const merge = customer => {
        if (!customer1.dateOfBirth || !customer.dateOfBirth) {
            return toastr.warning('', 'Client must have date of birth for merge');
        } else if (!isSameDOB(customer1.dateOfBirth, customer.dateOfBirth)) {
            return toastr.warning('', 'Clients have different DOB');
        }
        setCustomer2(customer);
    };

    const handleChangeSort = column => {
        setPage(0);
        if (column.field === sortState.columnId) {
            const sortDirection = sortState.sortDirection === 'asc' ? 'desc' : 'asc';
            setSortState({ ...sortState, sortDirection });
            return;
        }
        setSortState({ columnId: column.field === 'age' ? 'dateOfBirth' : column.field, sortDirection: 'asc' });
    };

    const renderColumns = () => {
        return (
            <TableRow className={classes.columnsRow}>
                {tableColumns.map(column => (
                    <TableCell key={column.title} style={column.style} component="th" scope="row">
                        {column.reordable ? (
                            <TableSortLabel
                                active={column.title === sortState.title}
                                direction={sortState.sortDirection}
                                onClick={() => handleChangeSort(column)}
                            >
                                <Typography>{column.title}</Typography>
                            </TableSortLabel>
                        ) : (
                            <Typography>{column.title}</Typography>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        );
    };

    const renderActions = customer => {
        if (!customer1 || !customer1.id) {
            if (customer.archived) {
                return <MergeClientsActions actions={archivedActions} customer={customer} />;
            }

            return <MergeClientsActions actions={actions} customer={customer} />;
        }
        if (customer1.id === customer.id)
            return (
                <Button className={`${classes.cancelMergeBtn} ${classes.actionBtn}`} onClick={cancelMerge}>
                    Cancel
                </Button>
            );
        return (
            <Button className={`${classes.mergeBtn} ${classes.actionBtn}`} onClick={() => merge(customer)}>
                Merge
            </Button>
        );
    };

    const getRowStyles = customer => {
        const styles = {
            backgroundColor: 'white'
        };

        if (!customer.active) {
            styles.backgroundColor = '#ffd59e';
        }

        if (customer.archived) {
            styles.backgroundColor = '#ff9e9e';
        }

        return styles;
    };

    return (
        <>
            <div className={classes.container}>
                {showDuplicates && (
                    <p>
                        These clients share the same first name and date of birth. They may NOT be the same person.
                        Please check carefully before merging clients.
                    </p>
                )}
                <Table className={classes.table}>
                    <TableHead>{renderColumns()}</TableHead>
                    <TableBody>
                        {customers.map(customer => (
                            <TableRow key={customer.id} style={getRowStyles(customer)}>
                                <TableCell>
                                    <Link onClick={() => openEditModal(customer)}>{customer.firstName}</Link>
                                </TableCell>
                                <TableCell>
                                    <Link onClick={() => openEditModal(customer)}>{customer.surname}</Link>
                                </TableCell>
                                <TableCell>{dobFormatter(customer.dateOfBirth, 'Missing DOB')}</TableCell>
                                <TableCell>{calculateAge(customer)}</TableCell>
                                <TableCell>{customer.gender}</TableCell>
                                <TableCell>{customer.mobilePhone}</TableCell>
                                <TableCell>{customer.email}</TableCell>
                                <TableCell>{customer.address1}</TableCell>
                                <TableCell>{dateFormatter(customer.createdAt)}</TableCell>
                                <TableCell>{dateFormatter(customer.dates?.firstVisit)}</TableCell>
                                <TableCell>{dateFormatter(customer.dates?.lastVisit)}</TableCell>
                                <TableCell>{customer.id}</TableCell>
                                <TableCell>{customer?.tagsString || ''}</TableCell>
                                <TableCell>{renderActions(customer)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
            <div className={classes.fixedTableFooter}>
                <TablePagination
                    width={'100%'}
                    count={numOfItems}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    onChangePage={(e, newPage) => setPage(newPage)}
                    onChangeRowsPerPage={e => {
                        setRowsPerPage(parseInt(e.target.value));
                        setPage(0);
                    }}
                />
            </div>
        </>
    );
}

MergeClientsTable.propTypes = {
    classes: PropTypes.object.isRequired,
    customers: PropTypes.array.isRequired,
    sortState: PropTypes.object.isRequired,
    setSortState: PropTypes.func.isRequired,
    numOfItems: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    setRowsPerPage: PropTypes.func.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    archiveCustomer: PropTypes.func.isRequired,
    setCustomer1: PropTypes.func.isRequired,
    setCustomer2: PropTypes.func.isRequired,
    customer1: PropTypes.object
};

export default withStyles(mergeClientsTable)(MergeClientsTable);
