export const mergeClientsTable = () => ({
    container: {
        marginTop: 16,
        width: '100%',
        overflowX: 'auto',
        '& p': {
            fontFamily: 'Roboto, Arial, "sans-serif"'
        }
    },
    fixedTableFooter: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    columnsRow: {
        '& th span p': {
            fontWeight: 500
        }
    },
    redirectButton: {
        padding: 0,
        color: '#3083EC',
        textDecoration: 'none',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    actionBtn: {
        '&:hover': {
            backgroundColor: '#3c3c6b'
        },
        fontWeight: 400,
        border: 0
    },
    cancelMergeBtn: {
        backgroundColor: '#fa4a43',
        color: '#ffffff'
    },
    mergeBtn: {
        backgroundColor: '#565A65',
        color: 'white'
    }
});

export const customModal = () => ({
    mergeClientsTh: {
        '& th': {
            maxWidth: 350,
            boxSizing: 'content-box',
            '@media (min-width: 768px)': {
                minWidth: 150
            },
            '@media (min-width: 1024px)': {
                minWidth: 190
            },
            '@media (min-width: 1440px)': {
                minWidth: 225
            }
        },
        '& th:nth-child(1)': {
            width: 100
        }
    },
    mergeClientsTableRow: {
        '& td, td p': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            '@media (min-width: 768px)': {
                maxWidth: '178px'
            },
            '@media (min-width: 1024px)': {
                maxWidth: '222px'
            },
            '@media (min-width: 1440px)': {
                maxWidth: '256px'
            }
        }
    },
    closeButton: {
        position: 'absolute',
        right: 6,
        top: 6,
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 20
    },
    clickableTableCell: {
        cursor: 'pointer'
    },
    selectedTableCell: {
        border: '1px solid green'
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& h4': {
            fontSize: 18,
            fontWeight: 500
        }
    }
});

export const mergeClientsHeader = () => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    headerStatusBoxLabel: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        justifySelf: 'flex-end',
        alignItems: 'center'
    },

    headerStatusBoxTooltip: {
        marginBottom: '2px',
        marginLeft: '2px'
    },
    optionsButton: {
        display: 'flex',
        justifySelf: 'flex-start',
        backgroundColor: '#3083EC',
        color: '#fff',
        width: 80,
        height: 38,
        borderRadius: 0,
        marginBottom: 0,
        textTransform: 'none',
        fontSize: 14,
        fontWeight: 400,
        padding: '6px 16px',

        '&:hover': {
            backgroundColor: '#3083EC'
        }
    }
});

export const mergeClientsEditCustomer = theme => ({
    formContainer: {
        flexFlow: 'column',
        marginLeft: theme.spacing(),
        marginRight: 90,
        marginTop: 10,
        marginBottom: 10,
        width: 200,
        display: 'flex',
        flexWrap: 'wrap'
    },
    textField: {
        width: '100%',
        marginTop: theme.spacing(),
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        marginBottom: theme.spacing()
    },
    noteField: {
        width: 'calc(100% - 10px)',
        '& .MuiOutlinedInput-root': {
            height: 'auto'
        }
    },
    whitespace: {
        height: 8,
        marginTop: theme.spacing(),
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        marginBottom: theme.spacing()
    },
    inputIcon: {
        color: '#bfbfbf'
    },
    hiddenCalendarIcon: {
        '& .MuiIconButton-root': {
            display: 'none'
        }
    },
    flexBox: {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'space-between'
    }
});
