export const squareView = () => ({
    container: {
        margin: 16,
        padding: 16,
        '& div': {
            '& b': {
                fontFamily: 'Roboto, Arial, Sans-serif',
                fontWeight: 500
            }
        }
    },
    connectButton: {
        marginTop: 16
    },
    fullWidthRoboto: {
        width: '100%',
        fontFamily: 'Roboto, Arial, Sans-serif'
    },
    font16px: {
        fontSize: '1rem'
    },
    listPadding: {
        marginLeft: '-15px'
    },
    errorBox: {
        backgroundColor: '#f9baba',
        border: '1px solid red',
        borderRadius: 10,
        padding: 10,
        width: '100%',
        fontFamily: 'Roboto, Arial, Sans-serif'
    },
    revokeBtn: {
        backgroundColor: 'red',
        color: 'white'
    },
    codeSection: {
        backgroundColor: '#daffc4',
        borderColor: '#9bd479',
        borderStyle: 'solid',
        borderWidth: 1,
        padding: '0px 10px 0px 10px',
        marginTop: 20,
        marginBottom: 20,
        borderRadius: 5
    },
    deviceName: {
        fontSize: '14px',
        fontFamily: 'Roboto, Arial, Sans-serif',
        '& input::placeholder': {
            fontSize: '14px!important',
            fontFamily: 'Roboto, Arial, Sans-serif !important'
        }
    }
});
