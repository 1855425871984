import React, { useEffect, useState } from 'react';

import notification from '../../services/Notification';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';

const Notification = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState(null);
    const [type, setType] = useState(null);

    useEffect(() => {
        notification.init((message, type) => {
            setMessage(message);
            setType(type);
            setIsOpen(true);
        });
    }, []);

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            open={isOpen}
            autoHideDuration={3000}
            onClose={() => setIsOpen(false)}
        >
            <Alert onClose={() => setIsOpen(false)} severity={type} elevation={6} variant="filled">
                {message}
            </Alert>
        </Snackbar>
    );
};

export default Notification;
