import React, { useState } from 'react';
import { Button, Checkbox, IconButton, Menu, MenuItem, TextField, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ClearIcon from '@material-ui/icons/Clear';

import { buttonsStyles } from '../../../collums-constants/styles/stylesheets/buttonsStyles';
import { actionsStyles } from './styles';
import { inputSearchStyles } from '../../../collums-constants/styles/stylesheets/inputSearchStyles';
import { STATUS_DELETED } from '../../../collums-constants';

export function NameFilter({ value, onAction }) {
    const classes = makeStyles(inputSearchStyles)();
    return (
        <TextField
            id="search-input"
            value={value}
            onChange={event => onAction(event.target.value)}
            variant="outlined"
            className={classes.inputSearch}
            InputProps={{
                endAdornment: (
                    <>
                        <IconButton
                            className={classes.icons}
                            onClick={() => {
                                onAction('');
                            }}
                            disabled={!value}
                        >
                            <ClearIcon style={!value ? { color: 'transparent' } : {}} />
                        </IconButton>
                        <IconButton position="end" className={classes.icons}>
                            <SearchIcon />
                        </IconButton>
                    </>
                )
            }}
        />
    );
}

export function FilterAction({ onAction, data }) {
    const [checked, setChecked] = useState(data.value);
    return (
        <div>
            <Checkbox
                checked={checked}
                onChange={() => {
                    data.value = !checked;
                    setChecked(!checked);
                    onAction(data);
                }}
            />
            <label>{data.label || data.name}</label>
        </div>
    );
}

export function AddAction({ onAction, data }) {
    const classes = makeStyles(buttonsStyles)();
    return (
        <>
            <IconButton
                onClick={() => {
                    onAction(data);
                }}
            >
                <AddCircleIcon className={classes.addCircleStyle} />
            </IconButton>
        </>
    );
}

export function InputFilter({ options }) {
    const classes = makeStyles(actionsStyles)();
    const { apiCall, reload, onFiltersChange } = options;
    const [inputOptions, setOptions] = useState([]);
    const loadOptions = async (value = '') => {
        const newOptions = await reload(value);
        setOptions(newOptions);
    };

    useState(() => loadOptions(), []);

    if (options.isSelect && !options.dynamic) {
        let defValue = reload();
        return (
            <Autocomplete
                defaultValue={defValue[0]}
                className={classes.autocomplete}
                variant="outlined"
                options={inputOptions}
                getOptionLabel={el => el.label}
                onChange={async (event, value) => {
                    onFiltersChange(apiCall, value.value);
                }}
                renderInput={params => (
                    <TextField
                        style={{ width: 200, marginRight: 20 }}
                        {...params}
                        label={options.name}
                        variant="outlined"
                    />
                )}
                disableClearable
            />
        );
    }
    return (
        <Autocomplete
            multiple
            options={inputOptions}
            className={classes.autocomplete}
            getOptionLabel={option => option.label}
            onChange={async (event, values) => {
                onFiltersChange(apiCall, values);
            }}
            onInputChange={(event, value) => {
                if (options.dynamic) loadOptions(value);
            }}
            renderInput={params => (
                <TextField
                    style={{ width: 200, marginRight: 20 }}
                    {...params}
                    label={options.name}
                    variant="outlined"
                />
            )}
        />
    );
}

export function ActionButton({ items, data, isFromOrg, clinic }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = event => setAnchorEl(event.currentTarget);
    const closeMenu = () => setAnchorEl(null);

    const globalStyles = makeStyles(buttonsStyles)();

    return (
        <>
            {items.length !== 0 && (
                <Button onClick={openMenu} className={globalStyles.grayButton}>
                    Options
                </Button>
            )}
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
                {items.map((element, index) => {
                    switch (element.title) {
                        case 'edit':
                            return (
                                <MenuItem
                                    key={index}
                                    onClick={() => {
                                        element.action(data);
                                        closeMenu();
                                    }}
                                >
                                    Edit
                                </MenuItem>
                            );
                        case 'delete':
                            return (
                                <MenuItem
                                    key={index}
                                    onClick={() => {
                                        element.action(data.id, data);
                                        closeMenu();
                                    }}
                                >
                                    Delete
                                </MenuItem>
                            );
                        case 'archive-practitioner':
                            return (
                                <MenuItem
                                    key={index}
                                    onClick={() => {
                                        element.action(data);
                                        closeMenu();
                                    }}
                                >
                                    {!data.archived ? 'Archive' : 'Unarchive'}
                                </MenuItem>
                            );
                        case 'view':
                            return (
                                <MenuItem
                                    key={index}
                                    onClick={() => {
                                        element.action(data);
                                        closeMenu();
                                    }}
                                >
                                    View
                                </MenuItem>
                            );
                        case 'archive':
                            /*eslint-disable-next-line */
                            let message;
                            /*eslint-disable-next-line */
                            let archiveType = typeof data.archived;
                            if (archiveType !== 'undefined' && archiveType === 'boolean') {
                                if (isFromOrg || !data?.locations?.length) {
                                    message = data.archived ? 'Unarchive' : 'Archive';
                                } else if (!isFromOrg && clinic && clinic !== 'organisation') {
                                    message = data.locations.find(loc => loc.clinic === clinic)?.archived
                                        ? 'Unarchive'
                                        : 'Archive';
                                }
                            } else message = data.active !== STATUS_DELETED ? 'Archive' : 'Unarchive';

                            return (
                                <MenuItem
                                    key={index}
                                    onClick={() => {
                                        element.action(data);
                                        closeMenu();
                                    }}
                                >
                                    {message}
                                </MenuItem>
                            );
                        default:
                            return (
                                <MenuItem
                                    key={index}
                                    onClick={() => {
                                        element.action(data.id, data);
                                        closeMenu();
                                    }}
                                >
                                    {element.title
                                        ? element.title.charAt(0).toUpperCase() + element.title.slice(1)
                                        : ''}
                                </MenuItem>
                            );
                    }
                })}
            </Menu>
        </>
    );
}
