import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';

import { singleAutocompleteStyles } from './styles';

class SingleAutocomplete extends React.Component {
    constructor(props) {
        super(props);
        this.value = '';

        this.onClose = this.onClose.bind(this);
    }

    onClose() {
        const { field, onChange } = this.props;
        if (!field.allowUndefinedOption) {
            return;
        }
        const option = field.options.find(option => option.label === this.value);
        if (option) {
            onChange(field.id, option);
        } else {
            onChange(field.id, { value: this.value, label: this.value, undefinedOption: true });
        }
    }

    renderDescription() {
        const { field, classes } = this.props;
        if (!field.errorMessages && !field.description) {
            return null;
        }
        return (
            <Typography className={field.isValid ? classes.description : classes.descriptionError}>
                {field.errorMessages || field.description}
            </Typography>
        );
    }

    render() {
        const { field, onChange, classes } = this.props;
        return (
            <div className={`${classes.container} ${field.isValid ? '' : 'error'}`}>
                <Autocomplete
                    id={field.id}
                    options={('DYNAMIC' === field.options ? [] : field.options) || []}
                    getOptionLabel={option => (null != option.label ? option.label : option)}
                    onChange={(event, value) => onChange(field.id, value)}
                    onInputChange={(event, value) => field.allowUndefinedOption && (this.value = value)}
                    onClose={this.onClose}
                    renderInput={params => (
                        <TextField {...params} label={field.label} placeholder={field.placeholder} fullWidth />
                    )}
                    value={field.value || { label: '' }}
                    freeSolo={field.allowUndefinedOption}
                />
                {this.renderDescription()}
            </div>
        );
    }
}

SingleAutocomplete.propTypes = {
    field: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(singleAutocompleteStyles)(SingleAutocomplete);
