// import api from './Api';

import api from '../collums-components/api/config';

class EquipmentApi {
    static query(skip = 0, limit = 15, queryParams = '') {
        return api.request({
            method: 'GET',
            url: `/admin-equipment?skip=${skip}&limit=${limit}${queryParams ? `&${queryParams}` : ''}`
        });
    }

    static listAll(clinic) {
        return api.request({
            method: 'GET',
            url: `/admin-equipment/list-all${clinic ? `?clinic=${clinic}` : ''}`
        });
    }

    static addEquipment(data) {
        return api.request({
            method: 'POST',
            url: '/equipment',
            data
        });
    }

    static editEquipment(id, data) {
        return api.request({
            method: 'POST',
            url: `/equipment/${id}`,
            data
        });
    }

    static deleteEquipment(id) {
        return api.request({
            method: 'DELETE',
            url: `/equipment/${id}`
        });
    }

    static archiveEquipment(id) {
        return api.request({
            method: 'GET',
            url: `/equipment-archive/${id}`
        });
    }

    static changeEquipmentActive(id) {
        return api.request({
            method: 'PUT',
            url: `/equipment/${id}/change-active`
        });
    }
}

export default EquipmentApi;
