const notificationsStyle = () => ({
    accordions: {
        '& .MuiExpansionPanelSummary-content': {
            '& p': {
                fontSize: 16,
                fontWeight: 400
            }
        }
    },
    centerText: {
        textAlign: 'center',
        marginTop: 30
    },
    marginLeft8px: {
        marginLeft: 8
    },
    marginTopLabels: {
        marginTop: 8
    },
    blueBtn: {
        height: 36,
        margin: '0px 10px 0px 0px',
        padding: '6px 14px',
        borderRadius: 0,
        color: '#ffffff',
        backgroundColor: '#3083EC',
        border: 'none',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, .2)'
        }
    },
    options: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 4,
        marginLeft: 40,
        marginRight: 40,
        '& input': {
            height: 'inherit',
            boxSizing: 'border-box'
        }
    },
    showArchived: {
        display: 'flex',
        alignItems: 'center'
    },
    inputField: {
        margin: '0.6rem 0'
    },
    tagsContainer: {
        marginTop: 8,
        display: 'flex',
        flexFlow: 'row wrap',
        gap: '4px'
    },
    categoriesLabel: {
        marginTop: 115,
        marginLeft: 10
    },
    categoriesEditLabel: {
        marginBottom: 10,
        marginLeft: 10
    },
    tagChipsContainer: {
        width: '50%',
        display: 'flex',
        flexFlow: 'row wrap',
        gap: '4px'
    },
    autocomplete: {
        '& .MuiInputBase-root': {
            padding: 2
        }
    },
    autocompleteOptions: {
        fontSize: 14,
        fontWeight: 400,
        padding: 0,
        margin: 0.5
    },
    autocompleteInputProps: {
        fontSize: 14,
        fontWeight: 400
    },
    autocompleteLabelProps: {
        fontSize: 14,
        fontWeight: 400
    },
    textBlueBtn: {
        height: 36,
        margin: '0px 10px 0px 0px',
        padding: '6px 14px',
        borderRadius: 0,
        color: '#3083EC',
        border: 'none',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, .2)'
        }
    },
    testContainer: {
        display: 'flex',
        marginTop: 10,
        alignItems: 'center'
    },
    reminderContainer: {
        display: 'flex',
        marginTop: 10,
        gap: '4px',
        alignItems: 'center'
    },
    selectInputProps: {
        display: 'flex',
        alignItems: 'center',
        height: 0,
        boxSizing: 'border-box'
    },
    input: {
        '& input::placeholder': {
            fontWeight: 400,
            fontSize: '14px !important',
            color: '#333 !important',
            fontFamily: 'Roboto, Arial, "sans-serif" !important'
        }
    },
    defaultLabelWidth: {
        minWidth: 65
    }
});
export default notificationsStyle;
