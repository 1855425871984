import api from '../collums-components/api/config';
import querystring from 'querystring';
class TemplatesApi {
    static getTemplates(filter) {
        return api.request({
            method: 'GET',
            url: `/templates?${querystring.stringify(filter)}`
        });
    }
}

export default TemplatesApi;
