import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';

import App from './components/App';
import Routes from './Routes';
import theme from './theme';
import * as serviceWorker from './serviceWorker';
import './styles/index.css';
import ReduxToastr from 'react-redux-toastr';
import { Provider } from 'react-redux';
import configureStore from './redux/configureStore';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment-timezone';
import DateFnsUtils from '@date-io/date-fns';
import { AccessTierProvider } from './collums-components/hooks/accessTier';
import { initSentry, setSentryUser } from './collums-components/helpers/sentry';
import * as Sentry from '@sentry/react';
import AuthApi from './collums-components/api/AuthApi';
import GenericErrorBoundaryFallback from './collums-components/components/common/GenericErrorBoundaryFallback';

initSentry();
moment.tz.setDefault(process.env.REACT_APP_TIMEZONE || 'Europe/London');

const Index = () => {
    const [sentryDialogOptions, setSentryDialogOptions] = useState({});

    useEffect(() => {
        (async () => {
            const currentUser = await AuthApi.getMe();
            const dialogOpts = setSentryUser(currentUser);
            setSentryDialogOptions(dialogOpts);
        })();
    }, []);

    return (
        <Provider store={configureStore()}>
            <CssBaseline />
            <MuiThemeProvider theme={theme}>
                <Sentry.ErrorBoundary
                    fallback={<GenericErrorBoundaryFallback />}
                    showDialog
                    dialogOptions={sentryDialogOptions}
                >
                    <BrowserRouter>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <AccessTierProvider>
                                <App>
                                    <ReduxToastr
                                        timeOut={3000}
                                        newestOnTop={false}
                                        preventDuplicates
                                        position="bottom-left"
                                        getState={state => state.toastr}
                                        transitionIn="fadeIn"
                                        transitionOut="fadeOut"
                                        progressBar
                                        closeOnToastrClick
                                    />
                                    <Routes />
                                </App>
                            </AccessTierProvider>
                        </MuiPickersUtilsProvider>
                    </BrowserRouter>
                </Sentry.ErrorBoundary>
            </MuiThemeProvider>
        </Provider>
    );
};

ReactDOM.render(<Index />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
