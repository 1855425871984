import React, { useRef } from 'react';

import { FormControl, TextField, withStyles } from '@material-ui/core';

import Modal from '../../collums-components/components/common/Modal';

import { commissionModalStyles } from './styles';
import { toastr } from 'react-redux-toastr';
import isObject from 'lodash/isObject';

import cloneDeep from 'lodash/cloneDeep';
import v4 from 'uuid/v4';
import isInvalidCommission from '../../services/isInvalidCommission';
import CommissionsScheme from '../common/CommissionsScheme';

function CommissionsModal({ selectedCommission, classes, closeModal, handleSubmit, serviceCategories }) {
    const createDefaultCommission = () => ({ allowFlatRate: false, id: v4() });

    const defaultCommission = {
        commissions: {
            course: [createDefaultCommission()],
            product: [createDefaultCommission()],
            service: [createDefaultCommission()],
            total: [createDefaultCommission()]
        }
    };

    const commissionFormRef = useRef(isObject(selectedCommission) ? selectedCommission : defaultCommission);

    return (
        <Modal
            size={'md'}
            isOpen
            disableBackdropClick={false}
            onClose={() => closeModal()}
            className={classes.modal}
            onCancel={() => closeModal()}
            onConfirm={() => {
                const commissionObjects = Object.values(commissionFormRef.current.commissions);
                if (!(commissionFormRef.current.name || '').trim().length) {
                    toastr.error('Missing field name');
                }
                if (commissionObjects.some(isInvalidCommission)) return;
                const formData = cloneDeep(commissionFormRef.current);
                Object.keys(formData.commissions).forEach(key => {
                    formData.commissions[key] = formData.commissions[key].map(item => {
                        if (item.allCategories) {
                            item.categories = [];
                        } else {
                            item.categories = (item.categories || []).map(el => el.id).filter(el => el);
                        }
                        return item;
                    });
                });
                handleSubmit(formData, selectedCommission);
            }}
            title={selectedCommission?.name ? `Edit ${selectedCommission.name} commission` : 'New commission'}
        >
            <form className={classes.accordionsForm}>
                <div className={classes.formContent}>
                    <div className={classes.formContentRow}>
                        <FormControl className={classes.formTextField}>
                            <TextField
                                variant="outlined"
                                label="Name"
                                defaultValue={selectedCommission.name || ''}
                                onChange={event => {
                                    commissionFormRef.current.name = event.target.value;
                                }}
                                required
                            />
                        </FormControl>
                    </div>
                    <div className={classes.formContentRow}>
                        <FormControl className={classes.formTextField}>
                            <TextField
                                onChange={event => {
                                    commissionFormRef.current.description = event.target.value;
                                }}
                                variant="outlined"
                                label="Description"
                                defaultValue={selectedCommission.description || ''}
                            />
                        </FormControl>
                    </div>
                    <CommissionsScheme
                        commissionFormRef={commissionFormRef}
                        selectedCommission={selectedCommission}
                        serviceCategories={serviceCategories}
                    />
                </div>
            </form>
        </Modal>
    );
}
export default withStyles(commissionModalStyles)(CommissionsModal);
