import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles, Checkbox, FormControlLabel } from '@material-ui/core';

import { productCategoryDetailsModalStyles } from '../styles';
import { getLocationItem } from '../../../collums-constants/utils';

function ProductStockTab({ register, category, clinic, isFromOrg, classes }) {
    const [autoStock, setAutoStock] = useState(
        category && typeof category.autoStockControl === 'boolean'
            ? isFromOrg
                ? category.autoStockControl
                : getLocationItem(category, clinic)?.autoStockControl
            : false
    );
    const [negative, setNegative] = useState(
        category && category.allowNegative
            ? isFromOrg
                ? category.allowNegative
                : getLocationItem(category, clinic)?.allowNegative
            : false
    );

    return (
        <div className={classes.formContent}>
            <FormControlLabel
                label="Allow stock control"
                control={
                    <Checkbox
                        checked={autoStock}
                        inputProps={{ name: 'autoStockControl' }}
                        onChange={(event, value) => {
                            setAutoStock(value);
                            if (!value) setNegative(value);
                        }}
                        inputRef={register}
                    />
                }
            />
            <FormControlLabel
                label="Allow negative stock"
                control={
                    <Checkbox
                        disabled={!autoStock}
                        checked={negative}
                        inputProps={{ name: 'allowNegative' }}
                        onChange={(event, value) => {
                            setNegative(value);
                        }}
                        inputRef={register}
                    />
                }
            />
        </div>
    );
}

ProductStockTab.propTypes = {
    classes: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    category: PropTypes.object
};

export default withStyles(productCategoryDetailsModalStyles)(ProductStockTab);
