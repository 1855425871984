import React, { useState } from 'react';
import { TextField, withStyles, Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { generalTabStyles as styles } from './styles';
import formStyles from '../../../../collums-constants/styles/stylesheets/formStyles';
import { useForms } from '../../../../collums-components/hooks/forms';
import { Autocomplete } from '@material-ui/lab';
import { ORGANISATION_FORM_TYPES } from '../../../../collums-constants';
import { modalsButtonStyles } from '../../../../collums-constants/styles/stylesheets/buttonsStyles';
import Modal from '../Modal';

const GeneralTab = ({ classes, setFormType }) => {
    const { data, setData } = useForms();
    const inputStyles = makeStyles(formStyles)();
    const [modalAllTreatments, setModalAllTreatments] = useState({
        show: false,
        type: data.isForAllTreatments ? 'unchecked' : 'checked'
    });
    const globalStyles = makeStyles(modalsButtonStyles)();

    const backAllTreatments = () => {
        setData({
            ...data,
            isForAllTreatments: !data.isForAllTreatments
        });
        setModalAllTreatments({ ...modalAllTreatments, show: false });
    };

    const closeAllTreatments = () => {
        setModalAllTreatments({ ...modalAllTreatments, show: false });
    };

    return (
        <>
            <Modal
                isOpen={modalAllTreatments.show}
                title={'Warning'}
                onConfirm={modalAllTreatments.type === 'checked' ? closeAllTreatments : false}
                confirmLabel={modalAllTreatments.type === 'checked' ? 'Add to ALL treatments' : ''}
                confirmClass={globalStyles.confirmButton}
                onBack={modalAllTreatments.type === 'checked' ? backAllTreatments : closeAllTreatments}
                backLabel={modalAllTreatments.type === 'checked' ? 'Back' : 'Close'}
                backClass={globalStyles.cancelButton}
                style={{ position: 'fixed', zIndex: 10 }}
                modalContainerStyle={{ maxWidth: '550px', background: 'white' }}
            >
                <span style={{ textAlign: 'center' }}>
                    {modalAllTreatments.type === 'checked' ? (
                        <p>
                            This action will add this form to ALL treatments.
                            <br /> Are you sure you want to continue?
                        </p>
                    ) : (
                        <p>
                            Unchecking this box will NOT remove this form from all treatments.
                            <br /> Please do this manually.
                        </p>
                    )}
                </span>
            </Modal>
            <div className={classes.general}>
                <div>
                    <div>
                        <TextField
                            value={data.name}
                            onChange={({ target }) => setData({ ...data, name: target.value })}
                            className={classes.formItem}
                            variant="outlined"
                            label="Name"
                            required
                        />
                        <TextField
                            value={data.description}
                            label="Description"
                            onChange={({ target }) => setData({ ...data, description: target.value })}
                            className={classes.formItem}
                            variant="outlined"
                        />
                    </div>
                </div>
                <div>
                    <div className={classes.flex}>
                        <Autocomplete
                            id={'form-type'}
                            options={Object.values(ORGANISATION_FORM_TYPES)}
                            getOptionLabel={option => option}
                            value={data.type}
                            disabled={Boolean(data.id)}
                            onChange={(_, value) => {
                                setFormType(value);
                                setData({ ...data, type: value });
                            }}
                            className={`${inputStyles.autocomplete} ${classes.formItem}`}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    className={inputStyles.autocompleteTextField}
                                    label="Type"
                                    variant="outlined"
                                    required
                                />
                            )}
                        />
                    </div>
                </div>
                {!data.isMasterForm && (
                    <div>
                        <FormControlLabel
                            label="For all treatments"
                            control={
                                <Checkbox
                                    checked={data.isForAllTreatments}
                                    onChange={() => {
                                        setData({
                                            ...data,
                                            isForAllTreatments: !data.isForAllTreatments
                                        });
                                        setModalAllTreatments({
                                            show: true,
                                            type: data.isForAllTreatments ? 'unchecked' : 'checked'
                                        });
                                    }}
                                />
                            }
                        />
                    </div>
                )}
                <div>
                    <FormControlLabel
                        label="Allow send by email"
                        control={
                            <Checkbox
                                checked={data.allowEmailSend}
                                onChange={() =>
                                    setData({
                                        ...data,
                                        allowEmailSend: !data.allowEmailSend
                                    })
                                }
                            />
                        }
                    />
                </div>
            </div>
        </>
    );
};

export default withStyles(styles)(GeneralTab);
