import {
    makeStyles,
    Checkbox,
    List,
    ListItem,
    Divider,
    ListItemIcon,
    ListItemText,
    FormControlLabel,
    Typography,
    Tooltip,
    Button
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { serviceDetailsModalStyles } from '../styles';
import LocationSettingModal from '../LocationSettitngModal';
import theme from '../../../theme';
import HelpIcon from '@material-ui/icons/Help';

function LocationTab({
    isFromOrg,
    clinics,
    register,
    control,
    setValue,
    getValues,
    currentService,
    allClinics,
    inlineLocationSettings,
    setInlineLocationSettings,
    getMainFormValues
}) {
    const classes = makeStyles(serviceDetailsModalStyles)(theme);
    const [filteredClinics, setFilteredClinics] = useState(clinics);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentSettingLocation, setCurrentSettingLocation] = useState({});
    const filterAvailableLocations = () => {
        setFilteredClinics(allClinics);
    };

    useEffect(() => {
        filterAvailableLocations();
    }, []); // eslint-disable-line

    const listOptions = useMemo(() => {
        return (filteredClinics || []).map(clinic => {
            const isSelected = (currentService?.locations || []).some(el => el.clinic === clinic.id);

            return {
                value: clinic.id,
                label: clinic.accountName,
                subtitle: undefined,
                isSelected: filteredClinics.length === 1 || isSelected
            };
        });
    }, [filteredClinics, currentService]);

    return (
        <div className={classes.formContent}>
            <div className={classes.formContentRow} style={{ display: isFromOrg ? '' : 'none' }}>
                {listOptions && listOptions.length > 0 ? (
                    <>
                        <div className={classes.locationsTitle}>Select the locations that can book this service.</div>
                        <Controller
                            name={'locations'}
                            control={control}
                            defaultValue={listOptions}
                            rules={register}
                            render={({ onChange, value }) => {
                                //// ---- fix issue available locs from category (listOptions ok, value not changing);

                                // value = listOptions;

                                const checkAll = (e, checked) => {
                                    const newItems = [
                                        ...value.map(item => {
                                            item.isSelected = checked;
                                            return item;
                                        })
                                    ];
                                    onChange(newItems);
                                };

                                const handleToggle = async option => {
                                    const currentIndex = (value || []).findIndex(v => v.value === option.value);
                                    if (!value[currentIndex].isSelected) {
                                        setCurrentSettingLocation(option);
                                        setIsModalVisible(true);
                                    }
                                    const newItems = [...value];
                                    const newItem = {
                                        ...value[currentIndex],
                                        isSelected: !value[currentIndex].isSelected
                                    };

                                    newItems.splice(currentIndex, 1, newItem);

                                    const values = getValues();
                                    const room_equip = values.room_equipment;

                                    const activeItems = newItems.filter(location => {
                                        return location.isSelected;
                                    });

                                    if (room_equip !== undefined) {
                                        room_equip.clinics = room_equip.clinics.filter(clinic => {
                                            return activeItems.find(el => el.label === clinic);
                                        });
                                        await setValue('room_equipment', room_equip);
                                    }
                                    onChange(newItems);
                                };

                                // Removing temporarily location prices
                                /* const getDefaultValue = option => {
                                        if (currentService.locations) {
                                            return currentService.locations.find(
                                                location => option.value === location.clinic
                                            );
                                        } else {
                                            return undefined;
                                        }
                                    }; */

                                let newValue = value;
                                if (!currentService.id) {
                                    newValue = newValue.map(option => ({ ...option, isSelected: false }));
                                    newValue = value.filter(
                                        option => !!filteredClinics.map(loc => loc.id).includes(option.value)
                                    );
                                    if (newValue.length === 1) {
                                        newValue[0].isSelected = true;
                                    }
                                }
                                return (
                                    <>
                                        {isFromOrg && newValue.length > 1 && (
                                            <div className={classes.locationFilter} style={{ alignItems: 'center' }}>
                                                <FormControlLabel
                                                    className={classes.selectAllLabel}
                                                    control={
                                                        <Checkbox
                                                            onChange={checkAll}
                                                            edge="start"
                                                            tabIndex={-1}
                                                            disableRipple
                                                            // checked={locations?.length && locations.filter(item => item.isSelected).length === locations.length}
                                                        />
                                                    }
                                                    label="Select all and configure later"
                                                />
                                                <Tooltip
                                                    placement="right"
                                                    style={{ marginTop: '1px' }}
                                                    title={
                                                        <Typography style={{ fontSize: '14px', display: 'grid' }}>
                                                            <span>
                                                                If you select all, you will need to configure this
                                                                service (rooms, staff, equipment etc) later either at
                                                                the location level or by clicking the configure buttons
                                                                below.
                                                            </span>
                                                        </Typography>
                                                    }
                                                    aria-label="help"
                                                >
                                                    <HelpIcon className={classes.helpIcon} />
                                                </Tooltip>
                                            </div>
                                        )}
                                        <List>
                                            {!newValue.length && 'Loading...'}
                                            {newValue.map((option, index) => {
                                                return (
                                                    <>
                                                        <ListItem
                                                            key={index}
                                                            role={undefined}
                                                            dense
                                                            button
                                                            // disabled={filteredClinics.length === 1}
                                                        >
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    onClick={() => handleToggle(option)}
                                                                    edge="start"
                                                                    checked={option.isSelected}
                                                                    tabIndex={-1}
                                                                    disableRipple
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={option.label}
                                                                secondary={option.subtitle}
                                                            />
                                                            {option.isSelected && (
                                                                <Button
                                                                    edge="start"
                                                                    aria-label="edit"
                                                                    onClick={() => {
                                                                        setCurrentSettingLocation(option);
                                                                        setIsModalVisible(true);
                                                                    }}
                                                                    className={classes.configureButton}
                                                                >
                                                                    Configure
                                                                </Button>
                                                            )}
                                                            {/* {option.isSelected && (
                                                            <PricingInputs
                                                                getValues={getValues}
                                                                watch={watch}
                                                                fieldName={'locations'}
                                                                value={value}
                                                                setValue={setValue}
                                                                currentIndex={index}
                                                                control={control}
                                                                register={register}
                                                                defaultValue={getDefaultValue(option)}
                                                            />
                                                        )} */}
                                                        </ListItem>
                                                        <Divider />
                                                    </>
                                                );
                                            })}
                                        </List>
                                    </>
                                );
                            }}
                        />
                    </>
                ) : (
                    <Typography>There are no locations that can be assigned.</Typography>
                )}
            </div>
            {isModalVisible && (
                <LocationSettingModal
                    isVisible={isModalVisible}
                    currentSettingLocation={currentSettingLocation}
                    closeModal={() => {
                        setIsModalVisible(false);
                    }}
                    inlineLocationSettings={inlineLocationSettings}
                    setInlineLocationSettings={setInlineLocationSettings}
                    getMainFormValues={getMainFormValues}
                    currentService={currentService}
                />
            )}
        </div>
    );
}

LocationTab.propTypes = {
    register: PropTypes.func.isRequired,
    control: PropTypes.any,
    watch: PropTypes.any,
    setValue: PropTypes.any,
    getValues: PropTypes.any,
    clinics: PropTypes.array,
    onChange: PropTypes.any,
    allClinics: PropTypes.array
};

export default LocationTab;
