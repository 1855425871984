import React from 'react';
import { withStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';

import { listTableStyles } from './styles';

const ListTable = props => {
    const defaultExpanded = props.defaultExpanded || false;
    const rowStyle = props.rowStyle;
    const customComponents = props.customComponents || {};
    return (
        <MaterialTable
            data={props.data}
            columns={props.columns}
            icons={{
                Add: Add
            }}
            options={{
                addRowPosition: 'first',
                draggable: false,
                paging: false,
                sorting: false,
                actionsColumnIndex: -1,
                showTitle: false,
                search: false,
                add: false,
                defaultExpanded,
                rowStyle
            }}
            parentChildData={props.parentChildData}
            components={{
                ...customComponents,
                Container: containerProps => (
                    <div className={`${props.classes.table} ${props.tableClasses}`}>{containerProps.children}</div>
                ),
                Action: () => <></>,
                Toolbar: () => <></>
            }}
        />
    );
};

ListTable.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    tableClasses: PropTypes.string,
    parentChildData: PropTypes.func,
    onView: PropTypes.func,
    onAdd: PropTypes.func,
    onEdit: PropTypes.func,
    onRemove: PropTypes.func,
    actions: PropTypes.array,
    classes: PropTypes.object.isRequired,
    defaultExpanded: PropTypes.bool
};

export default withStyles(listTableStyles)(ListTable);
