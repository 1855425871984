import React from 'react';

import { Paper, withStyles, Checkbox, FormControlLabel } from '@material-ui/core';
import { TreeItem, TreeView } from '@material-ui/lab';

import styles from './styles';

function CheckboxTree({ classes, parentTree = [], selectedItems, parentClick, childClick, notFromOrg }) {
    const getIsParentSelected = parent => {
        return parent.child.some(el => selectedItems.includes(el.id));
    };
    return (
        <Paper className={classes.listRoot}>
            <TreeView>
                {parentTree.map(parent => (
                    <TreeItem
                        key={parent.id}
                        nodeId={parent.id}
                        label={
                            <div className={classes.treeLabel}>
                                <FormControlLabel
                                    disabled={notFromOrg}
                                    onClick={e => parentClick(e, parent)}
                                    label={parent.name}
                                    control={<Checkbox defaultChecked={getIsParentSelected(parent)} />}
                                />
                            </div>
                        }
                    >
                        {(parent.child || []).map(child => (
                            <TreeItem
                                key={child.id}
                                nodeId={child.id}
                                onClick={e => childClick(e, child.id)}
                                label={
                                    <div style={{ display: 'flex' }}>
                                        <FormControlLabel
                                            disabled={notFromOrg}
                                            label={child.name}
                                            control={<Checkbox checked={selectedItems.includes(child.id)} />}
                                        />
                                    </div>
                                }
                                className={classes.treeItems}
                            />
                        ))}
                    </TreeItem>
                ))}
            </TreeView>
        </Paper>
    );
}

export default withStyles(styles)(CheckboxTree);
