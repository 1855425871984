import { FormControl, InputLabel, makeStyles, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { categoryDetailsModalStyles } from '../styles';
import TagsInput from '../../../collums-components/form/TagsInput';
import ColorPicker from '../../common/ColorPicker';

function GeneralTab({
    register,
    isFromOrg,
    categories,
    category,
    setValue,
    searchCategories,
    parent,
    setParent,
    control,
    allClinics
}) {
    const classes = makeStyles(categoryDetailsModalStyles)();
    //  const isRequired = category.id !== undefined ? { name: false, active: false } : { name: true, active: true };

    useEffect(() => {
        register({ name: 'parentId' });
        /* eslint-disable-next-line */
    }, []);

    return (
        <div className={classes.formContent}>
            <div className={classes.formContentRow}>
                <div style={{ display: 'flex', width: '100%' }}>
                    <FormControl className={classes.formItem} variant="outlined">
                        <InputLabel shrink>Name *</InputLabel>
                        <TextField
                            inputProps={{ readOnly: !isFromOrg && allClinics.length !== 1 }}
                            inputRef={register({ required: 'Name' })}
                            name="name"
                            variant="outlined"
                            defaultValue={category.name || ''}
                        />
                    </FormControl>

                    <FormControl className={classes.formItem} variant="outlined">
                        <InputLabel shrink>Description</InputLabel>
                        <TextField
                            inputProps={{ readOnly: !isFromOrg && allClinics.length !== 1 }}
                            multiline="true"
                            inputRef={register}
                            rowsMax={3}
                            name="description"
                            variant="outlined"
                            defaultValue={category.description}
                        />
                    </FormControl>
                </div>

                <div className={classes.formContentRow} style={{ display: 'flex', width: '100%' }}>
                    <div className={classes.formItem}></div>
                    <Autocomplete
                        disabled={!isFromOrg && allClinics.length !== 1}
                        style={{ width: '48%' }}
                        options={categories}
                        value={
                            category.parentCategoryObj && category.parentCategoryObj[0]
                                ? category.parentCategoryObj[0]
                                : parent
                        }
                        getOptionLabel={option => option.name}
                        onChange={async (e, value) => {
                            setValue('parentId', value !== null ? value.id : undefined);
                            setParent(value);
                        }}
                        className={`${classes.autocomplete} ${classes.formItem}`}
                        renderInput={params => (
                            <>
                                <InputLabel shrink style={{ marginBottom: 7, marginLeft: 10 }}>
                                    Parent Category
                                </InputLabel>
                                <TextField
                                    {...params}
                                    onChange={(e, value) => searchCategories(value)}
                                    variant="outlined"
                                    className={classes.autocompleteTextField}
                                />
                            </>
                        )}
                    />
                </div>

                <div className={classes.formContentRow}>
                    <FormControl className={classes.formItem}>
                        <ColorPicker
                            disabled={!isFromOrg && allClinics.length !== 1}
                            inputRef={register}
                            name="color"
                            defaultValue={category.color}
                        />
                    </FormControl>
                </div>
                <div className={classes.formContentRow}>
                    <TagsInput
                        disabled={!isFromOrg && allClinics.length !== 1}
                        name="tags"
                        control={control}
                        className={classes.formItem}
                        canAdd
                        defaultValue={category.tags || []}
                    />
                </div>
            </div>
        </div>
    );
}

GeneralTab.propTypes = {
    register: PropTypes.func.isRequired,
    categories: PropTypes.array,
    control: PropTypes.any,
    watch: PropTypes.any
};

export default GeneralTab;
