import React, { useEffect, useState } from 'react';

import TagsInput from '../../../collums-components/form/TagsInput';

import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputAdornment,
    InputLabel,
    Link,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import { Controller } from 'react-hook-form';

import { CURRENCY_TYPES, PROFESSIONAL_BODIES } from '../../../collums-constants';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { serviceDetailsModalStyles } from '../../services/styles';
import HelpIcon from '@material-ui/icons/Help';
import OrganisationsApi from '../../../api/OrganisationsApi';

export default function ProfessionalTab({
    register,
    control,
    setPerformTreatments,
    performTreatments,
    practitioner,
    clinicsOpt,
    setValue,
    selectAllCategories,
    isSoloPractitioner,
    setIsSoloPractitioner,
    jobOpts,
    watch,
    isFromOrg,
    clinicsSettings,
    setClinicsSettings
}) {
    const classes = makeStyles(serviceDetailsModalStyles)();
    const selectedClinics = clinicsOpt || watch('locations');

    const [salary, setSalary] = useState((practitioner || {}).salary || 0);
    const [hourlyRate, setHourlyRate] = useState((practitioner || {}).hourlyRate || 0);

    const [isFullTime, setFullTime] = useState(
        (practitioner || {}).isFullTime !== undefined ? practitioner.isFullTime : true
    );
    const [isPartTime, setPartTime] = useState((practitioner || {}).isPartTime || false);

    const [currencySymbol, setCurrencySymbol] = useState('£');

    const [organisationData, setOrganisationData] = useState(null);

    useEffect(() => {
        (async () => {
            const organisation = await OrganisationsApi.getOrganisation();
            setOrganisationData(organisation);
        })();
    }, []);

    useEffect(() => {
        if (organisationData?.currency) {
            const currency = CURRENCY_TYPES.find(type => type.NAME === organisationData.currency);
            if (currency) {
                setCurrencySymbol(currency.SYMBOL);
            }
        }
    }, [organisationData]);

    useEffect(() => {
        register({ name: 'clinics', required: true });
        register({ name: 'salary' });
        register({ name: 'hourlyRate' });
        register({ name: 'isPartTime' });
        register({ name: 'isFullTime' });
        register({ name: 'performTreatments' });
        register({ name: 'isSoloPractitioner' });
        /*eslint-disable-next-line */
    }, []);

    useEffect(() => {
        // select clinic if its only one available
        if (clinicsOpt.length === 1 && !isFromOrg) {
            // maybe there is one clinic already selected from useEffect on editing practitioner
            if (selectedClinics.length === 0) {
                setValue('clinics', [clinicsOpt[0].accountName]);
            }
        }
        /*eslint-disable-next-line */
    }, [clinicsOpt, selectedClinics]);

    useEffect(() => {
        setValue('isFullTime', isFullTime);
        /*eslint-disable-next-line */
    }, [isFullTime]);

    useEffect(() => {
        setValue('isPartTime', isPartTime);
        /*eslint-disable-next-line */
    }, [isPartTime]);

    useEffect(() => {
        setValue('performTreatments', performTreatments);
        /*eslint-disable-next-line */
    }, [performTreatments]);

    useEffect(() => {
        setValue('isSoloPractitioner', isSoloPractitioner);
        /*eslint-disable-next-line */
    }, [isSoloPractitioner]);

    useEffect(() => {
        setValue('salary', salary);
        /*eslint-disable-next-line */
    }, [salary]);

    useEffect(() => {
        setValue('hourlyRate', hourlyRate);
        /*eslint-disable-next-line */
    }, [hourlyRate]);

    const changeTimeByFullTime = newValue => {
        setPartTime(!newValue);
    };

    const changeTimeByPartTime = newValue => {
        setFullTime(!newValue);
    };

    return (
        <div className={classes.formContent}>
            {/* {isFromOrg && ( */}
            <div className={`${classes.formContentRow} ${classes.jobRow}`}>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel required>Job</InputLabel>
                    <Controller
                        name="job"
                        rules={{ required: true }}
                        control={control}
                        as={
                            <Select
                                inputProps={{
                                    className: classes.selectInputProps
                                }}
                            >
                                <MenuItem value={''}>
                                    <em>None</em>
                                </MenuItem>
                                {jobOpts.map(job => {
                                    return (
                                        <MenuItem value={job.id} key={job.id}>
                                            {job.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        }
                        defaultValue={(practitioner || {}).job ? practitioner.job.id : ''}
                    />
                </FormControl>
                <Tooltip
                    title={
                        <Typography style={{ fontSize: 14 }}>
                            The job you assign will dictate which modules this staff member has access to. If user
                            permissions are not configured, staff with that job will be able to access the Calendar
                            only.
                        </Typography>
                    }
                    aria-label="help"
                >
                    <HelpIcon />
                </Tooltip>
                <Link
                    className={classes.jobLink}
                    style={{ marginLeft: 15 }}
                    onClick={() => {
                        window.open(`${process.env.REACT_APP_ADMIN_URL}/organisation/job`, '_blank');
                    }}
                >
                    Manage/create jobs
                </Link>
            </div>
            {/* )} */}
            {(!isFromOrg || clinicsOpt.length === 1 || !practitioner.id) && (
                <div className={classes.formContentRow}>
                    <span hidden>
                        <FormControlLabel
                            className={classes.formItem}
                            checked={true}
                            control={
                                <Checkbox
                                    disabled={!!clinicsSettings[clinicsOpt[0]?.id]?.isSoloPractitioner}
                                    checked={true}
                                    onChange={() => {
                                        setPerformTreatments(!performTreatments);
                                        setIsSoloPractitioner(false);
                                        if (clinicsSettings[clinicsOpt[0]?.id]) {
                                            clinicsSettings[clinicsOpt[0]?.id].isPerformTreatments = !performTreatments;
                                        }
                                        setClinicsSettings(clinicsSettings);
                                    }}
                                />
                            }
                            label={'Show in calendar'}
                        />
                    </span>
                    {(!isFromOrg || clinicsOpt.length === 1 || practitioner.id) && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    // disabled={!performTreatments}
                                    checked={isSoloPractitioner}
                                    onChange={() => {
                                        setIsSoloPractitioner(!isSoloPractitioner);
                                        selectAllCategories(!isSoloPractitioner);
                                        if (clinicsSettings[clinicsOpt[0]?.id]) {
                                            clinicsSettings[clinicsOpt[0]?.id].isSoloPractitioner = !isSoloPractitioner;
                                        }
                                        setClinicsSettings(clinicsSettings);
                                    }}
                                />
                            }
                            className={classes.formItem}
                            style={{ marginLeft: 2 }}
                            label={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography>Solo practitioner</Typography>
                                    <Tooltip
                                        title={
                                            <Typography style={{ fontSize: 14 }}>
                                                Check this box if this staff member is the only one in the clinic to
                                                perform treatments./ When this is the case, you will not need to set up
                                                schedules or service rules for this practitioner – they will be
                                                available for booking at all times and will be able to perform all
                                                services.
                                            </Typography>
                                        }
                                        aria-label="help"
                                    >
                                        <HelpIcon />
                                    </Tooltip>
                                </div>
                            }
                        />
                    )}
                </div>
            )}
            <div className={classes.formContentRow}>
                <TagsInput
                    disabled={!isFromOrg && clinicsOpt.length > 1}
                    name="jobTags"
                    control={control}
                    className={classes.formItem}
                    defaultValue={(practitioner || {}).jobTags || []}
                />
            </div>
            <div className={classes.formContentRow} style={{ marginTop: 8 }}>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel>Professional body</InputLabel>
                    <Controller
                        defaultValue=""
                        name="professionalBody"
                        control={control}
                        as={
                            <Select
                                disabled={!isFromOrg && clinicsOpt.length > 1}
                                inputProps={{
                                    className: classes.selectInputProps
                                }}
                            >
                                <MenuItem value={undefined}>
                                    <em>None</em>
                                </MenuItem>
                                {Object.values(PROFESSIONAL_BODIES)
                                    .filter(el => el !== PROFESSIONAL_BODIES.NONE)
                                    .map(professionalBody => (
                                        <MenuItem key={professionalBody} value={professionalBody}>
                                            {professionalBody}
                                        </MenuItem>
                                    ))}
                            </Select>
                        }
                    />
                </FormControl>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Professional reg no</InputLabel>
                    <TextField
                        inputProps={{
                            readOnly: !isFromOrg && clinicsOpt.length > 1
                        }}
                        name="professionalRegCode"
                        variant="outlined"
                        inputRef={register}
                        defaultValue={practitioner?.professionalRegCode || ''}
                    />
                </FormControl>

                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Date for renewal</InputLabel>
                    <Controller
                        defaultValue={null}
                        name="dateForRenewal"
                        control={control}
                        as={
                            <KeyboardDatePicker
                                disabled={!isFromOrg && clinicsOpt.length > 1}
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                TextFieldComponent={props => <TextField {...props} variant="outlined" />}
                            />
                        }
                    />
                </FormControl>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Employment start date</InputLabel>
                    <Controller
                        defaultValue={null}
                        name="employmentStartDate"
                        control={control}
                        as={
                            <KeyboardDatePicker
                                disabled={!isFromOrg && clinicsOpt.length > 1}
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                TextFieldComponent={props => <TextField {...props} variant="outlined" />}
                                InputProps={{
                                    className: classes.inputDatepicker
                                }}
                            />
                        }
                    />
                </FormControl>
                <FormControlLabel
                    label="Full time"
                    control={
                        <Checkbox
                            disabled={!isFromOrg && clinicsOpt.length > 1}
                            checked={isFullTime}
                            onChange={() => {
                                changeTimeByFullTime(!isFullTime);
                                setFullTime(!isFullTime);
                            }}
                        />
                    }
                />
                <FormControlLabel
                    label="Part time"
                    control={
                        <Checkbox
                            disabled={!isFromOrg && clinicsOpt.length > 1}
                            checked={isPartTime}
                            onChange={() => {
                                changeTimeByPartTime(!isPartTime);
                                setPartTime(!isPartTime);
                            }}
                        />
                    }
                />
            </div>
            <div className={classes.formContentRow}>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Hours per week</InputLabel>
                    <TextField
                        inputProps={{
                            readOnly: !isFromOrg && clinicsOpt.length > 1
                        }}
                        inputRef={register}
                        defaultValue={(practitioner || {}).hoursPerWeek ? practitioner.hoursPerWeek : ''}
                        name="hoursPerWeek"
                        type="number"
                        variant="outlined"
                    />
                </FormControl>

                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Annual salary</InputLabel>
                    <TextField
                        inputProps={{
                            readOnly: !isFromOrg && clinicsOpt.length > 1
                        }}
                        value={salary}
                        onChange={e => {
                            !isNaN(Number(e.target.value)) ? setSalary(Number(e.target.value)) : setSalary(0);
                        }}
                        variant="outlined"
                        type="number"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>
                        }}
                    />
                </FormControl>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Hourly rate</InputLabel>
                    <TextField
                        inputProps={{
                            readOnly: !isFromOrg && clinicsOpt.length > 1
                        }}
                        value={hourlyRate}
                        onChange={e => {
                            !isNaN(Number(e.target.value)) ? setHourlyRate(Number(e.target.value)) : setHourlyRate(0);
                        }}
                        variant="outlined"
                        type="number"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>
                        }}
                    />
                </FormControl>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Overtime hourly rate</InputLabel>
                    <TextField
                        inputProps={{
                            readOnly: !isFromOrg && clinicsOpt.length > 1
                        }}
                        inputRef={register}
                        name="overtimePerHour"
                        variant="outlined"
                        defaultValue={(practitioner || {}).id ? practitioner.overtimePerHour : 0}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>
                        }}
                    />
                </FormControl>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel>Paid annual leave allowance</InputLabel>
                    <Controller
                        name="paidAnnualLeave"
                        control={control}
                        as={
                            <Select
                                disabled={!isFromOrg && clinicsOpt.length > 1}
                                inputProps={{
                                    className: classes.selectInputProps
                                }}
                            >
                                {new Array(101).fill().map((item, index) => (
                                    <MenuItem key={index} value={index}>
                                        {index}
                                    </MenuItem>
                                ))}
                            </Select>
                        }
                        defaultValue={(practitioner || {}).id ? practitioner.paidAnnualLeave : 0}
                    />
                </FormControl>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel>Paid sick day allowance</InputLabel>
                    <Controller
                        defaultValue={0}
                        name="sickDayAllowance"
                        control={control}
                        as={
                            <Select
                                disabled={!isFromOrg && clinicsOpt.length > 1}
                                inputProps={{
                                    className: classes.selectInputProps
                                }}
                            >
                                {new Array(101).fill().map((item, index) => (
                                    <MenuItem key={index} value={index}>
                                        {index}
                                    </MenuItem>
                                ))}
                            </Select>
                        }
                        default={(practitioner || {}).id ? practitioner.sickDayAllowance : 0}
                    />
                </FormControl>
            </div>

            {/* {!clinicsOpt.length && 'Loading locations...'}
            {clinicsOpt.length > 1 && (
                <div style={{ display: 'block', width: '100%', marginTop: 10 }}>
                    <div>
                        <b>{'Locations *'}</b>
                    </div>
                    <List className={{ width: '100%' }}>
                        {clinicsOpt.map((option, index) => {
                            return (
                                <>
                                    <ListItem
                                        key={`${option.id}-${index}`}
                                        role={undefined}
                                        className={{ width: '100%' }}
                                        dense
                                        button
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                onClick={() => {
                                                    if (!alreadySelected(option))
                                                        setValue('clinics', [...selectedClinics, option.accountName]);
                                                    else
                                                        setValue(
                                                            'clinics',
                                                            selectedClinics.filter(
                                                                element => element !== option.accountName
                                                            )
                                                        );
                                                }}
                                                checked={alreadySelected(option)}
                                                edge="start"
                                                tabIndex={-1}
                                                disableRipple
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={option.accountName} />
                                    </ListItem>
                                    <Divider />
                                </>
                            );
                        })}
                    </List>
                </div>
            )} */}
        </div>
    );
}
