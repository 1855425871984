import React from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { selectAutocompleteStyles } from './styles';

function SelectAutocomplete({
    name,
    control,
    opts,
    label,
    className,
    getValues,
    defaultValue,
    rules,
    props,
    ChipProps,
    required = false,
    optionsLabel = 'value'
}) {
    const classes = makeStyles(selectAutocompleteStyles)();
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue ? { value: defaultValue } : undefined}
            render={({ value, onChange }) => {
                return (
                    <Autocomplete
                        value={value}
                        onChange={(event, newValue) => {
                            onChange(newValue);
                        }}
                        className={`${className} ${classes.autocomplete}`}
                        options={opts}
                        getOptionSelected={option => option === getValues(name)}
                        getOptionLabel={option => (option[optionsLabel] ? option[optionsLabel].toString() : undefined)}
                        {...props}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={required ? label + ' *' : label}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{
                                    ...params.InputLabelProps,
                                    className: classes.textFieldInputLabelProps
                                }}
                            />
                        )}
                        renderOption={option => {
                            return (
                                <p className={classes.textFieldOptions}>
                                    {option[optionsLabel] ? option[optionsLabel].toString() : undefined}
                                </p>
                            );
                        }}
                        ChipProps={ChipProps}
                    />
                );
            }}
        ></Controller>
    );
}

SelectAutocomplete.propTypes = {
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.any,
    register: PropTypes.any,
    getValues: PropTypes.func,
    control: PropTypes.any,
    opts: PropTypes.array,
    label: PropTypes.string,
    className: PropTypes.string,
    rules: PropTypes.any,
    props: PropTypes.object,
    ChipProps: PropTypes.object,
    optionsLabel: PropTypes.string
};

export default SelectAutocomplete;
