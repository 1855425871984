import React from 'react';
import PropTypes from 'prop-types';
import { toLocaleString } from '../helpers/index';
import NumberFormat from 'react-number-format';

function CurrencyInput({ value, defaultValue, onChange, onBlur, CustomInput }) {
    const currencyFormatter = value => {
        if (isNaN(value)) {
            return '';
        }
        const amount = toLocaleString(value / 100);

        return `${amount}`;
    };

    return (
        <NumberFormat
            defaultValue={defaultValue ?? value}
            onFocus={e => e.target.select()}
            tabIndex={-1}
            onBlur={onBlur}
            onChange={onChange}
            variant="outlined"
            format={currencyFormatter}
            customInput={CustomInput}
        />
    );
}

CurrencyInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    onBlur: PropTypes.func,
    CustomInput: PropTypes.any,
    defaultValue: PropTypes.string
};

export default CurrencyInput;
