import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toastr } from 'react-redux-toastr';

import { Button, withStyles, makeStyles } from '@material-ui/core';

import { calcDateFromType, getLocationItem } from '../../collums-constants/utils/index';

import Modal from '../common/Modal';
import Accordion from '../common/Accordion';
import { coursesStyles } from './styles';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';

import GeneralTab from './tabs/GeneralTab';
import PriceAndLocationTab from './tabs/PriceAndLocationTab';
import OnlineBookingTab from './tabs/OnlineBookingTab';
import CommissionTab from './tabs/CommissionTab';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';

import ServiceApi from '../../api/ServiceApi';
import CourseApi from '../../api/CoursesApi';
import ConfirmModal from '../common/ConfirmModal';

const validationMessages = {
    required: 'This field is required.',
    minValueZero: 'This value cannot be less than 0',
    minValueOne: 'This value cannot be less than 1'
};

function CourseFormModal({
    open,
    title,
    onClose,
    classes,
    selectedCourse,
    getCoursesCategories,
    categories,
    setCategories,
    refreshList,
    errorMessage,
    getCourseGrossPrice,
    getCourseNetPrice,
    courseTaxes,
    clinic,
    clinics,
    isFromOrg,
    allClinics
}) {
    const globalStyles = makeStyles(modalsButtonStyles)();
    const defaultValues = {
        name: selectedCourse.name || '',
        active: selectedCourse ? selectedCourse.active : true,
        code: selectedCourse.code || '',
        description: selectedCourse.description || '',
        services: selectedCourse.service || '',
        category: selectedCourse.category || '',
        subCategories: selectedCourse.category?.subCategories || [],
        quantity: parseInt(selectedCourse.quantity, 10) || 1,
        expiresAfterType: selectedCourse.expiresAfterType || 'weeks',
        expiresAfterQuantity: selectedCourse.expiresAfterQuantity || undefined,
        endsOn: selectedCourse.endsOn || null,
        tags: selectedCourse.tags || [],
        minTimeBetweenServices: selectedCourse.minTimeBetweenServices,
        minTimeBetweenServicesType: selectedCourse.minTimeBetweenServicesType || 'days',
        startValidityOnRedemption: selectedCourse.startValidityOnRedemption || false,
        tax: isFromOrg ? selectedCourse.itemTax : getLocationItem(selectedCourse, clinic).itemTax,
        discount: 0,
        salePrice: selectedCourse.salePrice || 0,
        netPrice:
            selectedCourse.netPrice || selectedCourse.salePrice
                ? selectedCourse.salePrice / (selectedCourse?.tax?.rate || 0 + 1)
                : 0,
        frequency: selectedCourse.frequency || '',
        allowOnlineBooking: selectedCourse ? selectedCourse.allowOnlineBooking : true,
        onlineName: selectedCourse.onlineName || undefined,
        onlineDescription: selectedCourse.onlineDescription || undefined,
        onlineInfoBubble: selectedCourse.onlineInfoBubble || '',
        bookingLink: selectedCourse.bookingLink || '',
        showOnline: selectedCourse ? selectedCourse.showOnline : true,
        displayDurationServices: selectedCourse.displayDurationServices || false,
        durationOfServices: selectedCourse.durationOfServices || 0,
        showPrice: selectedCourse.showPrice || false,
        onlinePrice: selectedCourse.onlinePrice || selectedCourse.salePrice
    };

    const { register, unregister, handleSubmit, control, watch, setValue, getValues, errors } = useForm({
        defaultValues
    });
    const fields = watch();

    const [services, setServices] = useState();
    const [filterServices, setFilterServices] = useState({ value: '' });
    const [isLoading, setIsLoading] = useState(false);

    const [confirmModal, setConfirmModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState('');
    const [modalCancel, setModalCancel] = useState(null);
    const [modalContinue, setModalContinue] = useState(null);
    const [modalOnContinue, setModalOnContinue] = useState(() => {});

    const onSubmit = async data => {
        let oryginalData = { ...data };
        const newTags = data.tags.map(tag => tag.id);

        data.locations = data.locations?.filter(loc => loc.isSelected);
        if (!data.locations.length) return toastr.error('Locations is a required field');

        if (!data.endsOn) {
            if (data.expiresAfterQuantity) {
                data.endsOn = calcDateFromType(data.expiresAfterQuantity, data.expiresAfterType);
            }
        }

        if (data.locations?.length) {
            data.locations = data.locations
                .map(location => ({
                    clinic: location.value,
                    isSelected: location.isSelected
                }))
                .filter(loc => loc.isSelected);
        }

        if (!data?.expiresAfterQuantity)
            return toastr.error('Cannot save with the "Course expires after" date field empty');

        const body = {
            name: data.name,
            active: data.active || selectedCourse.active || false,
            archived: data.archived || selectedCourse.archived || false,
            code: data.code,
            description: data.description,
            service: data.services.id,
            category: data.category.id,
            quantity: data.quantity || 1,
            locations: data.locations || [],
            expiresAfterType: data.expiresAfterType,
            expiresAfterQuantity: data.expiresAfterQuantity,
            endsOn: data.endsOn,
            tags: newTags,
            minTimeBetweenServices: data.minTimeBetweenServices,
            minTimeBetweenServicesType: data.minTimeBetweenServicesType,
            startValidityOnRedemption: data.startValidityOnRedemption,
            tax: data.tax.id,
            netPrice: data.netPrice,
            salePrice: data.salePrice,
            dateOfFirstInstallment: data.dateOfFirstInstallment,
            frequency: data.frequency,
            allowOnlineBooking: data.allowOnlineBooking,
            onlineName: data.onlineName,
            onlineDescription: data.onlineDescription,
            onlineInfoBubble: data.onlineInfoBubble,
            bookingLink: data.onlineBookingLink,
            showOnline: data.showOnline,
            displayDurationServices: data.displayDurationServices,
            durationOfServices: data.durationOfServices,
            showPrice: data.showPrice,
            onlinePrice: data.onlinePrice,
            allowCommission: data.allowCommission
        };

        if (selectedCourse.id) {
            try {
                if (data.name !== selectedCourse.prevName) {
                    setModalTitle('Confirm Editing');
                    setModalContent(
                        'Changing the name of this course will also affect historic appointments, invoices, reports and anywhere else it features.\n' +
                            'If you wish to keep historic records the same but have this change for the future, please inactivate this course and create a new one.'
                    );
                    setModalCancel('Cancel');
                    setModalContinue('Confirm');
                    setModalOnContinue(() => () => {
                        selectedCourse.prevName = data.name;
                        onSubmit(oryginalData);
                    });
                    setConfirmModal(true);
                    return;
                }
                if (!isFromOrg) {
                    body.salePrice = defaultValues.salePrice;
                    body.netPrice = defaultValues.netPrice;
                    body.tax = selectedCourse.tax;
                    body.allowCommission = selectedCourse.allowCommission;
                    body.category = selectedCourse.category?.id || selectedCourse.category;
                    body.locations = selectedCourse.locations.map(loc => {
                        if (loc.clinic === clinic) {
                            loc.salePrice = data.salePrice;
                            loc.netPrice = data.netPrice;
                            loc.tax = data.tax?.id;
                            loc.allowCommission = data.allowCommission;
                            loc.category = data.category.id;
                            return loc;
                        } else {
                            return loc;
                        }
                    });
                } else {
                    const oldLocationsClinics = selectedCourse?.locations?.map(loc => loc.clinic) || [];

                    body.locations = body.locations
                        .map(loc => {
                            if (oldLocationsClinics.includes(loc.clinic)) {
                                return selectedCourse.locations.find(_loc => _loc.clinic === loc.clinic);
                            } else {
                                loc.active = selectedCourse.active;
                                loc.archived = selectedCourse.archived;
                                loc.salePrice = data.salePrice;
                                loc.netPrice = data.netPrice;
                                loc.tax = data.tax?.id;
                                loc.allowCommission = data.allowCommission;
                                loc.category = data.category.id;
                                return loc;
                            }
                        })
                        .filter(el => el);
                }

                await CourseApi.updateCourse(selectedCourse.id, body, isFromOrg, clinic);
                toastr.success('Success', 'your records were successfully updated!');
            } catch (err) {
                if (typeof err === 'object') {
                    if (err.data && err.data.message) {
                        toastr.error(err.data.message);
                        return;
                    }
                }
                toastr.error(err?.data?.message || errorMessage.title, errorMessage.message);
            }
        } else {
            try {
                body.active = true;
                body.archived = false;
                body.locations = data.locations.map(loc => {
                    loc.salePrice = data.salePrice;
                    loc.netPrice = data.netPrice;
                    loc.tax = data.tax.id;
                    loc.active = true;
                    loc.archived = false;
                    loc.allowCommission = data.allowCommission;
                    loc.category = data.category.id;
                    return loc;
                });
                await CourseApi.createCourse(body);
                toastr.success('Success', 'your records were successfully saved!');
            } catch (err) {
                if (typeof err === 'object') {
                    if (err.data && err.data.message) {
                        toastr.error(err.data.message);
                        return;
                    }
                }
                toastr.error(err?.data?.message || errorMessage.title, errorMessage.message);
            }
        }
        await refreshList();
        onClose();
    };

    const showErros = error => {
        for (let err in error) {
            if (error[err]) {
                return toastr.error(`${err}`, `${error[err].message}`);
            }
        }
    };

    const getServices = async () => {
        try {
            const result = await ServiceApi.getServices(filterServices, isFromOrg, clinic, 999);

            const resultSorted = result.items.sort((a, b) => {
                const aToLower = a?.name?.toLowerCase();
                const bToLower = b?.name?.toLowerCase();

                if (aToLower < bToLower) {
                    return -1;
                }
                if (aToLower > bToLower) {
                    return 1;
                }
                return 0;
            });
            setServices(resultSorted);
        } catch {
            toastr.error(errorMessage.title, errorMessage.message);
        }
    };

    const loadData = async () => {
        try {
            setIsLoading(true);
            await getServices();
            await getCoursesCategories({ name: '' });
        } finally {
            setIsLoading(false);
        }
    };

    const formProps = {
        classes,
        validationMessages,
        register,
        unregister,
        control,
        fields,
        setValue,
        watch,
        isFromOrg,
        clinic,
        services,
        clinics,
        setServices,
        getServices,
        categories,
        setCategories,
        getCoursesCategories,
        filterServices,
        setFilterServices,
        getValues,
        getCourseNetPrice,
        getCourseGrossPrice,
        selectedCourse,
        courseTaxes,
        allClinics
    };

    useEffect(() => {
        loadData();
        /* eslint-disable-next-line */
    }, []);

    useEffect(() => {
        getServices();
        getCoursesCategories({ name: '' });
        /* eslint-disable-next-line */
    }, []);

    useEffect(() => {
        if (selectedCourse.name && !selectedCourse.service) {
            toastr.error('Missing required field', 'Please, select a service before proceding');
        }
    }, [selectedCourse]);

    useEffect(() => {
        showErros(errors);
    }, [errors]);

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setModalTitle('');
        setModalContent();
        setModalContinue(null);
        setModalCancel(null);
    };

    if (isLoading) return <LoadingScreen />;
    return (
        <>
            <Modal id="CourseFormModal" isOpen={open} title={title} size={'md'} onClose={onClose} onCancel={onClose}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={classes.accordions}>
                        <Accordion title={'General'}>
                            <GeneralTab {...formProps} key={'general'} />
                        </Accordion>
                        <Accordion title={isFromOrg && allClinics.length > 1 ? 'Price & Locations' : 'Price'}>
                            <PriceAndLocationTab {...formProps} />
                        </Accordion>
                        <Accordion title={'Online Booking'}>
                            <OnlineBookingTab {...formProps} />
                        </Accordion>
                        <Accordion title={'Commission'}>
                            <CommissionTab {...formProps} />
                        </Accordion>
                    </div>

                    <div className={globalStyles.buttonsContainer}>
                        <Button className={globalStyles.cancelButton} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button className={globalStyles.confirmButton} type="submit" variant="outlined">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>
            {confirmModal && (
                <ConfirmModal
                    isOpen
                    setIsOpen={closeConfirmModal}
                    onConfirm={modalOnContinue}
                    title={modalTitle}
                    content={modalContent}
                    centerContent
                    continueText={modalContinue}
                    cancelText={modalCancel}
                />
            )}
        </>
    );
}

export default withStyles(coursesStyles)(CourseFormModal);
