import React, { useEffect, useState } from 'react';
import CheckboxTree from './CheckboxTree';
import coursesApi from '../../../api/CoursesApi';
import LoadingScreen from '../../../collums-components/components/common/loadingScreen';

function FormCourses({ selectedCourses, setSelectedCourses, isFromOrg }) {
    const [coursesCategories, setCoursesCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getCourses = async () => {
        setIsLoading(true);
        const response = await coursesApi.getCoursesByCategory();
        const formattedCourses = response.map(value => {
            return {
                name: value.category.name,
                id: value.category.id,
                child: value.courses
            };
        });
        setCoursesCategories(formattedCourses);
        setIsLoading(false);
    };

    const categoryClick = (e, category) => {
        const coursesId = category.child.map(course => course.id);
        const newSelectedCourses = (() => {
            if (!e.target.checked) {
                return selectedCourses.filter(course => !coursesId.includes(course));
            }
            return [...selectedCourses, ...coursesId];
        })();
        setSelectedCourses(newSelectedCourses);
    };

    const toggleChild = (e, courseId) => {
        const newSelectedCourses = (() => {
            if (e.target.checked) return [...selectedCourses, courseId];
            return selectedCourses.filter(service => service !== courseId);
        })();
        setSelectedCourses(newSelectedCourses);
    };

    useEffect(() => {
        getCourses();
    }, []);

    return (
        <>
            {isLoading && <LoadingScreen />}
            <CheckboxTree
                notFromOrg={!isFromOrg}
                parentTree={coursesCategories}
                selectedItems={selectedCourses}
                parentClick={categoryClick}
                childClick={toggleChild}
            />
        </>
    );
}

export default FormCourses;
