import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Chip, makeStyles, InputLabel, FormControl, Select, MenuItem } from '@material-ui/core';

import { CommissionCategoriesStyles } from './styles';

function CommissionCategoryInput({ disabled, inputSize, formRef, options, index, type }) {
    const classes = makeStyles(CommissionCategoriesStyles)();
    const [value, setValue] = useState(formRef.current?.commissions?.[type]?.[index]?.categories || []);

    const handleDelete = index => {
        const newValue = value.filter((_, ind) => ind !== index);
        Object.assign(formRef.current?.commissions?.[type][index], {
            categories: newValue,
            allCategories: false
        });
        setValue(newValue);
    };

    const handleDeleteAll = () => {
        Object.assign(formRef.current?.commissions?.[type][index], {
            categories: [],
            allCategories: false
        });
        setValue([]);
    };

    const canShowAllLabel =
        value.length === options.length && Boolean(formRef.current?.commissions?.[type][index].allCategories);
    return (
        <div className={classes.root}>
            <div className={classes.commissionCategoriesContainer} style={{ width: `${inputSize || '48'}%` }}>
                <FormControl className={classes.autocomplete} fullWidth>
                    <InputLabel id="select-category" style={{ marginLeft: 14, top: -5 }}>
                        Select a category
                    </InputLabel>
                    <Select
                        labelId="select-label"
                        variant="outlined"
                        id="select-category"
                        label="Select a category"
                        renderValue={() => null}
                        onChange={event => {
                            const isAllSelected = event.target.value.name === 'All';
                            if (isAllSelected) {
                                Object.assign(formRef.current?.commissions?.[type][index], {
                                    categories: options,
                                    allCategories: true
                                });
                                setValue(options);
                                return;
                            }
                            Object.assign(formRef.current?.commissions?.[type][index], {
                                categories: [...value, event.target.value],
                                allCategories: false
                            });
                            setValue([...value, event.target.value]);
                        }}
                    >
                        <MenuItem value={{ name: 'All' }}>
                            <em>All</em>
                        </MenuItem>
                        {options.map(option => {
                            return (
                                <MenuItem key={option} value={option}>
                                    {option.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <div
                    className={classes.commissionCategoryChipsContainer}
                    style={{ marginBottom: value.length ? 16 : 0 }}
                >
                    {canShowAllLabel ? (
                        <Chip disabled={disabled} size="small" label={'All'} onDelete={() => handleDeleteAll()} />
                    ) : (
                        value.map((commissionCategory, index) => (
                            <Chip
                                disabled={disabled}
                                key={index}
                                size="small"
                                label={commissionCategory.name}
                                onDelete={() => handleDelete(index)}
                            />
                        ))
                    )}
                </div>
            </div>
        </div>
    );
}

CommissionCategoryInput.propTypes = {
    disabled: PropTypes.bool,
    inputSize: PropTypes.string,
    formRef: PropTypes.object.isRequired,
    type: PropTypes.string,
    index: PropTypes.number,
    options: PropTypes.array.isRequired
};

export default CommissionCategoryInput;
