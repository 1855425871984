import React, { useState } from 'react';
import { Box, CircularProgress, FormControl, makeStyles, TextField } from '@material-ui/core';
import { squareView } from '../square/styles';
import { toastr } from 'react-redux-toastr';
import Modal from '../common/Modal';
import StripeApi from '../../api/StripeApi';

const StripeCreateDeviceModal = ({ onClose }) => {
    const classes = makeStyles(squareView)();
    const [label, setLabel] = useState('');
    const [code, setCode] = useState('');
    const [loading, setLoading] = useState(false);

    const save = async () => {
        if (!label.trim() || !code.trim()) {
            toastr.error('Please fill all fields');
            return;
        }
        setLoading(true);

        const result = await StripeApi.createDevice({ code, label });

        if (result && result?.id) {
            setLoading(false);
            onClose();
            toastr.success('Device paired successfully');
            return true;
        } else {
            setLoading(false);
            toastr.error('Invalid pairing code');
            return false;
        }
    };

    return (
        <Modal
            isOpen
            onConfirm={save}
            onCancel={onClose}
            onClose={onClose}
            title="Add new terminal"
            id="device-form"
            size="xs"
            confirmButtonColored={true}
        >
            <FormControl style={{ width: '100%', marginTop: 12 }} variant="outlined">
                <TextField
                    placeholder="Device name"
                    className={classes.deviceName}
                    style={{ width: '100%' }}
                    value={label}
                    onChange={e => setLabel(e.target.value)}
                    variant="outlined"
                />
            </FormControl>
            <FormControl style={{ width: '100%', marginTop: 12 }} variant="outlined">
                <TextField
                    placeholder="Pairing code"
                    className={classes.deviceName}
                    style={{ width: '100%' }}
                    value={code}
                    onChange={e => setCode(e.target.value)}
                    variant="outlined"
                />
            </FormControl>
            {loading && (
                <Box style={{ textAlign: 'center', margin: '15px 0 0' }}>
                    <CircularProgress />
                </Box>
            )}
        </Modal>
    );
};

export default StripeCreateDeviceModal;
