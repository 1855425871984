export const clinicDetailsModalStyles = () => ({
    form: {
        width: '100%',
        '& .MuiFormControl-root': {
            width: '100%'
        },
        '& input': {
            height: 'inherit',
            boxSizing: 'border-box'
        }
    },
    selectField: {
        width: '100%'
    },
    centerFlexItem: {
        display: 'flex',
        alignItems: 'center'
    },
    accordionTitle: {
        '& p': {
            fontSize: 16,
            fontWeight: 400
        }
    },
    formItem: {
        marginTop: '8px !important'
    },
    autocomplete: {
        margin: 0,
        fontSize: 14,
        width: '100%',
        '& > * > *, & > *': {
            lineHeight: '10px'
        },
        '& input': {
            fontSize: 14,
            marginTop: -5
        }
    },
    subTitleContainer: {
        '& h4': {
            fontSize: 16,
            fontWeight: 400,
            fontFamily: 'Roboto, Arial, Sans-serif'
        }
    },
    locationMapButton: {
        width: 'fit-content',
        minWidth: 80,
        height: 38,
        borderRadius: 0,
        fontWeight: 500,
        padding: '6px 14px',
        margin: '0',
        boxShadow: 'none',
        border: '1px solid rgba(0, 0, 0, 0.87)',
        backgroundColor: 'transparent',
        color: 'rgba(0, 0, 0, 0.87)',
        '&:hover': {
            backgroundColor: 'transparent'
        },
        '&.Mui-disabled': {
            border: '1px solid rgba(191,191,191,0.87)'
        },
        textTransform: 'none',
        fontSize: 14
    }
});
