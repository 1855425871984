class Notification {
    init(onNotification) {
        this.onNotification = onNotification;
    }

    success(message) {
        this.onNotification(message, 'success');
    }

    error(message) {
        this.onNotification(message, 'error');
    }
}

export default new Notification();
