export const generalTabStyles = () => ({
    general: {
        display: 'block',
        padding: 10,
        width: '100%'
    },
    mainGrid: {
        marginBottom: 20
    },
    gridItem: {
        marginBottom: 40
    },
    addButtonContainer: {
        height: 38,
        display: 'flex',
        alignItems: 'center',
        justifyContet: 'center'
    },
    addButton: {
        margin: '0 4px'
    },
    autocompleteArea: {
        width: '48%',
        display: 'flex',
        alignItems: 'center'
    },
    addIcon: {
        fontSize: 30,
        color: '#3083EC'
    },
    flex: {
        display: 'flex'
    },
    formItem: {
        width: '48% !important',
        marginRight: 8,
        marginTop: 8,
        marginBottom: 8,
        '& label': {
            fontFamily: 'Roboto, Arial, Sans-serif'
        }
    },
    defaultFont: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        marginRight: 10
    },
    centralizeRow: {
        display: 'flex',
        alignItems: 'center'
    }
});
