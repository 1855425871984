import React from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    withStyles
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { modalFormStyles as styles } from './styles';

function Modal({
    classes,
    title,
    children,
    onConfirm,
    confirmLabel,
    confirmClass = {},
    onBack,
    backLabel,
    backClass = {},
    actions,
    onClose,
    isOpen,
    style = {},
    modalContainerStyle = {}
}) {
    const renderActions = () => {
        const buttons = [];
        if (onBack) {
            buttons.push(
                <Button
                    key="back"
                    onClick={e => {
                        e.preventDefault();
                        onBack();
                    }}
                    color="primary"
                    className={backClass}
                >
                    {backLabel || 'Back'}
                </Button>
            );
        }
        if (onConfirm) {
            buttons.push(
                <Button
                    key="confirm"
                    onClick={e => {
                        e.preventDefault();
                        onConfirm();
                    }}
                    color="primary"
                    className={confirmClass}
                >
                    {confirmLabel || 'Confirm'}
                </Button>
            );
        }
        buttons.push(...(actions || []));
        return buttons;
    };
    return (
        <div
            className="modal"
            style={{
                display: isOpen ? 'flex' : 'none',
                position: 'absolute',
                height: '100%',
                ...style
            }}
        >
            <div className={`modal-content ${classes.modalContainer}`} style={{ ...modalContainerStyle }}>
                <DialogTitle className={classes.title} disableTypography>
                    <Typography variant="h4">{title}</Typography>
                    {onClose && (
                        <IconButton onClick={onClose} className={classes.closeButton}>
                            <Close />
                        </IconButton>
                    )}
                </DialogTitle>
                <DialogContent>{children}</DialogContent>
                <DialogActions>{renderActions()}</DialogActions>
            </div>
        </div>
    );
}

export default withStyles(styles)(Modal);
