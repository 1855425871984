import * as _ from 'lodash';

const evaluateRule = (rule, formValue) => {
    if (rule.operator) {
        return evaluateRules(rule);
    } else if (rule.func) {
        return rule.func(_.get(formValue, rule.path));
    } else if (rule.value) {
        return rule.value === _.get(formValue, rule.path);
    } else if (rule.valueNot) {
        return rule.valueNot !== _.get(formValue, rule.path);
    } else if (rule.isDefined) {
        return !!_.get(formValue, rule.path);
    }
};

const evaluateRules = (ruleObject, formValue) => {
    if ('all' === ruleObject.operator) {
        return _.every(ruleObject.rules, rule => evaluateRule(rule, formValue));
    } else if ('any' === ruleObject.operator) {
        return _.some(ruleObject.rules, rule => evaluateRule(rule, formValue));
    } else {
        return evaluateRule(ruleObject, formValue);
    }
};

export const controlledDisable = (formValue, fieldSchema, ruleObject) => ({
    ...fieldSchema,
    defaultDisabled: evaluateRules(ruleObject, formValue)
});

export const controlledVisibility = (formValue, fieldSchema, ruleObject) => ({
    ...fieldSchema,
    visible: evaluateRules(ruleObject, formValue)
});

export const dynamicOptions = (fieldSchema, options, defaultValue) => {
    return {
        ...fieldSchema,
        options,
        defaultValue: defaultValue && options.find(option => option.value === defaultValue)
    };
};
