import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { htmlEditor as styles } from './styles';
import { withStyles, makeStyles, Button } from '@material-ui/core';
import TinyMce from '../../../../../collums-components/components/common/TinyMce/TinyMce';
import { useForms } from '../../../../../collums-components/hooks/forms';
import { modalsButtonStyles } from '../../../../../collums-constants/styles/stylesheets/buttonsStyles';
import ConsentPreview from './ConsentPreview';

const HtmlEditor = ({ classes, editorRef, onChange }) => {
    const buttonClasses = makeStyles(modalsButtonStyles)();
    const [isShowingPreview, setIsShowingPreview] = useState(false);

    const { data } = useForms();

    return (
        <>
            <div className={classes.consentArea}>
                <TinyMce refEditor={editorRef} onChange={onChange} value={data.consent} />
            </div>
            <Button
                className={`${buttonClasses.baseButton} ${classes.previewButton}`}
                onClick={() => {
                    setIsShowingPreview(true);
                }}
            >
                Preview
            </Button>
            {isShowingPreview && (
                <ConsentPreview
                    onClose={() => setIsShowingPreview(false)}
                    content={editorRef.current ? editorRef.current.getContent() : ''}
                />
            )}
        </>
    );
};

HtmlEditor.propTypes = {
    classes: PropTypes.object.isRequired,
    editorRef: PropTypes.object,
    onChange: PropTypes.func
};

export default withStyles(styles)(HtmlEditor);
