import React, { useEffect, useState } from 'react';
import ListPagination from '../common/ListPagination';
import StripeApi from '../../api/StripeApi';
import { toastr } from 'react-redux-toastr';
import StripeCreateDeviceModal from './StripeCreateDeviceModal';
import { Box, Button, makeStyles } from '@material-ui/core';
import { stripeView } from './styles';
import CancelContinueModal from '../../collums-components/components/common/CancelContinueModal';

const sortDevices = devices => {
    return devices.sort((a, b) => {
        if (parseInt(a?.metadata?.createdAt) < parseInt(b?.metadata?.createdAt)) {
            return -1;
        }
        if (parseInt(a?.metadata?.createdAt) > parseInt(b?.metadata?.createdAt)) {
            return -1;
        }
        return 0;
    });
};

const StripeTerminalList = () => {
    const classes = makeStyles(stripeView())();
    const [devices, setDevices] = useState([]);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [showRemoveModal, setShowRemoveModal] = useState(false);

    useEffect(() => {
        (async () => {
            await fetchDevices();
        })();
    }, []);

    const fetchDevices = async () => {
        const response = await StripeApi.terminalList();
        if (response) {
            setDevices(sortDevices(response.data));
        }
    };

    const handleDeletePopup = data => {
        setShowRemoveModal(data);
    };

    const handleDelete = async data => {
        await StripeApi.removeDevice(data);
        await fetchDevices();

        toastr.success('Card reader has been removed');
    };

    return (
        <>
            <Box style={{ margin: '20px 0' }} className={classes.connectButton}>
                <p>Instructions to connect your Stripe card terminal to Collums:</p>
                <div>
                    <ul>
                        <li>Click “Add new terminal” below.</li>
                        <li>
                            Enter a name for your terminal (this is especially important if you have more than one).
                        </li>
                        <li>Turn on your terminal and connect it to your WiFi.</li>
                        <li>A code will appear on your terminal - enter this into Collums.</li>
                        <li>Click “Confirm”.</li>
                    </ul>
                </div>
                <Button
                    style={{ marginBottom: '10px' }}
                    color="primary"
                    variant="contained"
                    onClick={() => setIsFormOpen(true)}
                >
                    Add new terminal
                </Button>

                {!!devices.length && (
                    <ListPagination
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        data={devices}
                        actions={[{ title: 'delete', action: handleDeletePopup }]}
                        maxSize={devices.length}
                        defaultColumns={[{ title: 'Name', field: 'label' }]}
                        onCreate={() => setIsFormOpen(true)}
                        item={'Stripe'}
                        highlightArchived={false}
                        noHeader={true}
                    />
                )}

                {isFormOpen && (
                    <StripeCreateDeviceModal
                        onClose={async () => {
                            setIsFormOpen(false);
                            await fetchDevices();
                        }}
                    />
                )}
            </Box>
            <CancelContinueModal
                open={showRemoveModal}
                setOpen={setShowRemoveModal}
                title="Warning"
                contentText="Are you sure to remove card reader?"
                onContinue={() => handleDelete(showRemoveModal)}
            />
        </>
    );
};

export default StripeTerminalList;
