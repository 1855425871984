import TextField from '@material-ui/core/TextField';
import React, { useCallback } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const GOOGLE_PLACE_COUNTRY = 'country';
const GOOGLE_PLACE_STREET = 'route';
const GOOGLE_PLACE_TOWN = 'town'; // this code is overwritten/filtered in getGooglePlaceAddressComponent function
const GOOGLE_PLACE_POSTCODE = 'postal_code';
const GOOGLE_PLACE_COUNTY = 'county'; // this code is overwritten/filtered in getGooglePlaceAddressComponent function

const getGooglePlaceAddressComponent = (place, code) => {
    const addressComponents = place?.address_components || [];

    if (addressComponents?.length) {
        const component = addressComponents.find(component => {
            const types = component?.types || [];
            switch (code) {
                case GOOGLE_PLACE_COUNTY:
                    return (
                        types.includes('administrative_area_level_2') || types.includes('administrative_area_level_1')
                    );
                case GOOGLE_PLACE_TOWN:
                    return types.includes('locality') || types.includes('postal_town');
                default:
                    return types.includes(code);
            }
        });

        return component?.long_name || component?.short_name || null;
    }

    return null;
};

const GoogleAutocompleteField = ({ value, onChange, fieldProps, detailed = false, classes }) => {
    const { placePredictions, getPlacePredictions, placesService, isPlacePredictionsLoading } = usePlacesService({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        debounce: 500, // protect spam requests and cost
        options: {
            componentRestrictions: {
                country: 'UK' // limit/filter results to UK only.
            }
        }
    });

    const onChangeHandler = useCallback(
        place => {
            let result = {
                value: place?.structured_formatting?.main_text || place?.description || ''
            };

            // get place details
            if (detailed && placesService) {
                if (place?.place_id) {
                    placesService.getDetails(
                        {
                            placeId: place?.place_id,
                            language: 'en'
                        },
                        placeDetails => {
                            onChange({
                                ...result,
                                country: getGooglePlaceAddressComponent(placeDetails, GOOGLE_PLACE_COUNTRY),
                                town: getGooglePlaceAddressComponent(placeDetails, GOOGLE_PLACE_TOWN),
                                county: getGooglePlaceAddressComponent(placeDetails, GOOGLE_PLACE_COUNTY),
                                street: getGooglePlaceAddressComponent(placeDetails, GOOGLE_PLACE_STREET),
                                postcode: getGooglePlaceAddressComponent(placeDetails, GOOGLE_PLACE_POSTCODE)
                            });
                        }
                    );
                } else {
                    onChange(result);
                }
            } else {
                onChange(result);
            }
        },
        [detailed, onChange, placesService]
    );

    const renderInput = useCallback(
        params => {
            return (
                <TextField
                    {...fieldProps}
                    {...params}
                    inputProps={{
                        ...(params?.inputProps || {}),
                        value: value
                    }}
                    variant="outlined"
                    fullWidth
                    className={classes.autoCompleteTextField}
                    InputLabelProps={{ shrink: !!fieldProps?.shrink }}
                />
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fieldProps, value]
    );

    return (
        <div className={classes.root}>
            <Autocomplete
                id="address-google-autocomplete"
                className={`${classes.font} ${classes.autocomplete}`}
                options={placePredictions || []}
                getOptionLabel={option => option.description}
                get
                inputValue={value}
                onInputChange={(e, value, reason) => {
                    if (reason === 'input') {
                        onChange({ value });
                        getPlacePredictions({ input: value });
                    }
                }}
                onChange={(e, value) => {
                    onChangeHandler(value);
                }}
                filterOptions={x => x}
                loading={isPlacePredictionsLoading}
                loadingText="Loading..."
                noOptionsText="No results"
                renderInput={renderInput}
                freeSolo
            />
        </div>
    );
};

GoogleAutocompleteField.propTypes = {
    value: PropTypes.string,
    /**
     *  Pass "place" variable is an object with properties:
     *  {
     *    value - input value or address line value from google if found,
     *
     *    // all other properties can be undefined or null if location not found or is missing in google response.
     *    country
     *    town
     *    county
     *    street
     *    postcode
     *  }
     */
    onChange: PropTypes.func,
    fieldProps: PropTypes.object,
    detailed: PropTypes.bool,
    defaultValue: PropTypes.string
};

export default withStyles(styles)(GoogleAutocompleteField);
