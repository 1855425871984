import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { numberInputStyles } from './styles';

class NumberInput extends React.Component {
    render() {
        const { field, onChange, classes } = this.props;
        const dynamicStyle = {
            paddingLeft: field.indent ? 24 : 0
        };
        return (
            <div className={classes.container} style={dynamicStyle}>
                {field.prefix && <Typography className={classes.prefix}>{field.prefix}</Typography>}
                <TextField
                    id={field.id}
                    label={field.label}
                    value={field.controlledValue || field.value || ''}
                    type="number"
                    error={!field.isValid}
                    disabled={field.disabled}
                    placeholder={field.placeholder}
                    helperText={field.errorMessages || field.description}
                    onChange={event => onChange(field.id, event.target.value)}
                    fullWidth
                />
            </div>
        );
    }
}

NumberInput.propTypes = {
    field: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(numberInputStyles)(NumberInput);
