import Axios from 'axios';

const API_KEY = 'AIzaSyAxbQiEskQDZ9aqwfkK1wMay5rDLaYy9xY';
const API_URL = 'https://maps.googleapis.com/maps/api/geocode/json';

export const getCoord = async address => {
    const requestUrl = `${API_URL}?address=${address}&key=${API_KEY}`;
    const {
        data: { results }
    } = await Axios.get(requestUrl);

    return results.length > 0 ? results[0].geometry.location : {};
};
