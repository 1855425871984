import React from 'react';
import PropTypes from 'prop-types';

import {
    withStyles,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Button,
    InputAdornment,
    IconButton
} from '@material-ui/core';
import { Controller, useFieldArray } from 'react-hook-form';

import TagsInput from '../../../collums-components/form/TagsInput';

import { accordionFormStyles } from '../styles';
import { Close } from '@material-ui/icons';
import { PRODUCT_VOL_UNITS } from '../../../collums-constants';
import VolumeUnit from './components/VolumeUnit';
import { getLocationItem } from '../../../collums-constants/utils';

function ProductFormGeneral({
    categories,
    subcategories,
    brands,
    suppliers,
    selectedProduct,
    clinic,
    control,
    register,
    classes,
    volumeUnitRef,
    isFromOrg,
    allClinics
}) {
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'general.barcodes'
    });

    const numberOpts = Array(39)
        .fill(0)
        .map((_, index) => ((index + 1) * 5 + 5).toString());

    return (
        <div className={classes.formContent}>
            <div className={classes.formContentRow}>
                <TextField
                    inputProps={{ readOnly: !isFromOrg && allClinics.length > 1 }}
                    className={classes.formItem}
                    inputRef={register({ required: 'Name' })}
                    name="general.name"
                    label="Name *"
                    variant="outlined"
                    defaultValue={selectedProduct ? selectedProduct.name : ''}
                />
                <TextField
                    inputProps={{ readOnly: !isFromOrg && allClinics.length > 1 }}
                    className={classes.formItem}
                    inputRef={register}
                    name="general.description"
                    label="Description"
                    variant="outlined"
                    defaultValue={selectedProduct ? selectedProduct.description : ''}
                />
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel>Category *</InputLabel>
                    <Controller
                        // disabled={!isFromOrg}
                        name="general.category"
                        rules={{ required: 'Category' }}
                        defaultValue={
                            selectedProduct
                                ? isFromOrg
                                    ? selectedProduct.category?.id
                                    : getLocationItem(selectedProduct, clinic)?.category
                                : null
                        }
                        control={control}
                        as={
                            <Select>
                                <MenuItem value={null}>
                                    <em>None</em>
                                </MenuItem>
                                {categories.map(category => (
                                    <MenuItem key={category.id} value={category.id}>
                                        {category.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        }
                    />
                </FormControl>
                <FormControl
                    // disabled={!isFromOrg}
                    className={classes.formItem}
                    variant="outlined"
                >
                    <InputLabel>Sub Category</InputLabel>
                    <Controller
                        name="general.subCategory"
                        defaultValue={
                            selectedProduct ? selectedProduct.subCategory?.id || selectedProduct.subCategory : null
                        }
                        control={control}
                        as={
                            <Select>
                                <MenuItem value={null}>
                                    <em>None</em>
                                </MenuItem>
                                {subcategories.map(subCategory => (
                                    <MenuItem key={subCategory.id} value={subCategory.id}>
                                        {subCategory.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        }
                    />
                </FormControl>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel>Brand</InputLabel>
                    <Controller
                        name="general.brand"
                        defaultValue={
                            selectedProduct
                                ? isFromOrg || allClinics.length === 1
                                    ? selectedProduct.brand?.id
                                    : getLocationItem(selectedProduct, clinic)?.brand
                                : null
                        }
                        control={control}
                        as={
                            <Select>
                                <MenuItem value={null}>
                                    <em>None</em>
                                </MenuItem>
                                {brands.map(brand => (
                                    <MenuItem key={brand.id} value={brand.id}>
                                        {brand.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        }
                    />
                </FormControl>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel>Supplier</InputLabel>
                    <Controller
                        name="general.supplier"
                        defaultValue={(() => {
                            if (isFromOrg || allClinics.length === 1) {
                                return selectedProduct?.supplier?.id || null;
                            }
                            return suppliers.find(
                                sup => sup?.id?.toString() === getLocationItem(selectedProduct, clinic).supplier
                            )?.id;
                        })()}
                        control={control}
                        as={
                            <Select>
                                <MenuItem value={null}>
                                    <em>None</em>
                                </MenuItem>
                                {suppliers.map(supplier => (
                                    <MenuItem key={supplier.id} value={supplier.id}>
                                        {supplier.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        }
                    />
                </FormControl>
                <FormControl className={classes.formItem}>
                    <VolumeUnit
                        disabled={!isFromOrg && allClinics.length > 1}
                        className={classes.volumeValue}
                        label={'Volume'}
                        volumeUnitRef={volumeUnitRef}
                        opts={numberOpts}
                    />
                </FormControl>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel>Volume Unit</InputLabel>
                    <Controller
                        disabled={!isFromOrg && allClinics.length > 1}
                        name="general.volUnit"
                        defaultValue={selectedProduct?.volUnit || 'mls'}
                        control={control}
                        as={
                            <Select>
                                {Object.values(PRODUCT_VOL_UNITS).map(productUnit => {
                                    return (
                                        <MenuItem key={productUnit} value={productUnit}>
                                            {productUnit}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        }
                    />
                </FormControl>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel>Product type *</InputLabel>
                    <Controller
                        disabled={!isFromOrg && allClinics.length > 1}
                        name="general.productType"
                        defaultValue={selectedProduct ? selectedProduct.type : null}
                        control={control}
                        rules={{ required: 'Product Type' }}
                        as={
                            <Select>
                                <MenuItem value={'Retail'}>Retail</MenuItem>
                                <MenuItem value={'Professional'}>Professional</MenuItem>
                                <MenuItem value={'Prescription'}>Prescription</MenuItem>
                                <MenuItem value={null}>
                                    <em>None</em>
                                </MenuItem>
                            </Select>
                        }
                    />
                </FormControl>
                <TagsInput
                    disabled={!isFromOrg && allClinics.length > 1}
                    name="general.tags"
                    control={control}
                    canAdd
                    className={classes.formItem}
                    defaultValue={selectedProduct ? selectedProduct.tags : []}
                />
            </div>
            {fields.map((item, index) => (
                <TextField
                    key={item.id}
                    inputProps={{ readOnly: !isFromOrg && allClinics.length > 1 }}
                    inputRef={register()}
                    className={`${classes.formItem} ${classes.barcodeInput}`}
                    variant="outlined"
                    label={`Barcode ${index + 1}`}
                    name={`general.barcodes[${index}].value`}
                    defaultValue={selectedProduct?.barcodes[index] || ''}
                    control={control}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start" onClick={() => remove(index)}>
                                <IconButton className={classes.removeBarcode}>
                                    <Close className={classes.removeIcon} />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            ))}
            <Button
                disabled={!isFromOrg && allClinics.length > 1}
                onClick={() => append('')}
                variant="outlined"
                className={classes.outlinedButton}
            >
                Add barcode
            </Button>
        </div>
    );
}

ProductFormGeneral.propTypes = {
    subcategories: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
    suppliers: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    getValues: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    brands: PropTypes.array.isRequired,
    selectedProduct: PropTypes.object,
    control: PropTypes.any,
    watch: PropTypes.any
};

export default withStyles(accordionFormStyles)(ProductFormGeneral);
