import React from 'react';
import PropTypes from 'prop-types';

import { withStyles, Checkbox, FormControlLabel } from '@material-ui/core';
import { coursesStyles } from '../styles';
import { getLocationItem } from '../../../collums-constants/utils';

function CommissionTab({ register, clinic, isFromOrg, selectedCourse, classes, allClinics }) {
    const allowCommission = (() => {
        if (isFromOrg || allClinics.length === 1) {
            return selectedCourse.allowCommission ?? true;
        }
        return getLocationItem(selectedCourse, clinic)?.allowCommission ?? true;
    })();

    return (
        <div className={classes.formContent}>
            <FormControlLabel
                label="Allow commission"
                control={
                    <Checkbox
                        defaultChecked={allowCommission}
                        inputProps={{ name: 'allowCommission' }}
                        inputRef={register}
                    />
                }
            />
        </div>
    );
}

CommissionTab.propTypes = {
    classes: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    selectedCourse: PropTypes.object
};

export default withStyles(coursesStyles)(CommissionTab);
