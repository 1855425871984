import React, { useState, useEffect } from 'react';
import { startOfDay, endOfDay } from 'date-fns';
import { useForm } from 'react-hook-form';
import { toastr } from 'react-redux-toastr';

import { withStyles, Button, makeStyles, Typography } from '@material-ui/core';

import styles from './styles';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';

import Modal from '../common/Modal';
import Accordion from '../common/Accordion';
import General from './forms/General';
import Services from './forms/Services';
import Courses from './forms/Courses';
import Products from './forms/Products';

import { TEMPORARY_DISCOUNTS } from '../../collums-constants/index';

import TemporaryDiscountsApi from '../../api/TemporaryDiscountsApi';
import LocationTab from '../common/LocationTab';
import OrganisationsApi from '../../api/OrganisationsApi';

function TemporaryDiscountModal({
    classes,
    open,
    closeModal,
    selectedDiscount,
    refreshList,
    page,
    rowsPerPage,
    nameFilter,
    clinics,
    clinic,
    isFromOrg,
    ...props
}) {
    const globalStyles = makeStyles(modalsButtonStyles)();
    const [selectedServices, setSelectedServices] = useState([]);
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [formData, setFormData] = useState({});
    const [isMultiClinic, setIsMultiClinic] = useState();

    const defaultValues = {
        active: selectedDiscount?.active,
        name: selectedDiscount?.name || '',
        description: selectedDiscount?.description || '',
        discountType: selectedDiscount?.discountType || TEMPORARY_DISCOUNTS.DISCOUNT_TYPES.PERCENTAGE,
        discount: selectedDiscount?.discount || null,
        discountValid: (isFromOrg
            ? selectedDiscount?.discountValid
            : selectedDiscount?.locations[0]?.discountValid) || {
            to: null,
            from: null
        },
        applyTo: selectedDiscount?.applyTo || TEMPORARY_DISCOUNTS.APPLY.TOTAL
    };

    const { register, control, handleSubmit, watch } = useForm({ defaultValues });

    const applyToFieldWatcher = watch('applyTo');
    const discountValidFrom = watch('discountValid.from');
    const discountValidTo = watch('discountValid.to');
    const selectedDiscountLoc = selectedDiscount?.locations?.find(item => item.clinic === clinic?.id);

    const title = (() => {
        if (selectedDiscount) {
            return `View/Edit ${selectedDiscount.name} Discount`;
        }
        return 'New Discount';
    })();

    useEffect(() => {
        (async () => {
            const organisation = await OrganisationsApi.getOrganisation();
            setIsMultiClinic(!!organisation?.isMultiClinic);
        })();
    }, []);

    const panelProps = {
        register,
        control,
        watch,
        selectedServices,
        setSelectedServices,
        selectedCourses,
        setSelectedCourses,
        selectedProducts,
        setSelectedProducts,
        clinics,
        clinic,
        isFromOrg,
        selectedDiscount,
        selectedItem: selectedDiscount,
        ...props
    };

    const formatDatesToIntervalOf24hrs = dates => {
        if (!dates?.from || !dates?.to) return dates;
        return { from: startOfDay(new Date(dates.from)), to: endOfDay(new Date(dates.to)) };
    };

    const discountValidIsUpdate = dates => {
        const oldDates = defaultValues?.discountValid;
        const oldDatesFrom = oldDates?.from ? new Date(oldDates.from).toString() : null;
        const oldDatesTo = oldDates?.to ? new Date(oldDates.to).toString() : null;
        const newDatesFrom = new Date(dates.from).toString();
        const newDatesTo = new Date(dates.to).toString();

        if (!oldDates || !dates) {
            return false;
        }
        return newDatesFrom !== oldDatesFrom || newDatesTo !== oldDatesTo;
    };

    const confirmSave = async () => {
        await onSubmitConfirm(formData);
        setOpenConfirm(false);
    };

    const onSubmit = async data => {
        setFormData(data);
        const formDataDiscountValid = isFromOrg ? data.discountValid : data.location.discountValid;
        const newDates = formatDatesToIntervalOf24hrs(formDataDiscountValid);
        if (discountValidIsUpdate(newDates) && selectedDiscount?.id && isMultiClinic) {
            setOpenConfirm(true);
        } else {
            if (!isFromOrg && clinics.length === 1) {
                data.discountValid = data.location.discountValid;
                data.locations = data.locations.map(loc => {
                    return {
                        clinic: loc.value,
                        active: true,
                        archived: false,
                        discountValid: data.location.discountValid
                    };
                });
                delete data.location;
            }
            await onSubmitConfirm(data);
        }
    };

    const onSubmitConfirm = async data => {
        if (!data) return;
        data.discountValid = formatDatesToIntervalOf24hrs(data?.discountValid);

        if (!data.name) {
            data.name = defaultValues.name || '';
        }

        if (!data.description) {
            data.description = defaultValues.description || '';
        }

        if (!data.discount) {
            data.discount = defaultValues.discount || null;
        }

        if (!isFromOrg && clinics.length > 1) {
            const discountValid = formatDatesToIntervalOf24hrs({
                from: discountValidFrom,
                to: discountValidTo
            });
            data.discountValid = discountValid;
            if (
                data.location &&
                new Date(discountValid.from).toISOString() ===
                    new Date(data.location.discountValid.from).toISOString() &&
                new Date(discountValid.to).toISOString() === new Date(data.location.discountValid.to).toISOString()
            ) {
                // ---- Data is equal to the default set at org level
                delete data.location;
            } else {
                // ---- Data picked is different to the default -> assign it to loc level
                data.locations = selectedDiscount.locations.map(item => {
                    if (data.location && item.clinic === clinic.id) {
                        return {
                            ...item,
                            discountValid: formatDatesToIntervalOf24hrs(data.location.discountValid)
                        };
                    } else {
                        return item;
                    }
                });
                delete data.location;
            }
        }
        const dbOperation = async body => {
            // body.discountValid = formatDatesToIntervalOf24hrs(body.discountValid);
            if (selectedDiscount && selectedDiscount.id) {
                await TemporaryDiscountsApi.update(selectedDiscount.id, body);
                toastr.success('Success!', 'Your discount was successfully saved');
                return;
            }
            await TemporaryDiscountsApi.create({ ...body, active: true });
            toastr.success('Success!', 'Your discount was successfully created');
        };

        try {
            const services = selectedServices.map(service => {
                return {
                    type: TEMPORARY_DISCOUNTS.ITEMS_TYPES.SERVICE,
                    item: service
                };
            });

            const courses = selectedCourses.map(course => {
                return {
                    type: TEMPORARY_DISCOUNTS.ITEMS_TYPES.COURSE,
                    item: course
                };
            });

            const products = selectedProducts.map(product => {
                return {
                    type: TEMPORARY_DISCOUNTS.ITEMS_TYPES.PRODUCT,
                    item: product
                };
            });
            if (isFromOrg) {
                data.locations = data.locations
                    .filter(loc => loc.isSelected)
                    .map(loc => {
                        let existentLocation = selectedDiscount?.locations.find(item => item.clinic === loc.value);
                        if (!existentLocation && selectedDiscount) {
                            existentLocation = { active: selectedDiscount.active, archived: selectedDiscount.archived };
                        }
                        loc.discountValid = formatDatesToIntervalOf24hrs(
                            existentLocation?.discountValid || {
                                from: discountValidFrom,
                                to: discountValidTo
                            }
                        );

                        const initialDate = selectedDiscount?.discountValid;

                        if (initialDate) {
                            // ---- if initial (default) matches current loc date, change to new values
                            if (
                                new Date(initialDate?.from).toISOString() ===
                                    new Date(loc.discountValid?.from).toISOString() &&
                                new Date(initialDate?.to).toISOString() ===
                                    new Date(loc.discountValid?.to).toISOString()
                            ) {
                                existentLocation.discountValid = data.discountValid;
                            }
                        }
                        return {
                            // CHANGE TERNARIES AFTERWARDS!!!
                            clinic: loc.value,
                            discountValid: formatDatesToIntervalOf24hrs({
                                from: discountValidFrom,
                                to: discountValidTo
                            }),
                            active: selectedDiscount
                                ? existentLocation
                                    ? selectedDiscount?.active
                                        ? existentLocation?.active
                                        : false
                                    : selectedDiscount?.active
                                : true,
                            archived: selectedDiscount
                                ? existentLocation
                                    ? selectedDiscount?.archived
                                        ? true
                                        : existentLocation?.archived
                                    : selectedDiscount?.archived
                                : false
                        };
                    });
            }
            const body = {
                ...data,
                eligableItems: [...services, ...courses, ...products]
            };

            await dbOperation(body);
            closeModal();
            await refreshList(nameFilter, page, rowsPerPage);
        } catch (e) {
            console.error(e);
            toastr.error('Oops!', 'Something went wrong');
        }
    };

    const onError = () => {
        toastr.error('Missing Fields!', 'Complete all the fields on General to proceed');
        return;
    };

    useEffect(() => {
        if (!selectedDiscount) return;

        const products = (selectedDiscount.eligableItems || [])
            .filter(item => item.type === TEMPORARY_DISCOUNTS.ITEMS_TYPES.PRODUCT)
            .map(item => item.item);
        const courses = (selectedDiscount.eligableItems || [])
            .filter(item => item.type === TEMPORARY_DISCOUNTS.ITEMS_TYPES.COURSE)
            .map(item => item.item);
        const services = (selectedDiscount.eligableItems || [])
            .filter(item => item.type === TEMPORARY_DISCOUNTS.ITEMS_TYPES.SERVICE)
            .map(item => item.item);

        setSelectedServices(services);
        setSelectedCourses(courses);
        setSelectedProducts(products);
    }, [selectedDiscount]);

    return (
        <Modal
            id="temporaryDiscountsModal"
            isOpen={open}
            title={title}
            size={'md'}
            onClose={closeModal}
            onCancel={closeModal}
        >
            <Modal
                isOpen={openConfirm}
                size={'sm'}
                confirmLabel={'Continue'}
                confirmButtonColored={true}
                onConfirm={confirmSave}
                onClose={confirmSave}
            >
                <Typography style={{ textAlign: 'center', marginBottom: '15px' }} variant={'h4'}>
                    {isFromOrg
                        ? 'These changes will be made at all locations'
                        : "Don't forget to make the same change at Organisation level"}
                </Typography>
            </Modal>

            <form onSubmit={handleSubmit(onSubmit, onError)} className={classes.accordionsForm}>
                <Accordion title="General">
                    <General
                        {...panelProps}
                        discountValidFrom={
                            !isFromOrg && selectedDiscountLoc?.discountValid
                                ? selectedDiscountLoc.discountValid.from
                                : discountValidFrom
                        }
                        discountValidTo={
                            !isFromOrg && selectedDiscountLoc?.discountValid
                                ? selectedDiscountLoc.discountValid.to
                                : discountValidTo
                        }
                    />
                </Accordion>
                <Accordion title={'Locations'} hideItem={!isFromOrg}>
                    {' '}
                    <LocationTab {...panelProps} key="pricing_location" />{' '}
                </Accordion>
                {applyToFieldWatcher === TEMPORARY_DISCOUNTS.APPLY.ITEM && (
                    <>
                        <Accordion title="Services">
                            <Services {...panelProps} />
                        </Accordion>
                        <Accordion title="Courses">
                            <Courses {...panelProps} />
                        </Accordion>
                        <Accordion title="Products">
                            <Products {...panelProps} />
                        </Accordion>
                    </>
                )}

                <div className={globalStyles.buttonsContainer}>
                    <Button className={globalStyles.cancelButton} onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button className={globalStyles.confirmButton} type="submit" variant="outlined">
                        Submit
                    </Button>
                </div>
            </form>
        </Modal>
    );
}

export default withStyles(styles)(TemporaryDiscountModal);
