import api from '../collums-components/api/config';

class SuppliersApi {
    static getAll(clinic, isFromOrg = false) {
        return api.request({
            method: 'GET',
            url: `suppliers?clinic=${clinic}&isFromOrg=${isFromOrg}`
        });
    }

    static search(queryString) {
        return api.request({
            method: 'GET',
            url: `suppliers/filters${queryString ? `?${queryString}` : ''}`
        });
    }

    static searchById(id) {
        return api.request({
            method: 'GET',
            url: `suppliers/search/${id}`
        });
    }

    static update(id, data) {
        return api.request({
            method: 'PUT',
            url: `suppliers/${id}`,
            data
        });
    }

    static getBrands() {
        return api.request({
            method: 'GET',
            url: 'stocks/brands'
        });
    }

    static addNew(newItem) {
        return api.request({
            method: 'POST',
            url: 'suppliers',
            data: { ...newItem }
        });
    }

    static delete(id) {
        return api.request({
            method: 'DELETE',
            url: `suppliers/${id}`
        });
    }
}

export default SuppliersApi;
