import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import ConfirmModal from '../common/ConfirmModal';
import { toastr } from 'react-redux-toastr';

import View from '../common/View';

import { listClinics, archiveClinic /*updateClinic, deleteClinic */ } from '../../collums-components/api/ClinicApi';

import ClinicFormModal from './ClinicFormModal';
import ListPagination from '../common/ListPagination';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import { getOrganisationCountryCode } from '../../collums-components/helpers';
import OrganisationApi from '../../api/OrganisationsApi';
import InformationModal from '../common/InformationModal';
import { Grid, Typography } from '@material-ui/core';
import OrganisationsApi from '../../api/OrganisationsApi';

function ClinicsView() {
    const [tableData, setTableData] = useState([]);
    const [selectedClinic, setSelectedClinic] = useState(null);
    const [clinicFormOpen, setClinicFormOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [showArchiveds, setShowArchiveds] = useState(false);
    const [archiveConfirmModal, setArchiveConfirmModal] = useState(false);
    const [countryCode, setCountryCode] = useState(null);
    const [reminderPopupOpen, setReminderPopupOpen] = useState(false);
    const [isMultiClinic, setIsMultiClinic] = useState(false);

    useEffect(() => {
        const getOrganisation = async () => {
            return await OrganisationApi.getOrganisation();
        };

        if (!countryCode) {
            getOrganisation().then(org => {
                setCountryCode(getOrganisationCountryCode(org));
            });
        }
    }, [countryCode]);

    function onView(item) {
        history.push(`/organisation/clinics?${queryString.stringify({ clinicId: item.id })}`);
    }

    function onAdd() {
        history.push(`/organisation/clinics?${queryString.stringify({ view: 'add' })}`);
    }

    useEffect(() => {
        (async () => {
            const organisation = await OrganisationsApi.getOrganisation();
            setIsMultiClinic(!!organisation?.isMultiClinic);
        })();
    }, []);

    /*async function onEdit(item) {
        const clinicId = item.id;
        delete item.id;

        await updateClinic(clinicId, item);
        await loadClinics();
    }*/

    /* async function onDelete(id) {
        try {
            await deleteClinic(id).then(() => {
                toastr.success('Clinic successfully deleted');
                loadClinics();
            });
            return true;
        } catch (err) {
            return false;
        }
    } */

    function onCloseClinicDetails() {
        setSelectedClinic(null);
        setClinicFormOpen(false);
        history.push('/organisation/clinics');
    }

    async function loadClinics(skip, limit, queryParam = '') {
        try {
            setIsLoading(true);
            if (queryParam.indexOf('archived=false') >= 0) setShowArchiveds(false);
            else if (queryParam.indexOf('archived=true') >= 0) setShowArchiveds(true);

            if (showArchiveds && queryParam === '') {
                queryParam = 'archived=true';
            }
            queryParam = queryParam.split('&')[0];
            const data = await listClinics(queryParam);
            const clinics = data.map(clinic => ({
                ...clinic,
                lateCancellationChargeValue: clinic.lateCancellationChargeValue || 'NONE'
            }));
            setTableData(clinics);
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        const parsedQueryString = queryString.parse(history.location.search);

        if (parsedQueryString.clinicId) {
            setSelectedClinic(parsedQueryString.clinicId);
            setClinicFormOpen(true);
        }

        if (parsedQueryString.view === 'add') setClinicFormOpen(true);

        loadClinics();
        /* eslint-disable-next-line */
    }, [history.location.search]);

    const HandleArchiveClinic = async () => {
        try {
            await archiveClinic(selectedClinic.id).then(res => {
                toastr.success(`Clinic ${res.accountName} successfully ${res.archived ? 'unarchived' : 'archived'}`);
            });
            loadClinics();
        } catch (err) {
            toastr.error('something went wrong');
            return;
        }
    };

    const openArchive = clinic => {
        setSelectedClinic(clinic);
        setArchiveConfirmModal(true);
    };

    const handleRedirect = clinicId => {
        history.push(`/location/${clinicId}/equipment`);
    };

    const actions = [
        {
            title: 'archive',
            action: openArchive
        },
        {
            title: 'Settings',
            action: handleRedirect
        }
    ];

    return (
        <View>
            <ListPagination
                isFromOrg={true}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                data={tableData}
                maxSize={tableData.length}
                defaultColumns={[
                    { title: 'Name', field: 'accountName', onClick: onView },
                    { title: 'Public Email', field: 'publicEmail' },
                    { title: 'Address', field: 'address' },
                    { title: 'City', field: 'city' },
                    { title: 'Telephone', field: 'telephone' },
                    { title: 'Email', field: 'email' },
                    { title: 'Area', field: 'area' }
                ]}
                actions={actions}
                onCreate={onAdd}
                filters={[{ name: 'archived', label: 'Show Archived', value: false }]}
                highlightArchived={true}
                ApiCall={loadClinics}
            />
            {clinicFormOpen && (
                <ClinicFormModal
                    open={clinicFormOpen}
                    clinicId={selectedClinic}
                    onClose={onCloseClinicDetails}
                    countryCode={countryCode}
                    openReminderPopup={() => setReminderPopupOpen(true)}
                />
            )}

            <InformationModal
                open={reminderPopupOpen && isMultiClinic}
                onClose={() => {
                    setReminderPopupOpen(false);
                }}
                title={'Don’t forget to complete your new location setup'}
                contentMaxWidth={'335px'}
                closeLabel="Continue"
            >
                <Grid container>
                    <Grid item>
                        <Typography paragraph={true} display="block">
                            Please remember to update location availability for:
                        </Typography>
                        <Typography paragraph={true} display="block">
                            Staff, Service Categories, Services, Course Categories, Courses, Product Categories,
                            Products and Product Brands.
                        </Typography>
                    </Grid>
                </Grid>
            </InformationModal>
            <LoadingScreen isVisible={isLoading} />
            {archiveConfirmModal && (
                <ConfirmModal
                    isOpen
                    setIsOpen={setArchiveConfirmModal}
                    onConfirm={HandleArchiveClinic}
                    title={`${selectedClinic.archived ? 'Unarchive' : 'Archive'} Location`}
                    content={`Are you sure you want to ${selectedClinic.archived ? 'unarchive' : 'archive'} ${
                        selectedClinic.accountName
                    } location`}
                />
            )}
        </View>
    );
}

export default ClinicsView;
