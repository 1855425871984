import React, { useEffect, useRef, useState } from 'react';
import { FormControl, InputLabel, makeStyles, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { serviceDetailsModalStyles } from '../services/styles';
import { useDebounce } from '../../collums-components/helpers';

function StaffInputs({ fieldName, currentIndex, option, setValue, defaultValue, taxValue }) {
    const classes = makeStyles(serviceDetailsModalStyles)();
    const [duration, setDuration] = useState(defaultValue ? defaultValue.duration : undefined);
    const [netPrice, setNetPrice] = useState(defaultValue ? defaultValue.netPrice : undefined);
    const [grossPrice, setGrossPrice] = useState(defaultValue ? defaultValue.grossPrice : undefined);
    const isFirstRender = useRef(true);

    const debounceDuration = useDebounce(duration, 500);
    const debounceNetPrice = useDebounce(netPrice, 500);
    const debounceGrossPrice = useDebounce(grossPrice, 500);

    const handleNaN = num => {
        if (isNaN(num)) {
            setNetPrice(null);
            setGrossPrice(null);
            return true;
        }
    };

    const changeValue = (num, isNet = true) => {
        if (isNet) {
            if (taxValue?.rate) {
                const value = Math.round(num * (taxValue?.rate / 100 + 1) * 100) / 100;
                setGrossPrice(value);
                return;
            }
            setGrossPrice(num);
        } else {
            if (taxValue?.rate) {
                const value = Math.round((num / (taxValue?.rate / 100 + 1)) * 100) / 100;
                setNetPrice(value);
                return;
            }
            setNetPrice(num);
        }
    };

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        if (netPrice !== undefined) {
            const netPriceAsNumber = Number(netPrice);
            if (handleNaN(netPriceAsNumber)) return;
            changeValue(netPriceAsNumber);
            return;
        }
        if (grossPrice !== undefined) {
            const grossPriceAsNumber = Number(grossPrice);
            if (handleNaN(grossPriceAsNumber)) return;
            changeValue(grossPriceAsNumber, false);
            return;
        }
        //eslint-disable-next-line
    }, [taxValue]);

    useEffect(() => {
        setValue(option, {
            grossPrice: grossPrice === '' ? undefined : grossPrice,
            netPrice: netPrice === '' ? undefined : netPrice,
            duration: duration === '' ? undefined : duration
        });
        /*eslint-disable-next-line */
    }, [debounceDuration, debounceNetPrice, debounceGrossPrice]);

    return (
        <>
            <div className={classes.rowInputItems}>
                <div>
                    <FormControl variant="outlined">
                        <InputLabel shrink>Durations (mins)</InputLabel>
                        <TextField
                            value={Number(duration) === 0 ? '' : duration}
                            name={`${fieldName}[${currentIndex}].duration`}
                            onChange={e => {
                                if (!e.target.value) {
                                    setDuration(null);
                                    return;
                                }
                                const newValue = Number(e.target.value).toFixed(0);
                                setDuration(String(newValue));
                            }}
                            variant="outlined"
                            InputProps={{
                                classes: {
                                    input: classes.textFieldInputProps
                                }
                            }}
                            InputLabelProps={{
                                className: classes.textFieldInputLabelProps
                            }}
                        />
                    </FormControl>
                </div>
                <div>
                    <FormControl variant="outlined">
                        <InputLabel shrink>Net Price</InputLabel>
                        <TextField
                            value={Number(netPrice) === 0 ? '' : netPrice}
                            name={`${fieldName}[${currentIndex}].netPrice`}
                            onChange={e => {
                                if (!e.target.value) {
                                    setGrossPrice(null);
                                    setNetPrice(null);
                                    return;
                                }
                                setNetPrice(e.target.value);
                                const newValue = Math.round(Number(e.target.value) * 100) / 100;
                                if (taxValue?.rate) {
                                    const newGrossPrice = Math.round(newValue * (taxValue?.rate / 100 + 1) * 100) / 100;
                                    setGrossPrice(String(newGrossPrice));
                                    return;
                                }
                                setGrossPrice(String(newValue));
                            }}
                            variant="outlined"
                            InputProps={{
                                classes: {
                                    input: classes.textFieldInputProps
                                }
                            }}
                            InputLabelProps={{
                                shrink: true,
                                className: classes.textFieldInputLabelProps
                            }}
                        />
                    </FormControl>
                </div>
                <div>
                    <FormControl variant="outlined">
                        <InputLabel shrink>Gross Price</InputLabel>
                        <TextField
                            value={Number(grossPrice) === 0 ? '' : grossPrice}
                            name={`${fieldName}[${currentIndex}].grossPrice`}
                            onChange={e => {
                                if (!e.target.value) {
                                    setGrossPrice(null);
                                    setNetPrice(null);
                                    return;
                                }
                                setGrossPrice(e.target.value);
                                const newValue = Math.round(Number(e.target.value) * 100) / 100;
                                if (taxValue?.rate) {
                                    const newNetPrice = Math.round((newValue / (taxValue?.rate / 100 + 1)) * 100) / 100;
                                    setNetPrice(String(newNetPrice));
                                    return;
                                }
                                setNetPrice(String(newValue));
                            }}
                            variant="outlined"
                            InputProps={{
                                classes: {
                                    input: classes.textFieldInputProps
                                }
                            }}
                            InputLabelProps={{
                                shrink: true,
                                className: classes.textFieldInputLabelProps
                            }}
                        />
                    </FormControl>
                </div>
            </div>
        </>
    );
}

StaffInputs.propTypes = {
    currentIndex: PropTypes.any,
    fieldName: PropTypes.string,
    control: PropTypes.func,
    register: PropTypes.func,
    setValue: PropTypes.func,
    getValues: PropTypes.func,
    watch: PropTypes.func
};

export default StaffInputs;
