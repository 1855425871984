export const autocompleteStyles = theme => ({
    formControl: {
        '& .MuiInputBase-root': {
            height: 38,
            padding: 0,
            paddingLeft: 12
        },
        '& .MuiInputBase-input': {
            paddingTop: '8px !important'
        },
        '& fieldset': {
            borderColor: theme.palette.black.full
        }
    }
});

export const buttonStyles = () => ({
    primaryButton: {
        height: 44,
        margin: '0px 10px 10px 0px',
        padding: '6px 14px',
        borderRadius: 0,
        backgroundColor: '#b41778',
        '&:hover': {
            backgroundColor: '#912d6b'
        },
        '&:active': {
            backgroundColor: '#6e2251'
        }
    },
    primaryText: {
        fontSize: 14,
        color: '#ffffff',
        textTransform: 'none'
    },
    secondaryButton: {
        height: 44,
        margin: '0px 10px 10px 0px',
        padding: '6px 14px',
        borderRadius: 0,
        backgroundColor: '#2b78e4',
        '&:hover': {
            backgroundColor: '#286cd3'
        },
        '&:active': {
            backgroundColor: '#215bad'
        }
    },
    secondaryText: {
        fontSize: 14,
        color: '#ffffff',
        textTransform: 'none'
    },
    cancelButton: {
        height: 44,
        margin: '0px 10px 10px 0px',
        padding: '6px 14px',
        borderRadius: 0,
        borderColor: '#575A64',
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: '#f0f0f0'
        },
        '&:active': {
            backgroundColor: '#d8d8d8'
        }
    },
    cancelText: {
        fontSize: 14,
        color: '#575A64',
        textTransform: 'none'
    }
});

export const dateInputStyles = theme => ({
    root: {
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 0
        }
    },
    blackBorder: {
        '& fieldset': {
            borderColor: theme.palette.black.full
        }
    }
});

export const selectStyles = theme => ({
    formControl: {
        '& .MuiSelect-select:focus': {
            backgroundColor: 'inherit'
        },
        '& .MuiSelect-root': {
            paddingTop: 22
        },
        '& fieldset': {
            borderColor: theme.palette.black.full
        }
    }
});

export const testFieldStyles = theme => ({
    textField: {
        '& fieldset': {
            borderColor: theme.palette.black.full
        }
    }
});

export const badgeLoading = () => ({
    badgeWraper: {
        width: '5.2rem',
        height: 'fit-content',
        zIndex: '1',
        borderRadius: '5px',
        display: 'flex',
        placeContent: 'space-between',
        padding: '4px 7px',
        fontWeight: 'bold',
        transition: 'visibility 0s, opacity 0.5s linear',
        '& .MuiCircularProgress-colorPrimary': {
            width: '14px !important',
            height: 'unset !important'
        },
        '& .MuiSvgIcon-root': {
            width: '18px !important',
            height: 'unset !important'
        }
    },
    badgeLoading: {
        backgroundColor: '#fbfb9d',
        width: '5rem'
    },
    badgeComplete: {
        backgroundColor: '#84e595',
        width: '5.5rem'
    },
    centerSpinner: {
        display: 'flex',
        alignItems: 'center'
    }
});

export const smsEditor = () => ({
    smsBtn: {
        cursor: 'pointer',
        marginLeft: 8
    },
    smsInput: {
        '& .MuiInputBase-input': {
            lineHeight: 1.1
        },
        '& textarea::placeholder': {
            fontWeight: 400,
            fontSize: '14px !important',
            color: '#333 !important',
            fontFamily: 'Roboto, Arial, "sans-serif" !important'
        }
    },
    helperText: {
        color: '#9c9c9c',
        textAlign: 'left',
        fontSize: '12px'
    },
    marginTop8px: {
        marginTop: 8
    },
    link: {
        color: '#3083EC',
        textTransform: 'none',
        textDecoration: 'none'
    }
});

export const emailEditor = () => ({
    editor: {
        height: 430,
        width: '100% !important',

        '& .rdw-editor-main, & .cm-s-default': {
            borderColor: '#7C7E86',
            borderStyle: 'solid',
            borderWidth: 1
        }
    },
    emailRoot: {
        width: '100%'
    }
});

export const messageForm = () => ({
    selectRoot: {
        backgroundColor: 'white',
        width: '12rem'
    },
    selectLabel: {
        opacity: 1,
        marginTop: '-0.25rem',
        marginLeft: '1rem'
    },
    mergeTags: {
        overflowY: 'scroll'
    },
    mergeBtn: {
        background: 'white',
        width: '100%',
        boxShadow: 'none',
        padding: 0,
        marginBottom: 10,
        borderWidth: 1,
        marginRight: 12,
        borderRadius: 0,
        '&:hover': {
            background: 'white',
            boxShadow: 'none'
        }
    },
    testBtn: {
        background: '#72c2f6',
        boxShadow: 'none',
        '&:hover': {
            background: '#72c2f6',
            boxShadow: 'none'
        }
    },
    nextBtn: {
        background: '#B41678',
        boxShadow: 'none',
        borderRadius: 0,
        '&:hover': {
            background: '#B41678',
            boxShadow: 'none'
        }
    },
    cancelBtn: {
        background: 'white',
        boxShadow: 'none',
        color: '#7C7E86',
        borderColor: '#7C7E86',
        borderStyle: 'solid',
        borderWidth: 1,
        marginRight: 12,
        borderRadius: 0,
        '&:hover': {
            background: 'white',
            boxShadow: 'none'
        }
    }
});

export const urlShorter = () => ({
    urlShorterWraper: {
        border: '1px solid #c4c4c4',
        borderRadius: '4px',
        width: '11rem',
        padding: '1rem',
        position: 'absolute',
        right: '50%',
        zIndex: '2',
        backgroundColor: 'white'
    },
    urlShorterBtn: {
        marginTop: '1rem',
        display: 'flex',
        justifyContent: 'space-between'
    },
    nextBtn: {
        background: '#B41678',
        boxShadow: 'none',
        borderRadius: 0,
        height: '2rem',
        color: 'white',
        '&:hover': {
            background: '#B41678',
            boxShadow: 'none'
        }
    },
    cancelBtn: {
        background: 'white',
        height: '2rem',
        boxShadow: 'none',
        color: '#7C7E86',
        borderColor: '#7C7E86',
        borderStyle: 'solid',
        borderWidth: 1,
        marginRight: 12,
        borderRadius: 0,
        '&:hover': {
            background: 'white',
            boxShadow: 'none'
        }
    }
});

export const messageTest = () => ({
    testBtn: {
        background: '#72c2f6',
        boxShadow: 'none',
        '&:hover': {
            background: '#72c2f6',
            boxShadow: 'none'
        }
    }
});

export const modal = () => ({
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '50%'
    },
    modalHTMLWrapContent: {
        backgroundColor: 'white',
        width: '100%',
        minHeight: '100%',
        borderRadius: '4px'
    },
    break: {
        display: 'break'
    },
    modalHTMLHeader: {
        float: 'left',
        minWidth: '4rem',
        textAlign: 'right',
        marginRight: '1rem'
    },
    hrCustom: {
        width: '97%',
        margin: '1rem 0'
    },
    modalHTMLContent: {
        overflow: 'auto'
    }
});
