import React from 'react';
import PropTypes from 'prop-types';

import { withStyles, Checkbox, FormControlLabel } from '@material-ui/core';
import { accordionFormStyles } from '../styles';

function CommissionTab({ register, selectedProduct, classes, clinic }) {
    const localCommission = (() => {
        const targetLocation = selectedProduct?.locations?.find(loc => {
            return loc.clinic === clinic;
        });
        if (targetLocation) return targetLocation.allowCommission ?? true;
        return selectedProduct?.allowCommission ?? true;
    })();

    return (
        <div className={classes.formContent}>
            <FormControlLabel
                label="Allow commission"
                control={
                    <Checkbox
                        defaultChecked={localCommission}
                        inputProps={{ name: 'allowCommission' }}
                        inputRef={register}
                    />
                }
            />
        </div>
    );
}

CommissionTab.propTypes = {
    classes: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    selectedProduct: PropTypes.object
};

export default withStyles(accordionFormStyles)(CommissionTab);
