export const stripeView = () => ({
    container: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontSize: '14px',
        margin: 16,
        padding: 16,
        '& div': {
            '& b': {
                fontWeight: 500
            }
        }
    },
    revokeBtn: {
        backgroundColor: 'red',
        color: 'white',

        '&:hover': {
            backgroundColor: 'red'
        }
    },
    progress: {
        verticalAlign: 'top',
        marginLeft: 10
    },
    dashboardButton: {
        marginTop: 20,
        marginBottom: 20,

        '& button': {
            backgroundColor: '#2b78e4',
            padding: '3px 9px'
        },
        '& button:hover': {
            backgroundColor: '#2b78e4'
        }
    }
});
