import {
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import React, { useMemo, useEffect } from 'react';
import { serviceDetailsModalStyles } from '../../services/styles';
import { tabsStyles } from '../styles';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import theme from '../../../theme';
import HelpIcon from '@material-ui/icons/Help';

export default function OnlineBookingTab({ isFromOrg, setValue, watch, control, register, practitioner }) {
    const classes = makeStyles(() => ({ ...serviceDetailsModalStyles(theme), ...tabsStyles() }))();
    const [listOptions, setListOptions] = useState();

    const clinics = watch('clinics');

    const initialOptions = useMemo(
        () =>
            clinics?.length &&
            clinics.map(clinic => {
                let isSelected = false;
                const selectedItemLocations = practitioner?.locations
                    ?.map(item => (item.allowOnlineBooking ? item.clinic?.id : false))
                    .filter(el => el);
                if (selectedItemLocations?.includes(clinic.value)) {
                    isSelected = true;
                }

                return {
                    ...clinic,
                    isSelected
                };
            }),
        [clinics, practitioner]
    );

    useEffect(() => {
        if (!listOptions || listOptions.length === 0) {
            setListOptions(initialOptions);
            setValue('onlineBooking', initialOptions);
        }
    }, [clinics, initialOptions, practitioner]); //eslint-disable-line

    return (
        <>
            <div className={classes.onlineBookingContent}>
                <p>Allow online booking: </p>

                {Boolean(listOptions?.length) && (
                    <Controller
                        name={'onlineBooking'}
                        control={control}
                        defaultValue={listOptions}
                        rules={register}
                        render={({ onChange, value }) => {
                            const handleToggle = option => {
                                const currentIndex = (value || []).findIndex(v => v.value === option.value);
                                const newItems = [...value];
                                const newItem = {
                                    ...value[currentIndex],
                                    isSelected: !value[currentIndex].isSelected
                                };
                                newItems.splice(currentIndex, 1, newItem);
                                onChange(newItems);
                            };
                            const checkAll = (e, checked) => {
                                const newItems = [
                                    ...value.map(item => {
                                        item.isSelected = checked;
                                        return item;
                                    })
                                ];
                                onChange(newItems);
                            };

                            return (
                                <>
                                    {isFromOrg && value.length > 1 && (
                                        <div className={classes.locationFilter}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={checkAll}
                                                        edge="start"
                                                        tabIndex={-1}
                                                        disableRipple
                                                        // checked={locations?.length && locations.filter(item => item.isSelected).length === locations.length}
                                                    />
                                                }
                                                label="Select all"
                                            />
                                        </div>
                                    )}
                                    <List>
                                        {!value.length && 'Loading...'}
                                        {value.map((option, idx) => (
                                            <>
                                                <ListItem key={idx} role={undefined} dense button>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            onClick={() => handleToggle(option)}
                                                            edge="start"
                                                            checked={option.isSelected}
                                                            tabIndex={-1}
                                                            disableRipple
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary={option.label} secondary={option.subtitle} />
                                                </ListItem>
                                                <Divider />
                                            </>
                                        ))}
                                    </List>
                                </>
                            );
                        }}
                    />
                )}
            </div>

            <div className={classes.onlineBookingContent}>
                <FormControl className={classes.formItem} variant="outlined">
                    <p>
                        Priority when “Any practitioner” is selected
                        <Tooltip
                            className={classes.tooltip}
                            title={
                                <Typography className={classes.typography}>
                                    When selecting “Any practitioner” in online booking, choose the priority of the
                                    staff to be booked. Enter number 1 for this staff member to be booked first (if
                                    available). If not available, the system will choose the staff you save as number 2
                                    and so on.
                                </Typography>
                            }
                            aria-label="help"
                        >
                            <HelpIcon className={classes.helpIcon} />
                        </Tooltip>
                    </p>
                    <TextField inputRef={register} name="order" variant="outlined" type="number" />
                </FormControl>
            </div>
        </>
    );
}
