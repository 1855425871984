import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography, ListItem, Checkbox } from '@material-ui/core';
import { useDrag, useDrop } from 'react-dnd';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

function ReorderStaffCard({
    classes,
    item,
    handleDrop,
    handleChange,
    onChange,
    value,
    type,
    disabled = false,
    hidden = false
}) {
    const draggable = useRef(null);

    const [, drag] = useDrag({
        item: {
            type: type,
            payload: item
        }
    });

    const [, drop] = useDrop({
        accept: [type],
        drop: colectedProps => {
            handleDrop(colectedProps.payload, item, onChange, value);
        }
    });

    return (
        <ListItem
            display="flex"
            justifyContent="space-between"
            className={`${classes.listItem} ${hidden ? classes.hidden : ''}`}
            ref={drag(drop(draggable))}
            p={1}
            pr={3}
            mb={1}
            bgcolor="#ffffff"
        >
            <DragIndicatorIcon style={{ cursor: 'grab' }} />
            <Checkbox
                disabled={disabled}
                checked={item?.isSelected}
                onChange={() => handleChange(value, onChange, item.id)}
                indeterminate={item?.indeterminate}
            />
            <Typography className={classes.textOverflow}>{item.name || item.label || ''}</Typography>
        </ListItem>
    );
}

ReorderStaffCard.propTypes = {
    classes: PropTypes.object.isRequired,
    item: PropTypes.object,
    handleDrop: PropTypes.func,
    handleChange: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.array,
    type: PropTypes.string,
    disabled: PropTypes.bool
};

export default withStyles()(ReorderStaffCard);
