import React, { useEffect, useState, useCallback, useRef } from 'react';
import { withStyles } from '@material-ui/core';
import { toastr } from 'react-redux-toastr';

import { taxRateStyles } from '../styles';
import CommissionsModal from '../CommissionsModal';

import TaxesApi from '../../../api/TaxesApi';
import CommissionApi from '../../../collums-components/api/CommissionApi';
import CategoryApi from '../../../collums-components/api/CategoryApi';

import View from '../../common/View';
import ConfirmModal from '../../common/ConfirmModal';
import ListPagination from '../../common/ListPagination';

import getCommissionsData from '../../../services/getCommissionsData';

function CommissionsTab({ classes, expanded, setIsLoading }) {
    const [data, setData] = useState([]);
    const [confirmModal, setConfirmModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState('');
    const [selectedCommission, setSelectedCommission] = useState(false);
    const [isCommissionsModalVisible, setIsCommissionsModalVisible] = useState(false);
    const showArchived = useRef(false);
    const showInactive = useRef(false);
    const [serviceCategories, setServiceCategories] = useState([]);

    useEffect(() => {
        const updateCategories = async () => {
            const newCategories = await CategoryApi.getAll();
            setServiceCategories(newCategories);
        };

        updateCategories();
    }, []);

    useEffect(() => {
        const run = async () => {
            if (!data?.length && expanded) {
                try {
                    setIsLoading(true);
                    const data = await getCommissions(
                        page,
                        rowsPerPage,
                        `archived=${showArchived.current}&inactive=${showInactive.current}`
                    );
                    setData(data);
                } catch (err) {
                    return toastr.error(err?.data?.message || 'Something went wrong');
                } finally {
                    setIsLoading(false);
                }
            }
        };
        run();
    }, [expanded]); //eslint-disable-line

    const submit = useCallback(
        (formData, selectedCommission) => {
            if (!selectedCommission || selectedCommission === 'new') {
                createCommission(formData);
            }
            if (formData && selectedCommission && selectedCommission !== 'new') {
                formData.id = undefined;
                formData.active = selectedCommission.active ?? true;
                formData.archived = selectedCommission.archived ?? true;
                updateCommission(selectedCommission.id, formData);
            }
        },
        [] //eslint-disable-line
    );

    const getCommissions = async (skip, limit, queryParam = '') => {
        try {
            // setIsLoading(true);
            const query = {
                archived: showInactive.current,
                inactive: showArchived.current
            };
            if (queryParam.indexOf('archived=true') >= 0) {
                showArchived.current = true;
                query.archived = true;
            } else {
                showArchived.current = false;
                query.archived = false;
            }
            if (queryParam.indexOf('inactive=true') >= 0) {
                showInactive.current = true;
                query.inactive = true;
            } else {
                showInactive.current = false;
                query.inactive = false;
            }

            const commissions = await CommissionApi.list(query);
            setData(commissions);
            return commissions;
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            // setIsLoading(false);
        }
    };

    const openCommission = useCallback(
        commission => {
            if (commission && commission.id) {
                setSelectedCommission(getCommissionsData(commission, { services: serviceCategories }));
            } else {
                setSelectedCommission('new');
            }
            setIsCommissionsModalVisible(true);
        },
        [serviceCategories]
    );

    const createCommission = async data => {
        try {
            setIsLoading(true);
            data.active = true;
            data.archived = false;
            await CommissionApi.create(data);
            CloseCommissionModal();
            getCommissions(page, rowsPerPage, `archived=${showArchived.current}&inactive=${showInactive.current}`);
            return toastr.success('Success creating a new commission');
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    };

    const updateCommission = async (commissionId, data) => {
        try {
            setIsLoading(true);
            await CommissionApi.update(commissionId, data);
            CloseCommissionModal();
            getCommissions(page, rowsPerPage, `archived=${showArchived.current}&inactive=${showInactive.current}`);
            return toastr.success(`Success updating ${data.name} tax`);
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    };
    const CloseCommissionModal = () => {
        setSelectedCommission();
        setIsCommissionsModalVisible(false);
    };

    const openConfirmModal = (title, component) => {
        setConfirmModal(true);
        setModalTitle(title);
        setModalContent(component);
    };
    const closeConfirmModal = () => {
        setConfirmModal(false);
        setModalTitle('');
        setModalContent();
    };

    const handleArchive = async () => {
        if (!selectedCommission) return;
        try {
            setIsLoading(true);
            const commission = selectedCommission;
            const commissionId = selectedCommission.id;
            await CommissionApi.archive(commissionId);
            getCommissions(page, rowsPerPage, `archived=${showArchived.current}&inactive=${showInactive.current}`);
            return toastr.success(
                `Commission ${commission.name} successfully ${commission.archived ? 'archived' : 'unarchived'}`
            );
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    };

    const toDo = () => {
        if (modalTitle === 'Archive Commission') handleArchive();
    };

    const openArchive = async commission => {
        setSelectedCommission(commission);
        openConfirmModal(
            'Archive Commission',
            `Are you sure you want to ${(commission.name || {}).archived ? 'unarchive' : 'archive'} this commission?`
        );
    };
    const inactivateItem = async commission => {
        try {
            setIsLoading(true);
            const commissionId = commission.id;
            await CommissionApi.updateActivity(commissionId);
            getCommissions(page, rowsPerPage, `archived=${showArchived.current}&inactive=${showInactive.current}`);
            return toastr.success(
                `Commission ${commission.name} successfully ${commission.active ? 'activated' : 'inactivated'}`
            );
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    };
    const actions = [
        {
            title: 'archive',
            action: openArchive
        }
    ];
    const unselectItem = async (field, value, tax) => {
        try {
            // setIsLoading(true);
            tax.items[field] = value;
            const taxId = tax.id;
            delete tax.id;
            await TaxesApi.update(taxId, tax);
            getCommissions(page, rowsPerPage, `archived=${showArchived.current}&inactive=${showInactive.current}`);
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            // setIsLoading(false);
        }
    };

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    return (
        <div className={classes.taxRatesTabRoot}>
            <View>
                <ListPagination
                    isFromOrg={true}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    data={data}
                    maxSize={data.length}
                    defaultColumns={[
                        { title: 'Active', field: 'active', onClick: inactivateItem },
                        { title: 'Name', field: 'name', onClick: openCommission },
                        { title: 'Description', field: 'description' }
                    ]}
                    actions={actions}
                    onCreate={openCommission}
                    filters={[
                        { name: 'archived', label: 'Show Archived', value: false },
                        { name: 'inactive', label: 'Show Inactive', value: false }
                    ]}
                    highlightArchived={true}
                    ApiCall={getCommissions}
                    activeCall={inactivateItem}
                    unselectItem={unselectItem}
                    addNewButtonText={'Add new commission structure'}
                />
            </View>
            {confirmModal && (
                <ConfirmModal
                    isOpen
                    setIsOpen={closeConfirmModal}
                    onConfirm={toDo}
                    title={modalTitle}
                    content={modalContent}
                />
            )}
            {isCommissionsModalVisible && selectedCommission && (
                <CommissionsModal
                    handleSubmit={submit}
                    closeModal={CloseCommissionModal}
                    selectedCommission={selectedCommission}
                    serviceCategories={serviceCategories}
                />
            )}
        </div>
    );
}
export default withStyles(taxRateStyles)(CommissionsTab);
