import api from '../collums-components/api/config';

class ProductCategory {
    static getCategories(query) {
        return api.request({
            method: 'GET',
            url: `/product/categories${query}`
        });
    }

    static getCategory(categoryId) {
        return api.request({
            method: 'GET',
            url: `/product/categories/${categoryId}`
        });
    }

    static filter(value = '', isSub = false, clinic, isFromOrg = false) {
        return api.request({
            method: 'GET',
            url: `/product/categories/filterBy?name=${value}&isSub=${isSub}&clinic=${clinic}&isFromOrg=${isFromOrg}`
        });
    }

    static createCategory(data, clinic, isFromOrg = false) {
        return api.request({
            method: 'POST',
            url: `/product/categories?clinic=${clinic}&isFromOrg=${isFromOrg}`,
            data: data
        });
    }

    static updateCategory(categoryId, data, clinic, isFromOrg = false) {
        return api.request({
            method: 'PUT',
            url: `/product/categories/${categoryId}?clinic=${clinic}&isFromOrg=${isFromOrg}`,
            data: data
        });
    }

    static deleteCategory(categoryId) {
        return api.request({
            method: 'DELETE',
            url: `/product/categories/${categoryId}`
        });
    }

    static archiveCategory(categoryId, value, clinic, isFromOrg = false) {
        return api.request({
            method: 'POST',
            url: `/product/categories/${categoryId}/archive?value=${value}&clinic=${clinic}&isFromOrg=${isFromOrg}`
        });
    }

    static changeActiveCategory(categoryId, value, clinic, isFromOrg = false) {
        return api.request({
            method: 'PUT',
            url: `/product/categories/${categoryId}/change-active?value=${value}&clinic=${clinic}&isFromOrg=${isFromOrg}`
        });
    }
}

export default ProductCategory;
