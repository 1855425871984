import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Modal from '../../common/Modal';
import { Button, makeStyles, withStyles } from '@material-ui/core';
import LoadingScreen from './../../../collums-components/components/common/loadingScreen';
import { toastr } from 'react-redux-toastr';
import StockApi from '../../../api/StockApi';
import Accordion from '../../common/Accordion';
import GeneralTab from './tabs/General';
import { modalsButtonStyles } from '../../../collums-constants/styles/stylesheets/buttonsStyles';
import { BrandModalStyles } from './styles';
import LocationTab from '../../common/LocationTab';

function BrandModal({ isFromOrg, clinic, clinics, isVisible, onClose, brand, classes, allClinics }) {
    const globalStyles = makeStyles(modalsButtonStyles)();

    const formRef = useRef();
    const [isLoading, setIsLoading] = useState(false);

    const { register, handleSubmit, control } = useForm();

    const save = async value => {
        value.locations = value.locations.map(item => {
            item.clinic = item.value;
            item.active = true;
            item.archived = false;

            delete item.isSelected;
            delete item.subtitle;
            delete item.value;
            delete item.label;

            return item;
        });

        try {
            setIsLoading(true);
            if (brand?.id) {
                await StockApi.updateBrand(brand.id, value).then(() => onClose(true));
                toastr.success('Brand successfully updated');
            } else {
                await StockApi.createBrand(value).then(() => onClose(true));
                toastr.success('Brand successfully created');
            }
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    };
    const onError = () => {
        toastr.error('Missing Fields!', 'Complete all the fields on General to proceed');
        return;
    };
    const tabProps = {
        register,
        brand,
        control,
        isFromOrg,
        clinic,
        clinics,
        selectedItem: brand,
        allClinics
    };

    return (
        <>
            {isLoading && <LoadingScreen />}
            <Modal
                id={'product-brand-form-modal'}
                isOpen={isVisible}
                onCancel={() => onClose()}
                onClose={() => onClose()}
                title={`View/Edit${brand?.name ? ` ${brand.name}` : ''} Brand`}
            >
                <form ref={formRef} className={classes.accordionsForm}>
                    <Accordion title={'General'}>
                        <GeneralTab {...tabProps} key="general" />
                    </Accordion>
                    <Accordion hideItem={!isFromOrg && allClinics.length === 1} title={'Locations'}>
                        <LocationTab {...tabProps} key="locations" />
                    </Accordion>
                    <div className={globalStyles.buttonsContainer}>
                        <Button className={globalStyles.cancelButton} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button className={globalStyles.confirmButton} onClick={handleSubmit(save, onError)}>
                            Confirm
                        </Button>
                    </div>
                </form>
            </Modal>
        </>
    );
}

BrandModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(BrandModalStyles)(BrandModal);
