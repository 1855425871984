import React, { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Menu, MenuItem, Fade, makeStyles } from '@material-ui/core';

import { buttonsStyles } from '../../../collums-constants/styles/stylesheets/buttonsStyles';

const Actions = forwardRef(({ actions, id, isFromOrg, isArchived = false, hasCopy = true }, ref) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const globalStyles = makeStyles(buttonsStyles)();

    const handleClick = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    /* const handleDelete = () => actions.handleDelete(id); */
    const handleArchive = () => actions.handleArchive(id, isArchived);
    const handleDuplicate = () => actions.handleDuplicate(id);

    useImperativeHandle(ref, () => ({
        handleClose
    }));

    return (
        <div>
            <Button
                aria-controls="fade-menu"
                aria-haspopup="true"
                className={globalStyles.grayButton}
                onClick={handleClick}
            >
                Options
            </Button>
            <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                {/* <MenuItem onClick={handleDelete}>Delete</MenuItem> */}
                <MenuItem
                    onClick={() => {
                        handleArchive();
                        handleClose();
                    }}
                >
                    {isArchived ? 'Unarchive' : 'Archive'}
                </MenuItem>
                {hasCopy && isFromOrg && (
                    <MenuItem
                        onClick={() => {
                            handleDuplicate();
                            handleClose();
                        }}
                    >
                        Copy
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
});

Actions.propTypes = {
    actions: PropTypes.object,
    id: PropTypes.string
};

export default Actions;
