import React from 'react';
import { Table, TableCell, TableContainer, TableHead, TableRow, TableBody, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const AdminTable = ({ list, columns }) => {
    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((header, index) => {
                                return <TableCell key={index}>{header.label}</TableCell>;
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map(item => {
                            return (
                                <TableRow
                                    key={item.id}
                                    style={{ backgroundColor: !item.archived ? 'white' : '#ff9e9e' }}
                                >
                                    {columns.map((column, index) => {
                                        return (
                                            <TableCell key={index}>
                                                {column.specialField ? (
                                                    column.specialField(item)
                                                ) : (
                                                    <Typography>{item[column.key]}</Typography>
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

AdminTable.propTypes = {
    list: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired
};

export default AdminTable;
