import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, FormControl, Select, InputLabel, FormHelperText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { selectDropdownStyles } from './styles';

class SelectDropdown extends React.Component {
    renderLabel() {
        const { field } = this.props;
        if (!field.label) {
            return null;
        }
        return <InputLabel id={`${field.id}-label`}>{field.label}</InputLabel>;
    }

    renderDescription() {
        const { field } = this.props;
        if (!field.errorMessages && !field.description) {
            return null;
        }
        return <FormHelperText>{field.errorMessages || field.description}</FormHelperText>;
    }

    render() {
        const { field, onChange, classes } = this.props;
        const initialValue =
            null == field.value &&
            null != field.controlledDefaultValue &&
            field.options.find(option => option.value === field.controlledDefaultValue);
        const value =
            initialValue || (field.value && field.options.find(option => option.value === field.value.value)) || '';
        return (
            <FormControl className={classes.container} error={null != field.isValid && !field.isValid} fullWidth>
                {this.renderLabel()}
                <Select
                    id={field.id}
                    value={value}
                    placeholder={field.placeholder}
                    onChange={event => onChange(field.id, event.target.value)}
                >
                    {field.options.map((item, index) => (
                        <MenuItem key={index} value={item}>
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
                {this.renderDescription()}
            </FormControl>
        );
    }
}

SelectDropdown.propTypes = {
    field: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(selectDropdownStyles)(SelectDropdown);
