export const coursesStyles = () => ({
    tableContainer: {
        width: '100%',
        overflowX: 'auto'
    },
    fixedTableFooter: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    accordionsForm: {
        '& .MuiExpansionPanelSummary-content': {
            '& p': {
                fontSize: 16,
                fontWeight: 400
            }
        }
    },
    autoCompleteContainer: {
        marginTop: 8,
        width: '48px'
    },
    filterAutocomplete: {
        width: '12%',
        margin: 8
    },
    locationFilter: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 20,
        flexWrap: 'wrap',
        '& .MuiCheckbox-root:nth-of-type(1)': {
            paddingLeft: 0
        }
    },
    spanLocationLabel: {
        fontSize: 14,
        marginBottom: 20,
        fontFamily: 'Roboto, Arial, Sans-serif'
    },
    rightActionsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    formContent: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap'
    },
    formContentRow: {
        width: '100%'
    },
    formItem: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        width: '48%',
        marginRight: 8,
        marginTop: 10,
        marginBottom: 8
    },
    locationsTitle: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 5,
        '& b': {
            fontSize: 14,
            fontWeight: 500,
            fontFamily: 'Roboto, Arial, Sans-serif !important',
            color: 'rgba(0, 0, 0, 0.87)'
        }
    },
    accordions: {
        '& .MuiExpansionPanelSummary-content': {
            '& p': {
                fontSize: 16,
                fontWeight: 400
            }
        }
    },
    halfRow: {
        width: '48%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    halfItem: {
        width: '48%',
        marginTop: 8,
        marginBottom: 8
    },
    outlinedButton: {
        marginBottom: '5%',
        alignSelf: 'center',
        height: 'fit-content'
    },
    listRow: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    listContainer: {
        width: '50%'
    },
    title: {
        marginTop: '2%'
    },
    accordionTitle: {
        marginTop: 16
    },
    accordion: {
        width: '100%'
    },
    requiredFields: {
        display: 'flex',
        flexDirection: 'column',
        '& p': {
            fontSize: '10px',
            margin: 0,
            color: 'red'
        }
    },
    cellRightAlign: {
        textAlign: 'right'
    },
    autocomplete: {
        '& .MuiInputBase-root': {
            padding: '2px 8px',
            paddingRight: '45px !important'
        }
    },
    autocompleteOptions: {
        padding: 0,
        margin: 0.5
    },
    inputDatepicker: {
        height: 38
    },
    marginLeft: {
        marginLeft: 10
    },
    checkboxesContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '12px',
        alignItems: 'center',
        '& span': {
            color: 'rgba(0, 0, 0, 0.87)'
        },
        '& label': {
            fontFamily: 'Roboto, Arial, Sans-serif',
            marginRight: 10
        },
        '& :last-child': {
            marginRight: 0
        }
    }
});
