import api from '../collums-components/api/config';

class TemporaryDiscountsApi {
    static create(data) {
        return api.request({
            method: 'POST',
            url: '/temp-discounts',
            data
        });
    }

    static update(id, data) {
        return api.request({
            method: 'PUT',
            url: `/temp-discounts/${id}`,
            data
        });
    }

    static toggleProperty(id, property, value, isFromOrg = false, clinicId) {
        return api.request({
            method: 'PUT',
            url: `/temp-discounts/toggle-property/${id}?property=${property}&value=${value}&isFromOrg=${isFromOrg}${
                clinicId ? `&clinicId=${clinicId}` : ''
            }`
        });
    }

    static getAll(name = '', page = 0, rowsPerPage = 25, isFromOrg = false, clinicId, archived = false) {
        return api.request({
            method: 'GET',
            url: `/temp-discounts?name=${name}&page=${page}&rowsPerPage=${rowsPerPage}&isFromOrg=${isFromOrg}${
                clinicId ? `&clinicId=${clinicId}` : ''
            }&archived=${archived}`
        });
    }
}

export default TemporaryDiscountsApi;
