import { makeStyles, FormControl, Divider, List, Typography, FormHelperText, Input } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DragableListItem from '../../common/DragableListItem';
import PropTypes from 'prop-types';
import { isArray } from 'lodash';
import ClinicSelector from '../../common/ClinicSelector';
import { serviceDetailsModalStyles } from '../../services/styles';
import { categoryDetailsModalStyles } from '../styles';

function RoomEquipmentTab({
    setValue,
    register,
    control,
    watch,
    category,
    clinic: currentClinic,
    clinics,
    rooms,
    equipments,
    isFromOrg,
    changedRooms,
    setChangedRooms,
    changedEquipments,
    setChangedEquipments,
    services
}) {
    const [roomError, setRoomError] = useState(false);
    const catStyles = makeStyles(categoryDetailsModalStyles)();
    const servStyles = makeStyles(serviceDetailsModalStyles)();
    const classes = { ...catStyles, ...servStyles };
    const selectedTagsWatch = watch('room_equipment.clinics');

    // eslint-disable-next-line
    const watchLocations = watch('locations') || [];
    const [selectedLocations, setSelectedLocations] = useState([]);
    if (!isArray(rooms)) {
        rooms = rooms?.items || [];
    }
    if (!isArray(equipments)) {
        equipments = equipments?.items || [];
    }

    useEffect(() => {
        setSelectedLocations(watchLocations.filter(location => location.isSelected));
    }, [watchLocations]);

    // ---- Order the location rooms & equips on the same order as Locations, ignoring the check order
    const orderedLocationOptions = [];
    if (selectedLocations && selectedTagsWatch?.length) {
        selectedLocations.forEach(selectedLoc => {
            selectedTagsWatch.forEach(location => {
                if (location === selectedLoc.label) {
                    orderedLocationOptions.push(location);
                }
            });
        });
    } else if (selectedLocations && !selectedTagsWatch?.length) {
        selectedLocations.forEach(selectedLoc => {
            orderedLocationOptions.push(selectedLoc.label);
        });
    }

    const handleRoomsDefaultValue = clinic => {
        const currLocation = selectedLocations.find(location => location.label === clinic);

        if (currLocation === undefined) return [];
        const currentServices = services.filter(service =>
            service.locations.some(loc => loc.clinic.toString() === currentClinic)
        );

        const locationData = category.locations.find(location => location.clinic === currLocation.value);
        if (locationData) {
            const mappedRooms = (rooms || []).map(room => {
                const selectedRoom = (!isArray(locationData.rooms)
                    ? locationData.rooms.items
                    : locationData.rooms
                ).find(selectedRoom => selectedRoom.room === room.id);

                const isRoomCheckedInAllServices =
                    currentServices.length &&
                    currentServices.every(service =>
                        service.locations
                            .find(loc => loc.clinic === currentClinic)
                            .rooms.some(locRoom => locRoom.room === room.id)
                    );
                const isRoomCheckedInAnyServices =
                    currentServices.length &&
                    currentServices.some(service =>
                        service.locations
                            .find(loc => loc.clinic === currentClinic)
                            .rooms.some(locRoom => locRoom.room === room.id)
                    );

                if (isRoomCheckedInAllServices || isRoomCheckedInAnyServices) {
                    return {
                        ...room,
                        isSelected: isRoomCheckedInAllServices || isRoomCheckedInAnyServices,
                        indeterminate: !isRoomCheckedInAllServices,
                        priority: selectedRoom ? selectedRoom.priority : -1
                    };
                } else {
                    return { ...room, isSelected: false, priority: -1 };
                }
            });
            return orderByPriority(mappedRooms);
        } else {
            return rooms;
        }
    };

    const handleEquipmentDefaultValue = clinic => {
        const currLocation = selectedLocations.find(location => location.label === clinic);

        if (currLocation === undefined) return [];
        const currentServices = services.filter(service =>
            service.locations.some(loc => loc.clinic.toString() === currentClinic)
        );

        const locationData = category.locations.find(location => location.clinic === currLocation.value);

        if (locationData) {
            const mappedEquips = equipments.map(equip => {
                const selectedEquip = locationData.equipments.find(
                    selectedEquip => selectedEquip.equipment === equip.id
                );

                const isEquipCheckedInAllServices =
                    currentServices.length &&
                    currentServices.every(service =>
                        service.locations
                            .find(loc => loc.clinic === currentClinic)
                            .equipments.some(locEquipment => locEquipment.equipment === equip.id)
                    );
                const isEquipmentCheckedInAnyServices =
                    currentServices.length &&
                    currentServices.some(service =>
                        service.locations
                            .find(loc => loc.clinic === currentClinic)
                            .equipments.some(locEquipment => locEquipment.equipment === equip.id)
                    );

                if (isEquipCheckedInAllServices || isEquipmentCheckedInAnyServices) {
                    return {
                        ...equip,
                        isSelected: isEquipCheckedInAllServices || isEquipmentCheckedInAnyServices,
                        indeterminate: !isEquipCheckedInAllServices,
                        priority: selectedEquip ? selectedEquip.priority : -1
                    };
                } else {
                    return { ...equip, isSelected: false, priority: -1 };
                }
            });

            return orderByPriority(mappedEquips);
        } else {
            return equipments;
        }
    };

    const orderByPriority = items => {
        const orderedByPriority = items.sort((a, b) => {
            if (a !== -1 && b !== -1) {
                if (a.priority < b.priority) {
                    return -1;
                } else if (a.priority > b.priority) {
                    return 1;
                }
                return 0;
            }
            return 0;
        });

        const orderedByIsSelected = orderedByPriority.sort((a, b) => {
            if ((a.isSelected || a.indeterminate) && !b.isSelected && !b.indeterminate) {
                return -1;
            } else if (!a.isSelected && !a.indeterminate && (b.isSelected || b.indeterminate)) {
                return 1;
            }
            return 0;
        });

        return orderedByIsSelected;
    };

    const handleChange = (oneSelected = false) => (list, setList, id) => {
        const newChangedRooms = changedRooms.filter(room => room !== id);
        newChangedRooms.push(id);
        setChangedRooms(newChangedRooms);
        setValue('changedRooms', newChangedRooms.join(','));
        setRoomError(false);

        const itemIndex = list.findIndex(value => value.id === id);

        if (oneSelected) {
            // minimum one selected
            const selectedItem = list.filter(item => item.isSelected).length;
            if (selectedItem < 2 && list[itemIndex].isSelected) {
                setRoomError(true);
                return;
            }
        }
        const newItem = { ...list[itemIndex], isSelected: !list[itemIndex].isSelected, indeterminate: false };
        const newList = [...list];

        newList.splice(itemIndex, 1, newItem);
        setList(newList);
    };

    const handleChangeEquipment = () => (list, setList, id) => {
        const newChangedEquipments = changedEquipments.filter(equipment => equipment !== id);
        newChangedEquipments.push(id);
        setChangedEquipments(newChangedEquipments);
        setValue('changedEquipments', newChangedEquipments.join(','));

        const itemIndex = list.findIndex(value => value.id === id);

        const newItem = { ...list[itemIndex], isSelected: !list[itemIndex].isSelected, indeterminate: false };
        const newList = [...list];

        newList.splice(itemIndex, 1, newItem);
        setList(newList);
    };

    const handleItemReordering = (dragged, draggedOver, onChange, value) => {
        let newItems = value;
        const setter = onChange;
        const draggedIndex = newItems.findIndex(item => item.id === dragged.id);
        const draggedOverIndex = newItems.findIndex(item => item.id === draggedOver.id);
        newItems[draggedOverIndex] = { ...dragged, id: dragged.id };
        newItems[draggedIndex] = { ...draggedOver, id: draggedOver.id };
        setter([...newItems]);
    };

    const handleDrop = (draggedItem, draggedOver, onChange, value) => {
        if (draggedItem && draggedOver && draggedItem.id !== draggedOver.id)
            handleItemReordering(draggedItem, draggedOver, onChange, value);
    };

    const addIsSelected = (arr, isRoom) => {
        if (isRoom && arr.length === 1) {
            return arr.map(item => {
                return { ...item, isSelected: true };
            });
        }
        return arr.map(item => {
            return { ...item, isSelected: false };
        });
    };

    const renderRooms = (onChange, value, index) => {
        return (
            <>
                <Typography className={classes.clinicLabel}>Rooms*</Typography>
                <List>
                    <DndProvider backend={HTML5Backend}>
                        {value.map((room, itemIndex) => {
                            return (
                                <>
                                    <DragableListItem
                                        classes={classes}
                                        key={`room-item-${itemIndex}`}
                                        item={room}
                                        handleDrop={handleDrop}
                                        handleChange={handleChange(true)}
                                        onChange={onChange}
                                        value={value}
                                        type={`REORDER_ROOMS-${index}`}
                                        disabled={value.length === 1 && value.every(item => item.isSelected)}
                                    />
                                    <Divider />
                                </>
                            );
                        })}
                    </DndProvider>
                    {roomError && <FormHelperText error={true}>At least one room must be selected</FormHelperText>}
                    <span hidden>
                        <Input inputRef={register} name="changedRooms" defaultValue="" />
                    </span>
                </List>
            </>
        );
    };

    const renderEquipment = (onChange, value, index) => {
        return (
            <>
                <Typography className={classes.clinicLabel}>Equipment</Typography>
                <DndProvider backend={HTML5Backend}>
                    <List>
                        {value.map((item, itemIndex) => {
                            return (
                                <>
                                    <DragableListItem
                                        classes={classes}
                                        key={`equipment-item-${itemIndex}`}
                                        item={{ ...item }}
                                        handleDrop={handleDrop}
                                        handleChange={handleChangeEquipment()}
                                        onChange={onChange}
                                        value={value}
                                        type={`REORDER_EQUIPMENTS-${index}`}
                                    />
                                    <Divider />
                                </>
                            );
                        })}
                        <span hidden>
                            <Input inputRef={register} name="changedEquipments" defaultValue="" />
                        </span>
                    </List>
                </DndProvider>
            </>
        );
    };

    const clinicDefaultValues =
        category?.locations
            ?.map(el => {
                const exists = (clinics || []).find(clinic => clinic.id === el.clinic);
                return exists?.accountName;
            })
            .filter(el => el) || [];

    return services.length > 0 ? (
        <div className={classes.formContent}>
            {!selectedLocations.length && <Typography>First you need to choose a location</Typography>}
            {!!selectedLocations.length && (
                <div className={classes.formContentRow} style={{ display: isFromOrg ? '' : 'none' }}>
                    <ClinicSelector
                        name="room_equipment.clinics"
                        control={control}
                        className={classes.formItem}
                        options={selectedLocations}
                        defaultValue={clinicDefaultValues}
                        currentItem={category}
                    />
                </div>
            )}
            {orderedLocationOptions &&
                orderedLocationOptions.map((clinic, index) => {
                    // ---- Check if the location to render is selected
                    const selectedLocationsFiltered = selectedLocations
                        .map(loc => {
                            return loc.label === clinic ? loc.label : false;
                        })
                        .filter(el => el);
                    if (!selectedLocationsFiltered.includes(clinic)) {
                        return false;
                    }
                    // ---- Finds the real location index among all clinic locations
                    const locationRealIndex = clinics.findIndex(location => location.accountName === clinic);
                    return (
                        <>
                            {isFromOrg && (
                                <Typography className={`${classes.title} ${classes.bold}`}>{clinic}</Typography>
                            )}
                            <div className={classes.listRow}>
                                <FormControl className={classes.listContainer}>
                                    <Controller
                                        name={`locations[${locationRealIndex}].rooms[${index}]`}
                                        control={control}
                                        rules={register}
                                        defaultValue={
                                            category.locations
                                                ? handleRoomsDefaultValue(clinic)
                                                : addIsSelected(rooms, true)
                                        }
                                        render={({ onChange, value }) => {
                                            return renderRooms(onChange, value, index);
                                        }}
                                    ></Controller>
                                </FormControl>
                                <FormControl className={classes.listContainer}>
                                    <Controller
                                        name={`locations[${locationRealIndex}].equipments[${index}]`}
                                        control={control}
                                        rules={register}
                                        defaultValue={
                                            category.locations
                                                ? handleEquipmentDefaultValue(clinic)
                                                : addIsSelected(equipments)
                                        }
                                        render={({ onChange, value }) => {
                                            return renderEquipment(onChange, value, index);
                                        }}
                                    ></Controller>
                                </FormControl>
                            </div>
                        </>
                    );
                })}
        </div>
    ) : (
        <Typography>
            There are no active services in this category. Please activate services in order to allocate rooms and
            equipment.
        </Typography>
    );
}

RoomEquipmentTab.propTypes = {
    register: PropTypes.any,
    control: PropTypes.any,
    clinics: PropTypes.array,
    watch: PropTypes.any
};

export default RoomEquipmentTab;
