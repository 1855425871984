import {
    FormControl,
    InputLabel,
    makeStyles,
    TextField,
    Typography,
    Tooltip,
    FormControlLabel,
    Checkbox,
    Input,
    Grid,
    RadioGroup,
    Radio
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { categoryDetailsModalStyles } from '../styles';
import HelpIcon from '@material-ui/icons/Help';
import { getLocationItem } from '../../../collums-constants/utils';
import { DEPOSIT_TYPES } from '../../settings/tabs/CustomerAppTab';

function OnlineBookingTab({
    register,
    watch,
    setValue,
    category,
    isFromOrg,
    clinic,
    changedOnlineBooking,
    setChangedOnlineBooking,
    services,
    getValues,
    clinics,
    organisationData
}) {
    const classes = makeStyles(categoryDetailsModalStyles)();
    const [isShowDurationOnline, setIsShowDurationOnline] = useState(false);
    const [isShowPriceOnline, setIsShowPriceOnline] = useState(false);
    const [isShowOnline, setIsShowOnline] = useState(false);
    const [, setRefreshKey] = useState(0);
    const handleCheckboxChange = (e, value, name) => {
        const newChangedOnlineBooking = changedOnlineBooking.filter(option => option !== name);
        newChangedOnlineBooking.push(name);
        setChangedOnlineBooking(newChangedOnlineBooking);
        setValue('changedOnlineBooking', newChangedOnlineBooking.join(','));
    };

    // If we're at org level (clinic === 'organisation') and there's only a single location, use that.
    // Otherwise return the clinic passed in (meaning we're at location-level)
    const urlLocation = clinic === 'organisation' ? (clinics.length === 1 ? clinics[0].id : null) : clinic;

    const customerAppUrl = `${process.env.REACT_APP_CUSTOMER_URL}/step/${urlLocation}/${category.id}`;

    useEffect(() => {
        if (services?.length) {
            setValue(
                'showDurationOnline',
                services.some(srv => srv.showDurationOnline)
            );

            setValue(
                'showPriceOnline',
                services.some(srv => srv.showPriceOnline)
            );

            setValue(
                'showOnline',
                isFromOrg
                    ? services.some(srv => srv.showOnline)
                    : services.some(srv => getLocationItem(srv, clinic).showOnline)
            );
        }
        setRefreshKey(prevKey => prevKey + 1);
    }, [services]); // eslint-disable-line

    useEffect(() => {
        if (organisationData) {
            if (!category.bookingDepositType || !organisationData?.bookingDepositType) {
                if (organisationData?.bookingDepositType) {
                    setValue('bookingDepositType', DEPOSIT_TYPES.DEFAULT);
                } else {
                    setValue('bookingDepositType', DEPOSIT_TYPES.NO_DEPOSIT);
                }
            } else {
                setValue('bookingDepositType', category.bookingDepositType);
                setValue('bookingDepositAmount', category.bookingDepositAmount);
            }
        }
    }, [organisationData]); // eslint-disable-line

    const watchedShowDurationOnline = watch('showDurationOnline', isShowDurationOnline);
    if (watchedShowDurationOnline !== isShowDurationOnline) {
        setIsShowDurationOnline(watchedShowDurationOnline);
    }

    const watchedShowPriceOnline = watch('showPriceOnline', isShowPriceOnline);
    if (watchedShowPriceOnline !== isShowPriceOnline) {
        setIsShowPriceOnline(watchedShowPriceOnline);
    }

    const watchedShowOnline = watch('showOnline', isShowOnline);
    if (watchedShowOnline !== isShowOnline) {
        setIsShowOnline(watchedShowOnline);
    }

    return (
        <div className={classes.formContent}>
            <div className={classes.onlineBookingContentRow}>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Order number</InputLabel>
                    <TextField
                        inputRef={register}
                        name="order"
                        variant="outlined"
                        type="number"
                        defaultValue={isFromOrg ? category?.order : getLocationItem(category, clinic)?.order}
                    />
                </FormControl>

                <FormControl className={classes.formItemTooltip} variant="outlined">
                    <Tooltip
                        className={classes.tooltip}
                        title={
                            <Typography className={classes.typography}>
                                Enter the position you would like this category/service to appear in the online booking
                                list. E.g. 1 for top of the list, 2 for 2nd etc. Leaving this field blank will display
                                it in alphabetical order.
                            </Typography>
                        }
                        aria-label="help"
                    >
                        <HelpIcon className={classes.helpIcon} />
                    </Tooltip>
                </FormControl>
            </div>

            <div className={classes.onlineBookingContentRow}>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Online name</InputLabel>
                    <TextField
                        inputRef={register}
                        name="onlineName"
                        variant="outlined"
                        defaultValue={isFromOrg ? category?.onlineName : getLocationItem(category, clinic)?.onlineName}
                    />
                </FormControl>

                <FormControl className={classes.formItemTooltip} variant="outlined">
                    <Tooltip
                        className={classes.tooltip}
                        title={
                            <Typography className={classes.typography}>
                                Use this if you wish the name of this service to appear differently for online booking.
                                Use this wisely as using many different names can make it difficult to know which
                                services link to those online!
                            </Typography>
                        }
                        aria-label="help"
                    >
                        <HelpIcon className={classes.helpIcon} />
                    </Tooltip>
                </FormControl>
            </div>

            <div className={classes.onlineBookingContentRow}>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Online description</InputLabel>
                    <TextField
                        inputRef={register}
                        name="onlineSubtitle"
                        variant="outlined"
                        defaultValue={
                            isFromOrg ? category?.onlineSubtitle : getLocationItem(category, clinic)?.onlineSubtitle
                        }
                    />
                </FormControl>

                <FormControl className={classes.formItemTooltip} variant="outlined">
                    <Tooltip
                        className={classes.tooltip}
                        title={
                            <Typography className={classes.typography}>
                                This text will appear below the service name in online booking.
                            </Typography>
                        }
                        aria-label="help"
                    >
                        <HelpIcon className={classes.helpIcon} />
                    </Tooltip>
                </FormControl>
            </div>

            <div className={classes.onlineBookingContentRow}>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Info text</InputLabel>
                    <TextField
                        inputRef={register}
                        name="onlineDescription"
                        variant="outlined"
                        defaultValue={
                            isFromOrg
                                ? category?.onlineDescription
                                : getLocationItem(category, clinic)?.onlineDescription
                        }
                    />
                </FormControl>

                <FormControl className={classes.formItemTooltip} variant="outlined">
                    <Tooltip
                        className={classes.tooltip}
                        title={
                            <Typography className={classes.typography}>
                                This text will appear when hovering an info bubble next to the service name in online
                                booking.
                            </Typography>
                        }
                        aria-label="help"
                    >
                        <HelpIcon className={classes.helpIcon} />
                    </Tooltip>
                </FormControl>
            </div>
            {isFromOrg && (
                <>
                    <div className={classes.formContentRow}>
                        <FormControlLabel
                            label="Show Duration"
                            control={
                                <Checkbox
                                    indeterminate={
                                        (!getValues('changedOnlineBooking') ||
                                            getValues('changedOnlineBooking').indexOf('showDurationOnline') === -1) &&
                                        services.some(srv => srv.showDurationOnline) &&
                                        services.some(srv => !srv.showDurationOnline)
                                    }
                                    checked={isShowDurationOnline}
                                    inputProps={{ name: 'showDurationOnline' }}
                                    inputRef={register}
                                    onChange={(e, value) => {
                                        handleCheckboxChange(e, value, 'showDurationOnline');
                                    }}
                                />
                            }
                        />
                    </div>
                    <div className={classes.formContentRow}>
                        <FormControlLabel
                            label="Show Price"
                            control={
                                <Checkbox
                                    indeterminate={
                                        (!getValues('changedOnlineBooking') ||
                                            getValues('changedOnlineBooking').indexOf('showPriceOnline') === -1) &&
                                        services.some(srv => srv.showPriceOnline) &&
                                        services.some(srv => !srv.showPriceOnline)
                                    }
                                    checked={isShowPriceOnline}
                                    inputProps={{ name: 'showPriceOnline' }}
                                    inputRef={register}
                                    onChange={(e, value) => {
                                        handleCheckboxChange(e, value, 'showPriceOnline');
                                    }}
                                />
                            }
                        />
                    </div>
                </>
            )}
            <div className={classes.formContentRow} hidden={true}>
                <FormControlLabel
                    label="Allow online booking"
                    control={
                        <Checkbox
                            indeterminate={
                                (!getValues('changedOnlineBooking') ||
                                    getValues('changedOnlineBooking').indexOf('showOnline') === -1) &&
                                (isFromOrg
                                    ? services.some(srv => srv.showOnline)
                                    : services.some(srv => getLocationItem(srv, clinic).showOnline)) &&
                                (isFromOrg
                                    ? services.some(srv => !srv.showOnline)
                                    : services.some(srv => !getLocationItem(srv, clinic).showOnline))
                            }
                            checked={isShowOnline}
                            inputProps={{ name: 'showOnline' }}
                            inputRef={register}
                            disabled={isFromOrg ? false : !category?.showOnline}
                            onChange={(e, value) => {
                                handleCheckboxChange(e, value, 'showOnline');
                            }}
                        />
                    }
                />
            </div>
            <span hidden>
                <Input inputRef={register} name="changedOnlineBooking" defaultValue="" />
            </span>
            {urlLocation && (
                <div className={classes.formContentRow}>
                    <Typography className={classes.typography}>
                        URL:{' '}
                        <a className={classes.linkItem} href={customerAppUrl}>
                            {customerAppUrl}
                        </a>
                    </Typography>
                </div>
            )}

            <Grid container direction="column" spacing={3} className={classes.depositContainer}>
                <Grid item style={{ marginTop: '24px' }}>
                    <div className={classes.inlineContainer}>
                        <Typography variant="h3">
                            Type of deposit
                            <Tooltip
                                className={classes.tooltip2}
                                title={
                                    <Typography className={classes.typography}>
                                        Default global online booking deposit rules are set in Admin &gt; Settings. Set
                                        different rules here if you wish to override the default.
                                    </Typography>
                                }
                                aria-label="help"
                            >
                                <HelpIcon className={classes.helpIcon} />
                            </Tooltip>
                        </Typography>
                    </div>

                    <div className={classes.inlineContainer}>
                        <RadioGroup
                            style={{ display: 'flex', flexDirection: 'row' }}
                            name="bookingDepositType"
                            inputRef={register}
                            onChange={(e, value) => {
                                handleCheckboxChange(e, value, 'bookingDepositType');
                            }}
                        >
                            <FormControlLabel
                                key={`tod-${DEPOSIT_TYPES.NO_DEPOSIT}`}
                                value={DEPOSIT_TYPES.NO_DEPOSIT}
                                control={<Radio inputRef={register} />}
                                label={DEPOSIT_TYPES.NO_DEPOSIT}
                                checked={getValues()?.bookingDepositType === DEPOSIT_TYPES.NO_DEPOSIT}
                                disabled={!organisationData?.bookingDepositType}
                            />
                            {organisationData.bookingDepositType && (
                                <FormControlLabel
                                    key={`tod-${DEPOSIT_TYPES.DEFAULT}`}
                                    value={DEPOSIT_TYPES.DEFAULT}
                                    control={<Radio inputRef={register} />}
                                    label={DEPOSIT_TYPES.DEFAULT}
                                    checked={getValues()?.bookingDepositType === DEPOSIT_TYPES.DEFAULT}
                                    disabled={!organisationData?.bookingDepositType}
                                />
                            )}
                            <FormControlLabel
                                key={`tod-${DEPOSIT_TYPES.PERCENTAGE}`}
                                value={DEPOSIT_TYPES.PERCENTAGE}
                                control={<Radio inputRef={register} />}
                                label={DEPOSIT_TYPES.PERCENTAGE}
                                checked={getValues()?.bookingDepositType === DEPOSIT_TYPES.PERCENTAGE}
                                disabled={!organisationData?.bookingDepositType}
                            />
                            <FormControlLabel
                                key={`tod-${DEPOSIT_TYPES.FIXED_AMOUNT}`}
                                value={DEPOSIT_TYPES.FIXED_AMOUNT}
                                control={<Radio inputRef={register} />}
                                label={DEPOSIT_TYPES.FIXED_AMOUNT}
                                checked={getValues()?.bookingDepositType === DEPOSIT_TYPES.FIXED_AMOUNT}
                                disabled={!organisationData?.bookingDepositType}
                            />
                        </RadioGroup>
                    </div>

                    <div className={classes.inlineContainer}>
                        <FormControl variant="h4" style={{ marginBottom: '5px' }}>
                            Deposit percentage/amount
                        </FormControl>
                    </div>
                    <div className={classes.inlineContainer}>
                        <FormControl className={classes.fieldContainer}>
                            <TextField
                                id="bookingDepositAmount-input"
                                name="bookingDepositAmount"
                                variant="outlined"
                                // value={category?.bookingDepositAmount}
                                onChange={(e, value) => {
                                    handleCheckboxChange(e, value, 'bookingDepositAmount');
                                }}
                                type="number"
                                InputProps={{
                                    inputProps: { min: 1 }
                                }}
                                inputRef={register}
                                style={{ width: '240px' }}
                                disabled={
                                    !organisationData?.bookingDepositType ||
                                    ![DEPOSIT_TYPES.FIXED_AMOUNT, DEPOSIT_TYPES.PERCENTAGE].includes(
                                        getValues()?.bookingDepositType
                                    )
                                }
                            />
                        </FormControl>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default OnlineBookingTab;
