import {
    makeStyles,
    Checkbox,
    List,
    ListItem,
    Divider,
    ListItemIcon,
    ListItemText,
    Typography,
    FormControlLabel
} from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useMemo, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { LocationTabStyles } from './styles';

function LocationTab({ clinics, isFromOrg, register, control, category, selectedItem, isFrom, disabled }) {
    const classes = makeStyles(LocationTabStyles)();
    const [listOptions, setListOptions] = useState();

    const initialOptions = useMemo(
        () =>
            clinics?.length &&
            clinics.map(clinic => {
                let savedClinic;
                if (category !== undefined && category.locations !== undefined && _.size(category.locations) > 0) {
                    savedClinic = category.locations.find(element => element.clinic === clinic.id);
                }
                let isSelected = savedClinic !== undefined ? true : false;

                const selectedItemLocations =
                    isFrom === 'staff'
                        ? selectedItem?.locations?.map(item => item.clinic?.id)
                        : selectedItem?.locations?.map(item => item.clinic);

                if (selectedItemLocations?.includes(clinic.id)) {
                    isSelected = true;
                }

                if (clinics.length === 1) {
                    isSelected = true;
                }
                return {
                    value: clinic.id,
                    label: clinic.accountName,
                    subtitle: undefined,
                    isSelected
                };
            }),
        [clinics, category, selectedItem, isFrom]
    );

    useEffect(() => {
        setListOptions(initialOptions);
    }, [clinics, initialOptions]);

    return (
        <div className={classes.formContent}>
            <div className={classes.formContentRow}>
                {listOptions && listOptions.length > 0 && (
                    <>
                        <div className={classes.locationsTitle}>
                            <Typography>Select locations that can use this item.</Typography>
                        </div>
                        <Controller
                            name={'locations'}
                            control={control}
                            defaultValue={listOptions}
                            rules={register}
                            render={({ onChange, value }) => {
                                const handleToggle = option => {
                                    const currentIndex = (value || []).findIndex(v => v.value === option.value);
                                    const newItems = [...value];
                                    const newItem = {
                                        ...value[currentIndex],
                                        isSelected: !value[currentIndex].isSelected
                                    };
                                    newItems.splice(currentIndex, 1, newItem);
                                    onChange(newItems);
                                };
                                const checkAll = (e, checked) => {
                                    const newItems = [
                                        ...value.map(item => {
                                            item.isSelected = checked;
                                            return item;
                                        })
                                    ];
                                    onChange(newItems);
                                };

                                return (
                                    <>
                                        {isFromOrg && value.length > 1 && (
                                            <div className={classes.locationFilter}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            disabled={disabled}
                                                            onChange={checkAll}
                                                            edge="start"
                                                            tabIndex={-1}
                                                            disableRipple
                                                            // checked={locations?.length && locations.filter(item => item.isSelected).length === locations.length}
                                                        />
                                                    }
                                                    label="Select all"
                                                />
                                            </div>
                                        )}
                                        <List>
                                            {!value.length && 'Loading...'}
                                            {value.map((option, index) => (
                                                <>
                                                    <ListItem
                                                        key={index}
                                                        role={undefined}
                                                        dense
                                                        button
                                                        disabled={disabled}
                                                    >
                                                        <ListItemIcon>
                                                            <Checkbox
                                                                onClick={() => handleToggle(option)}
                                                                edge="start"
                                                                checked={clinics.length === 1 || option.isSelected}
                                                                tabIndex={-1}
                                                                disableRipple
                                                                disabled={clinics.length === 1}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={option.label}
                                                            secondary={option.subtitle}
                                                        />
                                                    </ListItem>
                                                    <Divider />
                                                </>
                                            ))}
                                        </List>
                                    </>
                                );
                            }}
                        />
                    </>
                )}
            </div>
        </div>
    );
}

LocationTab.propTypes = {
    register: PropTypes.func.isRequired,
    control: PropTypes.any,
    watch: PropTypes.any,
    setValue: PropTypes.any,
    getValues: PropTypes.any,
    clinics: PropTypes.array,
    onChange: PropTypes.any
};

export default LocationTab;
