import React from 'react';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import { TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';

import { durationAutocompleteStyles } from './styles';

class DurationAutocomplete extends React.Component {
    constructor(props) {
        super(props);
        this.value = '';
        this.options = [
            ..._.times(12, index => ({
                label: `${(index + 1) * 5} minutes`,
                value: (index + 1) * 5 * 60
            })),
            ..._.times(24, index => ({
                label: `${index + 1} ${1 === index + 1 ? 'hour' : 'hours'}`,
                value: (index + 1) * 3600
            })),
            ..._.times(7, index => ({
                label: `${index + 1} ${1 === index + 1 ? 'day' : 'days'}`,
                value: (index + 1) * 86400
            })),
            ..._.times(7, index => ({
                label: `${index + 1} ${1 === index + 1 ? 'week' : 'weeks'}`,
                value: (index + 1) * 604800
            })),
            ..._.times(12, index => ({
                label: `${index + 1} ${1 === index + 1 ? 'month' : 'months'}`,
                value: (index + 1) * 2419200
            })),
            ..._.times(4, index => ({
                label: `${index + 1} ${1 === index + 1 ? 'year' : 'years'}`,
                value: (index + 1) * 31536000
            }))
        ];
        this.dynamicOptions = [];
        this.state = { wasEverClosed: false };

        this.onInputChange = this.onInputChange.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    onInputChange(event, value) {
        const { field, onChange } = this.props;
        this.value = value;
        const numberPrefixRegex = /^(\d+\.?\d*)/;
        if (numberPrefixRegex.test(this.value)) {
            const dynamicNumber = Number(numberPrefixRegex.exec(this.value)[1]);
            this.dynamicOptions = [
                { label: 1 === dynamicNumber ? 'minute' : 'minutes', value: 60 },
                { label: 1 === dynamicNumber ? 'hour' : 'hours', value: 3600 },
                { label: 1 === dynamicNumber ? 'day' : 'days', value: 86400 },
                { label: 1 === dynamicNumber ? 'week' : 'weeks', value: 604800 },
                { label: 1 === dynamicNumber ? 'month' : 'months', value: 2419200 },
                { label: 1 === dynamicNumber ? 'year' : 'years', value: 31536000 }
            ].map(unit => ({
                label: `${dynamicNumber} ${unit.label}`,
                value: dynamicNumber * unit.value
            }));
        }
        const option = [...this.options, ...this.dynamicOptions].find(
            option => option.label.trim().toLocaleLowerCase() === this.value.trim().toLocaleLowerCase()
        );
        if (option) {
            onChange(field.id, option);
            return;
        }
        const minuteRegex1 = /^(\d+\.?\d*)[ ]*(mins|hrs|wks|mnths|yrs)/;
        if (minuteRegex1.test(this.value)) {
            const parsed = minuteRegex1.exec(this.value);
            const dynamicNumber = Number(parsed[1]);
            const dynamicUnit = parsed[2];
            const unitValueMap = {
                mins: 60,
                hrs: 3600,
                wks: 604800,
                mnths: 2419200,
                yrs: 31536000
            };
            const option = [...this.options, ...this.dynamicOptions].find(
                option => option.value === dynamicNumber * unitValueMap[dynamicUnit]
            );
            onChange(field.id, option);
            return;
        }
        onChange(field.id, { value: this.value, label: this.value, undefinedOption: true });
    }

    onClose() {
        this.setState({ wasEverClosed: true });
    }

    renderDescription() {
        const { field, classes } = this.props;
        if (!field.errorMessages && !field.description) return null;
        return (
            <Typography
                className={this.state.wasEverClosed && !field.isValid ? classes.descriptionError : classes.description}
            >
                {(this.state.wasEverClosed && field.errorMessages) || field.description}
            </Typography>
        );
    }

    render() {
        const { field, onChange, classes } = this.props;
        return (
            <div
                className={`${classes.container} ${this.state.wasEverClosed && !field.isValid ? classes.invalid : ''}`}
            >
                <Autocomplete
                    id={field.id}
                    options={_.sortBy(_.uniqBy([...this.options, ...this.dynamicOptions], 'label'), 'value').filter(
                        option => {
                            return option.label
                                .trim()
                                .toLocaleLowerCase()
                                .includes(this.value.trim().toLocaleLowerCase());
                        }
                    )}
                    getOptionLabel={option => (null != option.label ? option.label : option)}
                    onChange={(event, value) => onChange(field.id, value)}
                    onInputChange={this.onInputChange}
                    onClose={this.onClose}
                    renderInput={params => (
                        <TextField {...params} label={field.label} placeholder={field.placeholder} fullWidth />
                    )}
                    value={field.value || { label: '' }}
                    freeSolo={true}
                />
                {this.renderDescription()}
            </div>
        );
    }
}

DurationAutocomplete.propTypes = {
    field: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(durationAutocompleteStyles)(DurationAutocomplete);
