export const practitionersViewStyles = () => ({
    avatar: {
        width: 64
    }
});

export const practitionerFormModalStyles = () => ({
    accordionsForm: {
        '& .MuiExpansionPanelSummary-content': {
            '& p': {
                fontSize: 16,
                fontWeight: 400
            }
        }
    }
});

export const practitionerDetailsModalStyles = () => ({
    modal: {
        '& .MuiDialog-paper': {
            backgroundColor: '#eeeeee'
        }
    }
});

export const tabsStyles = () => ({
    commissionButton: {
        margin: 8,
        fontSize: 14,
        fontWeight: 400,
        textTransform: 'none',
        backgroundColor: '#3083EC'
    },
    textArea: {
        padding: '140px 10px',
        height: 220
    },
    tableContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    flatContent: {
        width: '80%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    textWarning: {
        fontSize: 14,
        marginBlockStart: 0,
        fontWeight: '400 !important'
    }
});
