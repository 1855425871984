import React from 'react';
import PropTypes from 'prop-types';
import { emailEditor as styles } from './styles';
import { withStyles } from '@material-ui/core';
import JoditEditor from './JoditEditorWrapper';
import base64extractor from './helpers/base64extractor';
import base64converter from './helpers/base64converter';
import NotificationApi from '../../../../api/NotificationApi';

const EmailEditor = ({
    classes,
    setLoadingUpload,
    loadingUpload,
    editor,
    message,
    setMessage,
    setUnsavedChanges,
    defaultValue
}) => {
    const form = message;

    const handleEditorState = async content => {
        let data = content || '';

        // Extract the base64 images
        const base64img = data.includes('data:image') ? base64extractor(data) : null;

        // base64 images found in the editor
        if (base64img) {
            setLoadingUpload({ ...loadingUpload, visible: true });
            // Convert each base64 image to file
            const fileimg = base64img.map(img =>
                base64converter(img, `image-${new Date().getTime()}.${img.split(/[/;]/g)[1]}`)
            );

            const path = [];

            // Insert each image in the database
            const promises = await fileimg.map(async img => path.push(await NotificationApi.uploadFile(img)));

            // Await until all the images are inserted in the bucket
            await Promise.all(promises);

            // Replace all base64 images by S3 link files
            path.forEach(el => (data = data.replace(/<img src="data:.*?>/, `<img src="${el}">`)));
        }

        setMessage(data);
        setUnsavedChanges();
    };

    // If new image is inserted, instant
    const handleInputEditor = data => (data.includes('data:image') ? handleEditorState(data) : setMessage(data));

    return (
        <>
            <div style={{ display: 'flex' }}>
                <div className={classes.emailRoot}>
                    <JoditEditor
                        ref={editor}
                        value={form}
                        defaultValue={defaultValue}
                        className={classes.editor}
                        name="email-editor"
                        setMessage={setMessage}
                        onChange={data => {
                            if (message !== data) setUnsavedChanges(true);
                            if (data.length <= 1) setMessage(data);
                            handleInputEditor(data);
                        }}
                        onBlur={data => handleEditorState(data)}
                        tabIndex={1} // tabIndex of textarea
                    />
                </div>
            </div>
        </>
    );
};

EmailEditor.propTypes = {
    classes: PropTypes.object.isRequired,
    setLoadingUpload: PropTypes.func,
    loadingUpload: PropTypes.object,
    editor: PropTypes.object
};

export default React.memo(withStyles(styles)(EmailEditor));
