import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { singleCheckboxStyles } from './styles';

class SingleCheckbox extends React.Component {
    render() {
        const { field, onChange, classes } = this.props;
        const dynamicStyle = {
            paddingLeft: field.indent ? 24 : 0
        };
        return (
            <div className={classes.container} style={dynamicStyle}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={field.value || false}
                            onChange={() => onChange(field.id, !field.value)}
                            color="primary"
                        />
                    }
                    label={field.label}
                />
            </div>
        );
    }
}

SingleCheckbox.propTypes = {
    field: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(singleCheckboxStyles)(SingleCheckbox);
