import React from 'react';
import PropTypes from 'prop-types';

import { customModal } from '../styles';

import {
    withStyles,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    TableContainer
} from '@material-ui/core';

import customerFields from '../services/customerModelMap';
import { getCustomerInfo, isCustomerSelected } from '../services/helpers';

import CustomModalActions from './custom/CustomModalActions';
import Modal from './custom/Modal';

function MergeClientsModal({
    classes,
    open,
    closeModal,
    customer1,
    customer2,
    mergedCustomer,
    preferentialCustomerTemplates,
    confirmCustomerMerge,
    updateMergedCustomer
}) {
    const getClasses = (customer, field) => {
        if (getCustomerInfo(customer1, field) === getCustomerInfo(customer2, field)) {
            return '';
        }
        const isSelected = isCustomerSelected(preferentialCustomerTemplates, customer, field);
        return `${classes.clickableTableCell} ${isSelected ? classes.selectedTableCell : ''}`;
    };

    const isRowEmpty = (valueCustomer1, valueCustomer2) => {
        if (valueCustomer1 === valueCustomer2 && !valueCustomer1) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <Modal id="MergeClientsModal" isOpen={open} title={'Merge Clients'} size={'lg'} onClose={closeModal}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead className={classes.mergeClientsTh}>
                        <TableRow>
                            <TableCell>Field</TableCell>
                            <TableCell>Customer 1</TableCell>
                            <TableCell>Customer 2</TableCell>
                            <TableCell>Merged customer</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {customerFields
                            .filter(field => field.id !== 'id')
                            .map(field =>
                                isRowEmpty(getCustomerInfo(customer1, field), getCustomerInfo(customer2, field)) ? (
                                    ''
                                ) : (
                                    <TableRow key={field.id} className={classes.mergeClientsTableRow}>
                                        <TableCell>
                                            <Typography>{field.label}</Typography>
                                        </TableCell>
                                        <TableCell
                                            className={getClasses(customer1, field)}
                                            onClick={() => {
                                                if (
                                                    getCustomerInfo(customer1, field) !==
                                                    getCustomerInfo(customer2, field)
                                                ) {
                                                    updateMergedCustomer(customer1, field);
                                                }
                                            }}
                                        >
                                            <Typography>{getCustomerInfo(customer1, field)}</Typography>
                                        </TableCell>
                                        <TableCell
                                            className={getClasses(customer2, field)}
                                            onClick={() => {
                                                if (
                                                    getCustomerInfo(customer1, field) !==
                                                    getCustomerInfo(customer2, field)
                                                ) {
                                                    return updateMergedCustomer(customer2, field);
                                                }
                                            }}
                                        >
                                            <Typography>{getCustomerInfo(customer2, field)}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{getCustomerInfo(mergedCustomer, field)}</Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                    </TableBody>
                </Table>
            </TableContainer>
            <CustomModalActions onCancel={closeModal} onConfirm={confirmCustomerMerge} />
        </Modal>
    );
}

MergeClientsModal.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    customer1: PropTypes.object.isRequired,
    customer2: PropTypes.object.isRequired,
    mergedCustomer: PropTypes.object.isRequired,
    confirmCustomerMerge: PropTypes.func.isRequired,
    updateMergedCustomer: PropTypes.func.isRequired
};

export default withStyles(customModal)(MergeClientsModal);
