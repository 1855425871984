import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
    Link,
    withStyles,
    Typography,
    TableContainer
    /* Button,
    Menu,
    MenuItem */
} from '@material-ui/core';
import { brandsTableStyles } from './styles';

function SuppliersTable({
    classes,
    suppliers,
    paginationOptions,
    count,
    handleChangePage,
    handleChangeRowsPerPage,
    onModalOpen
    /* onDeleteRow,
    refreshList */
}) {
    const [items, setItems] = useState([]);
    const [paginate, setPaginate] = useState(paginationOptions);

    const rows = items.slice(
        paginate.page * paginate.rowsPerPage,
        paginate.page * paginate.rowsPerPage + paginate.rowsPerPage
    );
    const getFullAddress = item => {
        const array = [item.address, item.address2, item.city, item.county, item.postCode].filter(el => el);
        const result = array.join('-');
        return result;
    };

    useEffect(() => {
        setItems(suppliers);
        setPaginate(paginationOptions);
    }, [suppliers, paginationOptions]);

    /*eslint-disable-next-line */
    const ActionButton = ({ item }) => {
        return <></>;
        /* const [anchorEl, setAnchorEl] = useState(null);
        const openMenu = event => setAnchorEl(event.currentTarget);
        const closeMenu = () => setAnchorEl(null);
        return (
            <>
                <Button onClick={openMenu} style={{ backgroundColor: '#3083EC', color: 'white' }}>
                    actions
                </Button>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
                    <MenuItem
                        onClick={async () => {
                            const id = item._id ? item._id : item.id;
                            handleChangePage({}, 0);
                            await onDeleteRow(id);
                            await refreshList();
                        }}
                    >
                        Delete
                    </MenuItem>
                </Menu>
            </>
        ); */
    };

    return (
        <>
            <TableContainer className={classes.tableContainer}>
                <Table className={classes.root}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Supplier</TableCell>
                            <TableCell>Brands</TableCell>
                            <TableCell>Contact Name</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(paginate.rowsPerPage > 0 ? rows : items).map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <Typography>
                                        <Link onClick={() => onModalOpen(item)}>{item.name}</Link>
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.brands}>
                                    <Typography>{item.brands?.map(brand => brand?.name).join()}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{item.contactName}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{getFullAddress(item)}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        <Link href={`mailto:${item.email}`} className={classes.link}>
                                            {item.email}
                                        </Link>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{item.telephone}</Typography>
                                </TableCell>
                                <TableCell>
                                    <ActionButton item={item} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className={classes.fixedTableFooter}>
                <TablePagination
                    width={'100%'}
                    rowsPerPageOptions={[10, 25, 50]}
                    colSpan={12}
                    rowsPerPage={paginate?.rowsPerPage}
                    count={count.current}
                    page={paginate?.page}
                    SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
        </>
    );
}

SuppliersTable.propTypes = {
    classes: PropTypes.any.isRequired,
    suppliers: PropTypes.array.isRequired,
    paginate: PropTypes.object,
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    onModalOpen: PropTypes.func,
    refreshList: PropTypes.func
    /* onDeleteRow: PropTypes.func,*/
};

export default withStyles(brandsTableStyles)(SuppliersTable);
