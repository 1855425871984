import PropTypes from 'prop-types';
import { Button, withStyles, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toastr } from 'react-redux-toastr';
import CategoryApi from '../../collums-components/api/CategoryApi';
import Modal from '../common/Modal';
import Accordion from '../common/Accordion';
import GeneralTab from './tabs/GeneralTab';
import LocationTab from '../common/LocationTab';
import RoomEquipmentTab from './tabs/RoomEquipmentTab';
import CommissionTab from './tabs/CommissionTab';
import { categoryModalStyles } from './styles';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import ConfirmModal from '../common/ConfirmModal';
import ServicesFormTab from '../common/ServicesFormsTab';
import OnlineBookingTab from './tabs/OnlineBookingTab';
import StaffTab from './tabs/StaffTab';
import PractitionerApi from '../../api/PractitionerApi';
import ServiceApi from '../../api/ServiceApi';
import { getLocationItem } from '../../collums-constants/utils';
import OrganisationsApi from '../../api/OrganisationsApi';

const _ = require('lodash');

function CategoryFormModal({
    classes,
    visible,
    onClose,
    Category,
    onConfirm,
    isFromOrg,
    rooms = [],
    equipments = [],
    clinic,
    clinics = {},
    formProperties,
    defaultFormProperties,
    formOptions,
    disableBackdropClick,
    allClinics
}) {
    const globalStyles = makeStyles(modalsButtonStyles)();

    const category = { ...Category, prevName: Category?.name } || {};
    const [categories, setCategories] = useState([]);
    const [parent, setParent] = useState(null);
    const [practitioners, setPractitioners] = useState([]);
    const [services, setServices] = useState([]);

    const [openConfirm, setOpenConfirm] = useState(false);

    const [practitionersList, setPractitionersList] = useState([]);
    const [changedPractitioners, setChangedPractitioners] = useState([]);
    const [changedRooms, setChangedRooms] = useState([]);
    const [changedEquipments, setChangedEquipments] = useState([]);
    const [changedOnlineBooking, setChangedOnlineBooking] = useState([]);
    const [organisationData, setOrganisationData] = useState(null);
    const defaultValues = {
        category: { ...Category, prevName: Category?.name }
    };
    if (!isFromOrg && clinics) {
        const formatedClinic = clinics.map(clinic => ({
            value: clinic.id,
            label: clinic.accountName,
            subtitle: undefined,
            isSelected: true
        }));
        defaultValues.locations = formatedClinic;
    }
    const { register, handleSubmit, control, watch, errors, setValue, getValues } = useForm({
        defaultValues
    });
    const [saveButtonInactive, setSaveButtonInactive] = useState(false);

    useEffect(() => {
        (async () => {
            const organisation = await OrganisationsApi.getOrganisation();
            setOrganisationData(organisation);
        })();
    }, []);

    const loadPractitioners = async () => {
        const res = await PractitionerApi.listActives();
        const practitionerList = res.find(el => clinic === el.id)?.practitioners || [];
        setPractitioners(practitionerList);
    };
    const loadServices = async () => {
        const res = await ServiceApi.getServices({ value: '' }, isFromOrg, clinic, 99999, category.id);
        setServices(res.items);
    };

    useState(() => {
        const loadCategoryFilter = async () => {
            try {
                const fetchedCategories = await CategoryApi.filter('', false, clinic, isFromOrg);
                if (category.parentCategory) {
                    const categoryFetched = await CategoryApi.get(category.parentCategory);
                    if (!categoryFetched) return;
                    const parentCategModel = ['id', 'name'];
                    const parentCateg = _.pick(categoryFetched, parentCategModel);
                    if (fetchedCategories.find(el => el.id === parentCateg.id)) {
                        setCategories([parentCateg, ...fetchedCategories.filter(el => el.id !== parentCateg.id)]);
                    } else setCategories([parentCateg, ...fetchedCategories]);
                    setParent(parentCateg);
                } else setCategories(fetchedCategories);
            } catch (err) {
                return toastr.error(err?.data?.message || 'Something went wrong');
            }
        };

        loadCategoryFilter();
        loadPractitioners();
        loadServices();
    }, []);

    const searchCategories = async value => {
        const newValues = await CategoryApi.filter(value);
        setCategories(newValues);
    };

    useEffect(() => {
        const requiredFields = Object.keys(errors);

        if (requiredFields.length !== 0) {
            const messages = requiredFields.map(field => errors[field].message);

            toastr.error('Missing fields', messages.join(','));
        }
    }, [errors]);

    const formRef = useRef();

    const tabProps = {
        categories,
        clinics,
        clinic,
        equipments,
        rooms,
        isFromOrg,
        control,
        register,
        watch,
        setValue,
        getValues,
        parent,
        setParent,
        selectedItem: category,
        category,
        practitioners,
        practitionersList,
        setPractitionersList,
        services,
        changedPractitioners,
        setChangedPractitioners,
        changedRooms,
        setChangedRooms,
        changedEquipments,
        setChangedEquipments,
        changedOnlineBooking,
        setChangedOnlineBooking,
        allClinics,
        organisationData
    };

    const checkDifferences = (current, payload, setSaveButtonInactive = () => {}) => {
        let isTheSame = true;
        let straightSave = true;
        let selectedRooms = [];
        let selectedEquipments = [];
        if (!payload?.locations?.length) {
            return;
        }
        payload.locations.forEach(location => {
            if (location.equipments && location.equipments.length) {
                selectedEquipments = location.equipments.flat().filter(e => e.isSelected);
            }
            if (location.rooms && location.rooms.length) {
                selectedRooms = location.rooms.flat().filter(e => e.isSelected);
            }
        });

        const straightSaveKeys = [
            // Auxiliary variables should not be checked
            'changedOnlineBooking',
            'changedRooms',
            'changedEquipments',
            'changedPractitioners',
            // Online Booking Section (should not show modal).
            'showOnline',
            'onlineName',
            'onlineSubtitle',
            'onlineDescription',
            'showPriceOnline',
            'showDurationOnline',
            'showOnline',
            'order',
            // Validate later
            'allowCommission'
        ];

        Object.keys(payload).forEach(key => {
            if (key !== 'room_equipment' && !Array.isArray(payload[key]) && current[key] !== payload[key]) {
                isTheSame = false;
                if (!straightSaveKeys.includes(key)) {
                    straightSave = !isFromOrg && (key === 'color' || key === 'name');
                }
            }
        });

        if ((payload.tags || []).length !== (current.tags || []).length) {
            isTheSame = false;
        } else if (current.tags) {
            (payload.tags || []).forEach((payloadTag, i) => {
                if (current.tags[i] !== payloadTag) {
                    isTheSame = false;
                }
            });
        }

        const currentCommission = isFromOrg
            ? current.allowCommission
            : getLocationItem(current, clinic).allowCommission;

        if (currentCommission !== payload?.allowCommission) {
            isTheSame = false;
            straightSave = false;
        }

        if (payload)
            if (!isFromOrg) {
                current.locations
                    .filter(location => location?.clinic === clinic)
                    .forEach(currLocation => {
                        if (
                            currLocation.equipments.length !== selectedEquipments.length ||
                            currLocation.rooms.length !== selectedRooms.length
                        ) {
                            isTheSame = false;
                            straightSave = false;
                        } else {
                            selectedEquipments.forEach((payloadEquipment, i) => {
                                if (currLocation.equipments[i].equipment !== payloadEquipment.id) {
                                    isTheSame = false;
                                    straightSave = false;
                                }
                            });

                            selectedRooms
                                .filter(selectedRoom => selectedRoom?.clinic === currLocation.clinic)
                                .forEach((payloadRoom, i) => {
                                    if (currLocation.rooms[i].room !== payloadRoom.id) {
                                        isTheSame = false;
                                        straightSave = false;
                                    }
                                });
                        }
                    });
            }

        if (current.location && payload.location && current.location.id !== payload.location.id) {
            isTheSame = false;
            straightSave = false;
        }

        if (!_.isEqual(defaultFormProperties.current, formProperties.current)) {
            isTheSame = false;
            straightSave = false;
        }

        payload.locations = payload.locations.filter(location => location.isSelected);

        const currentLocationsId = current.locations.map(loc => loc.clinic);
        const payloadLocationsId = payload.locations.map(loc => loc.value);

        // const oldServiceLocations = (current.locations || []).map(loc => loc?.clinic);
        // const newSelectedClinics = payload.locations.map(loc => loc.value);
        if (isFromOrg) {
            // ---- find all cat loc and org level that are using it and display on modal like tax when archiving
            // const removedLocations = oldServiceLocations
            //     .map(loc => (!newSelectedClinics.includes(loc) ? loc : false))
            //     .filter(el => el);
            /// ---- get all services and locs using it and show on a modal
        }

        if (isFromOrg && !_.isEqual((currentLocationsId || []).sort(), (payloadLocationsId || []).sort())) {
            isTheSame = false;
            straightSave = false;
        }

        if (straightSave && !isTheSame) {
            return onConfirm(
                payload,
                practitionersList.filter(p => p.isSelected).map(p => ({ staff: p.id })),
                setSaveButtonInactive
            );
        }

        return !isTheSame ? setOpenConfirm(true) : onClose();
    };

    return (
        <>
            {organisationData && (
                <Modal
                    isOpen={visible}
                    title={
                        category.id !== undefined
                            ? `View/Edit ${category.name} Service Category`
                            : 'New Service Category'
                    }
                    id="category"
                    onClose={onClose}
                    onCancel={onClose}
                    disableBackdropClick={disableBackdropClick ? disableBackdropClick : false}
                >
                    <form ref={formRef} className={classes.accordionsForm}>
                        <Accordion title={'General'}>
                            {' '}
                            <GeneralTab {...tabProps} searchCategories={searchCategories} key="general" />{' '}
                        </Accordion>
                        <Accordion hideItem={!isFromOrg} title={'Locations'}>
                            {' '}
                            <LocationTab {...tabProps} key="pricing_location" />{' '}
                        </Accordion>
                        <Accordion title={'Room & Equipment'} hideItem={isFromOrg}>
                            {' '}
                            <RoomEquipmentTab {...tabProps} key="room_equipment" />{' '}
                        </Accordion>
                        <Accordion title={'Staff'} hideItem={isFromOrg}>
                            {' '}
                            <StaffTab {...tabProps} key="staff" />{' '}
                        </Accordion>
                        <Accordion title={'Online Booking & Deposit'}>
                            {' '}
                            <OnlineBookingTab {...tabProps} key="online_booking" />{' '}
                        </Accordion>
                        <Accordion title={'Commission'}>
                            {' '}
                            <CommissionTab {...tabProps} key="commissions" />{' '}
                        </Accordion>
                        <Accordion title={'Forms'}>
                            <ServicesFormTab
                                isFromOrg={isFromOrg}
                                formPropertiesRef={formProperties}
                                formOptions={formOptions}
                                allClinics
                            />
                        </Accordion>

                        <div className={globalStyles.buttonsContainer}>
                            <Button className={globalStyles.cancelButton} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button
                                className={globalStyles.confirmButton}
                                disabled={saveButtonInactive}
                                onClick={handleSubmit(data => {
                                    setSaveButtonInactive(true);
                                    if (category.id) {
                                        setSaveButtonInactive(false);
                                        checkDifferences(category, data, setSaveButtonInactive);
                                    } else {
                                        onConfirm(
                                            data,
                                            practitionersList.filter(p => p.isSelected).map(p => ({ staff: p.id })),
                                            setSaveButtonInactive
                                        );
                                    }
                                })}
                            >
                                Save
                            </Button>
                        </div>
                    </form>
                </Modal>
            )}
            {openConfirm && category.id !== undefined && (
                <ConfirmModal
                    onConfirm={handleSubmit(data => {
                        setSaveButtonInactive(true);
                        onConfirm(
                            data,
                            practitionersList.filter(p => p.isSelected).map(p => ({ staff: p.id })),
                            setSaveButtonInactive
                        );
                    })}
                    isOpen={openConfirm}
                    setIsOpen={setOpenConfirm}
                    title={'Confirm Editing'}
                    centerContent={true}
                    contentHtml={`${
                        getValues().name !== category.prevName
                            ? 'Changing the name of this category will also affect historic appointments, invoices, reports and anywhere else it features.<br>' +
                              'If you wish to keep historic records the same but have this change for the future, please inactivate this category and create a new one.<br><br>'
                            : ''
                    } These changes will be replicated to all services in that category. Are you sure?`}
                />
            )}
        </>
    );
}

CategoryFormModal.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func.isRequired
};

export default withStyles(categoryModalStyles)(CategoryFormModal);
