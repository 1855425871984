import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Modal from '../../common/Modal';
import { Button, makeStyles, withStyles } from '@material-ui/core';
import LoadingScreen from './../../../collums-components/components/common/loadingScreen';
import { toastr } from 'react-redux-toastr';
import Accordion from '../../common/Accordion';
import GeneralTab from './tabs/General';
import { modalsButtonStyles } from '../../../collums-constants/styles/stylesheets/buttonsStyles';
import { JobModalStyles } from './styles';
import JobApi from './../../../collums-components/api/jobApi';

function JobModal({ isVisible, onClose, job, classes }) {
    const globalStyles = makeStyles(modalsButtonStyles)();

    const formRef = useRef();
    const [isLoading, setIsLoading] = useState(false);

    const { register, handleSubmit, control } = useForm();

    const save = async value => {
        try {
            setIsLoading(true);
            if (job?.id) {
                await JobApi.update(job.id, value).then(() => onClose(true));
                toastr.success('Job successfully updated');
            } else {
                await JobApi.create(value).then(() => onClose(true));
                toastr.success('Job successfully created');
            }
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    };

    const tabProps = {
        register,
        control,
        job
    };

    return (
        <>
            {isLoading && <LoadingScreen />}
            <Modal
                id={'job-form-modal'}
                isOpen={isVisible}
                onCancel={() => onClose()}
                onClose={() => onClose()}
                title={`View/Edit${job?.name ? ` ${job.name}` : ''} Job`}
            >
                <form ref={formRef} className={classes.accordionsForm}>
                    <Accordion title={'General'}>
                        <GeneralTab {...tabProps} key="general" />
                    </Accordion>
                    <div className={globalStyles.buttonsContainer}>
                        <Button className={globalStyles.cancelButton} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            className={globalStyles.confirmButton}
                            onClick={handleSubmit(data => {
                                save(data);
                            })}
                        >
                            Confirm
                        </Button>
                    </div>
                </form>
            </Modal>
        </>
    );
}

JobModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(JobModalStyles)(JobModal);
