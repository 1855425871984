const setFormOptions = (item, fetchedForms, formRef, defaultFormProperties) => {
    const getById = formId => fetchedForms.find(form => form.id === formId);

    formRef.current = {
        customerForms: (item.customerForms || []).map(getById),
        practitionerForms: (item.practitionerForms || []).map(getById),
        formsToEmail: (item.formsToEmail || []).map(formItem => {
            const findForm = getById(formItem.form);
            return {
                ...formItem,
                form: findForm
            };
        }),
        defaultTreatmentRecord: item.defaultTreatmentRecord
    };

    if (defaultFormProperties) {
        defaultFormProperties.current = { ...formRef.current };
    }
};

export default setFormOptions;
