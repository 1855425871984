import React from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, Paper, TextField, withStyles, Typography, makeStyles } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import { paymentTypeModalStyles } from './styles';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import { useState } from 'react';

function PaymentTypeModal({ closeModal, classes, append, setUpdate, paymentType, updatePaymentType }) {
    const [paymentTypeLabel, setPaymentTypeLabel] = useState('');
    const globalStyles = makeStyles(modalsButtonStyles)();

    const submitForm = e => {
        e.preventDefault();
        e.stopPropagation();
        if (!paymentType || paymentType === 'new') {
            // add fields to form
            append({ name: paymentTypeLabel.trim(), label: paymentTypeLabel, active: true });
            setUpdate(true);
        } else {
            // update existing fields
            updatePaymentType(paymentType.id, {
                name: paymentType?.name === 'Card' ? paymentType.name : paymentTypeLabel.trim(),
                label: paymentTypeLabel,
                active: true
            });
            setTimeout(() => {
                // set signal to update database (with delay, because "updatePaymentType" triggers some changes)
                setUpdate(true);
            }, 100);
        }
        closeModal();
    };

    return (
        <Modal
            open={true}
            disableBackdropClick={false}
            onClose={() => closeModal()}
            className={classes.modal}
            onCancel={() => closeModal()}
        >
            <Paper className={classes.root}>
                <div className={classes.header}>
                    <Typography>
                        {paymentType?.label ? `Edit ${paymentType.label} payment type` : 'New payment type'}
                    </Typography>
                    <div className={classes.closeBtn}>
                        <CloseIcon onClick={closeModal} />
                    </div>
                </div>
                <form className={classes.formRoot} onSubmit={submitForm}>
                    <TextField
                        className={classes.formItem}
                        name="name"
                        label="Name"
                        variant="outlined"
                        defaultValue={paymentType?.label ? paymentType.label : ''}
                        onChange={e => setPaymentTypeLabel(e.target.value)}
                        inputProps={{ className: classes.textFieldInputProps }}
                    />

                    <div className={globalStyles.buttonsContainer}>
                        <Button className={globalStyles.cancelButton} onClick={() => closeModal()}>
                            Cancel
                        </Button>
                        <Button className={globalStyles.confirmButton} type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Paper>
        </Modal>
    );
}

PaymentTypeModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    classes: PropTypes.object,
    selectedTag: PropTypes.object
};

export default withStyles(paymentTypeModalStyles)(PaymentTypeModal);
