import api from '../collums-components/api/config';

class CustomerApi {
    static query(query) {
        return api.request({
            method: 'GET',
            url: '/customers',
            params: query
        });
    }

    static getMergeClientCustomers(query) {
        return api.request({
            method: 'GET',
            url: '/customers/merge-clients',
            params: query
        });
    }

    static createMergeCustomerTemplate(data) {
        return api.request({
            method: 'POST',
            url: '/customers/create-merged-customer/template',
            data
        });
    }

    static deleteCustomerAvatar(customerId) {
        return api.request({
            method: 'GET',
            url: `/customers/remove-avatar/${customerId}`
        });
    }

    static mergeCustomers(data) {
        return api.request({
            method: 'POST',
            url: '/customers/merge-customers',
            data
        });
    }

    static create(data) {
        return api.request({
            method: 'POST',
            url: '/customers',
            data
        });
    }

    static update(customerId, data) {
        return api.request({
            method: 'PUT',
            url: `/customers/${customerId}`,
            data
        });
    }

    static remove(customerId) {
        return api.request({
            method: 'DELETE',
            url: `/customers/${customerId}`
        });
    }

    static unremove(customerId) {
        return api.request({
            method: 'GET',
            url: `/customers/${customerId}/unremove`
        });
    }
}

export default CustomerApi;
