import Api from './config';

export default class AlertsApi {
    static getMessageCounter() {
        return Api.request({
            method: 'GET',
            url: '/messages-counter?period=month'
        });
    }

    static getAlerts(clinic, page = 0, rowsPerPage = 100, withArchived, name) {
        const query = new URLSearchParams();
        clinic && query.append('clinic', clinic);
        !withArchived && query.append('archived', false);
        name && query.append('name', name);
        query.append('page', page);
        query.append('rowsPerPage', rowsPerPage);

        return Api.request({
            method: 'GET',
            url: `/alerts?${query.toString()}`
        });
    }

    static getAlertsCount({ clinic, withArchived, name } = {}) {
        const query = new URLSearchParams();
        clinic && query.append('clinic', clinic);
        !withArchived && query.append('archived', false);
        name && query.append('name', name);

        return Api.request({
            method: 'GET',
            url: `/alerts/count?${query.toString()}`
        });
    }

    static getAlert(id) {
        return Api.request({
            method: 'GET',
            url: `/alerts/${id}`
        });
    }

    static changeActive(id, target, newValue) {
        return Api.request({
            method: 'PUT',
            url: `/alerts/${id}/change-active?newValue=${newValue}&target=${target}`
        });
    }

    static updateAlert(id, target, data) {
        return Api.request({
            method: 'PUT',
            url: `/alerts/${id}/${target}`,
            data
        });
    }

    static createAlert(data) {
        return Api.request({
            method: 'POST',
            url: '/alerts',
            data
        });
    }

    static changeArchived(id) {
        return Api.request({
            method: 'PUT',
            url: `/alerts/${id}/change-archived`
        });
    }
}
