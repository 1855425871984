import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import PractitionerApi from '../../api/PractitionerApi';
import View from '../common/View';
import PractitionerFormModal from './PractitionerFormModal';
import { practitionersViewStyles } from './styles';
import ListPagination from '../common/ListPagination/index';

import PractitionerDetailsModal from './PractitionerDetailsModal';
import JobApi from '../../collums-components/api/jobApi';
import AddressApi from '../../collums-components/api/AddressApi';
import { listClinics } from '../../collums-components/api/ClinicApi';
import ConfirmModal from '../common/ConfirmModal';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import { toastr } from 'react-redux-toastr';
import { getOrganisationCountryCode, toLocaleString } from '../../collums-components/helpers/index';
import CategoryApi from '../../collums-components/api/CategoryApi';
import CommissionApi from '../../collums-components/api/CommissionApi';
import moment from 'moment-timezone';
import OrganisationApi from '../../api/OrganisationsApi';
import { Typography, Link } from '@material-ui/core';

//const DEFAULT_AVATAR_URL = 'https://www.moblab.com/images/avatar-placeholder.jpg';

const PractitionersView = ({ clinic, isFromOrg }) => {
    const history = useHistory();
    if (isFromOrg === undefined) {
        isFromOrg = clinic === 'organisation';
    }
    const [practitioner, setPractitioner] = useState({});
    const [practitionerEdit, setPractitionerEdit] = useState(null);
    const [practitioners, setPractitioners] = useState({
        items: [],
        size: 10
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [jobs, setJobs] = useState([]);
    const [countries, setCountries] = useState([]);
    const [clinics, setClinics] = useState([]);

    const [confirmModal, setConfirmModal] = useState(false);
    const [confirmCreateModal, setConfirmCreateModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState('');
    const [modalCancel, setModalCancel] = useState(null);
    const [modalContinue, setModalContinue] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const [inactive, setInactive] = useState(true);
    const [archived, setArchived] = useState(false);

    const [filter, setFilter] = useState('');
    const location = useLocation();

    const [serviceCategories, setServiceCategories] = useState([]);
    const [commissions, setCommissions] = useState([]);
    const [countryCode, setCountryCode] = useState(null);

    useEffect(() => {
        const getOrganisation = async () => {
            return await OrganisationApi.getOrganisation();
        };

        if (!countryCode) {
            getOrganisation().then(org => {
                setCountryCode(getOrganisationCountryCode(org));
            });
        }
    }, [countryCode]);

    const loadCommissions = async () => {
        try {
            const commissions = await CommissionApi.list();
            setCommissions(commissions);
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        }
    };

    const loadCategories = async () => {
        const newCategories = await CategoryApi.getAll();
        setServiceCategories(newCategories);
    };

    const fetchFormData = async () => {
        JobApi.listAll().then(setJobs);
        AddressApi.getCountries().then(setCountries);
        listClinics().then(setClinics);
        await loadCategories();
        await loadCommissions();
    };

    const refreshList = async () => {
        ApiCall(0, rowsPerPage, '', true);
    };

    const getPractitionerId = () => {
        const query = queryString.parse(location.search);
        return query.practitionerId;
    };

    const getFormDefaultValues = async id => {
        return await PractitionerApi.get(id);
    };

    const onCreate = () => {
        setConfirmCreateModal(true);
    };

    const openDetails = async practitioner => {
        const practitionerId = practitioner?.id || 'new';

        await fetchFormData();

        if (practitionerId !== 'new') {
            const practitionerDefaultValues = await getFormDefaultValues(practitionerId);
            const transformedValues = {
                ...practitionerDefaultValues,
                commissions:
                    practitionerDefaultValues.commissions &&
                    Object.keys(practitionerDefaultValues.commissions)
                        .map(type => {
                            const current = practitionerDefaultValues.commissions[type];

                            return {
                                [type]: {
                                    ...current,
                                    commissionRanges: (current.commissionRanges || []).map(range => {
                                        return {
                                            from: toLocaleString(range.from),
                                            to: toLocaleString(range.to),
                                            commission: {
                                                ...range.commission,
                                                value: range.commission.value
                                            }
                                        };
                                    })
                                }
                            };
                        })
                        .reduce((curr, acc) => ({ ...acc, ...curr }), {})
            };
            setPractitioner(transformedValues);
        } else setPractitioner({});
        if (clinic) {
            history.push(`/location/${clinic?.id}/staff?${queryString.stringify({ practitionerId })}`);
        } else {
            history.push(`/organisation/staffs?${queryString.stringify({ practitionerId })}`);
        }
    };

    const onDetailsCancel = () => {
        if (clinic && !clinic.id) {
            clinic.id = location.pathname.split('/')[2];
        }
        if (clinic) {
            history.push(`/location/${clinic?.id}/staff`);
        } else {
            history.push('/organisation/staffs');
        }
    };

    const ApiCall = async (skip = 0, limit = 15, queryParam = '', refreshing = false) => {
        setIsLoading(true);

        if (!queryParam) {
            queryParam = `&inactive=${inactive}&archived=${archived}${filter ? `&filter=${filter}` : ''}`;
        }

        if (queryParam.indexOf('inactive=true') > -1) setInactive(true);
        else setInactive(false);

        if (queryParam.indexOf('filter=') > -1) {
            const splitByFilterName = queryParam.split('filter=')[1];
            if (splitByFilterName) {
                const filterName = splitByFilterName.split('&')[0];
                setFilter(filterName);
            }
        } else setFilter('');

        if (queryParam.indexOf('archived=true') > -1) setArchived(true);
        else setArchived(false);

        if (clinic && !clinic.id) {
            clinic.id = location.pathname.split('/')[2];
        }

        if (clinic?.id && queryParam.length && queryParam.indexOf('clinic=') === -1) {
            queryParam = queryParam + `&clinic=${clinic.id}`;
        }
        if (queryParam.length && queryParam.indexOf('isFromOrg=') === -1) {
            queryParam = queryParam + `&isFromOrg=${isFromOrg}`;
        }
        try {
            const newData = await PractitionerApi.adminQuery(skip, limit, queryParam).then(res => {
                const date = Date.now();
                return {
                    ...res,
                    items: res.items.map(p => ({ ...p, avatar: p.avatar ? `${p.avatar}?${date}` : undefined }))
                };
            });
            if (refreshing) {
                setPractitioners(newData);
                setPage(0);
            }
            return newData.items;
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    };

    const onEdit = async item => {
        return item;
    };

    const onRemove = async () => {
        try {
            const id = practitioner.id;
            if (!id) return;
            const value = !practitioner.archived;
            await PractitionerApi.remove(id, value, clinic?.id, isFromOrg);
            refreshList();
            toastr.success(`Staff ${value ? 'removed' : 'unarchived '} successfully`);
            setPractitioner({});
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong on archive practitioner');
        }
    };

    const toDo = () => {
        if (['Archive practitioner', 'Unarchive practitioner'].includes(modalTitle)) onRemove();
    };

    const openRemove = async data => {
        setPractitioner(data);
        const apptsInFuture = await PractitionerApi.getApptsInFuture(data.id);

        if (apptsInFuture.length && !data.archived) {
            openConfirmModal(
                'Archive practitioner',
                <>
                    This staff has future appointments in the calendar on the following dates:
                    {apptsInFuture.map((appt, index) => (
                        <p key={index}>
                            {' '}
                            {moment(appt.event.start).format(' HH:mm - Do MMMM YYYY')} at {appt.clinic.organisationName}
                        </p>
                    ))}
                    <p>Are you sure you wish to continue?</p>
                </>,
                'Archive staff',
                'Back'
            );
            return;
        }

        if (data.archived) {
            openConfirmModal(
                'Unarchive practitioner',
                'Are you sure you want to unarchive this practitioner? If you do this, this practitioner will be activated.'
            );
            return;
        }
        openConfirmModal(
            'Archive practitioner',
            'Are you sure you want to archive this practitioner? If you do this, this practitioner will be deactivated.'
        );
    };
    /*const openAssignPractitioners = async data => {
        await setPractitioner(data);
        // setAssignStaffModalOpen(true);
    };*/

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setModalTitle('');
        setModalContent();
        setModalContinue(null);
        setModalCancel(null);
    };

    const openConfirmModal = (title, component, continueText, cancelText) => {
        setConfirmModal(true);
        setModalTitle(title);
        setModalContent(component);
        setModalContinue(continueText);
        setModalCancel(cancelText);
    };

    const assignPractitionersAction = (() => {
        if (isFromOrg) {
            return [
                {
                    title: 'archive-practitioner',
                    action: openRemove
                }
            ];
        }
        return [];
    })();

    // !requested to remove this function on Story 439 | const openEditPractitioner = item => setPractitionerEdit(item);
    const closeEditPractitioner = () => setPractitionerEdit(null);
    return (
        <View>
            <ListPagination
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                page={page}
                clinic={clinic?.id}
                setPage={setPage}
                isFromOrg={isFromOrg}
                data={practitioners.items}
                defaultColumns={[
                    { title: 'Photo', field: 'photo' },
                    { title: 'Display Name', field: 'displayName', onClick: openDetails },
                    { title: 'First Name', field: 'firstName' },
                    { title: 'Surname', field: 'surname' },
                    { title: 'Gender', field: 'gender' },
                    { title: 'Email', field: 'email' }
                ]}
                maxSize={practitioners.size}
                actions={assignPractitionersAction.filter(el => el)}
                ApiCall={ApiCall}
                filters={[
                    { name: 'archived', label: 'Show Archived', value: false },
                    { name: 'inactive', label: 'Show Inactive', value: false }
                ]}
                onCreate={onCreate}
                nameFilter={true}
                filterName="filter"
                highlightArchived={true}
            />
            {!!practitionerEdit && (
                <PractitionerDetailsModal
                    key={getPractitionerId()}
                    practitionerId={practitionerEdit.id}
                    soloPractitioner={practitioners.soloPractitioner}
                    practitioner={practitionerEdit}
                    onConfirm={onEdit}
                    refreshList={refreshList}
                    countries={countries}
                    onCancel={closeEditPractitioner}
                />
            )}
            {!!getPractitionerId() && (
                <PractitionerFormModal
                    visible={true}
                    practitioner={practitioner}
                    onClose={onDetailsCancel}
                    soloPractitioner={practitioners.soloPractitioner}
                    jobs={jobs}
                    countries={countries}
                    refreshList={refreshList}
                    setIsLoading={setIsLoading}
                    allClinics={clinics}
                    currentClinic={clinic}
                    isFromOrg={isFromOrg}
                    serviceCategories={serviceCategories}
                    commissions={commissions}
                    countryCode={countryCode}
                />
            )}
            <LoadingScreen isVisible={isLoading} />
            {confirmModal && (
                <ConfirmModal
                    isOpen
                    setIsOpen={closeConfirmModal}
                    onConfirm={toDo}
                    title={modalTitle}
                    content={modalContent}
                    centerContent
                    continueText={modalContinue}
                    cancelText={modalCancel}
                />
            )}
            <ConfirmModal
                isOpen={confirmCreateModal}
                hideCloseIcon={true}
                setIsOpen={value => {
                    setConfirmCreateModal(value);
                }}
                onConfirm={() => openDetails({})}
                title={''}
                content={
                    <>
                        <Typography>Adding a new user will increase the cost of your subscription.</Typography>
                        <Typography>
                            Please tell us via <Link href="mailto:support@collums.co">support@collums.co</Link>
                        </Typography>
                        <br />
                        <Typography>
                            Adding a new user without notice may result in a double charge for this user (up to £50 per
                            month).
                        </Typography>
                        <br />
                        <Typography>Please only continue if you understand and agree to this.</Typography>
                    </>
                }
                centerContent
                continueText={'I agree & continue'}
                cancelText={'Back'}
            />
        </View>
    );
};

PractitionersView.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(practitionersViewStyles)(PractitionersView);
