export const TabStyles = () => ({
    formContent: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap'
    },
    formContentRow: {
        width: '100%'
    },
    formItem: {
        width: '48%',
        marginRight: 8,
        marginTop: 8,
        marginBottom: 8
    },
    largeFormItem: {
        width: '100%'
    }
});
