import { makeStyles, FormControl, Divider, List, Typography, FormHelperText } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { serviceDetailsModalStyles } from '../styles';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DragableListItem from '../../common/DragableListItem';
import PropTypes from 'prop-types';
import { isArray } from 'lodash';
import ClinicSelector from '../../common/ClinicSelector';
import { STATUS_ACTIVE } from '../../../collums-constants';

function RoomEquipmentTab({
    register,
    control,
    watch,
    currentService,
    clinics,
    rooms,
    equipments,
    isFromOrg,
    allClinics
}) {
    const [roomError, setRoomError] = useState(false);
    const classes = makeStyles(serviceDetailsModalStyles)();
    const selectedTagsWatch = watch('room_equipment.clinics');

    // eslint-disable-next-line
    const watchLocations = watch('locations') || [];
    const [selectedLocations, setSelectedLocations] = useState([]);
    if (!isArray(rooms)) {
        rooms = rooms?.items || [];
    }
    if (!isArray(equipments)) {
        equipments = equipments?.items || [];
    }

    useEffect(() => {
        setSelectedLocations(watchLocations.filter(location => location.isSelected));
    }, [watchLocations]);

    // ---- Order the location rooms & equips on the same order as Locations, ignoring the check order
    const orderedLocationOptions = [];
    if (selectedLocations && selectedTagsWatch?.length) {
        selectedLocations.forEach(selectedLoc => {
            selectedTagsWatch.forEach(location => {
                if (location === selectedLoc.label) {
                    orderedLocationOptions.push(location);
                }
            });
        });
    } else if (selectedLocations && !selectedTagsWatch?.length) {
        selectedLocations.forEach(selectedLoc => {
            orderedLocationOptions.push(selectedLoc.label);
        });
    }

    const handleRoomsDefaultValue = clinic => {
        const currLocation = selectedLocations.find(location => location.label === clinic);

        if (currLocation === undefined) return [];

        const locationData = currentService.locations.find(location => location.clinic === currLocation.value);

        if (locationData) {
            const mappedRooms = (rooms || []).map(room => {
                const selectedRoom = (!isArray(locationData.rooms)
                    ? locationData.rooms.items
                    : locationData.rooms
                ).find(selectedRoom => selectedRoom.room === room.id);
                if (selectedRoom) return { ...room, isSelected: true, priority: selectedRoom.priority };
                else return { ...room, isSelected: false, priority: -1 };
            });
            return orderByPriority(mappedRooms);
        } else {
            return rooms;
        }
    };

    const handleEquipmentDefaultValue = clinic => {
        const currLocation = selectedLocations.find(location => location.label === clinic);

        if (currLocation === undefined) return [];

        const locationData = currentService.locations.find(location => location.clinic === currLocation.value);

        if (locationData) {
            const mappedEquips = equipments.map(equip => {
                const selectedEquip = locationData.equipments.find(selectedRoom => selectedRoom.equipment === equip.id);
                if (selectedEquip) return { ...equip, isSelected: true, priority: selectedEquip.priority };
                else return { ...equip, isSelected: false, priority: -1 };
            });

            return orderByPriority(mappedEquips);
        } else {
            return equipments;
        }
    };

    const orderByPriority = items => {
        const orderedByPriority = items.sort((a, b) => {
            if (a !== -1 && b !== -1) {
                if (a.priority < b.priority) return -1;
                else if (a.priority > b.priority) return 1;
                return 0;
            }
            return 0;
        });

        const orderedByIsSelected = orderedByPriority.sort((a, b) => {
            if (a.isSelected && !b.isSelected) return -1;
            else if (!a.isSelected && b.isSelected) return 1;
            return 0;
        });

        return orderedByIsSelected;
    };

    const handleChange = (oneSelected = false) => (list, setList, id) => {
        setRoomError(false);

        const itemIndex = list.findIndex(value => value.id === id);

        if (oneSelected) {
            // minimum one selected
            const selectedItem = list.filter(item => item.isSelected && item.active === STATUS_ACTIVE).length;
            if (selectedItem < 2 && list[itemIndex].isSelected) {
                setRoomError(true);
                return;
            }
        }

        const newItem = { ...list[itemIndex], isSelected: !list[itemIndex].isSelected };
        const newList = [...list];

        newList.splice(itemIndex, 1, newItem);
        setList(newList);
    };

    const handleItemReordering = (dragged, draggedOver, onChange, value) => {
        let newItems = value;
        const setter = onChange;
        const draggedIndex = newItems.findIndex(item => item.id === dragged.id);
        const draggedOverIndex = newItems.findIndex(item => item.id === draggedOver.id);
        newItems[draggedOverIndex] = { ...dragged, id: dragged.id };
        newItems[draggedIndex] = { ...draggedOver, id: draggedOver.id };
        setter([...newItems]);
    };

    const handleDrop = (draggedItem, draggedOver, onChange, value) => {
        if (draggedItem && draggedOver && draggedItem.id !== draggedOver.id)
            handleItemReordering(draggedItem, draggedOver, onChange, value);
    };

    const addIsSelected = (arr, isRoom) => {
        if (isRoom && arr.length === 1) {
            return arr.map(item => {
                return { ...item, isSelected: true };
            });
        }
        return arr.map(item => {
            return { ...item, isSelected: false };
        });
    };

    const renderRooms = (onChange, value, index) => {
        // value = value.filter(room => categoryRoomIds.includes(room.id));
        const isActive = room => {
            return room?.active === STATUS_ACTIVE;
        };

        const activeRooms = value.filter(item => item?.active === STATUS_ACTIVE && !item?.archived) || [];
        return (
            <>
                <Typography className={classes.clinicLabel}>Rooms</Typography>
                <List>
                    <DndProvider backend={HTML5Backend}>
                        {value.map((room, itemIndex) => {
                            return (
                                <>
                                    <DragableListItem
                                        classes={classes}
                                        key={`room-item-${itemIndex}`}
                                        item={{ ...room }}
                                        handleDrop={handleDrop}
                                        handleChange={handleChange(true)}
                                        onChange={onChange}
                                        value={value}
                                        type={`REORDER_ROOMS-${index}`}
                                        hidden={!isActive(room)}
                                        disabled={
                                            !isActive(room) ||
                                            (activeRooms.length === 1 && activeRooms.every(item => item.isSelected))
                                        }
                                    />
                                    {isActive(room) ? <Divider /> : ''}
                                </>
                            );
                        })}
                    </DndProvider>
                    {roomError && <FormHelperText error={true}>At least one room must be selected</FormHelperText>}
                </List>
            </>
        );
    };

    const renderEquipment = (onChange, value, index) => {
        return (
            <>
                <Typography className={classes.clinicLabel}>Equipment</Typography>
                <DndProvider backend={HTML5Backend}>
                    <List>
                        {value.map((item, itemIndex) => {
                            return (
                                <>
                                    <DragableListItem
                                        classes={classes}
                                        key={`equipment-item-${itemIndex}`}
                                        item={{ ...item }}
                                        handleDrop={handleDrop}
                                        handleChange={handleChange(false)}
                                        onChange={onChange}
                                        value={value}
                                        type={`REORDER_EQUIPMENTS-${index}`}
                                    />
                                    <Divider />
                                </>
                            );
                        })}
                    </List>
                </DndProvider>
            </>
        );
    };

    const clinicDefaultValues =
        currentService?.locations
            ?.map(el => {
                const exists = (clinics || []).find(clinic => clinic.id === el.clinic);
                return exists?.accountName;
            })
            .filter(el => el) || [];
    return (
        <div className={classes.formContent}>
            {!selectedLocations.length && <Typography>First you need to choose a location</Typography>}
            {isFromOrg && !!selectedLocations.length && allClinics.length !== 1 && (
                <div className={classes.formContentRow}>
                    <ClinicSelector
                        name="room_equipment.clinics"
                        control={control}
                        className={classes.formItem}
                        options={selectedLocations}
                        defaultValue={clinicDefaultValues}
                        currentItem={currentService}
                    />
                </div>
            )}
            {orderedLocationOptions &&
                orderedLocationOptions.map((clinic, index) => {
                    // ---- Check if the location to render is selected
                    const selectedLocationsFiltered = selectedLocations
                        .map(loc => {
                            return loc.label === clinic ? loc.label : false;
                        })
                        .filter(el => el);
                    if (!selectedLocationsFiltered.includes(clinic)) {
                        return false;
                    }
                    // ---- Finds the real location index among all clinic locations
                    const locationRealIndex = clinics.findIndex(location => location.accountName === clinic);
                    return (
                        <>
                            {isFromOrg && <Typography className={classes.clinicTitle}>{clinic}</Typography>}
                            <div className={classes.listRow}>
                                <FormControl className={classes.listContainer}>
                                    <Controller
                                        name={`locations[${locationRealIndex}].rooms[${index}]`}
                                        control={control}
                                        rules={register}
                                        defaultValue={
                                            currentService.locations
                                                ? handleRoomsDefaultValue(clinic)
                                                : addIsSelected(rooms, true)
                                        }
                                        render={({ onChange, value }) => {
                                            return renderRooms(onChange, value, index);
                                        }}
                                    ></Controller>
                                </FormControl>
                                <FormControl className={classes.listContainer}>
                                    <Controller
                                        name={`locations[${locationRealIndex}].equipments[${index}]`}
                                        control={control}
                                        rules={register}
                                        defaultValue={
                                            currentService.locations
                                                ? handleEquipmentDefaultValue(clinic)
                                                : addIsSelected(equipments)
                                        }
                                        render={({ onChange, value }) => {
                                            return renderEquipment(onChange, value, index);
                                        }}
                                    ></Controller>
                                </FormControl>
                            </div>
                        </>
                    );
                })}
        </div>
    );
}

RoomEquipmentTab.propTypes = {
    register: PropTypes.any,
    control: PropTypes.any,
    clinics: PropTypes.array,
    watch: PropTypes.any
};

export default RoomEquipmentTab;
