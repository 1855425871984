export const settingsStyles = theme => ({
    selectField: {
        width: '100%'
    },
    breakLines: {
        marginTop: 30
    },
    intervalContainer: {
        height: 56,
        width: '60%'
    },
    fieldContainer: {
        height: 56,
        width: '75%'
    },
    inlineContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    requireCard: {
        marginLeft: -10
    },
    intervalLabel: {
        marginTop: 9
    },
    intervalSelect: {
        width: 100,
        marginLeft: 10
    },
    minTimeBefore: {
        width: 80,
        marginLeft: 10,
        marginRight: 10
    },
    formControl: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '75%',
        '& .MuiTypography-root': {
            color: 'rgba(0, 0, 0, 0.87)'
        }
    },
    addPaymentTypeContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 10,
        alignItems: 'center'
    },
    clickableText: {
        cursor: 'pointer',
        color: '#3083EC !important'
    },
    notificationContainer: {
        marginLeft: 10,
        marginTop: 25
    },
    disallowContainer: {
        marginLeft: 10
    },
    checkboxesContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    infoContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    reportsContainer: {
        display: 'block'
    },
    paymentsTabRoot: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    typeNotFixed: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    sectionTitle: {
        marginLeft: 16,
        marginTop: 10
    },
    sectionTitleWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    noPaddingTop: {
        paddingTop: '0 !important'
    },
    noMarginLeft: {
        marginLeft: '0 !important'
    },
    extraPaddingBottom: {
        paddingBottom: '35px !important'
    },
    disabled: {
        color: 'rgba(0, 0, 0, 0.38)'
    },
    colorInput: {
        width: '100px',
        marginLeft: 8,
        marginRight: 8,
        marginTop: 8,
        marginBottom: 8
    },
    calendarColors: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: 16,
        '@media (max-width: 970px)': {
            flexDirection: 'column',
            alignItems: 'flex-start'
        }
    },
    colorItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center'
    },
    marginTop: {
        marginTop: 5
    },
    row: {
        margin: '15px 0',
        width: '100%',
        padding: 0
    },
    customerAppSettingsContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    ProductStockContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    editorSize: {
        width: '30%',
        [theme.breakpoints.up('mobileMax')]: {
            width: '60%'
        },
        [theme.breakpoints.up('tabletPortrait')]: {
            width: '90%'
        },
        [theme.breakpoints.up('tabletLandscape')]: {
            width: '95%'
        },
        [theme.breakpoints.up('ipadPro')]: {
            width: '100%'
        }
    },
    accordionDetails: {
        display: 'flex',
        '@media (max-width: 740px)': {
            display: 'block'
        }
    }
});

export const paymentTypeModalStyles = () => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiPaper-root': {
            paddingBottom: 24
        }
    },
    root: {
        padding: '0.5% 2.5%'
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        '& p': {
            fontSize: 16,
            fontWeight: 500
        },
        position: 'relative',
        margin: '5px 0'
    },
    closeBtn: {
        position: 'absolute',
        right: 0,
        top: 0,
        '& :hover': {
            cursor: 'pointer'
        }
    },
    formRoot: {
        display: 'flex',
        flexDirection: 'column'
    },
    formItem: {
        width: '20vw !important',
        marginTop: 8,
        marginRight: 8,
        marginBottom: 8,
        paddingRight: 0,
        '& label': {
            fontSize: 14
        }
    }
});

export const taxRateStyles = () => ({
    taxRatesContainer: {
        display: 'flex',
        flexDirection: 'column',
        // marginLeft: 10,
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
    },
    taxRatesTabRoot: {
        width: '100%'
    }
});

export const taxRateModalStyles = () => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiPaper-root': {
            paddingBottom: 24
        }
    },
    root: {
        padding: '0.5% 2.5%'
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        '& p': {
            fontSize: 16,
            fontWeight: 500
        },
        position: 'relative',
        margin: '5px 0'
    },
    closeBtn: {
        position: 'absolute',
        right: 0,
        top: 0,
        '& :hover': {
            cursor: 'pointer'
        }
    },
    formRoot: {
        display: 'flex',
        flexDirection: 'column'
    },
    formItem: {
        width: '20vw !important',
        marginTop: 8,
        marginRight: 8,
        marginBottom: 8,
        paddingRight: 0,
        '& label': {
            fontSize: 14
        }
    },
    itemCheckboxes: {
        display: 'flex',
        flexDirection: 'column'
    },
    applyText: {
        marginTop: '10px'
    }
});

export const commissionModalStyles = () => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // maxWidth: '80%',
        '& .MuiPaper-root': {
            paddingBottom: 24
        }
    },
    root: {
        maxHeight: '90%',
        overflowY: 'scroll',
        padding: '0.5% 2.5%'
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        '& p': {
            fontSize: 16,
            fontWeight: 500
        },
        position: 'relative',
        margin: '5px 0'
    },
    closeBtn: {
        position: 'absolute',
        right: 0,
        top: 0,
        '& :hover': {
            cursor: 'pointer'
        }
    },
    formItem: {
        width: '20vw !important',
        marginTop: 8,
        marginRight: 8,
        marginBottom: 8,
        paddingRight: 0,
        '& label': {
            fontSize: 14,
            fontFamily: 'Roboto, Arial, Sans-serif'
        }
    },
    commissionTypes: {
        minWidth: '20vw',
        width: '100% !important',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    commissionRuleContainer: {},
    commissionRuleRow: {
        padding: '10px 0'
    },
    removeRuleButton: {
        backgroundColor: 'red !important',
        color: 'white',
        marginBottom: '15px !important'
    },
    commissionButton: {
        margin: 8,
        fontSize: 14,
        fontWeight: 400,
        textTransform: 'none',
        backgroundColor: '#3083EC'
    },
    tableContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    commissionCatSelectorContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        position: 'relative',
        '& > p': {
            width: '20%'
        },
        '& > div': {
            width: '80%',
            height: '100%',
            maxWidth: '565px',
            position: 'relative'
        }
    },
    flatContent: {
        width: '80%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    flatRateInputs: {
        display: 'flex',
        flexDirection: 'row'
    },
    textWarning: {
        fontSize: 14,
        marginBlockStart: 0,
        fontWeight: '400 !important'
    },
    formContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        paddingTop: '20px',
        // width: '70%',
        // flexWrap: 'wrap',
        fontFamily: 'Roboto, Arial, sans-serif !important'
    },
    formContentRow: {
        // width: '70%'
    },
    formTextField: {
        width: '100%',
        fontFamily: 'Roboto, Arial, Sans-serif !important'
    },
    accordionTitle: {
        '& p': {
            marginBottom: 10,
            fontSize: 14,
            fontWeight: 500
        }
    }
});

export const TaxInUseModalStyles = () => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiPaper-root': {
            maxHeight: '90vh',
            overflowY: 'scroll',
            paddingBottom: 24,
            width: '80vw'
        }
    },
    usingTaxContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        '& > div': {
            width: '33%'
        }
    },
    resourcesUsingTax: {
        width: '33%'
    },
    root: {
        padding: '0.5% 2.5%'
    },
    subtitle: {
        fontWeight: 'bold'
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        '& p': {
            fontSize: 16,
            fontWeight: 500
        },
        position: 'relative',
        margin: '15px 0'
    },
    closeBtn: {
        position: 'absolute',
        right: 0,
        top: 0,
        '& :hover': {
            cursor: 'pointer'
        }
    },
    linkItem: {
        '&:visited': {
            color: 'blue'
        }
    }
});

export const SecurityStyles = () => ({
    fieldContainer: {
        height: 56,
        marginTop: 20
    },
    inlineContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    customerAppSettingsContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 10
    },
    logoutTime: {
        width: 80,
        marginLeft: 10,
        marginRight: 10
    },
    disabled: {
        color: '#7e7e7e'
    }
});

export const treatmentRecordStyles = () => ({
    toxin: {
        display: 'flex',
        alignItems: 'center'
    },
    optionContainer: {
        width: '100%',
        display: 'flex',
        alignitems: 'center'
    },
    treatmentRecordOption: {
        marginTop: 15,
        marginBottom: 20
    },
    toxinInfo: {
        display: 'flex',
        width: 140,
        alignItems: 'center'
    },
    autocomplete: {
        width: '250px !important'
    },
    doseInfo: {
        display: 'flex',
        width: 140
    },
    doseUnit: {
        display: 'flex',
        marginTop: 20
    },
    doseUnitInputs: {
        width: 320,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    doseUnitInput: {
        width: 50,
        marginRight: 12.5,
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        }
    },
    circleIcon: {
        marginLeft: '128px',
        color: '#3083EC',
        fontSize: 14
    },
    deleteIcon: {
        color: 'red',
        fontSize: 18,
        marginBottom: '-12px'
    },
    deleteIconArea: {
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: '20px'
    }
});
