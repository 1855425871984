import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from '@material-ui/core';
import LoadingScreen from '../../../../collums-components/components/common/loadingScreen';
import { toastr } from 'react-redux-toastr';
import NotificationApi from '../../../../api/NotificationApi';

//eslint-disable-next-line
const EmailTemplate = ({ message, getTemplates, closeModal, subject }) => {
    const [isLoading, setIsLoading] = useState(false);

    const [name, setName] = useState('');

    const save = async () => {
        if (!name) {
            toastr.warning('Name cannot be blank');
            return;
        }
        setIsLoading(true);
        const data = {
            name,
            type: 'email',
            content: message,
            subject: subject
        };
        try {
            const newTemplate = await NotificationApi.createTemplate(data);
            if (getTemplates) getTemplates();
            toastr.success('Email template successfully created');
            closeModal(newTemplate);
        } catch (err) {
            if (typeof err === 'object') {
                if (err.data && err.data.message) {
                    toastr.error(err.data.message);
                    setIsLoading(false);
                    return;
                }
            }
            toastr.error('Something went wrong');
        }
        setIsLoading(false);
    };

    return (
        <>
            <Dialog open={true} onClose={() => closeModal()} aria-labelledby="email-template-save">
                <DialogTitle id="email-template-save">Save Email Template</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Before you save this new email template, you need to choose a name to it.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        fullWidth
                        value={name}
                        onChange={evt => setName(evt.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closeModal()} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={save} color="primary">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
            {isLoading && <LoadingScreen />}
        </>
    );
};

export default EmailTemplate;
