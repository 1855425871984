import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';
import { toastr } from 'react-redux-toastr';

import { Button, Modal, Paper, TextField, withStyles, Typography, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

import TagsApi from '../../api/TagsApi';

import { tagModalStyles } from './styles';
import { modalsButtonStyles } from '../../../collums-constants/styles/stylesheets/buttonsStyles';
import { useEffect } from 'react';

function TagsModal({ isVisible, selectedTag, isFromOrg, closeModal, classes }) {
    const globalStyles = makeStyles(modalsButtonStyles)();

    const formRef = useRef();

    const typeOpts = [
        'Jobs',
        'Clients',
        'Consultation',
        'Forms',
        'Services',
        'Categories',
        'Equipment',
        'Rooms',
        'Treatment records',
        'Toxin',
        'Filler',
        'LHR'
    ];

    const { register, handleSubmit, errors } = useForm();

    useEffect(() => {
        const requiredFields = Object.keys(errors);

        if (requiredFields.length !== 0) {
            const messages = requiredFields.map(field => errors[field].message);

            toastr.error('Missing fields', messages.join(','));
        }
    }, [errors]);

    const persistData = values => {
        if (selectedTag) {
            TagsApi.updateTag(selectedTag.id, values)
                .then(() => {
                    toastr.success('Tag updated!');
                    closeModal();
                })
                .catch(err => {
                    if (typeof err === 'object') {
                        if (err.data && err.data.message) {
                            toastr.error(err.data.message);
                            return;
                        }
                    }
                    toastr.error('Something went wrong');
                });
        } else {
            TagsApi.createTag({ ...values, active: true })
                .then(() => {
                    toastr.success('Tag created!');
                    closeModal();
                })
                .catch(err => {
                    if (typeof err === 'object') {
                        if (err.data && err.data.message) {
                            toastr.error(err.data.message);
                            return;
                        }
                    }
                    toastr.error('Something went wrong');
                });
        }
    };

    return (
        <Modal
            open={isVisible}
            disableBackdropClick={false}
            onClose={() => closeModal()}
            className={classes.modal}
            onCancel={() => closeModal()}
        >
            <Paper className={classes.root}>
                <div className={classes.header}>
                    <Typography>{selectedTag ? `View/Edit ${selectedTag.name} Tag` : 'New Tag'}</Typography>
                    <div className={classes.closeBtn}>
                        <CloseIcon onClick={closeModal} />
                    </div>
                </div>
                <form
                    ref={formRef}
                    className={classes.formRoot}
                    onSubmit={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleSubmit(persistData)();
                    }}
                >
                    <TextField
                        className={classes.formItem}
                        inputRef={register({ required: 'Name' })}
                        name="name"
                        label="Name *"
                        variant="outlined"
                        defaultValue={selectedTag?.name || ''}
                        inputProps={{ className: classes.textFieldInputProps, readOnly: !isFromOrg }}
                    />
                    <TextField
                        className={classes.formItem}
                        inputRef={register}
                        name="description"
                        label="Description"
                        variant="outlined"
                        defaultValue={selectedTag?.description || ''}
                        inputProps={{ className: classes.textFieldInputProps, readOnly: !isFromOrg }}
                    />
                    <Autocomplete
                        disabled={!isFromOrg}
                        className={classes.autocomplete}
                        freeSolo
                        options={typeOpts || []}
                        label="Type"
                        variant="outlined"
                        defaultValue={selectedTag?.type || ''}
                        renderInput={params => (
                            <TextField
                                {...params}
                                className={classes.autocompleteTextField}
                                inputRef={register({ required: 'Type' })}
                                name="type"
                                label="Type *"
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    classes: {
                                        input: classes.textFieldInputProps
                                    }
                                }}
                            />
                        )}
                        renderOption={option => {
                            return <p className={classes.autocompleteOptions}>{`${option}`}</p>;
                        }}
                    />
                    {isFromOrg && (
                        <div className={globalStyles.buttonsContainer}>
                            <Button className={globalStyles.cancelButton} onClick={() => closeModal()}>
                                Cancel
                            </Button>
                            <Button className={globalStyles.confirmButton} type="submit">
                                Save
                            </Button>
                        </div>
                    )}
                </form>
            </Paper>
        </Modal>
    );
}

TagsModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    classes: PropTypes.object,
    selectedTag: PropTypes.object,
    isFromOrg: PropTypes.bool.isRequired
};

export default withStyles(tagModalStyles)(TagsModal);
