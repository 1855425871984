const GoogleAutocompleteStyles = () => ({
    autocomplete: {
        margin: 0,
        fontSize: 14,
        width: '100%',
        '& input': {
            fontSize: 14,
            marginTop: -8
        }
    },
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '4px',
        '& > button': {
            padding: 8,
            '& svg > path': {
                fill: '#3083EC'
            }
        }
    },
    autoCompleteTextField: {
        '& input::placeholder': {
            opacity: '1 !important',
            color: '#333 !important',
            fontSize: '14px !important',
            fontFamily: 'Roboto, Arial, Sans-serif !important'
        },
        '& input': {
            paddingBottom: '12px !important'
        }
    }
});

export default GoogleAutocompleteStyles;
