import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';

import { multipleAutocompleteStyles } from './styles';

class MultipleAutocomplete extends React.Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(event, value) {
        const { field, onChange } = this.props;
        if ('string' === typeof value[value.length - 1]) {
            const userString = value.pop();
            const option = field.options.find(
                option => option.label.trim().toLocaleLowerCase() === userString.trim().toLocaleLowerCase()
            );
            if (option) {
                value.push(option);
            } else {
                value.push({ label: userString, value: userString });
            }
        }
        onChange(field.id, value);
    }

    render() {
        const { field, classes } = this.props;
        return (
            <div className={classes.container}>
                <Autocomplete
                    id={field.id}
                    options={('DYNAMIC' === field.options ? [] : field.options) || []}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    renderInput={params => (
                        <TextField {...params} label={field.label} placeholder={field.placeholder} fullWidth />
                    )}
                    value={field.value || []}
                    freeSolo={field.allowUndefinedOption}
                    multiple
                />
            </div>
        );
    }
}

MultipleAutocomplete.propTypes = {
    field: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(multipleAutocompleteStyles)(MultipleAutocomplete);
