import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Typography, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CategoryFormModal from './ProductCategoryFormModal';
import ConfirmModal from '../common/ConfirmModal';
import PropTypes from 'prop-types';
import Modal from '../common/Modal';
import ProductCategoryApi from '../../api/ProductCategoryApi';
import { productCategoryModalStyles } from './styles';
import { toastr } from 'react-redux-toastr';
import { getLocationItem } from '../../collums-constants/utils';
const _ = require('lodash');

const ProductCategoryModal = props => {
    const [category, setCategory] = useState({});
    const methodType = props.methodType;

    useEffect(() => {
        const getCat = async () => {
            const cat = await ProductCategoryApi.getCategory(props.categoryId);
            setCategory({ ...cat, prevName: cat.name });
        };
        getCat();
    }, [props.categoryId]);

    const confirm = async (method, data = {}) => {
        let response, errData;
        let action = method;
        if (method === 'archive' && category.name.includes('- COPY'))
            return toastr.error("Can't arhcive copies with 'COPY' in name");
        if (category.id === undefined) action = 'create';

        switch (action) {
            case 'create':
                try {
                    const clonedData = _.cloneDeep(data);

                    const locations = (clonedData?.locations || []).filter(el => el && el.isSelected);
                    clonedData.locations = locations.map(el => {
                        return {
                            clinic: el.value,
                            allowCommission: data.allowCommission,
                            autoStockControl: data.autoStockControl,
                            allowNegative: data.allowNegative,
                            active: true,
                            archived: false
                        };
                    });

                    response = await ProductCategoryApi.createCategory(clonedData, props.clinic, props.isFromOrg);
                    toastr.success(`Category ${response.name} successfully created!`);
                } catch (err) {
                    err.data !== undefined ? (errData = err.data.message) : (errData = 'Something went wrong');
                    toastr.error(errData);
                    return;
                }
                break;
            case 'update':
                try {
                    data.locations = data.locations.filter(el => el && el.isSelected);
                    const newLocationsClinics = props.isFromOrg
                        ? data.locations.map(loc => loc.clinic)
                        : [props.clinic];
                    const oldLocationsClinics = props.isFromOrg
                        ? category?.locations?.map(loc => loc.clinic) || []
                        : [props.clinic];

                    if (props.isFromOrg) {
                        data.locations = data.locations.map(loc => ({
                            clinic: loc.value
                        }));
                        data.locations = data.locations.map(loc => {
                            loc.active = loc.active || true;
                            loc.archived = loc.archived || false;
                            // --- means that is inserting a new loc and not updating old ones
                            if (!oldLocationsClinics.includes(loc.clinic)) {
                                loc.active = category.active;
                                loc.archived = category.archived;
                            }
                            loc.allowCommission = data.allowCommission;
                            loc.autoStockControl = data.autoStockControl;
                            loc.allowNegative = data.allowNegative;
                            if (oldLocationsClinics.includes(loc.clinic)) {
                                const existentOldLoc = category.locations.find(_loc => _loc.clinic === loc.clinic);
                                if (existentOldLoc.id) {
                                    delete existentOldLoc.id;
                                }

                                return {
                                    ...existentOldLoc,
                                    ...loc
                                };
                            } else {
                                if (loc.id) {
                                    delete loc.id;
                                }

                                return loc;
                            }
                        });
                    } else if (!props.isFromOrg && props.clinic) {
                        data.locations = category?.locations?.length ? category.locations : [{ clinic: props.clinic }];
                        data.locations = data.locations.map(loc => {
                            if (newLocationsClinics.includes(loc.clinic)) {
                                if (loc.clinic === props.clinic) {
                                    loc.allowCommission = data.allowCommission;
                                    loc.autoStockControl = data.autoStockControl;
                                    loc.allowNegative = data.allowNegative;
                                }

                                // return loc;
                                const oldLoc = category.locations.find(_loc => _loc?.clinic === loc.clinic) || [];
                                if (oldLoc.id) delete oldLoc.id;
                                if (loc.id) delete loc.id;

                                return {
                                    ...oldLoc,
                                    ...loc
                                };
                            } else {
                                return loc;
                            }
                        });
                        data.allowCommission = category.allowCommission;
                        data.autoStockControl = category.autoStockControl;
                        data.allowNegative = category.allowNegative;
                    }
                    if (!data.description) {
                        data.description = '';
                    }
                    response = await ProductCategoryApi.updateCategory(
                        category.id,
                        data,
                        props.clinic,
                        props.isFromOrg
                    );
                    toastr.success('Category successfully updated!');
                } catch (err) {
                    err.data !== undefined ? (errData = err.data.message) : (errData = 'Something went wrong');
                    toastr.error(errData);
                    return;
                }
                break;
            case 'remove':
                try {
                    response = await ProductCategoryApi.deleteCategory(category.id);
                    toastr.success('Category successfully removed!');
                } catch (err) {
                    err.data !== undefined ? (errData = err.data.message) : (errData = 'Something went wrong');
                    toastr.error(errData);
                    return;
                }
                break;
            default:
                try {
                    const value = props.isFromOrg
                        ? !category.archived
                        : !getLocationItem(category, props.clinic).archived;
                    response = await ProductCategoryApi.archiveCategory(
                        category.id,
                        value,
                        props.clinic,
                        props.isFromOrg
                    );
                    toastr.success(`Category ${response.name} successfully archived!`);
                } catch (err) {
                    err.data !== undefined ? (errData = err.data.message) : (errData = 'Something went wrong');
                    toastr.error(errData);
                    return;
                }
                break;
        }
        props.onConfirm();
    };

    if (!props.categoryId) {
        return null;
    }

    const Update = () => {
        return (
            <CategoryFormModal
                Category={category}
                visible={true}
                onClose={props.onCancel}
                onConfirm={data => confirm('update', data)}
                clinics={props.clinics}
                isFromOrg={props.isFromOrg}
                clinic={props.clinic}
                allClinics={props.allClinics}
            />
        );
    };

    const Create = () => {
        return (
            <CategoryFormModal
                visible={true}
                onClose={props.onCancel}
                onConfirm={data => confirm('create', data)}
                clinics={props.clinics}
                isFromOrg={props.isFromOrg}
                clinic={props.clinic}
                allClinics={props.allClinics}
            />
        );
    };

    const Archive = () => {
        const isArchived = props.isFromOrg ? category?.archived : getLocationItem(category, props.clinic)?.archived;
        const modalContent = isArchived
            ? 'Are you sure you want to unarchive this category?'
            : 'Archiving this product category will also archive all products within it. If you still need these products, please assign them to different categories before proceeding.';

        return (
            <>
                {!_.isEmpty(category) ? (
                    <ConfirmModal
                        isOpen
                        setIsOpen={props.onCancel}
                        onConfirm={() => confirm('archive')}
                        continueText={isArchived ? 'Unarchive' : 'Archive'}
                        title="Warning"
                        cancelText="Back"
                        content={modalContent}
                    />
                ) : (
                    ''
                )}
            </>
        );
    };

    const Remove = () => {
        return (
            <Modal
                id={`${methodType}-category`}
                isOpen={true}
                title={methodType}
                size="xs"
                draggable
                onConfirm={() => confirm('remove')}
                onClose={props.onCancel}
            >
                <Box container spacing={4}>
                    <Typography item>Are you sure you want to delete this category?</Typography>
                </Box>
            </Modal>
        );
    };

    const MethodModal = () => {
        switch (methodType) {
            case 'Archive':
                return <Archive />;
            case 'Remove':
                return <Remove />;
            case 'Create':
                return <Create />;
            default:
                return <Update />;
        }
    };

    return <MethodModal />;
};

ProductCategoryModal.propTypes = {
    categoryId: PropTypes.string,
    category: PropTypes.object,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(productCategoryModalStyles)(ProductCategoryModal));
