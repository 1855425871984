import api from '../collums-components/api/config';

class CoursesApi {
    static listCourses(qs) {
        return api.request({
            method: 'GET',
            url: `/course${qs}`
        });
    }

    static getCoursesByCategory() {
        return api.request({
            method: 'GET',
            url: '/course/groupedByCategory'
        });
    }

    static getCoursesWithouAppt(id) {
        return api.request({
            method: 'GET',
            url: `/course/withoutAppt/${id}`
        });
    }

    static archiveCourse(id, value, isFromOrg = false, clinic) {
        return api.request({
            method: 'PUT',
            url: `/course/archive/${id}?value=${value}&isFromOrg=${isFromOrg}&clinic=${clinic}`
        });
    }

    static activeCourse(id) {
        return api.request({
            method: 'PUT',
            url: `/course/active/${id}`
        });
    }

    static createCourse(body) {
        return api.request({
            method: 'POST',
            url: '/course',
            data: body
        });
    }

    static updateCourse(id, body, isFromOrg = false, clinic) {
        return api.request({
            method: 'PUT',
            url: `/course/${id}?isFromOrg=${isFromOrg}&clinic=${clinic}`,
            data: body
        });
    }

    static deleteCourse(id) {
        return api.request({
            method: 'DELETE',
            url: `/course/${id}`
        });
    }

    static changeCourseActive(id, value, isFromOrg = false, clinic) {
        return api.request({
            method: 'PUT',
            url: `/course/${id}/change-active?value=${value}&isFromOrg=${isFromOrg}&clinic=${clinic}`
        });
    }

    static changeCourseShowOnline(id) {
        return api.request({
            method: 'PUT',
            url: `/course/${id}/change-show-online`
        });
    }
}

export default CoursesApi;
