import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import _ from 'lodash';

import SelectDropdown from './SelectDropdown';
import SingleAutocomplete from './SingleAutocomplete';
import UnitAndValue from './UnitAndValue';
import DurationAutocomplete from './DurationAutocomplete';
import { rowEditorStyles } from './styles';
import TextInput from './TextInput';

class RowEditor extends React.Component {
    constructor(props) {
        super(props);
        this.values = [];

        this.renderRow = this.renderRow.bind(this);
    }

    static idFactory(field, schemaField) {
        return `${field.id}-${schemaField.id}`;
    }

    static fieldFactory(valueRow, schemaField) {
        return { ...schemaField, value: valueRow[schemaField.id] };
    }

    static onChangeFactory(field, schemaField, index, onChange) {
        return (id, value) => {
            const shallowValueCopy = [...field.value];
            shallowValueCopy[index] = {
                ...shallowValueCopy[index],
                [schemaField.id]: value
            };
            onChange(field.id, shallowValueCopy);
        };
    }

    renderDescription() {
        const { field, classes } = this.props;
        if (!field.errorMessages && !field.description) return null;
        return (
            <Typography className={field.isValid ? classes.description : classes.descriptionError}>
                {field.errorMessages || field.description}
            </Typography>
        );
    }

    renderSubHeader() {
        const { field, classes } = this.props;
        return _.times(field.rows || 1, subRowIndex => (
            <div key={subRowIndex} className={classes.subRow}>
                {field.schema
                    .filter(schemaField => null == schemaField.row || subRowIndex === schemaField.row)
                    .map(schemaField => (
                        <div key={schemaField.id} className={classes.cellHeader}>
                            <Typography> {schemaField.columnLabel}</Typography>
                        </div>
                    ))}
                <div className={classes.cellRemove} />
            </div>
        ));
    }

    renderHeader() {
        const { field, classes } = this.props;
        if (!field.value || !field.value.length) return null;
        return <div className={classes.subRowWrapper}>{this.renderSubHeader()}</div>;
    }

    renderField(schemaField, valueRow, index) {
        const { field, onChange, classes } = this.props;
        switch (schemaField.type) {
            case 'text':
                return (
                    <div key={schemaField.id} className={classes.cellInput}>
                        <TextInput
                            id={RowEditor.idFactory(field, schemaField)}
                            field={RowEditor.fieldFactory(valueRow, schemaField)}
                            onChange={RowEditor.onChangeFactory(field, schemaField, index, onChange)}
                        />
                    </div>
                );
            case 'select':
                return (
                    <div key={schemaField.id} className={classes.cellInput}>
                        <SelectDropdown
                            id={RowEditor.idFactory(field, schemaField)}
                            field={RowEditor.fieldFactory(valueRow, schemaField)}
                            onChange={RowEditor.onChangeFactory(field, schemaField, index, onChange)}
                        />
                    </div>
                );
            case 'autocomplete':
                return (
                    <div key={schemaField.id} className={classes.cellInput}>
                        <SingleAutocomplete
                            id={RowEditor.idFactory(field, schemaField)}
                            field={RowEditor.fieldFactory(valueRow, schemaField)}
                            onChange={RowEditor.onChangeFactory(field, schemaField, index, onChange)}
                        />
                    </div>
                );
            case 'unitAndValue':
                return (
                    <div key={schemaField.id} className={classes.cellInput}>
                        <UnitAndValue
                            id={RowEditor.idFactory(field, schemaField)}
                            field={RowEditor.fieldFactory(valueRow, schemaField)}
                            onChange={RowEditor.onChangeFactory(field, schemaField, index, onChange)}
                        />
                    </div>
                );
            case 'duration':
                return (
                    <div key={schemaField.id} className={classes.cellInput}>
                        <DurationAutocomplete
                            id={RowEditor.idFactory(field, schemaField)}
                            field={RowEditor.fieldFactory(valueRow, schemaField)}
                            onChange={RowEditor.onChangeFactory(field, schemaField, index, onChange)}
                        />
                    </div>
                );
            default:
                return (
                    <div key={schemaField.id}>
                        <Typography>
                            No renderer for nested field {schemaField.id} of type {schemaField.type}
                        </Typography>
                    </div>
                );
        }
    }

    renderSubRow(valueRow, index) {
        const { field, classes } = this.props;
        return _.times(field.rows || 1, subRowIndex => (
            <div key={subRowIndex} className={classes.subRow}>
                {field.schema
                    .filter(schemaField => null == schemaField.row || subRowIndex === schemaField.row)
                    .map(schemaField => this.renderField(schemaField, valueRow, index))}
            </div>
        ));
    }

    renderRow(valueRow, index) {
        const { field, onChange, classes } = this.props;
        return (
            <div key={index} className={classes.row}>
                <div className={classes.subRowWrapper}>{this.renderSubRow(valueRow, index)}</div>
                <div className={classes.cellRemove}>
                    <IconButton
                        size="small"
                        onClick={() => {
                            onChange(field.id, [
                                ...field.value.slice(0, index),
                                ...field.value.slice(index + 1, field.value.length)
                            ]);
                        }}
                    >
                        <Close fontSize="small" />
                    </IconButton>
                </div>
            </div>
        );
    }

    renderRows() {
        const value = this.props.field.value || [];
        return value.map(this.renderRow);
    }

    render() {
        const { field, onChange, classes } = this.props;
        return (
            <div className={classes.container}>
                <Typography className={field.isValid ? classes.label : classes.labelError}>{field.label}</Typography>
                {this.renderDescription()}
                {this.renderHeader()}
                {this.renderRows()}
                <Button
                    className={classes.addButton}
                    onClick={() => {
                        const value = field.value || [];
                        onChange(field.id, [...value, {}]);
                    }}
                >
                    Add
                </Button>
            </div>
        );
    }
}

RowEditor.propTypes = {
    field: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(rowEditorStyles)(RowEditor);
