import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';

import form from './roomsForm';
import Modal from '../../common/Modal';
import { Autocomplete } from '@material-ui/lab';
import { Controller, useForm } from 'react-hook-form';
import TagsInput from '../../../collums-components/form/TagsInput';
import { FormControl, makeStyles, TextField, Button } from '@material-ui/core';

import { form as formStyle } from './styles';
import { modalsButtonStyles } from '../../../collums-constants/styles/stylesheets/buttonsStyles';

const activeOptions = [
    {
        label: 'Yes',
        value: 10
    },
    {
        label: 'No',
        value: 100
    }
];

const RoomFormModal = ({ visible, setVisibility, handleAddItem, handleUpdateItem, defaultValue, clinic }) => {
    const { control, register, handleSubmit, errors } = useForm({});

    const globalStyles = makeStyles(modalsButtonStyles)();
    const classes = makeStyles(formStyle)();

    useEffect(() => {
        const requiredFields = Object.keys(errors);

        if (requiredFields.length !== 0) {
            const messages = requiredFields.map(field => errors[field].message);

            toastr.error('Missing fields', messages.join(','));
        }
    }, [errors]);

    const handleAddRoom = formData => {
        if (!formData.name) {
            toastr.error('Missing name field');
            return;
        }
        if (!clinic) {
            toastr.error('Missing clinic information');
            return;
        }
        if (defaultValue) {
            const data = {
                id: defaultValue.id,
                description: formData.description,
                clinic: clinic.id,
                name: formData.name,
                tags: (formData.tags || []).map(tag => tag.id),
                active: defaultValue.active
            };

            const isSucceeded = handleUpdateItem(data);
            if (!isSucceeded) return;
        } else {
            const data = {
                description: formData.description,
                clinic: clinic.id,
                name: formData.name,
                tags: (formData.tags || []).map(tag => tag.id),
                active: 10
            };

            const isSucceeded = handleAddItem(data);
            if (!isSucceeded) return;
        }
    };

    const renderInput = data => {
        if (data.name === 'tags') {
            return (
                <TagsInput
                    control={control}
                    defaultValue={defaultValue && (defaultValue[data.label.toLowerCase()] || [])}
                    name={data.name ? data.name : ''}
                    inputSize={72}
                    showInsideLabel={true}
                />
            );
        }
        if (data.type === 'select') {
            const defaultFieldValue = (() => {
                if (defaultValue) {
                    return defaultValue[data.label.toLowerCase()] === 10 ? activeOptions[0] : activeOptions[1];
                }
                return activeOptions[0];
            })();
            return (
                <Controller
                    name={data.name}
                    control={control}
                    rules={register({ required: data.required })}
                    defaultValue={defaultFieldValue}
                    onChange={([, data]) => data}
                    render={({ onChange, ...props }) => (
                        <Autocomplete
                            className={classes.inputField}
                            name={data.name}
                            {...props}
                            id={data.id}
                            options={data.options}
                            getOptionLabel={option => {
                                return option.label;
                            }}
                            defaultValue={defaultFieldValue}
                            disabled={defaultValue ? defaultValue.archived : false}
                            ref={register}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    name={data.name ? data.name : ''}
                                    className={classes.autocompleteTextfield}
                                    label={data.label}
                                    variant="outlined"
                                />
                            )}
                            onChange={(event, option) => onChange(option && option)}
                        />
                    )}
                />
            );
        }
        return (
            <Controller
                render={props => (
                    <TextField
                        {...props}
                        id={data.id}
                        className={classes.inputField}
                        variant="outlined"
                        label={data.label === 'Name' ? data.label + ' *' : data.label}
                    />
                )}
                defaultValue={defaultValue ? defaultValue[data.label.toLowerCase()] : ''}
                name={data.name}
                control={control}
                rules={register({ required: data.required })}
            />
        );
    };
    return (
        <Modal
            isOpen={visible}
            onCancel={() => setVisibility(false)}
            onClose={() => setVisibility(false)}
            title={(defaultValue || {}).id ? `View/Edit ${defaultValue.name} room` : 'New room'}
            id="room-form"
            size="xs"
            dialogClass={classes.modal}
        >
            <FormControl style={{ width: '100%' }}>
                {/* All fields */}
                {form(activeOptions).map(input => renderInput(input))}
                <div className={globalStyles.buttonsContainer}>
                    <Button className={globalStyles.cancelButton} onClick={() => setVisibility(false)}>
                        Cancel
                    </Button>
                    <Button
                        className={globalStyles.confirmButton}
                        type="submit"
                        variant="outlined"
                        onClick={() => {
                            handleSubmit(handleAddRoom)();
                        }}
                    >
                        Save
                    </Button>
                </div>
            </FormControl>
        </Modal>
    );
};

RoomFormModal.propTypes = {
    visible: PropTypes.bool,
    setVisibility: PropTypes.func,
    handleAddItem: PropTypes.func,
    handleUpdateItem: PropTypes.func,
    defaultValue: PropTypes.any
};

export default RoomFormModal;
