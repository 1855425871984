import React, { useState, useCallback, useEffect } from 'react';
import {
    FormControlLabel,
    Checkbox,
    withStyles,
    Typography,
    FormControl,
    Select,
    MenuItem,
    TextField,
    Grid,
    RadioGroup,
    Radio,
    Tooltip
} from '@material-ui/core';
import { unmountComponentAtNode } from 'react-dom';
import { settingsStyles } from '../styles';
import { TC_MERGE_TAGS, TC_MODAL_MERGE_TAGS } from '../../../collums-constants/index';

import Editor from './components/Editor';
import { Controller } from 'react-hook-form';
import InfoTooltip from '../../common/InfoTooltip';
import { late_cancellation_template } from '../../../collums-constants/utils';
import HelpIcon from '@material-ui/icons/Help';

export const DEPOSIT_TYPES = {
    NO_DEPOSIT: 'No deposit',
    DEFAULT: 'Default deposit',
    PERCENTAGE: 'Percentage',
    FIXED_AMOUNT: 'Fixed amount'
};

function CustomerAppTab({
    classes,
    register,
    organisation,
    setOrganisation,
    homeBannerRef,
    termsAndConditionsRef,
    lateCancellationRef,
    thankYouRef,
    control,
    setBookIntervals,
    bookIntervals
}) {
    const [isFirstLoading, setIsFirstLoading] = useState(true);

    useEffect(() => {
        register('homeBannerText');
        register('termsAndConditionsText');
        register('thankYouText');
    }, [register]);

    const BOOKING_INTERVALS = [5, 10, 15, 30, 45, 60];

    useEffect(() => {
        if (organisation && organisation.termsAndConditionsText && isFirstLoading) {
            // waits for the organisation to be loaded, to the editors not have empty refs
            setIsFirstLoading(false);
        }
    }, [isFirstLoading, organisation]);

    const changeHomeBannerCheckbox = useCallback(
        e => {
            const checked = e.target.checked;
            setOrganisation({ ...organisation, showHomeBannerText: checked });
            if (!checked) {
                unmountComponentAtNode(document.getElementById('home-banner-editor'));
            }
        },
        [organisation, setOrganisation]
    );

    const changeBookingDepositCheckbox = useCallback(
        e => {
            setOrganisation({
                ...organisation,
                bookingDeposit: e.target.checked
            });
        },
        [organisation, setOrganisation]
    );

    const changeBookingDepositType = useCallback(
        e => {
            setOrganisation({
                ...organisation,
                bookingDepositType: e.target.value
            });
        },
        [organisation, setOrganisation]
    );

    const BreakLines = () => <div className={classes.breakLines} />;

    return (
        <div className={classes.customerAppSettingsContainer}>
            <Grid container direction="column" spacing={3}>
                <Grid item style={{ paddingBottom: '0' }}>
                    <Typography variant="h4">Deposits</Typography>
                </Grid>
                <Grid item>
                    <div className={classes.inlineContainer}>
                        <FormControlLabel
                            label="Require online booking deposit"
                            control={
                                <Checkbox
                                    onChange={changeBookingDepositCheckbox}
                                    color="primary"
                                    checked={Boolean(organisation?.bookingDeposit)}
                                    inputRef={register}
                                    name="bookingDeposit"
                                />
                            }
                        />
                        <InfoTooltip
                            title={
                                'Setting this rule will apply to all services as default unless they have their own rules. You can change online deposit rules for individual services in Admin > Services.'
                            }
                        />
                    </div>

                    <div
                        className={
                            classes.inlineContainer + (organisation?.bookingDeposit ? '' : ' ' + classes.disabled)
                        }
                    >
                        <FormControl variant="h4">Default deposit rule</FormControl>
                    </div>

                    <div className={classes.inlineContainer}>
                        <RadioGroup
                            style={{ display: 'flex', flexDirection: 'row' }}
                            name="bookingDepositType"
                            inputRef={register}
                            onChange={changeBookingDepositType}
                        >
                            <FormControlLabel
                                key={`tod-${DEPOSIT_TYPES.PERCENTAGE}`}
                                value={DEPOSIT_TYPES.PERCENTAGE}
                                control={<Radio inputRef={register} />}
                                label={DEPOSIT_TYPES.PERCENTAGE}
                                checked={organisation?.bookingDepositType === DEPOSIT_TYPES.PERCENTAGE}
                                disabled={!organisation?.bookingDeposit}
                            />
                            <FormControlLabel
                                key={`tod-${DEPOSIT_TYPES.FIXED_AMOUNT}`}
                                value={DEPOSIT_TYPES.FIXED_AMOUNT}
                                control={<Radio inputRef={register} />}
                                label={DEPOSIT_TYPES.FIXED_AMOUNT}
                                checked={organisation?.bookingDepositType === DEPOSIT_TYPES.FIXED_AMOUNT}
                                disabled={!organisation?.bookingDeposit}
                            />
                        </RadioGroup>
                    </div>

                    <div className={classes.inlineContainer}>
                        <FormControl variant="h4" style={{ marginBottom: '5px', marginRight: '16px' }}>
                            Deposit percentage/amount
                        </FormControl>
                        <InfoTooltip
                            title={'Enter 100% to ensure services are paid for in full at the time of booking online.'}
                        />
                    </div>
                    <div className={classes.inlineContainer}>
                        <FormControl className={classes.fieldContainer}>
                            <TextField
                                id="bookingDepositAmount-input"
                                name="bookingDepositAmount"
                                variant="outlined"
                                value={organisation?.bookingDepositAmount}
                                onChange={({ target }) => {
                                    setOrganisation({
                                        ...organisation,
                                        bookingDepositAmount: target.value,
                                        bookingDeposit: true
                                    });
                                }}
                                type="number"
                                InputProps={{
                                    inputProps: { min: 1 }
                                }}
                                inputRef={register}
                                style={{ width: '240px' }}
                                disabled={!organisation?.bookingDeposit}
                            />
                        </FormControl>
                    </div>
                </Grid>
            </Grid>

            <Grid item style={{ margin: '7px 0px 8px' }}>
                <Typography variant="h4">Online booking rules</Typography>
            </Grid>

            <FormControl variant="outlined" className={`${classes.intervalContainer}`}>
                <div className={`${classes.inlineContainer} ${classes.intervalLabel}`}>
                    <Typography>Offer online booking intervals every</Typography>
                    <Controller
                        name="bookingIntervals"
                        className={classes.intervalSelect}
                        defaultValue={bookIntervals}
                        control={control}
                        as={
                            <Select onChange={() => setBookIntervals}>
                                {BOOKING_INTERVALS.map(el => {
                                    return (
                                        <MenuItem key={el} value={el}>
                                            {`${el} mins`}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        }
                    />
                </div>
            </FormControl>

            <FormControl className={classes.fieldContainer} variant="outlined">
                <div className={`${classes.inlineContainer} ${classes.intervalLabel}`}>
                    <Typography>Do not allow online bookings</Typography>
                    <Controller
                        name="minTimeBefore"
                        className={classes.minTimeBefore}
                        defaultValue={organisation.minTimeBefore}
                        control={control}
                        as={
                            <TextField
                                id="minTimeBefore-number"
                                type="number"
                                variant="outlined"
                                InputProps={{
                                    inputProps: { min: 0 }
                                }}
                                onKeyDown={e => {
                                    if (e.keyCode === 109 || e.keyCode === 107) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        }
                    />
                    <Typography style={{ marginRight: '16px' }}>min before appointment.</Typography>
                    <InfoTooltip
                        title={
                            'Use this feature to prevent last minute online bookings that you may not be prepared for. Enter the minimum number of minutes that a client can book before their appointment time.'
                        }
                    />
                </div>
            </FormControl>

            <div className={classes.row}>
                <Typography variant="h4" className={classes.marginTop}>
                    Customise online text
                </Typography>
                <FormControlLabel
                    label="Show homepage banner text"
                    control={
                        <Checkbox
                            onChange={changeHomeBannerCheckbox}
                            color="primary"
                            checked={
                                organisation && organisation.showHomeBannerText
                                    ? organisation.showHomeBannerText
                                    : false
                            }
                            inputRef={register}
                            name="showHomeBannerText"
                        />
                    }
                />
                {organisation && organisation.showHomeBannerText && (
                    <div id="home-banner-editor">
                        <Editor
                            textRef={homeBannerRef}
                            field="homeBannerText"
                            placeholder="Input the text you want to be seen here"
                            organisation={organisation}
                        />
                    </div>
                )}
            </div>
            <BreakLines />
            <Typography variant="h4">T&C text before completing online booking</Typography>
            {!isFirstLoading && (
                <div className={classes.row}>
                    <div>
                        <Editor
                            textRef={termsAndConditionsRef}
                            field="termsAndConditionsText"
                            organisation={organisation}
                            mergeTags={TC_MERGE_TAGS}
                        />
                    </div>
                </div>
            )}
            <BreakLines />
            <Typography variant="h4">Thank you text after completing online booking</Typography>
            {!isFirstLoading && (
                <div className={classes.row}>
                    <div>
                        <Editor textRef={thankYouRef} field="thankYouText" organisation={organisation} />
                    </div>
                </div>
            )}
            <BreakLines />
            <Typography variant="h4" className={classes.sectionTitleWrapper}>
                Late cancellation / reschedule warning text
                <Tooltip
                    title={
                        <Typography style={{ fontSize: 14 }}>
                            This text will be shown when trying to cancel/reschedule a booking within your specified
                            late cancellation period. Set the late cancellation period in the Calendar section above.
                        </Typography>
                    }
                    aria-label="help"
                    style={{ marginLeft: '4px' }}
                >
                    <HelpIcon />
                </Tooltip>
            </Typography>
            {!isFirstLoading && (
                <div className={classes.row}>
                    <div>
                        <Editor
                            textRef={lateCancellationRef}
                            field="lateCancellationText"
                            defaultValue={late_cancellation_template}
                            organisation={organisation}
                            mergeTags={TC_MODAL_MERGE_TAGS}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default withStyles(settingsStyles)(CustomerAppTab);
