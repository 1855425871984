export default [
    { id: 'id', label: 'ID' },
    { id: 'title', label: 'Title' },
    { id: 'firstName', label: 'First Name' },
    { id: 'aka', label: 'AKA' },
    { id: 'middleName', label: 'Middle Name' },
    { id: 'surname', label: 'Surname' },
    { id: 'prevSurname', label: 'Prev surname' },
    { id: 'dateOfBirth', label: 'DOB', type: 'date' },
    { id: 'gender', label: 'Gender' },
    { id: 'email', label: 'Email' },
    { id: 'niNumber', label: 'NI number' },
    { id: 'nhsNumber', label: 'NHS number' },
    { id: 'insurance', label: 'Insurance' },
    { id: 'mobilePhone', label: 'Mobile' },
    { id: 'otherPhone', label: 'Other phone', type: 'array' },
    { id: 'address1', label: 'Address 1' },
    { id: 'address2', label: 'Address 2' },
    { id: 'city', label: 'City' },
    { id: 'county', label: 'County' },
    { id: 'state', label: 'State' },
    { id: 'country', label: 'Country', type: 'object', objectLabel: 'label' },
    { id: 'postCode', label: 'Postcode' },
    { id: 'generalPractitioner', label: 'General Practitioner' },
    { id: 'onlineUsername', label: 'Online username' },
    { id: 'banned', label: 'Banned' },
    { id: 'bannedOnline', label: 'Banned online' },
    { id: 'marketingTypes', label: 'Marketing Types', type: 'array' },
    { id: 'notificationTypes', label: 'Notification Types', type: 'array' },
    { id: 'newsletter', label: 'Newsletter' },
    { id: 'referredBy', label: 'Referred by', type: 'object', objectLabel: 'firstName' },
    { id: 'referralSource', label: 'referralSource' },
    { id: 'clinicalAlert', label: 'Clinical alert' }
];
