import React, { useEffect, useState, useCallback, useRef } from 'react';

import { InputLabel, makeStyles, TextField } from '@material-ui/core';

import { serviceDetailsModalStyles } from '../../services/styles';
import { tabsStyles } from '../styles';

import { Autocomplete } from '@material-ui/lab';
import CommissionsScheme from '../../common/CommissionsScheme';
import getCommissionsData from '../../../services/getCommissionsData';
import theme from '../../../theme';

export default function CommissionTab({
    register,
    commissions,
    setValue,
    practitioner,
    serviceCategories,
    isFromOrg,
    allClinics
}) {
    const [selectedCommission, setSelectedCommission] = useState(false);
    const formRef = useRef({});

    const getPractitionerCommission = useCallback(() => {
        if (practitioner.commission) {
            const newCommission = getCommissionsData(practitioner.commission, { service: serviceCategories });

            formRef.current = newCommission;
            setSelectedCommission(newCommission);
        }
        // eslint-disable-next-line
    }, [practitioner]);

    useEffect(() => {
        setValue('commission', selectedCommission?.id);
        // eslint-disable-next-line
    }, [selectedCommission]);

    useEffect(() => {
        register({ name: 'commission' });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getPractitionerCommission();
    }, [practitioner]); //eslint-disable-line

    const classes = makeStyles(() => ({ ...serviceDetailsModalStyles(theme), ...tabsStyles() }))();

    return (
        <div className={classes.formContent}>
            <p className={classes.noteText}>
                Note: Commission set here will be calculated on the value of all sales or services performed. This is in
                addition to any individual service commissions set in the Service Rules section above.
            </p>

            <Autocomplete
                value={selectedCommission}
                disabled={!isFromOrg && allClinics.length > 1}
                autoHighlight
                getOptionLabel={option => option.name}
                options={commissions}
                className={classes.commissionSelector}
                onChange={(_, value) => {
                    formRef.current = (() => {
                        if (value) {
                            return getCommissionsData(value, { services: serviceCategories });
                        }
                    })();
                    setSelectedCommission(value ?? undefined);
                }}
                renderInput={params => (
                    <>
                        <InputLabel shrink>Select the commission schema</InputLabel>
                        <TextField
                            tabIndex={3}
                            {...params}
                            margin="dense"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                classes: {
                                    input: classes.autocompleteInputProps
                                }
                            }}
                            className={classes.autocompleteInputProps}
                        />
                    </>
                )}
            />

            {selectedCommission && (
                <>
                    <div className={`${classes.formContentRow} ${classes.accordionTitle}`}>
                        <p>Commission details</p>
                    </div>

                    <CommissionsScheme
                        disabled
                        commissionFormRef={formRef}
                        selectedCommission={selectedCommission}
                        serviceCategories={serviceCategories}
                    />
                </>
            )}
        </div>
    );
}
