import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { stripeView } from './styles';
import OrganisationsApi from '../../api/OrganisationsApi';
import StripeApi from '../../api/StripeApi';
import { toastr } from 'react-redux-toastr';
import { useLocation } from 'react-router-dom';
import CancelContinueModal from '../../collums-components/components/common/CancelContinueModal';
import StripeTerminalList from './StripeTerminalList';

const URL_TYPE_RETURN = 'return';
const URL_TYPE_REFRESH = 'refresh';

export default function StripeView() {
    const classes = makeStyles(stripeView())();
    const location = useLocation();
    const [organisation, setOrganisation] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isAccountEnabled, setIsAccountEnabled] = useState(null);
    const [dashboardLink, setDashboardLink] = useState();
    const [displayContent, setDisplayContent] = useState(false);
    const [showRevokeModal, setShowRevokeModal] = useState(false);

    useEffect(() => {
        (async () => {
            const organisationData = await OrganisationsApi.listOrganisations();
            setOrganisation(organisationData);
            if (organisationData?.stripeAccountId) {
                const data = await StripeApi.getAccount();
                if (Object.prototype.hasOwnProperty.call(data, 'isEnabled')) {
                    setIsAccountEnabled(data.isEnabled);
                    setDisplayContent(true);
                    if (data.isEnabled === true) {
                        const dashboardLink = await StripeApi.getDashboardLink();
                        if (dashboardLink?.url) {
                            setDashboardLink(dashboardLink.url);
                        }
                    }
                }
            } else {
                setIsAccountEnabled(null);
                setDisplayContent(true);
            }
        })();
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const type = params.get('type');

        if (type === URL_TYPE_REFRESH) {
            toastr.error('Problem occurred during connecting to Stripe, please try again');
            return false;
        } else if (type === URL_TYPE_RETURN) {
            toastr.success('Onboarding process has finished successfully');
            return false;
        }
    }, [location.search]);

    const redirectToOnboarding = async () => {
        setIsLoading(true);

        const { url } = await StripeApi.getOnboardingLink();
        if (!url) {
            toastr.error('Problem occurred during connecting to Stripe');
            return false;
        }
        window.location.href = url;
    };

    const openDashboardSite = () => {
        dashboardLink && window.open(dashboardLink, '_blank');
    };

    const revoke = async () => {
        const data = await StripeApi.revokeAccess();
        if (data) {
            setIsAccountEnabled(null);
            setDashboardLink(undefined);
            toastr.success('Stripe account has been revoked');
        }
        return false;
    };

    const OnboardingButton = () => {
        return isAccountEnabled === true ? (
            <Button
                color="warn"
                variant="outlined"
                className={classes.revokeBtn}
                onClick={() => setShowRevokeModal(true)}
            >
                Revoke
            </Button>
        ) : (
            <Button disabled={isLoading} color="primary" variant="contained" onClick={() => redirectToOnboarding()}>
                {isAccountEnabled === false ? 'Continue' : 'Connect'}
            </Button>
        );
    };

    if (!organisation) {
        return null;
    }

    if (organisation && !organisation.stripeCheckoutActive) {
        return (
            <div className={classes.container}>
                <Box display="flex" flexDirection="column">
                    <div className={classes.fullWidthRoboto}>
                        <p>Stripe payment functionality is disabled for this application.</p>
                    </div>
                </Box>
            </div>
        );
    }

    return (
        <div className={classes.container}>
            <Box display="flex" flexDirection="column">
                {organisation && displayContent && (
                    <>
                        <div>
                            {isAccountEnabled ? (
                                <>
                                    <p>Your Collums and Stripe accounts are connected.</p>
                                    {dashboardLink && (
                                        <div className={classes.dashboardButton}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={() => openDashboardSite()}
                                            >
                                                Go to Stripe Dashboard
                                            </Button>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <p>
                                    Click connect and follow the instructions to connect to your Stripe account and
                                    allow Collums to save card details and take payments.
                                </p>
                            )}
                        </div>
                        {isAccountEnabled === false && (
                            <div>
                                <Typography
                                    style={{
                                        color: 'red'
                                    }}
                                >
                                    Your Stripe account setup is not finished. Please continue the setup.
                                </Typography>
                            </div>
                        )}
                        <div className={classes.connectButton}>
                            {isAccountEnabled && (
                                <p>
                                    Click revoke to disconnect. If you do this, you will not be able to use the saved
                                    card functionality or take payments.
                                </p>
                            )}

                            <OnboardingButton />
                            <span className={classes.progress}>
                                {isLoading && <CircularProgress color="secondary" size={24} />}
                            </span>
                        </div>
                        <CancelContinueModal
                            open={showRevokeModal}
                            setOpen={setShowRevokeModal}
                            title="Warning"
                            contentText="Are you sure to disconnect your Stripe account?"
                            onContinue={() => revoke()}
                        />
                    </>
                )}
                {!!isAccountEnabled && <StripeTerminalList />}
            </Box>
        </div>
    );
}
