import React, { useState, useEffect } from 'react';
import CheckboxTree from './CheckboxTree';
import productApi from '../../../api/ProductApi';
import LoadingScreen from '../../../collums-components/components/common/loadingScreen';

function FormProducts({ selectedProducts, setSelectedProducts, isFromOrg }) {
    const [brands, setBrands] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getProductsBrands = async () => {
        setIsLoading(true);
        try {
            const response = await productApi.getProductsByBrand();
            const formattedResponse = response.map(value => {
                return {
                    name: value.brand?.name || 'Without brand',
                    id: value.brand?.id || 'noBrand',
                    child: [...value.products]
                };
            });
            setBrands(formattedResponse);
        } catch (e) {
            console.error(e);
        }
        setIsLoading(false);
    };

    const brandClick = (e, brand) => {
        const productsId = brand.child.map(product => product.id);
        const newSelectedProducts = (() => {
            if (!e.target.checked) {
                return selectedProducts.filter(product => !productsId.includes(product));
            }
            return [...selectedProducts, ...productsId];
        })();
        setSelectedProducts(newSelectedProducts);
    };

    const toggleChild = (e, productId) => {
        const newSelectedProducts = (() => {
            if (e.target.checked) return [...selectedProducts, productId];
            return selectedProducts.filter(service => service !== productId);
        })();
        setSelectedProducts(newSelectedProducts);
    };

    useEffect(() => {
        getProductsBrands();
    }, []);

    return (
        <>
            {isLoading && <LoadingScreen />}
            <CheckboxTree
                notFromOrg={!isFromOrg}
                parentTree={brands}
                selectedItems={selectedProducts}
                parentClick={brandClick}
                childClick={toggleChild}
            />
        </>
    );
}

export default FormProducts;
