import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import DateFnsUtils from '@date-io/date-fns';
import Moment from 'moment';

import {
    withStyles,
    makeStyles,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Typography,
    FormControl,
    TextField,
    IconButton,
    Checkbox,
    Button
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { MuiPickersUtilsProvider, TimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import { Delete } from '@material-ui/icons';
import { useForm, useFieldArray } from 'react-hook-form';

import { updateClinic } from '../../collums-components/api/ClinicApi';

import OpenedModal from '../common/OpenedModal';
import { modalsButtonStyles, buttonsStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import { hoursViewStyles } from './styles';
import { getCurrentTimezonedDate } from '../../collums-components/helpers/timezone';

const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

function HoursView({ clinic, classes, reloadClinic }) {
    const [updatedClinic, setUpdatedClinic] = useState({});

    const updateValue = newValue => setUpdatedClinic({ ...updatedClinic, ...newValue });
    const [expanded, setExpanded] = useState('');

    const globalStyles = makeStyles(modalsButtonStyles)();
    const buttonStyles = makeStyles(buttonsStyles)();

    const { handleSubmit, register, control } = useForm();

    const { fields, append } = useFieldArray({
        control,
        name: 'holidays'
    });

    const defaultStartTime = getCurrentTimezonedDate();
    defaultStartTime.setHours('09');
    defaultStartTime.setMinutes('00');

    const defaultEndTime = getCurrentTimezonedDate();
    defaultEndTime.setHours('16');
    defaultEndTime.setMinutes('00');

    const onSubmit = async () => {
        const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = updatedClinic;
        const formatDate = day => {
            // if it is an invalid date
            if (isNaN(day.start.getTime())) {
                return {
                    ...day,
                    start: defaultStartTime,
                    end: defaultEndTime
                };
            }

            if (Moment(day.end).isBefore(Moment(day.start)))
                throw Error('The end time should be later than the start.');

            // Returning HH:mm to database
            return {
                ...day,
                start: new Date(day.start).toTimeString().split(' ')[0],
                end: new Date(day.end).toTimeString().split(' ')[0]
            };
        };

        const formatHoliday = holiday => {
            if (!holiday.date) throw Error('Holiday should have a date');

            if (!holiday.isClosed) {
                if (!holiday.start || !holiday.end) throw Error('Start and end times need to be provided.');

                if (Moment(holiday.end).isBefore(Moment(holiday.start)))
                    throw Error('Holiday end time should be later than the start.');

                return {
                    ...holiday,
                    start: `${`0${new Date(holiday.start).getHours()}`.slice(-2)}:${`0${new Date(
                        holiday.start
                    ).getMinutes()}`.slice(-2)}`,
                    end: `${`0${new Date(holiday.end).getHours()}`.slice(-2)}:${`0${new Date(
                        holiday.end
                    ).getMinutes()}`.slice(-2)}`
                };
            } else {
                return holiday;
            }
        };

        let data;

        try {
            data = {
                time: [
                    formatDate(monday),
                    formatDate(tuesday),
                    formatDate(wednesday),
                    formatDate(thursday),
                    formatDate(friday),
                    formatDate(saturday),
                    formatDate(sunday)
                ],
                holidays: fields.map(holiday => formatHoliday(holiday))
            };
        } catch (error) {
            toastr.error(error.message);
            return;
        }

        try {
            await updateClinic(updatedClinic.id, data);
            toastr.success(`Clinic ${updatedClinic.accountName} hours successfully updated`);
        } catch (err) {
            toastr.error(err?.data?.message || 'An error has occurred');
        }
    };

    const defaultTimes = data => {
        const date = getCurrentTimezonedDate();
        // Fill missing schedules with the default schedule time (9am - 4pm)
        const schedules = new Array(7).fill(0).map((_, i) => ({
            start:
                data && data[i] && data[i].start
                    ? new Date(date.setHours(...data[i].start.split(':')))
                    : defaultStartTime,
            end: data && data[i] && data[i].end ? new Date(date.setHours(...data[i].end.split(':'))) : defaultEndTime,
            isClosed: data && data[i] ? !!data[i].isClosed : true
        }));

        return {
            monday: schedules[0],
            tuesday: schedules[1],
            wednesday: schedules[2],
            thursday: schedules[3],
            friday: schedules[4],
            saturday: schedules[5],
            sunday: schedules[6]
        };
    };

    const getClinicTime = ({ start, end }) => {
        const date = getCurrentTimezonedDate();
        return {
            start: start ? new Date(date.setHours(...start.split(':'))) : defaultStartTime,
            end: end ? new Date(date.setHours(...end.split(':'))) : defaultEndTime
        };
    };

    const mapHolidays = data => {
        return data?.map(holiday => {
            if (holiday.isClosed) {
                return {
                    ...holiday,
                    date: getCurrentTimezonedDate(holiday.date)
                };
            }
            const start = getCurrentTimezonedDate();
            start.setHours(holiday.start.split(':')[0]);
            start.setMinutes(holiday.start.split(':')[1]);

            const end = getCurrentTimezonedDate();
            end.setHours(holiday.end.split(':')[0]);
            end.setMinutes(holiday.end.split(':')[1]);

            return {
                ...holiday,
                date: getCurrentTimezonedDate(holiday.date),
                start,
                end
            };
        });
    };

    const defaultHoliday = () => {
        const defaultHoliday = {
            date: null,
            start: null,
            end: null,
            isClosed: false
        };

        return defaultHoliday;
    };

    const handleCancel = async () => {
        setExpanded('');
        await reloadClinic();
    };

    useEffect(() => {
        async function start() {
            await reloadClinic();
        }
        start();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location]);

    useEffect(() => {
        async function setHours() {
            const data = clinic;

            data.monday = getClinicTime(data.time[0]);

            fields.splice(0, fields.length);

            append(mapHolidays(data.holidays));

            const _updatedClinic = {
                ...data,
                ...defaultTimes(data.time),
                newHoliday: defaultHoliday(),
                shouldUpdate: false
            };
            setUpdatedClinic(_updatedClinic);
        }

        if (clinic.id) setHours();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clinic]);

    return (
        <OpenedModal>
            <form
                className={classes.form}
                onSubmit={e => {
                    e.preventDefault();
                    handleSubmit(onSubmit)();
                }}
            >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Accordion
                        expanded={expanded === 'hours'}
                        onChange={() => (expanded === 'hours' ? setExpanded('') : setExpanded('hours'))}
                    >
                        <AccordionSummary>
                            <Typography>Clinic hours</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container direction="row" spacing={4}>
                                {WEEKDAYS.map(weekday => {
                                    return (
                                        <Grid item container direction="row" spacing={4} lg={12} key={weekday}>
                                            <Typography
                                                style={{ width: '12.5%', marginLeft: '2%' }}
                                                className={classes.centerFlexItem}
                                            >
                                                {weekday}
                                            </Typography>
                                            <Grid item>
                                                <FormControl
                                                    style={{
                                                        opacity: [weekday.toLowerCase()].isClosed ? '0.5' : '1'
                                                    }}
                                                >
                                                    <TimePicker
                                                        disabled={
                                                            updatedClinic[weekday.toLowerCase()]
                                                                ? updatedClinic[weekday.toLowerCase()].isClosed
                                                                : false
                                                        }
                                                        id={`${weekday.toLowerCase()}-start-time`}
                                                        label="Start time"
                                                        name={`${weekday.toLowerCase()}.start`}
                                                        value={
                                                            updatedClinic[weekday.toLowerCase()]?.start
                                                                ? updatedClinic[weekday.toLowerCase()].start
                                                                : null
                                                        }
                                                        onChange={date =>
                                                            updateValue({
                                                                [weekday.toLowerCase()]: {
                                                                    ...updatedClinic[weekday.toLowerCase()],
                                                                    start: date
                                                                }
                                                            })
                                                        }
                                                        inputRef={register()}
                                                        type="time"
                                                        ampm={false}
                                                        TextFieldComponent={props => (
                                                            <TextField {...props} variant="outlined" />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item>
                                                <FormControl>
                                                    <TimePicker
                                                        id={`${weekday.toLowerCase()}-end-time`}
                                                        label="End time"
                                                        disabled={
                                                            updatedClinic[weekday.toLowerCase()]
                                                                ? updatedClinic[weekday.toLowerCase()].isClosed
                                                                : false
                                                        }
                                                        name={`${weekday.toLowerCase()}.end`}
                                                        value={
                                                            updatedClinic[weekday.toLowerCase()]?.end
                                                                ? updatedClinic[weekday.toLowerCase()].end
                                                                : null
                                                        }
                                                        onChange={date =>
                                                            updateValue({
                                                                [weekday.toLowerCase()]: {
                                                                    ...updatedClinic[weekday.toLowerCase()],
                                                                    end: date
                                                                }
                                                            })
                                                        }
                                                        inputRef={register()}
                                                        type="time"
                                                        ampm={false}
                                                        TextFieldComponent={props => (
                                                            <TextField {...props} variant="outlined" />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item direction="row" className={classes.centerFlexItem}>
                                                <FormControl>
                                                    {updatedClinic[weekday.toLowerCase()] && (
                                                        <Checkbox
                                                            id={`${weekday.toLowerCase()}-isClosed`}
                                                            name={`${weekday.toLowerCase()}.isClosed`}
                                                            checked={updatedClinic[weekday.toLowerCase()].isClosed}
                                                            onChange={(_, isClosed) =>
                                                                updateValue({
                                                                    [weekday.toLowerCase()]: {
                                                                        ...updatedClinic[weekday.toLowerCase()],
                                                                        isClosed: isClosed
                                                                    }
                                                                })
                                                            }
                                                            inputRef={register()}
                                                            type="checkbox"
                                                        />
                                                    )}
                                                </FormControl>
                                                <Typography>Closed</Typography>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        expanded={expanded === 'holidays'}
                        onChange={() => (expanded === 'holidays' ? setExpanded('') : setExpanded('holidays'))}
                    >
                        <AccordionSummary>
                            <Typography>Holidays</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container direction="row" spacing={4}>
                                {fields.map((holiday, index) => {
                                    return (
                                        holiday && (
                                            <Grid item container direction="row" spacing={4} lg={12} key={holiday.id}>
                                                <Grid item>
                                                    <FormControl>
                                                        <KeyboardDatePicker
                                                            autoOk={true}
                                                            disabled={holiday.isClosed}
                                                            className={classes.formItem}
                                                            name={`holidays[${index}].date`}
                                                            id={`holidays[${index}].date`}
                                                            value={holiday.date}
                                                            disableToolbar
                                                            variant="inline"
                                                            format="dd/MM/yyyy"
                                                            margin="normal"
                                                            label="Date"
                                                            onChange={date => {
                                                                holiday.date = date;
                                                                updateValue({
                                                                    shouldUpdate: !updatedClinic.shouldUpdate
                                                                });
                                                            }}
                                                            TextFieldComponent={props => (
                                                                <TextField {...props} variant="outlined" />
                                                            )}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item className={classes.centerFlexItem}>
                                                    <FormControl>
                                                        <TimePicker
                                                            className={holiday.start ? '' : classes.timeInput}
                                                            disabled={holiday.isClosed}
                                                            key={`holidays[${index}].start-time`}
                                                            id={`holidays[${index}].start-time`}
                                                            label="Start time"
                                                            name={`holidays[${index}].start`}
                                                            value={holiday.start}
                                                            inputRef={register()}
                                                            type={!holiday.start ? '' : 'time'}
                                                            ampm={false}
                                                            onChange={date => {
                                                                holiday.start = date;
                                                                updateValue({
                                                                    shouldUpdate: !updatedClinic.shouldUpdate
                                                                });
                                                            }}
                                                            TextFieldComponent={props => (
                                                                <TextField {...props} variant="outlined" />
                                                            )}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item className={classes.centerFlexItem}>
                                                    <FormControl>
                                                        <TimePicker
                                                            className={holiday.end ? '' : classes.timeInput}
                                                            disabled={holiday.isClosed}
                                                            key={`holidays[${index}].end-time`}
                                                            id={`holidays[${index}].end-time`}
                                                            label="End time"
                                                            name={`holidays[${index}].end`}
                                                            value={holiday.end}
                                                            inputRef={register()}
                                                            type={!holiday.end ? '' : 'time'}
                                                            ampm={false}
                                                            onChange={date => {
                                                                holiday.end = date;
                                                                updateValue({
                                                                    shouldUpdate: !updatedClinic.shouldUpdate
                                                                });
                                                            }}
                                                            TextFieldComponent={props => (
                                                                <TextField {...props} variant="outlined" />
                                                            )}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item direction="row" className={classes.centerFlexItem}>
                                                    <FormControl>
                                                        {holiday && (
                                                            <Checkbox
                                                                id={`holidays[${index}].isClosed`}
                                                                name={`holidays[${index}].isClosed`}
                                                                checked={holiday.isClosed}
                                                                inputRef={register()}
                                                                onChange={(_, isClosed) => {
                                                                    holiday.isClosed = isClosed;
                                                                    updateValue({
                                                                        shouldUpdate: !updatedClinic.shouldUpdate
                                                                    });
                                                                }}
                                                                type="checkbox"
                                                            />
                                                        )}
                                                    </FormControl>
                                                    <Typography>Closed</Typography>
                                                </Grid>
                                                <Grid item direction="row" className={classes.centerFlexItem}>
                                                    <IconButton
                                                        onClick={() => {
                                                            updateValue({
                                                                shouldUpdate: !updatedClinic.shouldUpdate
                                                            });
                                                            fields.splice(index, 1);
                                                        }}
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        )
                                    );
                                })}
                                <IconButton
                                    onClick={() => {
                                        const newHoliday = defaultHoliday();

                                        append({
                                            start: newHoliday.start,
                                            end: newHoliday.end,
                                            isClosed: newHoliday.isClosed,
                                            date: newHoliday.date
                                        });
                                    }}
                                >
                                    <AddCircleIcon className={buttonStyles.addCircleStyle} />
                                </IconButton>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </MuiPickersUtilsProvider>
                <div className={globalStyles.buttonsContainer}>
                    <Button className={globalStyles.cancelButton} onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button className={globalStyles.confirmButton} type="submit" variant="outlined">
                        Save
                    </Button>
                </div>
            </form>
        </OpenedModal>
    );
}

export default withStyles(hoursViewStyles)(HoursView);
