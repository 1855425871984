import React, { useEffect, useState } from 'react';
import {
    Button,
    Chip,
    ExpansionPanel,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    withStyles
} from '@material-ui/core';
import Modal from '../common/SimpleModal';
import notificationsStyle from './style';
import SmsTab from './tabs/Sms';
import EmailTab from './tabs/Email';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core';
import CancelContinueModal from '../../collums-components/components/common/CancelContinueModal';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import CategoryApi from '../../collums-components/api/CategoryApi';
import { toastr } from 'react-redux-toastr';
const PreAfterCareModal = ({ notification, closeModal, classes, isPreAfterCare = false, generalTabSaveHandler }) => {
    const [currentPanel, setCurrentPanel] = useState('');
    const [isShowingExitConfirmation, setIsShowingExitConfirmation] = useState(false);
    const [haveEmailUnsavedChanges, setHaveEmailUnsavedChanges] = useState(false);
    const [haveSMSUnsavedChanges, setHaveSMSUnsavedChanges] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [data, setData] = useState({});

    const globalStyles = makeStyles(modalsButtonStyles)();
    const closeAccordion = () => {
        setCurrentPanel('');
    };

    const exitConfirmation = () => {
        setIsShowingExitConfirmation(false);
        closeModal();
    };

    const showConfirmationDialog = () => {
        if (haveEmailUnsavedChanges || haveSMSUnsavedChanges) setIsShowingExitConfirmation(true);
        else closeModal();
    };

    useEffect(() => {
        setData({
            name: notification?.name || '',
            description: notification?.description || ''
        });
    }, [notification]);

    const disableTransition = { transition: 'none' };

    const saveGeneralTabButtonHandler = () => {
        generalTabSaveHandler({ ...data, categories: selectedCategories }, notification.id);
    };

    const loadCategories = async () => {
        try {
            const categ = await CategoryApi.filter('', false, null, true);
            setCategories(categ);
            setSelectedCategories(categ.filter(cat => notification?.categories.includes(cat.id)));
        } catch (error) {
            toastr.error(error.data?.message || 'Something went wrong');
        }
    };

    useEffect(() => {
        loadCategories();
        // eslint-disable-next-line
    }, []);

    const handleCategoryDelete = index => {
        const newTags = [...selectedCategories];
        newTags.splice(index, 1);
        setSelectedCategories(newTags);
    };

    return (
        <>
            <Modal
                isOpen={true}
                title={`View/Edit ${notification.name} ${isPreAfterCare ? 'Pre/After care' : 'Notification'}`}
                id="notification"
                onClose={showConfirmationDialog}
                onCancel={showConfirmationDialog}
                size={'lg'}
                currentPanel={currentPanel}
            >
                <ExpansionPanel
                    onChange={() => {
                        if ('generalPanel' === currentPanel) setCurrentPanel('');
                        else setCurrentPanel('generalPanel');
                    }}
                    expanded={'generalPanel' === currentPanel}
                    className={classes.accordions}
                    style={disableTransition}
                    CollapseProps={{ classes: { container: { transition: 'none' } } }}
                >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>General</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container p={2} spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    value={data.name}
                                    onChange={({ target }) => setData({ ...data, name: target.value })}
                                    className={classes.formItem}
                                    variant="outlined"
                                    label="Name"
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    value={data.description}
                                    label="Description"
                                    onChange={({ target }) => setData({ ...data, description: target.value })}
                                    className={classes.formItem}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className={classes.tagsContainer}>
                                    <div style={{ width: '45%' }}>
                                        <InputLabel shrink className={classes.categoriesEditLabel}>
                                            Send to these service categories
                                        </InputLabel>
                                        <Select
                                            onChange={event => {
                                                if (event.target.value) {
                                                    setSelectedCategories([...selectedCategories, event.target.value]);
                                                }
                                            }}
                                            className={classes.autocomplete}
                                            variant={'outlined'}
                                            style={{ width: '100%' }}
                                        >
                                            {categories
                                                .filter(opt => !selectedCategories.some(el => el.id === opt.id))
                                                .map(opt => {
                                                    return (
                                                        <MenuItem key={opt.name} value={opt}>
                                                            {opt.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </div>

                                    <div className={classes.tagChipsContainer}>
                                        {/* eslint-disable-next-line */}
                                        {selectedCategories.map((tag, index) => (
                                            <Chip
                                                key={index}
                                                size="small"
                                                label={tag.name}
                                                onDelete={() => handleCategoryDelete(index)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} className={globalStyles.buttonsContainer}>
                                <Button className={globalStyles.cancelButton} onClick={showConfirmationDialog}>
                                    Cancel
                                </Button>
                                <Button className={globalStyles.confirmButton} onClick={saveGeneralTabButtonHandler}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel
                    onChange={() => {
                        if ('emailPanel' === currentPanel) setCurrentPanel('');
                        else setCurrentPanel('emailPanel');
                    }}
                    expanded={'emailPanel' === currentPanel}
                    className={classes.accordions}
                    style={disableTransition}
                    CollapseProps={{ classes: { container: { transition: 'none' } } }}
                >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{notification.name} Email</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <EmailTab
                            notification={notification}
                            closeAccordion={closeAccordion}
                            setHaveEmailUnsavedChanges={setHaveEmailUnsavedChanges}
                            isPreAfterCare={isPreAfterCare}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                {notification.from !== 'Form' && (
                    <ExpansionPanel
                        onChange={() => {
                            if ('smsPanel' === currentPanel) setCurrentPanel('');
                            else setCurrentPanel('smsPanel');
                        }}
                        className={classes.accordions}
                        expanded={'smsPanel' === currentPanel}
                        style={disableTransition}
                        CollapseProps={{ classes: { container: { transition: 'none' } } }}
                    >
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{notification.name} SMS</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <SmsTab
                                notification={notification}
                                closeAccordion={closeAccordion}
                                setHaveSMSUnsavedChanges={setHaveSMSUnsavedChanges}
                            />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                )}
            </Modal>
            {isShowingExitConfirmation && (
                <CancelContinueModal
                    setOpen={setIsShowingExitConfirmation}
                    title="Your changes will not be saved"
                    contentText="Are you sure you want to continue?"
                    onContinue={exitConfirmation}
                />
            )}
        </>
    );
};

export default withStyles(notificationsStyle)(PreAfterCareModal);
