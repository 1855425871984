import React, { useRef, useState } from 'react';

import {
    withStyles,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Typography,
    TableBody,
    TablePagination,
    TableFooter,
    Link
} from '@material-ui/core';

import Actions from '../common/TabContent/actions';
import ConfirmModal from '../common/ConfirmModal';

import styles from './styles';
import columns from './columns';
import Switch from '../../collums-components/components/common/customSwitch';

import { TEMPORARY_DISCOUNTS } from '../../collums-constants';
import { toLocaleString } from '../../collums-components/helpers/index';
import { getLocationItem } from '../../collums-constants/utils';
import { toastr } from 'react-redux-toastr';
import Moment from 'moment';
import TemporaryDiscountsApi from '../../api/TemporaryDiscountsApi';

function TemporaryDiscountsTable({
    classes,
    items,
    page,
    rowsPerPage,
    count,
    onChangePage,
    onChangeRowsPerPage,
    selectDiscount,
    openModal,
    isFromOrg,
    clinic,
    toggleItem,
    refreshList
}) {
    const [confirmModal, setConfirmModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState('');
    const [itemId, setItemId] = useState('');
    const actionRef = useRef(null);

    const renderDiscountValue = item => {
        if (item.discountType === TEMPORARY_DISCOUNTS.DISCOUNT_TYPES.PERCENTAGE) {
            return `${item.discount}%`;
        }
        return toLocaleString(item.discount);
    };

    const handleDuplicate = async id => {
        const currentItem = items.find(item => item.id === id);
        if (!currentItem) {
            toastr.error('Error discounts duplicated!');
            refreshList();
            return;
        }
        currentItem.name = currentItem.name + ' - copy';
        currentItem.eligableItems = currentItem?.eligableItems.map(i => ({ type: i.type, item: i.item }));
        delete currentItem.archived;
        delete currentItem.id;
        delete currentItem.modifiedAt;
        delete currentItem.createdAt;

        await TemporaryDiscountsApi.create({ ...currentItem, active: true }).then(() => {
            toastr.success('Discounts duplicated!');
            refreshList();
        });
    };

    const actions = {
        handleArchive: (id, isArchived) => {
            openConfirmModal(isArchived);
            setItemId(id);
            actionRef.current.handleClose();
        },
        handleDuplicate: id => handleDuplicate(id)
    };

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setModalTitle('');
        setModalContent('');
    };

    const openConfirmModal = isArchived => {
        setModalTitle(`${isArchived ? 'Unarchive' : 'Archive'} discount`);
        setModalContent(`Are you sure you want to ${isArchived ? 'unarchive' : 'archive'} this discount?`);
        setConfirmModal(true);
    };

    const handleArchive = () => {
        if (!itemId) return;

        const currentItem = items.find(item => item.id.toString() === itemId.toString());
        const value = isFromOrg ? !currentItem.archived : !getLocationItem(currentItem, clinic.id).archived;

        return toggleItem(itemId, 'Archived', value);
    };

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map(column => (
                            <TableCell
                                className={column.label === 'Amount' ? classes.cellRightAlign : ''}
                                key={column.id}
                                style={column.style}
                            >
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map(item => (
                        <TableRow
                            key={item.id}
                            style={{
                                backgroundColor: (() => {
                                    if (isFromOrg) {
                                        return item.archived ? '#ff9e9e' : 'white';
                                    }
                                    return item.archived || getLocationItem(item, clinic.id)?.archived
                                        ? '#ff9e9e'
                                        : 'white';
                                })()
                            }}
                        >
                            <TableCell>
                                <Switch
                                    checked={(() => {
                                        if (isFromOrg) return item.active;
                                        return getLocationItem(item, clinic.id)?.active;
                                    })()}
                                    onChange={(e, value) => {
                                        toggleItem(item.id, 'Active', value);
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <Typography>
                                    <Link
                                        onClick={() => {
                                            selectDiscount(item);
                                            openModal();
                                        }}
                                    >
                                        {item.name}
                                    </Link>
                                </Typography>
                            </TableCell>
                            <TableCell>{item.description}</TableCell>
                            <TableCell>{item.createdAt ? Moment(item.createdAt).format('DD/MM/YYYY') : '-'}</TableCell>
                            <TableCell>{item.discountType}</TableCell>
                            <TableCell className={classes.cellRightAlign}>{renderDiscountValue(item)}</TableCell>
                            <TableCell>
                                <Actions
                                    ref={actionRef}
                                    actions={actions}
                                    id={item.id}
                                    isFromOrg={isFromOrg}
                                    isArchived={isFromOrg ? item.archived : getLocationItem(item, clinic.id)?.archived}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TablePagination
                        rowsPerPage={rowsPerPage}
                        count={count}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        page={page}
                        onChangePage={onChangePage}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                    />
                </TableFooter>
            </Table>

            <ConfirmModal
                isOpen={confirmModal}
                setIsOpen={closeConfirmModal}
                onConfirm={handleArchive}
                title={modalTitle}
                content={modalContent}
                centerContent={true}
            />
        </>
    );
}

export default withStyles(styles)(TemporaryDiscountsTable);
