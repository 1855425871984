import React, { useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup, Typography, withStyles } from '@material-ui/core';
import { settingsStyles } from '../styles';
import { ACCOUNTING_TYPE } from '../../../collums-constants';
import AccountingDescriptions from '../../../collums-constants/accountingTypeDescriptions';
import InfoTooltip from '../../../collums-components/components/common/InfoTooltip';

function ReportsSettings({ classes, organisation, setOrganisation }) {
    const [canShowReportsForm, setCanShowReportsForm] = useState(false);

    useEffect(() => {
        setCanShowReportsForm(Boolean(organisation?.reporting));
    }, [organisation]);

    const changeExcIncTax = ({ target }) => {
        setOrganisation({
            ...organisation,
            reporting: {
                ...organisation.reporting,
                includeTaxes: target.value === 'inc'
            }
        });
    };

    const handleChangeAccView = ({ target }) => {
        setOrganisation({
            ...organisation,
            reporting: {
                ...organisation.reporting,
                accountingType: target.value
            }
        });
    };

    if (!canShowReportsForm) return null;

    return (
        <div className={classes.reportsContainer}>
            <Typography>Default report view:</Typography>
            <RadioGroup value={organisation.reporting.includeTaxes ? 'inc' : 'exc'} onChange={changeExcIncTax} row>
                <FormControlLabel value={'inc'} control={<Radio />} label={'Inc tax'} />
                <FormControlLabel value={'exc'} control={<Radio />} label={'Ex tax'} />
            </RadioGroup>
            <div className={classes.infoContainer}>
                <Typography>Default accounting view: </Typography>
                <InfoTooltip
                    toolTipSize={16}
                    title={
                        <Typography>
                            {AccountingDescriptions.SALES_DESCRIPTION}
                            <br />
                            {AccountingDescriptions.REVENUE_DESCRIPTION}
                        </Typography>
                    }
                />
            </div>
            <RadioGroup value={organisation.reporting.defaultAccView} onChange={handleChangeAccView} row>
                <FormControlLabel value={ACCOUNTING_TYPE.SALES} control={<Radio />} label={ACCOUNTING_TYPE.SALES} />
                <FormControlLabel value={ACCOUNTING_TYPE.REVENUE} control={<Radio />} label={ACCOUNTING_TYPE.REVENUE} />
            </RadioGroup>
        </div>
    );
}

export default withStyles(settingsStyles)(ReportsSettings);
