const modalStyles = () => ({
    modalContainer: {
        '& .MuiDialog-paper': {
            backgroundColor: '#eeeeee',
            '& .MuiDialogTitle-root': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& h4': {
                    fontSize: 18,
                    fontWeight: 500
                }
            }
        }
    }
});

module.exports = {
    modalStyles
};
