import React, { useState } from 'react';
import { ExpansionPanel, withStyles } from '@material-ui/core';
import Modal from '../common/SimpleModal';
import notificationsStyle from './style';
import SmsTab from './tabs/Sms';
import EmailTab from './tabs/Email';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core';
import CancelContinueModal from '../../collums-components/components/common/CancelContinueModal';

const NotificationModal = ({ notification, closeModal, classes, isPreAfterCare = false }) => {
    const [currentPanel, setCurrentPanel] = useState('');
    const [isShowingExitConfirmation, setIsShowingExitConfirmation] = useState(false);
    const [haveEmailUnsavedChanges, setHaveEmailUnsavedChanges] = useState(false);
    const [haveSMSUnsavedChanges, setHaveSMSUnsavedChanges] = useState(false);

    const closeAccordion = () => {
        setCurrentPanel('');
    };

    const exitConfirmation = () => {
        setIsShowingExitConfirmation(false);
        closeModal();
    };

    const showConfirmationDialog = () => {
        if (haveEmailUnsavedChanges || haveSMSUnsavedChanges) setIsShowingExitConfirmation(true);
        else closeModal();
    };

    const disableTransition = { transition: 'none' };
    return (
        <>
            <Modal
                isOpen={true}
                title={`View/Edit ${notification.name} ${isPreAfterCare ? 'Pre/After care' : 'Notification'}`}
                id="notification"
                onClose={showConfirmationDialog}
                onCancel={showConfirmationDialog}
                size={'lg'}
                currentPanel={currentPanel}
            >
                <ExpansionPanel
                    onChange={() => {
                        if ('emailPanel' === currentPanel) setCurrentPanel('');
                        else setCurrentPanel('emailPanel');
                    }}
                    expanded={'emailPanel' === currentPanel}
                    className={classes.accordions}
                    style={disableTransition}
                    CollapseProps={{ classes: { container: { transition: 'none' } } }}
                >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{notification.name} Email</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <EmailTab
                            notification={notification}
                            closeAccordion={closeAccordion}
                            setHaveEmailUnsavedChanges={setHaveEmailUnsavedChanges}
                            isPreAfterCare={isPreAfterCare}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                {notification.from !== 'Form' && (
                    <ExpansionPanel
                        onChange={() => {
                            if ('smsPanel' === currentPanel) setCurrentPanel('');
                            else setCurrentPanel('smsPanel');
                        }}
                        className={classes.accordions}
                        expanded={'smsPanel' === currentPanel}
                        style={disableTransition}
                        CollapseProps={{ classes: { container: { transition: 'none' } } }}
                    >
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{notification.name} SMS</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <SmsTab
                                notification={notification}
                                closeAccordion={closeAccordion}
                                setHaveSMSUnsavedChanges={setHaveSMSUnsavedChanges}
                            />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                )}
            </Modal>
            {isShowingExitConfirmation && (
                <CancelContinueModal
                    setOpen={setIsShowingExitConfirmation}
                    title="Your changes will not be saved"
                    contentText="Are you sure you want to continue?"
                    onContinue={exitConfirmation}
                />
            )}
        </>
    );
};

export default withStyles(notificationsStyle)(NotificationModal);
