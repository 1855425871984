import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

function PercentageInput({ classes, defaultValue, onChange, onBlur, CustomInput, disabled = false }) {
    const currencyFormatter = value => {
        if (isNaN(value)) {
            return '';
        }

        let amount = '';
        if (value.length > 4) {
            amount = value;
            return `${amount.charAt(0) || 0}${amount.charAt(1) || 0}.${amount.charAt(2) || 0}${amount.charAt(3) || 0}%`;
        } else {
            if (value === '0') {
                return `${value}.00%`;
            }
            amount = value / 100 + '%';
            const intAndDecimals = amount.split('.');
            if (intAndDecimals?.length > 0 && intAndDecimals[1]?.length === 2) {
                amount = value / 100 + '0%';
            }
            if (value.length === 2) {
                amount = value + '%';
            }
        }

        return `${amount}`;
    };

    return (
        <NumberFormat
            className={classes}
            onFocus={e => e.target.select()}
            tabIndex={-1}
            onBlur={onBlur}
            onChange={onChange}
            variant="outlined"
            defaultValue={defaultValue}
            format={currencyFormatter}
            customInput={CustomInput}
            label="Rate"
            disabled={disabled}
        />
    );
}

PercentageInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.any,
    classes: PropTypes.any,
    onBlur: PropTypes.func,
    CustomInput: PropTypes.any
};

export default PercentageInput;
