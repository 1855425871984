import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import Notification from './common/Notification';
import SideBar from '../collums-components/components/common/Sidebar';
import { validateJobPermissions } from '../collums-components/helpers/index';
import authApi from '../api/AuthApi';
import { getQueryParams } from '../services/locationHelper';
import config from '../config';
import ShowPINModal from '../collums-components/components/common/PINModal/index';
import { isAvailableByPlan, isValidMongoIdString } from '../collums-constants/utils';
import { COLLUMS_APPS_ACCESS_TIER } from '../collums-constants';
import { TOKEN } from '../collums-constants/storageKeys';
import EnvironmentDisabled from './../collums-components/components/common/EnvironmentDisabled';
import OrganisationsApi from '../api/OrganisationsApi';
import { useDispatch } from 'react-redux';
import { loadOrganisation } from '../actions/organisationActions';
import { useCookies } from 'react-cookie';
import LogoutModal from '../collums-components/components/common/LogoutModal';
import TaxesApi from '../collums-components/api/TaxesApi';
import Intercom from '../collums-components/helpers/Intercom';

import '../styles/App.css';
import { getHasAdvancedPermissions, validateAndUpdateClinic } from '../collums-components/helpers/user';

const App = ({ children }) => {
    const [isInitialized, setIsInitialized] = useState(false);
    const [cookies] = useCookies([TOKEN]);
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        const doEffect = async () => {
            const token = getQueryParams(location).token || cookies.token;
            if (token) {
                try {
                    localStorage.setItem('token', token);

                    const me = await authApi.getMe();

                    const organisation = await OrganisationsApi.listOrganisations();
                    const taxes = await TaxesApi.listTaxes();

                    localStorage.setItem('currency', organisation.currency);

                    dispatch(
                        loadOrganisation({
                            ...organisation,
                            taxes,
                            job: me.job,
                            role: me.role,
                            hasAdvancedPermissions: getHasAdvancedPermissions(me)
                        })
                    );

                    await validateJobPermissions(token, me, process.env.REACT_APP_NAME);

                    const currentClinic = getQueryParams(location).clinic;
                    if (currentClinic && isValidMongoIdString(currentClinic)) {
                        validateAndUpdateClinic(me, currentClinic);
                    }

                    await Intercom.updateUser(me);
                } catch (error) {
                    if (error && error.status === 401) {
                        window.location = `${config.authUrl}?redirect=admin`;
                    } else {
                        throw error;
                    }
                }
            } else {
                window.location = `${config.authUrl}?redirect=admin`;
            }
            setIsInitialized(true);
        };

        doEffect();

        // eslint-disable-next-line
    }, []);

    if (!isAvailableByPlan(COLLUMS_APPS_ACCESS_TIER.ADMIN)) {
        return <EnvironmentDisabled />;
    }

    if (!isInitialized) {
        return null;
    }

    return (
        <div className="container-fluid">
            <div className="App">
                <ShowPINModal />
                {cookies.logoutActive === 'true' && <LogoutModal />}
                <>
                    <div className="Page-view">
                        <div>{children}</div>
                        <Notification />
                    </div>
                    <SideBar appName="admin" />
                </>
            </div>
        </div>
    );
};

App.propTypes = {
    children: PropTypes.any.isRequired
};

export default App;
