import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { smsEditor as styles } from './styles';
import { withStyles, Box, TextField, Typography } from '@material-ui/core';

function SMSEditor({
    classes,
    setSMSMessage,
    smsMessage,
    selectionPosition,
    setSelectionPosition,
    editorRef,
    haveUnsavedChanges
}) {
    const [historyData, setHistoryData] = useState({
        position: 0,
        data: []
    });
    const form = smsMessage;

    const [smsCreditCount, setSMSCreditCount] = useState(1);

    useEffect(() => {
        if (editorRef) {
            editorRef.current.value = form || '';
            setSelectionPosition(editorRef.current.selectionEnd || 0);
        }
        /* eslint-disable-next-line */
    }, [editorRef, setSelectionPosition]);

    useEffect(() => {
        editorRef.current.value = smsMessage;
        /* eslint-disable-next-line */
    }, [smsMessage, setSMSMessage]);

    const handleHistory = (content, pos) => {
        if (historyData.data[historyData.data.length - 1] === content) return;
        const newData = { position: pos ? pos : historyData.position, data: [...historyData.data, content] };
        setHistoryData(newData);
    };

    return (
        <>
            <Box display="block" position="relative" width="100%">
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ display: 'block', width: '80%' }}>
                        <TextField
                            inputRef={editorRef}
                            onSelect={() => {
                                setSelectionPosition(editorRef.current.selectionStart);
                            }}
                            multiline
                            style={{ width: '100%' }}
                            rows={15}
                            className={classes.smsInput}
                            onClick={e => {
                                const pos = e.target.selectionEnd;
                                editorRef.current.selectionEnd = pos;
                                setSelectionPosition(pos);
                            }}
                            onChange={event => {
                                setSMSMessage(event.target.value);
                                haveUnsavedChanges();
                                const count = parseInt(event.target.value.length / 160, 10) + 1;
                                setSMSCreditCount(count);
                                if (event.target.value[event.target.value.length - 1] === ' ') {
                                    handleHistory(editorRef.current.value);
                                }
                            }}
                            onFocus={() => {
                                if (editorRef.current) {
                                    editorRef.current.setSelectionRange(selectionPosition, selectionPosition);
                                }
                            }}
                            placeholder="Message"
                            InputProps={{ style: { height: 256, width: '100%' } }}
                            variant="outlined"
                        />
                        <Typography
                            className={`${classes.helperText} ${classes.marginTop8px}`}
                        >{`Characters ${smsMessage.length}/160 ${smsCreditCount} SMS credits (when GSM-7 character set used). Using other characters will use more credits.`}</Typography>
                        <Typography className={classes.helperText}>
                            Check the number of credits (segments) your message will use{' '}
                            <a
                                className={classes.link}
                                href="https://twiliodeved.github.io/message-segment-calculator/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                here
                            </a>
                            . Read more about SMS encoding{' '}
                            <a
                                className={classes.link}
                                href="https://www.twilio.com/blog/2017/03/what-the-heck-is-a-segment.html"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                here
                            </a>
                        </Typography>
                    </div>
                </div>
            </Box>
        </>
    );
}

SMSEditor.propTypes = {
    classes: PropTypes.object.isRequired,
    smsMessage: PropTypes.object.isRequired,
    setSMSMessage: PropTypes.func.isRequired,
    setSelectionPosition: PropTypes.func.isRequired,
    editorRef: PropTypes.object
};

export default withStyles(styles)(SMSEditor);
