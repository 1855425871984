import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import StaffInputs from '../../common/StaffInputs';
import PropTypes from 'prop-types';
import { serviceDetailsModalStyles } from '../styles';
import { Link } from '@material-ui/core';

import {
    makeStyles,
    Checkbox,
    List,
    ListItem,
    Divider,
    ListItemIcon,
    ListItemText,
    Typography
} from '@material-ui/core';

function Staff({ control, watch, getValues, practitioners, currentService, allClinics }) {
    const classes = makeStyles(serviceDetailsModalStyles)();
    // eslint-disable-next-line
    const watchLocations = watch('locations') || [];
    const [locations, setLocations] = useState([]);
    const watchTax = watch('taxType') || 0;

    useEffect(() => {
        setLocations(watchLocations.filter(location => location.isSelected));
    }, [watchLocations]);

    const listOptions =
        (practitioners &&
            practitioners.map(clinic => {
                return {
                    id: clinic.id,
                    label: clinic.accountName,
                    practitioners: clinic.practitioners.map(practitioner => ({
                        value: practitioner.id,
                        label: practitioner.displayName,
                        subtitle: undefined,
                        duration: undefined,
                        isSelected: false,
                        netPrice: undefined,
                        grossPrice: undefined
                    }))
                };
            })) ||
        [];
    const setDefaultValues = clinic => {
        const location = currentService.locations.find(location => location.clinic === clinic.value);
        if (location) {
            if (location.staffs) {
                return listOptions.map(pract => {
                    const practitionersAvailable = pract.practitioners.map(practitioner => {
                        const staffSelected = practitioners[0]?.practitioners
                            ?.find(pract => practitioner?.value?.toString() === pract.id)
                            ?.locations?.find(loc => loc.clinic === clinic.id || loc.clinic === clinic.value)
                            ?.services?.find(service => currentService.id === service.service);

                        if (staffSelected) {
                            const servicePractitioner = location.staffs.find(
                                staff => staff.staff === practitioner.value
                            );
                            return {
                                ...practitioner,
                                netPrice: servicePractitioner?.netPrice,
                                grossPrice: servicePractitioner?.grossPrice,
                                duration: staffSelected.duration,
                                isSelected: true
                            };
                        } else {
                            return practitioner;
                        }
                    });
                    return { ...pract, practitioners: practitionersAvailable };
                });
            } else {
                return [];
            }
        } else {
            return [];
        }
    };

    const getDefaultStaffValue = (option, clinic) => {
        const dataFromPractitioner = practitioners[0]?.practitioners
            ?.find(staff => staff.id === option.value)
            ?.locations?.find(loc => loc.clinic === clinic.value)
            ?.services?.find(service => service.service === currentService.id);
        const clinicIndex = currentService.locations.findIndex(location => location.clinic === clinic.value);
        if (clinicIndex !== -1) {
            const defaultStaffValue = currentService.locations[clinicIndex].staffs.find(
                staff => staff.staff === option.value
            );
            if (dataFromPractitioner?.duration && defaultStaffValue.duration === undefined) {
                defaultStaffValue.duration = dataFromPractitioner.duration;
            }
            return defaultStaffValue;
        } else {
            return undefined;
        }
    };
    return (
        <div className={classes.formContentRow}>
            {!(locations.length > 0) && (
                <Typography>You should select at least one clinic before, on Pricing & Location.</Typography>
            )}
            {!!locations.length &&
                locations.map((clinic, clinicIndex) => {
                    const defaultValue = currentService.locations ? setDefaultValues(clinic) : listOptions;
                    if (!defaultValue?.length) return null;
                    const practitionerss = defaultValue.filter(clin => clin.id === clinic.value)[0] || [];

                    return (
                        <>
                            <Typography className={classes.spanStaffLabel}>
                                Select the staff allowed to perform this service. If desired, enter duration and price
                                for that staff member. The default duration and price will be used if these fields are
                                left blank.
                                {allClinics.length === 1 && (
                                    <>
                                        <br />
                                        <br />
                                        If you don’t see the staff you wish to enable below, ensure that they have
                                        permission to perform this service in{' '}
                                        <Link
                                            className={classes.link}
                                            href="/organisation/staffs"
                                            target="_blank"
                                            underline="none"
                                            color="indigo"
                                        >
                                            admin/organisation/staff
                                        </Link>{' '}
                                        in tab service rules
                                    </>
                                )}
                            </Typography>
                            <Typography className={classes.clinicLabel}>{clinic.label}</Typography>
                            <Controller
                                name={`list[${clinicIndex}].practitioners`}
                                control={control}
                                defaultValue={practitionerss.practitioners}
                                render={({ onChange, value }) => {
                                    const handleToggle = option => {
                                        const currentIndex = (value || []).findIndex(v => v.value === option.value);
                                        const newItems = [...value];
                                        const newItem = {
                                            ...value[currentIndex],
                                            isSelected: !value[currentIndex].isSelected
                                        };

                                        newItems.splice(currentIndex, 1, newItem);
                                        onChange(newItems);
                                    };

                                    const onItemChange = (option, newValues) => {
                                        const currentIndex = (value || []).findIndex(v => v.value === option.value);
                                        const newItems = [...value];
                                        const newItem = {
                                            ...value[currentIndex],
                                            ...newValues
                                        };

                                        newItems.splice(currentIndex, 1, newItem);

                                        onChange(newItems);
                                    };
                                    return (
                                        <List>
                                            {value.length === 0 && (
                                                <Typography>
                                                    No practitioners assigned to this clinic. To display the desired
                                                    staff member(s) in this list, first make them available in
                                                    admin/staff/service rules.
                                                </Typography>
                                            )}
                                            {value.map((option, index) => (
                                                <>
                                                    <ListItem
                                                        key={index}
                                                        role={undefined}
                                                        dense
                                                        style={{ marginTop: 5 }}
                                                        button
                                                    >
                                                        <ListItemIcon>
                                                            <Checkbox
                                                                onClick={() => handleToggle(option)}
                                                                edge="start"
                                                                checked={option.isSelected}
                                                                tabIndex={-1}
                                                                disableRipple
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={option.label}
                                                            secondary={option.subtitle}
                                                        />
                                                        {option.isSelected && (
                                                            <StaffInputs
                                                                getValues={getValues}
                                                                setValue={onItemChange}
                                                                currentIndex={index}
                                                                option={option}
                                                                fieldName={`list[${clinicIndex}].practitioners`}
                                                                defaultValue={
                                                                    currentService.locations
                                                                        ? getDefaultStaffValue(option, clinic)
                                                                        : undefined
                                                                }
                                                                taxValue={watchTax}
                                                            />
                                                        )}
                                                    </ListItem>
                                                    <Divider />
                                                </>
                                            ))}
                                        </List>
                                    );
                                }}
                            />
                        </>
                    );
                })}
        </div>
    );
}

Staff.propTypes = {
    control: PropTypes.any,
    register: PropTypes.any,
    watch: PropTypes.any,
    getValues: PropTypes.any,
    setValue: PropTypes.any,
    practitioners: PropTypes.array,
    currentService: PropTypes.object
};

export default Staff;
