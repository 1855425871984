import React, { useState } from 'react';
import { NameFilter, FilterAction, AddAction, ActionButton, InputFilter } from './Actions';
import {
    IconButton,
    withStyles,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Typography
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import StopIcon from '@material-ui/icons/Stop';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import { toLocaleString, useDebounce } from '../../../collums-components/helpers';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Switch from '../../../collums-components/components/common/customSwitch';

import { listPaginationStyles } from './styles';
import scrollTop from '../../../services/scrollTop';
import { CURRENT_CLINIC } from '../../../collums-constants/storageKeys';
import { getLocationItem } from '../../../collums-constants/utils';

import { DEFAULT_PHOTO, GENDERS } from '../../../collums-constants';

const ListPagination = ({
    data,
    defaultColumns,
    actions = [],
    ApiCall,
    item,
    maxSize,
    haveSubItems,
    linkAction,
    filters = [],
    isFromOrg,
    onCreate,
    customButton,
    nameFilter = false,
    filterName,
    clinic,
    inputFilters = [],
    allowInlineEdit = false,
    updateCall,
    canSort = false,
    sortContent = [],
    refreshListByOrder,
    highlightArchived = false,
    page,
    brands,
    suppliers,
    setPage,
    rowsPerPage,
    rowsPerPageOptions = [10, 25, 50],
    itemTaxes,
    itemCategories,
    setRowsPerPage,
    activeCall,
    unselectItem,
    classes,
    noHeader = false,
    allClinics = [],
    addNewButtonText = ''
}) => {
    const [params, setParams] = useState('');
    const [nameToFilter, setNameToFilter] = useState('');
    const [columns, setColumns] = useState([]);
    const [queryFilters, setQueryFilters] = useState(filters);
    const [editing, setEditing] = useState(false);
    const [editingRow, setEditingRow] = useState({});
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const filterDebounce = useDebounce(nameToFilter, 600);
    const [titleHovered, setTitleHovered] = useState('');
    const [fullfilledCategories, setFullfilledCategories] = useState(['']);
    const [fullfilledProdObjectProps, setFullfilledProdObjectProps] = useState({ brands: [], suppliers: [] });

    if (!clinic) {
        clinic = localStorage.getItem(CURRENT_CLINIC);
    }
    const filter = async field => {
        let newFilters = [];
        if (field) {
            newFilters = await queryFilters.map(element => {
                if (field.name === element.name) element.value = field.value;
                return element;
            });
        }
        let query = '';
        await newFilters.map(element => {
            query += `${element.name}=${element.value}&`;
            return element;
        });
        setQueryFilters(newFilters.length ? newFilters : filters);
        let queryString = query.substr(0, query.length - 1);
        if (nameToFilter) queryString += `&${filterName}=${encodeURIComponent(nameToFilter)}`;
        if (clinic) queryString += `&clinic=${clinic}`;
        await setParams(queryString);
        await setPage(0);
        refreshList(queryString);
    };

    const refreshList = async (queryParam, rows) => {
        setPage(0);

        const limit = rows || rowsPerPage;
        if (ApiCall) await ApiCall(0, limit * (page + 1), queryParam, true);
    };

    const filterByName = name => {
        setNameToFilter(name);
    };

    useEffect(() => {
        if (!nameFilter) return;
        let queryString = nameToFilter ? `&${filterName}=${encodeURIComponent(nameToFilter)}` : '';
        queryFilters.map(filter => {
            queryString += `&${filter.name}=${encodeURIComponent(filter.value)}`;
            return filter;
        });
        refreshList(queryString);
        /*eslint-disable-next-line */
    }, [filterDebounce]);

    const onFiltersChange = (func, values) => {
        setPage(0);
        if (nameToFilter) func(values, `&${filterName}=${encodeURIComponent(nameToFilter)}`, true);
        else func(values, '', true);
    };

    useEffect(() => {
        const clone = defaultColumns;
        let listActions;

        if (actions) {
            listActions = {
                data: actions.map(element => {
                    return element;
                }),
                field: 'actions'
            };
        } else {
            listActions = {};
        }

        setColumns([...clone, listActions]);
        //eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        scrollTop();
    }, [page]);

    const handleChangePage = async (event, newPage) => {
        if (newPage > page && data.length < maxSize) {
            const newData = await ApiCall(data.length, rowsPerPage * (page + 1), params);
            data.push(...newData);
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value));
        refreshList('', parseInt(event.target.value));
    };

    const handleReorder = title => {
        if (title !== orderBy) {
            setOrderBy(title);
            setOrder('desc');
        } else {
            setOrder(order === 'asc' ? 'desc' : 'asc');
        }
        return;
    };

    const refreshWithOrder = async query => {
        refreshListByOrder(query, params + `&${filterName}=${nameToFilter}`);
    };

    useEffect(() => {
        if (!order || !orderBy) return;
        refreshWithOrder(`&orderBy=${`${order === 'asc' ? '' : '-'}`}${orderBy}`);
        /*eslint-disable-next-line */
    }, [order, orderBy]);

    const renderColumns = () => {
        return (columns || [])
            .filter(column => !(column.field === 'actions' && !column.data.length))
            .map(column => {
                const canSortItem = canSort ? sortContent.find(element => element === column.title) : false;
                if (canSortItem)
                    return (
                        <TableCell
                            align={column.title === 'Active' ? 'center' : ''}
                            className={
                                column.title === 'Net price' ||
                                column.title === 'Price' ||
                                column.title === 'Late Cancellation Charge' ||
                                column.title === 'Late Cancellation Period'
                                    ? classes.cellRightAlign
                                    : ''
                            }
                            key={column.title}
                            sortDirection={orderBy === column.title ? order : false}
                        >
                            <TableSortLabel
                                hideSortIcon={titleHovered !== column.title}
                                onMouseEnter={() => setTitleHovered(column.title)}
                                onMouseLeave={() => setTitleHovered('')}
                                active={orderBy === column.title}
                                direction={orderBy === column.title ? order : 'asc'}
                                onClick={() => handleReorder(column.title)}
                            >
                                {column.title}
                                {orderBy === column.title ? (
                                    <span hidden>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    );
                return (
                    <TableCell
                        align={column.title === 'Active' ? 'center' : ''}
                        style={{ cursor: 'default' }}
                        component="th"
                        scope="row"
                        key={column.title}
                        className={
                            column.title === 'Net price' ||
                            column.title === 'Price' ||
                            column.title === 'Late Cancellation Charge' ||
                            column.title === 'Late Cancellation Period'
                                ? classes.cellRightAlign
                                : ''
                        }
                    >
                        {column.title}
                    </TableCell>
                );
            });
    };

    const changeEditing = row => {
        setEditing(true);
        if (!isFromOrg) {
            const localData = row.locations.find(loc => loc.clinic === clinic);
            row.netPrice = localData.netPrice;
            row.grossPrice = localData.grossPrice;
            row.tax = itemTaxes.find(tax => tax.id === localData.tax).rate;
        }
        setEditingRow(row);
    };

    const cancelEdit = () => {
        setEditing(false);
        setEditingRow({});
    };

    const submitEdit = async () => {
        const succeeded = await updateCall(editingRow);
        if (succeeded) cancelEdit();
    };

    useEffect(() => {
        cancelEdit();
        const getItemCats = async () => {
            return setFullfilledCategories(await itemCategories);
        };
        const getProdObjProps = async () => {
            const fetchedBrands = await brands();
            const fetchedSuppliers = await suppliers();
            return setFullfilledProdObjectProps({ brands: fetchedBrands, suppliers: fetchedSuppliers });
        };
        getItemCats();
        if (brands) {
            getProdObjProps();
        }

        /*eslint-disable-next-line */
    }, [page, rowsPerPage, data]);

    const rowAvatar = row => {
        const defaultAvatar = row.gender === 'Female' ? DEFAULT_PHOTO.DEFAULT_FEMALE : DEFAULT_PHOTO.DEFAULT_MALE;

        const avatar = row.avatar ? row.avatar : null;

        return avatar ?? defaultAvatar;
    };

    const renderRows = () => {
        const startRow = page * rowsPerPage;
        const endRow = startRow + rowsPerPage;
        const dataSliced = data.slice(startRow, endRow);

        return dataSliced.map((row, index) => (
            <TableRow
                key={`${row.id}:${index}~${startRow}/${endRow}`}
                style={(() => {
                    if (highlightArchived) {
                        return {
                            backgroundColor:
                                (isFromOrg && !row?.archived) ||
                                (!isFromOrg &&
                                    clinic &&
                                    !row?.archived &&
                                    clinic !== 'organisation' &&
                                    !row?.locations?.find(loc => loc.clinic === clinic)?.archived)
                                    ? 'white'
                                    : '#ff9e9e'
                        };
                    }
                    return {};
                })()}
            >
                {columns
                    .filter(column => !(column.field === 'actions' && !column.data.length))
                    .map(column => (
                        <TableCell
                            align={column.field === 'isActive' || column.field === 'active' ? 'center' : ''}
                            className={
                                column.title === 'Net price' ||
                                column.title === 'Price' ||
                                column.title === 'Late Cancellation Charge' ||
                                column.title === 'Late Cancellation Period'
                                    ? classes.cellRightAlign
                                    : ''
                            }
                            component="td"
                            scope="row"
                            key={column.field}
                        >
                            {(() => {
                                switch (column.field) {
                                    case 'photo':
                                        return (
                                            <img
                                                width={64}
                                                height={64}
                                                src={
                                                    rowAvatar(row) ||
                                                    (row.gender === GENDERS.FEMALE
                                                        ? DEFAULT_PHOTO.DEFAULT_FEMALE
                                                        : DEFAULT_PHOTO.DEFAULT_MALE)
                                                }
                                                alt=""
                                            />
                                        );
                                    case 'address':
                                        return (
                                            <Typography>
                                                {`${row[column.field]?.line1 || ''} ${row[column.field]?.line2 || ''}`}
                                            </Typography>
                                        );
                                    case 'name':
                                        if (editing && row.id === editingRow.id && isFromOrg)
                                            return (
                                                <TextField
                                                    style={{ width: '100%' }}
                                                    onChange={e => {
                                                        setEditingRow({ ...editingRow, name: e.target.value });
                                                    }}
                                                    defaultValue={editingRow.name}
                                                />
                                            );
                                        if (column.onClick)
                                            return (
                                                <Typography
                                                    onClick={() => {
                                                        setEditing(false);
                                                        setEditingRow({});
                                                        column.onClick(row);
                                                    }}
                                                >
                                                    <Link style={{ textDecoration: 'none' }}>{row.name}</Link>
                                                </Typography>
                                            );
                                        else return <Typography>{row.name}</Typography>;
                                    case 'product-size':
                                        if (row.vol === undefined || row.vol === 0 || !row.volUnit) return '';
                                        if ((row.vol === 0 || row.vol === 1) && row.volUnit === 'Units')
                                            return `${row.vol} Unit`;
                                        return `${row.vol} ${row.volUnit}`;
                                    case 'active': {
                                        const isRowActive = (() => {
                                            if (isFromOrg) {
                                                if (typeof row.active === 'number') {
                                                    return row.active === 10;
                                                }
                                                // ---- fix here
                                                return (typeof row.active === 'boolean' && row.active) || row.active;
                                            } else if (!isFromOrg && clinic !== 'organisation') {
                                                return (
                                                    row.locations?.find(loc => loc.clinic === clinic)?.active ||
                                                    row.active === 10
                                                );
                                            } else {
                                                return row.active === 10;
                                            }
                                        })();
                                        if (editing && row.id === editingRow.id) return <Switch />;
                                        return (
                                            <Switch
                                                checked={isRowActive}
                                                onChange={(e, value) => activeCall && activeCall(row, value)}
                                            />
                                        );
                                    }
                                    case 'isActive': {
                                        const isRowActive = rowItem => {
                                            if (isFromOrg || allClinics.length === 1) {
                                                if (typeof rowItem.isActive === 'number') {
                                                    return !!(rowItem.isActive === 10);
                                                }
                                                return !!(
                                                    (typeof rowItem.isActive === 'boolean' && rowItem.isActive) ||
                                                    rowItem.active
                                                );
                                            } else if (!isFromOrg && clinic !== 'organisation') {
                                                return !!rowItem.locations.find(loc => loc.clinic === clinic).isActive;
                                            }
                                        };
                                        if (editing && row.id === editingRow.id) {
                                            return (
                                                <Switch
                                                    checked={isRowActive(editingRow)}
                                                    onChange={() => {
                                                        const rowNewValue = (() => {
                                                            if (typeof editingRow.isActive === 'number') {
                                                                return editingRow.isActive === 10 ? 100 : 10;
                                                            }
                                                            return !editingRow.isActive;
                                                        })();
                                                        setEditingRow({ ...editingRow, isActive: rowNewValue });
                                                    }}
                                                />
                                            );
                                        }
                                        return (
                                            <Switch
                                                checked={isRowActive(row)}
                                                disabled={row.archived}
                                                onChange={(e, value) => {
                                                    activeCall && activeCall(row, value);
                                                }}
                                            />
                                        );
                                    }
                                    case 'items.services':
                                        return (
                                            <Switch
                                                checked={row.items.services}
                                                value={'services'}
                                                onChange={(e, value) => {
                                                    unselectItem && unselectItem(e.target.value, value, row);
                                                }}
                                            />
                                        );
                                    case 'items.products':
                                        return (
                                            <Switch
                                                checked={row.items.products}
                                                value={'products'}
                                                onChange={(e, value) => {
                                                    unselectItem && unselectItem(e.target.value, value, row);
                                                }}
                                            />
                                        );
                                    case 'rate':
                                        return <Typography>{row.rate}%</Typography>;
                                    case 'tax':
                                        if (editing && row.id === editingRow.id) {
                                            return (
                                                <Select
                                                    style={{ width: '100%' }}
                                                    onChange={e => {
                                                        const rate = Number(e.target.value);
                                                        const netPrice = Number(editingRow.netPrice ?? 0);
                                                        const grossPrice = netPrice + netPrice * (rate / 100);
                                                        setEditingRow({
                                                            ...editingRow,
                                                            itemTax: {
                                                                rate: e.target.value
                                                            },
                                                            grossPrice: grossPrice.toFixed(2)
                                                        });
                                                    }}
                                                    defaultValue={(() => {
                                                        if (isFromOrg) {
                                                            return editingRow.itemTax.rate;
                                                        }
                                                        return itemTaxes.find(
                                                            tax =>
                                                                tax.id ===
                                                                row.locations.find(loc => loc.clinic === clinic).tax
                                                        )?.rate;
                                                    })()}
                                                >
                                                    {itemTaxes.length &&
                                                        itemTaxes.map((item, idx) => (
                                                            <MenuItem value={item.rate} key={idx}>
                                                                {item?.rate}%
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            );
                                        }

                                        return (() => {
                                            if (isFromOrg || allClinics.length === 1) {
                                                return row.itemTax?.name || '';
                                            }
                                            return itemTaxes.find(
                                                tax => tax.id === row.locations.find(loc => loc.clinic === clinic).tax
                                            )?.name;
                                        })();
                                    case 'online':
                                        return row.showOnline ? (
                                            <CheckBoxIcon style={{ color: 'grey' }} />
                                        ) : (
                                            <CheckBoxOutlineBlankIcon style={{ color: 'grey' }} />
                                        );
                                    case 'nameAndColor':
                                        return (
                                            <Typography
                                                onClick={() => linkAction(row, 'Update')}
                                                style={{ textAlign: 'center' }}
                                            >
                                                <StopIcon style={{ color: row.color }} fontSize="small" />
                                                <Link>{row.name}</Link>
                                            </Typography>
                                        );
                                    case 'country':
                                        return <Typography>{row.address.country}</Typography>;
                                    case 'city':
                                        return <Typography>{row.address.city}</Typography>;
                                    case 'telephone':
                                        return <Typography>{row.publicPhone}</Typography>;
                                    case 'email':
                                        return <Typography>{row.publicEmail}</Typography>;
                                    case 'tags':
                                        if (!row.tags) return;
                                        return row.tags.map(element => (
                                            <Chip size="small" key={element.id} label={element.name} />
                                        ));
                                    case 'product-brand':
                                        if (!row.brand) {
                                            return;
                                        } else {
                                            let brand = {};
                                            if (isFromOrg) {
                                                brand = row?.brand || {};
                                            } else {
                                                const locationBrand = getLocationItem(row, clinic)?.brand;

                                                if (!locationBrand) return;
                                                if (!fullfilledProdObjectProps.brands.length) return;

                                                brand = fullfilledProdObjectProps.brands.find(
                                                    brand => brand.id.toString() === locationBrand
                                                );
                                            }

                                            if (!brand) return;

                                            return <Chip key={brand.id} label={brand.name} />;
                                        }

                                    case 'product-netPrice':
                                        if (editing && row.id === editingRow.id)
                                            return (
                                                <TextField
                                                    style={{ width: '100%' }}
                                                    type="number"
                                                    onChange={e => {
                                                        if (e.target.value < 0) return;

                                                        const grossPrice = Number(
                                                            (e.target.value || 0) * (1 + editingRow.itemTax.rate / 100)
                                                        );

                                                        setEditingRow({
                                                            ...editingRow,
                                                            grossPrice: grossPrice.toFixed(2),
                                                            netPrice: e.target.value
                                                        });
                                                    }}
                                                    value={Math.round(editingRow.netPrice * 100) / 100}
                                                />
                                            );
                                        return (
                                            <Typography>
                                                {toLocaleString(
                                                    isFromOrg
                                                        ? row.netPrice
                                                        : row.locations.find(loc => loc.clinic === clinic)?.netPrice
                                                )}
                                            </Typography>
                                        );
                                    case 'product-grossPrice':
                                        if (editing && row.id === editingRow.id)
                                            return (
                                                <TextField
                                                    type="number"
                                                    style={{ width: '100%' }}
                                                    onChange={e => {
                                                        if (e.target.value < 0) return;

                                                        const tax = editingRow.itemTax.rate;
                                                        const value = parseFloat(e.target.value) || 0;
                                                        const netPrice = value - (value * tax) / (100 + tax);
                                                        setEditingRow({
                                                            ...editingRow,
                                                            grossPrice: e.target.value,
                                                            netPrice: netPrice.toFixed(2)
                                                        });
                                                    }}
                                                    value={Math.round(editingRow.grossPrice * 100) / 100}
                                                />
                                            );
                                        return (
                                            <Typography>
                                                {toLocaleString(
                                                    isFromOrg
                                                        ? row.grossPrice
                                                        : row.locations.find(loc => loc.clinic === clinic)?.grossPrice
                                                )}
                                            </Typography>
                                        );
                                    case 'product-supplier':
                                        return (
                                            <Typography>
                                                {(() => {
                                                    if (isFromOrg) {
                                                        return row.supplier?.name;
                                                    }
                                                    return (
                                                        fullfilledProdObjectProps?.suppliers?.find(
                                                            sup =>
                                                                sup.id.toString() ===
                                                                getLocationItem(row, clinic).supplier
                                                        )?.name || ''
                                                    );
                                                })()}
                                            </Typography>
                                        );
                                    case 'product-category':
                                        return (
                                            <Typography>
                                                {(() => {
                                                    if (isFromOrg) {
                                                        return row.category?.name;
                                                    }
                                                    return (
                                                        fullfilledCategories.find(
                                                            cat =>
                                                                cat?.value?.toString() ===
                                                                getLocationItem(row, clinic)?.category?.toString()
                                                        )?.label || ''
                                                    );
                                                })()}
                                            </Typography>
                                        );
                                    case 'lateCancellation':
                                        if (row.lateCancellationChargeType === 'PERCENTAGE') {
                                            return <Typography /**/>{row.lateCancellationChargeValue}%</Typography>;
                                        }
                                        if (row.lateCancellationChargeType === 'AMOUNT') {
                                            return (
                                                <Typography /**/>
                                                    {toLocaleString(row.lateCancellationChargeValue)}
                                                </Typography>
                                            );
                                        }
                                        return <Typography></Typography>;
                                    case 'displayName':
                                        if (column.onClick)
                                            return (
                                                <Typography
                                                    onClick={() => {
                                                        setEditing(false);
                                                        setEditingRow({});
                                                        column.onClick(row);
                                                    }}
                                                >
                                                    <Link style={{ textDecoration: 'none' }}>{row.displayName}</Link>
                                                </Typography>
                                            );
                                        else return <Typography>{row.displayName}</Typography>;
                                    case 'accountName':
                                        if (column.onClick)
                                            return (
                                                <Typography
                                                    onClick={() => {
                                                        setEditing(false);
                                                        setEditingRow({});
                                                        column.onClick(row);
                                                    }}
                                                >
                                                    <Link style={{ textDecoration: 'none' }}>{row.accountName}</Link>
                                                </Typography>
                                            );
                                        else return <Typography>{row.accountName}</Typography>;
                                    case 'area':
                                        return <Typography>{row.area[0]?.name}</Typography>;
                                    case 'actions':
                                        if (editing && row.id === editingRow.id)
                                            return (
                                                <div style={{ display: 'flex' }}>
                                                    <IconButton onClick={() => submitEdit(row)}>
                                                        <CheckIcon style={{ fontSize: 14 }} />
                                                    </IconButton>
                                                    <IconButton onClick={() => cancelEdit()}>
                                                        <ClearIcon style={{ fontSize: 14 }} />
                                                    </IconButton>
                                                </div>
                                            );
                                        if (allowInlineEdit)
                                            return (
                                                <ActionButton
                                                    isFromOrg={isFromOrg}
                                                    clinic={clinic}
                                                    items={[
                                                        { title: 'edit', action: () => changeEditing(row) },
                                                        ...column.data
                                                    ]}
                                                    data={row}
                                                />
                                            );
                                        return (
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <ActionButton
                                                    isFromOrg={isFromOrg}
                                                    clinic={clinic}
                                                    items={column.data}
                                                    data={row}
                                                />
                                            </div>
                                        );
                                    default:
                                        if (haveSubItems) return <Typography>{row.main[column.field]}</Typography>;
                                        return <Typography>{row[column.field]}</Typography>;
                                }
                            })()}
                        </TableCell>
                    ))}
            </TableRow>
        ));
    };

    return (
        <>
            {customButton && customButton}
            <div className={classes.searchContainer}>
                {nameFilter && <NameFilter onAction={filterByName} value={nameToFilter} />}
                {onCreate &&
                    (isFromOrg ||
                        (allClinics && allClinics.length === 1) ||
                        ['Rooms', 'Equipments', 'Job', 'Square'].includes(item)) && (
                        <>
                            {addNewButtonText.length > 0 && <Typography>{addNewButtonText}</Typography>}
                            <AddAction onAction={onCreate} data={data} />
                        </>
                    )}
            </div>
            {!noHeader && (
                <div className={classes.header}>
                    <div className={classes.inputFilters}>
                        {inputFilters.map(options => {
                            return <InputFilter key={options.name} options={{ ...options, onFiltersChange }} />;
                        })}
                    </div>
                    {filters && (
                        <div className={classes.filtersContainer}>
                            {filters.map(option => {
                                if (option.hidden) {
                                    return '';
                                }
                                return <FilterAction key={option.name} data={option} onAction={filter} />;
                            })}
                        </div>
                    )}
                </div>
            )}
            <TableContainer className={classes.tableContainer}>
                <Table aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>{renderColumns()}</TableRow>
                    </TableHead>
                    <TableBody>{renderRows()}</TableBody>
                </Table>
            </TableContainer>
            <div className={classes.paginationContainer}>
                <TablePagination
                    width={'100%'}
                    rowsPerPageOptions={rowsPerPageOptions}
                    colSpan={3}
                    count={maxSize}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
        </>
    );
};

export default withStyles(listPaginationStyles)(ListPagination);
