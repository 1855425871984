import Axios from 'axios';
import api from '../collums-components/api/config';

class PractitionerApi {
    static query(skip = 0, limit = 15, queryParams = '', noShowDeleted = true) {
        return api.request({
            method: 'GET',
            url: `/practitioners?noShowDeleted=${noShowDeleted}&skip=${skip}&limit=${limit}${
                queryParams ? `&${queryParams}` : ''
            }`
        });
    }

    static get(id) {
        return api.request({
            method: 'GET',
            url: `/practitioners/${id}`
        });
    }

    static async uploadPractitionerAvatar(practitionerId, file) {
        const uploadUrl = await api.request({
            method: 'GET',
            url: `/practitioners/${practitionerId}/upload-url?type=${file.name.split('.')[1]}`
        });

        const signedRequest = uploadUrl.signedRequest;
        const url = uploadUrl.url;

        const options = {
            headers: {
                'Content-Type': file.name.split('.')[1],
                'x-amz-acl': 'public-read'
            }
        };

        // signedRequest, file, options
        await Axios({ url: signedRequest, method: 'PUT', data: file, options });
        return url;
    }

    static async uploadPractitionerSignature(practitionerId, file) {
        const uploadUrl = await api.request({
            method: 'GET',
            url: `/practitioners/${practitionerId}/upload-signature-url?type=${file.name.split('.')[1]}`
        });

        const signedRequest = uploadUrl.signedRequest;
        const url = uploadUrl.url;

        const options = {
            headers: {
                'Content-Type': file.name.split('.')[1],
                'x-amz-acl': 'public-read'
            }
        };

        // signedRequest, file, options
        await Axios({ url: signedRequest, method: 'PUT', data: file, options });
        return url;
    }

    static async deletePractitionerAvatar(practitionerId) {
        return api.request({
            method: 'GET',
            url: `/practitioners/${practitionerId}/remove-avatar`
        });
    }

    static async deletePractitionerSignature(practitionerId) {
        return api.request({
            method: 'GET',
            url: `/practitioners/${practitionerId}/remove-signature`
        });
    }

    static async availablePractitioners(serviceId, clinicId) {
        return api.request({
            method: 'GET',
            url: `/practitioners/by-service/${serviceId}?clinic=${clinicId}`
        });
    }

    static queryByClinic(clinicId) {
        return api.request({
            method: 'GET',
            url: `/practitioners/clinic/${clinicId}`
        });
    }

    static create(data) {
        return api.request({
            method: 'POST',
            url: '/practitioners',
            data
        });
    }

    static update(practitionerId, data, isFromOrg = false, location = undefined) {
        return api.request({
            method: 'POST',
            url: `/practitioners/${practitionerId}?clinic=${localStorage.getItem(
                'currentClinic'
            )}&isFromOrg=${isFromOrg}&location=${location}`,
            data
        });
    }

    static remove(practitionerId, value, clinic, isFromOrg = false) {
        return api.request({
            method: 'DELETE',
            url: `/practitioners/${practitionerId}?value=${value}&clinic=${clinic}&isFromOrg=${isFromOrg}`
        });
    }

    static listActives() {
        return api.request({
            method: 'GET',
            url: 'practitioners/list-actives'
        });
    }

    static adminQuery(skip = 0, limit = 15, queryParams = '') {
        return api.request({
            method: 'GET',
            url: `/admin-practitioners?skip=${skip}&limit=${limit}${queryParams ? `&${queryParams}` : ''}`
        });
    }

    static getApptsInFuture(practitionerId) {
        return api.request({
            method: 'GET',
            url: `/practitioners/${practitionerId}/appts-in-future`
        });
    }
}

export default PractitionerApi;
