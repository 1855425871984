import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Modal,
    Paper,
    TextField,
    withStyles,
    Typography,
    makeStyles,
    Checkbox,
    FormControlLabel
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import { taxRateModalStyles } from './styles';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import { useState } from 'react';
import PercentageInput from '../../collums-components/form/PercentageInput';
import { toastr } from 'react-redux-toastr';

function TaxRateModal({ closeModal, classes, createTax, updateTax, selectedTaxRate }) {
    const [taxRateLabel, setTaxRateLabel] = useState('');
    const [taxRateDescription, setTaxRateDescription] = useState('');
    const [taxRateItems, setTaxRateItems] = useState({
        services: false,
        products: false
    });
    const updateTaxItems = (event, value) => {
        const _taxRateItems = taxRateItems;
        _taxRateItems[event.target.value] = value;
        setTaxRateItems(_taxRateItems);
    };
    const globalStyles = makeStyles(modalsButtonStyles)();
    const taxRatePercentage = useRef('%0.00');

    const isBlockedForEdit = ['20% VAT', 'Exempt', 'No VAT'].includes(selectedTaxRate.name);

    const submitForm = e => {
        e.preventDefault();
        e.stopPropagation();

        if (!selectedTaxRate || selectedTaxRate === 'new') {
            if (!taxRateLabel || !taxRatePercentage.current || !taxRateDescription) {
                return toastr.error('All fields need to me filled');
            }
            const values = {
                name: taxRateLabel.trim(),
                description: taxRateDescription,
                rate: Number(taxRatePercentage.current.replace('%', '')),
                items: taxRateItems,
                active: true
            };
            createTax(values);
        }
        if (selectedTaxRate && selectedTaxRate.id) {
            let rate = Number(taxRatePercentage.current.replace('%', ''));
            if (!rate && rate !== 0) {
                rate = selectedTaxRate.rate;
            }
            const values = {
                name: taxRateLabel.trim() || selectedTaxRate.name,
                description: taxRateDescription || selectedTaxRate.description,
                rate: rate,
                items: taxRateItems || selectedTaxRate.items,
                active: selectedTaxRate.active === false ? false : true,
                archived: selectedTaxRate.archived === false ? false : true
            };
            updateTax({ id: selectedTaxRate.id, data: values });
        }
    };

    return (
        <Modal
            open={true}
            disableBackdropClick={false}
            onClose={() => closeModal()}
            className={classes.modal}
            onCancel={() => closeModal()}
        >
            <Paper className={classes.root}>
                <div className={classes.header}>
                    <Typography>
                        {selectedTaxRate?.name ? `Edit ${selectedTaxRate.name} tax rate` : 'New tax rate'}
                    </Typography>
                    <div className={classes.closeBtn}>
                        <CloseIcon onClick={closeModal} />
                    </div>
                </div>
                <form className={classes.formRoot} onSubmit={submitForm}>
                    <TextField
                        className={classes.formItem}
                        name="name"
                        label="Name"
                        variant="outlined"
                        defaultValue={selectedTaxRate?.name ? selectedTaxRate.name : ''}
                        onChange={e => setTaxRateLabel(e.target.value)}
                        inputProps={{ className: classes.textFieldInputProps }}
                        disabled={isBlockedForEdit}
                    />
                    <TextField
                        className={classes.formItem}
                        name="description"
                        label="Description"
                        variant="outlined"
                        defaultValue={selectedTaxRate?.description ? selectedTaxRate.description : ''}
                        onChange={e => setTaxRateDescription(e.target.value)}
                        inputProps={{ className: classes.textFieldInputProps }}
                        disabled={isBlockedForEdit}
                    />
                    <PercentageInput
                        classes={classes.formItem}
                        CustomInput={TextField}
                        defaultValue={
                            selectedTaxRate?.rate ? selectedTaxRate?.rate.toString().replace('.', '') + '%' : 0
                        }
                        existentValue={selectedTaxRate?.rate}
                        onChange={e => (taxRatePercentage.current = e.target.value)}
                        disabled={isBlockedForEdit}
                    />
                    <div className={classes.itemCheckboxes}>
                        <Typography className={classes.applyText}>Apply tax to:</Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={'services'}
                                    defaultChecked={selectedTaxRate?.items?.services}
                                    onChange={(e, newValue) => updateTaxItems(e, newValue)}
                                    className={classes.checkboxElement}
                                />
                            }
                            label="Services"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={'products'}
                                    defaultChecked={selectedTaxRate?.items?.products}
                                    onChange={(e, newValue) => updateTaxItems(e, newValue)}
                                    className={classes.checkboxElement}
                                />
                            }
                            label="Products"
                        />
                    </div>
                    <div className={globalStyles.buttonsContainer}>
                        <Button className={globalStyles.cancelButton} onClick={() => closeModal()}>
                            Cancel
                        </Button>
                        <Button className={globalStyles.confirmButton} type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Paper>
        </Modal>
    );
}

TaxRateModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    classes: PropTypes.object,
    selectedTag: PropTypes.object
};

export default withStyles(taxRateModalStyles)(TaxRateModal);
