import React from 'react';
import PropTypes from 'prop-types';

import { withStyles, Checkbox, FormControlLabel, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { categoryDetailsModalStyles } from '../styles';
import { getLocationItem } from '../../../collums-constants/utils';

const forStaffOptions = [
    { name: 'Sell this service', id: 'SELL' },
    { name: 'Perform this service', id: 'PERFORM' },
    { name: 'Perform AND sell this service', id: 'BOTH' }
];

function CommissionTab({ register, category, classes, watch, control, clinic, isFromOrg }) {
    const allowCommissionWatch = watch('allowCommission');

    return (
        <div className={classes.formContent}>
            <FormControlLabel
                label="Allow commission"
                control={
                    <Checkbox
                        defaultChecked={
                            category.id
                                ? isFromOrg
                                    ? category.allowCommission
                                    : getLocationItem(category, clinic).allowCommission
                                : true
                        }
                        inputProps={{ name: 'allowCommission' }}
                        inputRef={register}
                    />
                }
            />
            {allowCommissionWatch && (
                <div className={classes.formContentRow}>
                    <FormControl className={classes.formItem} variant="outlined">
                        <InputLabel>For Staff that</InputLabel>
                        <Controller
                            defaultValue={category?.commissionCondition}
                            name={'commissionCondition'}
                            control={control}
                            as={
                                <Select>
                                    {forStaffOptions.map(options => (
                                        <MenuItem key={options.id} value={options.id}>
                                            {options.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            }
                        />
                    </FormControl>
                </div>
            )}
        </div>
    );
}

CommissionTab.propTypes = {
    classes: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    category: PropTypes.object
};

export default withStyles(categoryDetailsModalStyles)(CommissionTab);
