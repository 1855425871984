import React from 'react';
import Render from '../../../collums-components/components/formBuilder/render';
import Modal from '../../common/Modal';
import { FormProvider } from '../../../collums-components/hooks/forms';

const FormPreviewModal = ({ closeModal, formRef }) => {
    return (
        <FormProvider
            value={{
                isJourney: true,
                defaultData: formRef.current
            }}
        >
            <Modal
                id="form-preview-modal"
                isOpen
                title="Form Preview"
                size="md"
                onCancel={closeModal}
                onClose={closeModal}
            >
                <Render />
            </Modal>
        </FormProvider>
    );
};

export default FormPreviewModal;
