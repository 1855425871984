import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
    Button,
    Paper,
    Typography,
    withStyles,
    makeStyles,
    List,
    Divider,
    FormControl,
    Checkbox,
    Tooltip,
    Grid,
    CircularProgress
} from '@material-ui/core';
import Modal from '../common/Modal';
import { locationSettingModalStyles } from './styles';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import RoomApi from '../../api/RoomApi';
import EquipmentApi from '../../api/EquipmentApi';
import PractitionerApi from '../../api/PractitionerApi';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DragableListItem from '../common/DragableListItem';
import { DEFAULT_PHOTO, STATUS_ACTIVE } from '../../collums-constants';
import ServiceApi from '../../api/ServiceApi';
import HelpIcon from '@material-ui/icons/Help';
import _ from 'lodash';
import ConfirmModal from '../common/ConfirmModal';

function LocationSettingModal({
    closeModal,
    classes,
    currentSettingLocation,
    inlineLocationSettings,
    setInlineLocationSettings,
    getMainFormValues,
    additionalWorkOnContinue,
    currentService
}) {
    const formRef = useRef();
    const { register, control, handleSubmit, setValue, watch } = useForm();

    const globalStyles = makeStyles(modalsButtonStyles)();
    const [practitioners, setPractitioners] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [equipments, setEquipments] = useState({ size: 5, items: [] });
    const [step, setStep] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const [confirmModal, setConfirmModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState('');
    const [modalCancel, setModalCancel] = useState(null);
    const [modalContinue, setModalContinue] = useState(null);
    const [modalOnContinue, setModalOnContinue] = useState(() => {});

    const watchPractitioners = watch('practitioners');
    const watchRooms = watch('rooms');
    const watchEquipments = watch('equipments');

    const loadData = async clinic => {
        setIsLoading(true);
        try {
            const category = getMainFormValues().category;
            let categorySettings;
            const currentClinicSettings = inlineLocationSettings[clinic];
            if (!currentClinicSettings && category) {
                categorySettings = await ServiceApi.getServices({ value: '' }, true, clinic, 99999, category);
            }
            const fetchedRooms = await RoomApi.listAll(clinic);
            let rooms = fetchedRooms.filter(room => room?.active === STATUS_ACTIVE && !room?.archived);
            if (currentClinicSettings) {
                rooms.map(room => {
                    if (currentClinicSettings.rooms.includes(room.id)) {
                        room.isSelected = true;
                    }
                    return room;
                });
            } else if (categorySettings) {
                rooms.map(room => {
                    if (
                        categorySettings.items.every(service => {
                            const cLoc = service.locations.find(loc => clinic === loc.clinic);
                            if (!cLoc) {
                                return true;
                            }
                            return cLoc?.rooms?.some(sRoom => sRoom?.room === room?.id);
                        })
                    ) {
                        room.isSelected = true;
                    }
                    return room;
                });
            }
            setRooms(rooms);
            const fetchedEquipment = await EquipmentApi.listAll(clinic);
            let equipments = fetchedEquipment.items;
            if (currentClinicSettings) {
                equipments.map(equipment => {
                    if (currentClinicSettings.equipments.includes(equipment.id)) {
                        equipment.isSelected = true;
                    }
                    return equipment;
                });
            } else if (categorySettings) {
                equipments.map(equipment => {
                    if (
                        categorySettings.items.every(service => {
                            const cLoc = service.locations.find(loc => clinic === loc.clinic);
                            if (!cLoc) {
                                return true;
                            }
                            return cLoc?.equipments?.some(sEquip => sEquip.equipment === equipment.id);
                        })
                    ) {
                        equipment.isSelected = true;
                    }
                    return equipment;
                });
            }
            setEquipments(equipments);
            const practitionerList = await PractitionerApi.queryByClinic(clinic);
            let practitioners = practitionerList[0].practitioners;
            practitioners = practitioners.filter(staff => !staff.archived && staff.active);
            if (currentClinicSettings) {
                practitioners.map(practitioner => {
                    const hasService = (practitioner?.locations || []).some(location => {
                        return (
                            location.clinic === clinic &&
                            (location?.services || []).some(service => service.service === currentService.id)
                        );
                    });

                    if (hasService) {
                        practitioner.isSelected = true;
                    }

                    return practitioner;
                });
            }

            setPractitioners(practitioners);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadData(currentSettingLocation.value);
        // eslint-disable-next-line
    }, []);

    const practitionerAvatar = practitioner => {
        const defaultAvatar =
            practitioner.gender === 'Female' ? DEFAULT_PHOTO.DEFAULT_FEMALE : DEFAULT_PHOTO.DEFAULT_MALE;

        const avatar = practitioner.avatar ? practitioner.avatar : null;

        return avatar ?? defaultAvatar;
    };

    const handleItemReordering = (dragged, draggedOver, onChange, value) => {
        let newItems = value;
        const setter = onChange;
        const draggedIndex = newItems.findIndex(item => item.id === dragged.id);
        const draggedOverIndex = newItems.findIndex(item => item.id === draggedOver.id);
        newItems[draggedOverIndex] = { ...dragged, id: dragged.id };
        newItems[draggedIndex] = { ...draggedOver, id: draggedOver.id };
        setter([...newItems]);
    };

    const handleDrop = (draggedItem, draggedOver, onChange, value) => {
        if (draggedItem && draggedOver && draggedItem.id !== draggedOver.id)
            handleItemReordering(draggedItem, draggedOver, onChange, value);
    };

    const handleChange = () => (list, setList, id) => {
        const itemIndex = list.findIndex(value => value.id === id);

        const newItem = { ...list[itemIndex], isSelected: !list[itemIndex].isSelected };
        const newList = [...list];

        newList.splice(itemIndex, 1, newItem);
        setList(newList);
    };

    const renderRooms = (onChange, value) => {
        return (
            <>
                <Typography className={classes.clinicLabel} align="center">
                    Select the rooms this service can be performed in
                    <Tooltip
                        title={
                            <Typography style={{ fontSize: 14 }} align="center">
                                You can change the priority order of the rooms in admin/services (or
                                admin/location/services if you have more than one location).
                            </Typography>
                        }
                        aria-label="help"
                    >
                        <HelpIcon className={classes.helpIcon} />
                    </Tooltip>
                </Typography>
                <List>
                    <DndProvider backend={HTML5Backend}>
                        {value.map(room => {
                            return (
                                <>
                                    <DragableListItem
                                        classes={classes}
                                        key={`room-item-${room.name}`}
                                        item={room}
                                        handleDrop={handleDrop}
                                        handleChange={handleChange()}
                                        onChange={onChange}
                                        value={value}
                                        type={'REORDER_ROOMS'}
                                        disabled={value.length === 1 && value.every(item => item.isSelected)}
                                    />
                                    <Divider />
                                </>
                            );
                        })}
                    </DndProvider>
                </List>
            </>
        );
    };

    const renderEquipment = (onChange, value) => {
        return (
            <>
                <Typography className={classes.clinicLabel} align="center">
                    Select the equipment that this service uses
                    <Tooltip
                        title={
                            <Typography style={{ fontSize: 14 }} align="center">
                                If you select more than one equipment, the service is available to book if either one OR
                                the other equipment is free, not one AND the other.
                                <br /> I.e. It is not possible to have a service requiring 2 pieces of equipment at the
                                same time.
                                <br /> E.g. If you have two dermapens, call them dermapen 1 and dermapen 2. Now select
                                both for your dermapen service and you will now be able to book two dermaen services at
                                the same time, each of them using one of the dermapens.
                            </Typography>
                        }
                        aria-label="help"
                    >
                        <HelpIcon className={classes.helpIcon} />
                    </Tooltip>
                </Typography>
                <DndProvider backend={HTML5Backend}>
                    <List>
                        {value.map(item => {
                            return (
                                <>
                                    <DragableListItem
                                        classes={classes}
                                        key={`equipment-item-${item.name}`}
                                        item={item}
                                        handleDrop={handleDrop}
                                        handleChange={handleChange()}
                                        onChange={onChange}
                                        value={value}
                                        type={'REORDER_EQUIPMENTS'}
                                    />
                                    <Divider />
                                </>
                            );
                        })}
                    </List>
                </DndProvider>
            </>
        );
    };

    const save = async value => {
        const newInlineLocationSettings = inlineLocationSettings;
        newInlineLocationSettings[currentSettingLocation.value] = {
            rooms: (value.rooms || []).filter(room => room.isSelected).map(room => room.id),
            equipments: (value.equipments || [])
                .filter(equipment => equipment.isSelected)
                .map(equipment => equipment.id),
            practitioners: (value.practitioners || []).filter(staff => staff.isSelected).map(staff => staff._id)
        };
        setInlineLocationSettings(newInlineLocationSettings);
        closeModal();
        if (typeof additionalWorkOnContinue === 'function') {
            additionalWorkOnContinue();
        }
    };

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setModalTitle('');
        setModalContent();
        setModalContinue(null);
        setModalCancel(null);
    };

    const saveButtonHandler = () => {
        switch (step) {
            case 1: {
                setInlineLocationSettings(prev => {
                    const locationSettings = { ...prev };
                    locationSettings[currentSettingLocation.value] = {
                        practitioners: (watchPractitioners || [])
                            .filter(staff => staff.isSelected)
                            .map(staff => staff._id),
                        equipments: locationSettings[currentSettingLocation.value]?.equipments || [],
                        rooms: locationSettings[currentSettingLocation.value]?.rooms || []
                    };

                    return {
                        ...locationSettings
                    };
                });
                setStep(2);
                break;
            }
            case 2: {
                setInlineLocationSettings(prev => {
                    const locationSettings = { ...prev };
                    locationSettings[currentSettingLocation.value] = {
                        practitioners: locationSettings[currentSettingLocation.value]?.practitioners || [],
                        equipments: locationSettings[currentSettingLocation.value]?.equipments || [],
                        rooms: (watchRooms || []).filter(room => room.isSelected).map(room => room.id)
                    };

                    return {
                        ...locationSettings
                    };
                });
                setStep(3);
                break;
            }
            case 3: {
                setInlineLocationSettings(prev => {
                    const locationSettings = { ...prev };
                    locationSettings[currentSettingLocation.value] = {
                        practitioners: locationSettings[currentSettingLocation.value]?.practitioners || [],
                        equipments: (watchEquipments || [])
                            .filter(equipment => equipment.isSelected)
                            .map(equipment => equipment.id),
                        rooms: locationSettings[currentSettingLocation.value]?.rooms || [],
                        finishedWizard: true
                    };

                    return {
                        ...locationSettings
                    };
                });
                closeModal();
                break;
            }
            default:
                break;
        }
    };

    const isSaveButtonEnabled = useCallback(() => {
        switch (step) {
            case 1:
                return (watchPractitioners || []).filter(staff => staff.isSelected)?.length;
            case 2:
                return (watchRooms || []).filter(room => room.isSelected)?.length;
            default:
                return true;
        }
    }, [step, watchPractitioners, watchRooms]);

    return (
        <>
            <Modal
                isOpen={true}
                disableBackdropClick={true}
                onClose={() => closeModal()}
                onCancel={() => closeModal()}
                title={`Configure service - ${currentSettingLocation.label}`}
                hideCloseIcon={true}
                dialogClass={classes.modal}
            >
                <form
                    ref={formRef}
                    className={classes.formRoot}
                    onSubmit={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleSubmit(save)();
                    }}
                >
                    <div style={{ display: step === 1 ? 'block' : 'none' }}>
                        {practitioners?.length ? (
                            <FormControl className={classes.listContainer} style={{ display: 'block' }}>
                                <Controller
                                    name={'practitioners'}
                                    control={control}
                                    defaultValue={practitioners}
                                    render={({ onChange, value }) => {
                                        const handleToggle = option => {
                                            const currentIndex = (value || []).findIndex(v => v._id === option._id);
                                            const newItems = _.cloneDeep(value);
                                            const newItem = {
                                                ...value[currentIndex],
                                                isSelected: !value[currentIndex].isSelected
                                            };

                                            newItems.splice(currentIndex, 1, newItem);
                                            onChange(newItems);
                                            setValue('practitioners', newItems, { shouldDirty: true });
                                        };
                                        return (
                                            <>
                                                <Typography
                                                    className={classes.clinicLabel}
                                                    align="center"
                                                    style={{ marginBottom: '20px' }}
                                                >
                                                    Select the staff that can perform this service
                                                </Typography>
                                                <Grid container spacing={1}>
                                                    {value.map((option, index) => {
                                                        return (
                                                            <Grid key={index} item xs={3}>
                                                                <Paper
                                                                    elevation={0}
                                                                    onClick={() => handleToggle(option)}
                                                                    align="center"
                                                                >
                                                                    <img
                                                                        alt="Practitioner avatar"
                                                                        style={{ height: '128px', width: '128px' }}
                                                                        src={`${practitionerAvatar(option)}`}
                                                                    ></img>
                                                                    <br />
                                                                    <Checkbox
                                                                        checked={
                                                                            option.isSelected
                                                                                ? option.isSelected
                                                                                : false
                                                                        }
                                                                        tabIndex={-1}
                                                                    />
                                                                    {option.displayName}
                                                                </Paper>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </>
                                        );
                                    }}
                                />
                            </FormControl>
                        ) : (
                            <Typography className={classes.clinicLabel} align="center" style={{ marginBottom: '20px' }}>
                                {isLoading ? (
                                    <CircularProgress />
                                ) : (
                                    <>
                                        There are no staff present in this location <br />
                                        Please first create the staff, rooms and any equipment in this location before
                                        configuring this service.
                                    </>
                                )}
                            </Typography>
                        )}
                    </div>

                    <div style={{ display: step === 2 ? 'block' : 'none' }}>
                        {!!rooms.length && (
                            <FormControl className={classes.listContainer} style={{ display: 'block' }}>
                                <Controller
                                    name="rooms"
                                    control={control}
                                    rules={register}
                                    defaultValue={rooms}
                                    render={({ onChange, value }) => {
                                        return renderRooms(onChange, value);
                                    }}
                                ></Controller>
                            </FormControl>
                        )}
                        {!rooms.length && (
                            <Typography className={classes.clinicLabel} align="center" style={{ marginBottom: '20px' }}>
                                No room available
                            </Typography>
                        )}
                    </div>
                    <div style={{ display: step === 3 ? 'block' : 'none' }}>
                        {!!equipments.length && (
                            <FormControl className={classes.listContainer} style={{ display: 'block' }}>
                                <Controller
                                    name="equipments"
                                    control={control}
                                    rules={register}
                                    defaultValue={equipments}
                                    render={({ onChange, value }) => {
                                        return renderEquipment(onChange, value);
                                    }}
                                ></Controller>
                            </FormControl>
                        )}
                        {!equipments.length && (
                            <Typography className={classes.clinicLabel} align="center" style={{ marginBottom: '20px' }}>
                                No equipment available
                            </Typography>
                        )}
                    </div>

                    <div className={globalStyles.buttonsContainer}>
                        <Button
                            className={globalStyles.cancelButton}
                            onClick={() => {
                                if (step === 1) {
                                    if (inlineLocationSettings[currentSettingLocation.value]) {
                                        closeModal();
                                    } else {
                                        setModalTitle('Update confirmation');
                                        setModalContent(
                                            'You have not finished configuring your service. You will not be able to book this service until it has been configured either here or in admin/location.'
                                        );
                                        setModalCancel('Back');
                                        setModalContinue('Continue');
                                        setModalOnContinue(() => () => {
                                            closeModal();
                                            if (typeof additionalWorkOnContinue === 'function') {
                                                additionalWorkOnContinue();
                                            }
                                        });
                                        setConfirmModal(true);
                                    }
                                } else {
                                    setStep(step - 1);
                                }
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            className={globalStyles.confirmButton}
                            type="button"
                            onClick={saveButtonHandler}
                            disabled={!isSaveButtonEnabled()}
                        >
                            Next
                        </Button>
                    </div>
                </form>
            </Modal>
            {confirmModal && (
                <ConfirmModal
                    isOpen
                    setIsOpen={closeConfirmModal}
                    onConfirm={modalOnContinue}
                    title={modalTitle}
                    content={modalContent}
                    centerContent
                    continueText={modalContinue}
                    cancelText={modalCancel}
                />
            )}
        </>
    );
}

LocationSettingModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    classes: PropTypes.object,
    currentSettingLocation: PropTypes.object,
    inlineLocationSettings: PropTypes.object,
    setInlineLocationSettings: PropTypes.func,
    getMainFormValues: PropTypes.func,
    currentService: PropTypes.object
};

export default withStyles(locationSettingModalStyles)(LocationSettingModal);
