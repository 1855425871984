import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    FormControlLabel,
    InputLabel,
    makeStyles,
    TextField,
    Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { toastr } from 'react-redux-toastr';
import ServiceApi from '../../../collums-components/api/ServiceApi';
import CategoryApi from '../../../collums-components/api/CategoryApi';
import LoadingScreen from '../../../collums-components/components/common/loadingScreen';
import { toLocaleString } from '../../../collums-components/helpers';
import { serviceDetailsModalStyles } from '../../services/styles';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';

const Moment = require('moment');
const Accordion = ({
    children,
    title,
    className,
    onChange,
    typographyClassName,
    // isPerformTreatments,
    // isSoloPractitioner,
    hideItem = false
}) => {
    if (hideItem) {
        return <div style={{ display: 'none' }}>{children}</div>;
    }
    return (
        <ExpansionPanel className={className} onChange={onChange}>
            <ExpansionPanelSummary
                // disabled={!isPerformTreatments || isSoloPractitioner}
                expandIcon={<ExpandMoreIcon />}
            >
                {typographyClassName === 'checkbox' ? (
                    title()
                ) : (
                    <Typography className={typographyClassName}>{title}</Typography>
                )}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default function ServiceRulesTab({
    clinics: allClinics,
    categories,
    setCat,
    control,
    showMoreCategories,
    performTreatments,
    isSoloPractitioner,
    servicesFields,
    appendService,
    changeService,
    removeService,
    isFromOrg,
    clinicOptions,
    canShowMore = false,
    practitionerId,
    appendMultipleServices,
    removeMultipleServices,
    updateCategory,
    currentService,
    setCurrentService,
    watch,
    clinicsSettings,
    proctitionerId
}) {
    const classes = makeStyles(serviceDetailsModalStyles)();
    const [loading, setLoading] = useState(false);
    const clinics = watch('clinics');
    const taxes = useSelector(state => state.organisation.taxes);

    const getService = async (category, actualClinicId) => {
        const services = await ServiceApi.query(
            category.id,
            undefined,
            actualClinicId || '',
            `count=100&isFromOrg=${isFromOrg}&active=false`
        );

        services.items = services.items.sort((a, b) => a.name.localeCompare(b.name));
        services.items = services.items
            .map(serv => {
                if (serv?.locations?.find(loc => loc?.clinic === actualClinicId)) {
                    serv.clinic = actualClinicId;
                    return serv;
                }
                return false;
            })
            .filter(el => el);
        services.size = services.items.length;

        const repeatedServices = currentService?.items
            ?.map(currService => {
                return services.items
                    .map(serv => {
                        if (currService.id === serv.id) {
                            if (currService?.clinic) {
                                return currService.clinic === actualClinicId;
                            }
                            return true;
                        }
                        return false;
                    })
                    .filter(el => el);
            })
            .filter(el => el?.length);

        const onList = svcId => {
            return servicesFields.find(serv => serv.id === svcId);
        };

        if (!repeatedServices?.length) {
            const currentServiceClone = currentService || { items: [] };
            if (currentService?.items === undefined) {
                setCurrentService(services);
            }
            let actualSubCategories = await CategoryApi.getSubCategories('', category.id, actualClinicId, false, true);
            if (actualSubCategories.length) {
                const subServices = services.items.filter(el => el?.subCategory);
                services.items = services.items
                    .filter(el => !el?.subCategory)
                    .sort((a, b) => a.name.localeCompare(b.name));

                actualSubCategories = actualSubCategories
                    .map(subCat => {
                        if (!subServices.length) {
                            return false;
                        }
                        const subHaveAnyService = subServices.filter(service => {
                            return service?.subCategory === subCat.id;
                        });

                        if (!subHaveAnyService.length) {
                            return false;
                        }

                        const allServicesOnList = subServices
                            .filter(service => {
                                if (service?.subCategory === subCat.id) return service;
                                return false;
                            })
                            .every(service => onList(service.id));

                        return { ...subCat, allSelected: allServicesOnList };
                    })
                    .filter(el => el);

                const actualCategories = categories
                    .map((clinic, index) => {
                        if (clinic.id === actualClinicId) {
                            const UpdatedClinicBody = clinic.categories
                                .map((cat, index) => {
                                    if (cat.id === category.id) {
                                        cat.subCategories = actualSubCategories;
                                        return { cat, index };
                                    }
                                    return undefined;
                                })
                                .filter(el => el)[0];
                            clinic.categories[UpdatedClinicBody.index] = UpdatedClinicBody.cat;
                            return { updatedClinic: clinic, index };
                        }
                        return undefined;
                    })
                    .filter(el => el)[0];
                categories[actualCategories.index] = actualCategories.updatedClinic;
                currentServiceClone.items.push(...services.items, ...subServices);

                setCurrentService(currentServiceClone);
            } else {
                currentServiceClone.items.push(...services.items);
                setCurrentService(currentServiceClone);
            }
        }

        setCat([...categories]);
    };

    const onChangeRow = service => {
        changeService(service);
    };

    const appendRow = service => {
        service.offered = true;
        appendService(service);
    };

    const removeRow = service => {
        removeService(service);
    };

    const getRealPrice = (service, clinic) => {
        const selectedClinic = clinicOptions.find(el => el.accountName === clinic?.accountName);
        let gross = service.defaultGrossPrice;
        if (selectedClinic && practitionerId) {
            const location = service?.locations?.[0] || service?.locations;
            if (location) {
                const staff = location?.staffs?.find(staff => staff?.staff === practitionerId);
                const tax = (taxes || []).find(tax => tax.id === location?.taxType);
                if (staff) {
                    if (staff.grossPrice !== undefined) return staff.grossPrice;
                    if (staff.grossPrice === undefined && staff.netPrice !== undefined) {
                        return staff.netPrice * (1 + (tax?.rate ?? 0) / 100);
                    }
                }
                gross = location.grossPrice;
            }
        }
        return gross;
    };

    const ServiceRow = ({ service, category, clinic }) => {
        const onList = useMemo(() => {
            return servicesFields.find(srvc => {
                const srvcClinicId = srvc?.clinic?.id || srvc?.clinic;
                return srvc.id.toString() === service.id.toString() && srvcClinicId === clinic.id;
            });
        }, [service, clinic]);

        const showService = useMemo(() => {
            return {
                ...service,
                offered: category.allSelected,
                commission: { type: 'None', value: 0 },
                comissionDeduction: 0
            };
        }, [service, category]);

        const time = useMemo(() => {
            return moment()
                .startOf('day')
                .add(service.defaultDuration || 0, 'minutes');
        }, [service]);

        const defaultDuration = useMemo(() => {
            return time.format('HH:mm');
        }, [time]);

        const [inputValue, setInputValue] = useState(onList?.commission?.value || 0);

        const inputRef = useRef(null);

        useEffect(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, [inputValue]);

        const onChange = value => {
            setInputValue(parseFloat(value));
            return parseFloat(value);
        };

        const onBlur = () => {
            onChangeRow({
                ...onList,
                commission: {
                    type: onList.commission.type,
                    value: inputValue
                }
            });
            return;
        };

        const onFocus = () => {
            if (inputValue === 0) {
                setInputValue(NaN);
            }
            inputRef.current.focus();
            return;
        };

        // const isShowingCommissionDeduction =
        //     (onList?.commissionDeduction || { type: 'None' }).type !== 'None' && onList?.commissionDeduction;

        // at clinic level show only services practitioner is assigned to
        if (!isFromOrg && allClinics.length > 1) {
            if (
                !service.locations
                    .find(loc => loc.clinic === clinic.id)
                    ?.staffs.find(staff => staff.staff === practitionerId)
            ) {
                return false;
            }
        }

        const serviceLocation = service?.locations.find(location => location.clinic === clinic.id);
        const isActive = serviceLocation?.active || false;

        return (
            <div key={service.id} className={classes.serviceRow}>
                <div>
                    <Checkbox
                        checked={onList ? onList.offered : showService.offered}
                        onChange={(e, checked) => {
                            checked ? appendRow({ ...showService, clinic }) : removeRow(showService, clinic, category);
                        }}
                        // disabled={(() => {
                        //     if (!practitionerId && performTreatments) {
                        //         return false;
                        //     }
                        //     return (
                        //         !clinicsSettings[clinic.id]?.isPerformTreatments ||
                        //         !!clinicsSettings[clinic.id]?.isSoloPractitioner
                        //     );
                        // })()}
                    />
                </div>
                <div className={classes.horizontallyCenteredRowItem}>
                    <Typography
                        className={onList?.offered ? classes.bold : classes.commissionButton}
                        style={!isActive ? { color: 'grey' } : {}}
                    >
                        {showService?.name} {!isActive ? ' (inactive)' : ''}
                    </Typography>
                </div>
                {onList?.offered && (
                    <>
                        <div className={classes.marginlessRowItem}>
                            <InputLabel shrink style={{ marginLeft: 12 }}>
                                Commission
                            </InputLabel>
                            <Autocomplete
                                style={{ marginTop: 6 }}
                                options={['None', 'Amount', '%']}
                                renderInput={params => (
                                    <TextField style={{ width: '100%' }} {...params} variant="outlined" />
                                )}
                                onChange={(e, option) => {
                                    onChangeRow({ ...onList, commission: { type: option, value: 0 } });
                                }}
                                defaultValue={onList?.commission?.type}
                            />

                            {onList.commission?.type !== 'None' && onList.commission?.type && (
                                <>
                                    <br />
                                    <InputLabel shrink style={{ marginLeft: 12 }}>
                                        Commission value
                                    </InputLabel>
                                    <TextField
                                        variant="outlined"
                                        type="number"
                                        style={{ width: '100%', marginTop: 6 }}
                                        inputProps={onList.commission.type === '%' ? { min: 0, max: 100 } : { min: 0 }}
                                        ref={inputRef}
                                        onChange={e => onChange(e.target.value)}
                                        onBlur={() => onBlur()}
                                        onFocus={() => onFocus()}
                                        value={inputValue}
                                    />
                                </>
                            )}
                        </div>
                        <div className={classes.serviceRowItem80}>
                            <span>Total service price</span>
                            <br />
                            <span style={{ textAlign: 'center' }}>{toLocaleString(getRealPrice(onList, clinic))}</span>
                        </div>
                        <div className={classes.serviceRowItem}>
                            <InputLabel shrink style={{ marginLeft: 12 }}>
                                Duration
                            </InputLabel>
                            <Autocomplete
                                style={{ width: '100%', marginTop: 6 }}
                                value={onList.duration || defaultDuration}
                                onChange={(event, newValue) => onChangeRow({ ...onList, duration: newValue })}
                                id="Duration"
                                options={new Array(144)
                                    .fill(5)
                                    .map((e, i) =>
                                        Moment.duration(e * i + 5, 'minutes').format('HH:mm', { trim: false })
                                    )}
                                renderInput={params => (
                                    <TextField style={{ width: '100%' }} {...params} variant="outlined" />
                                )}
                            />
                        </div>

                        {/*<div className={classes.serviceRowItem}>
                            <Autocomplete
                                style={{ width: '100%', marginTop: 8 }}
                                options={['None', 'Amount', '%']}
                                renderInput={params => (
                                    <TextField
                                        style={{ width: '100%' }}
                                        {...params}
                                        label="Deduction"
                                        variant="outlined"
                                    />
                                )}
                                onChange={(e, option) => {
                                    onChangeRow({ ...onList, commissionDeduction: { type: option, value: 0 } });
                                }}
                                defaultValue={onList.commissionDeduction?.type || 'None'}
                            />

                            {isShowingCommissionDeduction ? (
                                <>
                                    <br />
                                    <TextField
                                        style={{ width: '100%' }}
                                        variant="outlined"
                                        label="Deduction value"
                                        type="number"
                                        inputProps={
                                            onList.commissionDeduction.type === '%' ? { min: 0, max: 100 } : { min: 0 }
                                        }
                                        value={onList.commissionDeduction.value}
                                        onChange={e =>
                                            onChangeRow({
                                                ...onList,
                                                commissionDeduction: {
                                                    type: onList.commissionDeduction.type,
                                                    value: e.target.value
                                                }
                                            })
                                        }
                                    />
                                </>
                            ) : (
                                <></>
                            )}
                        </div>*/}
                    </>
                )}
            </div>
        );
    };

    // select all services from a category. If the services from category where not fetched yet, get them
    const handleSelectAllServicesFromCategory = useCallback(
        async (e, checked, category, clinic, categoryIndex) => {
            try {
                setLoading(true);

                const categoriesIds = [category.id];
                let subCategory = {};

                if (category?.parentCategory) {
                    categoriesIds.push(category.parentCategory);
                    subCategory = category;
                }
                let services = await ServiceApi.getServiceWithCategoriesID({
                    categoriesIds: categoriesIds,
                    clinicId: clinic.id,
                    subCategory
                });
                if (!isFromOrg && allClinics.length > 1) {
                    services = services.filter(service =>
                        service?.locations?.staffs.find(staff => staff.staff === practitionerId)
                    );
                }
                if (services.length) {
                    const mappedServices = services.map(element => ({
                        ...element,
                        offered: false,
                        commission: { type: 'None', value: 0 },
                        allowOnlineBooking: false,
                        duration: undefined,
                        pos: categoryIndex
                    }));

                    if (checked) {
                        const filteredServices = mappedServices
                            .filter(el => {
                                return !servicesFields.some(servField => {
                                    return el.id === servField.id && servField.clinic === clinic.id;
                                });
                            })
                            .map(el => {
                                el.clinic = clinic.id;
                                return el;
                            });

                        appendMultipleServices(filteredServices, clinic);
                        updateCategory(category.id, clinic.id, true, category.parentCategory);
                    } else {
                        const filteredServices = mappedServices
                            .filter(el => {
                                return servicesFields.some(servField => {
                                    return el.id === servField.id && servField.clinic === clinic.id;
                                });
                            })
                            .map(serv => {
                                return serv.id;
                            });

                        updateCategory(category.id, clinic.id, false, category.parentCategory);
                        removeMultipleServices(filteredServices, clinic);
                    }
                } else {
                    updateCategory(category.id, clinic.id, false);
                }
            } catch (err) {
                return toastr.error(err?.data?.message || 'Something went wrong');
            } finally {
                setLoading(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [appendMultipleServices, removeMultipleServices, updateCategory, servicesFields]
    );
    // render the checkbox with label on accordion summary
    const categoryTitle = useCallback(
        (category, clinic, categoryIndex) => {
            const isActive = category?.locations?.active;

            return (
                <div className={classes.categoryTitle} key={`${category.id}-${category.allSelected}`}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="select-all-checkbox"
                                onClick={e => e.stopPropagation()}
                                checked={category.allSelected}
                                // disabled={(() => {
                                //     if (!practitionerId && performTreatments) {
                                //         return false;
                                //     }
                                //     return (
                                //         !clinicsSettings[clinic.id]?.isPerformTreatments ||
                                //         !!clinicsSettings[clinic.id]?.isSoloPractitioner
                                //     );
                                // })()}
                                onChange={e => {
                                    handleSelectAllServicesFromCategory(
                                        e,
                                        !category.allSelected,
                                        category,
                                        clinic,
                                        categoryIndex
                                    );
                                }}
                                name="selectAllCategory"
                                color="primary"
                                indeterminate={category.indeterminate}
                            />
                        }
                    />
                    <Typography className={classes.bold} style={!isActive ? { color: 'grey' } : {}}>
                        {category.name} {!isActive ? ' (inactive)' : ''}
                    </Typography>
                </div>
            );
        },
        /* eslint-disable-next-line */
        [
            classes.bold,
            classes.categoryTitle,
            handleSelectAllServicesFromCategory,
            performTreatments,
            categories,
            isSoloPractitioner
        ]
    );

    const renderCategory = ({ clinic, isSub, category, clinicIndex, categoryIndex }) => {
        const serviceHasLoaded = !!currentService?.items?.find(service => {
            if (service?.subCategory) {
                return category?.id === service?.subCategory;
            }
            return category?.id === service?.category?.id;
        });
        const subCategoryLoaded = serviceHasLoaded && category?.subCategories && !!category?.subCategories?.length;

        const allLoaded = (() => {
            if (category?.subCategories?.length) {
                return !!subCategoryLoaded;
            }
            return !!serviceHasLoaded;
        })();
        let hideCategory =
            !isFromOrg &&
            allClinics.length > 1 &&
            !categories
                .find(loc => loc.id === clinic.id)
                ?.categories?.find(cat => cat.id === category.id)
                ?.services.filter(service => service?.locations?.staffs?.find(staff => staff.staff === practitionerId))
                .length;
        if (isSub) {
            hideCategory =
                !isFromOrg &&
                allClinics.length > 1 &&
                !currentService?.items
                    .filter(srv => srv.subCategory === category || srv.subCategory === category.id)
                    .some(srv =>
                        srv.locations
                            ?.find(loc => loc.clinic === clinic.id)
                            ?.staffs?.find(staff => staff.staff === practitionerId)
                    );
        }
        return (
            <Accordion
                className={isSub && classes.accordion}
                key={category.id}
                onChange={(e, expanded) => (expanded && !isSub ? getService(category, clinic.id) : null)}
                title={() => categoryTitle(category, clinic, categoryIndex)}
                typographyClassName="checkbox"
                isPerformTreatments={
                    !!clinicsSettings[clinic.id]?.isPerformTreatments || (!practitionerId && performTreatments)
                }
                isSoloPractitioner={!!clinicsSettings[clinic.id]?.isSoloPractitioner}
                hideItem={hideCategory}
            >
                <div className={classes.serviceContainer}>
                    {!allLoaded && <CircularProgress />}
                    <Box mt={2}>
                        {allLoaded &&
                            currentService?.items?.map((service, index) => {
                                if (service?.clinic === clinic?.id) {
                                    if (
                                        category.id === service.subCategory ||
                                        (category.id === service.category.id && !service?.subCategory)
                                    ) {
                                        return (
                                            <Controller
                                                key={index}
                                                defaultValue={{ service: service.id, commission: {} }}
                                                name="services"
                                                control={control}
                                                render={({ onChange }) => (
                                                    <ServiceRow
                                                        service={service}
                                                        category={category}
                                                        clinic={clinic}
                                                        onChange={onChange}
                                                        index={index}
                                                        isSub={isSub}
                                                    />
                                                )}
                                            />
                                        );
                                    }
                                }

                                return null;
                            })}
                    </Box>
                </div>
            </Accordion>
        );
    };

    async function loadCategories() {
        setLoading(true);
        await showMoreCategories(servicesFields, true);
        setLoading(false);
    }

    return (
        <div className={classes.formContent}>
            <div className={classes.formContentRow}>
                {isFromOrg ? (
                    <p>
                        Note: Commission set here will be <strong>in addition</strong> to any commission layers set in
                        the Commission section below. Whether this service commission is based on services sold or
                        performed is set in admin/services/commission.
                    </p>
                ) : (
                    <p>
                        Note: Commission set here will be in addition to any commission layers set in the Commission
                        section below
                    </p>
                )}
                {categories &&
                    !!categories.length &&
                    categories.map((clinic, clinicIndex) => {
                        const availableClinicsIds = clinics.map(clinic => clinic.value);
                        return (
                            <Box
                                display={availableClinicsIds.includes(clinic.id) ? 'inherit' : 'none'}
                                key={clinicIndex}
                            >
                                <div key={clinicIndex}>
                                    <div className={classes.accordionTitle}>
                                        <Typography>{clinic.accountName}</Typography>
                                    </div>
                                    <br />
                                    {clinic.categories.map((cat, catIndex) => {
                                        return renderCategory({
                                            category: cat,
                                            clinic: clinic,
                                            clinicIndex: clinicIndex,
                                            categoryIndex: catIndex
                                        });
                                    })}
                                </div>
                            </Box>
                        );
                    })}
                {(!categories || !categories.length) && (
                    <Typography className={classes.bold}>Please, select some locations first</Typography>
                )}
                {categories.length && !!clinics.length && canShowMore && (performTreatments || !practitionerId) && (
                    <Button onClick={loadCategories}>Show All</Button>
                )}
                {loading && <LoadingScreen />}
            </div>
        </div>
    );
}
