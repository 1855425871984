import React, { useState } from 'react';
import { Form } from 'react-forms-processor';
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    Typography,
    withStyles
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

import Modal from '../common/Modal';
import renderer from '../formBuilder/renderer';
import { controlledDisable, controlledVisibility } from '../../services/formHelper';
import { detailsFormModalStyles } from './styles';

const DetailsFormModal = props => {
    const isFormValidMapInit = props.sections.reduce(
        (acc, section, key) => ({
            ...acc,
            [key]: section.isInitiallyValid
        }),
        {}
    );
    const [formValue, setFormValue] = useState({});
    const [isFormValidMap, setIsFormValidMap] = useState(isFormValidMapInit);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const confirm = async () => {
        setIsFormSubmitted(true);
        props.onConfirm();
    };

    const mapForm = form => {
        const value = _.assign({}, props.initialValue, formValue);
        return form
            .map(field => {
                let mappedField = { ...field };
                if (field.controlledVisibility && null == field.visible) {
                    mappedField = { ...field, ...controlledVisibility(value, mappedField, field.controlledVisibility) };
                }
                if (field.controlledDisable && null == field.defaultDisabled) {
                    mappedField = { ...field, ...controlledDisable(value, mappedField, field.controlledDisable) };
                }
                return mappedField;
            })
            .map(field => ({
                ...field,
                defaultValue: field.defaultValue || field.controlledValue || props.initialValue[field.id]
            }));
    };

    const renderSplitForm = (formIndex, formSchema, startIndex, endIndex) => {
        const splitForm = formSchema.slice(startIndex, endIndex);
        return (
            <Form
                renderer={renderer}
                defaultFields={splitForm}
                showValidationBeforeTouched={isFormSubmitted}
                onChange={(value, isValid) => {
                    const newFormValue = { ...formValue, ...value };
                    const newFormValidMap = { ...isFormValidMap, [formIndex]: isValid };

                    setFormValue(newFormValue);
                    setIsFormValidMap(newFormValidMap);

                    props.onChange(
                        newFormValue,
                        _.every(isFormValidMap, isValid => isValid)
                    );
                }}
                validationHandler={field => {
                    if ('duration' === field.type && field.value) {
                        if (!field.value.label) {
                            return 'Field is required';
                        }
                        if (field.value.undefinedOption) {
                            return 'Wrong time format';
                        }
                    }
                    if (props.validationHandler) {
                        return props.validationHandler(field);
                    }
                }}
            />
        );
    };

    const renderSection = (section, index) => {
        const mappedForm = mapForm(section.form);
        return (
            <ExpansionPanel key={index} defaultExpanded={section.defaultExpanded}>
                <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h6">{section.displayName}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            {renderSplitForm(index, mappedForm, 0, section.splitIndex)}
                        </Grid>
                        <Grid item xs={6}>
                            {renderSplitForm(index, mappedForm, section.splitIndex, section.form.length)}
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    };

    return (
        <Modal
            id={props.id}
            isOpen={true}
            title={props.title}
            size="lg"
            draggable
            dialogClass={props.classes.modal}
            onConfirm={confirm}
            onCancel={props.onCancel}
            onClose={props.onCancel}
        >
            <form>{props.sections.map(renderSection)}</form>
        </Modal>
    );
};

DetailsFormModal.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    sections: PropTypes.array.isRequired,
    initialValue: PropTypes.object,
    validationHandler: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(detailsFormModalStyles)(DetailsFormModal);
