const temporaryDiscountsStyles = () => ({
    autoCompleteContainer: {
        marginTop: 8,
        width: '48px'
    },
    filterAutocomplete: {
        width: '12%',
        margin: 8
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    rightActionsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    formContent: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap'
    },
    formContentRow: {
        width: '100%'
    },
    formItem: {
        width: '48%',
        marginRight: 8,
        marginTop: 8,
        marginBottom: 8
    },
    confirmOutlinedButton: {
        alignSelf: 'center',
        height: 'fit-content'
    },
    accordionsForm: {
        '& .MuiExpansionPanelSummary-content': {
            '& p': {
                fontSize: 16,
                fontWeight: 400
            }
        }
    },
    listRow: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    listContainer: {
        width: '50%'
    },
    title: {
        marginTop: '2%'
    },
    accordionTitle: {
        marginTop: 16
    },
    accordion: {
        width: '100%'
    },
    requiredFields: {
        display: 'flex',
        flexDirection: 'column',
        '& p': {
            fontSize: '10px',
            margin: 0,
            color: 'red'
        }
    },
    cellRightAlign: {
        textAlign: 'center'
    },
    checkboxesContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '12px',
        alignItems: 'center',
        '& span': {
            color: 'rgba(0, 0, 0, 0.87)'
        },
        '& label': {
            fontFamily: 'Roboto, Arial, Sans-serif',
            marginRight: 10
        },
        '& :last-child': {
            marginRight: 0
        }
    }
});

export default temporaryDiscountsStyles;
