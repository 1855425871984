import { makeStyles, FormControlLabel, Checkbox, FormControl } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import React from 'react';
import { coursesStyles } from '../styles';
import PropTypes from 'prop-types';

function OnlineBookingTab({ control, unregister }) {
    const classes = makeStyles(coursesStyles)();

    return (
        <div className={classes.formContent}>
            <div className={classes.formContentRow}>
                <FormControl className={classes.formItem} variant="outlined">
                    <FormControlLabel
                        disabled={true}
                        className={classes.formItem}
                        label="Allow online booking"
                        control={
                            <Controller
                                control={control}
                                name="allowOnlineBooking"
                                render={({ onChange, onBlur, value, ref }) => (
                                    <Checkbox
                                        onBlur={onBlur}
                                        disabled={true}
                                        onChange={e => {
                                            onChange(e.target.checked);

                                            if (!e.target.checked) {
                                                unregister('onlineName');
                                                unregister('onlineDescription');
                                            }
                                        }}
                                        checked={value}
                                        inputRef={ref}
                                        defaultValue={value || false}
                                    />
                                )}
                            />
                        }
                    />
                    <label>Coming soon</label>
                </FormControl>
            </div>
            {/*             <div className={classes.formContentRow}>
                <Button variant="contained" component="label" className={classes.formItem}>
                    Upload image
                    <input ref={register} name="onlineImage" type="file" style={{ display: 'none' }} />
                </Button>
            </div> */}
        </div>
    );
}

OnlineBookingTab.propTypes = {
    register: PropTypes.any
};

export default OnlineBookingTab;
