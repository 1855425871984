import 'date-fns';
import React, { useState, useEffect, useCallback } from 'react';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import PhoneNumberInput from 'material-ui-phone-number';
import Modal from '../common/Modal';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Controller, useForm } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';
import { clinicDetailsModalStyles } from './styles';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';

import { createClinic, getClinic, updateClinic } from '../../collums-components/api/ClinicApi';
import { DEFAULT_PHONE_COUNTRY, PREFERRED_PHONE_COUNTRIES } from '../../collums-constants';

import LocationMap from './LocationMap/LocationMap';
import { isValidPhone } from '../../collums-components/helpers/validPhoneNumber';
import SimpleCountriesInput from '../../collums-components/form/SimpleCountriesInput';
import AddressApi from '../../collums-components/api/AddressApi';
import { isArray } from 'lodash';
import { getCoord } from '../../api/GoogleMapsApi';
import { Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import GoogleAutocompleteField from '../common/GoogleAutocompleteField/GoogleAutocompleteField';

const Accordion = ({ classes, children, title }) => (
    <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionTitle}>
            <Typography>{title}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
    </ExpansionPanel>
);

Accordion.propTypes = {
    children: PropTypes.any,
    title: PropTypes.string
};

function ClinicDetailsModal({ open, onClose, clinicId, classes, countryCode, openReminderPopup }) {
    const globalStyles = makeStyles(modalsButtonStyles)();

    const [clinic, setClinic] = useState({});
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState({ label: '', value: '' });
    const [showLocationMap, setShowLocationMap] = useState(false);
    const [customCoordinates, setCustomCoordinates] = useState({});

    const [isInvalid, setIsInvalid] = useState({
        phone: false,
        zipcode: false
    });

    const { handleSubmit, register, errors, setValue, getValues, control, reset, watch } = useForm();

    const currentClinic = localStorage.getItem('currentClinic');

    async function getCountriesList() {
        const res = await AddressApi.getCountries(currentClinic);

        if (res && isArray(res)) {
            setCountries(
                res.filter(item => {
                    return !!item;
                })
            );
        }
    }

    useEffect(() => {
        register('publicPhone');
    }, [register]);

    const onSubmit = async values => {
        const { accountName, publicEmail, publicPhone, lateCancellationChargeValue, lateCancellationPeriod } = values;

        const { lateCancellationChargeType, address } = clinic;

        const data = {
            address,
            accountName,
            publicEmail,
            publicPhone,
            lateCancellationChargeType: lateCancellationChargeType ? lateCancellationChargeType.toUpperCase() : 'NONE',
            lateCancellationChargeValue,
            lateCancellationPeriod
        };

        if (customCoordinates.lat && customCoordinates.lng) {
            data.address = {
                ...data.address,
                latitude: customCoordinates.lat,
                longitude: customCoordinates.lng
            };
        } else if (!hasCoordinates()) {
            const googleMapsQueryAddressValues = [address.line1, address.city, address.country];
            const googleMapsQueryAddress = googleMapsQueryAddressValues.join(',').replaceAll(' ', '+');
            const coords = await getCoord(googleMapsQueryAddress);

            data.address = {
                ...data.address,
                latitude: coords?.lat || 51.512545,
                longitude: coords?.lng || -0.08284
            };
        }

        if (isInvalid.phone) {
            toastr.error('Invalid phone number');
            return;
        }

        if (!data.address?.postcode || isInvalid.zipcode) {
            toastr.error('Invalid postcode');
            return;
        }

        if (!data.address?.line1) {
            toastr.error('Invalid address 1');
            return;
        }

        if (!data.address?.country) {
            toastr.error('Invalid country');
            return;
        }

        if (!clinicId) {
            try {
                await createClinic(data);
                toastr.success(`Clinic ${data.accountName} successfully created`);

                if (openReminderPopup) {
                    openReminderPopup();
                }

                onClose();
            } catch (err) {
                toastr.error('An error has occurred');
            }
        } else {
            try {
                await updateClinic(clinicId, data);
                toastr.success(`Clinic ${data.accountName} successfully updated`);
                onClose();
            } catch (err) {
                toastr.error('An error has occurred');
            }
        }
    };

    const handleAddressSearch = fullAddress => {
        const updatedAddress = {
            ...clinic?.address,
            ...(fullAddress.address && { line1: fullAddress.address }),
            city: fullAddress.city ? fullAddress.city : '',
            country: fullAddress.country ? fullAddress.country : ''
        };

        if (Object.keys(fullAddress).length === 0) {
            setIsInvalid({
                ...isInvalid,
                zipcode: true
            });
            toastr.error('Invalid address');
            return;
        }
        setIsInvalid({
            ...isInvalid,
            zipcode: false
        });

        updateValue({
            ...clinic,
            address: {
                ...updatedAddress,
                country: clinic.address.country
            }
        });
    };

    const updateValue = newValue => setClinic({ ...clinic, ...newValue });

    useEffect(() => {
        async function fetch() {
            const data = await getClinic(clinicId);

            setIsInvalid({
                phone: data.publicPhone ? false : true,
                zipcode: false
            });

            //Should update is the trigger for the field array to update, as
            //setValue doesnt work on field array, neither onChange functions

            setClinic({
                ...data,
                shouldUpdate: false
            });

            reset();
        }

        if (clinicId) {
            fetch();
        }

        getCountriesList();
        //eslint-disable-next-line
    }, [clinicId]);

    useEffect(() => {
        if (clinic && clinic?.address?.country && countries) {
            const selected = countries.find(country => country.label === clinic.address.country);
            if (selected) {
                setCountry(selected);
            }
        }
    }, [clinic, countries]);

    useEffect(() => {
        const requiredFields = Object.keys(errors);

        if (requiredFields.length !== 0) {
            const messages = requiredFields.map(field => errors[field].message);

            toastr.error('Missing fields', messages.join(','));
        }
    }, [errors]);

    const hasCoordinates = useCallback(() => {
        return !!(clinic?.address?.latitude && clinic?.address?.longitude);
    }, [clinic.address]);

    const hasPostcode = useCallback(() => {
        return !!(getValues('postcode') || clinic?.address?.postcode);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clinic.address, getValues, watch]);

    const updateCoordinatesHandler = async () => {
        const googleMapsQueryAddressValues = [
            getValues('line1') || clinic?.address?.line1,
            getValues('city') || clinic?.address?.city,
            getValues('country') || country?.label || '',
            getValues('postcode') || clinic?.address?.postcode
        ];

        const googleMapsQueryAddress = googleMapsQueryAddressValues.join(',').replaceAll(' ', '+');
        const coords = await getCoord(googleMapsQueryAddress);

        if (Object.keys(customCoordinates).length) {
            setCustomCoordinates({});
        }

        updateValue({
            address: {
                ...clinic.address,
                latitude: coords?.lat || clinic?.address?.lat || 51.512545,
                longitude: coords?.lng || clinic?.address?.lng || -0.08284
            }
        });
    };

    const getCountryCode = useCallback(() => {
        if (!clinicId || (clinic?.id && !clinic?.publicPhone)) {
            return countryCode;
        }

        return countryCode || DEFAULT_PHONE_COUNTRY;
    }, [clinicId, clinic.id, clinic.publicPhone, countryCode]);

    const zipcodeLookup = async e => {
        const res = await AddressApi.addressLookup(e.target.value);
        handleAddressSearch(res);
    };

    return (
        <Modal
            isOpen={open}
            onCancel={() => {
                onClose();
                setClinic({});
            }}
            onClose={() => {
                onClose();
                setClinic({});
            }}
            size="md"
            title={!clinicId ? 'Add new location' : `View/Edit ${clinic.accountName}`}
            id="ClinicFormModal"
        >
            <form
                className={classes.form}
                onSubmit={e => {
                    e.preventDefault();
                    handleSubmit(onSubmit)();
                }}
            >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    {/** General clinic details */}
                    <Accordion classes={classes}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item container direction="row" spacing={4}>
                                <Grid item container direction="column" spacing={4} md={6}>
                                    <Grid item>
                                        <FormControl>
                                            <TextField
                                                id="account-name-input"
                                                label="Location Name *"
                                                variant="outlined"
                                                value={clinic.accountName || ''}
                                                onChange={({ target }) => updateValue({ accountName: target.value })}
                                                name="accountName"
                                                inputRef={register({ required: 'Name' })}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item>
                                        <FormControl>
                                            <TextField
                                                id="line2-input"
                                                name="line2"
                                                label="Address 2"
                                                variant="outlined"
                                                value={clinic.address ? clinic.address.line2 : ''}
                                                onChange={({ target }) =>
                                                    updateValue({
                                                        address: { ...clinic.address, line2: target.value }
                                                    })
                                                }
                                                inputProps={{ style: { textTransform: 'capitalize' } }}
                                                inputRef={register()}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item>
                                        <FormControl>
                                            <TextField
                                                id="city-input"
                                                name="city"
                                                label="City *"
                                                variant="outlined"
                                                value={clinic.address ? clinic.address.city : ''}
                                                onChange={({ target }) =>
                                                    updateValue({
                                                        address: { ...clinic.address, city: target.value }
                                                    })
                                                }
                                                inputProps={{ style: { textTransform: 'capitalize' } }}
                                                inputRef={register({ required: 'City' })}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item>
                                        <FormControl>
                                            <SimpleCountriesInput
                                                name="country"
                                                value={country}
                                                label=""
                                                onChange={value => {
                                                    if (!value) {
                                                        value = { label: '', value: '' };
                                                    }
                                                    setCountry(value);
                                                    updateValue({
                                                        address: { ...clinic.address, country: value.label }
                                                    });
                                                }}
                                                required={true}
                                                className={`${classes.font} ${classes.autocomplete}`}
                                            />
                                        </FormControl>
                                    </Grid>

                                    {/* <Grid item>
                                    <FormControl>
                                        <TextField
                                            id="public-phone-input"
                                            name="publicPhone"
                                            label="Public Phone"
                                            variant="outlined"
                                            value={clinic.publicPhone || ''}
                                            onChange={({ target }) => updateValue({ publicPhone: target.value })}
                                            inputRef={register({
                                                required: ' Public Phone'
                                            })}
                                        />
                                        {errors.phone && errors.phone.message}
                                    </FormControl>
                                </Grid> */}
                                    <Grid item>
                                        <FormControl className={classes.fieldContainer}>
                                            <PhoneNumberInput
                                                id="public-phone-input"
                                                name="publicPhone"
                                                variant="outlined"
                                                value={clinic.publicPhone || ''}
                                                label="Public Facing Phone *"
                                                onChange={(value, phoneInfo) => {
                                                    if (value === undefined) {
                                                        value = clinic.publicPhone;
                                                    }

                                                    setIsInvalid({
                                                        ...isInvalid,
                                                        phone: !isValidPhone(value, phoneInfo.countryCode)
                                                    });
                                                    updateValue({ publicPhone: value });
                                                    setValue('publicPhone', value);
                                                }}
                                                error={isInvalid.phone}
                                                inputProps={register('publicPhone', {
                                                    validate: value => {
                                                        if (value === undefined) {
                                                            value = clinic.publicPhone;
                                                        }

                                                        return (
                                                            (value !== undefined && value.length >= 5) || 'Public Phone'
                                                        );
                                                    }
                                                })}
                                                preferredCountries={PREFERRED_PHONE_COUNTRIES}
                                                defaultCountry={getCountryCode()}
                                            />
                                            {errors.phone && errors.phone.message}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item container direction="column" spacing={4} md={6}>
                                    <Grid item>
                                        <Controller
                                            control={control}
                                            rules={register({ required: 'Address 1' })}
                                            name="line1"
                                            defaultValue={clinic.address ? clinic.address.line1 : ''}
                                            render={({ onChange, value }) => {
                                                return (
                                                    <FormControl>
                                                        <GoogleAutocompleteField
                                                            onChange={place => {
                                                                onChange(place?.value || '');

                                                                const getProperty = property => {
                                                                    return place?.[property] ? place[property] : '';
                                                                };

                                                                let countryToUpdate = clinic?.address?.country;

                                                                if (!clinic?.address?.country) {
                                                                    if (place?.country) {
                                                                        const localCountry = getProperty('country');
                                                                        const countryFromList = countries.find(
                                                                            el => el?.label === localCountry
                                                                        );

                                                                        if (countryFromList?.value) {
                                                                            countryToUpdate = localCountry;
                                                                            setCountry(countryFromList);
                                                                        }
                                                                    }
                                                                }

                                                                // update form state
                                                                updateValue({
                                                                    address: {
                                                                        ...clinic.address,
                                                                        line1: place?.value,
                                                                        country: countryToUpdate,
                                                                        city: clinic?.address?.city
                                                                            ? clinic?.address?.city
                                                                            : getProperty('town'),
                                                                        postcode: clinic?.address?.postcode
                                                                            ? clinic?.address?.postcode
                                                                            : getProperty('postcode'),
                                                                        county: clinic?.address?.county
                                                                            ? clinic?.address?.county
                                                                            : getProperty('county')
                                                                    }
                                                                });
                                                            }}
                                                            value={value}
                                                            detailed
                                                            fieldProps={{
                                                                id: 'line1-input',
                                                                label: 'Address 1 *',
                                                                inputProps: {
                                                                    style: { textTransform: 'capitalize' }
                                                                },
                                                                placeholder: 'Start typing address here',
                                                                shrink: true
                                                            }}
                                                        />
                                                    </FormControl>
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControl>
                                            <TextField
                                                id="line3-input"
                                                name="line3"
                                                label="Address 3"
                                                variant="outlined"
                                                value={clinic.address ? clinic.address.line3 : ''}
                                                onChange={({ target }) =>
                                                    updateValue({ address: { ...clinic.address, line3: target.value } })
                                                }
                                                inputProps={{ style: { textTransform: 'capitalize' } }}
                                                inputRef={register()}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item>
                                        <FormControl>
                                            <TextField
                                                id="county-input"
                                                name="County"
                                                label="County"
                                                variant="outlined"
                                                value={clinic.address ? clinic.address.county : ''}
                                                onChange={({ target }) =>
                                                    updateValue({
                                                        address: { ...clinic.address, county: target.value }
                                                    })
                                                }
                                                inputProps={{ style: { textTransform: 'capitalize' } }}
                                                inputRef={register()}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item>
                                        <FormControl>
                                            <FormControl>
                                                <TextField
                                                    id="postcode-input"
                                                    name="Postcode"
                                                    label="Postcode *"
                                                    value={clinic.address?.postcode || ''}
                                                    onChange={({ target }) => {
                                                        updateValue({
                                                            address: { ...clinic.address, postcode: target.value }
                                                        });
                                                    }}
                                                    onBlur={zipcodeLookup}
                                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                                    variant="outlined"
                                                />
                                            </FormControl>
                                        </FormControl>
                                    </Grid>

                                    <Grid item>
                                        <FormControl>
                                            <TextField
                                                id="public-email-input"
                                                name="publicEmail"
                                                label="Public Facing Email *"
                                                variant="outlined"
                                                value={clinic.publicEmail || ''}
                                                onChange={({ target }) => updateValue({ publicEmail: target.value })}
                                                inputRef={register({
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: 'invalid email address'
                                                    },
                                                    required: ' Public Facing Email'
                                                })}
                                            />
                                            {errors.email && errors.email.message}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container direction="row" spacing={4}>
                                <Grid item container direction="column" md={12}>
                                    {!showLocationMap && (
                                        <Grid item>
                                            <Button
                                                className={`${classes.locationMapButton}`}
                                                onClick={() => setShowLocationMap(prevState => !prevState)}
                                                disabled={!hasCoordinates() && !hasPostcode()}
                                            >
                                                {showLocationMap ? 'Hide Location Map' : 'See map location'}
                                            </Button>
                                            <Tooltip
                                                title={
                                                    <Typography style={{ fontSize: 14 }}>
                                                        Click to see where your location will be shown on Google Maps at
                                                        the end of the online booking journey.
                                                    </Typography>
                                                }
                                                aria-label="help"
                                            >
                                                <HelpIcon style={{ fontSize: 18 }} />
                                            </Tooltip>
                                        </Grid>
                                    )}
                                    {showLocationMap && (
                                        <Grid item container direction="column" spacing={2}>
                                            <Grid item>
                                                <LocationMap
                                                    location={clinic.address}
                                                    newLocation={customCoordinates}
                                                    onChange={coordinates => setCustomCoordinates(coordinates)}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    className={`${classes.locationMapButton}`}
                                                    onClick={updateCoordinatesHandler}
                                                >
                                                    Update coordinates
                                                </Button>
                                                <span> </span>
                                                <span>or click on the map to change the location of the pin</span>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Accordion>
                </MuiPickersUtilsProvider>
                <div className={globalStyles.buttonsContainer}>
                    <Button className={globalStyles.cancelButton} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button className={globalStyles.confirmButton} type="submit" variant="outlined">
                        Save
                    </Button>
                </div>
            </form>
        </Modal>
    );
}

ClinicDetailsModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    clinicId: PropTypes.string,
    classes: PropTypes.object.isRequired,
    countryCode: PropTypes.string,
    openReminderPopup: PropTypes.func
};

export default withStyles(clinicDetailsModalStyles)(ClinicDetailsModal);
