import React from 'react';
import PropTypes from 'prop-types';

import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormControl, InputLabel, MenuItem, TextField } from '@material-ui/core';

const numberOpts = Array(20)
    .fill(0)
    .map((value, index) => {
        if (index === 0) return 5;
        else return (index + 1) * 5;
    });

function RateTypeInput({ className, onChange, value, label, disabled }) {
    return (
        <div className={className} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <FormControl variant="outlined">
                <InputLabel>{label}</InputLabel>
                <Select
                    disabled={disabled}
                    onChange={e => onChange({ ...value, type: e.target.value })}
                    value={value.type || '%'}
                >
                    <MenuItem value="Amount">Amount</MenuItem>
                    <MenuItem value="%">%</MenuItem>
                </Select>
            </FormControl>
            <FormControl style={{ marginBottom: 10, marginLeft: 12 }}>
                <Autocomplete
                    disabled={disabled}
                    options={value.type === 'Amount' ? [] : numberOpts}
                    getOptionSelected={option => option === value.value}
                    getOptionLabel={option => option.toString()}
                    value={value.value}
                    freeSolo
                    onChange={(e, newValue) => onChange({ ...value, value: newValue })}
                    onInputChange={(e, newValue) => onChange({ ...value, value: newValue })}
                    renderInput={params => (
                        <TextField {...params} margin="dense" variant="outlined" fullWidth tabIndex={2} />
                    )}
                />
            </FormControl>
        </div>
    );
}

RateTypeInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string
};

export default RateTypeInput;
