import React from 'react';
import PropTypes from 'prop-types';

import { withStyles, Checkbox, FormControlLabel } from '@material-ui/core';
import { courseCategoryDetailsModalStyles } from '../styles';
import { getLocationItem } from '../../../collums-constants/utils';

function CommissionTab({ register, category, clinic, isFromOrg, classes }) {
    return (
        <div className={classes.formContent}>
            <FormControlLabel
                label="Allow commission"
                control={
                    <Checkbox
                        defaultChecked={
                            category
                                ? isFromOrg
                                    ? category.allowCommission
                                    : getLocationItem(category, clinic).allowCommission
                                : true
                        }
                        inputProps={{ name: 'allowCommission' }}
                        inputRef={register}
                    />
                }
            />
        </div>
    );
}

CommissionTab.propTypes = {
    classes: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    category: PropTypes.object
};

export default withStyles(courseCategoryDetailsModalStyles)(CommissionTab);
