import React from 'react';
import { Box, Button, Typography, withStyles, makeStyles } from '@material-ui/core';
import Modal from '../../../common/Modal';
import { PropTypes } from 'prop-types';

import { modalsButtonStyles } from '../../../../collums-constants/styles/stylesheets/buttonsStyles';
import { confirmModalStyles } from '../../../common/styles';

const PinSaveModal = ({ classes, onConfirm, onCancel }) => {
    const buttonClasses = makeStyles(modalsButtonStyles)();

    return (
        <>
            <Modal
                id="pin-save-modal"
                isOpen
                title="PIN changed"
                size="xs"
                hideTitle
                onCancel={onCancel}
                onClose={onCancel}
            >
                <Box container spacing={4}>
                    <Typography
                        variant="h4"
                        className={`${classes.centered} ${classes.marginBottom} ${classes.defaultFont}`}
                    ></Typography>
                    <Typography className={`${classes.centered} ${classes.defaultFont}`}>
                        New PIN will be saved and emailed to staff member when you click Save.
                    </Typography>
                </Box>
                <div className={buttonClasses.buttonsContainer}>
                    <Button
                        className={buttonClasses.confirmButton}
                        onClick={() => {
                            onConfirm();
                        }}
                    >
                        Confirm
                    </Button>
                </div>
            </Modal>
        </>
    );
};

PinSaveModal.propTypes = {
    classes: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default withStyles(confirmModalStyles)(PinSaveModal);
