export const productsViewStyles = theme => ({
    header: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexWrap: 'wrap',
        '& .MuiCheckbox-root:nth-of-type(1)': {
            paddingLeft: 0
        }
    },
    addButton: {
        '& svg': {
            fontSize: 32
        }
    },
    table: {
        '& tr td': {
            height: 48,
            paddingTop: 0,
            paddingBottom: 0
        },
        '& tr[level="1"]': {
            background: theme.palette.grey['100']
        },
        '& tr[level="1"] td:nth-of-type(2)': {
            paddingLeft: 32,
            paddingRight: 0
        },
        '& tr[level="2"] td:nth-of-type(2)': {
            paddingLeft: 48
        }
    },
    tooltip: {
        fontSize: 14,
        fontFamily: 'Roboto, Arial, Sans-serif'
    }
});

export const productsFormStyles = () => ({
    outlinedButton: {
        padding: '9px 15px',
        alignSelf: 'center',
        height: 'fit-content'
    },
    accordionsForm: {
        '& .MuiExpansionPanelSummary-content': {
            '& p': {
                fontSize: 16,
                fontWeight: 400
            }
        }
    }
});

export const accordionFormStyles = () => ({
    sectionLabelFirst: {
        marginBottom: 8
    },
    sectionLabelNext: {
        marginTop: 16,
        marginBottom: 8
    },
    lastSection: {
        marginBottom: 32
    },
    dynamicAddField: {
        width: '100%'
    },
    dynamicAddFieldIconButton: {
        width: '100%',
        border: '1px dashed rgba(0, 0, 0, .6)',
        borderRadius: 0
    },
    formContent: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap'
    },
    formContentRow: {
        width: '100%'
    },
    formItem: {
        width: '48% !important',
        marginRight: 8,
        marginTop: 8,
        marginBottom: 8,
        paddingRight: 0
    },
    colourInput: {
        width: '10%'
    },
    rowInputItems: {
        width: '50%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    listRow: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        margin: '0 20%'
    },
    outlinedButton: {
        height: 38,
        padding: '6px 14px',
        alignSelf: 'center',
        marginTop: 8
    },
    volumeValue: {
        '& .MuiFormControl-marginDense': {
            margin: 0
        },
        width: '100% !important'
    },
    barcodeInput: {
        marginTop: 16,
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 0
        }
    },
    removeBarcode: {
        padding: '4px'
    },
    removeIcon: {
        width: '0.8928571428571429rem',
        height: 'auto'
    },
    marginTop8: {
        marginTop: 8
    }
});
