import React, { useEffect, useRef, useState } from 'react';
import { Button, Grid, TextField, Typography, withStyles, makeStyles } from '@material-ui/core';
import { alertFormStyle } from '../style';
import SMSEditor from './components/SMSEditor';
import { toastr } from 'react-redux-toastr';

import { modalsButtonStyles } from '../../../collums-constants/styles/stylesheets/buttonsStyles';
import AlertsApi from '../../../collums-components/api/AlertsApi';
import OrganisationsApi from '../../../api/OrganisationsApi';
import { getOrganisationPhoneCode } from '../../../collums-components/helpers';

const SmsTab = ({ classes, alert, closeAccordion, setHaveSMSUnsavedChanges }) => {
    const globalStyles = makeStyles(modalsButtonStyles)();

    const editorRef = useRef();
    const [loadingUpload, setLoadingUpload] = useState({ visible: false, status: 'loading' });
    const [message, setMessage] = useState(alert ? alert.sms.content : '');
    const [smsList, setSmsList] = useState(alert?.sms?.recipients || '');
    const [selectionPosition, setSelectionPosition] = useState(0);
    const [smsUsage, setSMSUsage] = useState(alert?.sms?.smsUsage || '');

    const [organisation, setOrganisation] = useState(null);

    useEffect(() => {
        const getOrganisation = async () => {
            return OrganisationsApi.getOrganisation();
        };

        if (!organisation) {
            getOrganisation().then(org => {
                setOrganisation(org);
            });
        }

        // eslint-disable-next-line
    }, []);

    const saveData = async () => {
        if (!message) {
            toastr.error('Missing content');
            return;
        }
        if (!smsUsage || smsUsage === '0') {
            toastr.error('Invalid SMS usage');
            return;
        }
        if (!smsList && smsList !== '') {
            toastr.error('Missing recipient(s)');
            return;
        }
        try {
            const data = {
                name: alert.name,
                sms: {
                    content: message,
                    recipients: smsList,
                    smsUsage: smsUsage.toString()
                },
                sentAlert: alert.sentAlert,
                description: alert.description,
                email: alert.email,
                clinic: alert.clinic
            };
            await AlertsApi.updateAlert(alert.id, 'sms', data);
            toastr.success('Alert sms successfully updated!');
            setHaveSMSUnsavedChanges(false);
            closeAccordion();
        } catch (err) {
            if (typeof err === 'object') {
                if (err.data && err.data.message) {
                    toastr.error(err.data.message);
                    return;
                }
            }
            toastr.error('Something went wrong');
        }
    };

    return (
        <div style={{ display: 'block', width: '100%' }}>
            <Grid container xs={12} spacing={2} style={{ marginBottom: 20 }}>
                <Grid container item xs={12} alignItems="center">
                    <Grid item className={classes.defaultLabelWidth}>
                        <Typography>Format:</Typography>
                    </Grid>
                    <Grid item className={classes.defaultLabelWidth}>
                        <Typography>SMS</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <SMSEditor
                setLoadingUpload={setLoadingUpload}
                loadingUpload={loadingUpload}
                editorRef={editorRef}
                smsMessage={message}
                setSMSMessage={setMessage}
                haveUnsavedChanges={() => setHaveSMSUnsavedChanges(true)}
                setSelectionPosition={setSelectionPosition}
                selectionPosition={selectionPosition}
            />
            <div className={classes.flexContainer}>
                <Typography>Send when SMS usage =</Typography>
                <TextField
                    style={{ marginLeft: 5, marginRight: 5, width: 75 }}
                    label=""
                    placeholder=""
                    variant="outlined"
                    value={smsUsage}
                    onChange={e => {
                        if (isNaN(e.target.value || 0)) {
                            return;
                        }
                        if (!e.target.value) {
                            setSMSUsage('');
                            return;
                        }

                        setSMSUsage(Number.parseInt(e.target.value).toString());
                    }}
                />
                <Typography>credits.</Typography>
            </div>
            <div className={classes.flexContainer}>
                <Typography>SMS recipient(s):</Typography>
                <TextField
                    style={{ marginLeft: 20, marginRight: 10, width: '50%' }}
                    placeholder={`Number must start with ${getOrganisationPhoneCode(
                        organisation
                    )} (omit next 0). Separate numbers with commas`}
                    variant="outlined"
                    value={smsList}
                    className={`${classes.input} usePlaceholderTextField`}
                    onChange={e => {
                        setSmsList(e.target.value);
                    }}
                />
            </div>
            <div className={globalStyles.buttonsContainer}>
                <Button
                    className={globalStyles.cancelButton}
                    onClick={() => {
                        closeAccordion();
                        setHaveSMSUnsavedChanges(false);
                    }}
                >
                    Cancel
                </Button>
                <Button className={globalStyles.confirmButton} onClick={saveData}>
                    Save
                </Button>
            </div>
        </div>
    );
};

export default withStyles(alertFormStyle)(SmsTab);
