export const form = () => ({
    inputField: {
        margin: '0.6rem 0'
    },
    autocompleteTextfield: {
        width: '100%',
        '& .MuiOutlinedInput-adornedEnd': {
            height: '38px',
            padding: '0 4px'
        }
    },
    modal: {
        '& .MuiDialog-paper': {
            backgroundColor: 'white'
        }
    }
});
