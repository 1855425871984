import React, { useEffect, useState } from 'react';
import Api from '../../api/RoomApi';

import DetailModal from '../details/detailedModal';
import RoomFormModal from './form/RoomFormModal';

import ListPagination from '../common/ListPagination';
import View from '../common/View';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import InfoIcon from '@material-ui/icons/Info';
import ConfirmModal from '../common/ConfirmModal';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import { toastr } from 'react-redux-toastr';
import { useLocation } from 'react-router-dom';
import { STATUS_ACTIVE } from '../../collums-constants';
import { Typography } from '@material-ui/core';

const RoomsView = ({ clinic, isFromOrg }) => {
    const [roomEdit, setRoomEdit] = useState(null);
    const [rooms, setRooms] = useState({
        items: [],
        size: 25
    });
    const [room, setRoomDetailed] = useState({});
    const [formVisibility, setFormVisibility] = useState(false);
    const [detailVisibility, setDetailVisibility] = useState(false);
    const [archived, setArchived] = useState(false);
    const [inactive, setInactive] = useState(true);

    const [confirmModal, setConfirmModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState('');

    const [page, setPage] = useState(0);

    const [isLoading, setIsLoading] = useState(false);

    const [rowsPerPage, setRowsPerPage] = useState(rooms.size);

    const [nameFilter, setNameFilter] = useState('');
    const location = useLocation();

    const toDo = () => {
        if (modalTitle === 'Archive room') handleArchive();
        if (modalTitle === 'Room deactivation') handleActive();
    };

    const openArchive = async data => {
        setRoomDetailed(data);
        const content = (
            <Typography className="text-align-center">
                Are you sure you want to {data.archived ? 'unarchive' : 'archive'} this room?{' '}
                {!data.archived ? (
                    <>
                        <br />
                        <br />
                        This room will no longer be available for new bookings. Existing bookings in this room will
                        remain.
                        <br />
                        All service connections will be lost if you continue.
                    </>
                ) : (
                    ''
                )}
            </Typography>
        );

        openConfirmModal('Archive room', content);
    };

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setModalTitle('');
        setModalContent();
    };

    const openConfirmModal = (title, component) => {
        setConfirmModal(true);
        setModalTitle(title);
        setModalContent(component);
    };

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const id = urlParams.get('id');

        if (id) setDetailVisibility(id);
    }, []);

    const handleFormModalVisibility = bool => {
        setRoomEdit(null);
        setFormVisibility(bool);
    };
    const handleDetailModalVisibility = id => setDetailVisibility(id);

    const handleUpdateData = async () => await ApiCall(0, rowsPerPage, '', true);

    const handleEdit = data => {
        setRoomEdit(data);
        setFormVisibility(true);
    };

    const handleAddItem = async data => {
        if (data.description === undefined) data.description = '';
        try {
            await Api.addRoom(data);
            handleUpdateData();
            setFormVisibility(false);
            toastr.success(`Room ${data.name} successfully created`);
            await ApiCall(0, 15, '', true);
            return true;
        } catch (err) {
            if (typeof err === 'object') {
                if (err.data && err.data.message) {
                    toastr.error(err.data.message);
                    return false;
                }
            }
            toastr.error('Something went wrong');
            return false;
        }
    };

    const handleUpdateItem = async data => {
        const id = data.id;
        delete data.id;

        try {
            // Should not be able to update a room's location
            delete data.clinic;

            await Api.updateRoom(id, data);
            setFormVisibility(false);
            toastr.success(`Room ${data.name} successfully updated`);
            await ApiCall(0, 15, '', true);
            return true;
        } catch (err) {
            if (typeof err === 'object') {
                if (err.data && err.data.message) {
                    toastr.error(err.data.message);
                    return false;
                }
            }
            toastr.error('Something went wrong');
            return false;
        }
    };

    /*  const handleDelete = async id => {
        try {
            await Api.deleteRoom(id).then(() => {
                toastr.success('Room successfully deleted');
            });
            return true;
        } catch (err) {
            return false;
        }
    }; */

    const handleActive = async value => {
        const data = value || room;
        if (!(data || {}).id) return;
        if (data.archived) {
            toastr.error(`Cannot change room ${data.name}, because this room is archived`);
            return;
        }
        try {
            await Api.changeRoomActive(data.id);
            ApiCall(0, rowsPerPage, '', true);
            toastr.success(`Room ${data.name} successfully updated`);
        } catch (err) {
            if (typeof err === 'object') {
                if (err.data && err.data.message) {
                    toastr.error(err.data.message);
                    return;
                }
            }
            toastr.error('Something went wrong');
        }
    };

    const activeCall = async room => {
        if (room.active !== STATUS_ACTIVE) {
            handleActive(room);
            return;
        }

        setRoomDetailed(room);
        openConfirmModal(
            'Room deactivation',
            <Typography className="text-align-center">
                This room will no longer be available for new bookings. Existing bookings in this room will remain.
                <br />
                All service connections will be remembered and restored if/when you reactivate this room
            </Typography>
        );
    };

    const handleArchive = async () => {
        const id = room.id;
        if (!id) return;

        try {
            await Api.archiveRoom(id).then(res => {
                toastr.success(`Room ${res.name} successfully ${res.archived ? 'archived' : 'unarchived'}`);
                handleUpdateData();
            });
        } catch (err) {
            if (typeof err === 'object') {
                if (err.data && err.data.message) {
                    toastr.error(err.data.message);
                    return;
                }
            }
            toastr.error('Something went wrong');
        }
    };

    const handleDetailed = async (id, data) => {
        await setRoomDetailed(data);
        setDetailVisibility(true);
    };

    const handleDuplicate = async (id, data) => {
        const names = rooms.items
            .map(item => item.name)
            .filter(
                name =>
                    name.startsWith(
                        data.name.slice(0, data.name.indexOf(' -') >= 0 ? data.name.indexOf(' -') : data.name.length)
                    ) || name === data.name
            );
        const name = data.name + ' - Copy'.repeat(names.length);
        const { active, description, tags } = data;
        handleAddItem({ active, description, name, tags });
    };

    const ApiCall = async (skip, limit, queryParam = '', refreshing = false) => {
        try {
            setIsLoading(true);
            if (!queryParam && nameFilter) queryParam += `nameFilter=${nameFilter}`;
            if (queryParam.indexOf('nameFilter') > -1) {
                const splitByFilterName = queryParam.split('nameFilter=')[1];
                if (splitByFilterName) {
                    const filterName = splitByFilterName.split('&')[0];
                    setNameFilter(filterName);
                }
            } else setNameFilter('');
            if (refreshing && !queryParam) {
                queryParam = `archived=${archived}&inactive=${inactive}`;
            }
            if (queryParam.indexOf('archived=false') >= 0) setArchived(false);
            else setArchived(true);
            if (queryParam.indexOf('inactive=false') >= 0) setInactive(false);
            else setInactive(true);
            if (clinic && !clinic.id) {
                clinic.id = location.pathname.split('/')[2];
            }
            if (clinic?.id && queryParam.length && queryParam.indexOf('clinic=') === -1) {
                queryParam = queryParam + `&clinic=${clinic.id}`;
            }
            const newData = await Api.query(skip, limit, queryParam).then(res => res);
            if (refreshing) {
                setRooms(newData);
                setPage(0);
            }
            return newData.items;
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    };

    const copyAction = (() => {
        if (isFromOrg) {
            return {
                title: 'copy',
                action: handleDuplicate,
                icon: FileCopyIcon
            };
        }
        return false;
    })();

    const actions = [
        {
            title: 'archive',
            action: openArchive
        },
        copyAction,
        {
            title: 'details',
            action: handleDetailed,
            icon: InfoIcon
        } /* ,
        {
            title: 'delete',
            action: handleDelete
        } */
    ].filter(el => el);

    return (
        <>
            <View>
                <ListPagination
                    item={'Rooms'}
                    page={page}
                    setPage={setPage}
                    data={rooms.items || []}
                    maxSize={rooms.size || 10}
                    clinic={clinic.id}
                    defaultColumns={[
                        { title: 'Active', field: 'active' },
                        { title: 'Name', field: 'name', onClick: handleEdit },
                        { title: 'Description', field: 'description' },
                        { title: 'Tags', field: 'tags' }
                    ]}
                    filters={[
                        { name: 'archived', label: 'Show Archived', value: false },
                        { name: 'inactive', label: 'Show Inactive', value: false }
                    ]}
                    actions={actions}
                    ApiCall={ApiCall}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    onCreate={handleFormModalVisibility}
                    nameFilter={true}
                    filterName="nameFilter"
                    highlightArchived={true}
                    activeCall={activeCall}
                />
                {formVisibility && (
                    <RoomFormModal
                        defaultValue={roomEdit}
                        visible={formVisibility}
                        setVisibility={handleFormModalVisibility}
                        handleAddItem={handleAddItem}
                        handleUpdateItem={handleUpdateItem}
                        ApiCall={ApiCall}
                        clinic={clinic}
                    />
                )}
                {!!detailVisibility && (
                    <DetailModal
                        title="Room view"
                        data={room}
                        visible={!!detailVisibility}
                        setVisibility={handleDetailModalVisibility}
                    />
                )}
                {confirmModal && (
                    <ConfirmModal
                        isOpen={confirmModal}
                        setIsOpen={setConfirmModal}
                        onConfirm={toDo}
                        onClose={closeConfirmModal}
                        title={modalTitle}
                        content={modalContent}
                    />
                )}
                <LoadingScreen isVisible={isLoading} />
            </View>
        </>
    );
};

export default RoomsView;
