//eslint-disable-next-line
export default activeOptions => [
    {
        id: 'name',
        name: 'name',
        type: 'text',
        label: 'Name',
        required: true
    },
    {
        id: 'description',
        name: 'description',
        type: 'text',
        label: 'Description',
        required: true
    },
    {
        id: 'tags',
        name: 'tags',
        type: 'text',
        label: 'Tags',
        required: false
    }
];
