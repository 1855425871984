import { Checkbox, InputLabel, makeStyles, FormControlLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import { tagsStyles } from './styles';
import _ from 'lodash';

function ClinicSelector({ options, control, rules, name, defaultValue, currentItem }) {
    const classes = makeStyles(tagsStyles)();
    const clinicLabels = options.map(clinic => clinic.label);

    const renderInput = ({ value, onChange }) => {
        const handleDelete = index => {
            const newTags = [...value];
            const removeIdx = newTags.indexOf(clinicLabels[index]);
            newTags.splice(removeIdx, 1);
            onChange(newTags);
        };
        const hasContent = idx => {
            return currentItem.locations.map(loc => {
                return loc.clinic === options[idx].value && _.isEmpty(loc.rooms) && _.isEmpty(loc.equipments)
                    ? false
                    : true;
            });
        };

        return (
            <div className={`${classes.tagsContainer}`}>
                <InputLabel shrink>Select or add Clinics*</InputLabel>
                {clinicLabels.map((option, index) => {
                    // ---- Find the equivalent index for the option on service location
                    if (!currentItem.locations) {
                        return false;
                    }
                    const contentIndex = currentItem.locations
                        .map((loc, idx) => {
                            return loc.clinic === options[index].value ? idx : false;
                        })
                        .filter(el => el)[0];

                    return (
                        <FormControlLabel
                            key={index}
                            control={
                                <Checkbox
                                    defaultChecked={index === 0 || (hasContent(index)[contentIndex] && contentIndex)}
                                    name="clinics"
                                    value={option}
                                    disabled={clinicLabels.length === 1}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            if (value.find(elm => elm === event.target.value)) return;
                                            onChange([...value, event.target.value || event.target.value]);
                                        } else {
                                            handleDelete(index);
                                        }
                                    }}
                                />
                            }
                            label={option}
                        />
                    );
                })}
            </div>
        );
    };

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue || []}
            rules={rules}
            render={renderInput}
        />
    );
}

ClinicSelector.propTypes = {
    defaultValue: PropTypes.array,
    options: PropTypes.array,
    control: PropTypes.any,
    name: PropTypes.string,
    rules: PropTypes.any
};

export default ClinicSelector;
