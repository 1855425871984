export const servicesViewStyles = theme => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 20,
        alignItems: 'center',
        height: 48,
        flexWrap: 'wrap',
        '& .MuiCheckbox-root:nth-of-type(1)': {
            paddingLeft: 0
        }
    },
    addButton: {
        '& svg': {
            fontSize: 32
        }
    },
    table: {
        width: '100%',
        '& tr th': {
            lineHeight: '1.0714285714285714rem',
            minWidth: '80px'
        },
        '& tr td': {
            '& .MuiBox-root': {
                alignItems: 'center'
            }
        },
        '& tr[level="1"]': {
            background: theme.palette.grey['100']
        },
        '& tr[level="1"] td:nth-of-type(2)': {
            paddingLeft: 32,
            paddingRight: 0
        },
        '& tr[level="2"] td:nth-of-type(2)': {
            paddingLeft: 48
        }
    },
    autocomplete: {
        marginRight: 20,
        width: 200,
        '& .MuiInputBase-root': {
            padding: '2px 8px',
            paddingRight: '45px !important'
        },
        '& .MuiAutocomplete-tag': {
            marginTop: 1
        }
    },
    autocompleteOptions: {
        padding: 0,
        margin: 0.5
    },
    autocompleteChipProps: {
        maxWidth: '72%',
        margin: 1,
        padding: 0,
        fontSize: 11
    },
    autocompleteTextField: {
        width: 200,
        marginRight: 20
    },
    checkboxesContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '12px',
        alignItems: 'center',
        '& span': {
            color: 'rgba(0, 0, 0, 0.87)'
        },
        '& label': {
            fontFamily: 'Roboto, Arial, Sans-serif'
        }
    },
    paginationContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    tablePagination: {
        '& select': {
            lineHeight: 1
        }
    }
});

export const categoryDetailsModalStyles = () => ({
    sectionLabelFirst: {
        marginBottom: 8
    },
    sectionLabelNext: {
        marginTop: 16,
        marginBottom: 8
    },
    lastSection: {
        marginBottom: 32
    },
    expansionTitle: {
        fontSize: 16,
        fontWeight: 400
    },
    leftContainer: {
        '& .MuiInputLabel-formControl': {
            fontSize: 12
        },
        '& .MuiInputBase-input': {
            fontSize: 14
        }
    },
    rightContainer: {
        '& p': {
            fontSize: 14
        },
        '& span': {
            fontSize: 14
        }
    }
});

export const serviceDetailsModalStyles = theme => ({
    rowItem: {
        margin: '0 1%',
        flex: 1
    },
    configureButton: {
        backgroundColor: theme.palette.gray.bold,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.gray.bold
        }
    },
    serviceRowItem: {
        marginLeft: '2%',
        flex: 1
    },
    locationFilter: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    onlineBookingContent: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Roboto, Arial, Sans-serif'
    },
    serviceRowItem80: {
        marginLeft: '2%',
        flex: 0.8
    },
    marginlessRowItem: {
        flex: 1
    },
    horizontallyCenteredRowItem: {
        flex: 1,
        margin: '0 auto'
    },
    link: {
        color: '#3083EC'
    },
    jobRow: {
        alignItems: 'center',
        display: 'flex'
    },
    jobLink: {
        color: '#3083EC',
        textDecoration: 'none',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none'
        },
        cursor: 'pointer'
    },
    accordions: {
        '& .MuiExpansionPanelSummary-content': {
            '& p': {
                fontSize: 16,
                fontWeight: 400
            }
        }
    },
    sectionLabelFirst: {
        marginBottom: 8
    },
    sectionLabelNext: {
        marginTop: 16,
        marginBottom: 8
    },
    lastSection: {
        marginBottom: 32
    },
    commissionCatSelectorContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        position: 'relative',
        '& > p': {
            width: '20%'
        },
        '& > div': {
            width: '80%',
            height: '100%',
            maxWidth: '565px',
            position: 'relative'
        }
    },
    dynamicAddField: {
        width: '100%'
    },
    dynamicAddFieldIconButton: {
        width: '100%',
        border: '1px dashed rgba(0, 0, 0, .6)',
        borderRadius: 0
    },
    formContent: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        fontFamily: 'Roboto, Arial, sans-serif !important'
    },
    onlineBookingContentRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    formItemTooltip: {
        width: '48%',
        marginRight: 8,
        marginTop: 8,
        marginBottom: 8,
        display: 'flex',
        justifyContent: 'center',
        '& svg': {
            marginTop: '1px'
        }
    },
    formContentRow: {
        width: '100%'
    },
    spanStaffLabel: {
        fontSize: 14,
        marginBottom: 10,
        fontFamily: 'Roboto, Arial, Sans-serif'
    },
    clinicLabel: {
        fontSize: 14,
        fontWeight: 500,
        fontFamily: 'Roboto, Arial, Sans-serif'
    },
    formItem: {
        width: '48%',
        marginRight: 8,
        marginTop: 10,
        marginBottom: 8,
        '& label': {
            fontFamily: 'Roboto, Arial, Sans-serif'
        }
    },
    bold: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontWeight: 500,
        fontSize: '14px!important'
    },
    colourInput: {
        width: '10%'
    },
    rowInputItems: {
        width: '50%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    listRow: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    listContainer: {
        width: '50%'
    },
    clinicTitle: {
        marginTop: '2%',
        fontWeight: 500
    },
    accordionTitle: {
        marginTop: 16,
        '& p': {
            fontSize: 14,
            fontWeight: 500
        }
    },
    accordion: {
        width: '100%'
    },
    serviceContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    serviceRow: {
        padding: 8,
        background: '#eaeaea',
        borderRadius: 5,
        marginTop: 4,
        boxShadow: '1px 1px 0.5px 0px #afafaf',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& div': {
            paddingTop: '0!important'
        }
    },
    lateCancellationItem: {
        marginBottom: 8,
        '& div': {
            width: '100%'
        }
    },
    commissionSelector: {
        width: '100%'
    },
    noteText: {
        paddingBottom: '20px'
    },
    commissionInput: {
        width: '60%',
        height: '40px'
    },
    locationsTitle: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 5,
        '& b': {
            fontSize: 14,
            fontWeight: 500,
            fontFamily: 'Roboto, Arial, Sans-serif !important',
            color: 'rgba(0, 0, 0, 0.87)'
        }
    },
    requiredFields: {
        display: 'flex',
        flexDirection: 'column',
        '& p': {
            fontSize: '10px',
            margin: 0,
            color: 'red'
        }
    },
    autocomplete: {
        '& .MuiInputBase-root': {
            padding: '2px 8px'
        }
    },
    autocompleteOptions: {
        padding: 0,
        margin: 0.5
    },
    autocompleteInputProps: {
        fontSize: 14,
        fontWeight: 400,
        width: '100%'
    },
    autocompleteLabelProps: {
        fontSize: 14,
        fontWeight: 400
    },
    isAddOnContainer: {
        display: 'flex',
        alignItems: 'center',
        '& label': {
            marginRight: 5
        }
    },
    categoryTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        '& span': {
            padding: 0
        }
    },
    helpIcon: {
        fontSize: 14,
        marginTop: '-8px',
        marginLeft: '5px'
    },
    selectAllLabel: {
        '&.MuiFormControlLabel-root': {
            margin: '0 !important'
        }
    },
    hidden: {
        display: 'none'
    }
});
export const locationSettingModalStyles = () => ({
    modal: {
        '& .MuiDialog-paper': {
            backgroundColor: '#ffffff'
        },
        '& form': {
            margin: '0px 100px'
        }
    },
    helpIcon: {
        fontSize: 14,
        marginTop: '-8px',
        marginLeft: '5px'
    }
});
