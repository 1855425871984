import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { supplierSyles } from './styles';
import { inputSearchStyles } from '../../collums-constants/styles/stylesheets/inputSearchStyles';
import { buttonsStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import { toastr } from 'react-redux-toastr';
import { withStyles, makeStyles, IconButton, TextField, Box } from '@material-ui/core';
import { Search, Clear } from '@material-ui/icons';

import SuppliersApi from '../../api/suppliersApi';
import StockApi from '../../api/StockApi';

import SuppliersTable from './SuppliersTable';
import DetailModal from './detailModal';

import View from '../common/View';
import FilterSelect from '../common/FilterSelect';
import { useDebounce } from '../../collums-components/helpers/index';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import scrollTop from './../../services/scrollTop';

const toastrError = () => {
    return toastr.error('Oops!', 'Something went wrong!');
};

function SuppliersView({ classes, isFromOrg, clinic, clinics, allClinics }) {
    const [filter, setFilters] = useState({ search: '', brands: '', name: '' });
    const filterDebounce = useDebounce(filter.search, 600);
    const [isModalOpen, setModalOpen] = useState(false);
    const [nameFilterOptions, setNameFilterOptions] = useState([]);
    const [brandFilterOptions, setBrandFilterOptions] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState({});
    const [paginationOptions, setPaginationOptions] = useState({ page: 0, rowsPerPage: 25 });
    const [isLoading, setIsLoading] = useState(false);
    const count = useRef(0);

    const globalSearchStyles = makeStyles(inputSearchStyles)();
    const globalButtonsStyles = makeStyles(buttonsStyles)();

    const refreshList = async searchParams => {
        try {
            setIsLoading(true);
            if (searchParams) {
                if (clinic && !searchParams.clinic) {
                    searchParams.clinic = clinic;
                }
                searchParams.isFromOrg = `${isFromOrg}`;
                const query = buildQueryParams(searchParams);
                const response = await SuppliersApi.search(query);
                setSuppliers([...response]);
            } else {
                const response = await SuppliersApi.getAll(clinic, isFromOrg);
                setSuppliers([...response]);
                setNameFilterOptions([...response]);
            }
            scrollTop();
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    };

    const getBrands = async () => {
        try {
            const response = await StockApi.getAllBrands('null', true);
            setBrandFilterOptions(response);
            return;
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        }
    };

    const buildQueryParams = queryObject => {
        const query = Object.keys(queryObject)
            .map(key => {
                if (queryObject[key]) {
                    return `${key}=${encodeURIComponent(queryObject[key])}`;
                }
                return undefined;
            })
            .filter(el => el);
        return query.join('&');
    };

    const handleChangePage = (event, newPage) => {
        setPaginationOptions({ ...paginationOptions, page: newPage });
    };

    const handleChangeRowsPerPage = event => {
        setPaginationOptions({
            ...paginationOptions,
            page: 0,
            rowsPerPage: parseInt(event.target.value, 25)
        });
    };

    const onModalClose = () => {
        setModalOpen(false);
    };

    const onModalOpen = item => {
        setSelectedSupplier(item);
        setModalOpen(true);
    };

    const onDeleteRow = async id => {
        try {
            await SuppliersApi.delete(id);
            toastr.success('Success', 'Record was successfully deleted');
        } catch {
            toastrError();
        }
    };

    const onAddSupplier = async data => {
        try {
            await SuppliersApi.addNew(data);
            toastr.success('New supplier successfully added');
        } catch (e) {
            toastr.error(e.data.message);
            throw e.data;
        }
    };

    const onEditSupplier = async (id, body) => {
        try {
            await SuppliersApi.update(id, body);
            toastr.success('Supplier successfully edited!');
        } catch {
            toastrError();
        }
    };

    useEffect(() => {
        refreshList();
        getBrands();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        refreshList(filter);
        // eslint-disable-next-line
    }, [filter.brands, filter.name]);

    useEffect(() => {
        refreshList(filter);
        // eslint-disable-next-line
    }, [filterDebounce]);

    useEffect(() => {
        count.current = suppliers.length;
    }, [suppliers.length]);

    return (
        <View>
            <div className={classes.root}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        className={globalSearchStyles.inputSearch}
                        id="seachSupplier"
                        variant="outlined"
                        value={filter.search}
                        onChange={e => {
                            setFilters({ ...filter, search: e.target.value });
                        }}
                        InputProps={{
                            endAdornment: (
                                <>
                                    <IconButton
                                        className={globalSearchStyles.icons}
                                        onClick={() => {
                                            setFilters({ ...filter, search: '' });
                                        }}
                                        disabled={!filter.search}
                                    >
                                        <Clear style={!filter.search ? { color: 'transparent' } : {}} />
                                    </IconButton>
                                    <IconButton
                                        position="end"
                                        onClick={() => refreshList(filter)}
                                        className={globalSearchStyles.icons}
                                    >
                                        <Search />
                                    </IconButton>
                                </>
                            )
                        }}
                    />
                    {(isFromOrg || allClinics.length === 1) && (
                        <IconButton
                            onClick={() => {
                                setSelectedSupplier({});
                                setModalOpen(true);
                            }}
                        >
                            <AddCircleIcon className={globalButtonsStyles.addCircleStyle} />
                        </IconButton>
                    )}
                </div>
                <Box display="flex" flexDirection="row" marginBottom="16px" marginTop="5px">
                    <div>
                        <FilterSelect
                            label="Suppliers"
                            values={nameFilterOptions}
                            onChange={e => {
                                setFilters({ ...filter, name: e.target.value.name });
                            }}
                        />
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <FilterSelect
                            label="Brand"
                            values={brandFilterOptions}
                            onChange={e => {
                                setFilters({ ...filter, brands: e.target.value.name });
                            }}
                        />
                    </div>
                </Box>
                <SuppliersTable
                    suppliers={suppliers}
                    paginationOptions={paginationOptions}
                    count={count}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    onModalOpen={onModalOpen}
                    onDeleteRow={onDeleteRow}
                    refreshList={refreshList}
                />
                {isModalOpen && (
                    <DetailModal
                        clinic={clinic}
                        clinics={clinics}
                        isFromOrg={isFromOrg}
                        isOpen={isModalOpen}
                        onClose={onModalClose}
                        supplier={selectedSupplier}
                        brands={brandFilterOptions}
                        refreshList={refreshList}
                        onAddSupplier={onAddSupplier}
                        onEditSupplier={onEditSupplier}
                        allClinics={allClinics}
                    />
                )}
                {isLoading && <LoadingScreen />}
            </div>
        </View>
    );
}

SuppliersView.propTypes = {
    classes: PropTypes.object
};

export default withStyles(supplierSyles)(SuppliersView);
