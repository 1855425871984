import React from 'react';

import { Controller } from 'react-hook-form';

import { TEMPORARY_DISCOUNTS } from '../../../collums-constants/index';

import { withStyles, Box, FormControlLabel, TextField, RadioGroup, Radio } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import styles from './styles';

function FormGeneral({
    classes,
    register,
    control,
    isFromOrg = false,
    clinics,
    discountValidFrom,
    discountValidTo,
    watch
}) {
    const discountType = Object.values(TEMPORARY_DISCOUNTS.DISCOUNT_TYPES);
    const applyTo = Object.values(TEMPORARY_DISCOUNTS.APPLY);
    const requiredMessage = 'This field is required';
    const watchedValidFrom = watch(isFromOrg ? 'discountValid.from' : 'location.discountValid.from');
    const watchedValidTo = watch(isFromOrg ? 'discountValid.to' : 'location.discountValid.to');

    return (
        <Box className={classes.formContent}>
            <Box className={classes.inputRoot}>
                <p className={classes.formItem}>Name</p>
                <TextField
                    inputRef={register({ required: requiredMessage })}
                    name="name"
                    disabled={!isFromOrg && clinics.count > 1}
                    variant="outlined"
                    className={classes.formItem}
                    inputProps={{ readOnly: !isFromOrg && clinics.count > 1 }}
                />
            </Box>
            <Box className={classes.inputRoot}>
                <p className={classes.formItem}>Description</p>
                <TextField
                    inputRef={register}
                    name="description"
                    disabled={!isFromOrg && clinics.count > 1}
                    multiline
                    variant="outlined"
                    className={classes.formItem}
                    inputProps={{ readOnly: !isFromOrg && clinics.count > 1 }}
                />
            </Box>
            <Box className={classes.inputRoot}>
                <p className={classes.formItem}>Type of discount</p>
                <Controller
                    name="discountType"
                    control={control}
                    rules={{ required: requiredMessage }}
                    as={
                        <RadioGroup row>
                            {discountType.map(type => (
                                <FormControlLabel
                                    disabled={!isFromOrg && clinics.count > 1}
                                    key={type}
                                    value={type}
                                    control={<Radio />}
                                    label={type}
                                />
                            ))}
                        </RadioGroup>
                    }
                />
            </Box>
            <Box className={classes.inputRoot}>
                <p className={classes.formItem}>Discount amount</p>
                <Controller
                    name="discount"
                    control={control}
                    rules={
                        ({ required: requiredMessage },
                        {
                            min: {
                                value: 1,
                                message: 'Min value for this field is 1'
                            }
                        })
                    }
                    render={({ onChange, value }) => (
                        <TextField
                            value={value}
                            type="number"
                            variant="outlined"
                            disabled={!isFromOrg && clinics.count > 1}
                            inputProps={{ readOnly: !isFromOrg && clinics.count > 1 }}
                            onChange={e => {
                                // float
                                if (e.target.value.includes('.')) {
                                    if (e.target.value.split('.')[1].length > 3) {
                                        const parts = e.target.value.split('.');
                                        const value =
                                            parseFloat(`${parts[0]}.${parts[1].slice(0, 3)}`).toFixed(3) || undefined;
                                        onChange(value);
                                    } else {
                                        const value = parseFloat(e.target.value) || undefined;
                                        onChange(value);
                                    }
                                } else {
                                    // integer
                                    onChange(parseFloat(e.target.value) || undefined);
                                }
                            }}
                        />
                    )}
                />
            </Box>
            <Box className={classes.inputRoot}>
                <p className={classes.formItem}>Apply discount to</p>
                <Controller
                    name="applyTo"
                    disabled={!isFromOrg}
                    control={control}
                    rules={{ required: requiredMessage }}
                    as={
                        <RadioGroup row>
                            {applyTo.map(type => (
                                <FormControlLabel
                                    disabled={!isFromOrg && clinics.count > 1}
                                    key={type}
                                    value={type}
                                    control={<Radio />}
                                    label={type}
                                />
                            ))}
                        </RadioGroup>
                    }
                />
            </Box>
            <Box className={classes.inputRoot}>
                <p className={classes.formItem}>Discount valid</p>
                <Box display="flex" alignItems="center">
                    <p>From</p>
                    <Controller
                        name={isFromOrg ? 'discountValid.from' : 'location.discountValid.from'}
                        defaultValue={discountValidFrom}
                        control={control}
                        rules={{ required: requiredMessage }}
                        style={{ '& :placeholder': { fontSize: 24 } }}
                        as={
                            <KeyboardDatePicker
                                label={!discountValidFrom && !watchedValidFrom ? 'dd/mm/yyyy' : ''}
                                format="dd/MM/yyyy"
                                inputVariant="outlined"
                                InputLabelProps={{
                                    shrink: false
                                }}
                            />
                        }
                    />
                </Box>
                <Box display="flex" alignItems="center" marginLeft="24px">
                    <p style={{ width: '32px' }}>To</p>
                    <Controller
                        name={isFromOrg ? 'discountValid.to' : 'location.discountValid.to'}
                        defaultValue={discountValidTo}
                        control={control}
                        rules={{ required: requiredMessage }}
                        as={
                            <KeyboardDatePicker
                                label={!discountValidTo && !watchedValidTo ? 'dd/mm/yyyy' : ''}
                                minDate={discountValidFrom}
                                format="dd/MM/yyyy"
                                inputVariant="outlined"
                                InputLabelProps={{
                                    shrink: false
                                }}
                            />
                        }
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(FormGeneral);
