import api from '../collums-components/api/config';
class SquareApi {
    static generateCsrf() {
        return api.request({
            method: 'GET',
            url: '/xero/csrf'
        });
    }

    static async getSeller() {
        return api.request({
            method: 'GET',
            url: '/xero/get-seller'
        });
    }

    static createSeller(code, state, redirect_uri) {
        return api.request({
            method: 'POST',
            url: '/xero/seller',
            data: { code, state, redirect_uri }
        });
    }

    static checkSellerConnected() {
        return api.request({
            method: 'GET',
            url: '/xero/is-connected'
        });
    }

    static revokeToken() {
        return api.request({
            method: 'POST',
            url: '/xero/revoke',
            data: {}
        });
    }

    static async refreshToken() {
        return api.request({
            method: 'GET',
            url: '/xero/refresh'
        });
    }

    static async listDevices() {
        return api.request({
            method: 'GET',
            url: '/xero/devices'
        });
    }

    // static createDevice(data) {
    //     return api.request({
    //         method: 'POST',
    //         url: '/xero/terminals/register',
    //         data
    //     });
    // }

    // static async getDevice(code) {
    //     return api.request({
    //         method: 'GET',
    //         url: '/xero/device?deviceCode=' + code
    //     });
    // }
}

export default SquareApi;
