/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import CheckboxTree from './CheckboxTree';
import categoriesApi from '../../../collums-components/api/CategoryApi';
import LoadingScreen from '../../../collums-components/components/common/loadingScreen';

function FormServices({ selectedServices, setSelectedServices, isFromOrg, clinic }) {
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchCategories = async () => {
        setIsLoading(true);
        const categoriesCount = await categoriesApi.countCategories(clinic?.id, isFromOrg);
        const query = `?withServices=true&count=${categoriesCount}`;
        const response = await categoriesApi.query(query, isFromOrg);
        setIsLoading(false);
        return response;
    };

    const getCategories = async () => {
        try {
            const response = await fetchCategories();
            const items = response.categories.map((category, index) => {
                return {
                    ...category,
                    child: response.services[index]
                };
            });
            const newCategories = [...categories, ...items];
            setCategories(newCategories);
        } catch (e) {
            console.error(e);
        }
    };

    const categoryClick = (e, category) => {
        const servicesId = category.child.map(service => service.id);
        const newSelectedServices = (() => {
            if (!e.target.checked) {
                return selectedServices.filter(service => !servicesId.includes(service));
            }
            return [...selectedServices, ...servicesId];
        })();
        setSelectedServices(newSelectedServices);
    };

    const toggleChild = (e, serviceId) => {
        const newSelectedServices = (() => {
            if (e.target.checked) return [...selectedServices, serviceId];
            return selectedServices.filter(service => service !== serviceId);
        })();
        setSelectedServices(newSelectedServices);
    };

    useEffect(() => {
        getCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {isLoading && <LoadingScreen />}
            <CheckboxTree
                notFromOrg={!isFromOrg}
                parentTree={categories}
                selectedItems={selectedServices}
                parentClick={categoryClick}
                childClick={toggleChild}
            />
        </>
    );
}

export default FormServices;
