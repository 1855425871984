import React from 'react';
import PropTypes from 'prop-types';
import {
    withStyles,
    TextField,
    IconButton,
    Button,
    Menu,
    MenuItem,
    makeStyles,
    Checkbox,
    FormControlLabel,
    Tooltip,
    Typography
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { mergeClientsHeader } from '../styles';
import { inputSearchStyles } from '../../../collums-constants/styles/stylesheets/inputSearchStyles';
import { buttonsStyles } from '../../../collums-constants/styles/stylesheets/buttonsStyles';
import HelpIcon from '@material-ui/icons/Help';

const DropdownButton = ({ classes, label, options }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <Button
                className={classes.optionsButton}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                {label}
            </Button>
            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {options?.map(op => {
                    return (
                        <MenuItem
                            key={op?.label}
                            onClick={() => {
                                if (op.handleClick) {
                                    op.handleClick();
                                }
                                handleClose();
                            }}
                        >
                            {op?.label}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};

const DropdownButtonStyled = withStyles(mergeClientsHeader)(DropdownButton);

function MergeClientsHeader({
    classes,
    setPage,
    filter,
    setFilter,
    setCreateCustomerModalOpen,
    showDuplicates,
    setShowDuplicates,
    formatAndExportCsv,
    showArchived,
    setShowArchived,
    isFromOrg
}) {
    const globalSearchStyles = makeStyles(inputSearchStyles)();
    const globalButtonsStyles = makeStyles(buttonsStyles)();

    return (
        <>
            <div className={classes.header}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        id="searchSupplier"
                        variant="outlined"
                        value={filter}
                        onChange={e => {
                            setFilter(e.target.value);
                            setPage(0);
                        }}
                        className={globalSearchStyles.inputSearch}
                        InputProps={{
                            endAdornment: (
                                <>
                                    <IconButton
                                        className={globalSearchStyles.icons}
                                        onClick={() => {
                                            setFilter('');
                                            setPage(0);
                                        }}
                                        disabled={!filter}
                                    >
                                        <ClearIcon style={!filter ? { color: 'transparent' } : {}} />
                                    </IconButton>
                                    <IconButton position="end" className={globalSearchStyles.icons}>
                                        <SearchIcon />
                                    </IconButton>
                                </>
                            )
                        }}
                    />
                    {!isFromOrg && (
                        <IconButton onClick={() => setCreateCustomerModalOpen(true)}>
                            <AddCircleIcon className={globalButtonsStyles.addCircleStyle} />
                        </IconButton>
                    )}
                    <div>
                        <DropdownButtonStyled
                            label={'Options'}
                            options={[
                                {
                                    label: 'Export as CSV',
                                    handleClick: formatAndExportCsv
                                }
                            ]}
                        />
                        {/*<IconButton onClick={formatAndExportCsv}>*/}
                        {/*    <GetAppIcon />*/}
                        {/*</IconButton>*/}
                    </div>
                </div>
                <div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showArchived}
                                onChange={({ target }) => setShowArchived(target.checked)}
                            />
                        }
                        label={
                            <div className={classes.headerStatusBoxLabel}>
                                <span>Show archived/unverified</span>
                                <Tooltip
                                    className={classes.headerStatusBoxTooltip}
                                    title={
                                        <Typography style={{ fontSize: 14 }}>
                                            Orange coloured rows are unverified - the clients have created an online
                                            account but didn’t verify their email address to complete the process. Go to
                                            their client card/communications tab and resend the email verification
                                            message.
                                        </Typography>
                                    }
                                    aria-label="help"
                                >
                                    <HelpIcon style={{ fontSize: 18 }} />
                                </Tooltip>
                            </div>
                        }
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showDuplicates}
                                onChange={({ target }) => setShowDuplicates(target.checked)}
                            />
                        }
                        label="Show duplicates"
                    />
                </div>
            </div>
        </>
    );
}

MergeClientsHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    setPage: PropTypes.func.isRequired,
    filter: PropTypes.string.isRequired,
    setFilter: PropTypes.string.isRequired,
    setCreateCustomerModalOpen: PropTypes.func.isRequired
};

export default withStyles(mergeClientsHeader)(MergeClientsHeader);
