import React from 'react';
import PropTypes from 'prop-types';

import { withStyles, Checkbox, FormControlLabel, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { serviceDetailsModalStyles } from '../styles';

const forStaffOptions = [
    { name: 'Sell this service', id: 'SELL' },
    { name: 'Perform this service', id: 'PERFORM' },
    { name: 'Perform AND sell this service', id: 'BOTH' }
];

function CommissionTab({
    classes,
    watch,
    allowCommission,
    commissionCondition,
    setAllowCommission,
    setCommissionCondition,
    currentService,
    isFromOrg,
    allClinics
}) {
    const allowCommissionWatch = watch('allowCommission');

    return (
        <div className={classes.formContent}>
            <FormControlLabel
                label="Allow commission"
                control={
                    <Checkbox
                        checked={allowCommission}
                        disabled={!isFromOrg && !currentService.allowCommission && allClinics.length > 1}
                        onChange={e => {
                            setAllowCommission(e.target.checked);
                        }}
                    />
                }
            />
            {allowCommissionWatch && (
                <div className={classes.formContentRow}>
                    <FormControl className={classes.formItem} variant="outlined">
                        <InputLabel>For Staff that</InputLabel>
                        <Select
                            value={commissionCondition}
                            onChange={e => {
                                setCommissionCondition(e.target.value);
                            }}
                        >
                            {forStaffOptions.map(options => (
                                <MenuItem key={options.id} value={options.id}>
                                    {options.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            )}
        </div>
    );
}

CommissionTab.propTypes = {
    classes: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    currentService: PropTypes.object
};

export default withStyles(serviceDetailsModalStyles)(CommissionTab);
