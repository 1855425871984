import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Controller } from 'react-hook-form';

import TagsModal from '../components/tags/TagsModal';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { Chip, makeStyles, TextField, IconButton, InputLabel } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import TagsApi from '../api/TagsApi';

import { tagsStyles } from './styles';

function Tags({ disabled, control, rules, name, defaultValue, inputSize, showAddButton, showInsideLabel }) {
    const [options, setOptions] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const _showAdd = showAddButton !== undefined ? showAddButton : true;

    async function getTagOptions() {
        const res = await TagsApi.listTags();
        setOptions(res);
    }

    useEffect(() => {
        getTagOptions();
    }, []);

    const classes = makeStyles(tagsStyles)();

    // eslint-disable-next-line
    const renderInput = ({ value, onChange }) => {
        const handleDelete = index => {
            const newTags = [...value];
            newTags.splice(index, 1);
            onChange(newTags);
        };
        return (
            <div className={classes.tagsContainer} style={{ width: `${inputSize || '48'}%` }}>
                <div className={classes.tagChipsContainer} style={{ marginBottom: value.length ? 16 : 0 }}>
                    {/* eslint-disable-next-line */}
                    {value.map((tag, index) => (
                        <Chip
                            disabled={disabled}
                            key={index}
                            size="small"
                            label={tag.name}
                            onDelete={() => handleDelete(index)}
                        />
                    ))}
                </div>
                <Autocomplete
                    disabled={disabled}
                    value={value}
                    className={classes.autocomplete}
                    onChange={(event, newValue) => {
                        if (newValue) {
                            onChange([...value, newValue]);
                        }
                    }}
                    filterOptions={options => {
                        const selectedValues = value.map(val => val.id);
                        return options.filter(opt => !selectedValues.includes(opt.id));
                    }}
                    options={options}
                    selectOnFocus
                    clearOnBlur
                    getOptionLabel={opt => opt.name || ''}
                    renderInput={params => (
                        <>
                            {!showInsideLabel && (
                                <InputLabel shrink style={{ marginBottom: 7, marginLeft: 10 }}>
                                    Select or add Tags
                                </InputLabel>
                            )}
                            <TextField
                                style={{ width: '100%' }}
                                {...params}
                                variant="outlined"
                                label={showInsideLabel ? 'Select or add Tags' : ''}
                                InputProps={{
                                    ...params.InputProps,
                                    classes: {
                                        input: classes.autocompleteInputProps
                                    }
                                }}
                                InputLabelProps={{
                                    ...params.InputLabelProps,
                                    className: classes.autocompleteLabelProps
                                }}
                            />
                        </>
                    )}
                    renderOption={option => {
                        return <p className={classes.autocompleteOptions}>{`${option.name}`}</p>;
                    }}
                />
            </div>
        );
    };

    return (
        <div className={classes.root}>
            <Controller
                name={name || 'tags-input'}
                control={control}
                defaultValue={defaultValue || []}
                rules={rules}
                render={renderInput}
            />
            {_showAdd && (
                <div className={classes.addButtonContainer}>
                    <IconButton
                        disabled={disabled}
                        onClick={() => setIsModalVisible(true)}
                        className={classes.addButton}
                    >
                        <AddCircleIcon style={{ fontSize: 30, color: `${disabled ? '#869dba' : '#3083EC'}` }} />
                    </IconButton>
                </div>
            )}
            {isModalVisible && (
                <TagsModal
                    isVisible={isModalVisible}
                    closeModal={() => {
                        setIsModalVisible(false);
                        getTagOptions();
                    }}
                    isFromOrg={!disabled}
                />
            )}
        </div>
    );
}

Tags.propTypes = {
    showAddButton: PropTypes.bool,
    defaultValue: PropTypes.array,
    inputSize: PropTypes.number,
    control: PropTypes.any,
    name: PropTypes.string,
    rules: PropTypes.any,
    showInsideLabel: PropTypes.bool,
    disabled: PropTypes.bool.isRequired,
    value: PropTypes.any //eslint-disable-line
};

export default Tags;
