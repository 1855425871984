import React from 'react';
import { withStyles, TextField, IconButton, makeStyles, FormControlLabel, Checkbox } from '@material-ui/core';
import { Search, Clear, AddCircle } from '@material-ui/icons';
import styles from './styles';

import { inputSearchStyles } from '../../collums-constants/styles/stylesheets/inputSearchStyles';
import { buttonsStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';

function TemporaryDiscountsHeader({
    classes,
    openModal,
    resetDiscount,
    setPage,
    nameFilter,
    setNameFilter,
    refreshList,
    showArchived,
    setShowArchived,
    isFromOrg,
    clinics
}) {
    const globalSearchStyles = makeStyles(inputSearchStyles)();
    const globalButtonsStyles = makeStyles(buttonsStyles)();

    return (
        <div className={classes.header}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                    className={globalSearchStyles.inputSearch}
                    id="searchSupplier"
                    variant="outlined"
                    value={nameFilter}
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            refreshList();
                        }
                    }}
                    onChange={e => {
                        setNameFilter(e.target.value);
                        setPage(0);
                    }}
                    InputProps={{
                        endAdornment: (
                            <>
                                <IconButton
                                    className={globalSearchStyles.icons}
                                    onClick={() => {
                                        setNameFilter('');
                                    }}
                                    disabled={!nameFilter}
                                >
                                    <Clear style={!nameFilter ? { color: 'transparent' } : {}} />
                                </IconButton>
                                <IconButton
                                    position="end"
                                    onClick={() => refreshList()}
                                    className={globalSearchStyles.icons}
                                >
                                    <Search />
                                </IconButton>
                            </>
                        )
                    }}
                />
                {(isFromOrg || clinics.length === 1) && (
                    <div className={classes.rightActionsContainer}>
                        <IconButton
                            onClick={() => {
                                resetDiscount();
                                openModal();
                            }}
                        >
                            <AddCircle className={globalButtonsStyles.addCircleStyle} />
                        </IconButton>
                    </div>
                )}
            </div>
            <div className={classes.checkboxesContainer}>
                <FormControlLabel
                    label="Show Archived"
                    control={
                        <Checkbox
                            checked={showArchived}
                            onChange={() => {
                                setShowArchived(prevState => !prevState);
                                setPage(0);
                            }}
                        />
                    }
                />
            </div>
        </div>
    );
}

export default withStyles(styles)(TemporaryDiscountsHeader);
