import React, { useEffect, useState } from 'react';

import Api from '../../api/EquipmentApi';

import DetailModal from '../details/detailedModal';
import EquipmentFormModal from './form/EquipmentFormModal';

import ListPagination from '../common/ListPagination';

import View from '../common/View';

import ConfirmModal from '../common/ConfirmModal';

import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import { toastr } from 'react-redux-toastr';
import { useLocation } from 'react-router-dom';

const EquipmentView = ({ clinic, isFromOrg }) => {
    const [equipEdit, setEquipEdit] = useState(null);
    const [equipment, setEquipment] = useState({
        items: [],
        size: 10
    });
    const [equipDetailed, setEquipDetailed] = useState({});
    const [formVisibility, setFormVisibility] = useState(false);
    const [detailVisibility, setDetailVisibility] = useState(false);
    const [archived, setArchived] = useState(false);
    const [inactive, setInactive] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [nameFilter, setNameFilter] = useState('');
    const location = useLocation();

    const toDo = () => {
        if (modalTitle === 'Archive equipment') handleArchive();
    };

    const openArchive = async data => {
        setEquipDetailed(data);
        openConfirmModal(
            'Archive equipment',
            `Are you sure you want to ${data.archived ? 'unarchive' : 'archive'} this equipment?`
        );
    };

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setModalTitle('');
        setModalContent();
    };

    const openConfirmModal = (title, component) => {
        setConfirmModal(true);
        setModalTitle(title);
        setModalContent(component);
    };

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const id = urlParams.get('id');

        if (id) setDetailVisibility(id);
    }, []);

    const handleFormModalVisibility = bool => {
        setEquipEdit(null);
        setFormVisibility(bool);
    };
    const handleDetailModalVisibility = id => setDetailVisibility(id);

    const handleAddItem = async data => {
        try {
            await Api.addEquipment(data);
            ApiCall(0, rowsPerPage, '', true);
            toastr.success(`Equipment ${data.name} successfully created`);
            setFormVisibility(false);
            return true;
        } catch (err) {
            if (typeof err === 'object') {
                if (err.data && err.data.message) {
                    toastr.error(err.data.message);
                    return false;
                }
            }
            toastr.error('Something went wrong');
            return false;
        }
    };

    const handleUpdateItem = async data => {
        const id = data.id;
        delete data.id;
        try {
            await Api.editEquipment(id, data);
            ApiCall(0, rowsPerPage, '', true);
            toastr.success(`Equipment ${data.name} successfully updated`);
            setFormVisibility(false);
            return true;
        } catch (err) {
            if (typeof err === 'object') {
                if (err.data && err.data.message) {
                    toastr.error(err.data.message);
                    return false;
                }
            }
            toastr.error('somethin went wrong');
            return false;
        }
    };

    const handleEdit = data => {
        setEquipEdit(data);
        setFormVisibility(true);
    };

    /* const handleDelete = async id => {
        try {
            await Api.deleteEquipment(id).then(() => {
                toastr.success('Equipment successfully deleted');
                ApiCall(0, 15, '', true);
            });
            return true;
        } catch (err) {
            return false;
        }
    }; */

    const handleArchive = async () => {
        const id = equipDetailed.id;
        if (!id) return;

        try {
            await Api.archiveEquipment(id).then(res => {
                toastr.success(`Equipment ${res.name} successfully ${res.archived ? 'archived' : 'unarchived'}`);
                ApiCall(0, rowsPerPage, '', true);
            });
            return true;
        } catch (err) {
            return false;
        }
    };

    const handleDuplicate = async (id, data) => {
        const names = equipment.items
            .map(item => item.name)
            .filter(
                name =>
                    name.startsWith(
                        data.name.slice(0, data.name.indexOf(' -') >= 0 ? data.name.indexOf(' -') : data.name.length)
                    ) || name === data.name
            );
        const name = data.name + ' - Copy'.repeat(names.length);
        const { active, description, tags } = data;
        handleAddItem({ active, description, name, tags });
    };

    const handleDetailed = async (id, data) => {
        const equip = data;
        await setEquipDetailed(equip);
        setDetailVisibility(true);
    };

    const activeCall = async equipment => {
        if (!(equipment || {}).id) return;
        if (equipment.archived) {
            toastr.error(`Cannot change equipment ${equipment.name} active, because this equipment is archived`);
            return;
        }
        try {
            await Api.changeEquipmentActive(equipment.id);
            ApiCall(0, rowsPerPage, '', true);
            toastr.success(`Equipment ${equipment.name} successfully updated`);
        } catch (err) {
            if (typeof err === 'object') {
                if (err.data && err.data.message) {
                    toastr.error(err.data.message);
                    return;
                }
            }
            toastr.error('Something went wrong');
        }
    };

    const ApiCall = async (skip, limit, queryParam, refreshing = false) => {
        try {
            setIsLoading(true);
            if (!queryParam && nameFilter) queryParam += `nameFilter=${nameFilter}`;
            if (queryParam.indexOf('nameFilter') > -1) {
                const splitByFilterName = queryParam.split('nameFilter=')[1];
                if (splitByFilterName) {
                    const filterName = splitByFilterName.split('&')[0];
                    setNameFilter(filterName);
                }
            } else setNameFilter('');
            if (refreshing && !queryParam) {
                queryParam = `archived=${archived}&inactive=${inactive}`;
            }
            if (queryParam.indexOf('archived=false') >= 0) setArchived(false);
            else setArchived(true);
            if (queryParam.indexOf('inactive=false') >= 0) setInactive(false);
            else setInactive(true);
            if (clinic && !clinic.id) {
                clinic.id = location.pathname.split('/')[2];
            }
            if (clinic?.id && queryParam.length && queryParam.indexOf('clinic=') === -1) {
                queryParam = queryParam + `&clinic=${clinic.id}`;
            }
            const newData = await Api.query(skip, limit, queryParam).then(res => res);
            if (refreshing) {
                setEquipment(newData);
                setPage(0);
            }
            return newData.items;
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    };

    const copyAction = (() => {
        if (isFromOrg) {
            return {
                title: 'copy',
                action: handleDuplicate
            };
        }
        return false;
    })();

    const actions = [
        {
            title: 'archive',
            action: openArchive
        },
        copyAction,
        {
            title: 'details',
            action: handleDetailed
        } /* ,
        {
            title: 'delete',
            action: handleDelete
        } */
    ].filter(el => el);

    return (
        <>
            <View>
                <LoadingScreen isVisible={isLoading} />
                <ListPagination
                    item="Equipments"
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    data={equipment.items}
                    maxSize={equipment.size}
                    clinic={clinic.id}
                    defaultColumns={[
                        { title: 'Active', field: 'active' },
                        { title: 'Name', field: 'name', onClick: handleEdit },
                        { title: 'Description', field: 'description' },
                        { title: 'Tags', field: 'tags' }
                    ]}
                    filters={[
                        { name: 'archived', label: 'Show Archived', value: false },
                        { name: 'inactive', label: 'Show Inactive', value: false }
                    ]}
                    actions={actions}
                    ApiCall={ApiCall}
                    onCreate={handleFormModalVisibility}
                    nameFilter={true}
                    filterName="nameFilter"
                    highlightArchived={true}
                    activeCall={activeCall}
                />
                {formVisibility && (
                    <EquipmentFormModal
                        defaultValue={equipEdit}
                        visible={formVisibility}
                        setVisibility={handleFormModalVisibility}
                        handleAddItem={handleAddItem}
                        handleUpdateItem={handleUpdateItem}
                        clinic={clinic}
                    />
                )}
                {!!detailVisibility && (
                    <DetailModal
                        title="Equipment view"
                        data={equipDetailed}
                        visible={!!detailVisibility}
                        setVisibility={handleDetailModalVisibility}
                    />
                )}
                {confirmModal && (
                    <ConfirmModal
                        isOpen
                        setIsOpen={closeConfirmModal}
                        onConfirm={toDo}
                        title={modalTitle}
                        content={modalContent}
                    />
                )}
            </View>
        </>
    );
};

export default EquipmentView;
