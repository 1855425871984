import {
    makeStyles,
    FormControlLabel,
    Checkbox,
    TextField,
    FormControl,
    InputLabel,
    Tooltip,
    Typography,
    Grid,
    RadioGroup,
    Radio
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { serviceDetailsModalStyles } from '../styles';
import HelpIcon from '@material-ui/icons/Help';
import PropTypes from 'prop-types';
import { getLocationItem } from '../../../collums-constants/utils';
import { DEPOSIT_TYPES } from '../../settings/tabs/CustomerAppTab';

function OnlineBookingTab({
    register,
    currentService,
    clinic,
    isFromOrg,
    selectedCategory,
    allClinics,
    organisationData,
    setValue,
    getValues
}) {
    const classes = makeStyles(serviceDetailsModalStyles)();

    const urlLocation = clinic === 'organisation' ? (allClinics.length === 1 ? allClinics[0].id : null) : clinic;
    const customerAppUrl = `${process.env.REACT_APP_CUSTOMER_URL}/step/${urlLocation}/${selectedCategory?.id}${
        currentService ? '/' + currentService?.id : ''
    }`;
    const [, setRefreshKey] = useState(0);

    useEffect(() => {
        if (organisationData) {
            if (!currentService.bookingDepositType || !organisationData?.bookingDepositType) {
                if (organisationData?.bookingDepositType) {
                    setValue('bookingDepositType', DEPOSIT_TYPES.DEFAULT);
                } else {
                    setValue('bookingDepositType', DEPOSIT_TYPES.NO_DEPOSIT);
                }
            } else {
                setValue('bookingDepositType', currentService.bookingDepositType);
                setValue('bookingDepositAmount', currentService.bookingDepositAmount);
            }
        }
    }, [organisationData]); // eslint-disable-line

    let allowBookingDisabled = (() => {
        if (isFromOrg) {
            return false;
        } else {
            return !currentService?.showOnline;
        }
    })();

    return (
        <div className={classes.formContent}>
            <div className={classes.formContentRow}>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Order</InputLabel>
                    <TextField
                        inputRef={register}
                        name="order"
                        variant="outlined"
                        defaultValue={
                            isFromOrg
                                ? currentService
                                    ? currentService.order
                                    : undefined
                                : getLocationItem(currentService, clinic).order
                        }
                    />
                </FormControl>
            </div>

            <div className={classes.onlineBookingContentRow}>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Online name</InputLabel>
                    <TextField
                        inputRef={register}
                        name="onlineName"
                        variant="outlined"
                        defaultValue={
                            isFromOrg
                                ? currentService
                                    ? currentService.onlineName
                                    : undefined
                                : getLocationItem(currentService, clinic).onlineName
                        }
                        disabled={allClinics > 1 && !isFromOrg}
                    />
                </FormControl>

                <FormControl className={classes.formItemTooltip} variant="outlined">
                    <Tooltip
                        className={classes.tooltip}
                        title={
                            <Typography className={classes.typography}>
                                Use this if you wish the name of this service to appear differently for online booking.
                                Use this wisely as using many different names can make it difficult to know which
                                services link to those online!
                            </Typography>
                        }
                        aria-label="help"
                    >
                        <HelpIcon className={classes.helpIcon} />
                    </Tooltip>
                </FormControl>
            </div>

            <div className={classes.onlineBookingContentRow}>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Online description</InputLabel>
                    <TextField
                        inputRef={register}
                        name="onlineSubtitle"
                        variant="outlined"
                        defaultValue={
                            isFromOrg
                                ? currentService
                                    ? currentService.onlineSubtitle
                                    : undefined
                                : getLocationItem(currentService, clinic).onlineSubtitle
                        }
                        disabled={allClinics > 1 && !isFromOrg}
                    />
                </FormControl>
                <FormControl className={classes.formItemTooltip} style={{ marginTop: 18 }} variant="outlined">
                    <Tooltip
                        title={
                            <Typography style={{ fontSize: 14 }}>
                                This text will appear below the service name in online booking.
                            </Typography>
                        }
                        aria-label="help"
                    >
                        <HelpIcon className={classes.helpIcon} />
                    </Tooltip>
                </FormControl>
            </div>

            <div className={classes.onlineBookingContentRow}>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Info text</InputLabel>
                    <TextField
                        inputRef={register}
                        name="onlineDescription"
                        variant="outlined"
                        defaultValue={
                            isFromOrg
                                ? currentService
                                    ? currentService.onlineDescription
                                    : undefined
                                : getLocationItem(currentService, clinic).onlineDescription
                        }
                        disabled={allClinics > 1 && !isFromOrg}
                    />
                </FormControl>
                <FormControl className={classes.formItemTooltip} style={{ marginTop: 18 }} variant="outlined">
                    <Tooltip
                        title={
                            <Typography style={{ fontSize: 14 }}>
                                This text will appear when hovering an info bubble next to the service name in online
                                booking.
                            </Typography>
                        }
                        aria-label="help"
                    >
                        <HelpIcon className={classes.helpIcon} />
                    </Tooltip>
                </FormControl>
            </div>

            {(isFromOrg || allClinics.length === 1) && (
                <>
                    <div className={classes.formContentRow}>
                        <FormControlLabel
                            label="Show Duration"
                            control={
                                <Checkbox
                                    defaultChecked={currentService ? currentService.showDurationOnline : false}
                                    inputProps={{ name: 'showDurationOnline' }}
                                    inputRef={register}
                                />
                            }
                        />
                    </div>
                    <div className={classes.formContentRow}>
                        <FormControlLabel
                            label="Show Price"
                            control={
                                <Checkbox
                                    disabled={false}
                                    defaultChecked={currentService ? currentService.showPriceOnline : false}
                                    inputProps={{ name: 'showPriceOnline' }}
                                    inputRef={register}
                                />
                            }
                        />
                    </div>
                </>
            )}
            <div className={classes.formContentRow}>
                <FormControlLabel
                    label="Allow online booking"
                    control={
                        <Checkbox
                            disabled={allowBookingDisabled}
                            defaultChecked={
                                isFromOrg
                                    ? currentService
                                        ? currentService.showOnline
                                        : false
                                    : getLocationItem(currentService, clinic).showOnline
                            }
                            inputProps={{ name: 'showOnline' }}
                            inputRef={register}
                        />
                    }
                />
            </div>
            {urlLocation && currentService.id && (
                <div className={classes.formContentRow}>
                    <Typography className={classes.typography}>
                        URL:{' '}
                        <a className={classes.linkItem} href={customerAppUrl}>
                            {customerAppUrl}
                        </a>
                    </Typography>
                </div>
            )}

            <Grid container direction="column" spacing={3} className={classes.depositContainer}>
                <Grid item style={{ marginTop: '24px' }}>
                    <div className={classes.inlineContainer}>
                        <FormControl variant="h4">Type of deposit</FormControl>
                        <Tooltip
                            className={classes.tooltip2}
                            title={
                                <Typography className={classes.typography}>
                                    Default global online booking deposit rules are set in Admin &gt; Settings. Set
                                    different rules here if you wish to override the default.
                                </Typography>
                            }
                            aria-label="help"
                        >
                            <HelpIcon className={classes.helpIcon} />
                        </Tooltip>
                    </div>

                    <div className={classes.inlineContainer}>
                        <RadioGroup
                            style={{ display: 'flex', flexDirection: 'row' }}
                            name="bookingDepositType"
                            inputRef={register}
                            onChange={(e, value) => {
                                setValue('bookingDepositType', value);
                                setRefreshKey(prevKey => prevKey + 1);
                            }}
                        >
                            <FormControlLabel
                                key={`tod-${DEPOSIT_TYPES.NO_DEPOSIT}`}
                                value={DEPOSIT_TYPES.NO_DEPOSIT}
                                control={<Radio inputRef={register} />}
                                label={DEPOSIT_TYPES.NO_DEPOSIT}
                                checked={getValues()?.bookingDepositType === DEPOSIT_TYPES.NO_DEPOSIT}
                                disabled={!organisationData?.bookingDepositType}
                            />
                            {organisationData.bookingDepositType && (
                                <FormControlLabel
                                    key={`tod-${DEPOSIT_TYPES.DEFAULT}`}
                                    value={DEPOSIT_TYPES.DEFAULT}
                                    control={<Radio inputRef={register} />}
                                    label={DEPOSIT_TYPES.DEFAULT}
                                    checked={getValues()?.bookingDepositType === DEPOSIT_TYPES.DEFAULT}
                                    disabled={!organisationData?.bookingDepositType}
                                />
                            )}
                            <FormControlLabel
                                key={`tod-${DEPOSIT_TYPES.PERCENTAGE}`}
                                value={DEPOSIT_TYPES.PERCENTAGE}
                                control={<Radio inputRef={register} />}
                                label={DEPOSIT_TYPES.PERCENTAGE}
                                checked={getValues()?.bookingDepositType === DEPOSIT_TYPES.PERCENTAGE}
                                disabled={!organisationData?.bookingDepositType}
                            />
                            <FormControlLabel
                                key={`tod-${DEPOSIT_TYPES.FIXED_AMOUNT}`}
                                value={DEPOSIT_TYPES.FIXED_AMOUNT}
                                control={<Radio inputRef={register} />}
                                label={DEPOSIT_TYPES.FIXED_AMOUNT}
                                checked={getValues()?.bookingDepositType === DEPOSIT_TYPES.FIXED_AMOUNT}
                                disabled={!organisationData?.bookingDepositType}
                            />
                        </RadioGroup>
                    </div>

                    <div className={classes.inlineContainer}>
                        <FormControl variant="h4" style={{ marginBottom: '5px' }}>
                            Deposit percentage/amount
                        </FormControl>
                    </div>
                    <div className={classes.inlineContainer}>
                        <FormControl className={classes.fieldContainer}>
                            <TextField
                                id="bookingDepositAmount-input"
                                name="bookingDepositAmount"
                                variant="outlined"
                                // onChange={(e, value) => {
                                //     handleCheckboxChange(e, value, 'bookingDepositAmount');
                                // }}
                                type="number"
                                InputProps={{
                                    inputProps: { min: 1 }
                                }}
                                inputRef={register}
                                style={{ width: '240px' }}
                                disabled={
                                    !organisationData?.bookingDepositType ||
                                    ![DEPOSIT_TYPES.FIXED_AMOUNT, DEPOSIT_TYPES.PERCENTAGE].includes(
                                        getValues()?.bookingDepositType
                                    )
                                }
                            />
                        </FormControl>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

OnlineBookingTab.propTypes = {
    register: PropTypes.any
};

export default OnlineBookingTab;
