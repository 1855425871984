import PropTypes from 'prop-types';
import { Button, withStyles, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toastr } from 'react-redux-toastr';
import ProductCategoryApi from '../../api/ProductCategoryApi';
import Modal from '../common/Modal';
import Accordion from '../common/Accordion';
import GeneralTab from './tabs/GeneralTab';
import CommissionTab from './tabs/CommissionTab';
import ProductStockTab from './tabs/ProductStockTab';
import { productCategoryModalStyles } from './styles';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import LocationTab from '../common/LocationTab';
import ConfirmModal from '../common/ConfirmModal';

const _ = require('lodash');

function ProductCategoryFormModal({
    isFromOrg,
    clinic,
    classes,
    visible,
    onClose,
    Category,
    onConfirm,
    clinics = [],
    allClinics = []
}) {
    const globalStyles = makeStyles(modalsButtonStyles)();

    const category = Category || {};
    const [categories, setCategories] = useState([]);
    const [parent, setParent] = useState(null);

    const [openConfirm, setOpenConfirm] = useState(false);

    const getFilteredCategories = async (value = '') => {
        const items = await ProductCategoryApi.filter(value, false, clinic, isFromOrg);
        return items.map(item => {
            return {
                id: item.value,
                name: item.label
            };
        });
    };

    useState(() => {
        const loadCategoryFilter = async () => {
            const fetchedCategories = await getFilteredCategories();
            if (category.parentCategory) {
                const categoryFetched = await ProductCategoryApi.getCategory(category.parentCategory);
                if (!categoryFetched) return;
                const parentCategModel = ['id', 'name'];
                const parentCateg = _.pick(categoryFetched, parentCategModel);
                if (fetchedCategories.find(el => el.id === parentCateg.id)) {
                    setCategories([parentCateg, ...fetchedCategories.filter(el => el.id !== parentCateg.id)]);
                } else setCategories([parentCateg, ...fetchedCategories]);
                setParent(parentCateg);
            } else setCategories(fetchedCategories);
        };

        loadCategoryFilter();
    }, []);

    const searchCategories = async value => {
        const newValues = await getFilteredCategories(value);
        setCategories(newValues);
    };

    const { register, handleSubmit, control, watch, errors, setValue, getValues } = useForm({
        defaultValues: {
            category: Category
        }
    });

    useEffect(() => {
        const requiredFields = Object.keys(errors);

        if (requiredFields.length !== 0) {
            const messages = requiredFields.map(field => errors[field].message);

            toastr.error('Missing fields', messages.join(','));
        }
    }, [errors]);

    const formRef = useRef();

    const tabProps = {
        categories,
        clinics,
        clinic,
        isFromOrg,
        control,
        register,
        watch,
        setValue,
        getValues,
        parent,
        setParent,
        selectedItem: Category,
        category,
        allClinics
    };

    const checkDifferences = (current, payload) => {
        let isTheSame = true;

        Object.keys(payload).forEach(key => {
            if (!Array.isArray(payload[key]) && current[key] !== payload[key]) {
                isTheSame = false;
            }
        });

        if (!isTheSame) {
            return setOpenConfirm(true);
        }
        return onConfirm(payload);
    };

    return (
        <>
            <Modal
                isOpen={visible}
                title={category.id !== undefined ? `View/Edit ${category.name} Category` : 'New Product Category'}
                id="category"
                onClose={onClose}
                onCancel={onClose}
            >
                <form ref={formRef} className={classes.accordionsForm}>
                    <Accordion title={'General'}>
                        {' '}
                        <GeneralTab {...tabProps} searchCategories={searchCategories} key="general" />{' '}
                    </Accordion>
                    <Accordion title={'Locations'} hideItem={!isFromOrg}>
                        <LocationTab {...tabProps} />
                    </Accordion>
                    <Accordion title={'Commission'}>
                        {' '}
                        <CommissionTab {...tabProps} />{' '}
                    </Accordion>
                    <Accordion title={'Product Stock'}>
                        {' '}
                        <ProductStockTab {...tabProps} />{' '}
                    </Accordion>

                    <div className={globalStyles.buttonsContainer}>
                        <Button className={globalStyles.cancelButton} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            className={globalStyles.confirmButton}
                            onClick={handleSubmit(data => {
                                if (category.id) {
                                    checkDifferences(category, data);
                                } else {
                                    onConfirm(data);
                                }
                            })}
                        >
                            Confirm
                        </Button>
                    </div>
                </form>
            </Modal>
            {openConfirm && category.id !== undefined && (
                <ConfirmModal
                    isOpen
                    setIsOpen={setOpenConfirm}
                    onConfirm={handleSubmit(data => onConfirm(data))}
                    onClose={() => setOpenConfirm(false)}
                    title={'Confirm Editing'}
                    centerContent={true}
                    contentHtml={`${
                        getValues().name !== Category.prevName
                            ? 'Changing the name of this product will also affect historic appointments, invoices, reports and anywhere else it features.<br>' +
                              'If you wish to keep historic records the same but have this change for the future, please inactivate this category and create a new one.<br><br>'
                            : ''
                    }`}
                />
            )}
        </>
    );
}

ProductCategoryFormModal.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func.isRequired
};

export default withStyles(productCategoryModalStyles)(ProductCategoryFormModal);
