import React from 'react';
import { withStyles } from '@material-ui/core';
import { modal } from './styles';
import { format } from 'date-fns';
import Modal from '../../../common/Modal';
import { getCurrentTimezonedDate } from '../../../../collums-components/helpers/timezone';

const Preview = ({ data, closeModal, classes }) => {
    const html = data.content;
    const subject = data.subject;
    const displayDate = format(getCurrentTimezonedDate(), 'MM/dd/yyyy');
    return (
        <>
            <Modal
                isOpen={true}
                title={''}
                id="preview"
                onClose={closeModal}
                onCancel={closeModal}
                size={'xl'}
                fullHeight={true}
            >
                <div className={classes.modalHTMLWrapContent}>
                    <div>
                        <div className={classes.modalHTMLHeader}>From: </div>
                        <div className={classes.break}> hello@askinology.com </div>
                        <div className={classes.modalHTMLHeader}>Subject: </div>
                        <div className={classes.break}>
                            <b> {subject}</b>
                        </div>
                        <div className={classes.modalHTMLHeader}>Date: </div>
                        <div className={classes.break}> {displayDate}</div>
                        <div className={classes.modalHTMLHeader}>To: </div>
                        <div className={classes.break}> to@example.com</div>
                    </div>

                    <hr className={classes.hrCustom} />

                    {/* HTMl preview */}
                    <div className={classes.modalHTMLContent} dangerouslySetInnerHTML={{ __html: html }} />
                </div>
            </Modal>
        </>
    );
};

export default withStyles(modal)(Preview);
