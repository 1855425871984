const resourcesTabs = (currentClinic, pointOrganisation) => [
    { name: 'Clients', ref: `/resources/location/${pointOrganisation ? 'organisation' : currentClinic}/merge-clients` },
    { name: 'Courses', ref: `/resources/location/${pointOrganisation ? 'organisation' : currentClinic}/courses` },
    { name: 'Products', ref: `/resources/location/${pointOrganisation ? 'organisation' : currentClinic}/products` },
    //   { name: 'Reasons', ref: `/resources/location/${currentClinic}/reasons` },
    { name: 'Services', ref: `/resources/location/${currentClinic}/services` },
    { name: 'Suppliers', ref: `/resources/location/${currentClinic}/suppliers` },
    { name: 'Tags', ref: `/resources/location/${currentClinic}/tags` }
];

export default resourcesTabs;
