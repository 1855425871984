import React from 'react';
import AdminTable from '../../collums-components/components/common/adminTable';

import View from '../common/View';

const NotificationTable = ({ notificationList = [], collumns = [] }) => {
    return (
        <View>
            <AdminTable list={notificationList} columns={collumns} />
        </View>
    );
};

export default NotificationTable;
