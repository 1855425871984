import React from 'react';
import { withStyles } from '@material-ui/core';
import formModalStyles from '../styles';
import Form from '../../../../collums-components/components/formBuilder';

const FormDesignTab = ({ classes, openPreviewModal }) => {
    return (
        <div className={classes.formDesign}>
            <Form usePaper={true} openPreviewModal={openPreviewModal} />
        </div>
    );
};

export default withStyles(formModalStyles)(FormDesignTab);
