module.exports = [
    'initialStock',
    'supplier',
    'linkedServices',
    'category',
    'subCategory',
    'brand',
    'name',
    'vol',
    'volUnit',
    'costPrice',
    'netPrice',
    'grossPrice',
    'min',
    'max',
    'awaitingDelivery',
    'type',
    'description',
    'tax',
    'barcodes',
    'allowCommission',
    'consumable',
    'tags',
    'autoStockControl',
    'allowNegative',
    'archived',
    'isActive',
    'shouldLast',
    'locations'
];
