export const EmailOptionStyles = () => ({
    deleteIcon: {
        color: 'red'
    },
    arrowIcons: {
        color: '#333',
        fontSize: 18
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 42
    },
    radioGroup: {
        flexDirection: 'row',
        marginLeft: 20
    }
});
