import api from '../collums-components/api/config';
import { stringify } from 'query-string';

const newQuery = params => {
    const query = new URLSearchParams(params);
    query.forEach((value, key) => (!value || value === 'undefined') && query.delete(key));
    return query.toString();
};

class ProductApi {
    static getProductsList(skip = 0, limit = 15, queryParams = '') {
        return api.request({
            method: 'GET',
            url: `/stocks/table?${newQuery({ skip, limit })}&${newQuery(queryParams)}`
        });
    }

    static getBrands(clinic, isFromOrg = false) {
        return api.request({
            method: 'GET',
            url: `/stocks/brands?clinic=${clinic}&isFromOrg=${isFromOrg}`
        });
    }

    static getProductsByBrand() {
        return api.request({
            method: 'GET',
            url: '/stocks/uniqByBrand'
        });
    }

    static getProductById(productId) {
        return api.request({
            method: 'GET',
            url: `/stocks/${productId}?withTags=true`
        });
    }

    static createBrand(data) {
        return api.request({
            method: 'POST',
            url: '/stocks/brands',
            data
        });
    }

    static createProductCategory(data) {
        return api.request({
            method: 'POST',
            url: '/stocks/categories',
            data
        });
    }

    static getSuppliers(clinic, isFromOrg = false) {
        return api.request({
            method: 'GET',
            url: `/stocks/suppliers?clinic=${clinic}&isFromOrg=${isFromOrg}`
        });
    }

    static getCategories(query = '') {
        return api.request({
            method: 'GET',
            url: `/stocks/categories${query}`
        });
    }

    static getSubcategories(clinic, isFromOrg = false) {
        return api.request({
            method: 'GET',
            url: `/stocks/subcategories?clinic=${clinic}&isFromOrg=${isFromOrg}`
        });
    }

    static createProduct(data) {
        return api.request({
            method: 'POST',
            url: '/stocks',
            data
        });
    }

    static updateProduct(id, data, clinic, isFromOrg = false) {
        return api.request({
            method: 'PUT',
            url: `/stocks/${id}?isFromOrg=${isFromOrg}${clinic ? `&clinic=${clinic}` : ''}`,
            data
        });
    }

    static getProducts(data) {
        const query = {
            order: '-createdAt',
            pageSize: 10,
            showArchived: true,
            showInactive: true
        };

        if (!data.hasPagination) query.hasPagination = false;

        if (data.page) query.page = data.page;

        if (data.pageSize) query.pageSize = data.pageSize;

        if (data.name) {
            query.name = encodeURIComponent(data.name);
        }

        if (data.category) {
            query.category = data.category;
        }

        const queryString = stringify(query);

        return api
            .request({
                method: 'GET',
                url: `/stocks?${queryString}`
            })
            .then(res => res.data);
    }

    static deleteProducts(ids) {
        return api.request({
            method: 'DELETE',
            url: '/stocks',
            data: { productIds: ids }
        });
    }

    static archive(productId, value, clinic, isFromOrg = false) {
        return api.request({
            method: 'PUT',
            url: `/stocks/${productId}/archive?value=${value}&isFromOrg=${isFromOrg}${
                clinic ? `&clinic=${clinic}` : ''
            }`
        });
    }

    static filterSupplier(value) {
        return api.request({
            method: 'GET',
            url: `/stocks/filterSupplier?supplier=${value}`
        });
    }

    static filterBrand(value, clinic, isFromOrg = false) {
        return api.request({
            method: 'GET',
            url: `/stocks/filterBrand?brand=${value}&clinic=${clinic}&isFromOrg=${isFromOrg}`
        });
    }

    static filterCategory(value, clinic, isFromOrg = false) {
        return api.request({
            method: 'GET',
            url: `/stocks/filterCategory?${newQuery({ category: value, clinic, isFromOrg })}`
        });
    }

    static changeProductActive(id, value, clinic, isFromOrg = false) {
        return api.request({
            method: 'PUT',
            url: `/stocks/${id}/change-active?${newQuery({ value, clinic, isFromOrg })}`
        });
    }
}

export default ProductApi;
