export const supplierSyles = () => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    saveButton: {
        width: 'min-content'
    },
    selectField: {
        width: 200,
        backgroundColor: '#fff'
    }
});

export const brandsTableStyles = () => ({
    root: {
        marginTop: '8px'
    },
    tableContainer: {
        width: '100%',
        overflowX: 'auto'
    },
    brands: {
        fontSize: '14px',
        fontWeight: 400
    },
    fixedTableFooter: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
    }
});

export const supplierModalForm = () => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: 20,
        paddingTop: 20,
        '& > div': {
            width: '100%'
        }
    },
    accordion: {
        width: '100%'
    },
    form: {
        width: '95%',
        '& h4': {
            marginBottom: '2vh',
            textAlign: 'center'
        },
        '& label > span': {
            color: 'tomato'
        }
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        margin: '8px 0',
        justifyContent: 'space-between'
    },
    formItem: {
        width: '48%',
        marginRight: 8,
        marginTop: 8,
        marginBottom: 8,
        '& label': {
            fontFamily: 'Roboto, Arial, Sans-serif'
        },
        '& input': {
            height: 'inherit',
            boxSizing: 'border-box'
        }
    },
    brandsStyle: {
        width: '48% !important',
        marginRight: '8px !important',
        marginTop: 8,
        marginBottom: 8,
        paddingBottom: 16,
        '& .MuiFormControl-marginDense': {
            marginTop: 0
        }
    },
    autocompleteChipProps: {
        margin: 1,
        padding: 0,
        height: '98%',
        fontSize: 12
    },
    outlinedButton: {
        width: '120px !important',
        border: '1px solid #000',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    }
});
