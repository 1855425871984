import React, { useState, useEffect } from 'react';
import { Grid, withStyles, Typography, TextField, makeStyles, IconButton } from '@material-ui/core';

import { treatmentRecordStyles } from '../styles';
import { Autocomplete } from '@material-ui/lab';
import formStyles from './../../../collums-constants/styles/stylesheets/formStyles';
import { shortStr } from './../../../collums-constants/utils';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { DEFAULT_UNIT_VALUES } from '../../../collums-constants';
import InfoTooltip from '../../common/InfoTooltip';
import customDropdownSearch from '../../../collums-components/helpers/customDropdownSearch';

function TreatmentRecordsTab({
    classes,
    treatmentRecordsRef,
    treatmentRecords,
    setTreatmentRecords,
    toxinProducts,
    addNewTreatmentRecord
}) {
    const formClasses = makeStyles(formStyles)();

    const [toxinOptions, setToxinOptions] = useState(toxinProducts);
    const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);

    const updateToxinOptions = () => {
        const usedToxins = treatmentRecordsRef.current.filter(el => el.toxin).map(el => el.toxin.id);
        const toxinsAvailable = toxinProducts.filter(product => {
            return !usedToxins.includes(product.id);
        });

        setIsAddButtonDisabled(!toxinsAvailable.length || treatmentRecordsRef.current.length !== usedToxins.length);
        setToxinOptions(toxinsAvailable);
    };

    useEffect(() => {
        treatmentRecordsRef.current = [...treatmentRecords];
        updateToxinOptions();
        //eslint-disable-next-line
    }, [treatmentRecords, treatmentRecordsRef, toxinProducts]);

    return (
        <Grid container direction="row" spacing={4}>
            <Grid item xs={3}>
                <Typography variant="h4" style={{ fontSize: 16 }}>
                    Toxin treatment record
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="h4" style={{ fontSize: 16 }}>
                    Injection dose values (units)
                </Typography>
            </Grid>
            <Grid item xs={12} container>
                {treatmentRecords.map(treatmentRecord => {
                    return (
                        <div key={treatmentRecord.id} className={classes.optionContainer}>
                            <div className={classes.treatmentRecordOption}>
                                <div className={classes.toxin}>
                                    <div className={classes.toxinInfo}>
                                        <Typography>Toxin</Typography>
                                        <InfoTooltip
                                            title={
                                                'If you wish to change the default values in the 10 dose circles, go to admin/product to create a product that you use as your toxin and tag it “Toxin”. Select it from the drop down and then choose the values you wish to see in the dose circles and save. You can choose different dose circles for each toxin (if you have more than one) by using the blue + circle.'
                                            }
                                        />
                                    </div>
                                    <Autocomplete
                                        id={`toxin-selector-${treatmentRecord.id}`}
                                        className={`${formClasses.autocomplete} ${classes.autocomplete}`}
                                        options={toxinOptions || []}
                                        getOptionLabel={option => shortStr(option?.name || '', 30)}
                                        value={treatmentRecord.toxin}
                                        onChange={(_, value) => {
                                            treatmentRecordsRef.current.forEach(record => {
                                                if (record.id === treatmentRecord.id) {
                                                    Object.assign(record, { toxin: value });
                                                }
                                            });
                                            updateToxinOptions();
                                        }}
                                        filterOptions={customDropdownSearch}
                                        renderInput={params => (
                                            <TextField {...params} label="" variant="outlined" fullWidth />
                                        )}
                                    />
                                </div>
                                <div className={classes.doseUnit}>
                                    <div className={classes.doseInfo}>
                                        <Typography>Dose Unit Values</Typography>
                                        <InfoTooltip
                                            title={
                                                'Choose the unit values to drag onto the face. This can be different for each type of toxin.'
                                            }
                                        />
                                    </div>
                                    <div className={classes.doseUnitInputs}>
                                        {DEFAULT_UNIT_VALUES.map((_, index) => {
                                            return (
                                                <TextField
                                                    key={`unit-field-${index}-${treatmentRecord.id}`}
                                                    defaultValue={(treatmentRecord.doseUnits || [])[index]}
                                                    className={classes.doseUnitInput}
                                                    variant="outlined"
                                                    style={{
                                                        marginTop: index > 4 ? 10 : 0
                                                    }}
                                                    onChange={({ target }) => {
                                                        treatmentRecordsRef.current.forEach(record => {
                                                            if (record.id === treatmentRecord.id) {
                                                                (record.doseUnits || [])[index] = target.value;
                                                            }
                                                        });
                                                    }}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            {treatmentRecords.length > 1 && (
                                <div className={classes.deleteIconArea}>
                                    <IconButton
                                        onClick={() => {
                                            setTreatmentRecords(
                                                treatmentRecordsRef.current.filter(
                                                    record => record.id !== treatmentRecord.id
                                                )
                                            );
                                        }}
                                        className={classes.deleteIcon}
                                    >
                                        <DeleteOutlineOutlinedIcon />
                                    </IconButton>
                                </div>
                            )}
                        </div>
                    );
                })}
                <IconButton
                    onClick={() => {
                        addNewTreatmentRecord();
                    }}
                    className={classes.circleIcon}
                    disabled={isAddButtonDisabled}
                >
                    <AddCircleIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
}

export default withStyles(treatmentRecordStyles)(TreatmentRecordsTab);
