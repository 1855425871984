import React, { useEffect, useState, useCallback } from 'react';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    withStyles,
    IconButton,
    Typography
} from '@material-ui/core';

import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import View from '../common/View';
import Switch from '../../collums-components/components/common/customSwitch';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import JobApi from '../../collums-components/api/jobApi';
import { userPermissionsStyles } from './styles';
import { toastr } from 'react-redux-toastr';

const apps = ['calendar', 'pos', 'marketing', 'reporting', 'stock', 'employees', 'admin'];

function UserPermissions({ classes }) {
    const [jobs, setJobs] = useState([]);
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const loadData = useCallback(() => {
        setIsLoading(true);
        JobApi.query().then(res => {
            setJobs(res);
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const updateJobPermission = useCallback(
        async (job, app) => {
            setIsLoading(true);
            try {
                await JobApi.changeAppPermission(job.id, { [app]: !job.permissions?.[app] });
            } catch (err) {
                toastr.error("Only admins can change user's permissions");
                setIsLoading(false);
                return;
            }
            loadData();
        },
        [loadData]
    );

    return (
        <View>
            {isLoading && <LoadingScreen />}
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        {jobs.map(job => (
                            <TableCell key={job.id} className={classes.centerAlignedCell}>
                                {job.name}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {apps.map(app => (
                        <>
                            <TableRow key={app}>
                                <TableCell style={{ display: 'flex', alignItems: 'center' }}>
                                    {app === 'employees' ? 'Schedules' : app.charAt(0).toUpperCase() + app.slice(1)}
                                    {app === 'calendar' && (
                                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                    )}
                                </TableCell>
                                {jobs.map(job => (
                                    <TableCell key={job.id} className={classes.centerAlignedCell}>
                                        <Switch
                                            checked={job.permissions?.[app]}
                                            onChange={() => {
                                                updateJobPermission(job, app);
                                            }}
                                        />
                                    </TableCell>
                                ))}
                            </TableRow>

                            {app === 'calendar' && (
                                <>
                                    <TableRow key={app} className={!open && classes.subAccordion}>
                                        <TableCell className={classes.centerAlignedCell}>
                                            <Typography className={classes.accordionText}>
                                                Show patient contact info
                                            </Typography>
                                        </TableCell>
                                        {jobs.map(job => (
                                            <TableCell key={job.id} className={classes.centerAlignedCell}>
                                                <Switch
                                                    checked={job.permissions?.['showPatientInfo']}
                                                    onChange={() => {
                                                        updateJobPermission(job, 'showPatientInfo');
                                                    }}
                                                />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow key={app} className={!open && classes.subAccordion}>
                                        <TableCell className={classes.centerAlignedCell}>
                                            <Typography className={classes.accordionText}>
                                                Access medical records
                                            </Typography>
                                        </TableCell>
                                        {jobs.map(job => (
                                            <TableCell key={job.id} className={classes.centerAlignedCell}>
                                                <Switch
                                                    checked={job.permissions?.['accessMedicalRecords']}
                                                    onChange={() => {
                                                        updateJobPermission(job, 'accessMedicalRecords');
                                                    }}
                                                />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </>
                            )}
                        </>
                    ))}
                </TableBody>
            </Table>
        </View>
    );
}

export default withStyles(userPermissionsStyles)(UserPermissions);
