import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, TextField, Tooltip } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import { textInputStyles } from './styles';

class TextInput extends React.Component {
    renderTooltip() {
        const { field, classes } = this.props;
        if (!field.tooltip) {
            return null;
        }
        return (
            <Tooltip className={classes.tooltip} title={field.tooltip}>
                <IconButton>
                    <Help />
                </IconButton>
            </Tooltip>
        );
    }

    render() {
        const { field, onChange, classes } = this.props;
        return (
            <div className={`field field-${field.id} ${classes.container}`}>
                <TextField
                    id={field.id}
                    label={field.label}
                    type={this.props.type || 'text'}
                    value={field.controlledValue || field.value || ''}
                    error={null != field.isValid && !field.isValid}
                    disabled={field.disabled}
                    placeholder={field.placeholder}
                    helperText={field.errorMessages || field.description}
                    onChange={event => onChange(field.id, event.target.value)}
                    onKeyDown={event => field.onKeyEnter && 13 === event.keyCode && field.onKeyEnter()}
                    autoComplete="new-password"
                    fullWidth
                />
                {this.renderTooltip()}
            </div>
        );
    }
}

TextInput.propTypes = {
    type: PropTypes.string,
    field: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(textInputStyles)(TextInput);
