import isEmpty from 'lodash/isEmpty';
import { toastr } from 'react-redux-toastr';

const isInvalidCommission = commissions => {
    const transformRangeToNumber = string => {
        return Number(string.slice(1).replace(/,/g, ''));
    };

    if (!isEmpty(commissions)) {
        return Object.keys(commissions).some(key => {
            const current = commissions[key];

            if (current.allowFlatRate) {
                if (!current.flatRateType) {
                    toastr.error('No empty type allowed on commissions');
                    return true;
                }
                if (
                    current.flatRateValue === undefined ||
                    current.flatRateValue === '' ||
                    isNaN(Number(current.flatRateValue.slice(1).replace(/,/g, '')))
                ) {
                    toastr.error('No empty values allowed on commissions');
                    return true;
                }
            } else {
                if (current.commissionRanges?.length) {
                    let foundError = false;

                    for (const [index, range] of current.commissionRanges.entries()) {
                        if (
                            range.from === undefined ||
                            range.from === '' ||
                            isNaN(transformRangeToNumber(range.from))
                        ) {
                            toastr.error('No empty range allowed on commissions');
                            foundError = true;
                            break;
                        }
                        if (range.to === undefined || range.to === '' || isNaN(transformRangeToNumber(range.to))) {
                            foundError = true;
                            if (range.from.includes('0.00')) {
                                foundError = false;
                            }
                            if (foundError) {
                                toastr.error('No empty range allowed on commissions');
                                break;
                            }
                        }
                        if (isEmpty(range.commission)) {
                            toastr.error('No empty commission allowed');
                            foundError = true;
                            break;
                        }

                        if (
                            range.commission?.value === undefined ||
                            range.commission?.value === null ||
                            (typeof range.commission?.value === 'string' && range.commission.value.trim() === '') ||
                            isNaN(Number(range.commission.value))
                        ) {
                            toastr.error('No empty values allowed on commissions');
                            foundError = true;
                            break;
                        }

                        if (range.to && transformRangeToNumber(range.from) > transformRangeToNumber(range.to)) {
                            foundError = true;
                            toastr.error('Final range must be higher than initial range');
                            break;
                        }

                        // ? validate if range is not overlapping the previous one
                        if (index > 0) {
                            const previousRange = current.commissionRanges[index - 1];

                            if (transformRangeToNumber(previousRange.to) > transformRangeToNumber(range.from)) {
                                foundError = true;
                                toastr.error('Ranges must be on ascending order');
                                break;
                            }
                        }
                    }
                    return foundError;
                }
            }

            return false;
        });
    }
    return false;
};

export default isInvalidCommission;
