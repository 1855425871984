const locationTabs = [
    { name: 'Equipment', ref: '/location/equipment', group: 'clinic' },
    { name: 'Hours', ref: '/location/hours', group: 'clinic' },
    { name: 'Rooms', ref: '/location/rooms', group: 'clinic' },
    //   { name: 'Card Terminals', ref: '/location/card-terminals' },
    { name: 'Staff', ref: '/location/staff', group: 'clinic', customName: 'Staff Details' },
    { name: 'Discounts', ref: '/location/discounts', group: 'clinic' },
    { name: 'Alerts', ref: '/location/alerts', group: 'clinic' }
];

export default locationTabs;
