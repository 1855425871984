import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';

const styles = {
    dialogClass: {
        '& .MuiDialog-paper': {
            paddingBottom: 24
        }
    },
    content: {
        '& .MuiDialogContent-root': {
            paddingTop: 8,
            paddingBottom: 18
        }
    },
    closeButton: {
        position: 'absolute',
        right: 6,
        top: 6,
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 20
    }
};

const DialogFullHeight = withStyles(() => ({
    paper: {
        height: '100%'
    }
}))(MuiDialog);

class ModalCustomer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullWidth: true,
            maxWidth: 'xl'
        };
    }

    renderActions() {
        const { classes } = this.props;
        const buttons = [];

        if (this.props.onCancel) {
            buttons.push(
                <Button className={classes.cancelButton} key="cancel" onClick={this.props.onCancel}>
                    {this.props.cancelLabel || 'Cancel'}
                </Button>
            );
        }

        if (this.props.actions) {
            this.props.actions.forEach(action => {
                buttons.push(
                    <Button className={classes.baseButton} key="confirm" onClick={action.action}>
                        {action.label}
                    </Button>
                );
            });
        }

        if (this.props.extraButtons) {
            buttons.push(...this.props.extraButtons);
        }

        if (this.props.onConfirm) {
            buttons.push(
                <Button className={classes.confirmButton} key="confirm" onClick={this.props.onConfirm}>
                    {this.props.confirmLabel || 'Confirm'}
                </Button>
            );
        }
        return buttons;
    }

    render() {
        const { classes } = this.props;
        const Dialog = this.props.fullHeight ? DialogFullHeight : MuiDialog;
        return (
            <Dialog
                fullWidth
                maxWidth={this.props.size || 'xl'}
                id="modal"
                open={this.props.isOpen}
                onClose={this.props.onCancel}
                aria-labelledby={this.props.id}
                classes={{ root: classes.dialogClass }}
            >
                <DialogTitle id={this.props.id}>
                    <Typography style={{ fontSize: 18, ...this.props.titleStyle }}>{this.props.title}</Typography>
                    {this.props.hideCloseIcon ? (
                        ''
                    ) : (
                        <IconButton className={classes.closeButton} onClick={this.props.onClose}>
                            <Close />
                        </IconButton>
                    )}
                </DialogTitle>
                <div className={classes.content}>
                    <DialogContent>{this.props.children}</DialogContent>
                </div>
                <DialogActions>{this.renderActions()}</DialogActions>
            </Dialog>
        );
    }
}

ModalCustomer.propTypes = {
    classes: PropTypes.object,
    id: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    titleStyle: PropTypes.object,
    size: PropTypes.string,
    cancelLabel: PropTypes.string,
    confirmLabel: PropTypes.string,
    fullHeight: PropTypes.bool,
    actions: PropTypes.array,
    extraButtons: PropTypes.array,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    children: PropTypes.node.isRequired,
    hideCloseIcon: PropTypes.bool
};

export default withStyles(theme => ({ ...styles, ...modalsButtonStyles(theme) }))(ModalCustomer);
