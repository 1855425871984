import React from 'react';
import { withStyles, IconButton } from '@material-ui/core';
import CustomAutoComplete from '../CustomAutoComplete';
import { OrderedOptionStyles } from './styles';
import { PropTypes } from 'prop-types';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { swapPosition } from './../../../../../collums-components/helpers/index';

const OrderedOption = ({ classes, disabled, value, id, index, options, onChange, propertyValues }) => {
    const isMoveDisabled = isUp => {
        if (isUp) {
            if (index === 0 || disabled) return true;
            return false;
        }
        if (index === propertyValues.length - 1 || disabled) return true;
    };

    const onReorder = newIndex => {
        onChange(swapPosition(propertyValues, index, newIndex));
    };

    const onDeleteItem = () => {
        const filteredValues = propertyValues.filter((_, itemIndex) => {
            return itemIndex !== index;
        });
        onChange(filteredValues);
    };

    return (
        <div className={classes.flex}>
            <div className={classes.orderArrowsContainer}>
                <IconButton onClick={() => onReorder(index - 1)} disabled={isMoveDisabled(true)}>
                    <ArrowUpwardIcon
                        className={`${classes.arrowIcons} ${
                            disabled || isMoveDisabled(true) ? classes.arrowIconsDisabled : ''
                        }`}
                    />
                </IconButton>
                <IconButton onClick={() => onReorder(index + 1)} disabled={isMoveDisabled(false)}>
                    <ArrowDownwardIcon
                        className={`${classes.arrowIcons} ${
                            disabled || isMoveDisabled(false) ? classes.arrowIconsDisabled : ''
                        }`}
                    />
                </IconButton>
            </div>
            <CustomAutoComplete
                id={id}
                options={options}
                value={value}
                disabled={disabled}
                onChange={(_, newValue) => {
                    const newPropValues = propertyValues.map((el, itemIndex) => {
                        if (index === itemIndex) return newValue;
                        return el;
                    });
                    onChange(newPropValues);
                }}
            />
            <IconButton disabled={disabled} onClick={onDeleteItem}>
                <DeleteOutlineOutlinedIcon
                    className={disabled ? classes.deleteIconDisabled : classes.deleteIcon}
                    aria-disabled={disabled}
                />
            </IconButton>
        </div>
    );
};

OrderedOption.propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    classes: PropTypes.object.isRequired,
    getOptionLabel: PropTypes.func.isRequired,
    freeSolo: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool
};

export default withStyles(OrderedOptionStyles)(OrderedOption);
