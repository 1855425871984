export const htmlEditor = () => ({
    editor: {
        height: 430,
        width: '100% !important',

        '& .rdw-editor-main, & .cm-s-default': {
            borderColor: '#7C7E86',
            borderStyle: 'solid',
            borderWidth: 1
        },
        fontFamily: 'Roboto, Arial, "sans-serif" !important'
    },
    previewButton: {
        backgroundColor: '#2b78e4',
        textTransform: 'capitalize',
        color: 'white',
        borderRadius: '30px !important',
        '&:hover': {
            backgroundColor: '#286cd3'
        },
        marginTop: '16px !important',
        marginBottom: '16px !important',
        marginLeft: '0px !important'
    },
    consentArea: {
        width: '100%'
    }
});
