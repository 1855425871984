export const detailsFormModalStyles = () => ({
    modal: {
        '& .MuiDialog-paper': {
            backgroundColor: '#eeeeee'
        }
    }
});
export const ColorPickerStyles = () => ({
    colorInput: {
        width: '100px',
        marginTop: '8px',
        marginLeft: '8px',
        marginRight: '8px',
        marginBottom: '8px',
        '& input': {
            height: '1.1876em !important', // Material UI default input height
            boxSizing: 'initial !important'
        }
    },
    colorInputNoLabel: {
        width: '100px',
        marginLeft: '8px',
        marginRight: '8px',
        marginBottom: '8px',
        '& input': {
            height: '1.1876em !important', // Material UI default input height
            boxSizing: 'initial !important'
        }
    }
});
export const listTableStyles = () => ({
    table: {
        '& .MuiToolbar-root': {
            justifyContent: 'space-between',
            paddingLeft: 0,
            paddingBottom: 16,
            '& > div:nth-child(1)': {
                display: 'none'
            },
            '& > div:nth-child(3) svg': {
                fontSize: 38
            }
        },
        '& tr td:last-of-type div': {
            justifyContent: 'flex-end'
        },
        '& table': {
            overflow: 'hidden'
        }
    }
});

export const viewStyles = () => ({
    container: {
        margin: 7,
        padding: '11.5px 33px'
    }
});

export const listSelectorStyles = () => ({
    listContainer: {
        maxHeight: 200,
        overflowY: 'auto',
        border: '1px solid #c7c7c7',
        borderRadius: 5,
        marginTop: 10
    }
});

export const tagsStyles = () => ({
    tagsContainer: {
        marginTop: 8
    },
    autocomplete: {
        '& .MuiInputBase-root': {
            padding: '2px 8px'
        }
    },
    autocompleteInputProps: {
        fontSize: 14,
        fontWeight: 400
    },
    autocompleteLabelProps: {
        fontSize: 14,
        fontWeight: 400
    },
    autocompleteOptions: {
        fontSize: 14,
        fontWeight: 400
    },
    chip: {
        fontSize: 14
    }
});

export const filterSelectStyles = () => ({
    formControl: {
        width: 200,
        height: 'inherit',
        boxSizing: 'border-box',
        '& div > div': {
            height: 'inherit',
            boxSizing: 'border-box'
        }
    }
});

export const confirmModalStyles = () => ({
    modal: {
        '& .MuiDialog-paper': {
            backgroundColor: 'white',
            alignItems: 'center'
        }
    }
});

export const selectAutocompleteStyles = () => ({
    textFieldInputLabelProps: {
        marginTop: 6
    },
    textFieldOptions: {
        fontSize: 14
    },
    autocomplete: {
        marginRight: 20,
        width: 300,
        '& .MuiInputBase-root': {
            padding: '9.5px 4px',
            paddingRight: '25px !important'
        },
        '& .MuiAutocomplete-tag': {
            marginTop: 1
        }
    }
});

export const staffInputsStyles = () => ({
    textFieldInputLabelProps: {
        fontSize: 14
    },
    textFieldInputProps: {
        fontSize: 14
    }
});

export const SubHeaderPlaceholderStyles = () => ({
    container: {
        height: 38
    }
});

export const LocationTabStyles = () => ({
    formContent: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        fontFamily: 'Roboto, Arial, sans-serif !important'
    },
    formContentRow: {
        width: '100%'
    },
    locationsTitle: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 5,
        '& b': {
            fontSize: 14,
            fontWeight: 500,
            fontFamily: 'Roboto, Arial, Sans-serif !important',
            color: 'rgba(0, 0, 0, 0.87)'
        }
    },
    locationFilter: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
});

export const openedModalStyles = () => ({
    centerFlexContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paperContainer: {
        padding: 20,
        width: '80%',
        alignSelf: 'center',
        backgroundColor: '#eeeeee',
        '& .MuiDialogTitle-root': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    }
});

export const customModalStyles = () => ({
    closeButton: {
        position: 'absolute',
        right: 6,
        top: 6,
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 20
    },
    modalContainer: {
        backgroundColor: '#eeeeee',
        '& .MuiDialogTitle-root': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& h4': {
                fontSize: 18,
                fontWeight: 500
            }
        },
        borderRadius: 4,
        '& .MuiDialogContent-root': {
            maxHeight: '80vh',
            overflow: 'auto'
        },
        boxShadow: `
            0px 11px 15px -7px rgb(0, 0, 0, 20%), 
            0px 24px 38px 3px rgb(0, 0, 0, 14%), 
            0px 9px 46px 8px rgb(0, 0, 0, 12%)
        `,
        top: '50%',
        transform: 'translate(-50%, -50%)',
        margin: '0',
        width: 'calc(90% - 60px)',

        '@media (min-width: 1300px)': {
            width: '90%'
        }
    }
});

export const commissionSchemeStyles = () => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // maxWidth: '80%',
        '& .MuiPaper-root': {
            paddingBottom: 24
        }
    },
    root: {
        maxHeight: '90%',
        overflowY: 'scroll',
        padding: '0.5% 2.5%'
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        '& p': {
            fontSize: 16,
            fontWeight: 500
        },
        position: 'relative',
        margin: '5px 0'
    },
    closeBtn: {
        position: 'absolute',
        right: 0,
        top: 0,
        '& :hover': {
            cursor: 'pointer'
        }
    },
    formItem: {
        width: '20vw !important',
        marginTop: 8,
        marginRight: 8,
        marginBottom: 8,
        paddingRight: 0,
        '& label': {
            fontSize: 14,
            fontFamily: 'Roboto, Arial, Sans-serif'
        }
    },
    commissionTypes: {
        minWidth: '20vw',
        width: '100% !important',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    commissionRuleContainer: {},
    commissionRuleRow: {
        padding: '10px 0'
    },
    removeRuleButton: {
        backgroundColor: 'red !important',
        color: 'white',
        marginBottom: '15px !important'
    },
    commissionButton: {
        margin: 8,
        fontSize: 14,
        fontWeight: 400,
        textTransform: 'none',
        backgroundColor: '#3083EC'
    },
    tableContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    commissionCatSelectorContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        position: 'relative',
        '& > p': {
            width: '20%'
        },
        '& > div': {
            width: '80%',
            height: '100%',
            maxWidth: '565px',
            position: 'relative'
        }
    },
    flatContent: {
        width: '80%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    flatRateInputs: {
        display: 'flex',
        flexDirection: 'row'
    },
    textWarning: {
        fontSize: 14,
        marginBlockStart: 0,
        fontWeight: '400 !important'
    },
    formContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        paddingTop: '20px',
        // width: '70%',
        // flexWrap: 'wrap',
        fontFamily: 'Roboto, Arial, sans-serif !important'
    },
    formContentRow: {
        // width: '70%'
    },
    formTextField: {
        width: '100%',
        fontFamily: 'Roboto, Arial, Sans-serif !important'
    },
    accordionTitle: {
        '& p': {
            marginBottom: 10,
            fontSize: 14,
            fontWeight: 500
        }
    },
    accordionsForm: {
        '& .MuiExpansionPanelSummary-content': {
            '& p': {
                fontSize: 16,
                fontWeight: 400
            }
        }
    }
});
