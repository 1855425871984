import {
    Box,
    Button,
    Checkbox,
    IconButton,
    Menu,
    MenuItem,
    TablePagination,
    TextField,
    Typography
} from '@material-ui/core';
import Switch from '../../collums-components/components/common/customSwitch';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import CategoryApi from '../../collums-components/api/CategoryApi';
import ListTable from '../common/ListTable';
import View from '../common/View';
import CategoriesModal from './CategoriesModal';
import { categoriesViewStyles } from './styles';
import { buttonsStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import { inputSearchStyles } from '../../collums-constants/styles/stylesheets/inputSearchStyles';
import StopIcon from '@material-ui/icons/Stop';
import { Search, Clear } from '@material-ui/icons';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import { toastr } from 'react-redux-toastr';
import scrollTop from './../../services/scrollTop';
import { getLocationItem } from '../../collums-constants/utils';

const ActionButton = ({ classes, item, clinic, isFromOrg, openCategoryDetails, categories, refreshList }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = event => setAnchorEl(event.currentTarget);
    const closeMenu = () => setAnchorEl(null);
    return (
        <>
            <Button onClick={openMenu} className={classes.grayButton}>
                Options
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
                <MenuItem
                    onClick={() => {
                        openCategoryDetails(item.id, 'Archive');
                    }}
                >
                    {(isFromOrg ? item.archived : getLocationItem(item, clinic).archived) ? 'Unarchive' : 'Archive'}
                </MenuItem>
                <MenuItem
                    onClick={async () => {
                        try {
                            const _item = { ...item };
                            _item.locations.forEach(location => {
                                delete location.id;
                                location.equipments.forEach(equipment => {
                                    delete equipment.id;
                                });
                                location.rooms.forEach(room => {
                                    delete room.id;
                                });
                            });
                            const categoryNames = [
                                ...categories.map(category => category.name),
                                ...categories.map(category => category.subCategories.map(s => s.name))
                            ].flat();
                            const repeatedNames = categoryNames.filter(
                                el => el.startsWith(_item.name + ' - COPY') || el === _item.name
                            );
                            delete _item.active;
                            delete _item.archived;
                            delete _item.showOnline;
                            delete _item.tableData;
                            delete _item.id;
                            delete _item.subCategories;
                            _item.tags = _item.tags?.map(tag => tag.id);
                            if (_item.parentCategory) {
                                _item.parentId = _item.parentCategory;
                                delete _item.parentCategory;
                            }
                            _item.defaultTreatmentRecord = _item.defaultTreatmentRecord || undefined;
                            Object.keys(_item).forEach(key => {
                                if (_item[key] === undefined || _item[key] === '') {
                                    delete _item[key];
                                }
                            });
                            _item.formsToEmail = (_item.formsToEmail || []).map(el => ({ ...el, id: undefined }));
                            const amount = repeatedNames.length;
                            const newName = _item.name + ' - COPY'.repeat(amount);
                            await CategoryApi.create({ ..._item, name: newName, onlineName: newName });
                            toastr.success(`Category ${item.name} successfully copied!`);
                            refreshList();
                        } catch (e) {
                            toastr.error(e.data ? e.data.message : 'Something went wrong');
                        }
                    }}
                >
                    Copy
                </MenuItem>
                {/* <MenuItem onClick={() => openCategoryDetails(item.id, 'Remove')}>Delete</MenuItem> */}
            </Menu>
        </>
    );
};

class CategoriesView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showArchived: false,
            isLoading: false,
            categories: [],
            subCategories: [],
            rowsFetched: 0,
            index: 0,
            maxPage: 1,
            isModalOpen: false,
            methodType: '',
            rowsPerPage: 25,
            currentPage: [],
            nameFilter: '',
            typing: false,
            typingTimeout: 0,
            showInactive: true
        };
        this.onCategoryDetailsConfirm = this.onCategoryDetailsConfirm.bind(this);
        this.onDetailsCancel = this.onDetailsCancel.bind(this);
        this.openCategoryDetails = this.openCategoryDetails.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.changePage = this.changePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.refreshList = this.refreshList.bind(this);
        this.changeName = this.changeName.bind(this);
        this.changeCategoryActive = this.changeCategoryActive.bind(this);
        this.getSubcategoriesArray = this.getSubcategoriesArray.bind(this);
    }

    async changeCategoryActive(object, value) {
        if (!(object || {}).id) return;
        if (object.archived) {
            toastr.error(`Cannot change category ${object.name} active, because this category is archived`);
            return;
        }
        try {
            await CategoryApi.changeCategoryActive(object.id, this.props.isFromOrg, this.props.clinic, value);
            toastr.success(`Category ${object.name} successfully updated`);
            this.refreshList();
        } catch (err) {
            if (typeof err === 'object') {
                if (err.data && err.data.message) {
                    toastr.error(err.data.message);
                    return;
                }
            }
            toastr.error('Something went wrong');
        }
    }

    async changeCategoryShowOnline(object, value) {
        if (!(object || {}).id) return;
        if (object.archived) {
            toastr.error(`Cannot change category ${object.name} online, because this category is archived`);
            return;
        }
        if (!object.active) {
            toastr.error(`Cannot change category ${object.name} online, because this category is inactive`);
            return;
        }
        try {
            await CategoryApi.changeCategoryShowOnline(object.id, value, this.props.clinic, this.props.isFromOrg);
            toastr.success(`Category ${object.name} successfully updated`);
            this.refreshList();
        } catch (err) {
            if (typeof err === 'object') {
                if (err.data && err.data.message) {
                    toastr.error(err.data.message);
                    return;
                }
            }
            toastr.error('Something went wrong');
        }
    }

    async componentDidMount() {
        await this.refreshList();
    }

    async refreshList() {
        //if (this.state.isLoading) return;
        try {
            this.setState({ isLoading: true });
            let queryParams = `?archived=${this.state.showArchived}&name=${
                this.state.nameFilter ? encodeURIComponent(this.state.nameFilter) : ''
            }&active=${!this.state.showInactive}&count=${this.state.rowsPerPage}&clinic=${
                this.props.clinic ? this.props.clinic : ''
            }&isFromOrg=${this.props.isFromOrg}`;
            const response = await CategoryApi.adminList(queryParams);
            const size = response.size;
            if (response) {
                this.setState({
                    categories: response.categories,
                    subCategories: response.subCategories,
                    size,
                    maxPage: parseInt(size / this.state.rowsPerPage) + (size % this.state.rowsPerPage !== 0 ? 1 : 0),
                    isLoading: false,
                    index: 0,
                    rowsFetched: response.categories.length
                });
            }
            scrollTop();
        } catch (e) {
            toastr.error((e?.data && e?.data?.message) || 'Something went wrong');
        } finally {
            this.setState({ isLoading: false });
        }
    }

    getCategoryId() {
        const query = queryString.parse(this.props.location.search);
        return query.categoryId;
    }

    getMethod() {
        return this.state.methodType;
    }

    getSubcategoriesArray(subCategoriesArr) {
        let allSubCategories = [];

        for (const subCategories of subCategoriesArr) {
            allSubCategories.push(...subCategories);
        }
        return allSubCategories;
    }

    getCategoryDetails() {
        const categoryId = this.getCategoryId();
        const subCategoriesArr = this.getSubcategoriesArray(this.state.subCategories);

        const currentRow = [...this.state.categories, ...subCategoriesArr];

        let categoryDetails =
            currentRow.find(category => {
                return category.id === categoryId;
            }) || undefined;

        return categoryDetails || {};
    }

    openCategoryDetails(categoryId, method = 'Update') {
        this.setState({ methodType: method });
        this.props.history.push(
            `/resources/location/${this.props.clinic}/services/categories?${queryString.stringify({ categoryId })}`
        );
    }

    openCategoryArchive(categoryId) {
        this.props.history.push(
            `/resources/location/${this.props.clinic}/services/categories?${queryString.stringify({ categoryId })}`
        );
    }

    onCategoryDetailsConfirm() {
        this.props.history.push(`/resources/location/${this.props.clinic}/services/categories`);
        this.refreshList();
    }

    onDetailsCancel() {
        this.props.history.push(`/resources/location/${this.props.clinic}/services/categories`);
    }

    async changePage(direction) {
        if (direction < 0) {
            if (!this.state.index) return;
            this.setState({ index: this.state.index - 1 });
        } else {
            if (this.state.maxPage === this.state.index + 1) return;
            if (this.state.rowsFetched < this.state.size) {
                this.setState({ isLoading: true });
                const response = await CategoryApi.adminList(
                    `?skip=${(this.state.index + 1) * this.state.rowsPerPage}&active=${!this.state
                        .showInactive}&count=${this.state.rowsPerPage}&archived=${this.state.showArchived}&name=${
                        this.state.nameFilter ? encodeURIComponent(this.state.nameFilter) : ''
                    }&isFromOrg=${this.props.isFromOrg}&clinic=${this.props.clinic ? this.props.clinic : ''}`
                );
                const { categories, subCategories } = response;
                const newCategories = [...this.state.categories, ...categories];
                const newSubCategories = [...this.state.subCategories, ...subCategories];
                if (response) {
                    this.setState({
                        index: this.state.index + 1,
                        categories: newCategories,
                        subCategories: newSubCategories,
                        rowsFetched: newCategories.length
                    });
                }
                this.setState({ isLoading: false });
            } else {
                this.setState({
                    index: this.state.index + 1
                });
            }
        }
        scrollTop();
    }

    handleChangePage(e, newPage) {
        this.changePage(newPage > this.state.index ? 1 : -1);
    }

    async handleChangeRowsPerPage(event) {
        const rowsPerPage = Number(event.target.value);
        await this.setState({
            index: 0,
            rowsPerPage
        });
        this.refreshList();
    }

    changeName(event) {
        if (this.state.typingTimeout) clearTimeout(this.state.typingTimeout);
        const self = this;
        this.setState({
            nameFilter: event.target.value,
            typing: false,
            typingTimeout: setTimeout(function() {
                self.refreshList();
            }, 800)
        });
    }

    render() {
        const startRow = this.state.index * this.state.rowsPerPage;
        const endRow = startRow + this.state.rowsPerPage;
        const categoriesSlice = this.state.categories.slice(startRow, endRow);
        const subCategoriesSlice = this.state.subCategories.slice(startRow, endRow);
        const subCategoriesList = [];
        subCategoriesSlice.map(element => {
            element.map(subElement => {
                subCategoriesList.push(subElement);
                return subElement;
            });
            return element;
        });
        const rows = [...categoriesSlice, ...subCategoriesList];
        return (
            <View>
                <LoadingScreen isVisible={this.state.isLoading} />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        id="search-input"
                        onChange={this.changeName}
                        variant="outlined"
                        className={this.props.classes.inputSearch}
                        InputProps={{
                            endAdornment: (
                                <>
                                    <IconButton
                                        className={this.props.classes.icons}
                                        onClick={() => {
                                            this.changeName({ target: { value: '' } });
                                        }}
                                        disabled={!this.nameFilter}
                                    >
                                        <Clear style={!this.nameFilter ? { color: 'transparent' } : {}} />
                                    </IconButton>
                                    <IconButton
                                        position="end"
                                        onClick={this.changeName}
                                        className={this.props.classes.icons}
                                    >
                                        <Search />
                                    </IconButton>
                                </>
                            )
                        }}
                    />
                    {(this.props.clinic === 'organisation' || this.props.allClinics.length === 1) && (
                        <IconButton onClick={() => this.openCategoryDetails('new', 'Create')}>
                            <AddCircleIcon className={this.props.classes.addCircleStyle} />
                        </IconButton>
                    )}
                </div>
                <div className={this.props.classes.checkboxcontainer}>
                    <Checkbox
                        checked={this.state.showArchived}
                        onChange={async (e, value) => {
                            await this.setState({ showArchived: value });
                            this.refreshList();
                        }}
                        name="checkedB"
                        color="primary"
                    />
                    <label>Show Archived</label>

                    {/*<Checkbox*/}
                    {/*    checked={this.state.showInactive}*/}
                    {/*    onChange={async (e, value) => {*/}
                    {/*        await this.setState({ showInactive: value });*/}
                    {/*        this.refreshList();*/}
                    {/*    }}*/}
                    {/*    name="checkedB"*/}
                    {/*    color="primary"*/}
                    {/*/>*/}
                    {/*<label>Show Inactive</label>*/}
                </div>
                <ListTable
                    tableClasses={this.props.classes.table}
                    data={rows}
                    defaultExpanded={true}
                    columns={[
                        {
                            title: 'Active',
                            render: item => {
                                return (
                                    <Switch
                                        disabled={
                                            this.props.isFromOrg
                                                ? item.archived
                                                : getLocationItem(item, this.props.clinic)?.archived
                                        }
                                        checked={
                                            this.props.isFromOrg
                                                ? item.active
                                                : getLocationItem(item, this.props.clinic)?.active
                                        }
                                        onChange={(e, value) => {
                                            this.changeCategoryActive(item, value);
                                        }}
                                    />
                                );
                            }
                        },
                        {
                            title: 'Name',
                            field: 'name',
                            render: item => {
                                return (
                                    <Box
                                        onClick={() => this.openCategoryDetails(item.id, 'Update')}
                                        style={{ display: 'flex' }}
                                    >
                                        <StopIcon style={{ color: item.color }} fontSize="small" />
                                        <Typography>
                                            <Link style={{ textDecoration: 'none', color: '#3083EC' }}>
                                                {item.name}
                                            </Link>
                                        </Typography>
                                    </Box>
                                );
                            },
                            cellStyle: { width: '40%' }
                        },
                        {
                            title: 'Online',
                            render: item => {
                                return (
                                    <Switch
                                        disabled={
                                            (this.props.isFromOrg
                                                ? item.archived
                                                : getLocationItem(item, this.props.clinic)?.archived) ||
                                            (this.props.isFromOrg
                                                ? !item.active
                                                : !getLocationItem(item, this.props.clinic)?.active)
                                        }
                                        checked={
                                            this.props.isFromOrg
                                                ? item.showOnline
                                                : getLocationItem(item, this.props.clinic)?.showOnline
                                        }
                                        onChange={(e, value) => {
                                            this.changeCategoryShowOnline(item, value);
                                        }}
                                    />
                                );
                            }
                        },
                        {
                            title: '',
                            render: item => (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <ActionButton
                                        clinic={this.props.clinic}
                                        isFromOrg={this.props.isFromOrg}
                                        refreshList={this.refreshList}
                                        categories={this.state.categories}
                                        openCategoryDetails={this.openCategoryDetails}
                                        item={item}
                                        classes={this.props.classes}
                                    />
                                </div>
                            )
                        }
                    ]}
                    rowStyle={rowData => {
                        if ((rowData || {}).parentCategory) {
                            return {
                                backgroundColor: (() => {
                                    if (
                                        this.props.isFromOrg
                                            ? !rowData.archived
                                            : !getLocationItem(rowData, this.props.clinic).archived
                                    ) {
                                        return '#f0f0f0';
                                    }
                                    return '#ff9e9e';
                                })()
                            };
                        }
                        return {
                            backgroundColor: (() => {
                                if (
                                    this.props.isFromOrg
                                        ? !rowData.archived
                                        : !getLocationItem(rowData, this.props.clinic).archived
                                ) {
                                    return 'white';
                                }
                                return '#ff9e9e';
                            })()
                        };
                    }}
                    parentChildData={(row, rows) => {
                        return rows.find(a => a.id === row.parentCategory);
                    }}
                />
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                    <TablePagination
                        width={'100%'}
                        rowsPerPageOptions={[10, 25, 50]}
                        count={this.state.size}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.index}
                        SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </div>
                {this.getCategoryId() ? (
                    <CategoriesModal
                        isFromOrg={this.props.isFromOrg}
                        key={this.getCategoryId()}
                        categoryId={this.getCategoryId()}
                        category={this.getCategoryDetails()}
                        onConfirm={this.onCategoryDetailsConfirm}
                        onCancel={this.onDetailsCancel}
                        methodType={this.getMethod()}
                        clinic={this.props.clinic}
                        allClinics={this.props.allClinics}
                    />
                ) : (
                    <></>
                )}
            </View>
        );
    }
}

CategoriesView.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
};

export default withRouter(
    withStyles(theme => ({ ...inputSearchStyles(theme), ...buttonsStyles(theme), ...categoriesViewStyles(theme) }))(
        CategoriesView
    )
);
