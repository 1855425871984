import React, { useState, useRef } from 'react';
import View from '../common/View';
import ListPagination from '../common/ListPagination';
import StockApi from './../../api/StockApi';
import { toastr } from 'react-redux-toastr';
import LoadingScreen from './../../collums-components/components/common/loadingScreen';
import { stringify } from 'query-string';
import BrandModal from './BrandModal/index';
import ConfirmModal from '../common/ConfirmModal';
import { getLocationItem } from '../../collums-constants/utils';

const ProductBrandView = ({ isFromOrg, clinic, clinics, allClinics }) => {
    const filters = useRef({
        name: '',
        inactive: false,
        archived: false
    });
    const [brands, setBrands] = useState([]);
    const [maxBrands, setMaxBrands] = useState(0);
    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [isBrandModalOpen, setIsBrandModalOpen] = useState(false);
    const [isArchiveOpen, setIsArchiveOpen] = useState(false);
    const [selectedBrand, setSelectedBrand] = useState();

    const ApiCall = async (skip, limit, queryParam = '', refreshing = false) => {
        const query = {
            name: '',
            inactive: filters.current.inactive,
            archived: filters.current.archived
        };
        if (!queryParam && filters.current.name) {
            Object.assign(query, filters.current);
        }
        if (queryParam.indexOf('name') > -1) {
            const splitByFilterName = queryParam.split('name=')[1];
            if (splitByFilterName) {
                const filterName = splitByFilterName.split('&')[0];
                filters.current.name = filterName;
                query.name = filterName;
            }
        } else filters.current.name = '';
        if (queryParam.indexOf('archived') > -1) {
            if (queryParam.indexOf('archived=false') > -1) {
                query.archived = false;
                filters.current.archived = false;
            } else {
                query.archived = true;
                filters.current.archived = true;
            }
        }
        if (queryParam.indexOf('inactive') > -1) {
            if (queryParam.indexOf('inactive=false') > -1) {
                query.inactive = false;
                filters.current.inactive = false;
            } else {
                query.inactive = true;
                filters.current.inactive = true;
            }
        }
        if (!isFromOrg) {
            query.clinic = clinic;
        }
        query.isFromOrg = isFromOrg;

        try {
            setIsLoading(true);
            const newData = await StockApi.getBrands(skip, limit, stringify(query));
            if (refreshing) {
                setBrands(newData.brands);
                setPage(0);
                setMaxBrands(newData.size);
            }
            setIsLoading(false);
            return newData.brands;
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong');
            setIsLoading(false);
            return [];
        }
    };

    const reload = () => {
        ApiCall(0, rowsPerPage, '', true);
    };

    const changeActive = async brand => {
        if (brand.archived) return toastr.error('Cannot active an archived Brand');
        try {
            setIsLoading(true);
            const value = isFromOrg ? !brand.active : !getLocationItem(brand, clinic).active;
            await StockApi.updateBrandActive(brand.id, value, clinic, isFromOrg);
            toastr.success('Brand updated successfully');
            reload();
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong');
            setIsLoading(false);
        }
    };

    const showArchiveModal = brand => {
        setSelectedBrand(brand);
        setIsArchiveOpen(true);
    };

    const closeArchiveModal = () => {
        setIsArchiveOpen(false);
        setSelectedBrand();
    };

    const onConfirmArchive = async () => {
        if (!selectedBrand) return;
        try {
            setIsLoading(true);
            const value = isFromOrg ? !selectedBrand.archived : !getLocationItem(selectedBrand, clinic).archived;
            await StockApi.archiveBrand(selectedBrand.id, value, clinic, isFromOrg);
            toastr.success(`Brand successfully ${value ? 'unarchived' : 'archived'}`);
            closeArchiveModal();
            reload();
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong');
            closeArchiveModal();
            setIsLoading(false);
        }
    };

    const editBrand = brand => {
        setSelectedBrand(brand);
        setIsBrandModalOpen(true);
    };

    return (
        <>
            {isBrandModalOpen && (
                <BrandModal
                    isVisible
                    isFromOrg={isFromOrg}
                    clinic={clinic}
                    clinics={clinics}
                    onClose={shouldReload => {
                        if (shouldReload) {
                            reload();
                        }
                        setSelectedBrand();
                        setIsBrandModalOpen(false);
                    }}
                    brand={selectedBrand}
                    allClinics={allClinics}
                />
            )}
            {isArchiveOpen && (
                <ConfirmModal
                    isOpen
                    setIsOpen={closeArchiveModal}
                    refreshList={reload}
                    onConfirm={onConfirmArchive}
                    title={`${selectedBrand.archived ? 'Unarchive' : 'Archive'} Brand`}
                    content={`Are you sure you want ${selectedBrand.archived ? 'unarchive' : 'archive'} this brand?`}
                />
            )}
            <LoadingScreen isVisible={isLoading} />
            <View>
                <ListPagination
                    isFromOrg={isFromOrg}
                    clinic={clinic}
                    page={page}
                    setPage={setPage}
                    data={brands}
                    maxSize={maxBrands}
                    defaultColumns={[
                        { title: 'Active', field: 'active' },
                        { title: 'Name', field: 'name', onClick: editBrand }
                    ]}
                    filters={[
                        { name: 'archived', label: 'Show Archived', value: false },
                        { name: 'inactive', label: 'Show Inactive', value: false }
                    ]}
                    actions={[
                        {
                            title: 'archive',
                            action: showArchiveModal
                        }
                    ]}
                    ApiCall={ApiCall}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    onCreate={() => {
                        setSelectedBrand();
                        setIsBrandModalOpen(true);
                    }}
                    nameFilter={true}
                    filterName="name"
                    highlightArchived={true}
                    activeCall={changeActive}
                    allClinics={allClinics}
                />
            </View>
        </>
    );
};

export default ProductBrandView;
