const SharedServicesOrgAndLocProps = [
    {
        key: 'archived'
    },
    {
        key: 'active'
    },
    {
        key: 'category'
    },
    {
        key: 'subCategory'
    },
    {
        key: 'defaultNetPrice',
        locKey: 'netPrice'
    },
    {
        key: 'defaultGrossPrice',
        locKey: 'grossPrice'
    },
    {
        key: 'onlineName'
    },
    {
        key: 'order'
    },
    {
        key: 'onlineDescription'
    },
    {
        key: 'onlineSubtitle'
    },
    {
        key: 'allowCommission'
    },
    {
        key: 'taxType'
    }
];

export default SharedServicesOrgAndLocProps;
