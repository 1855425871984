const AutoCompleteStyles = () => ({
    autocomplete: {
        width: 250,
        minHeight: 38,
        '& input': {
            fontSize: '14px',
            paddingRight: '26px !important'
        },
        '& .MuiOutlinedInput-root': {
            height: '38px !important'
        },
        '& .MuiAutocomplete-inputRoot': {
            padding: '0 !important',
            paddingLeft: '10px !important'
        }
    }
});

export default AutoCompleteStyles;
