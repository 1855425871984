import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { emailEditor as styles } from './styles';
import { withStyles } from '@material-ui/core';
import base64converter from './helpers/base64converter';
import NotificationApi from '../../../../api/NotificationApi';
import MergeTagsBox from '../../../../collums-components/components/common/MergeTagsBox';
import TinyMce from '../../../../collums-components/components/common/TinyMce/TinyMce';
import { toastr } from 'react-redux-toastr';
import LoadingScreen from '../../../../collums-components/components/common/loadingScreen';

const base64extractor = content => {
    return [...content.matchAll(/img.*?src="(.*?)"/gm)]
        .map(el => {
            return el[1] && el[1].includes('data:') ? el[1] : [];
        })
        .filter(array => array.length);
};

const EmailEditor = ({
    classes,
    mergeTags,
    setLoadingUpload,
    loadingUpload,
    editor,
    message,
    setMessage,
    lastClickedElement,
    setUnsavedChanges,
    defaultValue,
    setSubject,
    subject,
    pointerPosition,
    setPointerPosition
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const handleEditorState = async content => {
        let data = content || '';

        // Extract the base64 images
        const base64img = data.includes('data:image') ? base64extractor(data) : null;

        // base64 images found in the editor
        if (base64img) {
            setLoadingUpload({ ...loadingUpload, visible: true });
            // Convert each base64 image to file
            const fileimg = base64img.map(img =>
                base64converter(img, `image-${new Date().getTime()}.${img.split(/[/;]/g)[1]}`)
            );

            const path = [];

            // Insert each image in the database
            const promises = await fileimg.map(async img => path.push(await NotificationApi.uploadFile(img)));

            // Await until all the images are inserted in the bucket
            await Promise.all(promises);

            // Replace all base64 images by S3 link files
            path.forEach(el => (data = data.replace(/<img src="data:.*?>/, `<img src="${el}">`)));
        }

        setMessage(data);
        setUnsavedChanges();
    };

    // If new image is inserted, instant
    const handleInputEditor = data => (data.includes('data:image') ? handleEditorState(data) : setMessage(data));

    const onEditorChangeHandler = content => {
        if (message !== content) setUnsavedChanges(true);
        if (content.length <= 1) setMessage(content);
        handleInputEditor(content);
    };

    const onEditorBlurHandler = (event, editor) => {
        if (editor.isDirty()) {
            handleEditorState(editor.getContent());
        }
    };

    const onEditorClickHandler = () => {
        if (lastClickedElement) {
            lastClickedElement.current = null;
        }
    };

    const defaultConfig = {
        selector: '.tinymce',
        plugins: ['lists', 'code', 'image', 'link', 'media', 'table'],
        toolbar:
            'undo redo | ' +
            'styles | ' +
            'bold italic strikethrough forecolor backcolor | ' +
            'link image media | ' +
            'table | ' +
            'indent align bullist numlist | ' +
            'code removeformat',
        table_toolbar:
            'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
        toolbar_mode: 'wrap',
        fixed_toolbar_container: '.toolbar',
        menubar: true,
        inline: false,
        auto_focus: 'editor-1',
        object_resizing: false,
        placeholder: 'Write here...',
        images_file_types: 'jpeg,jpg,png,gif',
        browser_spellcheck: true,
        automatic_uploads: true,
        file_picker_types: 'image,file',
        file_picker_callback: function(cb, value, meta) {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', meta.filetype === 'image' ? 'image/*' : '*/*');

            input.onchange = function() {
                setIsLoading(true);
                const file = this.files[0];
                const reader = new FileReader();

                reader.onload = function() {
                    const dataURL = reader.result;

                    fetch(
                        (process.env.REACT_APP_BACKEND_URL || process.env.COLLUMS_API_URL) + '/email-attachment-upload',
                        {
                            method: 'POST',
                            headers: {
                                Authorization: localStorage.getItem('token'),
                                'Content-Type': 'application/json',
                                Accept: 'application/json'
                            },
                            body: JSON.stringify({ dataURL, fileName: file.name })
                        }
                    )
                        .then(response => {
                            if (response.status === 413) {
                                throw new Error('File exceeds max size 50MB');
                            }
                            return response.json();
                        })
                        .then(data => {
                            cb(data.imageUrl, { alt: file.name, text: file.name });
                            setIsLoading(false);
                        })
                        .catch(e => {
                            setIsLoading(false);
                            if (e.message) {
                                toastr.error(e.message);
                            } else {
                                toastr.error('Error uploading file. Please try again.');
                            }
                        });
                };

                reader.readAsDataURL(file);
            };

            input.click();
        }
    };

    return (
        <>
            <div style={{ display: 'flex' }}>
                <div
                    onClick={() => {
                        lastClickedElement.current = null;
                    }}
                    className={classes.emailRoot}
                >
                    {/*<JoditEditor*/}
                    {/*    ref={editor}*/}
                    {/*    value={form}*/}
                    {/*    defaultValue={defaultValue}*/}
                    {/*    className={classes.editor}*/}
                    {/*    name="email-editor"*/}
                    {/*    setMessage={setMessage}*/}
                    {/*    onChange={data => {*/}
                    {/*        if (message !== data) setUnsavedChanges(true);*/}
                    {/*        if (data.length <= 1) setMessage(data);*/}
                    {/*        handleInputEditor(data);*/}
                    {/*    }}*/}
                    {/*    onBlur={data => handleEditorState(data)}*/}
                    {/*    tabIndex={1} // tabIndex of textarea*/}
                    {/*/>*/}
                    <TinyMce
                        value={message || defaultValue}
                        className={classes.editor}
                        refEditor={editor}
                        onChange={onEditorChangeHandler}
                        init={{
                            ...defaultConfig,
                            selector: 'textarea',
                            height: '100%',
                            width: '100%',
                            max_height: 700,
                            min_height: 430
                        }}
                        editorProps={{
                            onBlur: onEditorBlurHandler,
                            onClick: onEditorClickHandler
                        }}
                    />
                    {isLoading && <LoadingScreen />}
                </div>
                <MergeTagsBox
                    height="400px"
                    mergeTags={mergeTags}
                    onClick={element => {
                        if (lastClickedElement.current) {
                            if (!subject) {
                                setSubject(element);
                            } else {
                                setSubject(
                                    subject.substring(0, pointerPosition) +
                                        element +
                                        subject.substring(pointerPosition, subject.length)
                                );
                            }
                            setTimeout(() => {
                                lastClickedElement.current.focus();
                            }, 10);
                        } else {
                            editor.current.insertContent(element);
                            setMessage(editor.current.getContent());
                            setUnsavedChanges();
                        }
                        setPointerPosition(pointerPosition + element.length);
                    }}
                />
            </div>
        </>
    );
};

EmailEditor.propTypes = {
    classes: PropTypes.object.isRequired,
    setLoadingUpload: PropTypes.func,
    loadingUpload: PropTypes.object,
    editor: PropTypes.object
};

export default React.memo(withStyles(styles)(EmailEditor));
