import React, { useEffect, useRef, useState } from 'react';
import {
    Button,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
    withStyles,
    makeStyles,
    Checkbox,
    FormControlLabel,
    RadioGroup,
    Radio
} from '@material-ui/core';
import notificationsStyle from '../style';
import EmailEditor from './components/TinyEmailEditor';
import NotificationApi from '../../../api/NotificationApi';
import { toastr } from 'react-redux-toastr';
import EmailTemplate from './components/EmailTemplate';
import { KeyboardTimePicker } from '@material-ui/pickers';
import Preview from './components/Preview';

import { modalsButtonStyles } from '../../../collums-constants/styles/stylesheets/buttonsStyles';
import { getCurrentTimezonedDate } from '../../../collums-components/helpers/timezone';
import TemplatesApi from '../../../api/templatesApi';

const EmailTab = ({ classes, notification, closeAccordion, setHaveEmailUnsavedChanges, isPreAfterCare }) => {
    const globalStyles = makeStyles(modalsButtonStyles)();
    const [sendType, setSendType] = useState(
        notification.email.isReminder ? 'sendBeforeAppointment' : 'sendOnCheckout'
    );

    const mergeTags = notification.email.mergeTags.map(element => `{${element}}`);
    const editor = useRef();
    const subjectRef = useRef();
    let targetOptions;
    if (notification.from === 'Appointment') {
        targetOptions = ['Clinic', 'Customer', 'Practitioner'];
    } else {
        if (notification.from === 'User') targetOptions = ['Customer'];
        else targetOptions = ['Clinic', 'Customer'];
    }
    let triggerOptions;
    if (notification.from === 'Appointment') {
        triggerOptions = [
            { label: 'appointment cancellation', value: 'cancel' },
            { label: 'appointment creation', value: 'creation' },
            { label: 'reschedule appointment', value: 'reschedule' }
        ];
    } else {
        if (notification.from === 'User')
            triggerOptions = [
                { label: 'sign up', value: 'signUp' },
                { label: 'click on forgot password', value: 'forgotPassword' },
                { label: 'click on reset password', value: 'resetPassword' }
            ];
        else
            triggerOptions = [
                { label: 'invoice payment', value: 'invoicePayment' },
                { label: 'invoice refund', value: 'invoiceRefund' }
            ];
    }

    const [loadingUpload, setLoadingUpload] = useState({ visible: false, status: 'loading' });
    const [message, setMessage] = useState(notification ? notification.email.content : '');
    const [emailList, setEmailList] = useState('');
    const [emailTemplates, setEmailTemplates] = useState([]);
    const [isSavingTemplate, setIsSavingTemplate] = useState(false);
    const [subject, setSubject] = useState(notification ? notification.email.subject : '');
    const [, setSelectionPosition] = useState(0);
    const [selectedTemplate, setSelectedTemplate] = useState();
    const [target, setTarget] = useState(notification ? notification.email.target : targetOptions[0]);
    const trigger = notification ? notification.email.trigger : triggerOptions[0].value;
    const [selectedTime, setSelectedTime] = useState(notification ? notification.email.sendReminder?.value || 1 : 1);
    const [selectedType, setSelectedType] = useState(
        notification ? notification.email.sendReminder?.type || 'days' : 'days'
    );
    const [hourOfDay, setHourOfDay] = useState(9);
    const [minuteOfDay, setMinuteOfDay] = useState(0);
    const [isReminder, setIsReminder] = useState(notification ? notification.email.isReminder : false);
    const [preview, setPreview] = useState({});
    const [previewVisibility, setPreviewVisibility] = useState(false);
    const [templateIndex, setTemplateIndex] = useState('');
    const [pointerPosition, setPointerPosition] = useState(0);
    const lastClickedElement = useRef(null);
    const [sendOnAppointmentCreation, setSendOnAppointmentCreation] = useState(
        notification.email.sendOnAppointmentCreation
    );

    useEffect(() => {
        if (notification) {
            const [hour, minute] = notification.email.timeOfDayToSend.split(':');
            setHourOfDay(Number(hour));
            setMinuteOfDay(Number(minute));
        }
        /*eslint-disable-next-line */
    }, []);

    const timeArray = new Array(100).fill(100).map((el, index) => index + 1);

    const keyboardTimeValue = getCurrentTimezonedDate();
    keyboardTimeValue.setHours(hourOfDay);
    keyboardTimeValue.setMinutes(minuteOfDay);

    const changeTime = e => {
        if (!e) return;
        const hours = e.getHours();
        const min = e.getMinutes();
        setHourOfDay(hours);
        setMinuteOfDay(min);
        setHaveEmailUnsavedChanges(true);
    };

    const saveData = async () => {
        if (!subject) {
            toastr.error('Missing subject');
            return;
        }
        if (!target) {
            toastr.error('Missing target');
            return;
        }
        if (!isReminder && !trigger) {
            toastr.error('Missing occasion');
            return;
        }
        const payload = {
            content: message,
            isReminder,
            trigger,
            sendReminder: {
                value: selectedTime,
                type: selectedType
            },
            subject,
            timeOfDayToSend: `${hourOfDay < 10 ? `0${hourOfDay}` : hourOfDay}:${
                minuteOfDay < 10 ? `0${minuteOfDay}` : minuteOfDay
            }`,
            ...(notification.from === 'Form' ? { sendOnAppointmentCreation } : {})
        };
        try {
            await NotificationApi.updateEmail(notification.id, payload);
            toastr.success('Notification email successfully updated!');
            setHaveEmailUnsavedChanges(false);
            closeAccordion();
        } catch (err) {
            if (typeof err === 'object') {
                if (err.data && err.data.message) {
                    toastr.error(err.data.message);
                    return;
                }
            }
            toastr.error('Something went wrong');
        }
    };

    const loadTemplates = () => {
        TemplatesApi.getTemplates({
            type: 'email'
        }).then(res => setEmailTemplates(res.data));
    };

    useEffect(loadTemplates, []);

    useEffect(() => {
        if (!selectedTemplate) return;
        emailTemplates.forEach((template, index) => {
            if (template.name === selectedTemplate.name) {
                setTemplateIndex(index);
            }
        });
        //eslint-disable-next-line
    }, [emailTemplates]);

    const closeTemplateModal = newTemplate => {
        setIsSavingTemplate(false);
        if (newTemplate) {
            setSelectedTemplate(newTemplate);
            loadTemplates();
        }
    };

    const saveTemplateAction = () => {
        if (message) setIsSavingTemplate(true);
        else toastr.warning('Content cannot be blank');
    };

    const ReminderOptions = ({ sendEmail = false }) => {
        return (
            <>
                <div className={classes.reminderContainer}>
                    <Typography>Send {sendEmail ? 'email' : 'reminder'}:</Typography>
                    <Select
                        id="select-remind-time"
                        variant="outlined"
                        defaultValue={selectedTime}
                        inputProps={{
                            className: classes.selectInputProps
                        }}
                        style={{ width: 70, marginLeft: 5 }}
                        onChange={evt => {
                            setSelectedTime(evt.target.value);
                            setHaveEmailUnsavedChanges(true);
                        }}
                    >
                        {timeArray.map((time, index) => {
                            return (
                                <MenuItem key={index} value={time}>
                                    {time}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <Select
                        id="select-remind-time-type"
                        variant="outlined"
                        defaultValue={selectedType}
                        inputProps={{
                            className: classes.selectInputProps
                        }}
                        style={{ width: 100, marginRight: 5 }}
                        onChange={evt => {
                            setSelectedType(evt.target.value);
                            setHaveEmailUnsavedChanges(true);
                        }}
                    >
                        <MenuItem value="days">day(s)</MenuItem>
                        <MenuItem value="hours">hour(s)</MenuItem>
                    </Select>
                    <Typography>before appointment</Typography>
                </div>
                {selectedType === 'days' && (
                    <div style={{ display: 'flex', marginTop: 10 }}>
                        <KeyboardTimePicker
                            margin="normal"
                            id="hour-and-minute"
                            value={keyboardTimeValue}
                            onChange={changeTime}
                            KeyboardButtonProps={{
                                'aria-label': 'change time'
                            }}
                            TextFieldComponent={props => (
                                <TextField
                                    {...props}
                                    style={{ height: 30, width: 150 }}
                                    label="Time of day to send"
                                    variant="outlined"
                                />
                            )}
                            ampm={false}
                        />
                    </div>
                )}
            </>
        );
    };

    const sendTestEmail = async () => {
        if (!emailList) {
            return toastr.warning('Please enter an email address');
        }
        if (emailList && message) {
            await NotificationApi.sendTests(true, {
                list: emailList,
                notificationTemplate: message,
                type: notification.from,
                subject,
                id: notification.id
            });
            toastr.success('Test email sent');
        }
    };

    const openPreview = async () => {
        if (!subject) {
            return;
        }
        const newPreview = await NotificationApi.getPreview({
            id: notification.id,
            content: message || '<div></div>',
            type: 'email',
            subject
        });
        setPreview(newPreview);
        setPreviewVisibility(true);
    };

    const updateTemplateAction = async () => {
        if (!selectedTemplate) return;
        if (!selectedTemplate.id) return;
        if (!message) {
            toastr.error('Missing content');
            return;
        }
        const payload = {
            content: message
        };
        try {
            const updatedTemplate = await NotificationApi.updateTemplate(selectedTemplate.id, payload);
            toastr.success('Notification template successfully updated!');
            setSelectedTemplate(updatedTemplate);
            await loadTemplates();
        } catch (err) {
            if (typeof err === 'object') {
                if (err.data && err.data.message) {
                    toastr.error(err.data.message);
                    return;
                }
            }
            toastr.error('Something went wrong');
        }
    };

    return (
        <div style={{ display: 'block', width: '100%' }}>
            <Grid container xs={12} alignItems={'space-between'}>
                <Grid container xs={6} spacing={2} style={{ marginBottom: 20 }}>
                    <Grid container item xs={12} alignItems="center">
                        <Grid item className={classes.defaultLabelWidth}>
                            <Typography>Format:</Typography>
                        </Grid>
                        <Grid item className={classes.defaultLabelWidth}>
                            <Typography>Email</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} alignItems="center">
                        <Grid item className={classes.defaultLabelWidth}>
                            <Typography>Template:</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Select
                                id="select"
                                defaultValue=""
                                variant="outlined"
                                style={{ width: 300, height: 30 }}
                                onChange={evt => {
                                    const value = evt.target.value;
                                    if (typeof value !== 'number') {
                                        setSelectedTemplate();
                                        setTemplateIndex('');
                                        return;
                                    }
                                    if (editor && editor.current) {
                                        editor.current.value = emailTemplates[value].content;
                                    }
                                    setMessage(emailTemplates[value].content);

                                    if (emailTemplates[value]?.subject) {
                                        setSubject(emailTemplates[value].subject);
                                    }

                                    setSelectedTemplate(emailTemplates[value]);
                                    setTemplateIndex(value);
                                    setHaveEmailUnsavedChanges(true);
                                }}
                                value={templateIndex}
                            >
                                <MenuItem value="">
                                    <Typography>None</Typography>
                                </MenuItem>
                                {emailTemplates.map((template, index) => {
                                    return (
                                        <MenuItem key={index} value={index}>
                                            <Typography>{template.name}</Typography>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} alignItems="center">
                        <Grid item className={classes.defaultLabelWidth}>
                            <Typography>Subject:</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                label="subject"
                                ref={subjectRef}
                                onFocus={() => {
                                    if (lastClickedElement.current) {
                                        lastClickedElement.current.setSelectionRange(pointerPosition, pointerPosition);
                                    }
                                }}
                                onClick={e => {
                                    setPointerPosition(e.target.selectionStart);
                                    lastClickedElement.current = e.target;
                                }}
                                value={subject}
                                onChange={evt => {
                                    setSubject(evt.target.value);
                                    setPointerPosition(evt.target.selectionStart);
                                    setHaveEmailUnsavedChanges(true);
                                }}
                                variant="outlined"
                                onSelect={e => {
                                    const pos = e.target.selectionEnd;
                                    subjectRef.current.selectionEnd = pos;
                                    setSelectionPosition(pos);
                                }}
                                style={{ width: 300, height: 30 }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} alignItems="center">
                        <Grid item className={classes.defaultLabelWidth}>
                            <Typography>Email to:</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Select
                                id="select"
                                defaultValue={target}
                                variant="outlined"
                                style={{ width: 300, height: 30 }}
                                onChange={evt => {
                                    const value = evt.target.value;
                                    if (!value) return;
                                    setTarget(value);
                                    setHaveEmailUnsavedChanges(true);
                                }}
                                disabled={true}
                            >
                                {targetOptions.map((option, index) => {
                                    return (
                                        <MenuItem key={index} value={option}>
                                            <Typography>{option}</Typography>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <EmailEditor
                lastClickedElement={lastClickedElement}
                setSubject={setSubject}
                pointerPosition={pointerPosition}
                subject={subject}
                setLoadingUpload={setLoadingUpload}
                loadingUpload={loadingUpload}
                editor={editor}
                message={message}
                defaultValue={notification ? notification.email.content : ''}
                setMessage={setMessage}
                setUnsavedChanges={() => setHaveEmailUnsavedChanges(true)}
                mergeTags={mergeTags}
                setPointerPosition={setPointerPosition}
            />
            <div style={{ marginTop: 20 }}>
                {selectedTemplate ? (
                    <Button className={classes.blueBtn} onClick={updateTemplateAction}>
                        Update template
                    </Button>
                ) : (
                    <Button className={classes.blueBtn} onClick={saveTemplateAction}>
                        Save template
                    </Button>
                )}
                <Button href="#text-buttons" color="primary" onClick={openPreview} className={classes.textBlueBtn}>
                    Preview
                </Button>
                {previewVisibility && <Preview data={preview} closeModal={() => setPreviewVisibility(false)} />}
            </div>
            {isSavingTemplate && (
                <EmailTemplate
                    message={message}
                    subject={subject}
                    getTemplates={loadTemplates}
                    closeModal={closeTemplateModal}
                />
            )}
            <div className={classes.testContainer}>
                <Typography>Send test email:</Typography>
                <TextField
                    style={{ marginLeft: 20, marginRight: 10, width: 300 }}
                    label="Email address"
                    placeholder="Email address, separate with comma"
                    variant="outlined"
                    value={emailList}
                    className="usePlaceholderTextField"
                    onChange={e => {
                        setEmailList(e.target.value);
                    }}
                />
                <Button className={classes.blueBtn} onClick={sendTestEmail}>
                    Send
                </Button>
                {notification.from === 'Form' && (
                    <FormControlLabel
                        control={
                            <Checkbox
                                defaultChecked={sendOnAppointmentCreation}
                                value={sendOnAppointmentCreation}
                                onChange={e => setSendOnAppointmentCreation(e.target.checked)}
                            />
                        }
                        label="Send on appointment creation"
                    />
                )}
            </div>
            {isPreAfterCare && (
                <div style={{ marginTop: 20 }}>
                    <RadioGroup
                        style={{ display: 'flex', flexDirection: 'row' }}
                        defaultValue={isReminder ? 'sendBeforeAppointment' : 'sendOnCheckout'}
                        onChange={e => {
                            setSendType(e.target.value);
                            e.target.value === 'sendBeforeAppointment' ? setIsReminder(true) : setIsReminder(false);
                        }}
                    >
                        <FormControlLabel value={'sendOnCheckout'} control={<Radio />} label={'Send on checkout'} />
                        <FormControlLabel
                            value={'sendBeforeAppointment'}
                            control={<Radio />}
                            label={'Send before appointment'}
                        />
                    </RadioGroup>
                    {sendType === 'sendBeforeAppointment' && (
                        <>
                            <div style={{ display: 'flex', marginTop: 20 }}>
                                <Typography className={`${classes.marginTopLabels}`}>Send:</Typography>
                                <TextField
                                    className={`${classes.marginLeft8px}`}
                                    required
                                    select
                                    variant="outlined"
                                    defaultValue={notification?.email?.sendReminder?.value || 1}
                                    onChange={evt => {
                                        setSelectedTime(evt.target.value);
                                        setHaveEmailUnsavedChanges(true);
                                    }}
                                >
                                    {timeArray.map(value => {
                                        return (
                                            <MenuItem key={value} value={value}>
                                                {value}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                                <TextField
                                    select
                                    className={classes.marginLeft8px}
                                    defaultValue={selectedType}
                                    variant="outlined"
                                    onChange={evt => {
                                        setSelectedType(evt.target.value);
                                        setHaveEmailUnsavedChanges(true);
                                    }}
                                >
                                    <MenuItem value="days">day(s)</MenuItem>
                                    <MenuItem value="hours">hour(s)</MenuItem>
                                </TextField>
                                <Typography className={`${classes.marginTopLabels} ${classes.marginLeft8px}`}>
                                    before appointment
                                </Typography>
                            </div>
                            {selectedType === 'days' && (
                                <div style={{ display: 'flex', marginTop: 10 }}>
                                    <KeyboardTimePicker
                                        margin="normal"
                                        id="hour-and-minute"
                                        value={keyboardTimeValue}
                                        onChange={changeTime}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time'
                                        }}
                                        TextFieldComponent={props => (
                                            <TextField
                                                {...props}
                                                style={{ height: 30, width: 150 }}
                                                label="Time of day to send"
                                                variant="outlined"
                                            />
                                        )}
                                        ampm={false}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
            {isReminder && (notification.from === 'Appointment' || !sendOnAppointmentCreation) && !isPreAfterCare && (
                <ReminderOptions sendEmail={notification.from === 'Form'} />
            )}

            <div className={globalStyles.buttonsContainer}>
                <Button
                    className={globalStyles.cancelButton}
                    onClick={() => {
                        closeAccordion();
                        setHaveEmailUnsavedChanges(false);
                    }}
                >
                    Cancel
                </Button>
                <Button className={globalStyles.confirmButton} onClick={saveData}>
                    Save
                </Button>
            </div>
        </div>
    );
};

export default withStyles(notificationsStyle)(EmailTab);
