import React from 'react';
import { Tooltip, Typography, withStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { styles } from './styles';
import PropTypes from 'prop-types';

const InfoTooltip = ({ classes, title, toolTipSize }) => {
    return (
        <Tooltip
            style={toolTipSize ? { fontSize: toolTipSize } : {}}
            title={<Typography className={classes.defaultFontSize}>{title}</Typography>}
            aria-label="info"
        >
            <InfoIcon />
        </Tooltip>
    );
};

InfoTooltip.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    toolTipSize: PropTypes.string
};

export default withStyles(styles)(InfoTooltip);
