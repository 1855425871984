import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../common/Modal';

const DetailedModal = ({ visible, setVisibility, data, title }) => {
    const toUppercase = string => string.charAt(0).toUpperCase() + string.slice(1);

    const renderContent = () => {
        const pre_keys = ['name', 'code', 'tags', 'description', 'rooms'];
        const keys = Object.keys(data).filter(key => pre_keys.includes(key));

        return keys.map((key, index) => (
            <div key={index} style={{ margin: '0.3rem 0' }}>
                <b>{`${toUppercase(key)}: `}</b>
                {key === 'rooms' ? (
                    <label>{data[key].map(item => item.room.name).join(', ')}</label>
                ) : (
                    <label>{key === 'tags' ? data[key].map(tag => tag.name).join(', ') : data[key]}</label>
                )}
            </div>
        ));
    };

    return (
        <Modal
            isOpen={visible}
            onCancel={() => setVisibility(false)}
            onClose={() => setVisibility(false)}
            title={title}
            id="data-detail"
            size="xs"
        >
            {data && renderContent()}
        </Modal>
    );
};

DetailedModal.propTypes = {
    visible: PropTypes.bool,
    setVisibility: PropTypes.func,
    data: PropTypes.object,
    title: PropTypes.string
};

export default DetailedModal;
