import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { smsEditor as styles } from './styles';
import { withStyles, Box, TextField, Typography } from '@material-ui/core';
// import RotateLeftIcon from '@material-ui/icons/RotateLeft';
// import RotateRightIcon from '@material-ui/icons/RotateRight';
import LinkIcon from '@material-ui/icons/Link';
import UrlShortner from './UrlShortener';

// import Emoji from './Emoji';
import MergeTagsBox from '../../../../collums-components/components/common/MergeTagsBox';

function SMSEditor({
    classes,
    setSMSMessage,
    smsMessage,
    selectionPosition,
    setSelectionPosition,
    editorRef,
    mergeTags,
    haveUnsavedChanges
}) {
    const [historyData, setHistoryData] = useState({
        position: 0,
        data: []
    });
    const form = smsMessage;

    const [displayUrl, setDisplayUrl] = useState(false);
    const [smsCreditCount, setSMSCreditCount] = useState(1);

    useEffect(() => {
        if (editorRef) {
            editorRef.current.value = form || '';
            setSelectionPosition(editorRef.current.selectionEnd || 0);
        }
        /* eslint-disable-next-line */
    }, [editorRef, setSelectionPosition]);

    useEffect(() => {
        editorRef.current.value = smsMessage;
        /* eslint-disable-next-line */
    }, [smsMessage, setSMSMessage]);

    // const addEmoji = emoji => {
    //     const msg = smsMessage + emoji;
    //     setSMSMessage(msg);
    //     haveUnsavedChanges();
    // };

    const handleHistory = (content, pos) => {
        if (historyData.data[historyData.data.length - 1] === content) return;
        const newData = { position: pos ? pos : historyData.position, data: [...historyData.data, content] };
        setHistoryData(newData);
    };

    const handleURLchange = url => {
        const msg = smsMessage + ` ${url}`;

        setSMSMessage(msg);
        setDisplayUrl(false);
        haveUnsavedChanges();
    };

    //eslint-disable-next-line
    const handleMoveHistory = direction => {
        const last = editorRef.current.value.split(' ').pop();

        // As the app is saving the each state by spaces, the last word without finish with space will not be tracked
        if (!historyData.data[historyData.data.length - 1].includes(last)) {
            handleHistory(editorRef.current.value, -1);
            editorRef.current.value = historyData.data[historyData.data.length - 1];
        } else {
            const position = historyData.position + direction;

            const history =
                position >= 0
                    ? historyData.data[historyData.data.length - 1]
                    : historyData.data[historyData.data.length - 1 + position];

            if (history) {
                editorRef.current.value = history;

                if (position <= 0) setHistoryData({ ...historyData, position });
            }
        }
    };

    return (
        <>
            <Box display="block" position="relative" width="100%">
                {/* <Emoji addEmoji={addEmoji} /> */}
                {/* 
                <RotateLeftIcon style={{ cursor: 'pointer' }} onClick={() => handleMoveHistory(-1)} />
                <RotateRightIcon style={{ cursor: 'pointer' }} onClick={() => handleMoveHistory(1)} /> 
                */}
                <LinkIcon onClick={() => setDisplayUrl(!displayUrl)} className={classes.smsBtn} />

                <UrlShortner
                    visible={displayUrl}
                    setVisibility={() => setDisplayUrl(!displayUrl)}
                    onChange={handleURLchange}
                />
                {/* Text field */}
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ display: 'block', width: '80%' }}>
                        <TextField
                            inputRef={editorRef}
                            onSelect={() => {
                                setSelectionPosition(editorRef.current.selectionStart);
                            }}
                            multiline
                            style={{ width: '100%' }}
                            rows={15}
                            className={classes.smsInput}
                            onClick={e => {
                                const pos = e.target.selectionEnd;
                                editorRef.current.selectionEnd = pos;
                                setSelectionPosition(pos);
                            }}
                            onChange={event => {
                                setSMSMessage(event.target.value);
                                haveUnsavedChanges();
                                const count = parseInt(event.target.value.length / 160, 10) + 1;
                                setSMSCreditCount(count);
                                if (event.target.value[event.target.value.length - 1] === ' ') {
                                    handleHistory(editorRef.current.value);
                                }
                            }}
                            onFocus={() => {
                                if (editorRef.current) {
                                    editorRef.current.setSelectionRange(selectionPosition, selectionPosition);
                                }
                            }}
                            placeholder="Message"
                            InputProps={{ style: { height: 256, width: '100%' } }}
                            variant="outlined"
                        />
                        <Typography
                            className={`${classes.helperText} ${classes.marginTop8px}`}
                        >{`Characters ${smsMessage.length}/160 ${smsCreditCount} SMS credits (when GSM-7 character set used). Using other characters will use more credits.`}</Typography>
                        <Typography className={classes.helperText}>
                            Check the number of credits (segments) your message will use{' '}
                            <a
                                className={classes.link}
                                href="https://twiliodeved.github.io/message-segment-calculator/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                here
                            </a>
                            . Read more about SMS encoding{' '}
                            <a
                                className={classes.link}
                                href="https://www.twilio.com/blog/2017/03/what-the-heck-is-a-segment.html"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                here
                            </a>
                        </Typography>
                    </div>
                    <MergeTagsBox
                        mergeTags={mergeTags}
                        onClick={element => {
                            const nextPosition = selectionPosition + element.length;

                            let msg = editorRef.current.value.slice(0, selectionPosition);
                            msg += element + editorRef.current.value.slice(selectionPosition);

                            setSMSMessage(msg);
                            haveUnsavedChanges();
                            setSelectionPosition(nextPosition);
                            setTimeout(() => {
                                editorRef.current.focus();
                            }, 10);
                        }}
                    />
                </div>
            </Box>
        </>
    );
}

SMSEditor.propTypes = {
    classes: PropTypes.object.isRequired,
    smsMessage: PropTypes.object.isRequired,
    setSMSMessage: PropTypes.func.isRequired,
    setSelectionPosition: PropTypes.func.isRequired,
    editorRef: PropTypes.object
};

export default withStyles(styles)(SMSEditor);
