import { makeStyles, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { productCategoryDetailsModalStyles } from '../styles';

function GeneralTab({
    register,
    categories,
    isFromOrg,
    category,
    setValue,
    searchCategories,
    parent,
    setParent,
    allClinics
}) {
    const classes = makeStyles(productCategoryDetailsModalStyles)();
    //  const isRequired = category.id !== undefined ? { name: false, active: false } : { name: true, active: true };

    useEffect(() => {
        register({ name: 'parentId' });
        /* eslint-disable-next-line */
    }, []);

    return (
        <div className={classes.formContent}>
            <div className={classes.formContentRow}>
                <div style={{ display: 'flex', width: '100%' }}>
                    <TextField
                        inputProps={{ readOnly: !isFromOrg && allClinics.length > 1 }}
                        className={classes.formItem}
                        inputRef={register({ required: 'Name' })}
                        name="name"
                        label="Name"
                        variant="outlined"
                        defaultValue={category.name || ''}
                    />

                    <Autocomplete
                        disabled={!isFromOrg && allClinics.length > 1}
                        style={{ width: '48%' }}
                        options={categories}
                        value={parent}
                        getOptionLabel={option => option.name}
                        onChange={async (e, value) => {
                            setValue('parentId', value !== null ? value.id : undefined);
                            setParent(value);
                        }}
                        className={`${classes.autocomplete} ${classes.formItem}`}
                        renderInput={params => (
                            <TextField
                                {...params}
                                onChange={(e, value) => searchCategories(value)}
                                label="Parent Category"
                                variant="outlined"
                                className={classes.autocompleteTextField}
                            />
                        )}
                    />
                </div>

                <div className={classes.formContentRow}>
                    <TextField
                        inputProps={{ readOnly: !isFromOrg && allClinics.length > 1 }}
                        multiline="true"
                        className={classes.formItem}
                        inputRef={register}
                        rowsMax={3}
                        name="description"
                        label="Description"
                        variant="outlined"
                        defaultValue={category.description}
                    />
                </div>
            </div>
        </div>
    );
}

GeneralTab.propTypes = {
    register: PropTypes.func.isRequired,
    categories: PropTypes.array,
    control: PropTypes.any,
    watch: PropTypes.any
};

export default GeneralTab;
