export const OrderedOptionStyles = () => ({
    deleteIcon: {
        color: 'red'
    },
    deleteIconDisabled: {
        color: '#7e7e7e'
    },
    arrowIcons: {
        color: '#333',
        fontSize: 18
    },
    arrowIconsDisabled: {
        color: '#7e7e7e'
    },
    flex: {
        display: 'flex',
        alignItems: 'center'
    },
    orderArrowsContainer: {
        display: 'flex',
        flexDirection: 'column'
    }
});
