export const autocompleteBox = () => ({
    dFlex: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        margin: '0.8rem 0',
        '& svg': {
            color: '#4a4a4a',
            cursor: 'pointer'
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            padding: '3px'
        }
    }
});

export const form = () => ({
    input: {
        margin: '16px 0',
        width: '100%',
        height: '38px'
    },
    inputField: {
        margin: '0.6rem 0'
    },
    autocompleteTextfield: {
        width: '100%',
        '& .MuiOutlinedInput-adornedEnd': {
            height: '38px',
            padding: '0 4px'
        }
    },
    modal: {
        '& .MuiDialog-paper': {
            backgroundColor: 'white'
        }
    }
});
