import React, { useEffect, useState } from 'react';
import JoditEditor from '../../../notifications/tabs/components/JoditEditorWrapper';
import { Card, Box, Button, withStyles } from '@material-ui/core';
import { settingsStyles } from '../../styles';
import { useSelector } from 'react-redux';
import { CURRENCY_TYPES } from '../../../../collums-constants';

const Editor = ({ classes, textRef, field, organisation, placeholder, mergeTags, width, defaultValue }) => {
    const [currencySymbol, setCurrencySymbol] = useState('£');

    const organisationState = useSelector(state => state.organisation);
    useEffect(() => {
        const currency = CURRENCY_TYPES.find(type => type.NAME === organisationState.currency);
        if (currency) {
            setCurrencySymbol(currency.SYMBOL);
        }
    }, [organisationState.currency]);

    const getValue = () => {
        if (organisation && organisation[field]) {
            if (organisation[field] !== '') {
                return organisation[field];
            }
        }

        return defaultValue || '';
    };

    return (
        <div style={mergeTags && mergeTags.length > 0 ? { display: 'flex' } : {}}>
            <div className={classes.editorSize}>
                <JoditEditor
                    width={width}
                    ref={textRef}
                    customizedButtons={true}
                    uploads={false}
                    value={getValue()}
                    placeholder={placeholder}
                    name={field}
                />
            </div>

            {mergeTags && mergeTags.length > 0 && (
                <Box ml={2} display="flex" width="auto" height={'100%'} flexDirection="column" alignItems="center">
                    <b style={{ fontFamily: 'Roboto' }}>Merge Tags</b>
                    <Card variant="outlined" style={{ width: '100%', marginTop: 10 }}>
                        <Box display="flex" width="100%" height="100%" flexDirection="column">
                            {mergeTags.map((element, index) => (
                                <Button
                                    key={index}
                                    onClick={() => {
                                        if (!textRef.current.s.current()) {
                                            textRef.current.s.insertCursorAtPoint(
                                                textRef.current.value.length,
                                                textRef.current.value.length
                                            );
                                        }
                                        textRef.current.s.insertHTML(element);
                                    }}
                                >
                                    {element === '{LateCancellationType}'
                                        ? `{LateCancellationType (% or ${currencySymbol || '£'})}`
                                        : element}
                                </Button>
                            ))}
                        </Box>
                    </Card>
                </Box>
            )}
        </div>
    );
};

export default withStyles(settingsStyles)(Editor);
