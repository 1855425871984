import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import { toastr } from 'react-redux-toastr';
import { Controller, useForm } from 'react-hook-form';
import { HTML5Backend } from 'react-dnd-html5-backend';

import form from './equipmentForm';
import Modal from '../../common/Modal';
import DragableListItem from '../../common/DragableListItem';
import TagsInput from '../../../collums-components/form/TagsInput';
import { Divider, List, makeStyles, TextField, Button, FormHelperText } from '@material-ui/core';

import RoomApi from '../../../api/RoomApi';

import { form as formStyle } from './styles';
import { modalsButtonStyles } from '../../../collums-constants/styles/stylesheets/buttonsStyles';

import { Autocomplete } from '@material-ui/lab';
import { sortBy } from 'lodash';

const activeOptions = [
    {
        label: 'Yes',
        value: 10
    },
    {
        label: 'No',
        value: 100
    }
];

const EquipmentFormModal = ({ visible, setVisibility, handleAddItem, handleUpdateItem, defaultValue, clinic }) => {
    const { control, register, errors, setValue, handleSubmit } = useForm();
    const [roomError, setRoomError] = useState(false);

    const classes = makeStyles(formStyle)();
    const globalStyles = makeStyles(modalsButtonStyles)();

    const [rooms, setRooms] = useState([]);

    useEffect(() => {
        (async () => {
            const rooms = await RoomApi.query(0, 15, `clinic=${clinic.id}`);

            let roomsOptions = rooms.items.map(room => ({
                id: room._id || room.id,
                isSelected: false,
                value: room._id || room.id,
                label: room.name
            }));

            if (defaultValue) {
                const pastRooms = (defaultValue.rooms || []).map(element => element.room.id);

                const selectedRooms = roomsOptions.map(room => {
                    if (pastRooms.includes(room.id)) {
                        return { ...room, isSelected: true, priority: pastRooms.indexOf(room.id) };
                    }
                    return room;
                });

                roomsOptions = sortBy(selectedRooms, ['-isSelected']);

                setValue('rooms', roomsOptions);
            }

            setRooms(roomsOptions);
        })();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const requiredFields = Object.keys(errors);

        if (requiredFields.length !== 0) {
            const messages = requiredFields.map(field => errors[field].message);

            toastr.error('Missing fields', messages.join(','));
        }
    }, [errors]);

    const handleAddEquipment = values => {
        if (!values.name) {
            toastr.error('Missing name field');
            return;
        }
        let selectedRooms = [];

        if (values.rooms) {
            selectedRooms = values.rooms.filter(room => room.isSelected);
            selectedRooms = selectedRooms.map((room, index) => ({ room: room.value, priority: index }));
        }

        // if (!selectedRooms.length || formData.Quantity < 0) return toastr.error('Please complete the form correctly.');

        if (defaultValue) {
            const rooms = selectedRooms;

            const data = {
                id: defaultValue.id,
                name: values.name,
                clinic: clinic.id,
                description: values.description,
                tags: (values.Tags || []).map(tag => tag.id),
                rooms
            };

            const isSucceeded = handleUpdateItem(data);
            if (!isSucceeded) return;
        } else {
            const data = {
                name: values.name,
                description: values.description,
                clinic: clinic.id,
                tags: (values.Tags || []).map(tag => tag.id),
                active: 10,
                rooms: selectedRooms
            };

            const isSucceeded = handleAddItem(data);
            if (!isSucceeded) return;
        }
    };

    const handleChange = (list, setList, id) => {
        setRoomError(false);

        const itemIndex = list.findIndex(value => value.id === id);

        // minimum one selected
        const selectedItem = list.filter(item => item.isSelected).length;
        if (selectedItem < 2 && list[itemIndex].isSelected) {
            setRoomError(true);
            return;
        }

        const newItem = { ...list[itemIndex], isSelected: !list[itemIndex].isSelected };
        const newList = [...list];

        newList.splice(itemIndex, 1, newItem);

        setList(newList);
    };

    const handleItemReordering = (dragged, draggedOver, onChange, value) => {
        let newItems = value;
        const setter = onChange;

        const draggedIndex = newItems.findIndex(item => item.id === dragged.id);
        const draggedOverIndex = newItems.findIndex(item => item.id === draggedOver.id);

        newItems[draggedOverIndex] = { ...dragged, id: dragged.id };
        newItems[draggedIndex] = { ...draggedOver, id: draggedOver.id };

        setter([...newItems]);
    };

    const handleDrop = (draggedItem, draggedOver, onChange, value) => {
        if (draggedItem && draggedOver && draggedItem.id !== draggedOver.id)
            handleItemReordering(draggedItem, draggedOver, onChange, value);
    };

    const renderInput = data => {
        if (data.name === 'tags') {
            return (
                <TagsInput
                    control={control}
                    defaultValue={defaultValue && (defaultValue[data.label.toLowerCase()] || [])}
                    name={data.label}
                    inputSize={70.5}
                />
            );
        }

        return data.type === 'select' ? (
            <Controller
                name={data.name}
                control={control}
                rules={register({ required: data.required })}
                defaultValue={() => {
                    if (defaultValue) {
                        return defaultValue[data.label.toLowerCase()] === 10 ? activeOptions[0] : activeOptions[1];
                    }
                    return activeOptions[0];
                }}
                onChange={([, data]) => data}
                render={({ onChange, ...props }) => (
                    <Autocomplete
                        className={classes.inputField}
                        name={data.name}
                        {...props}
                        id={data.id}
                        options={data.options}
                        getOptionLabel={option => {
                            return option.label;
                        }}
                        defaultValue={
                            defaultValue
                                ? defaultValue.active === 10
                                    ? { label: 'Yes', value: 10 }
                                    : { label: 'No', value: 100 }
                                : null
                        }
                        disabled={defaultValue ? defaultValue.archived : false}
                        ref={register}
                        renderInput={params => (
                            <TextField
                                {...params}
                                name={data.name ? data.name : ''}
                                className={classes.autocompleteTextfield}
                                label={data.label}
                                variant="outlined"
                            />
                        )}
                        onChange={(event, option) => onChange(option && option)}
                    />
                )}
            />
        ) : (
            <Controller
                render={props => (
                    <TextField
                        {...props}
                        type={data.type}
                        id={data.id}
                        className={`${classes.inputField} ${classes.input}`}
                        variant="outlined"
                        label={data.label === 'Name' ? data.label + ' *' : data.label}
                    />
                )}
                defaultValue={defaultValue ? defaultValue[data.label.toLowerCase()] : ''}
                name={data.name}
                control={control}
                rules={register({ required: data.required })}
            />
        );
    };

    return (
        <Modal
            isOpen={visible}
            onCancel={() => setVisibility(false)}
            onClose={() => setVisibility(false)}
            title={(defaultValue || {}).id ? `View/Edit ${defaultValue.name} equipment` : 'New equipment'}
            id="equipment-form"
            size="xs"
            dialogClass={classes.modal}
        >
            <form
                onSubmit={e => {
                    e.preventDefault();
                    handleSubmit(handleAddEquipment)();
                }}
            >
                {/* All fields */}
                {form.map(input => renderInput(input))}

                {/* Rooms */}
                {rooms.length !== 0 && (
                    <Controller
                        name={'rooms'}
                        control={control}
                        rules={register}
                        defaultValue={rooms}
                        render={({ onChange, value }) => (
                            <List>
                                <label>Room</label>
                                <DndProvider backend={HTML5Backend}>
                                    {value.map((room, itemIndex) => {
                                        return (
                                            <>
                                                <DragableListItem
                                                    key={`room-item-${itemIndex}`}
                                                    item={room}
                                                    handleDrop={handleDrop}
                                                    handleChange={handleChange}
                                                    onChange={onChange}
                                                    value={value}
                                                    type={'REORDER_ROOMS'}
                                                    disabled={
                                                        rooms.length === 1 && value.every(item => item.isSelected)
                                                    }
                                                />
                                                <Divider />
                                            </>
                                        );
                                    })}
                                </DndProvider>
                                {roomError && (
                                    <FormHelperText error={true}>At least one room must be selected</FormHelperText>
                                )}
                            </List>
                        )}
                    />
                )}

                <div className={globalStyles.buttonsContainer}>
                    <Button className={globalStyles.cancelButton} onClick={() => setVisibility(false)}>
                        Cancel
                    </Button>
                    <Button className={globalStyles.confirmButton} type="submit" variant="outlined">
                        Save
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

EquipmentFormModal.propTypes = {
    visible: PropTypes.bool,
    setVisibility: PropTypes.func,
    handleAddItem: PropTypes.func,
    handleUpdateItem: PropTypes.func
};

export default EquipmentFormModal;
