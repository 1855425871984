const temporaryDiscountsFormStyles = () => ({
    formContent: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap'
    },
    formContentRow: {
        width: '100%'
    },
    formItem: {
        width: '48%',
        marginRight: 8,
        marginTop: 8,
        marginBottom: 8
    },
    inputRoot: {
        margin: '0.25rem',
        width: '80%',
        display: 'flex',
        alignItems: 'center',
        '& p': {
            margin: 0,
            width: '25%'
        },
        fontFamily: 'Roboto, Arial, Sans-serif'
    },
    listRoot: {
        width: '100%',
        maxWidth: 360,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300
    },
    treeItems: {
        '& div': {
            fontSize: 14
        }
    },
    treeLabel: {
        '& .MuiTreeItem-root': {
            paddingTop: 0,
            paddingBottom: 0
        }
    },
    outlinedButton: {
        alignSelf: 'center',
        height: 'fit-content',
        width: '100%',
        backgroundColor: '#d6d3c9'
    }
});

export default temporaryDiscountsFormStyles;
