import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { toLocaleString } from '../../collums-components/helpers';
function CurrencyInput({ value, onChange, onBlur, CustomInput, disabled }) {
    const currencyFormatter = value => {
        if (isNaN(value)) {
            return '';
        }
        const amount = toLocaleString(value / 100);

        return `${amount}`;
    };

    const renderNumberFormat = useMemo(
        () => {
            return (
                <>
                    <NumberFormat
                        disabled={disabled}
                        defaultValue={0}
                        value={value}
                        onFocus={e => e.target.select()}
                        tabIndex={-1}
                        onBlur={onBlur}
                        onChange={onChange}
                        variant="outlined"
                        format={currencyFormatter}
                        customInput={CustomInput}
                    />
                </>
            );
        },
        // eslint-disable-next-line
        [value, disabled]
    );

    return renderNumberFormat;
}

CurrencyInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    onBlur: PropTypes.func,
    CustomInput: PropTypes.any
};

export default CurrencyInput;
