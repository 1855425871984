import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { withStyles, Typography, ListItem, Checkbox, List, Input, Link } from '@material-ui/core';
import { categoryDetailsModalStyles } from '../styles';
function StaffTab({
    register,
    setValue,
    classes,
    practitioners,
    clinic,
    clinics,
    practitionersList,
    setPractitionersList,
    services,
    changedPractitioners,
    setChangedPractitioners
}) {
    const handlePractitionersDefaultValue = () => {
        if (clinics.length !== 1) {
            practitioners = practitioners.filter(staff => {
                return services?.some(service =>
                    service?.locations
                        ?.find(loc => loc.clinic === clinic)
                        ?.staffs?.find(sStaff => sStaff.staff === staff.id)
                );
            });
        }
        practitioners = practitioners.map(staff => {
            const occurrencesInServices = services.reduce(
                (accumulator, service) =>
                    service.locations?.find(loc => loc.clinic === clinic) ? accumulator + 1 : accumulator,
                0
            );
            const occurrencesInStaff = staff?.locations
                .find(loc => loc.clinic === clinic)
                ?.services?.reduce(
                    (accumulator, service) =>
                        services.map(s => s.id).includes(service.service) ? accumulator + 1 : accumulator,
                    0
                );
            if (occurrencesInStaff === occurrencesInServices) {
                return { ...staff, isSelected: true };
            } else if (occurrencesInStaff) {
                return { ...staff, isSelected: false, indeterminate: true };
            }
            return staff;
        });
        return practitioners.sort((a, b) => a.displayName.localeCompare(b.displayName));
    };

    useEffect(() => {
        setPractitionersList(handlePractitionersDefaultValue());
        // eslint-disable-next-line
    }, [practitioners, services]);

    const handleChange = (e, value, id) => {
        const newPractitionersList = practitionersList.map(practitioner => {
            if (practitioner.id === id) {
                return { ...practitioner, isSelected: value, indeterminate: false };
            } else {
                return practitioner;
            }
        });
        setPractitionersList(newPractitionersList);
        const newChangedPractitioners = changedPractitioners.filter(staff => staff !== id);
        newChangedPractitioners.push(id);
        setChangedPractitioners(newChangedPractitioners);
        setValue('changedPractitioners', newChangedPractitioners.join(','));
        return newPractitionersList;
    };

    return (practitionersList || []).length > 0 ? (
        <>
            <List>
                {(practitionersList || []).map((item, itemIndex) => (
                    <ListItem
                        display="flex"
                        key={`staff-item-${itemIndex}`}
                        justifyContent="space-between"
                        className={classes.listItem}
                        p={1}
                        pr={3}
                        mb={1}
                        bgcolor="#ffffff"
                    >
                        <Checkbox
                            checked={item?.isSelected}
                            indeterminate={item?.indeterminate}
                            onChange={(e, value) => {
                                handleChange(e, value, item.id);
                            }}
                        />
                        <Typography className={classes.textOverflow}>{item.displayName}</Typography>
                    </ListItem>
                ))}
                <span hidden>
                    <Input inputRef={register} name="changedPractitioners" defaultValue="" />
                </span>
            </List>
        </>
    ) : (
        <Typography>
            If you don’t see the staff you wish to enable below, ensure that they have permission to perform this
            service in{' '}
            <Link href="/organisation/staffs" underline="none" color="indigo">
                admin/organisation/staff
            </Link>{' '}
        </Typography>
    );
}

StaffTab.propTypes = {
    classes: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    category: PropTypes.object
};

export default withStyles(categoryDetailsModalStyles)(StaffTab);
