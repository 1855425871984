import React, { useState, useRef } from 'react';
import View from '../common/View';
import ListPagination from '../common/ListPagination';
import { toastr } from 'react-redux-toastr';
import LoadingScreen from './../../collums-components/components/common/loadingScreen';
import { stringify } from 'query-string';
import ConfirmModal from '../common/ConfirmModal';
import JobApi from './../../collums-components/api/jobApi';
import JobModal from './JobModal';

const JobsView = () => {
    const filters = useRef({
        name: '',
        inactive: false,
        archived: false
    });
    const [jobs, setJobs] = useState([]);
    const [maxJobs, setMaxJobs] = useState(0);
    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [isJobModalOpen, setIsJobModalOpen] = useState(false);
    const [isArchiveOpen, setIsArchiveOpen] = useState(false);
    const [selectedJob, setSelectedJob] = useState();

    const ApiCall = async (skip, limit, queryParam = '', refreshing = false) => {
        const query = {
            name: '',
            inactive: filters.current.inactive,
            archived: filters.current.archived
        };
        if (!queryParam && filters.current.name) {
            Object.assign(query, filters.current);
        }
        if (queryParam.indexOf('name') > -1) {
            const splitByFilterName = queryParam.split('name=')[1];
            if (splitByFilterName) {
                const filterName = splitByFilterName.split('&')[0];
                filters.current.name = filterName;
                query.name = filterName;
            }
        } else filters.current.name = '';
        if (queryParam.indexOf('archived') > -1) {
            if (queryParam.indexOf('archived=false') > -1) {
                query.archived = false;
                filters.current.archived = false;
            } else {
                query.archived = true;
                filters.current.archived = true;
            }
        }
        if (queryParam.indexOf('inactive') > -1) {
            if (queryParam.indexOf('inactive=false') > -1) {
                query.inactive = false;
                filters.current.inactive = false;
            } else {
                query.inactive = true;
                filters.current.inactive = true;
            }
        }
        try {
            setIsLoading(true);
            const newData = await JobApi.list(skip, limit, stringify(query));
            if (refreshing) {
                setJobs(newData.jobs);
                setPage(0);
                setMaxJobs(newData.size);
            }
            setIsLoading(false);
            return newData.jobs;
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong');
            setIsLoading(false);
            return [];
        }
    };

    const reload = () => {
        ApiCall(0, rowsPerPage, '', true);
    };

    const changeActive = async job => {
        if (job.archived) return toastr.error('Cannot active an archived Job');
        try {
            setIsLoading(true);
            await JobApi.changeActive(job.id);
            toastr.success('Job updated successfully');
            reload();
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong');
            setIsLoading(false);
        }
    };

    const showArchiveModal = job => {
        setSelectedJob(job);
        setIsArchiveOpen(true);
    };

    const closeArchiveModal = () => {
        setIsArchiveOpen(false);
        setSelectedJob();
    };

    const onConfirmArchive = async () => {
        if (!selectedJob) return;
        try {
            setIsLoading(true);
            await JobApi.archive(selectedJob.id);
            toastr.success(`Job successfully ${selectedJob.archived ? 'unarchived' : 'archived'}`);
            closeArchiveModal();
            reload();
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong');
            closeArchiveModal();
            setIsLoading(false);
        }
    };

    const editJob = job => {
        setSelectedJob({
            ...job,
            showColumnInCalendar: job.showColumnInCalendar === 'Yes'
        });
        setIsJobModalOpen(true);
    };

    return (
        <>
            {isJobModalOpen && (
                <JobModal
                    isVisible
                    onClose={shouldReload => {
                        if (shouldReload) {
                            reload();
                        }
                        setSelectedJob();
                        setIsJobModalOpen(false);
                    }}
                    job={selectedJob}
                />
            )}
            {isArchiveOpen && (
                <ConfirmModal
                    isOpen
                    setIsOpen={closeArchiveModal}
                    centerContent
                    refreshList={reload}
                    onConfirm={onConfirmArchive}
                    title={`${selectedJob.archived ? 'Unarchive' : 'Archive'} Job`}
                    content={`Are you sure you want ${selectedJob.archived ? 'unarchive' : 'archive'} this job?`}
                />
            )}
            <LoadingScreen isVisible={isLoading} />
            <View>
                <ListPagination
                    item={'Job'}
                    page={page}
                    setPage={setPage}
                    data={jobs}
                    maxSize={maxJobs}
                    isFromOrg={true}
                    defaultColumns={[
                        { title: 'Active', field: 'active' },
                        { title: 'Name', field: 'name', onClick: editJob },
                        { title: 'Job Description', field: 'description' },
                        { title: 'Show column', field: 'showColumnInCalendar' }
                    ]}
                    filters={[
                        { name: 'archived', label: 'Show Archived', value: false },
                        { name: 'inactive', label: 'Show Inactive', value: false }
                    ]}
                    actions={[
                        {
                            title: 'archive',
                            action: showArchiveModal
                        }
                    ]}
                    ApiCall={ApiCall}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    onCreate={() => {
                        setSelectedJob();
                        setIsJobModalOpen(true);
                    }}
                    nameFilter={true}
                    filterName="name"
                    highlightArchived={true}
                    activeCall={changeActive}
                />
            </View>
        </>
    );
};

export default JobsView;
