import { NOTIFICATION_TYPES } from '../../../collums-constants';

const requiredFields = ['title', 'firstName', 'surname', 'gender', 'email', 'postCode', 'address1', 'city'];

export const validator = customerBody => {
    const missingFields = requiredFields.filter(field => !customerBody[field]);
    return missingFields;
};

export const mapper = customerBody => {
    const getMessageTypes = type => {
        const checkedStatuses = (() => {
            if (type === 'marketing') {
                return [customerBody.receiveMarketingEmails, customerBody.receiveMarketingSms];
            }
            return [customerBody.receiveNotificationEmails, customerBody.receiveNotificationSms];
        })();
        return [
            checkedStatuses[0] ? NOTIFICATION_TYPES.EMAIL : '',
            checkedStatuses[1] ? NOTIFICATION_TYPES.SMS : ''
        ].filter(el => el);
    };

    const propsToExclude = [
        'receiveMarketingEmails',
        'receiveMarketingSms',
        'receiveNotificationEmails',
        'receiveNotificationSms'
    ].reduce(
        (acc, key) => ({
            ...acc,
            [key]: undefined
        }),
        {}
    );

    return {
        ...customerBody,
        notificationTypes: getMessageTypes('notification'),
        marketingTypes: getMessageTypes('marketing'),
        referredBy: customerBody.referredBy?.id,
        ...propsToExclude
    };
};
