import { withStyles } from '@material-ui/core';
import React from 'react';
import Modal from '../../../../../common/Modal';
import styles from './styles';

const ConsentPreview = ({ classes, onClose, content }) => {
    return (
        <Modal id="consent-preview-modal" isOpen title="Consent Preview" size="md" onCancel={onClose} onClose={onClose}>
            <div className={classes.consentWrapper}>
                <div className="html-content" dangerouslySetInnerHTML={{ __html: content || '' }} />
            </div>
        </Modal>
    );
};

export default withStyles(styles)(ConsentPreview);
