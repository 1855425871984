import globalTheme from './collums-constants/styles/globalTheme';
import { createMuiTheme } from '@material-ui/core';
import { merge } from 'lodash';

// example of local theme
const theme = {
    app: 'admin'
    /*overrides: {
        MuiInputLabel: {
            root: {
                color: 'red'
            }
        }
    }*/
};

export default createMuiTheme(merge(globalTheme, theme));
