import React, { useState } from 'react';
import {
    Grid,
    FormControl,
    TextField,
    withStyles,
    InputLabel,
    Typography,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@material-ui/core';

import PostCodeInput from '../../../collums-components/form/PostCodeInput';
import UploadButton from '../../../collums-components/components/common/UploadButton';
import { useEffect } from 'react';

import { settingsStyles } from '../styles';
import { CURRENCY_TYPES, LOGO_HEIGHT } from '../../../collums-constants';
import { getLogoWidth } from './../../../collums-components/helpers/index';

function GeneralTab({ classes, register, organisation, setOrganisation, setValue, setFile }) {
    const [logoWidth, setLogoWidth] = useState(100);

    useEffect(() => {
        register('address.line1');
        register('address.city');
        register('address.country');
        register('address.postcode');
    }, [register]);

    useEffect(() => {
        if (organisation && organisation.logo) {
            getLogoWidth(organisation.logo, LOGO_HEIGHT).then(setLogoWidth);
        }
    }, [organisation]);

    const handleAddressSearch = fullAddress => {
        setValue('address', {
            ...organisation?.address,
            line1: fullAddress.address ? fullAddress.address : '',
            city: fullAddress.city ? fullAddress.city : '',
            country: fullAddress.country ? fullAddress.country : ''
        });

        setOrganisation({
            ...organisation,
            address: {
                ...organisation?.address,
                line1: fullAddress.address ? fullAddress.address : '',
                city: fullAddress.city ? fullAddress.city : '',
                country: fullAddress.country ? fullAddress.country : ''
            }
        });
    };

    const onDropLogo = async (file, picture) => {
        setOrganisation({ ...organisation, logo: picture });
        if (setFile) {
            setFile(file);
        }
    };

    const imageStyles = {
        height: LOGO_HEIGHT,
        width: logoWidth,
        marginTop: 10
    };

    return (
        <Grid container direction="row" spacing={4}>
            <Grid item container direction="column" spacing={4} xs={6}>
                <Grid item>
                    <FormControl className={classes.fieldContainer}>
                        <TextField
                            id="account-name-input"
                            label="Name"
                            variant="outlined"
                            name="name"
                            required
                            inputRef={register({ required: 'Name' })}
                            value={organisation.name ? organisation.name : ''}
                            onChange={({ target }) =>
                                setOrganisation({
                                    ...organisation,
                                    name: target.value
                                })
                            }
                        />
                    </FormControl>
                </Grid>

                <Grid item>
                    <FormControl className={classes.fieldContainer}>
                        <TextField
                            id="line2-input"
                            name="address.line2"
                            label="Address 2"
                            variant="outlined"
                            value={organisation.address ? organisation.address.line2 : ''}
                            onChange={({ target }) =>
                                setOrganisation({
                                    ...organisation,
                                    address: { ...organisation.address, line2: target.value }
                                })
                            }
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                            inputRef={register()}
                        />
                    </FormControl>
                </Grid>

                <Grid item>
                    <FormControl className={classes.fieldContainer}>
                        <TextField
                            id="city-input"
                            name="address.city"
                            label="City"
                            variant="outlined"
                            required
                            value={organisation.address ? organisation.address.city : ''}
                            onChange={({ target }) =>
                                setOrganisation({
                                    ...organisation,
                                    address: { ...organisation.address, city: target.value }
                                })
                            }
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                            inputRef={register()}
                        />
                    </FormControl>
                </Grid>

                <Grid item>
                    <FormControl className={classes.fieldContainer}>
                        <TextField
                            id="country-input"
                            name="address.country"
                            label="Country"
                            variant="outlined"
                            required
                            value={organisation.address ? organisation.address.country : ''}
                            onChange={({ target }) =>
                                setOrganisation({
                                    ...organisation,
                                    address: { ...organisation.address, country: target.value }
                                })
                            }
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                            inputRef={register()}
                        />
                    </FormControl>
                </Grid>

                <Grid item>
                    <FormControl className={classes.fieldContainer}>
                        <TextField
                            id="company-input"
                            name="companyNo"
                            value={organisation.companyNo ? organisation.companyNo : ''}
                            onChange={({ target }) =>
                                setOrganisation({
                                    ...organisation,
                                    companyNo: target.value
                                })
                            }
                            label="Company no"
                            variant="outlined"
                            inputRef={register()}
                        />
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl component="fieldset">
                        <Typography variant="h4">Select currency</Typography>
                        <RadioGroup
                            style={{ display: 'flex', flexDirection: 'row' }}
                            name="currency"
                            value={organisation?.currency ? organisation?.currency : CURRENCY_TYPES[0].NAME}
                            onChange={e => setOrganisation({ ...organisation, currency: e.target.value })}
                            inputRef={register()}
                        >
                            {CURRENCY_TYPES.map(types => (
                                <FormControlLabel
                                    key={types.NAME}
                                    value={types.NAME}
                                    control={<Radio inputRef={register()} />}
                                    label={types.LABEL}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>

            <Grid item container direction="column" spacing={4} xs={6}>
                <Grid item>
                    <FormControl className={classes.fieldContainer}>
                        <TextField
                            id="line1-input"
                            name="address.line1"
                            label="Address 1"
                            variant="outlined"
                            value={organisation.address ? organisation.address.line1 : ''}
                            onChange={({ target }) =>
                                setOrganisation({
                                    ...organisation,
                                    address: { ...organisation.address, line1: target.value }
                                })
                            }
                            required
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                            inputRef={register()}
                        />
                    </FormControl>
                </Grid>

                <Grid item>
                    <FormControl className={classes.fieldContainer}>
                        <TextField
                            id="line3-input"
                            name="address.line3"
                            label="Address 3"
                            variant="outlined"
                            value={organisation.address ? organisation.address.line3 : ''}
                            onChange={({ target }) =>
                                setOrganisation({
                                    ...organisation,
                                    address: { ...organisation.address, line3: target.value }
                                })
                            }
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                            inputRef={register()}
                        />
                    </FormControl>
                </Grid>

                <Grid item>
                    <FormControl className={classes.fieldContainer}>
                        <TextField
                            id="county-input"
                            name="address.county"
                            label="County"
                            variant="outlined"
                            value={organisation.address ? organisation.address.county : ''}
                            onChange={({ target }) =>
                                setOrganisation({
                                    ...organisation,
                                    address: { ...organisation.address, county: target.value }
                                })
                            }
                            inputProps={{ style: { textTransform: 'capitalize' } }}
                            inputRef={register()}
                        />
                    </FormControl>
                </Grid>

                <Grid item>
                    <FormControl className={classes.fieldContainer}>
                        <PostCodeInput
                            value={organisation.address ? organisation.address.postcode : ''}
                            onChange={({ target }) =>
                                setOrganisation({
                                    ...organisation,
                                    address: { ...organisation.address, postcode: target.value }
                                })
                            }
                            required
                            name="address.postcode"
                            inputRef={register()}
                            onSearch={handleAddressSearch}
                        />
                    </FormControl>
                </Grid>

                <Grid item>
                    <FormControl className={classes.fieldContainer}>
                        <TextField
                            id="company-input"
                            name="emailName"
                            required
                            value={organisation.emailName ? organisation.emailName : ''}
                            onChange={({ target }) =>
                                setOrganisation({
                                    ...organisation,
                                    emailName: target.value
                                })
                            }
                            label="Email Send from Name"
                            variant="outlined"
                            inputRef={register()}
                        />
                    </FormControl>
                </Grid>

                <Grid item>
                    <FormControl className={classes.fieldContainer}>
                        <TextField
                            id="company-input"
                            name="email"
                            required
                            value={organisation.email ? organisation.email : ''}
                            onChange={({ target }) =>
                                setOrganisation({
                                    ...organisation,
                                    email: target.value
                                })
                            }
                            label="Email Address"
                            variant="outlined"
                            inputRef={register()}
                        />
                    </FormControl>
                </Grid>

                <Grid item>
                    <FormControl className={classes.fieldContainer}>
                        <TextField
                            id="company-input"
                            name="vatNo"
                            value={organisation.vatNo ? organisation.vatNo : ''}
                            onChange={({ target }) =>
                                setOrganisation({
                                    ...organisation,
                                    vatNo: target.value
                                })
                            }
                            label="VAT no"
                            variant="outlined"
                            inputRef={register()}
                        />
                    </FormControl>
                </Grid>
            </Grid>

            <div
                style={{
                    display: 'flex',
                    padding: '10px',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: 9
                }}
            >
                <InputLabel>Logo</InputLabel>
                {organisation.logo ? (
                    <img alt="Organisation logo" style={imageStyles} src={organisation.logo} />
                ) : (
                    <div style={imageStyles} />
                )}
                <UploadButton
                    buttonText="Choose image"
                    onChange={onDropLogo}
                    imgExtension={['.jpg', '.gif', '.png', '.gif']}
                    maxFileSize={5242880}
                    style={{ width: '128px', marginTop: '20px' }}
                />
            </div>
        </Grid>
    );
}

export default withStyles(settingsStyles)(GeneralTab);
