import React, { useEffect, useState, useCallback, useRef } from 'react';
import { withStyles } from '@material-ui/core';
import { toastr } from 'react-redux-toastr';

import { taxRateStyles } from '../styles';
import TaxRateModal from '../TaxRateModal';

import TaxesApi from '../../../api/TaxesApi';

import View from '../../common/View';
import ConfirmModal from '../../common/ConfirmModal';
import ListPagination from '../../common/ListPagination';
import TaxInUseModal from '../TaxInUseModal';

function TaxRatesTab({ classes, expanded, organisationId, setIsLoading }) {
    const [data, setData] = useState([]);
    const [confirmModal, setConfirmModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState('');
    const [selectedTaxRate, setSelectedTaxRate] = useState();
    const [isTaxRateModalVisible, setIsTaxRateModalVisible] = useState(false);
    const [archiveInUseModalVisible, setArchiveInUseModalVisible] = useState(false);
    const [itemsUsingTax, setItemsUsingTax] = useState({});
    const [method, setMethod] = useState('');
    const showArchived = useRef(false);
    const showInactive = useRef(false);

    useEffect(() => {
        const run = async () => {
            if (!data?.length && expanded) {
                try {
                    // setIsLoading(true);
                    const data = await getTaxes(
                        page,
                        rowsPerPage,
                        `archived=${showArchived.current}&inactive=${showInactive.current}`
                    );
                    setData(data);
                } catch (err) {
                    return toastr.error(err?.data?.message || 'Something went wrong');
                } finally {
                    setIsLoading(false);
                }
            }
        };
        run();
    }, [expanded]); //eslint-disable-line

    const getTaxes = async (skip, limit, queryParam = '') => {
        try {
            // setIsLoading(true);
            const query = {
                archived: showInactive.current,
                inactive: showArchived.current
            };
            if (queryParam.indexOf('archived=true') >= 0) {
                showArchived.current = true;
                query.archived = true;
            } else {
                showArchived.current = false;
                query.archived = false;
            }
            if (queryParam.indexOf('inactive=true') >= 0) {
                showInactive.current = true;
                query.inactive = true;
            } else {
                showInactive.current = false;
                query.inactive = false;
            }

            const taxes = await TaxesApi.listTaxes(query);
            setData(taxes);
            return taxes;
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            // setIsLoading(false);
        }
    };

    const openTaxRatesModal = useCallback(taxRate => {
        if (taxRate && taxRate.id) {
            setSelectedTaxRate(taxRate);
        } else {
            setSelectedTaxRate('new');
        }
        setIsTaxRateModalVisible(true);
    }, []);

    const createTaxRate = async data => {
        try {
            // setIsLoading(true);
            await TaxesApi.create(organisationId, data);
            CloseTaxRatesModal();
            getTaxes(page, rowsPerPage, `archived=${showArchived.current}&inactive=${showInactive.current}`);
            return toastr.success('Success creating a new tax rate');
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            // setIsLoading(false);
        }
    };

    const updateTaxRate = async tax => {
        try {
            // setIsLoading(true);
            await TaxesApi.update(tax.id, tax.data);
            CloseTaxRatesModal();
            getTaxes(page, rowsPerPage, `archived=${showArchived.current}&inactive=${showInactive.current}`);
            return toastr.success(`Success updating ${tax.data.name} tax`);
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            // setIsLoading(false);
        }
    };
    const CloseTaxRatesModal = () => {
        setSelectedTaxRate();
        setIsTaxRateModalVisible(false);
    };

    const openConfirmModal = (title, component) => {
        setConfirmModal(true);
        setModalTitle(title);
        setModalContent(component);
    };
    const closeConfirmModal = () => {
        setConfirmModal(false);
        setModalTitle('');
        setModalContent();
    };

    const handleArchive = async () => {
        if (!selectedTaxRate) return;
        try {
            // setIsLoading(true);
            const tax = selectedTaxRate;
            const taxId = tax.id;
            tax.archived = !tax.archived;
            delete tax.id;
            await TaxesApi.update(taxId, tax);
            getTaxes(page, rowsPerPage, `archived=${showArchived.current}&inactive=${showInactive.current}`);
            return toastr.success(`Tax ${tax.name} successfully ${tax.archived ? 'archived' : 'unarchived'}`);
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            // setIsLoading(false);
        }
    };

    const toDo = () => {
        if (modalTitle === 'Archive Tax') handleArchive();
    };

    const openArchive = async tax => {
        setSelectedTaxRate(tax);
        const itemsUsingTax = await TaxesApi.getItemsUsingTax(tax.id);
        if (
            itemsUsingTax.services?.length > 0 ||
            itemsUsingTax.products?.length > 0 ||
            itemsUsingTax.courses?.length > 0
        ) {
            setMethod('archive');
            setArchiveInUseModalVisible(true);
            setItemsUsingTax(itemsUsingTax);
        } else {
            openConfirmModal(
                'Archive Tax',
                `Are you sure you want to ${(tax.name || {}).archived ? 'unarchive' : 'archive'} this tax?`
            );
        }
    };
    const inactivateItem = async tax => {
        try {
            // setIsLoading(true);
            setSelectedTaxRate(tax);
            if (tax.active) {
                const itemsUsingTax = await TaxesApi.getItemsUsingTax(tax.id);
                if (
                    itemsUsingTax.services?.length > 0 ||
                    itemsUsingTax.products?.length > 0 ||
                    itemsUsingTax.courses?.length > 0
                ) {
                    setMethod('inactivate');
                    setArchiveInUseModalVisible(true);
                    setItemsUsingTax(itemsUsingTax);
                    return;
                }
            }
            const taxId = tax.id;
            tax.active = !tax.active;
            delete tax.id;
            await TaxesApi.update(taxId, tax);
            getTaxes(page, rowsPerPage, `archived=${showArchived.current}&inactive=${showInactive.current}`);
            return toastr.success(`Tax ${tax.name} successfully ${tax.active ? 'activeted' : 'inactivated'}`);
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            // setIsLoading(false);
        }
    };
    const actions = [
        {
            title: 'archive',
            action: openArchive
        }
    ];
    const unselectItem = async (field, value, tax) => {
        try {
            // setIsLoading(true);
            tax.items[field] = value;
            const taxId = tax.id;
            delete tax.id;
            await TaxesApi.update(taxId, tax);
            getTaxes(page, rowsPerPage, `archived=${showArchived.current}&inactive=${showInactive.current}`);
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            // setIsLoading(false);
        }
    };

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    return (
        <div className={classes.taxRatesTabRoot}>
            <View>
                <ListPagination
                    isFromOrg={true}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    data={data}
                    maxSize={data.length}
                    defaultColumns={[
                        { title: 'Active', field: 'active', onClick: inactivateItem },
                        { title: 'Name', field: 'name', onClick: openTaxRatesModal },
                        { title: 'Description', field: 'description' },
                        { title: 'Rate', field: 'rate' },
                        { title: 'Products', field: 'items.products' },
                        { title: 'Services', field: 'items.services' }
                    ]}
                    actions={actions}
                    onCreate={openTaxRatesModal}
                    filters={[
                        { name: 'archived', label: 'Show Archived', value: false },
                        { name: 'inactive', label: 'Show Inactive', value: false }
                    ]}
                    highlightArchived={true}
                    ApiCall={getTaxes}
                    activeCall={inactivateItem}
                    unselectItem={unselectItem}
                    addNewButtonText={'Add new tax rate'}
                />
            </View>
            {confirmModal && (
                <ConfirmModal
                    isOpen
                    setIsOpen={closeConfirmModal}
                    onConfirm={toDo}
                    title={modalTitle}
                    content={modalContent}
                />
            )}
            {isTaxRateModalVisible && selectedTaxRate && (
                <TaxRateModal
                    closeModal={CloseTaxRatesModal}
                    createTax={createTaxRate}
                    updateTax={updateTaxRate}
                    selectedTaxRate={selectedTaxRate}
                />
            )}
            {archiveInUseModalVisible && selectedTaxRate && itemsUsingTax && (
                <TaxInUseModal
                    method={method}
                    setModalVisible={setArchiveInUseModalVisible}
                    itemsUsingTax={itemsUsingTax}
                    tax={selectedTaxRate}
                />
            )}
        </div>
    );
}
export default withStyles(taxRateStyles)(TaxRatesTab);
