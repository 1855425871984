import React, { useEffect, useState } from 'react';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';

function Accordion({
    title,
    children,
    disableFlex = true,
    defaultExpanded = false,
    hideItem = false,
    collapse = false
}) {
    const [isExpanded, setIsExpanded] = useState(defaultExpanded);

    useEffect(() => {
        return () => {
            // eslint-disable-next-line no-unused-vars
            setIsExpanded(expanded => {
                expanded = !expanded;
            });
        };
    }, [collapse]);

    if (hideItem) {
        return <div style={{ display: 'none' }}>{children}</div>;
    }

    return (
        <ExpansionPanel onChange={(e, isExpanded) => setIsExpanded(isExpanded)} expanded={isExpanded}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography>{title}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ display: disableFlex ? 'block' : 'flex' }}>
                {children}
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
}

Accordion.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.any,
    disableFlex: PropTypes.bool,
    hideItem: PropTypes.bool
};

export default Accordion;
