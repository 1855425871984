import React, { useRef, useState } from 'react';
import ListPagination from '../common/ListPagination';
import View from '../common/View';
import { stringify } from 'query-string';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import FormModal from './form';
import { archiveForm, getForm, listForms, updateFormActive } from '../../collums-components/api/FormApi';
import { toastr } from 'react-redux-toastr';
import ConfirmModal from '../common/ConfirmModal';

const defaultModalProperties = [
    {
        id: 'archive',
        visible: false
    },
    {
        id: 'form',
        visible: false
    }
];

const FormsView = () => {
    const filters = useRef({
        name: '',
        inactive: false,
        archived: false
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [isLoading, setIsLoading] = useState(false);
    const [forms, setForms] = useState([]);
    const [size, setSize] = useState(0);
    const [modalProperties, setModalProperties] = useState(defaultModalProperties);
    const [selectedForm, setSelectedForm] = useState();

    const getForms = async (skip = 0, limit = rowsPerPage, queryParam = '', refreshing = false) => {
        const query = {
            name: '',
            inactive: filters.current.inactive,
            archived: filters.current.archived
        };
        if (!queryParam && filters.current.name) {
            Object.assign(query, filters.current);
        }
        if (queryParam.indexOf('name') > -1) {
            const splitByFilterName = queryParam.split('name=')[1];
            if (splitByFilterName) {
                const filterName = splitByFilterName.split('&')[0];
                filters.current.name = filterName;
                query.name = filterName;
            }
        } else filters.current.name = '';
        if (queryParam.indexOf('archived') > -1) {
            if (queryParam.indexOf('archived=false') > -1) {
                query.archived = false;
                filters.current.archived = false;
            } else {
                query.archived = true;
                filters.current.archived = true;
            }
        }
        if (queryParam.indexOf('inactive') > -1) {
            if (queryParam.indexOf('inactive=false') > -1) {
                query.inactive = false;
                filters.current.inactive = false;
            } else {
                query.inactive = true;
                filters.current.inactive = true;
            }
        }
        try {
            setIsLoading(true);
            const newData = await listForms(skip, limit, stringify(query));
            if (refreshing) {
                setForms(newData.items);
                setPage(0);
                setSize(newData.size);
            }
            setIsLoading(false);
            return newData.items;
        } catch (err) {
            setIsLoading(false);
            return [];
        }
    };

    const refreshList = () => {
        getForms(0, undefined, '', true);
    };

    const showCreateModal = async () => {
        try {
            setIsLoading(true);
            setSelectedForm();
            setModalProperties(
                defaultModalProperties.map(el => {
                    if (el.id === 'form') return { ...el, visible: true };
                    return { ...el, visible: false };
                })
            );
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    };

    const showUpdateModal = async form => {
        try {
            setIsLoading(true);
            const fullFormInfo = await getForm(form.id);
            setSelectedForm(fullFormInfo);
            setModalProperties(
                defaultModalProperties.map(el => {
                    if (el.id === 'form') return { ...el, visible: true };
                    return { ...el, visible: false };
                })
            );
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    };

    const closeModal = () => {
        setSelectedForm();
        setModalProperties(defaultModalProperties);
    };

    const onConfirmArchive = async () => {
        if (!selectedForm) return;
        try {
            setIsLoading(true);
            await archiveForm(selectedForm.id);
            toastr.success(`Form sucessfuly ${selectedForm.archived ? 'unarchived' : 'archived'}`);
            setSelectedForm();
            refreshList();
            closeModal();
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong');
            closeModal();
            setIsLoading(false);
        }
    };

    const renderModal = () => {
        const modal = modalProperties.find(el => el.visible);
        if (modal) {
            switch (modal.id) {
                case 'form':
                    return <FormModal closeModal={closeModal} selectedForm={selectedForm} refreshList={refreshList} />;
                default:
                    if (!selectedForm) {
                        setModalProperties(defaultModalProperties);
                        return null;
                    }
                    return (
                        <ConfirmModal
                            isOpen
                            setIsOpen={closeModal}
                            onConfirm={onConfirmArchive}
                            title={`${selectedForm.archived ? 'Unarchive' : 'Archive'} Form`}
                            content={`Are you sure you want ${
                                selectedForm.archived ? 'unarchive' : 'archive'
                            } this form?`}
                            centerContent
                        />
                    );
            }
        }
    };

    const changeActive = async formData => {
        if (formData.archived) return toastr.error('Cannot active an archived Form');
        setIsLoading(true);
        try {
            await updateFormActive(formData.id);
            toastr.success('Form updated successfuly');
            await refreshList();
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong');
            setIsLoading(false);
        }
    };

    const showArchiveModal = formData => {
        setSelectedForm(formData);
        setModalProperties(
            defaultModalProperties.map(el => {
                if (el.id === 'archive') return { ...el, visible: true };
                return { ...el, visible: false };
            })
        );
    };

    const copyItem = async id => {
        try {
            setIsLoading(true);
            const fullFormInfo = await getForm(id);
            ['active', 'archived', 'createdAt', 'id', 'isMasterForm'].forEach(key => {
                if (fullFormInfo[key] !== undefined) delete fullFormInfo[key];
            });
            setSelectedForm({
                ...fullFormInfo,
                name: `${fullFormInfo.name} - Copy`
            });
            setModalProperties(
                defaultModalProperties.map(el => {
                    if (el.id === 'form') return { ...el, visible: true };
                    return { ...el, visible: false };
                })
            );
        } catch (err) {
            toastr.error(err?.data.message || 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    };

    const actions = [
        {
            title: 'archive',
            action: showArchiveModal
        },
        {
            title: 'copy',
            action: copyItem
        }
    ];

    return (
        <>
            <LoadingScreen isVisible={isLoading} />
            <View>
                <ListPagination
                    isFromOrg={true}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    data={forms}
                    maxSize={size}
                    defaultColumns={[
                        { title: 'Active', field: 'active' },
                        { title: 'Type', field: 'type' },
                        { title: 'Name', field: 'name', onClick: showUpdateModal },
                        { title: 'Description', field: 'description' }
                    ]}
                    filters={[
                        { name: 'archived', label: 'Show Archived', value: false },
                        { name: 'inactive', label: 'Show Inactive', value: false }
                    ]}
                    actions={actions}
                    ApiCall={getForms}
                    onCreate={showCreateModal}
                    nameFilter={true}
                    filterName="name"
                    highlightArchived={true}
                    activeCall={changeActive}
                />
            </View>
            {modalProperties.some(el => el.visible) && renderModal()}
        </>
    );
};

export default FormsView;
