export const adminMenuStyles = () => ({
    adminMenuContainer: {
        width: '100%',
        margin: 0
    },
    adminTitle: {
        textAlign: 'center',
        lineHeight: '15vh',
        fontWeight: 500
    },
    clinicOptions: {
        '& .MuiOutlinedInput-root': {
            fontSize: 12,
            height: 'auto'
        }
    },
    font: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontSize: '14px',
        '& > * > *, & > * > * > *, & > *': {
            fontFamily: 'Roboto, Arial, Sans-serif',
            fontSize: '14px'
        }
    },
    autocomplete: {
        margin: 0,
        fontSize: 14,
        width: '100%',
        '& > * > *, & > *': {
            lineHeight: '10px'
        },
        '& input': {
            fontSize: 14
        }
    },
    headerContainer: {
        // height: '15vh'
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        paddingBottom: '50px'
    },
    clinicSelector: {
        width: '200px'
    },
    menuOption: {
        backgroundColor: 'white',
        border: '3px solid black',
        borderRadius: 10,
        padding: '10px !important',
        height: 310,
        margin: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: 345
    },
    selectLocation: {
        '& input::placeholder': {
            fontWeight: 400,
            fontSize: '14px !important',
            color: '#333 !important',
            fontFamily: 'Roboto, Arial, "sans-serif" !important'
        }
    },
    menuOptionContainer: {
        marginTop: '1rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        padding: '0 16px'
    },
    menuOptionItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
    },
    link: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontSize: 16,
        marginBottom: 12,
        color: '#3083EC'
    },
    linkLocked: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontSize: 16,
        marginBottom: 12,
        color: '#626262'
    }
});
