import React from 'react';
import PropTypes from 'prop-types';

import { FormControl, InputLabel, TextField } from '@material-ui/core';

import AddressApi from '../api/AddressApi';

function PostCodeInput({ value, name, readOnly, onChange, onSearch, className, required }) {
    const zipcodeLookup = async e => {
        const res = await AddressApi.addressLookup(e.target.value);
        onSearch(res);
    };

    return (
        <FormControl className={className} variant="outlined">
            <InputLabel shrink>Postcode {required && '*'}</InputLabel>
            <TextField
                name={name}
                value={value}
                onChange={onChange}
                onBlur={zipcodeLookup}
                inputProps={{
                    readOnly: readOnly,
                    form: { autocomplete: 'off' },
                    style: { textTransform: 'uppercase' }
                }}
                required={required}
                variant="outlined"
            />
        </FormControl>
    );
}

PostCodeInput.propTypes = {
    onChange: PropTypes.func,
    onSearch: PropTypes.func.isRequired,
    value: PropTypes.any,
    className: PropTypes.string,
    name: PropTypes.string,
    required: PropTypes.bool,
    readOnly: PropTypes.bool
};

export default PostCodeInput;
