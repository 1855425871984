import {
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextField
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import UploadButton from '../../../collums-components/components/common/UploadButton';
import PostCodeInput from '../../../collums-components/form/PostCodeInput';
import { isValidPhone } from '../../../collums-components/helpers/validPhoneNumber';
import {
    GENDERS,
    TITLES,
    RELATIONSHIPS,
    PREFERRED_PHONE_COUNTRIES,
    DEFAULT_PHONE_COUNTRY,
    DEFAULT_PHOTO,
    COUNTRY_CODES
} from '../../../collums-constants';
import { serviceDetailsModalStyles } from '../../services/styles';
import PhoneNumberInput from 'material-ui-phone-number';
import DeleteIcon from '@material-ui/icons/DeleteOutline';

export default function PersonalTab({
    register,
    control,
    watch,
    setPhoneNumber,
    setOtherNumber,
    setValue,
    practitioner,
    isFromOrg,
    countries,
    setFile,
    setDeletedPhoto,
    setSignatureFile,
    setDeletedSignature,
    countryCode,
    allClinics
}) {
    const classes = makeStyles(serviceDetailsModalStyles)();
    const { gender } = watch(['gender']);
    const { mobilePhone } = watch(['mobilePhone']);
    const { otherPhone } = watch(['otherPhone']);
    const [avatar, setAvatar] = useState(practitioner.avatar || DEFAULT_PHOTO.DEFAULT_MALE);
    const [signature, setSignature] = useState(null);
    const [changedPhoto, setChangedPhoto] = useState(false);
    const [changedSignature, setChangedSignature] = useState(false);
    const [isInvalid, setIsInvalid] = useState({
        otherPhone: false,
        phone: false
    });

    const setAddressValues = address => {
        if (!address) return;
        setValue('county', address.admin_county || '');
        setValue('country', (countries.find(c => c.label === address.country) || {}).value);
        setValue('city', address.admin_district || '');
    };

    const onDropAvatar = async (file, picture) => {
        if (!picture) return;

        setAvatar(picture);
        setChangedPhoto(true);
        setDeletedPhoto(false);
        if (setFile) {
            setFile(file);
        }
    };

    const onDropSignature = async (file, picture) => {
        if (!picture) return;

        setSignature(picture);
        setChangedSignature(true);
        setDeletedSignature(false);
        if (setSignature) {
            setSignatureFile(file);
        }
    };

    const removePhoto = () => {
        if (setFile) {
            if (gender === GENDERS.FEMALE) {
                setAvatar(DEFAULT_PHOTO.DEFAULT_FEMALE);
            } else {
                setAvatar(DEFAULT_PHOTO.DEFAULT_MALE);
            }
            setDeletedPhoto(true);
            setChangedPhoto(true);
            setFile(null);
        }
    };

    const removeSignature = () => {
        if (setSignatureFile) {
            setSignature(null);
            setDeletedSignature(true);
            setChangedSignature(true);
            setSignatureFile(null);
        }
    };

    useEffect(() => {
        if (!practitioner.avatar && !changedPhoto) {
            if (gender === GENDERS.FEMALE) {
                setAvatar(DEFAULT_PHOTO.DEFAULT_FEMALE);
            } else {
                setAvatar(DEFAULT_PHOTO.DEFAULT_MALE);
            }
        }
        // eslint-disable-next-line
    }, [gender]);

    const handleChangePhoneNumber = (value, isOther) => {
        if (isOther) {
            setOtherNumber(value);
            return;
        }
        setPhoneNumber(value);
    };

    const practitionerAvatar = practitioner => {
        const defaultAvatar =
            practitioner.gender === 'Female' ? DEFAULT_PHOTO.DEFAULT_FEMALE : DEFAULT_PHOTO.DEFAULT_MALE;

        const avatar = practitioner.avatar ? practitioner.avatar : null;

        return avatar ?? defaultAvatar;
    };

    const practitionerSignature = practitioner => {
        return practitioner.signature ? practitioner.signature : null;
    };

    const signatureUrl = changedSignature ? `${signature}` : practitionerSignature(practitioner);

    return (
        <div className={classes.formContent}>
            <div style={{ display: 'flex', padding: '10px', margin: '0 15px 15px 0', flexDirection: 'column' }}>
                <img
                    alt="Customer avatar"
                    style={{ height: '128px', width: '128px' }}
                    src={changedPhoto ? `${avatar}` : practitionerAvatar(practitioner)}
                ></img>
                <div style={{ display: 'flex' }}>
                    <UploadButton
                        buttonText="Choose image"
                        onChange={onDropAvatar}
                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                        maxFileSize={5242880}
                        minHeight={300}
                        minWidth={300}
                        style={{ width: '128px', marginTop: '20px', marginBottom: '10px' }}
                    />
                    <IconButton
                        aria-label="upload picture"
                        component="span"
                        onClick={removePhoto}
                        style={{ marginTop: 8 }}
                    >
                        <DeleteIcon color="error" />
                    </IconButton>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <FormControlLabel
                    label="Active"
                    control={
                        <Checkbox
                            defaultChecked={practitioner.id ? practitioner.active : true}
                            inputProps={{ name: 'active' }}
                            inputRef={register}
                        />
                    }
                    disabled={practitioner ? practitioner.archived || practitioner.isAdmin : false}
                />
                <FormControlLabel
                    label="Show on PIN login screen?"
                    control={
                        <Checkbox
                            defaultChecked={practitioner?.shouldShowInLockscreen === false ? false : true}
                            inputProps={{ name: 'shouldShowInLockscreen' }}
                            inputRef={register}
                        />
                    }
                />
            </div>
            <div className={classes.formContentRow}>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel>Title</InputLabel>
                    <Controller
                        defaultValue={practitioner?.title || ''}
                        name="title"
                        control={control}
                        as={
                            <Select
                                disabled={!isFromOrg && allClinics.length > 1}
                                inputProps={{
                                    className: classes.selectInputProps
                                }}
                            >
                                <MenuItem value={undefined}>
                                    <em>None</em>
                                </MenuItem>
                                {Object.values(TITLES)
                                    .filter(title => title !== TITLES.CUSTOM)
                                    .sort()
                                    .map(title => (
                                        <MenuItem key={title} value={title}>
                                            {title}
                                        </MenuItem>
                                    ))}
                            </Select>
                        }
                    />
                </FormControl>
                <div />
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>First name *</InputLabel>
                    <TextField
                        inputProps={{
                            readOnly: !isFromOrg && allClinics.length > 1
                        }}
                        inputRef={register({ required: ' First name' })}
                        name="firstName"
                        variant="outlined"
                        default={practitioner?.firstName || ''}
                    />
                </FormControl>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Middle name</InputLabel>
                    <TextField
                        inputProps={{
                            readOnly: !isFromOrg && allClinics.length > 1
                        }}
                        inputRef={register}
                        name="middleName"
                        variant="outlined"
                        default={practitioner?.middleName || ''}
                    />
                </FormControl>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Surname *</InputLabel>
                    <TextField
                        inputProps={{
                            readOnly: !isFromOrg && allClinics.length > 1
                        }}
                        inputRef={register({ required: ' Surname' })}
                        name="surname"
                        variant="outlined"
                        default={practitioner?.surname || ''}
                    />
                </FormControl>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Previous surname</InputLabel>
                    <TextField
                        inputProps={{
                            readOnly: !isFromOrg && allClinics.length > 1
                        }}
                        inputRef={register}
                        name="prevSurname"
                        variant="outlined"
                        default={practitioner?.prevSurname || ''}
                    />
                </FormControl>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>AKA</InputLabel>
                    <TextField
                        inputProps={{
                            readOnly: !isFromOrg && allClinics.length > 1
                        }}
                        inputRef={register}
                        name="aka"
                        variant="outlined"
                    />
                </FormControl>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Display name *</InputLabel>
                    <TextField
                        inputProps={{
                            readOnly: !isFromOrg && allClinics.length > 1
                        }}
                        inputRef={register({ required: ' Display name' })}
                        name="displayName"
                        variant="outlined"
                    />
                </FormControl>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel>Gender</InputLabel>
                    <Controller
                        defaultValue=""
                        name="gender"
                        control={control}
                        as={
                            <Select
                                disabled={!isFromOrg && allClinics.length > 1}
                                inputProps={{
                                    className: classes.selectInputProps
                                }}
                            >
                                <MenuItem value={undefined}>
                                    <em>None</em>
                                </MenuItem>
                                {Object.values(GENDERS).map(gender => (
                                    <MenuItem key={gender} value={gender}>
                                        {gender}
                                    </MenuItem>
                                ))}
                            </Select>
                        }
                    />
                </FormControl>
                {gender === GENDERS.CUSTOM ? (
                    <FormControl className={classes.formItem} variant="outlined">
                        <InputLabel shrink>Custom gender</InputLabel>
                        <TextField
                            inputProps={{
                                readOnly: !isFromOrg && allClinics.length > 1
                            }}
                            inputRef={register}
                            name="customGender"
                            variant="outlined"
                        />
                    </FormControl>
                ) : (
                    <div />
                )}
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Mobile Phone</InputLabel>
                    <PhoneNumberInput
                        disabled={!isFromOrg && allClinics.length > 1}
                        variant="outlined"
                        error={isInvalid.phone}
                        onChange={(value, phoneInfo) => {
                            setIsInvalid({
                                ...isInvalid,
                                phone: !isValidPhone(value, phoneInfo.countryCode, phoneInfo.dialCode)
                            });
                            handleChangePhoneNumber(value);
                        }}
                        preferredCountries={PREFERRED_PHONE_COUNTRIES}
                        defaultCountry={countryCode || DEFAULT_PHONE_COUNTRY}
                        value={mobilePhone}
                        name="mobilePhone"
                    />
                </FormControl>
                <div />
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Other phone</InputLabel>
                    <PhoneNumberInput
                        disabled={!isFromOrg && allClinics.length > 1}
                        variant="outlined"
                        onChange={(value, phoneInfo) => {
                            setIsInvalid({
                                ...isInvalid,
                                otherPhone: !isValidPhone(value, phoneInfo.countryCode, phoneInfo.dialCode)
                            });
                            handleChangePhoneNumber(value, true);
                        }}
                        error={isInvalid.otherPhone}
                        preferredCountries={PREFERRED_PHONE_COUNTRIES}
                        defaultCountry={countryCode || DEFAULT_PHONE_COUNTRY}
                        value={
                            otherPhone ? otherPhone.phone : countryCode === COUNTRY_CODES['Australia'] ? '+61' : '+44'
                        }
                        name="otherPhone.phone"
                    />
                </FormControl>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Email *</InputLabel>
                    <TextField
                        inputProps={{
                            readOnly: !isFromOrg && allClinics.length > 1
                        }}
                        inputRef={register({ required: ' Email' })}
                        name="email"
                        variant="outlined"
                    />
                </FormControl>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>DOB</InputLabel>
                    <Controller
                        defaultValue={(practitioner || {}).dateOfBirth ? new Date(practitioner.dateOfBirth) : null}
                        name="dateOfBirth"
                        control={control}
                        as={
                            <DatePicker
                                disabled={!isFromOrg && allClinics.length > 1}
                                TextFieldComponent={props => <TextField {...props} variant="outlined" />}
                                maxDate={Date(`${new Date().getFullYear()}-1-1`)}
                                format="dd/MM/yyyy"
                            />
                        }
                    />
                </FormControl>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Address 1</InputLabel>
                    <TextField
                        inputProps={{
                            readOnly: !isFromOrg && allClinics.length > 1
                        }}
                        inputRef={register}
                        name="address1"
                        variant="outlined"
                    />
                </FormControl>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Address 2</InputLabel>
                    <TextField
                        inputProps={{
                            readOnly: !isFromOrg && allClinics.length > 1
                        }}
                        inputRef={register}
                        name="address2"
                        variant="outlined"
                    />
                </FormControl>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>City</InputLabel>
                    <TextField
                        inputProps={{
                            readOnly: !isFromOrg && allClinics.length > 1
                        }}
                        inputRef={register}
                        name="city"
                        variant="outlined"
                        InputLabelProps={{}}
                    />
                </FormControl>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>County</InputLabel>
                    <TextField
                        inputProps={{
                            readOnly: !isFromOrg && allClinics.length > 1
                        }}
                        inputRef={register}
                        name="county"
                        variant="outlined"
                    />
                </FormControl>
                <Controller
                    name="postcode"
                    defaultValue=""
                    control={control}
                    as={
                        <PostCodeInput
                            readOnly={!isFromOrg && allClinics.length > 1}
                            className={classes.formItem}
                            onSearch={setAddressValues}
                        />
                    }
                />
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Next of kin</InputLabel>
                    <TextField
                        inputProps={{
                            readOnly: !isFromOrg && allClinics.length > 1
                        }}
                        inputRef={register}
                        name="nextOfKin"
                        variant="outlined"
                    />
                </FormControl>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel>Relationship</InputLabel>
                    <Controller
                        defaultValue=""
                        name="relationship"
                        control={control}
                        as={
                            <Select
                                disabled={!isFromOrg && allClinics.length > 1}
                                inputProps={{
                                    className: classes.selectInputProps
                                }}
                            >
                                <MenuItem value={undefined}>
                                    <em>None</em>
                                </MenuItem>
                                {Object.values(RELATIONSHIPS).map(relationship => (
                                    <MenuItem key={relationship} value={relationship}>
                                        {relationship}
                                    </MenuItem>
                                ))}
                            </Select>
                        }
                    />
                </FormControl>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Next of kin tel number</InputLabel>
                    <TextField
                        inputProps={{
                            readOnly: !isFromOrg && allClinics.length > 1
                        }}
                        inputRef={register}
                        name="contactNo"
                        variant="outlined"
                    />
                </FormControl>
                <FormControl className={`${classes.formItem} ${classes.colourInput}`} variant="outlined">
                    <InputLabel shrink>Colour</InputLabel>
                    <TextField
                        disabled={!isFromOrg && allClinics.length > 1}
                        inputRef={register}
                        name="colour"
                        type="color"
                        variant="outlined"
                    />
                </FormControl>
            </div>
            <div style={{ display: 'flex', padding: '10px', margin: '0 15px 15px 0', flexDirection: 'column' }}>
                <div style={{ fontSize: 11 }}>Signature:</div>
                {signatureUrl && signatureUrl !== 'null' && (
                    <img
                        alt="Practitioner signature"
                        style={{ width: '200px', border: '1px solid black' }}
                        src={signatureUrl}
                    ></img>
                )}
                {(!signatureUrl || signatureUrl === 'null') && (
                    <div style={{ height: '100px', width: '200px', border: '1px solid black' }}></div>
                )}
                <div style={{ display: 'flex' }}>
                    <UploadButton
                        buttonText="Choose file"
                        onChange={onDropSignature}
                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                        maxFileSize={5242880}
                        minHeight={300}
                        minWidth={300}
                        style={{ width: '128px', marginTop: '20px', marginBottom: '10px' }}
                    />
                    <IconButton
                        aria-label="upload signature"
                        component="span"
                        onClick={removeSignature}
                        style={{ marginTop: 8 }}
                    >
                        <DeleteIcon color="error" />
                    </IconButton>
                </div>
            </div>
        </div>
    );
}
