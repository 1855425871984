import api from '../collums-components/api/config';
import Axios from 'axios';

class OrganisationsApi {
    static listOrganisations() {
        return api.request({
            method: 'GET',
            url: '/organisations'
        });
    }

    static getOrganisation() {
        return api.request({
            method: 'GET',
            url: '/organisations'
        });
    }

    static async uploadOrganisationLogo(organisationId, file) {
        const uploadUrl = await api.request({
            method: 'GET',
            url: `/organisations/${organisationId}/upload-url?type=${file.name.split('.')[1]}`
        });

        const signedRequest = uploadUrl.signedRequest;
        const url = uploadUrl.url;

        const options = {
            headers: {
                'Content-Type': file.name.split('.')[1],
                'x-amz-acl': 'public-read'
            }
        };
        try {
            await Axios({ url: signedRequest, method: 'PUT', data: file, options });
            return url;
        } catch (error) {
            return '';
        }
    }

    static updateOrganisation(data) {
        return api.request({
            method: 'PUT',
            url: '/organisations',
            data
        });
    }

    static updateShowPatientInfo(data) {
        return api.request({
            method: 'PUT',
            url: '/organisations/update-show-patient-info',
            data: { noShowPatientInfo: data }
        });
    }

    static createOrganisation(data) {
        return api.request({
            method: 'POST',
            url: '/organisations',
            data
        });
    }

    static removePaymentType(organisationId, paymentId) {
        return api.request({
            method: 'PUT',
            url: '/organisations/payments/remove',
            data: { organisationId, paymentId }
        });
    }
}

export default OrganisationsApi;
