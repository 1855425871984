export const userPermissionsStyles = () => ({
    centerAlignedCell: {
        textAlign: 'center !important'
    },
    subAccordion: {
        display: 'none'
    },
    accordionText: {
        width: 120
    }
});
