import api from '../collums-components/api/config';

class LoginApi {
    static getMe() {
        return api.request({
            method: 'GET',
            url: '/user/me'
        });
    }

    static login({ email, password }) {
        return api.request({
            method: 'POST',
            url: '/login',
            data: { email, password }
        });
    }

    static resetPassword({ email }) {
        return api.request({
            method: 'POST',
            url: '/reset-password',
            data: { email, redirect: 'collums-admin' }
        });
    }

    static changePassword({ password, code }) {
        return api.request({
            method: 'POST',
            url: `/reset-password/${code}`,
            data: { password }
        });
    }

    static generatePIN() {
        return api.request({
            method: 'GET',
            url: '/generate-pin'
        });
    }

    static validatePIN({ pin }) {
        return api.request({
            method: 'POST',
            url: 'validate-pin',
            data: { pin }
        });
    }

    static lockUser() {
        return api.request({
            method: 'POST',
            url: '/lock-user'
        });
    }

    static isUserLocked() {
        return api.request({
            method: 'GET',
            url: '/is-user-locked'
        });
    }
}

export default LoginApi;
