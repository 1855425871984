import React, { useState, useEffect, useMemo } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Header from '../header/Header';
// import SquareView from '../square/SquareView';
import XeroView from '../xero/XeroView';
import TemporaryDiscountsView from '../temporaryDiscounts/TemporaryDiscountsView';
import ClinicsView from '../clinics/ClinicsView';
import NotificationsView from '../notifications/NotificationsView';
import SubHeader from '../header/subHeader/SubHeader';

import organisationTabs from '../header/tabsReferences/organisationTabs';
import { organisationStyles } from './styles';
import FormsView from '../forms';
import Settings from '../settings/Settings';
import UserPermissions from '../userPermisssions/UserPermissions.jsx';
import PractitionersView from '../practitioners/PractitionersView';
import AlertsView from '../alerts/AlertsView';
import PreAfterCare from '../notifications/PreAfterCareView';
import JobsView from '../jobs/JobsView';
import StripeView from '../stripe/StripeView';
import ListsView from '../lists/ListsView';

const PlaceholderView = tabName => (
    <div>
        <h3>organisation {tabName}</h3>
    </div>
);

const OrganisationView = ({ classes, ...props }) => {
    const currentTab = props.match.params.tab;
    const [selectedSubTab, setSelectedSubTab] = useState('');

    /* when location or match changes, redifines the current subtab */
    useEffect(() => {
        if (props.match.params.subTab === undefined) {
            if (currentTab === 'temporary-discounts') {
                setSelectedSubTab(currentTab);
            } else {
                setSelectedSubTab('');
            }
        } else {
            setSelectedSubTab(props.match.params.subTab);
        }
    }, [props.match.params.subTab, currentTab]);

    /* sub tabs defined on the end of file */
    const subTabs = useMemo(() => {
        const selectSubTabs = currentTab => {
            if (currentTab === 'temporary-discounts') {
                return discountsSubTab;
            } else {
                return [];
            }
        };
        return selectSubTabs(currentTab);
    }, [currentTab]);

    /* seeks tabName, as the reference(currentTab) sometimes isn't equal */
    const tabName = () => {
        if (currentTab === 'temporary-discounts') {
            return 'Temporary Discounts';
        }

        const tab = organisationTabs.filter(tab => {
            return tab.ref.split('/')[2] === currentTab;
        });
        return tab[0].name;
    };

    function subTabName() {
        const subTab = subTabs.filter(tab => tab.value === selectedSubTab);
        return subTab[0]?.label;
    }

    function renderComponent() {
        switch (currentTab) {
            case 'account':
                return PlaceholderView(currentTab);
            case 'temporary-discounts':
                if (selectedSubTab === 'temporary-discounts') {
                    return <TemporaryDiscountsView isFromOrg={true} />;
                } else {
                    return PlaceholderView(currentTab);
                }

            case 'forms':
                return <FormsView />;
            case 'general-info':
                return PlaceholderView(currentTab);
            case 'loyalty':
                return PlaceholderView(currentTab);
            case 'notifications':
                return <NotificationsView />;
            case 'alerts':
                return <AlertsView />;
            case 'clinics':
                return <ClinicsView />;
            case 'online-booking':
                return PlaceholderView(currentTab);
            case 'preaftercare':
                return <PreAfterCare />;
            case 'settings':
                return <Settings />;
            // We are not using SQUARE payments anymore
            // case 'square':
            //     return <SquareView />;
            case 'stripe':
                return <StripeView />;
            case 'xero':
                return <XeroView />;
            case 'job':
                return <JobsView />;
            case 'user-permissions':
                return <UserPermissions />;
            case 'staffs':
                return <PractitionersView isFromOrg={true} />;
            case 'lists':
                return <ListsView />;
            default:
            // should not get here. TODO: wrong route component
            // return PlaceholderView(currentTab);
        }
    }

    // returns a boolean to show or not SubHeader
    function showSubHeader() {
        return currentTab === 'temporary-discounts';
    }

    return (
        <>
            <Header />
            {showSubHeader() ? (
                <SubHeader tabs={subTabs} currentSubTab={selectedSubTab} currentTab={currentTab} path="organisation" />
            ) : (
                <></>
            )}
            <div style={{ marginTop: 10, marginLeft: 10 }}>
                <Link className={classes.link} to="/">
                    Admin home
                </Link>
            </div>
            <Typography variant="h4" className={classes.title}>
                Organisation - {selectedSubTab !== '' ? subTabName() : tabName()}
            </Typography>
            {renderComponent()}
        </>
    );
};

export default withStyles(organisationStyles)(OrganisationView);

const discountsSubTab = [
    { label: 'Discounts', value: 'temporary-discounts' },
    { label: 'Bundles', value: 'bundles' }
];
