import React from 'react';
import { withStyles, IconButton, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import CustomAutoComplete from '../CustomAutoComplete';
import { EmailOptionStyles } from './styles';
import { PropTypes } from 'prop-types';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { EMAIL_FORM_FOR } from '../../../../../collums-constants';

const EmailOption = ({ classes, disabled, value, id, index, options, onChange, propertyValues }) => {
    const onDeleteItem = () => {
        const filteredValues = propertyValues.filter((_, itemIndex) => {
            return itemIndex !== index;
        });
        onChange(filteredValues);
    };

    return (
        <div className={classes.flex}>
            <CustomAutoComplete
                id={id}
                disabled={disabled}
                options={options}
                value={value.form}
                onChange={(_, newValue) => {
                    const newPropValues = propertyValues.map((el, itemIndex) => {
                        if (index === itemIndex) {
                            return {
                                ...el,
                                form: newValue
                            };
                        }
                        return el;
                    });

                    onChange(newPropValues);
                }}
            />
            <RadioGroup
                disabled={disabled}
                aria-label="send-form-on"
                name={id}
                value={value.sendOn}
                className={classes.radioGroup}
                onChange={({ target }) => {
                    const newValues = propertyValues.map((item, itemIndex) => {
                        if (itemIndex === index) {
                            return {
                                ...item,
                                sendOn: target.value
                            };
                        }
                        return item;
                    });
                    onChange(newValues);
                }}
            >
                {Object.entries(EMAIL_FORM_FOR).map(([key, emailCondition]) => {
                    return (
                        <FormControlLabel key={key} value={emailCondition} control={<Radio />} label={emailCondition} />
                    );
                })}
            </RadioGroup>
            <IconButton disabled={disabled} onClick={onDeleteItem}>
                <DeleteOutlineOutlinedIcon className={classes.deleteIcon} />
            </IconButton>
        </div>
    );
};

EmailOption.propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    classes: PropTypes.object.isRequired,
    getOptionLabel: PropTypes.func.isRequired,
    freeSolo: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool
};

export default withStyles(EmailOptionStyles)(EmailOption);
