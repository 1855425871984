/* component to show when there is no sub menu, to keep pages with the same element placement */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { SubHeaderPlaceholderStyles } from './styles';
const SubHeaderPlaceholder = () => {
    const classes = makeStyles(SubHeaderPlaceholderStyles)();
    return <div className={classes.container}></div>;
};

export default SubHeaderPlaceholder;
