export const listsViewStyles = () => ({
    textBox: {
        '& .MuiFormControl-root': {
            display: 'block',
            marginLeft: '20px'
        },
        '& .MuiInputBase-root': {
            display: 'block',
            border: '1px solid black'
        },
        '& .MuiInputBase-inputMultiline': {
            padding: '10px 20px'
        },
        marginTop: '10px'
    }
});
