import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Menu, MenuItem, Fade, makeStyles } from '@material-ui/core';

import { buttonsStyles } from '../../../collums-constants/styles/stylesheets/buttonsStyles';

const Actions = ({ actions, customer }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const globalStyles = makeStyles(buttonsStyles)();

    const handleClick = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <div>
            <Button
                aria-controls="fade-menu"
                aria-haspopup="true"
                className={globalStyles.grayButton}
                onClick={handleClick}
            >
                Options
            </Button>
            <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                {actions.map(action => (
                    <MenuItem
                        key={action.label}
                        onClick={() => {
                            action.handler(customer);
                            handleClose();
                        }}
                    >
                        {action.label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

Actions.propTypes = {
    actions: PropTypes.array.isRequired,
    customer: PropTypes.object.isRequired
};

export default Actions;
