export const alertFormStyle = () => ({
    accordions: {
        '& .MuiExpansionPanelSummary-content': {
            '& p': {
                fontSize: 16,
                fontWeight: 400
            }
        }
    },
    centerText: {
        textAlign: 'center',
        marginTop: 30
    },
    blueBtn: {
        height: 36,
        margin: '0px 10px 0px 0px',
        padding: '6px 14px',
        borderRadius: 0,
        color: '#ffffff',
        backgroundColor: '#3083EC',
        border: 'none',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, .2)'
        }
    },
    textBlueBtn: {
        height: 36,
        margin: '0px 10px 0px 0px',
        padding: '6px 14px',
        borderRadius: 0,
        color: '#3083EC',
        border: 'none',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, .2)'
        }
    },
    flexContainer: {
        display: 'flex',
        marginTop: 10,
        alignItems: 'center'
    },
    reminderContainer: {
        display: 'flex',
        marginTop: 10,
        gap: '4px',
        alignItems: 'center'
    },
    selectInputProps: {
        display: 'flex',
        alignItems: 'center',
        height: 0,
        boxSizing: 'border-box'
    },
    input: {
        '& input::placeholder': {
            fontWeight: 400,
            fontSize: '14px !important',
            color: '#333 !important',
            fontFamily: 'Roboto, Arial, "sans-serif" !important'
        }
    },
    defaultLabelWidth: {
        minWidth: 65
    }
});

export const alertViewStyle = () => ({
    countersDiv: {
        width: 'calc(100% - 40px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        '& *': {
            fontFamily: 'Roboto, Arial, "sans-serif"',
            fontSize: 14
        }
    }
});
