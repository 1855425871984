import * as actions from '../actions/actionTypes';

const defaultState = {};

const organisationReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actions.LOAD_ORGANISATION:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export default organisationReducer;
