import React from 'react';
import { TextField, withStyles, FormControlLabel, Checkbox } from '@material-ui/core';
import { TabStyles } from './styles';
import { Controller } from 'react-hook-form';

const General = ({ register, job, classes, control }) => {
    return (
        <div className={classes.formContent}>
            <div className={classes.formContentRow}>
                <TextField
                    className={classes.formItem}
                    inputRef={register({ required: 'Name' })}
                    defaultValue={job?.name || ''}
                    name="name"
                    label="Name"
                    variant="outlined"
                    required
                />
            </div>
            <div className={classes.formContentRow}>
                <TextField
                    className={`${classes.formItem} ${classes.largeFormItem}`}
                    inputRef={register}
                    defaultValue={job?.description || ''}
                    name="description"
                    label="Description"
                    variant="outlined"
                />
            </div>
            <div className={classes.formContentRow}>
                <FormControlLabel
                    className={classes.formItem}
                    label="Show column in Calendar"
                    control={
                        <Controller
                            control={control}
                            defaultValue={job?.showColumnInCalendar || false}
                            name="showColumnInCalendar"
                            render={({ onChange, onBlur, value, ref }) => (
                                <Checkbox
                                    onBlur={onBlur}
                                    onChange={e => onChange(e.target.checked)}
                                    checked={value}
                                    inputRef={ref}
                                    defaultChecked={value || false}
                                />
                            )}
                        />
                    }
                />
            </div>
        </div>
    );
};

export default withStyles(TabStyles)(General);
