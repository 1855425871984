import React from 'react';
import {
    Grid,
    FormControl,
    TextField,
    withStyles,
    FormControlLabel,
    FormHelperText,
    Select,
    MenuItem,
    Checkbox,
    Typography,
    Tooltip
} from '@material-ui/core';

import { Controller } from 'react-hook-form';

import { settingsStyles } from '../styles';
import ColorPicker from '../../common/ColorPicker';
import InfoTooltip from '../../common/InfoTooltip';
import InfoIcon from '@material-ui/icons/Info';

const LATE_CANCELLATION_CHARGE_TYPES = {
    AMOUNT: 'AMOUNT',
    PERCENTAGE: 'PERCENTAGE'
};

function GeneralTab({ classes, register, organisation, setOrganisation, control }) {
    const changeNotificationCheckbox = async ({ target }) => {
        if (target.id === 'appt-notification-email') {
            setOrganisation({
                ...organisation,
                appointmentNotifications: {
                    email: target.checked,
                    sms: organisation.appointmentNotifications?.sms
                }
            });
        } else {
            setOrganisation({
                ...organisation,
                appointmentNotifications: {
                    sms: target.checked,
                    email: organisation.appointmentNotifications?.email
                }
            });
        }
    };

    const changeShowCancelTerms = async ({ target }) => {
        setOrganisation({
            ...organisation,
            showCancelTerms: target.checked
        });
    };

    return (
        <Grid container direction="column" spacing={4}>
            <Grid item>
                <FormControlLabel
                    label="Remind if deposit required on booking"
                    control={
                        <Checkbox
                            onChange={e =>
                                setOrganisation({
                                    ...organisation,
                                    showBookingDepositNotificationInCalendar: e.target.checked
                                })
                            }
                            color="primary"
                            checked={Boolean(organisation?.showBookingDepositNotificationInCalendar)}
                            inputRef={register}
                            name="showBookingDepositNotificationInCalendar"
                        />
                    }
                />
                <InfoTooltip
                    title={
                        'When booking appointments into the calendar, you will see a message regarding the amount of deposit required.'
                    }
                />
            </Grid>

            <Typography variant="h4" className={classes.sectionTitle}>
                Choose colours for calendar events:
            </Typography>
            <Grid className={classes.calendarColors}>
                <div className={classes.colorItem}>
                    <Typography>Breaks</Typography>
                    <ColorPicker
                        inputRef={register}
                        name="colors.break"
                        onChange={value => {
                            setOrganisation({
                                ...organisation,
                                colors: { ...organisation?.colors, break: value }
                            });
                        }}
                        defaultValue={organisation?.colors?.break}
                    />
                </div>
                <div className={classes.colorItem}>
                    <Typography>Blocks</Typography>
                    <ColorPicker
                        inputRef={register}
                        name="colors.block"
                        onChange={value => {
                            setOrganisation({
                                ...organisation,
                                colors: { ...organisation?.colors, block: value }
                            });
                        }}
                        defaultValue={organisation?.colors?.block}
                    />
                </div>
                <div className={classes.colorItem}>
                    <Typography>Leave</Typography>
                    <ColorPicker
                        inputRef={register}
                        name="colors.leave"
                        onChange={value => {
                            setOrganisation({
                                ...organisation,
                                colors: { ...organisation?.colors, leave: value }
                            });
                        }}
                        defaultValue={organisation?.colors?.leave}
                    />
                </div>
            </Grid>
            <Typography variant="h4" className={classes.sectionTitle}>
                Late canc/No show fees
            </Typography>

            <Grid item direction="row" className={classes.noPaddingTop + ' ' + classes.extraPaddingBottom}>
                <div style={{ display: 'flex' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={
                                    organisation && organisation.showCancelTerms ? organisation.showCancelTerms : false
                                }
                                onChange={changeShowCancelTerms}
                                inputRef={register}
                                name="showCancelTerms"
                                color="primary"
                            />
                        }
                        className={`${classes.label}`}
                        label={
                            <Typography style={{ fontSize: 14 }}>
                                Show/use cancellation terms in online booking and calendar
                            </Typography>
                        }
                    />
                    <div style={{ marginTop: '7px' }}>
                        <InfoTooltip
                            title={
                                'If a staff member tries to reschedule or cancel an appointment within the time frame\n' +
                                '                                specified, they will see a pop-up reminding them that the action could incur a\n' +
                                '                                cancellation charge and its value. If a client tries to reschedule or cancel an\n' +
                                '                                appointment online within the time frame specified, they will see a similar pop-up. No\n' +
                                '                                payment will be take automatically - this will always have to be initiated by a member\n' +
                                '                                of staff by raising an invoice.'
                            }
                        />
                    </div>
                </div>

                <Grid item container direction="column" spacing={2} md={6}>
                    <Grid item>
                        <FormControl className={classes.fieldContainer}>
                            <FormHelperText>Cancellation charge type</FormHelperText>
                            <Controller
                                defaultValue={
                                    organisation.lateCancellationChargeType || LATE_CANCELLATION_CHARGE_TYPES.AMOUNT
                                }
                                name="lateCancellationChargeType"
                                control={control}
                                as={
                                    <Select
                                        id="late-cancellation-charge-type"
                                        variant="outlined"
                                        disabled={!organisation.showCancelTerms}
                                        value={organisation.lateCancellationChargeType}
                                        inputProps={{
                                            inputRef: () => {
                                                register({
                                                    name: 'lateCancellationChargeType',
                                                    value:
                                                        organisation.lateCancellationChargeType ||
                                                        LATE_CANCELLATION_CHARGE_TYPES.AMOUNT
                                                });
                                            },
                                            style: { textTransform: 'capitalize' }
                                        }}
                                        className={classes.selectField}
                                    >
                                        {Object.values(LATE_CANCELLATION_CHARGE_TYPES).map(type => (
                                            <MenuItem key={type} value={type}>
                                                {`${type.charAt(0)}${type.toLowerCase().slice(1)}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                }
                            />
                        </FormControl>
                    </Grid>

                    <Grid item>
                        <FormControl className={classes.fieldContainer}>
                            <FormHelperText>Cancellation charge value</FormHelperText>
                            <TextField
                                id="late-cancellation-charge-value"
                                name="lateCancellationChargeValue"
                                disabled={!organisation.showCancelTerms}
                                variant="outlined"
                                value={organisation.lateCancellationChargeValue || 0}
                                onChange={({ target }) =>
                                    setOrganisation({
                                        ...organisation,
                                        lateCancellationChargeValue: target.value
                                    })
                                }
                                inputRef={register}
                                type="number"
                            />
                        </FormControl>
                    </Grid>

                    <Grid item>
                        <FormControl className={classes.fieldContainer}>
                            <FormHelperText>Late cancellation period (hrs)</FormHelperText>
                            <TextField
                                id="late-cancellation-period"
                                name="lateCancellationPeriod"
                                disabled={!organisation.showCancelTerms}
                                variant="outlined"
                                value={organisation.lateCancellationPeriod || 0}
                                onChange={({ target }) =>
                                    setOrganisation({
                                        ...organisation,
                                        lateCancellationPeriod: target.value
                                    })
                                }
                                inputRef={register}
                                type="number"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>

            <div className={classes.sectionTitle}>
                <Typography variant="h4">
                    Default notification on appointment creation/edit{' '}
                    <Tooltip
                        title={
                            <Typography style={{ fontSize: 14 }}>
                                Notifications in Admin &gt; Notifications must be switched on for messages to be sent.
                            </Typography>
                        }
                        aria-label="test"
                        style={{ verticalAlign: 'bottom', marginLeft: '12px' }}
                    >
                        <InfoIcon />
                    </Tooltip>
                </Typography>
                <div className={classes.checkboxesContainer}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={
                                    organisation && organisation.appointmentNotifications
                                        ? organisation.appointmentNotifications.email
                                        : false
                                }
                                onChange={changeNotificationCheckbox}
                                id="appt-notification-email"
                                inputRef={register}
                                name="appointmentNotifications.email"
                                color="primary"
                            />
                        }
                        className={`${classes.label}`}
                        label="Email"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={
                                    organisation && organisation.appointmentNotifications
                                        ? organisation.appointmentNotifications.sms
                                        : false
                                }
                                onChange={changeNotificationCheckbox}
                                inputRef={register}
                                name="appointmentNotifications.sms"
                                color="primary"
                            />
                        }
                        className={`${classes.label}`}
                        label="SMS"
                    />
                </div>
            </div>
        </Grid>
    );
}

export default withStyles(settingsStyles)(GeneralTab);
