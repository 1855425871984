import React, { useMemo, useState } from 'react';
import {
    Grid,
    withStyles,
    FormControl,
    FormControlLabel,
    IconButton,
    makeStyles,
    Typography,
    Checkbox,
    Tooltip
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Switch from '../../../collums-components/components/common/customSwitch';

import { FIXED_PAYMENT_OPTIONS, INVOICE_PAYMENT_TYPES } from '../../../collums-constants/index';
import { buttonsStyles } from '../../../collums-constants/styles/stylesheets/buttonsStyles';
import CancelContinueModal from '../../../collums-components/components/common/CancelContinueModal';

import { settingsStyles } from '../styles';
import InfoIcon from '@material-ui/icons/Info';

function PaymentTypesTab({
    classes,
    fields,
    register,
    setValue,
    setOrganisation,
    organisation,
    openModal,
    openDeleteModal,
    setUpdate,
    hasAdvancedPermissions
}) {
    const buttonStyles = makeStyles(buttonsStyles)();
    const [squareModal, setSquareModal] = useState(false);
    const [chosenPaymentTypes, setChosenPaymentTypes] = useState([]);
    const [leftOptions, rightOptions] = useMemo(() => {
        const currentFields = [...fields];
        if (!currentFields || !currentFields.length || currentFields.length === 0) {
            return [];
        }

        const half = Math.ceil(currentFields.length / 2);
        return [currentFields.splice(0, half), currentFields.splice(-half)];
    }, [fields]);

    // Change an option to true/false, and set the form state
    const changePaymentOption = async (option, active) => {
        let newPayments = await (fields || []).map(payment => {
            if (payment.name === option.name) {
                return { ...option, active };
            }
            return payment;
        });

        if (option.name === INVOICE_PAYMENT_TYPES.CREDIT_CARD && !active) {
            setChosenPaymentTypes(newPayments);
            setSquareModal(true);
        } else {
            savePaymentTypes(newPayments);
        }
    };

    const savePaymentTypes = newPayments => {
        setValue('paymentTypes', newPayments);
        setOrganisation({
            ...organisation,
            paymentTypes: newPayments
        });
        // save the change on the api
        setUpdate(true);
        setChosenPaymentTypes([]);
    };

    const changeNotificationCheckbox = async ({ target }) => {
        if (target.id === 'payment-notification-email') {
            setOrganisation({
                ...organisation,
                paymentNotifications: {
                    email: target.checked,
                    SMS: organisation.paymentNotifications.SMS
                }
            });
        } else {
            setOrganisation({
                ...organisation,
                paymentNotifications: {
                    SMS: target.checked,
                    email: organisation.paymentNotifications.email
                }
            });
        }
    };

    const disallowCheckboxHandler = async ({ target }) => {
        setOrganisation({
            ...organisation,
            disallowAmexPayments: target.checked
        });
    };

    return (
        <div className={classes.paymentsTabRoot}>
            <div className={classes.addPaymentTypeContainer}>
                <Typography>Add new payment type</Typography>
                <IconButton onClick={openModal}>
                    <AddCircleIcon className={buttonStyles.addCircleStyle} />
                </IconButton>
            </div>
            <Grid container direction="row" spacing={4}>
                <Grid item container direction="column" spacing={4} xs={5}>
                    {leftOptions &&
                        leftOptions.length > 0 &&
                        leftOptions.map((option, index) => {
                            return (
                                <Grid item key={option.id}>
                                    <FormControl className={classes.formControl}>
                                        {FIXED_PAYMENT_OPTIONS.includes(option.name) ? (
                                            <>
                                                <Switch
                                                    inputRef={register()}
                                                    name={`paymentTypes[${index}].${option.name}`}
                                                    disabled
                                                    defaultValue={option}
                                                    checked={true}
                                                />
                                                <Typography>{option.label}</Typography>
                                            </>
                                        ) : (
                                            <div className={classes.typeNotFixed}>
                                                <Switch
                                                    name={`paymentTypes[${index}].${option.name}`}
                                                    inputRef={register()}
                                                    defaultValue={option}
                                                    checked={option.active}
                                                    onChange={({ target }) => {
                                                        changePaymentOption(option, target.checked);
                                                    }}
                                                />
                                                {option.name !== INVOICE_PAYMENT_TYPES.CREDIT_CARD ? (
                                                    <Typography
                                                        onClick={() => openModal(option)}
                                                        className={classes.clickableText}
                                                    >
                                                        {option.label}
                                                    </Typography>
                                                ) : (
                                                    <Typography>{option.label}</Typography>
                                                )}

                                                {option.name !== INVOICE_PAYMENT_TYPES.CREDIT_CARD && (
                                                    <IconButton
                                                        className={classes.actionBtn}
                                                        onClick={() => openDeleteModal(option)}
                                                    >
                                                        <DeleteOutlineIcon />
                                                    </IconButton>
                                                )}
                                            </div>
                                        )}
                                    </FormControl>
                                </Grid>
                            );
                        })}
                </Grid>

                <Grid item container direction="column" spacing={4} xs={5}>
                    {rightOptions &&
                        rightOptions.length > 0 &&
                        rightOptions.map((option, index) => {
                            return (
                                <Grid item key={option.id}>
                                    <FormControl className={classes.formControl}>
                                        {FIXED_PAYMENT_OPTIONS.includes(option.name) ? (
                                            <>
                                                <Switch
                                                    inputRef={register()}
                                                    name={`paymentTypes[${index + leftOptions.length}].${option.name}`}
                                                    disabled
                                                    defaultValue={option}
                                                    checked={true}
                                                />
                                                <Typography>{option.label}</Typography>
                                            </>
                                        ) : (
                                            <div className={classes.typeNotFixed}>
                                                <Switch
                                                    name={`paymentTypes[${index + leftOptions.length}].${option.name}`}
                                                    inputRef={register()}
                                                    defaultValue={option}
                                                    checked={option.active}
                                                    onChange={({ target }) => {
                                                        changePaymentOption(option, target.checked);
                                                    }}
                                                />
                                                <Typography
                                                    onClick={() => openModal(option)}
                                                    className={classes.clickableText}
                                                >
                                                    {option.label}
                                                </Typography>
                                                <IconButton
                                                    className={classes.actionBtn}
                                                    onClick={() => openDeleteModal(option)}
                                                >
                                                    <DeleteOutlineIcon />
                                                </IconButton>
                                            </div>
                                        )}
                                    </FormControl>
                                </Grid>
                            );
                        })}
                </Grid>
            </Grid>
            {squareModal && (
                <CancelContinueModal
                    setOpen={setSquareModal}
                    onContinue={() => savePaymentTypes(chosenPaymentTypes)}
                    closeOnBlur={false}
                    title="Stripe payments are required to charge saved cards, especially in the case of late cancellation or no show."
                    contentText="Are you sure you want to disable this?"
                    dialogSize="xs"
                    continueButtonText="Disable"
                    cancelButtonText="No"
                />
            )}
            <div className={classes.notificationContainer}>
                <Typography variant="h4" className={classes.sectionTitle + ' ' + classes.noMarginLeft}>
                    Default notification on taking payment
                    <Tooltip
                        title={
                            <Typography style={{ fontSize: 14 }}>
                                Notifications in Admin &gt; Notifications must be switched on for messages to be sent.
                            </Typography>
                        }
                        aria-label="help"
                        style={{ verticalAlign: 'bottom', marginLeft: '20px' }}
                    >
                        <InfoIcon />
                    </Tooltip>
                </Typography>
                <div className={classes.checkboxesContainer}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={
                                    organisation && organisation.paymentNotifications
                                        ? organisation.paymentNotifications.email
                                        : false
                                }
                                onChange={changeNotificationCheckbox}
                                id="payment-notification-email"
                                inputRef={register}
                                name="paymentNotifications.email"
                                color="primary"
                            />
                        }
                        className={`${classes.label}`}
                        label="Email"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={
                                    organisation && organisation.paymentNotifications
                                        ? organisation.paymentNotifications.SMS
                                        : false
                                }
                                onChange={changeNotificationCheckbox}
                                inputRef={register}
                                name="paymentNotifications.SMS"
                                color="primary"
                            />
                        }
                        className={`${classes.label}`}
                        label="SMS"
                    />
                </div>
            </div>
            {hasAdvancedPermissions ? (
                <div className={classes.disallowContainer}>
                    <div className={classes.checkboxesContainer}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!organisation?.disallowAmexPayments}
                                    onChange={disallowCheckboxHandler}
                                    inputRef={register}
                                    name="disallowAmexPayments"
                                    color="primary"
                                />
                            }
                            className={`${classes.label}`}
                            label="Disable AMEX Card Payments"
                        />
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
}

export default withStyles(settingsStyles)(PaymentTypesTab);
