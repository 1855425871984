export default () => ({
    consentWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        boxSizing: 'border-box',
        '& .html-content': {
            fontFamily: 'Roboto, Arial, Sans-serif',
            textAlign: 'justify',
            fontSize: '22px',
            wordBreak: 'break-word',

            '& p:first-child': {
                marginTop: 0,
                paddingTop: 0
            },

            '& p:last-child': {
                marginBottom: 0,
                paddingBottom: 0
            }
        },
        marginBottom: '2rem',
        paddingRight: '24px'
    }
});
