import api from './config';

class CommissionApi {
    static list(query) {
        const queryString = `archived=${query?.archived || false}${
            query?.inactive === true ? `&inactive=${query.inactive}` : ''
        }`;
        return api.request({
            method: 'GET',
            url: `/commissions?${queryString}`
        });
    }

    static create(data) {
        return api.request({
            method: 'POST',
            url: '/commissions',
            data
        });
    }

    static update(commissionId, data) {
        return api.request({
            method: 'PUT',
            url: `/commissions/${commissionId}`,
            data
        });
    }

    static archive(commissionId) {
        return api.request({
            method: 'DELETE',
            url: `/commissions/${commissionId}`
        });
    }

    static updateActivity(commissionId) {
        return api.request({
            method: 'PUT',
            url: `/commissions/${commissionId}/toggle-active`
        });
    }
}
export default CommissionApi;
