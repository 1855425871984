import { makeStyles, Select, FormControl, MenuItem, InputLabel, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { serviceDetailsModalStyles } from '../styles';
import CurrencyInput from '../../common/CurrencyInput';
import { getLocationItem } from '../../../collums-constants/utils';

function PricingTab({ isFromOrg, register, control, watch, clinic, setValue, currentService, taxes }) {
    const classes = makeStyles(serviceDetailsModalStyles)();
    const taxWatch = watch('taxType');
    const netPriceWatch = watch('defaultNetPrice');
    const [defaultNetPrice, setDefaultNetPrice] = useState(
        isFromOrg ? currentService?.defaultNetPrice || 0 : getLocationItem(currentService, clinic).netPrice
    );
    const [defaultGrossPrice, setDefaultGrossPrice] = useState(
        isFromOrg ? currentService?.defaultGrossPrice || 0 : getLocationItem(currentService, clinic).grossPrice
    );

    const calculate = (event, isNetPrice) => {
        let value = event.target.value;

        const tax = taxWatch?.rate || 0;

        value = value.replace(/[£$€]/g, '');
        value = value.replace(',', '');

        if (isNetPrice) {
            const newGrossValue = (parseFloat(value) * tax) / 100 + parseFloat(value);
            newGrossValue ? setDefaultGrossPrice(newGrossValue) : setDefaultGrossPrice(0);
            setDefaultNetPrice(value);
        } else {
            const newNetValue = parseFloat(value) - (parseFloat(value) * tax) / (100 + tax);
            newNetValue ? setDefaultNetPrice(Math.round(newNetValue * 100) / 100) : setDefaultNetPrice(0);
            setDefaultGrossPrice(value);
        }
    };

    useEffect(() => {
        register('defaultGrossPrice', defaultGrossPrice);
        register('defaultNetPrice', defaultNetPrice);
    }, []); // eslint-disable-line

    useEffect(() => {
        const tax = taxWatch?.rate || 0;
        setDefaultGrossPrice(parseFloat(defaultNetPrice) + (parseFloat(defaultNetPrice) * tax) / 100);
    }, [taxWatch]); // eslint-disable-line

    useEffect(() => {
        setValue('defaultGrossPrice', defaultGrossPrice);
        setValue('defaultNetPrice', defaultNetPrice);
    }, [defaultNetPrice, defaultGrossPrice]); // eslint-disable-line

    const changeTax = () => {
        const tax = 1 + (taxWatch?.rate || 0) / 100;
        const newGross = parseFloat(netPriceWatch) * tax;
        if (isNaN(newGross)) return;
        setValue('defaultGrossPrice', Math.round(newGross * 100) / 100);
    };

    useEffect(() => {
        changeTax();
    }, [taxWatch]); // eslint-disable-line

    return (
        <div className={classes.formContent}>
            <FormControl className={classes.formItem} variant="outlined">
                <InputLabel>Tax *</InputLabel>
                <Controller
                    name="taxType"
                    rules={register({ required: 'Tax type' })}
                    defaultValue={taxes.find(tax => {
                        const taxType = getLocationItem(currentService, clinic)?.taxType;
                        return tax.id === (taxType?.id || taxType);
                    })}
                    control={control}
                    as={
                        <Select>
                            {taxes &&
                                taxes.map((option, index) => (
                                    <MenuItem key={index} value={option}>
                                        {option.rate}%
                                    </MenuItem>
                                ))}
                        </Select>
                    }
                ></Controller>
            </FormControl>

            <div className={classes.formContentRow}>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Net price</InputLabel>
                    <CurrencyInput
                        disabled={taxWatch === undefined}
                        value={defaultNetPrice * 100}
                        CustomInput={TextField}
                        onChange={e => {
                            calculate(e, true);
                        }}
                    />
                </FormControl>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Gross price</InputLabel>
                    <CurrencyInput
                        disabled={taxWatch === undefined}
                        value={defaultGrossPrice * 100}
                        CustomInput={TextField}
                        onChange={e => {
                            calculate(e);
                        }}
                    />
                </FormControl>
            </div>
        </div>
    );
}

PricingTab.propTypes = {
    register: PropTypes.func.isRequired,
    control: PropTypes.any,
    watch: PropTypes.any,
    setValue: PropTypes.any,
    getValues: PropTypes.any,
    clinics: PropTypes.array,
    onChange: PropTypes.any
};

export default PricingTab;
