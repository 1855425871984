import React, { useEffect, useState, useRef, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { Typography, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import CategoryFormModal from './CategoriesFormModal';
import PropTypes from 'prop-types';
import Modal from '../common/Modal';
import CategoryApi from '../../collums-components/api/CategoryApi';
import { categoryModalStyles } from './styles';
import { toastr } from 'react-redux-toastr';
import ConfirmModal from '../common/ConfirmModal';
import RoomApi from '../../api/RoomApi';
import EquipmentApi from '../../api/EquipmentApi';
import { listClinics, getClinic } from '../../collums-components/api/ClinicApi';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import { getAvailableForServices } from '../../collums-components/api/FormApi';
import setFormOptions from './../../services/setFormOptions';
import { formatServiceFormData, getLocationItem } from '../../collums-constants/utils';
import TagsApi from '../../collums-components/api/TagsApi';
import { DEPOSIT_TYPES } from '../settings/tabs/CustomerAppTab';

const CategoryModal = props => {
    const [clinics, setClinics] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [equipments, setEquipments] = useState([]);
    const [forms, setForms] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [category, setCategory] = useState(props.category || {});

    const formProperties = useRef();
    const defaultFormProperties = useRef();

    const methodType = props.methodType || 'Update';

    const getCategory = useCallback(async () => {
        if (!category.id && props.categoryId !== 'new') {
            const res = await CategoryApi.get(props.categoryId);
            setCategory({ ...res, prevName: res.name });
        }
    }, [category.id, props.categoryId]);

    const loadItems = async () => {
        if (methodType === 'Update' || methodType === 'Create') {
            setIsLoading(true);
            getCategory();
            if (category?.tags?.length) {
                const categoryIds = (() => {
                    if (typeof category.tags[0] === 'string') return category.tags.join(',');
                    return category.tags.map(el => el.id);
                })();
                const fetchedTags = await TagsApi.getTagsByIds(categoryIds);
                setCategory({
                    ...category,
                    tags: fetchedTags
                });
            }
            const fetchedForms = await getAvailableForServices();
            setFormOptions(category, fetchedForms, formProperties, defaultFormProperties);
            setForms(fetchedForms);
            const fetchedRooms = await RoomApi.listAll(props.clinic ? props.clinic : '');
            setRooms(fetchedRooms);
            const fetchedEquipment = await EquipmentApi.listAll(props.clinic ? props.clinic : '');
            setEquipments(fetchedEquipment);
            const fetchedClinics =
                props.clinic !== 'organisation' ? [await getClinic(props.clinic)] : await listClinics();
            setClinics(fetchedClinics);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadItems();
        /*eslint-disable-next-line */
    }, []);

    const orderLocation = data => {
        _.forEach(data, (value, key) => {
            if (value === undefined || value === '') delete data[key];
        });
        delete data.room_equipment;
        if (data.locations === undefined || _.size(data.locations) === 0) return data;
        const locations = data.locations.filter(element => element.isSelected) || [];
        if (_.size(locations) === 0) {
            return { ...data, locations };
        }
        const rooms = locations
            .map((element, index) => {
                const haveRooms = element.rooms ? element.rooms[index] : undefined;
                if (category && !haveRooms) {
                    const currLoc = (category.locations || []).find(el => el.clinic === element.value);
                    if (currLoc) {
                        return (currLoc.rooms || []).map(el => ({ isSelected: true, id: el.room }));
                    }
                }
                return (haveRooms || []).filter(room => room.isSelected);
            })
            .map(room => {
                return room.map(element => {
                    return { room: element.id } || {};
                });
            });
        const equipments = locations
            .map((element, index) => {
                if (!element.equipments) {
                    if (category) {
                        const currLoc = (category.locations || []).find(el => el.clinic === element.value);
                        if (currLoc) {
                            return (currLoc.equipments || []).map(el => ({ isSelected: true, id: el.equipment }));
                        }
                    }
                    return [];
                }
                if (_.isArray(element.equipments[index])) {
                    return element.equipments[index].filter(equip => equip.isSelected && equip !== undefined);
                }
                return element.equipments?.[index]?.items
                    ? element.equipments[index].items.filter(equip => equip.isSelected && equip !== undefined)
                    : [];
            })
            .map(
                equipment =>
                    equipment.map(equip => {
                        return { equipment: equip.id };
                    }) || []
            );
        if (_.size(rooms) > 0 || _.size(equipments) > 0) data.clinics = locations.map(element => element.value);
        _.size(rooms) > 0 ? (data.rooms = rooms) : delete data.rooms;
        _.size(equipments) > 0 ? (data.equipments = equipments) : delete data.equipments;
        delete data.locations;
        if (data.rooms !== undefined) {
            data.rooms = data.rooms
                .map(element => element)
                .map(element => {
                    return element.map((rooms, priority) => {
                        return { room: rooms.room, priority: priority };
                    });
                });
        }
        if (data.equipments !== undefined) {
            data.equipments = data.equipments
                .map(element => element)
                .map(element => {
                    return element.map((equipment, priority) => {
                        return { equipment: equipment.equipment, priority: priority };
                    });
                });
        }
        /* eslint-disable-next-line */
        data.locations = data.clinics.map((element, index) => {
            const equipments = data.equipments[index] || [];
            const rooms = data.rooms[index] || [];
            if (equipments || rooms)
                return {
                    clinic: element,
                    equipments: equipments,
                    rooms: rooms
                };
        });
        delete data.clinics;
        delete data.equipments;
        delete data.rooms;
        return data;
    };

    const isLocationSelected = locations => {
        const selectedLocations = locations.filter(location => location.isSelected);
        if (!selectedLocations[0]) {
            return false;
        }
        return true;
    };

    const isFormDataInvalid = value => {
        Object.assign(value, formProperties.current);

        const errors = formatServiceFormData(value);

        if (errors.length) {
            toastr.error(errors[0]);
            return true;
        }
        return false;
    };

    const confirm = async (method, data = {}, practitionersList, setSaveButtonInactive = () => {}) => {
        setSaveButtonInactive(true);
        let response, errData;
        let action = method;

        if (category.id === undefined) action = 'create';
        if (action === 'archive' && category.name.includes('- COPY')) {
            setSaveButtonInactive(false);
            return toastr.error("Can't archive with 'COPY' in name");
        }

        if (action !== 'remove' && action !== 'archive') {
            if (!isLocationSelected(data.locations) && props.isFromOrg) {
                setSaveButtonInactive(false);
                toastr.error('Missing field location');
                return;
            }
        }

        if (
            [DEPOSIT_TYPES.PERCENTAGE, DEPOSIT_TYPES.FIXED_AMOUNT].includes(data.bookingDepositType) &&
            !data.bookingDepositAmount
        ) {
            setSaveButtonInactive(false);
            toastr.error('Missing percentage/amount field');
            return;
        }

        const result = { action };

        switch (action) {
            case 'create':
                try {
                    if (isFormDataInvalid(data)) {
                        setSaveButtonInactive(false);
                        return;
                    }
                    data = orderLocation(data);
                    data.tags = (data.tags || []).map(tag => tag.id);
                    if (props.isFromOrg || props.allClinics.length === 1) {
                        data.locations = data.locations.map(loc => {
                            loc.active = true;
                            loc.archived = false;
                            loc.showOnline = false;
                            loc.onlineName = data.onlineName;
                            loc.order = data.order;
                            loc.onlineDescription = data.onlineDescription;
                            loc.onlineSubtitle = data.onlineSubtitle;
                            loc.allowCommission = data.allowCommission;
                            loc.commissionCondition = data.commissionCondition;
                            if (!props.isFromOrg) {
                                loc.staffs = practitionersList;
                            }
                            return loc;
                        });
                    }
                    response = await CategoryApi.create(data);
                    result.response = response;
                    setSaveButtonInactive(false);
                    toastr.success(`Category ${response.name} successfully created!`);
                } catch (err) {
                    err.data !== undefined ? (errData = err.data.message) : (errData = 'Something went wrong');
                    setSaveButtonInactive(false);
                    toastr.error(errData);
                    return;
                }
                break;
            case 'update':
                try {
                    if (isFormDataInvalid(data)) {
                        setSaveButtonInactive(false);
                        return;
                    }
                    data = orderLocation(data);
                    data.tags = (data.tags || []).map(tag => tag.id);
                    const newLocationsClinics = props.isFromOrg
                        ? data.locations.map(loc => loc.clinic)
                        : [props.clinic];
                    const oldLocationsClinics = props.isFromOrg
                        ? category.locations.map(loc => loc.clinic)
                        : [props.clinic];
                    if (props.isFromOrg) {
                        data.locations = data.locations.map(loc => {
                            delete loc.rooms;
                            delete loc.equipments;
                            const originalLoc = category.locations.find(el => el.clinic === loc.clinic);
                            loc.active = originalLoc?.active || true;
                            loc.archived = originalLoc?.archived || false;
                            loc.showOnline = originalLoc?.showOnline || false;
                            loc.onlineName = data.onlineName;
                            loc.order = data.order;
                            loc.onlineDescription = data.onlineDescription;
                            loc.onlineSubtitle = data.onlineSubtitle;
                            loc.allowCommission = data.allowCommission;
                            loc.commissionCondition = data.commissionCondition;
                            if (oldLocationsClinics.includes(loc.clinic)) {
                                const existentOldLoc = category.locations.find(_loc => _loc.clinic === loc.clinic);
                                if (existentOldLoc.id) {
                                    delete existentOldLoc.id;
                                }

                                return {
                                    ...existentOldLoc,
                                    ...loc
                                };
                            } else {
                                if (loc.id) {
                                    delete loc.id;
                                }

                                return loc;
                            }
                        });
                    } else if (!props.isFromOrg && props.clinic) {
                        const locationData = {
                            rooms: data.locations?.[0]?.rooms ?? [],
                            equipments: data.locations?.[0]?.equipments ?? [],
                            staffs: practitionersList
                        };
                        data.locations = category?.locations?.length ? category.locations : [{ clinic: props.clinic }];
                        data.locations = data.locations
                            .map(loc => {
                                if (newLocationsClinics.includes(loc.clinic)) {
                                    if (loc.clinic === props.clinic) {
                                        loc.active = loc.active || true;
                                        loc.archived = loc.archived || false;
                                        loc.showOnline = loc.showOnline || false;
                                        loc.onlineName = data.onlineName;
                                        loc.order = data.order;
                                        loc.onlineDescription = data.onlineDescription;
                                        loc.onlineSubtitle = data.onlineSubtitle;
                                        loc.allowCommission = data.allowCommission;
                                        loc.commissionCondition = data.commissionCondition;
                                        loc.rooms = locationData.rooms;
                                        loc.equipments = locationData.equipments;
                                        loc.staffs = locationData.staffs;
                                    }
                                    // ---- Merge new locs with old ones (rooms, equips, etc)

                                    const oldLoc = category.locations.find(_loc => _loc?.clinic === loc.clinic) || {};
                                    if (oldLoc.id) delete oldLoc.id;

                                    if (loc.id) delete loc.id;

                                    return {
                                        ...oldLoc,
                                        ...loc
                                    };
                                } else {
                                    return false;
                                }
                            })
                            .filter(el => el);
                        data.onlineName = category.onlineName;
                        data.order = category.order;
                        data.onlineDescription = category.onlineDescription;
                        data.onlineSubtitle = category.onlineSubtitle;
                        data.allowCommission = category.allowCommission;
                        data.commissionCondition = category.commissionCondition;
                    }

                    if (!data.onlineDescription) {
                        data.onlineDescription = '';
                    }
                    if (!data.description) {
                        data.description = '';
                    }
                    if (!data.onlineName) {
                        data.onlineName = '';
                    }
                    if (!data.order) {
                        data.order = '';
                    }
                    if (!data.onlineSubtitle) {
                        data.onlineSubtitle = '';
                    }
                    response = await CategoryApi.update(category.id, data, props.clinic, props.isFromOrg);
                    setSaveButtonInactive(false);
                    toastr.success('Category successfully updated!');
                } catch (err) {
                    err.data !== undefined ? (errData = err.data.message) : (errData = 'Something went wrong');
                    setSaveButtonInactive(false);
                    toastr.error(errData);
                    return;
                }
                break;
            case 'remove':
                try {
                    response = await CategoryApi.remove(category.id);
                    setSaveButtonInactive(false);
                    toastr.success('Category successfully removed!');
                } catch (err) {
                    err.data !== undefined ? (errData = err.data.message) : (errData = 'Something went wrong');
                    setSaveButtonInactive(false);
                    toastr.error(errData);
                    return;
                }
                break;
            default:
                try {
                    const value = props.isFromOrg
                        ? category.archived
                        : getLocationItem(category, props.clinic).archived;
                    response = await CategoryApi.archive(category.id, !value, props.clinic, props.isFromOrg);
                    setSaveButtonInactive(false);
                    toastr.success(`Category ${response.name} successfully archived!`);
                } catch (err) {
                    err.data !== undefined ? (errData = err.data.message) : (errData = 'Something went wrong');
                    setSaveButtonInactive(false);
                    toastr.error(errData);
                    return;
                }
                break;
        }
        props.onConfirm(result);
    };

    if (!props.categoryId) {
        return null;
    }

    const Update = () => {
        if (isLoading) return <LoadingScreen />;
        return (
            <CategoryFormModal
                isFromOrg={props.isFromOrg}
                Category={category}
                visible={true}
                onClose={props.onCancel}
                onConfirm={(data, practitionersList, setSaveButtonInactive) => {
                    confirm('update', data, practitionersList, setSaveButtonInactive);
                }}
                rooms={rooms}
                equipments={equipments}
                clinics={clinics}
                clinic={props.clinic}
                formProperties={formProperties}
                formOptions={forms}
                defaultFormProperties={defaultFormProperties}
                allClinics={props.allClinics}
            />
        );
    };

    const Create = () => {
        if (isLoading) return <LoadingScreen />;
        return (
            <CategoryFormModal
                isFromOrg={props.isFromOrg}
                visible={true}
                onClose={props.onCancel}
                onConfirm={(data, practitionersList, setSaveButtonInactive) =>
                    confirm('create', data, practitionersList, setSaveButtonInactive)
                }
                rooms={rooms}
                equipments={equipments}
                clinics={clinics}
                clinic={props.clinic}
                formProperties={formProperties}
                formOptions={forms}
                disableBackdropClick={props.disableBackdropClick ? props.disableBackdropClick : false}
                allClinics={props.allClinics}
            />
        );
    };
    const Archive = () => {
        const modalTitle = `${category.archived ? 'Unarchive' : 'Archive'} category`;
        const modalContent = `Are you sure you want to ${category.archived ? 'unarchive' : 'archive'} this category?`;
        return (
            <ConfirmModal
                isOpen
                setIsOpen={props.onCancel}
                onConfirm={() => confirm('archive')}
                title={modalTitle}
                content={modalContent}
            />
        );
    };

    const Remove = () => {
        return (
            <Modal
                id={`${methodType}-category`}
                isOpen={true}
                title={methodType}
                size="xs"
                draggable
                onConfirm={() => confirm('remove')}
                onClose={props.onCancel}
            >
                <Box container spacing={4}>
                    <Typography item>Are you sure you want to delete this category?</Typography>
                </Box>
            </Modal>
        );
    };

    const MethodModal = () => {
        switch (methodType) {
            case 'Archive':
                return <Archive />;
            case 'Remove':
                return <Remove />;
            case 'Create':
                return <Create />;
            default:
                return <Update />;
        }
    };

    return <MethodModal />;
};

CategoryModal.propTypes = {
    categoryId: PropTypes.string,
    category: PropTypes.object,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(categoryModalStyles)(CategoryModal));
