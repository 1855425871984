import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { withStyles, Typography, IconButton, Tooltip, Button } from '@material-ui/core';
import { ServicesFormStyles } from './styles';
import OrderedOption from './components/OrderedOption';
import { TREATMENT_TYPES } from '../../../collums-constants';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HelpIcon from '@material-ui/icons/Help';
import EmailOption from './components/EmailOption';
import CustomAutoComplete from './components/CustomAutoComplete';

function ServicesFormTab({ classes, isFromOrg, formPropertiesRef, formOptions, allClinics }) {
    const [formProperties, setFormProperties] = useState(formPropertiesRef.current);

    const customerFormsDefault = [
        {
            active: false,
            allowEmailSend: false,
            id: '0',
            name: 'Client patient details'
        },
        {
            active: false,
            allowEmailSend: false,
            id: '1',
            name: 'PMH'
        }
    ];

    useEffect(() => {
        formPropertiesRef.current = formProperties;
    }, [formProperties, formPropertiesRef]);

    const [customerOptions, practitionerOptions, emailOptions] = useMemo(() => {
        return [
            {
                options: formProperties?.customerForms || [],
                type: 'customerForm'
            },
            {
                options: formProperties?.practitionerForms || [],
                type: 'practitionerForm'
            },
            {
                options: formProperties?.formsToEmail || [],
                type: 'formsToEmail'
            }
        ].map(forms => {
            const ids = forms.options?.map(el => el?.form?.id || el?.id).filter(el => el);
            return formOptions
                .filter(form => {
                    return !ids.includes(form.id) && form.active;
                })
                .filter(form => {
                    switch (forms.type) {
                        case 'customerForm': {
                            return !form.isMasterForm;
                        }
                        case 'practitionerForm': {
                            return !form.isMasterForm;
                        }
                        case 'formsToEmail': {
                            return form.allowEmailSend;
                        }
                        default: {
                            return true;
                        }
                    }
                });
        });
    }, [formProperties, formOptions]);

    return (
        <div>
            <div className={classes.flexContainer}>
                <div className={classes.clientContainer}>
                    <Typography variant="h3" className={classes.sectionName}>
                        Client journey forms
                        <IconButton
                            disabled={!isFromOrg && allClinics.length > 1}
                            onClick={() => {
                                setFormProperties({
                                    ...formProperties,
                                    customerForms: [...formProperties.customerForms, undefined]
                                });
                            }}
                            className={classes.addCircle}
                            style={{ marginTop: 0, marginBottom: 0, padding: '0 0 0 10px' }}
                        >
                            <AddCircleIcon style={{ fontSize: 30 }} />
                        </IconButton>
                    </Typography>
                    <div className={classes.sectionContent}>
                        <Typography variant="span" className={classes.span}>
                            Select forms and their order for the client side of the journey
                        </Typography>
                        <Tooltip
                            title={
                                <Typography style={{ fontSize: 14 }}>
                                    The client side of the journey always starts with confirming client details and then
                                    past medical history. The forms you add below will be shown after. At the end of
                                    this part of the journey, the client hands the device back to a member of sta! who
                                    will need to unlock it using their PIN. The same form can be present in both the
                                    client and practitioner journeys. When this is a consent form, it is signed by the
                                    client in the client journey and then signed again in the practitioner journey.
                                </Typography>
                            }
                            aria-label="help"
                        >
                            <HelpIcon className={classes.helpIcon} />
                        </Tooltip>
                    </div>
                    {customerFormsDefault.map((form, index) => {
                        const id = `customer-form-default-${form?.id}-${index}`;
                        return (
                            <OrderedOption
                                disabled={true}
                                key={`${id}-order-option`}
                                value={form}
                                id={id}
                                index={index}
                                options={[]}
                                onChange={() => {}}
                                propertyValues={formProperties.customerForms}
                            />
                        );
                    })}
                    {formProperties?.customerForms?.map((form, index) => {
                        const id = `customer-form-${form?.id}-${index}`;
                        return (
                            <OrderedOption
                                disabled={!isFromOrg && allClinics.length > 1}
                                key={`${id}-order-option`}
                                value={form}
                                id={id}
                                index={index}
                                options={customerOptions}
                                onChange={newValue => {
                                    setFormProperties({
                                        ...formProperties,
                                        customerForms: newValue
                                    });
                                }}
                                propertyValues={formProperties.customerForms}
                            />
                        );
                    })}
                    <div className={classes.addOptionArea}>
                        <Button
                            disabled={!isFromOrg && allClinics.length > 1}
                            onClick={() => {
                                setFormProperties({
                                    ...formProperties,
                                    customerForms: [...formProperties.customerForms, undefined]
                                });
                            }}
                            className={classes.addCircle}
                            style={{ margin: '0 0 10px 35px' }}
                        >
                            Add another form
                        </Button>
                    </div>
                </div>

                <div className={classes.practitionerContainer}>
                    <Typography variant="h3" className={classes.sectionName}>
                        Practitioner journey forms
                        <IconButton
                            disabled={!isFromOrg && allClinics.length > 1}
                            onClick={() => {
                                setFormProperties({
                                    ...formProperties,
                                    practitionerForms: [...formProperties.practitionerForms, undefined]
                                });
                            }}
                            className={classes.addCircle}
                            style={{ marginTop: 0, marginBottom: 0, padding: '0 0 0 10px' }}
                        >
                            <AddCircleIcon style={{ fontSize: 30 }} />
                        </IconButton>
                    </Typography>
                    <div className={classes.sectionContent}>
                        <Typography variant="span" className={classes.span}>
                            Select forms and their order for the practitioner side of the journey
                        </Typography>
                        <Tooltip
                            title={
                                <Typography style={{ fontSize: 14 }}>
                                    Choose the forms for the practitioner side of the journey (this is shown after the
                                    practitioner enters their PIN). After your selected forms, the journey ends with the
                                    photo upload and then treatment form
                                </Typography>
                            }
                            aria-label="help"
                        >
                            <HelpIcon className={classes.helpIcon} />
                        </Tooltip>
                    </div>
                    {formProperties?.practitionerForms.map((form, index) => {
                        const id = `practitioner-form-${form?.id}-${index}`;
                        return (
                            <OrderedOption
                                disabled={!isFromOrg && allClinics.length > 1}
                                key={`${id}-order-option`}
                                value={form}
                                id={id}
                                index={index}
                                options={practitionerOptions}
                                onChange={newValue => {
                                    setFormProperties({
                                        ...formProperties,
                                        practitionerForms: newValue
                                    });
                                }}
                                propertyValues={formProperties.practitionerForms}
                            />
                        );
                    })}
                    <div className={classes.addOptionArea}>
                        <Button
                            disabled={!isFromOrg && allClinics.length > 1}
                            onClick={() => {
                                setFormProperties({
                                    ...formProperties,
                                    practitionerForms: [...formProperties.practitionerForms, undefined]
                                });
                            }}
                            className={classes.addCircle}
                            style={{ margin: '0 0 10px 35px' }}
                        >
                            Add another form
                        </Button>
                    </div>
                </div>
            </div>

            <div className={classes.emailFormsContainer}>
                <Typography variant="h3" className={classes.sectionName}>
                    Forms to be emailed in advance
                    <IconButton
                        disabled={!isFromOrg && allClinics.length > 1}
                        onClick={() => {
                            setFormProperties({
                                ...formProperties,
                                formsToEmail: [...formProperties.formsToEmail, {}]
                            });
                        }}
                        className={classes.addCircle}
                        style={{ marginTop: 0, marginBottom: 0, padding: '0 0 0 10px' }}
                    >
                        <AddCircleIcon style={{ fontSize: 30 }} />
                    </IconButton>
                </Typography>
                <div className={classes.sectionContent}>
                    <Typography variant="span" className={classes.span}>
                        Select forms to be sent to client on appointment booking
                    </Typography>
                    <Tooltip
                        title={
                            <Typography style={{ fontSize: 14 }}>
                                {/* eslint-disable-next-line react/no-unescaped-entities */}
                                For forms to send, &quot;Pre-appointment forms&quot; notification must be switched on.
                                Do this at admin/notifications/Pre-appointment forms.
                            </Typography>
                        }
                        aria-label="help"
                    >
                        <HelpIcon className={classes.helpIcon} />
                    </Tooltip>
                </div>

                {formProperties?.formsToEmail.map((emailFormItem, index) => {
                    const id = `email-form-${emailFormItem?.form?.id}-${index}`;
                    return (
                        <EmailOption
                            disabled={!isFromOrg && allClinics.length > 1}
                            key={`${id}-order-option`}
                            value={emailFormItem}
                            id={id}
                            index={index}
                            options={emailOptions}
                            onChange={newValue => {
                                setFormProperties({
                                    ...formProperties,
                                    formsToEmail: newValue
                                });
                            }}
                            propertyValues={formProperties.formsToEmail}
                        />
                    );
                })}
                <div className={classes.addOptionArea}>
                    <Button
                        disabled={!isFromOrg && allClinics.length > 1}
                        onClick={() => {
                            setFormProperties({
                                ...formProperties,
                                formsToEmail: [...formProperties.formsToEmail, {}]
                            });
                        }}
                        className={classes.addCircle}
                        style={{ margin: '10px 0 10px 35px' }}
                    >
                        Add another form
                    </Button>
                </div>
            </div>

            <div>
                <Typography variant="h3" className={classes.sectionName}>
                    Treatment Record
                </Typography>
                <div className={classes.sectionContent}>
                    <Typography variant="span" className={classes.span}>
                        Default treatment record *
                    </Typography>
                    <Tooltip
                        title={
                            <Typography style={{ fontSize: 14 }}>
                                The treatment record is the template in which treatment notes are entered. Choose which
                                one will appear at the end of the journey for this service.
                            </Typography>
                        }
                        aria-label="help"
                    >
                        <HelpIcon className={classes.helpIcon} />
                    </Tooltip>
                    <div className={classes.marginLeft}>
                        <CustomAutoComplete
                            disabled={!isFromOrg && allClinics.length > 1}
                            id="treatment-record-selector"
                            options={Object.values(TREATMENT_TYPES)}
                            value={formProperties?.defaultTreatmentRecord}
                            onChange={(_, value) => {
                                setFormProperties({
                                    ...formProperties,
                                    defaultTreatmentRecord: value ?? undefined
                                });
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

ServicesFormTab.propTypes = {
    classes: PropTypes.object.isRequired,
    formPropertiesRef: PropTypes.object.isRequired,
    formOptions: PropTypes.array.isRequired
};

export default withStyles(ServicesFormStyles)(ServicesFormTab);
