import api from '../collums-components/api/config';
class RoomApi {
    static query(skip = 0, limit = 15, queryParams = '') {
        return api.request({
            method: 'GET',
            url: `/admin-rooms?skip=${skip}&limit=${limit}${queryParams ? `&${queryParams}` : ''}` // url: `/rooms/${clinic}`
        });
    }

    static listAll(clinic) {
        return api.request({
            method: 'GET',
            url: `/admin-rooms/list-all${clinic ? `?clinic=${clinic}` : ''}`
        });
    }

    static addRoom(data) {
        return api.request({
            method: 'POST',
            url: '/rooms',
            data
        });
    }

    static updateRoom(id, data) {
        return api.request({
            method: 'POST',
            url: `/rooms/${id}`,
            data
        });
    }

    static deleteRoom(id) {
        return api.request({
            method: 'DELETE',
            url: `/rooms/${id}`
        });
    }

    static archiveRoom(id) {
        return api.request({
            method: 'GET',
            url: `/rooms-archive/${id}`
        });
    }

    static changeRoomActive(id) {
        return api.request({
            method: 'PUT',
            url: `/rooms/${id}/change-active`
        });
    }
}

export default RoomApi;
