import React, { useCallback, useEffect, useState } from 'react';
import { listsViewStyles } from './styles';
import { withStyles } from '@material-ui/core/styles';
import OpenedModal from '../common/OpenedModal';
import { Accordion, AccordionDetails, AccordionSummary, Button, makeStyles, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClientTab from './tabs/ClientTab';
import ListApi from '../../collums-components/api/ListApi';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import { useForm } from 'react-hook-form';
import { toastr } from 'react-redux-toastr';

const ListsView = () => {
    const [expanded, setExpanded] = useState('client_details');
    const globalStyles = makeStyles(modalsButtonStyles)();
    const { register, handleSubmit } = useForm();
    const [formValues, setFormValues] = useState({});
    const [formInitialValues, setFormInitialValues] = useState({});

    useEffect(() => {
        ListApi.getAll().then(lists => {
            const initialFormValues = {};
            for (let i = 0; i < lists.length; i++) {
                initialFormValues[lists[i].sys_name] = lists[i].values;
            }
            setFormValues(initialFormValues);
            setFormInitialValues(initialFormValues);
        });
        // eslint-disable-next-line
    }, []);

    const handleCancel = () => {
        setFormValues(formInitialValues);
    };

    const onSubmit = useCallback(async values => {
        ListApi.updateOrganisation(values).then(async () => {
            toastr.success('Lists successfully updated!');
        });
    }, []);

    const submit = useCallback(
        e => {
            if (e) {
                e.preventDefault();

                handleSubmit(onSubmit)();
            }
        },
        [handleSubmit, onSubmit]
    );

    return (
        <OpenedModal>
            <form onSubmit={submit}>
                <Accordion
                    expanded={expanded === 'client_details'}
                    onChange={() => setExpanded(expanded === 'client_details' ? '' : 'client_details')}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontSize: '0.9rem' }} />}>
                        <Typography>Client Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ClientTab register={register} formValues={formValues} setFormValues={setFormValues} />
                    </AccordionDetails>
                </Accordion>

                <div className={globalStyles.buttonsContainer}>
                    <Button className={globalStyles.cancelButton} onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button className={globalStyles.confirmButton} type="submit" variant="outlined">
                        Save
                    </Button>
                </div>
            </form>
        </OpenedModal>
    );
};

export default withStyles(listsViewStyles)(ListsView);
