import React from 'react';
import { TextField, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { VolumeUnitStyles } from './styles';

const VolumeUnit = ({ opts, label, disabled, className, classes, volumeUnitRef }) => {
    const onChange = (_, newValue) => {
        volumeUnitRef.current = newValue;
    };

    return (
        <Autocomplete
            disabled={disabled}
            value={volumeUnitRef.current}
            onChange={onChange}
            onInputChange={onChange}
            freeSolo
            className={`${className} ${classes.autocomplete}`}
            options={opts}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                        ...params.InputLabelProps,
                        className: classes.textFieldInputLabelProps
                    }}
                />
            )}
            renderOption={option => {
                return <p className={classes.textFieldOptions}>{option}</p>;
            }}
        />
    );
};

VolumeUnit.propTypes = {
    opts: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    volumeUnitRef: PropTypes.object.isRequired
};

export default withStyles(VolumeUnitStyles)(VolumeUnit);
