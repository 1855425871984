import { Checkbox, FormControl, FormControlLabel, TextField, Typography, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { SecurityStyles } from '../styles';
import { Controller } from 'react-hook-form';

function SecurityTab({ classes, control, register, organisation, watch }) {
    const [disabled, setDisabled] = useState(organisation.logoutActive);
    const logoutTime = watch('logoutTime');
    return (
        <div className={classes.customerAppSettingsContainer}>
            <div className={`${classes.inlineContainer} ${classes.intervalLabel}`}>
                <FormControlLabel
                    label="Login Timeout"
                    inputRef={register}
                    control={
                        <Checkbox
                            defaultChecked={organisation.logoutActive}
                            name="logoutActive"
                            onChange={(e, checked) => {
                                setDisabled(checked);
                            }}
                        />
                    }
                />
            </div>

            <FormControl className={classes.fieldContainer} variant="outlined" disabled={disabled}>
                <div className={`${classes.inlineContainer} ${!disabled ? `${classes.disabled}` : ''}`}>
                    <Typography>After </Typography>
                    <Controller
                        name="logoutTime"
                        className={classes.logoutTime}
                        defaultValue={1}
                        control={control}
                        as={
                            <TextField
                                id="logout-time"
                                type="number"
                                variant="outlined"
                                disabled={!disabled}
                                InputProps={{
                                    inputProps: { min: 1 }
                                }}
                                onKeyDown={e => {
                                    if (e.keyCode === 109 || e.keyCode === 189 || e.keyCode === 107) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        }
                    />
                    <Typography>{`${logoutTime > 1 ? 'minutes' : 'minute'} of inactivity`}</Typography>
                </div>
            </FormControl>
        </div>
    );
}

export default withStyles(SecurityStyles)(SecurityTab);
