export const hoursViewStyles = () => ({
    form: {
        width: '100%',
        '& .MuiFormControl-root': {
            width: '100%'
        }
    },
    centerFlexItem: {
        display: 'flex',
        alignItems: 'center'
    },
    formItem: {
        marginTop: '8px !important'
    },
    timeInput: {
        width: '123.69px !important'
    }
});
