import isEqual from 'lodash/isEqual';

const updateLocLevelProperties = ({ newItem, oldItem, location, properties, clinic }) => {
    const oldLocationItem = oldItem.locations.find(loc => loc.clinic === location.clinic);

    if (!oldLocationItem) {
        return properties.reduce((acc, prop) => {
            const { key } = prop;
            const locKey = prop.locKey ?? key;
            return {
                ...acc,
                [locKey]: newItem[key]
            };
        }, location);
    }

    return properties.reduce((acc, prop) => {
        const { key } = prop;
        const locKey = prop.locKey ?? key;

        if (!isEqual(oldItem[key], newItem[key]) && newItem[key] !== undefined) {
            return {
                ...acc,
                [locKey]: newItem[key]
            };
        }

        if (!isEqual(location[locKey], oldLocationItem[locKey]) && clinic === location.clinic) {
            return acc;
        }

        return {
            ...acc,
            [locKey]: oldLocationItem[locKey]
        };
    }, location);
};

export default updateLocLevelProperties;
