import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Controller } from 'react-hook-form';
import TagsInput from '../../../collums-components/form/TagsInput';
import HelpIcon from '@material-ui/icons/Help';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import SelectionList from '../../common/SelectionList';
import { serviceDetailsModalStyles } from '../styles';
import { Autocomplete } from '@material-ui/lab';
import { SERVICE_AVAILABILITIES } from '../../../collums-constants';
import ColorPicker from '../../common/ColorPicker';
import { getLocationItem } from '../../../collums-constants/utils';
import CategoriesModal from '../../categories/CategoriesModal';

function GeneralTab({
    categories = [],
    subCategories = [],
    searchCategories,
    searchSubCategories,
    services = [],
    control,
    register,
    watch,
    currentService,
    setValue,
    isFromOrg,
    loadServices,
    selectedCategory,
    selectedSubCategory,
    setSelectedCategory,
    setSelectedSubCategory,
    setAllowCommission,
    setCommissionCondition,
    clinic,
    allClinics,
    onCategoryConfirm,
    setInlineLocationSettings
}) {
    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const classes = makeStyles(serviceDetailsModalStyles)();
    const isAddOn = watch('isAddOn');

    useEffect(() => {
        register('category');
        register('subCategory');
        /* eslint-disable-next-line */
    }, []);

    const tooltipAvailability = () => (
        <Tooltip
            placement="right"
            title={
                <Typography style={{ fontSize: '14px', display: 'grid' }}>
                    <span style={{ paddingBottom: '8px' }}>
                        &quot;Book & sell&quot; allows services to be booked in the calendar and sold via the POS. Most
                        services will have this setting.
                    </span>
                    <span style={{ paddingBottom: '8px' }}>
                        &quot;Book only&quot; does not allow services to be sold or added to invoices - an example of
                        this could be local anaesthetic that needs to be booked but you don&apos;t charge for.
                    </span>
                    <span>
                        &quot;Sell only&quot; is the best setting for a late cancellation fee or tips - these cannot be
                        booked in the calendar but can be added to an invoice.
                    </span>
                </Typography>
            }
            aria-label="help"
        >
            <HelpIcon className={classes.helpIcon} />
        </Tooltip>
    );
    return (
        <div className={classes.formContent}>
            <div className={classes.formContentRow}>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Name *</InputLabel>
                    <TextField
                        defaultValue={currentService ? currentService.name : ''}
                        inputRef={register({ required: 'Name' })}
                        name="name"
                        variant="outlined"
                        inputProps={{ readOnly: !isFromOrg && allClinics.length > 1 }}
                    />
                </FormControl>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Description</InputLabel>
                    <TextField
                        defaultValue={currentService ? currentService.description : ''}
                        inputRef={register()}
                        name="description"
                        variant="outlined"
                        inputProps={{ readOnly: !isFromOrg && allClinics.length > 1 }}
                    />
                </FormControl>
                <FormControl className={classes.formItem} style={{ display: 'inline-block' }} variant="outlined">
                    <InputLabel>Availability *</InputLabel>
                    <Controller
                        style={{ width: 'calc(100% - 20px)' }}
                        disabled={!isFromOrg && allClinics.length > 1}
                        name="availability"
                        rules={{ required: 'Availabity' }}
                        control={control}
                        defaultValue={currentService ? currentService.availability : ''}
                        as={
                            <Select
                                inputProps={{
                                    className: classes.selectInputProps
                                }}
                                defaultValue={SERVICE_AVAILABILITIES.BOOK_AND_SELL}
                            >
                                <MenuItem value={undefined}>
                                    <em>None</em>
                                </MenuItem>

                                <MenuItem value={SERVICE_AVAILABILITIES.SELL_ONLY}>Sell Only</MenuItem>
                                <MenuItem value={SERVICE_AVAILABILITIES.BOOK_AND_SELL}>Book & Sell</MenuItem>
                                <MenuItem value={SERVICE_AVAILABILITIES.BOOK_ONLY}>Book Only</MenuItem>
                            </Select>
                        }
                    ></Controller>
                    {tooltipAvailability()}
                </FormControl>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Category *</InputLabel>
                    <Autocomplete
                        // disabled={!isFromOrg}
                        disableClearable
                        options={categories}
                        value={selectedCategory}
                        getOptionLabel={option => option.name}
                        className={classes.autocomplete}
                        defaultValue={currentService.category ? categories[0] : null}
                        onChange={async (e, value) => {
                            if (!value || !value.id) {
                                setShowCategoryModal(true);
                                return false;
                            }
                            setAllowCommission(
                                isFromOrg ? value.allowCommission : getLocationItem(value, clinic)?.allowCommission
                            );
                            setCommissionCondition(
                                isFromOrg
                                    ? value.commissionCondition
                                    : getLocationItem(value, clinic)?.commissionCondition
                            );
                            setValue('category', value !== null ? value.id : undefined);
                            searchSubCategories('', value !== null ? value.id : undefined);
                            setSelectedCategory(value);
                            setValue('subCategory', undefined);
                            setSelectedSubCategory(null);
                        }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                onKeyDown={event => event.preventDefault()}
                                onChange={e => searchCategories(e.target.value)}
                                variant="outlined"
                                style={{ width: '100%' }}
                            />
                        )}
                        renderOption={option => {
                            return <p className={classes.autocompleteOptions}>{`${option.name}`}</p>;
                        }}
                    />
                </FormControl>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel shrink>Sub Category</InputLabel>
                    <Autocomplete
                        disabled={!isFromOrg && allClinics.length > 1}
                        value={selectedSubCategory}
                        options={subCategories}
                        className={classes.autocomplete}
                        getOptionLabel={option => option.name}
                        defaultValue={currentService.subCategory ? subCategories[0] : null}
                        onChange={async (e, value) => {
                            setValue('subCategory', value !== null ? value.id : undefined);
                            setSelectedSubCategory(value);
                        }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                onChange={e =>
                                    searchSubCategories(
                                        e.target.value,
                                        selectedCategory !== null ? (selectedCategory || {}).id : undefined
                                    )
                                }
                                variant="outlined"
                                style={{ width: '100%' }}
                            />
                        )}
                        renderOption={option => {
                            return <p className={classes.autocompleteOptions}>{`${option.name}`}</p>;
                        }}
                    />
                </FormControl>

                <div className={classes.isAddOnContainer}>
                    <FormControlLabel
                        label="Is add-on"
                        disabled={!isFromOrg && allClinics.length > 1}
                        control={
                            <Checkbox
                                defaultChecked={currentService ? currentService.isAddOn : false}
                                inputProps={{ name: 'isAddOn' }}
                                inputRef={register}
                            />
                        }
                    />
                    <Tooltip
                        title={
                            <Typography style={{ fontSize: 14 }}>
                                Add-ons cannot be booked on their own – they must be booked after another service.
                            </Typography>
                        }
                        aria-label="help"
                    >
                        <HelpIcon className={classes.helpIcon} />
                    </Tooltip>
                </div>

                <div />

                {isAddOn && (
                    <SelectionList
                        disabled={!isFromOrg && allClinics.length > 1}
                        label="Services for this add on"
                        control={control}
                        rules={register}
                        options={_.sortBy(services, 'name').map(service => ({
                            value: service.id,
                            label: service.name,
                            subtitle: service.onlineDescription
                        }))}
                        defaultValue={currentService ? currentService.addOnServices : []}
                        name="addOnServices"
                        isPaginated={true}
                        action={loadServices}
                    />
                )}
                <ColorPicker
                    disabled={!isFromOrg && allClinics.length > 1}
                    inputRef={register}
                    name="colour"
                    defaultValue={currentService?.colour}
                    props={{
                        style: {
                            marginTop: 20,
                            marginLeft: 0
                        }
                    }}
                />
                <TagsInput
                    disabled={!isFromOrg && allClinics.length > 1}
                    name="tags"
                    control={control}
                    className={classes.formItem}
                    canAdd
                    defaultValue={currentService ? currentService.tags : []}
                />
                <FormControlLabel
                    label="Sell as part of course only"
                    disabled={!isFromOrg && allClinics.length > 1}
                    control={
                        <Checkbox
                            defaultChecked={currentService ? currentService.courseOnly : false}
                            inputProps={{ name: 'courseOnly' }}
                            inputRef={register}
                        />
                    }
                />
            </div>
            {showCategoryModal && (
                <CategoriesModal
                    isFromOrg={isFromOrg}
                    key={'new'}
                    categoryId={'new'}
                    category={null}
                    onConfirm={response => {
                        categories.push(response.response);
                        setSelectedCategory(response.response);
                        setShowCategoryModal(false);
                        onCategoryConfirm();
                    }}
                    onCancel={() => {
                        setShowCategoryModal(false);
                    }}
                    methodType={'Create'}
                    clinic={clinic}
                    disableBackdropClick={true}
                    allClinics={allClinics}
                />
            )}
        </div>
    );
}

GeneralTab.propTypes = {
    register: PropTypes.func.isRequired,
    categories: PropTypes.array,
    services: PropTypes.array,
    control: PropTypes.any,
    watch: PropTypes.any
};

export default GeneralTab;
