import { AppBar, Tab, Tabs, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState, useMemo, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { typeMenuStyles } from './styles';

import resourcesTabs from './tabsReferences/resourcesTabs';
import locationTabsBase from './tabsReferences/locationTabs';
import organisationTabs from './tabsReferences/organisationTabs';
import OrganisationsApi from '../../api/OrganisationsApi';

function Header({ classes, match, history, currentClinic }) {
    const splitPath = match.path.split('/');
    const [selectedMenu, setSelectedMenu] = useState(splitPath[1]);
    const [tabPaths, setTabPath] = useState([]);
    const [organisationData, setOrganisationData] = useState(null);

    currentClinic = currentClinic || match.url?.split('/')[3];

    useEffect(() => {
        (async () => {
            const organisation = await OrganisationsApi.getOrganisation();
            setOrganisationData(organisation);
        })();
    }, []);

    useEffect(() => {
        setSelectedMenu(match.path.split('/')[1]);
    }, [match.path]);

    useEffect(() => {
        setTabPath(match.url?.split('/') || []);
    }, [match.url]);

    /* put the clinicId name in references */
    const locationTabs = useMemo(() => {
        const insertAccountId = (tabs, id) => {
            const group = (() => {
                const findTab = locationTabsBase.find(el => el.ref.includes(id));
                if (!findTab) {
                    return 'clinic';
                }
                return findTab.group;
            })();
            return tabs
                .filter(tab => {
                    return group === tab.group;
                })
                .map(tab => {
                    if (tab.group === 'clinic') {
                        const splitedTabRef = tab.ref.split('/');
                        return { ...tab, ref: `/location/${id}/${splitedTabRef[2]}` };
                    }
                    return tab;
                });
        };
        return selectedMenu === 'location' ? insertAccountId(locationTabsBase, match.params.id) : [];
    }, [selectedMenu, match.params.id]);

    function onTabChange(event, value) {
        history.push(value);
    }

    const organisationMenu = () => {
        if (!organisationData) {
            return [];
        }

        const menu = [];
        organisationTabs.map(tab => {
            if (
                (tab.ref === '/organisation/stripe' && !organisationData?.stripeCheckoutActive) ||
                (tab.ref === '/organisation/square' && organisationData.stripeAccountId)
            ) {
                return false;
            }

            menu.push(tab);
            return true;
        });
        return menu;
    };

    const tabs = useMemo(() => {
        const selectTabs = selectedMenu => {
            if (selectedMenu === 'organisation') {
                const orgTabs = organisationMenu();
                return orgTabs.filter(el => {
                    return !el?.locked;
                });
            } else if (selectedMenu === 'resources') {
                return resourcesTabs(currentClinic);
            } else {
                return locationTabs;
            }
        };
        return selectTabs(selectedMenu).map(tab => (
            <Tab
                key={tab.ref}
                value={tab.ref} /* remove first part of ref (selectedMenu) */
                className={classes.mainTab}
                label={
                    <a href={tab.ref} onClick={e => e.preventDefault()}>
                        {tab.name}
                    </a>
                }
                style={{ minWidth: 150, flex: '2 0 0px' }}
            />
        ));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisationData, selectedMenu, locationTabs, classes.mainTab, currentClinic]);

    const tabsValue = useMemo(() => {
        const valueRoute = tabPaths.slice(1, tabPaths.length).reduce((acc, curr) => {
            if (curr.includes('categories') || curr.includes('brands') || acc.includes(curr)) {
                return acc;
            }
            return acc.concat(`/${curr}`);
        }, '');
        return valueRoute;
    }, [tabPaths]);

    return (
        <AppBar position="static" classes={{ root: classes.noShadow }}>
            <Tabs
                centered
                variant="scrollable"
                indicatorColor="#565A65"
                scrollButtons="auto"
                value={tabsValue}
                onChange={onTabChange}
                className={`${classes.tabsContainer} ${classes.generalTabs}`}
                TabIndicatorProps={{ className: classes.tabIndicator }}
            >
                {tabs}
            </Tabs>
        </AppBar>
    );
}

Header.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(typeMenuStyles)(withRouter(Header));
