import React, { useEffect, useState } from 'react';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import Switch from '../../collums-components/components/common/customSwitch';
import NotificationApi from '../../api/NotificationApi';
import NotificationsModal from './NotificationsModal';
import { toastr } from 'react-redux-toastr';
import NotificationTable from './NotificationTable';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

const NotificationsView = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [notificationList, setNotificationList] = useState([]);
    const [selectedNotification, setSelectedNotification] = useState({});

    const changeActive = async (notification, target) => {
        const payload = {};
        if (target === 'email') {
            payload.isEmail = true;
            payload.value = !notification.email.active;
        } else {
            payload.isEmail = false;
            payload.value = !notification.sms.active;
        }
        try {
            await NotificationApi.changeActive(notification.id, payload);
            toastr.success(`Notification ${payload.isEmail ? 'email' : 'sms'} successfully updated`);
            await loadList();
        } catch (err) {
            toastr.error('Something went wrong');
        }
    };

    const loadList = async () => {
        try {
            setIsLoading(true);
            const list = await NotificationApi.query();
            //? Ordered list. Names that don't make part of it will be thrown in the end of it
            const orderedNames = [
                'Appointment Confirmation',
                'Appointment Reschedule',
                'Appointment Cancellation',
                '1st appointment reminder',
                '2nd appointment reminder',
                'Invoice Payment',
                'Invoice Refund',
                'Sign Up',
                'Reset Password',
                'Forgot Password',
                'Appointment Cancellation Clinic',
                'Appointment Reschedule Clinic',
                'Online appointment booking',
                'Appointment Check In'
            ];
            const orderedList = [
                ...orderedNames.map(name => {
                    const notification = list.find(notification => notification.name === name);
                    return notification;
                }),
                ...list.filter(notification => !orderedNames.includes(notification.name))
            ].filter(item => item);
            setNotificationList(orderedList);
        } catch (error) {
            toastr.error('Something went wrong');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadList();
    }, []);

    const openModal = async id => {
        setIsLoading(true);
        const selectedNotification = await NotificationApi.get(id);
        setSelectedNotification(selectedNotification);
        setModalOpen(true);
        setIsLoading(false);
    };

    const closeModal = async () => {
        setModalOpen(false);
    };

    const collumns = [
        {
            label: 'Name',
            key: 'name',
            specialField: notification => {
                return (
                    <Typography>
                        <Link
                            onClick={() => openModal(notification.id)}
                            style={{ textDecoration: 'none', color: '#3083EC' }}
                        >
                            {notification.name}
                        </Link>
                    </Typography>
                );
            }
        },
        {
            label: 'Recipient',
            key: 'email.target',
            specialField: notification => (
                <Typography>
                    {notification.email.target === 'Customer' ? 'Client' : notification.email.target}
                </Typography>
            )
        },
        {
            label: 'Description',
            key: 'description'
        },
        {
            label: 'Email',
            key: 'email',
            specialField: notification => (
                <Switch
                    checked={notification.email?.active}
                    onChange={() => {
                        changeActive(notification, 'email');
                    }}
                />
            )
        },
        {
            label: 'SMS',
            key: 'sms',
            specialField: notification =>
                notification.sms.trigger === 'pre-form' ? null : (
                    <Switch
                        checked={notification.sms?.active}
                        onChange={() => {
                            changeActive(notification, 'sms');
                        }}
                    />
                )
        }
    ];

    return (
        <>
            <NotificationTable
                notificationList={notificationList}
                openModal={openModal}
                changeActive={changeActive}
                collumns={collumns}
            />
            {isModalOpen && <NotificationsModal notification={selectedNotification} closeModal={closeModal} />}
            {isLoading && <LoadingScreen />}
        </>
    );
};

export default NotificationsView;
