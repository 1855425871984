export const organisationStyles = () => ({
    link: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontSize: 14,
        color: '#3083EC'
    },
    title: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 500
    }
});
