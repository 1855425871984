import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { viewStyles } from './styles';

class View extends React.Component {
    render() {
        return (
            <div className={`${this.props.classes.container} ${this.props.extraStyleClass}`}>{this.props.children}</div>
        );
    }
}

View.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired
};

export default withStyles(viewStyles)(View);
