import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { withStyles, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { Controller } from 'react-hook-form';

import { accordionFormStyles } from '../styles';
import { getLocationItem } from '../../../collums-constants/utils';

function ProductFormPricingStock({
    classes,
    control,
    netPrice,
    setNetPrice,
    grossPrice,
    setGrossPrice,
    register,
    selectedProduct,
    setValue,
    isFromOrg,
    clinic,
    taxes,
    watch
}) {
    const taxWatch = watch(`${isFromOrg ? 'pricingStock.tax' : 'location.pricingStock.tax'}`);
    const typeWatch = watch('general.productType');

    const calculate = async (value, isNetPrice) => {
        const tax = taxWatch.rate || 0;
        if (isNaN(Number(value)) || !value) return;

        if (isNetPrice) {
            await setNetPrice(prev => {
                if (value === '0.0' && prev === '0.00') {
                    value = '';
                    return value;
                }
                const help = String(value).replace('.', '');
                value = (Number(help) / 100).toFixed(2);
                return value;
            });

            if (value === '') {
                setGrossPrice(value);
                setValue('pricingStock.grossPrice', value);
                setValue('pricingStock.netPrice', value);
                return;
            }

            const newGrossValue = (((parseFloat(value) || 0) * (tax || 0)) / 100 + (parseFloat(value) || 0)).toFixed(2);

            setGrossPrice(newGrossValue);
            setValue('pricingStock.grossPrice', newGrossValue);
            setValue('pricingStock.netPrice', value);
        } else {
            await setGrossPrice(prev => {
                if (value === '0.0' && prev === '0.00') {
                    value = '';
                    return value;
                }
                const help = String(value).replace('.', '');
                value = (Number(help) / 100).toFixed(2);
                return value;
            });

            if (value === '') {
                setNetPrice(value);
                setValue('pricingStock.grossPrice', value);
                setValue('pricingStock.netPrice', value);
                return;
            }

            const newNetValue = (
                (parseFloat(value) || 0) -
                ((parseFloat(value) || 0) * (tax || 0)) / (100 + tax || 0)
            ).toFixed(2);

            setNetPrice(newNetValue);
            setValue('pricingStock.grossPrice', value);
            setValue('pricingStock.netPrice', newNetValue);
        }
    };

    useEffect(() => {
        if (netPrice) {
            const tax = taxWatch?.rate || 0;
            const newGrossValue = (
                ((parseFloat(netPrice) || 0) * (tax || 0)) / 100 +
                (parseFloat(netPrice) || 0)
            ).toFixed(2);

            setGrossPrice(newGrossValue);
            setValue('pricingStock.grossPrice', newGrossValue);
        }
        /* eslint-disable-next-line */
    }, [taxWatch]);

    return (
        <div className={classes.formContent}>
            <div className={classes.formContentRow}>
                <FormControl className={classes.formItem} variant="outlined">
                    <InputLabel>Tax *</InputLabel>
                    <Controller
                        name={isFromOrg ? 'pricingStock.tax' : 'location.pricingStock.tax'}
                        defaultValue={taxes.find(
                            tax => tax.id === getLocationItem(selectedProduct, clinic, 'tax')?.tax
                        )}
                        rules={{ required: 'Tax' }}
                        control={control}
                        as={
                            <Select>
                                {taxes &&
                                    taxes.map((option, index) => (
                                        <MenuItem key={index} value={option}>
                                            {option.rate}%
                                        </MenuItem>
                                    ))}
                            </Select>
                        }
                    ></Controller>
                </FormControl>
                <TextField
                    onChange={e => {
                        if (e.target.value.indexOf(' ') >= 0) return;
                        if (isNaN(Number(e.target.value))) return;
                        if (Number(e.target.value) < 0) return;
                        calculate(e.target.value, true);
                    }}
                    type="number"
                    className={classes.formItem}
                    variant="outlined"
                    name={isFromOrg ? 'pricingStock.netPrice' : 'location.pricingStock.netPrice'}
                    disabled={taxWatch === null || taxWatch === undefined}
                    rules={register({
                        required: 'Net Price',
                        min: { value: 0, message: 'Net Price should not be lower than zero' }
                    })}
                    label="Net Price *"
                    InputLabelProps={{
                        shrink: true
                    }}
                    value={
                        Number(netPrice)
                            .toFixed(2)
                            .toString() || ''
                    }
                />
                <TextField
                    onChange={e => {
                        if (e.target.value.indexOf(' ') >= 0) return;
                        if (isNaN(Number(e.target.value))) return;
                        if (Number(e.target.value) < 0) return;
                        calculate(e.target.value, false);
                    }}
                    type="number"
                    className={classes.formItem}
                    variant="outlined"
                    name={isFromOrg ? 'pricingStock.grossPrice' : 'location.pricingStock.grossPrice'}
                    disabled={taxWatch === null || taxWatch === undefined}
                    rules={register({
                        required: ' Gross Price',
                        min: { value: 0, message: 'Gross Price should not be lower than zero' }
                    })}
                    label="Gross Price *"
                    InputLabelProps={{
                        shrink: true
                    }}
                    value={
                        Number(grossPrice)
                            .toFixed(2)
                            .toString() || ''
                    }
                />
                {/*  <Controller
                    render={props => (
                        <TextField
                            {...props}
                            onChange={e => {
                                // eslint-disable-next-line
                                if (e.target.value.indexOf(' ') >= 0) return;
                                if (isNaN(Number(e.target.value))) return;
                                props.onChange(e);
                                calculate(e.target.value, true);
                                if (e.target.value === '') setValue('pricingStock.netPrice', 0);
                            }}
                            type="number"
                            className={classes.formItem}
                            variant="outlined"
                            label="Net Price *"
                            InputLabelProps={{
                                shrink: true
                            }}
                            value={netPrice}
                            inputRef={register({ required: ' Net price' })}
                        />
                    )}
                    name="pricingStock.netPrice"
                    control={control}
                    defaultValue={selectedProduct ? selectedProduct.netPrice : true}
                /> */}
                {/* <Controller
                    render={props => (
                        <TextField
                            {...props}
                            onChange={e => {
                                // eslint-disable-next-line
                                if (e.target.value.indexOf(' ') >= 0) return;
                                if (isNaN(Number(e.target.value))) return;
                                props.onChange(e);
                                calculate(e.target.value, false);
                                if (e.target.value === '') setValue('pricingStock.grossPrice', 0);
                            }}
                            className={classes.formItem}
                            InputLabelProps={{
                                shrink: true
                            }}
                            type="number"
                            variant="outlined"
                            label="Gross Price *"
                            inputRef={register({ required: ' Gross price' })}
                        />
                    )}
                    name="pricingStock.grossPrice"
                    control={control}
                    defaultValue={selectedProduct ? selectedProduct.grossPrice : true}
                /> */}
                {!isFromOrg && (
                    <>
                        <TextField
                            className={classes.formItem}
                            name={'location.pricingStock.minQuantity'}
                            defaultValue={
                                selectedProduct
                                    ? selectedProduct.locations.filter(loc => loc.clinic === clinic)?.[0].min || 1
                                    : 1
                            }
                            label="Min quantity"
                            inputRef={register({
                                min: { value: 0, message: 'Min value should not be lower than zero' }
                            })}
                            variant="outlined"
                            type="number"
                        />
                        <TextField
                            className={classes.formItem}
                            name={'location.pricingStock.maxQuantity'}
                            defaultValue={
                                selectedProduct
                                    ? selectedProduct.locations.filter(loc => loc.clinic === clinic)?.[0].max || 5
                                    : 5
                            }
                            label="Max quantity"
                            inputRef={register({ min: { value: 0, message: 'Max should not be lower than zero' } })}
                            variant="outlined"
                            type="number"
                        />
                        {typeWatch === 'Retail' && (
                            <FormControl className={classes.formItem}>
                                <TextField
                                    name="location.pricingStock.shouldLast"
                                    defaultValue={(() => {
                                        if (selectedProduct) {
                                            return (
                                                selectedProduct.locations.filter(loc => loc.clinic === clinic)
                                                    .shouldLast || 5
                                            );
                                        }
                                        return 5;
                                    })()}
                                    label="Days should last"
                                    inputRef={register}
                                    disabled={true}
                                    variant="outlined"
                                    type="number"
                                />
                                <Typography>Coming soon</Typography>
                            </FormControl>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

ProductFormPricingStock.propTypes = {
    classes: PropTypes.object.isRequired,
    getValues: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    selectedProduct: PropTypes.object,
    control: PropTypes.any,
    watch: PropTypes.any
};

export default withStyles(accordionFormStyles)(ProductFormPricingStock);
