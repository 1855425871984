import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react';
import View from '../common/View';
import ProductFormModal from './ProductFormModal';
import ProductApi from '../../api/ProductApi';
import TaxesApi from '../../api/TaxesApi';
import ListPagination from '../common/ListPagination';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import ConfirmModal from '../common/ConfirmModal';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import queryStringHelper from 'query-string';
import { useLocation } from 'react-router-dom';
import addCopySufix from '../../services/addCopySufix';
import { CURRENT_CLINIC } from '../../collums-constants/storageKeys';

const productCopyModel = require('./copyProductModel');

function ProductsView({ clinic, clinics, isFromOrg, allClinics }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [size, setSize] = useState(10);
    const [categoryList, setCategoryList] = useState([]);
    const [brandList, setBrandList] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [archived, setArchived] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [inactive, setInactive] = useState(true);
    const [confirmModal, setConfirmModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const type = useRef('All');
    const [order, setOrder] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [nameFilter, setNameFilter] = useState('');
    const [productTaxes, setProductTaxes] = useState([]);
    const [categoryFilters, setCategoryFilters] = useState([]);

    if (!clinic) {
        clinic = localStorage.getItem(CURRENT_CLINIC);
    }
    const [loadedOnRedirect, setLoadedOnRedirect] = useState(false);
    const location = useLocation();

    const toDo = () => {
        if (modalTitle === 'Archive product') handleArchive();
    };

    const activeCall = async (product, value) => {
        if (!(product || {}).id) return;
        if (product.archived) {
            toastr.error(`Cannot change product ${product.name} active, because this product is archived`);
            return;
        }
        try {
            await ProductApi.changeProductActive(product.id, value, clinic, isFromOrg);
            ApiCall(0, rowsPerPage * (page + 1), '', true);
            toastr.success(`Product ${product.name} successfully updated`);
        } catch (err) {
            if (typeof err === 'object') {
                if (err.data && err.data.message) {
                    toastr.error(err.data.message);
                    return;
                }
            }
            toastr.error('Something went wrong');
        }
    };

    const ApiCall = useCallback(
        async (skip, limit = 50, queryParam = '', refreshing = false, stopLoading = true) => {
            try {
                setIsLoading(true);
                if (!queryParam && nameFilter) queryParam += `nameFilter=${nameFilter}`;
                if (queryParam.indexOf('nameFilter') > -1) {
                    const splitByFilterName = queryParam.split('nameFilter=')[1];
                    if (splitByFilterName) {
                        const filterName = splitByFilterName.split('&')[0];
                        setNameFilter(filterName);
                    }
                } else setNameFilter('');
                if (queryParam.indexOf('orderBy') === -1 && !refreshing) queryParam += order;
                if (queryParam.indexOf('archived=false') >= 0) setArchived(false);
                else if (queryParam.indexOf('archived=true') >= 0) setArchived(true);
                let newData;
                // if (queryParam.indexOf('inactive=false') >= 0) setInactive(false);
                // else if (queryParam.indexOf('inactive=true') >= 0) setInactive(true);
                if (queryParam.indexOf('type') === -1) queryParam += `&type=${type.current}`;
                let queryString = queryParam ? queryParam : '';
                if (queryParam.indexOf('archived') === -1) queryString += `&archived=${archived}`;

                if (!isFromOrg && queryParam.length && queryParam.indexOf('clinic') === -1) {
                    queryParam = queryParam += `&clinic=${clinic}`;
                    queryString = queryString += `&clinic=${clinic}`;
                }
                if (categoryList.length || brandList.length || supplierList.length || inactive || archived) {
                    if (queryString.indexOf('category') === -1)
                        queryString += `&category=${
                            categoryList.length ? categoryList.map(element => element.value).join(',') : ''
                        }`;
                    if (queryString.indexOf('supplier') === -1)
                        queryString += `${
                            supplierList.length
                                ? '&supplier=' + supplierList.map(element => element.value).join(',')
                                : ''
                        }`;
                    if (queryString.indexOf('brand') === -1)
                        queryString += `${
                            brandList.length ? '&brand=' + brandList.map(element => element.value).join(',') : ''
                        }`;
                    if (queryString.indexOf('inactive') === -1) queryString += `&inactive=${inactive}`;
                    if (queryString.indexOf('archived') === -1) queryString += `&archived=${inactive}`;
                    newData = await ProductApi.getProductsList(skip, limit, queryString).then(res => res);
                } else {
                    newData = await ProductApi.getProductsList(skip, limit, queryParam).then(res => res);
                }
                if (refreshing) {
                    setProducts(newData.items);
                    setSize(newData.size);
                    setOrder('');

                    // check is empty page (no rows) after update row or change filter.
                    if (newData?.items && page > 0 && rowsPerPage * page >= newData.items.length) {
                        setPage(0);
                    }
                }
                if (!loadedOnRedirect) {
                    const query = queryStringHelper.parse(location.search);
                    if (query.productId) {
                        // The items in 'newData' are only the PAGINATED results. If product is not found, we need to search through API
                        const productData =
                            newData.items.find(product => product.id === query.productId) ??
                            (await ProductApi.getProductById(query.productId));
                        onView(productData);

                        setLoadedOnRedirect(true);
                    }
                }

                stopLoading && setIsLoading(false);
                return newData.items;
            } catch (err) {
                toastr.error(err?.data?.message || 'Something went wrong');
                setIsLoading(false);
                return [];
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            archived,
            brandList,
            categoryList,
            inactive,
            loadedOnRedirect,
            location.search,
            nameFilter,
            order,
            supplierList,
            clinic,
            isFromOrg,
            rowsPerPage,
            page
        ]
    );
    const getTaxes = useCallback(async () => {
        const taxes = await TaxesApi.getTaxesForItem('products');
        setProductTaxes(taxes);
    }, [products]); //eslint-disable-line
    useEffect(() => {
        getTaxes();
    }, []); //eslint-disable-line
    const refreshList = useCallback(() => {
        ApiCall(0, rowsPerPage * (page + 1), '', true);
    }, [ApiCall, page, rowsPerPage]);

    const handleArchive = async () => {
        if (selectedProduct.archived === false && selectedProduct.name.includes('- COPY'))
            return toastr.error("Can't archive with '- COPY' in name");
        try {
            const id = selectedProduct.id;
            const value = isFromOrg
                ? !selectedProduct.archived
                : !selectedProduct.locations.find(loc => loc.clinic === clinic).archived;
            const responseProd = await ProductApi.archive(id, value, clinic, isFromOrg);
            toastr.success(
                `Product ${responseProd.name} successfully ${responseProd.archived ? 'archived' : 'unarchived'}`
            );
            await ApiCall(0, rowsPerPage * (page + 1), `archived=${archived}`, true, false);
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    };

    const openArchive = async data => {
        setSelectedProduct(data);
        openConfirmModal(
            'Archive product',
            `Are you sure you want to ${data.archived ? 'unarchive' : 'archive'} this product?`
        );
    };

    const onView = async data => {
        try {
            const categoriesList = await ProductApi.getCategories(
                `?withSubcategories=false&clinic=${clinic}&isFromOrg=${isFromOrg}&active=true&archived=false`
            );
            await setSelectedProduct(data ? { ...data, 'general.barcodes': data.barcodes } : {});
            await setCategories(categoriesList || []);
            setIsModalOpen(true);
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        }
    };

    const handleCopy = async (id, data) => {
        try {
            if (!data.category) {
                toastr.warning("Cannot copy a product that doesn't have a category");
                return;
            }
            const _products = await ApiCall(
                0,
                rowsPerPage * (page + 1),
                `archived=${archived}&nameFilter=${nameFilter}`,
                true,
                false
            );
            data.brand = data.brand ? data.brand.id : undefined;
            data.supplier = data.supplier ? data.supplier.id : undefined;
            data.category = data.category ? data.category.id : undefined;
            data.description = data.description ? data.description : undefined;
            data.code = undefined;
            data.tags = data.tags ? data.tags.map(t => t.id) : [];
            const filteredData = _.pick(data, productCopyModel);

            const name = addCopySufix(data.name, _products);

            await ProductApi.createProduct({ ...filteredData, name, initialStock: 0, id: undefined });
            toastr.success('Product successfully copied!');
            await ApiCall(0, rowsPerPage * (page + 1), `archived=${archived}&nameFilter=${nameFilter}`, true);
            return true;
        } catch (err) {
            toastr.error(err.data?.message || 'Something went wrong');
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    const closeConfirmModal = () => {
        setConfirmModal(false);
        setModalTitle('');
        setModalContent();
    };

    const openConfirmModal = (title, component) => {
        setConfirmModal(true);
        setModalTitle(title);
        setModalContent(component);
    };

    const actions = [
        isFromOrg || allClinics.length === 1 ? { title: 'copy', action: handleCopy } : false,
        {
            title: 'archive',
            action: openArchive
        }
    ].filter(el => el);

    //CATEGORY

    const changeCategory = (newCategoryList, name) => {
        try {
            setCategoryList(newCategoryList);
            ApiCall(
                0,
                50,
                `archived=${archived}&category=${newCategoryList.map(element => element.value).join(',')}${
                    name ? name : ''
                }`,
                true
            );
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        }
    };

    const loadCategoryOptions = async (filter = '') => {
        const filteredData = categoryFilters.find(catFilter => catFilter.filter === filter);
        if (filteredData) return filteredData.categories;

        try {
            const categories = await ProductApi.filterCategory(filter, clinic, isFromOrg);
            setCategoryFilters([...categoryFilters, { filter, categories }]);
            return categories;
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        }
    };

    //SUPPLIER

    const changeSupplier = async (newSupplierList, name) => {
        try {
            await setSupplierList(newSupplierList);
            ApiCall(
                0,
                50,
                `archived=${archived}&supplier=${newSupplierList.map(element => element.value).join(',')}${
                    name ? name : ''
                }`,
                true
            );
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong');
        }
    };

    const loadSupplierOptions = async value => {
        return await ProductApi.filterSupplier(value);
    };

    //BRAND
    const changeBrandOptions = async (newBrandList, name) => {
        await setBrandList(newBrandList);
        ApiCall(
            0,
            50,
            `archived=${archived}&brand=${newBrandList.map(element => element.value).join(',')}${name ? name : ''}`,
            true
        );
    };

    const loadBrandOptions = async value => {
        return await ProductApi.filterBrand(value, clinic, isFromOrg);
    };

    //TYPE
    const changeType = async (newType, name) => {
        type.current = newType;
        ApiCall(0, rowsPerPage * (page + 1), `archived=${archived}&type=${newType}${name ? name : ''}`, true);
    };

    const SearchOptions = [
        {
            name: 'type',
            apiCall: changeType,
            reload: () => [
                { label: 'All', value: 'All' },
                { label: 'Professional', value: 'Professional' },
                { value: 'Retail', label: 'Retail' },
                { value: 'Prescription', label: 'Prescription' }
            ],
            isSelect: true,
            dynamic: false
        },
        {
            name: 'Category',
            apiCall: changeCategory,
            reload: loadCategoryOptions,
            dynamic: true
        },
        {
            name: 'Supplier',
            apiCall: changeSupplier,
            reload: loadSupplierOptions,
            dynamic: true
        },
        {
            name: 'Brand',
            apiCall: changeBrandOptions,
            reload: loadBrandOptions,
            dynamic: true
        }
    ];

    const updateCall = async data => {
        const { id } = data;
        const originalProd = products.find(prod => prod.id === id);
        const model = ['isActive', 'name', 'netPrice', 'grossPrice', 'tax', 'itemTax'];
        let formData = _.pick(data, model);
        if (
            !data.name ||
            (!data.netPrice && data.netPrice !== 0) ||
            (!data.grossPrice && data.grossPrice !== 0) ||
            (!data.tax && data.tax !== 0)
        ) {
            toastr.error('Missing fields');
            return false;
        }

        formData.tax = productTaxes.find(tax => tax.rate === formData.itemTax.rate).id;

        delete formData.itemTax;

        if (!isFromOrg) {
            delete formData.name;
        } else {
            formData.supplier = originalProd.supplier?.id ?? null;
            formData.brand = originalProd.brand?.id ?? null;
            formData.category = originalProd.category.id;
            formData.subCategory = originalProd.subCategory;
            formData.allowCommission = originalProd.allowCommission;
            formData.locations = originalProd.locations;
            formData.locations.map(loc => {
                loc.tax = formData.tax;
                loc.netPrice = data.netPrice;
                loc.grossPrice = data.grossPrice;
                return loc;
            });
        }

        const locationData = {
            name: originalProd.name,
            netPrice: originalProd.netPrice,
            grossPrice: originalProd.grossPrice,
            tax: productTaxes.find(tax => tax.rate === originalProd.itemTax.rate).id,
            locations: data.locations.map(loc => {
                if (loc.clinic === clinic) {
                    loc.tax = formData.tax;
                    loc.netPrice = formData.netPrice;
                    loc.grossPrice = formData.grossPrice;
                    return loc;
                }
                return loc;
            })
        };

        try {
            await ProductApi.updateProduct(id, isFromOrg ? formData : locationData, clinic, isFromOrg);
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong');
            return false;
        }
        toastr.success('Product succesfuly updated!');
        ApiCall(0, rowsPerPage * (page + 1), `archived=${archived}`, true);
        return true;
    };

    const refreshListByOrder = async (orderValue, params) => {
        await ApiCall(0, 50, params + orderValue, true);
        setOrder(orderValue);
    };

    const renderProductFormModal = useMemo(() => {
        return (
            <ProductFormModal
                isFromOrg={isFromOrg}
                clinics={clinics}
                clinic={clinic}
                productTaxes={productTaxes}
                loadCategoryOptions={loadCategoryOptions}
                loadSupplierOptions={loadSupplierOptions}
                loadBrandOptions={loadBrandOptions}
                selectedProduct={selectedProduct}
                isVisible={true}
                categories={categories}
                closeModal={() => {
                    setIsModalOpen(false);
                    setSelectedProduct({});
                    refreshList();
                }}
                allClinics={allClinics}
            />
        );
    }, [isFromOrg, clinics, clinic, productTaxes, selectedProduct, categories, refreshList]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <View>
                <ListPagination
                    page={page}
                    isFromOrg={isFromOrg}
                    clinic={clinic}
                    brands={async () => await ProductApi.getBrands(clinic, true)}
                    suppliers={async () => await ProductApi.getSuppliers(clinic, isFromOrg)}
                    itemTaxes={productTaxes}
                    itemCategories={loadCategoryOptions()}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[25, 50, 100]}
                    setRowsPerPage={setRowsPerPage}
                    data={products}
                    maxSize={size}
                    defaultColumns={[
                        { title: 'Active', field: 'isActive', allowEdit: true },
                        { title: 'Name', field: 'name', onClick: onView },
                        { title: 'Volume', field: 'product-size' },
                        { title: 'Brand', field: 'product-brand' },
                        { title: 'Supplier', field: 'product-supplier' },
                        { title: 'Category', field: 'product-category' },
                        { title: 'Type', field: 'type' },
                        { title: 'Net price', field: 'product-netPrice', allowEdit: true },
                        { title: 'Tax', field: 'tax', allowEdit: true },
                        { title: 'Price', field: 'product-grossPrice', allowEdit: true }
                    ]}
                    actions={actions}
                    ApiCall={ApiCall}
                    onCreate={() => {
                        onView();
                    }}
                    updateCall={updateCall}
                    inputFilters={SearchOptions}
                    filters={[
                        { name: 'archived', label: 'Show Archived', value: false },
                        { name: 'inactive', label: 'Show Inactive', value: inactive, hidden: true }
                    ]}
                    nameFilter={true}
                    filterName="nameFilter"
                    allowInlineEdit={true}
                    canSort={true}
                    sortContent={['Active', 'Size', 'Name', 'Net price', 'Tax', 'Price']}
                    refreshListByOrder={refreshListByOrder}
                    highlightArchived={true}
                    activeCall={activeCall}
                    allClinics={allClinics}
                />
            </View>
            <LoadingScreen isVisible={isLoading} />
            {isModalOpen && renderProductFormModal}
            <ConfirmModal
                onConfirm={toDo}
                isOpen={confirmModal}
                setIsOpen={closeConfirmModal}
                title={modalTitle}
                content={modalContent}
            />
        </>
    );
}

export default ProductsView;
