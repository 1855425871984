export const ServicesFormStyles = () => ({
    flexContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    sectionName: {
        fontSize: 18,
        fontWeight: 500
    },
    sectionContent: {
        display: 'flex',
        alignItems: 'center',
        margin: '15px 0'
    },
    marginLeft: {
        marginLeft: 10
    },
    span: {
        fontFamily: 'Roboto, Arial, Sans-serif'
    },
    addOptionArea: {
        width: '100%'
    },
    emailFormsContainer: {
        width: '100%'
    },
    clientContainer: {
        width: 410
    },
    practitionerContainer: {
        width: 450
    },
    addCircle: {
        color: '#3083EC',
        marginBottom: 20
    },
    helpIcon: {
        fontSize: 14,
        marginTop: '-8px',
        marginLeft: '5px'
    }
});
