import React from 'react';
import { InputLabel, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import { ColorPickerStyles } from './styles';
function ColorPicker({
    classes,
    disabled,
    name = 'colour',
    inputRef = null,
    onChange = null,
    defaultValue = '#000000',
    label = 'Colour',
    props = {}
}) {
    return (
        <>
            {label && (
                <InputLabel shrink style={{ marginLeft: 10 }}>
                    {label}
                </InputLabel>
            )}
            <TextField
                disabled={disabled}
                className={label ? classes.colorInput : classes.colorInputNoLabel}
                inputRef={inputRef}
                name={name}
                type="color"
                onBlur={e => {
                    if (onChange) onChange(e.target.value);
                }}
                defaultValue={defaultValue}
                variant="outlined"
                {...props}
                style={{ marginLeft: 0 }}
            />
        </>
    );
}

export default withStyles(ColorPickerStyles)(ColorPicker);
